import { getCompanyBoards, getCompanyBoardTemplates } from 'actions/boards'
import { getCalendarActions } from 'actions/calendarActions'
import { getCashFlows } from 'actions/cashFlow'
import { getClientGroups } from 'actions/clientGroups'
import { getCompanyIndirectCosts, getCompanyProductsCosts } from 'actions/costs'
import { getCustomers } from 'actions/customers'
import { getDatanalisisPages } from 'actions/datanalisis'
import { getForms } from 'actions/forms'
import { getDeductions, getDeparments, getEmployees, getEvents, getPositions, getSchedules } from 'actions/humanResources'
import { getInventoryCategories, getInventoryProducts, getInventoryProviders, getInventoryTransactions } from 'actions/inventory'
import { getMarketingCampaigns } from 'actions/marketingCampaigns'
import { getMassEmails } from 'actions/messages'
import {
  getPersonalFinanceCards,
  getPersonalFinanceCredits,
  getPersonalFinanceSavings,
  getPersonalFinanceTransactions,
} from 'actions/personalFinances'
import { getCompanyProjects } from 'actions/projects'
import { getCompanyQuotations } from 'actions/quotations'
import { getPosts } from 'actions/socialmedia'
import useCompany from 'hooks/useCompany'
import useFetchResource from 'hooks/useFetchResource'
import { useMemo } from 'react'

const useDatanalisisPage = () => {
  const { idCompany } = useCompany() ?? {}

  const resources = useMemo(() => {
    if (idCompany) {
      return [
        getCalendarActions(idCompany),
        getForms(idCompany),
        getClientGroups(idCompany),
        getCustomers(idCompany),
        getMassEmails(idCompany),
        getMarketingCampaigns(idCompany),
        getPosts(idCompany),
        getCompanyProductsCosts(idCompany),
        getCompanyIndirectCosts(idCompany),
        getCompanyProjects(idCompany),
        getCompanyQuotations(idCompany),
        getDatanalisisPages(idCompany),
        getInventoryCategories(idCompany),
        getInventoryProviders(idCompany),
        getInventoryProducts(idCompany),
        getInventoryTransactions(idCompany),
        getDeparments(idCompany),
        getPositions(idCompany),
        getSchedules(idCompany),
        getEmployees(idCompany),
        getDeductions(idCompany),
        getEvents(idCompany),
        getPersonalFinanceTransactions(idCompany),
        getPersonalFinanceSavings(idCompany),
        getPersonalFinanceCredits(idCompany),
        getPersonalFinanceCards(idCompany),
        getCompanyBoards(idCompany),
        getCompanyBoardTemplates(idCompany),
        getCashFlows(idCompany),
      ]
    }
  }, [idCompany])

  useFetchResource(resources)
}

export default useDatanalisisPage
