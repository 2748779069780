import useLocale from 'hooks/useLocale'
import Page from 'layouts/Page/Page'
import useAiBlocksPageLoadData, { getAiBlocksFunction } from 'pages/AiBlocksPage/useAiBlocksPageLoadData'
import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router'
import styles from '../styles.module.scss'
import {
  Box,
  Breadcrumbs,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import { generateTextAiBlock } from 'actions/aiBlocks'
import { Center } from '@mantine/core'
import { Edit3 } from 'react-feather'
import { AI_TEXT_GENERATOR_PAGE_URL } from 'constants/urls'

const AiTextGeneratorItemPage = () => {
  const locale = useLocale()
  const [aiBlocks, setAiBlocks] = React.useState([])
  const [isLoadingAiBlocks, setIsLoadingAiBlocks] = React.useState(true)
  const { id } = useParams()
  const aiBlock = useMemo(() => aiBlocks?.find(({ idAiBlock }) => idAiBlock === Number(id)), [aiBlocks, id])

  useAiBlocksPageLoadData()

  const [prompt, setPrompt] = React.useState('')
  const [options, setOptions] = React.useState([])
  const [option, setOption] = React.useState('')
  const [language, setLanguage] = React.useState(locale === 'es' ? 'Spanish' : 'English')
  const [response, setResponse] = React.useState('')

  const [generating, setGenerating] = React.useState(false)

  const handleReplyStyleChange = (event) => {
    setOption(event.target.value)
  }

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value)
  }

  const handleSubmit = async () => {
    setResponse('')
    setGenerating(true)
    const data = await generateTextAiBlock({
      title: aiBlock?.title,
      prompt: aiBlock?.prompt,
      extraPrompt: prompt,
      option,
      language,
    })
    setResponse(data?.payload?.text ?? '')
    setGenerating(false)
  }

  useEffect(() => {
    if (aiBlock) {
      const aiBlockOptions = aiBlock?.options || ''
      const options = aiBlockOptions === '' ? [] : aiBlockOptions.split(',')
      if (options.length > 0) {
        setOptions(options)
        setOption(options[0])
      }
    }
  }, [aiBlock])

  const getAiBlocksLocal = async () => {
    setIsLoadingAiBlocks(true)
    const response = await getAiBlocksFunction()
    const data = response?.payload?.aiBlocks ?? []
    setAiBlocks(data)
    setIsLoadingAiBlocks(false)
  }

  useEffect(() => {
    getAiBlocksLocal()
  }, [])

  return (
    <Page title={locale === 'es' ? 'Generador de texto AI' : 'AI Text Generator'} write={9} read={10}>
      <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href={AI_TEXT_GENERATOR_PAGE_URL}>
            {locale === 'es' ? 'Generador de texto AI' : 'AI Text Generator'}
          </Link>
          <Typography color="primary">{aiBlock?.title}</Typography>
        </Breadcrumbs>
      </div>

      <header className={styles.header}>
        <h1 className={styles.aiBlockTitle}>{aiBlock?.title}</h1>
      </header>
      <p className={styles.aiBlockSubTitle}>{aiBlock?.description}</p>

      {isLoadingAiBlocks ? (
        <Center>
          <div className={styles.loaderContainer}>
            <CircularProgress className={styles.loader} color="primary" />
          </div>
        </Center>
      ) : (
        <Box className={styles.formContainer}>
          <form
            onSubmit={(event) => {
              event.preventDefault()
              handleSubmit()
            }}
          >
            <div>
              <Chip label="1" variant="outlined" className="mr-2" color="primary" />
              <label>{locale === 'es' ? 'Escribe algo para generar' : 'Write something to generate'}</label>
            </div>
            <TextField
              multiline
              minRows={4}
              variant="outlined"
              fullWidth
              margin="normal"
              value={prompt}
              onChange={(event) => setPrompt(event.target.value)}
              required
            />
            {options.length > 0 && (
              <FormControl fullWidth margin="normal">
                <div className="mb-3">
                  <Chip label="2" variant="outlined" className="mr-2" color="primary" />
                  <label>{locale === 'es' ? 'Selecciona el estilo de la respuesta' : 'Select the reply style'}</label>
                </div>

                <Select value={option} onChange={handleReplyStyleChange} variant="outlined">
                  <MenuItem value="" disabled>
                    {locale === 'es' ? 'Selecciona una opción' : 'Select an option'}
                  </MenuItem>
                  {options.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <FormControl fullWidth margin="normal">
              <div className="mb-3">
                <Chip label={options.length === 0 ? '2' : '3'} variant="outlined" className="mr-2" color="primary" />
                <label>{locale === 'es' ? 'Selecciona el idioma' : 'Select the language'}</label>
              </div>
              <Select value={language} onChange={handleLanguageChange} variant="outlined">
                <MenuItem value="Spanish">{locale === 'es' ? 'Español' : 'Spanish'}</MenuItem>
                <MenuItem value="English">{locale === 'es' ? 'Inglés' : 'English'}</MenuItem>
              </Select>
            </FormControl>
            <div className={styles.buttonContainer}>
              {generating ? (
                <div className={styles.loaderContainer}>
                  <CircularProgress className={styles.loader} color="primary" />
                </div>
              ) : (
                <Button variant="contained" color="primary" type="submit" startIcon={<Edit3 />}>
                  {locale === 'es' ? 'Generar' : 'Generate'}
                </Button>
              )}
            </div>

            {response.length > 0 && (
              <TextField
                label={locale === 'es' ? 'Respuesta' : 'Response'}
                multiline
                minRows={8}
                variant="outlined"
                fullWidth
                margin="normal"
                value={response}
                InputProps={{
                  readOnly: true,
                }}
                sx={{
                  '& .MuiInputBase-input.Mui-disabled': {
                    color: 'black',
                  },
                  '& .MuiInputBase-input': {
                    color: 'black',
                  },
                }}
              />
            )}
          </form>
        </Box>
      )}
    </Page>
  )
}

export default AiTextGeneratorItemPage
