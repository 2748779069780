import ButtonIcon from 'components/common/ButtonIcon'
import LoadingSnackbar from 'components/common/LoadingSnackbar'
import useLocale from 'hooks/useLocale'
import useCreatePage from '../DatanalisisLayout/useCreatePage'
import styles from './EmptyPages.module.scss'

const EmptyPages = () => {
  const locale = useLocale()
  const [handleCreatePage, loading] = useCreatePage()

  return (
    <div className={styles.wrapper}>
      <figure className={styles.figure}>
        <i className="fa-solid fa-face-sad-tear" />
      </figure>

      <p className={styles.helper}>
        {locale === 'es' ? '¡No has creado ninguna página de indicadores!' : "You don't have any indicators page!"}
      </p>

      <ButtonIcon
        disabled={loading}
        icon={<i className="fas fa-plus-circle" />}
        variant="contained"
        color="primary"
        onClick={handleCreatePage}
      >
        {locale === 'es' ? 'Crear pagina' : 'Create page'}
      </ButtonIcon>

      <LoadingSnackbar open={loading} message={locale === 'es' ? 'Agregando página' : 'Adding page'} />
    </div>
  )
}

export default EmptyPages
