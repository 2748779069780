const countries = () =>
  [
    {
      name_en: 'Afghanistan',
      name_es: 'Afganistán',
      code: 'AF',
    },
    {
      name_en: 'Albania',
      name_es: 'Albania',
      code: 'AL',
    },
    {
      name_en: 'Algeria',
      name_es: 'Argelia',
      code: 'DZ',
    },
    {
      name_en: 'AmericanSamoa',
      name_es: 'Samoa Americana',
      code: 'AS',
    },
    {
      name_en: 'Andorra',
      name_es: 'Andorra',
      code: 'AD',
    },
    {
      name_en: 'Angola',
      name_es: 'Angola',
      code: 'AO',
    },
    {
      name_en: 'Anguilla',
      name_es: 'Anguilla',
      code: 'AI',
    },
    {
      name_en: 'Antarctica',
      name_es: 'Antártida',
      code: 'AQ',
    },
    {
      name_en: 'Antigua and Barbuda',
      name_es: 'Antigua y Barbuda',
      code: 'AG',
    },
    {
      name_en: 'Argentina',
      name_es: 'Argentina',
      code: 'AR',
    },
    {
      name_en: 'Armenia',
      name_es: 'Armenia',
      code: 'AM',
    },
    {
      name_en: 'Aruba',
      name_es: 'Aruba',
      code: 'AW',
    },
    {
      name_en: 'Australia',
      name_es: 'Australia',
      code: 'AU',
    },
    {
      name_en: 'Austria',
      name_es: 'Austria',
      code: 'AT',
    },
    {
      name_en: 'Azerbaijan',
      name_es: 'Azerbaiyán',
      code: 'AZ',
    },
    {
      name_en: 'Bahamas',
      name_es: 'Bahamas',
      code: 'BS',
    },
    {
      name_en: 'Bahrain',
      name_es: 'Baréin',
      code: 'BH',
    },
    {
      name_en: 'Bangladesh',
      name_es: 'Banglades',
      code: 'BD',
    },
    {
      name_en: 'Barbados',
      name_es: 'Barbados',
      code: 'BB',
    },
    {
      name_en: 'Belarus',
      name_es: 'Bielorrusia',
      code: 'BY',
    },
    {
      name_en: 'Belgium',
      name_es: 'Bélgica',
      code: 'BE',
    },
    {
      name_en: 'Belize',
      name_es: 'Belice',
      code: 'BZ',
    },
    {
      name_en: 'Benin',
      name_es: 'Benin',
      code: 'BJ',
    },
    {
      name_en: 'Bermuda',
      name_es: 'Bermudas',
      code: 'BM',
    },
    {
      name_en: 'Bhutan',
      name_es: 'Butan',
      code: 'BT',
    },
    {
      name_en: 'Bolivia',
      name_es: 'Bolivia',
      code: 'BO',
    },
    {
      name_en: 'Bosnia and Herzegovina',
      name_es: 'Bosnia-Herzegovina',
      code: 'BA',
    },
    {
      name_en: 'Botswana',
      name_es: 'Botsuana',
      code: 'BW',
    },
    {
      name_en: 'Brazil',
      name_es: 'Brasil',
      code: 'BR',
    },
    {
      name_en: 'British Indian Ocean Territory',
      name_es: 'Territorio Británico del Océano Índico',
      code: 'IO',
    },
    {
      name_en: 'Brunei Darussalam',
      name_es: 'Brunei',
      code: 'BN',
    },
    {
      name_en: 'Bulgaria',
      name_es: 'Bulgaria',
      code: 'BG',
    },
    {
      name_en: 'Burkina Faso',
      name_es: 'Burkina Faso',
      code: 'BF',
    },
    {
      name_en: 'Burundi',
      name_es: 'Burundi',
      code: 'BI',
    },
    {
      name_en: 'Cambodia',
      name_es: 'Camboya',
      code: 'KH',
    },
    {
      name_en: 'Cameroon',
      name_es: 'Camerún',
      code: 'CM',
    },
    {
      name_en: 'Canada',
      name_es: 'Canadá',
      code: 'CA',
    },
    {
      name_en: 'Cape Verde',
      name_es: 'Cabo Verde',
      code: 'CV',
    },
    {
      name_en: 'Cayman Islands',
      name_es: 'Islas Caimán',
      code: 'KY',
    },
    {
      name_en: 'Central African Republic',
      name_es: 'República Centroafricana',
      code: 'CF',
    },
    {
      name_en: 'Chad',
      name_es: 'Chad',
      code: 'TD',
    },
    {
      name_en: 'Chile',
      name_es: 'Chile',
      code: 'CL',
    },
    {
      name_en: 'China',
      name_es: 'China',
      code: 'CN',
    },
    {
      name_en: 'Christmas Island',
      name_es: 'Isla de Navidad',
      code: 'CX',
    },
    {
      name_en: 'Cocos (Keeling) Islands',
      name_es: 'Islas Cocos',
      code: 'CC',
    },
    {
      name_en: 'Colombia',
      name_es: 'Colombia',
      code: 'CO',
    },
    {
      name_en: 'Comoros',
      name_es: 'Comoras',
      code: 'KM',
    },
    {
      name_en: 'Congo',
      name_es: 'Congo',
      code: 'CG',
    },
    {
      name_en: 'Congo, The Democratic Republic of the',
      name_es: 'República Democrática del Congo',
      code: 'CD',
    },
    {
      name_en: 'Cook Islands',
      name_es: 'Islas Cook',
      code: 'CK',
    },
    {
      name_en: 'Costa Rica',
      name_es: 'Costa Rica',
      code: 'CR',
    },
    {
      name_en: "Cote d'Ivoire",
      name_es: 'Costa de Marfil',
      code: 'CI',
    },
    {
      name_en: 'Croatia',
      name_es: 'Croacia',
      code: 'HR',
    },
    {
      name_en: 'Cuba',
      name_es: 'Cuba',
      code: 'CU',
    },
    {
      name_en: 'Cyprus',
      name_es: 'Chipre',
      code: 'CY',
    },
    {
      name_en: 'Czechia',
      name_es: 'Chequia',
      code: 'CZ',
    },
    {
      name_en: 'Denmark',
      name_es: 'Dinamarca',
      code: 'DK',
    },
    {
      name_en: 'Djibouti',
      name_es: 'Yibuti',
      code: 'DJ',
    },
    {
      name_en: 'Dominica',
      name_es: 'Dominica',
      code: 'DM',
    },
    {
      name_en: 'Dominican Republic',
      name_es: 'República Dominicana',
      code: 'DO',
    },
    {
      name_en: 'Ecuador',
      name_es: 'Ecuador',
      code: 'EC',
    },
    {
      name_en: 'Egypt',
      name_es: 'Egipto',
      code: 'EG',
    },
    {
      name_en: 'El Salvador',
      name_es: 'El Salvador',
      code: 'SV',
    },
    {
      name_en: 'Equatorial Guinea',
      name_es: 'Guinea Ecuatorial',
      code: 'GQ',
    },
    {
      name_en: 'Eritrea',
      name_es: 'Eritrea',
      code: 'ER',
    },
    {
      name_en: 'Estonia',
      name_es: 'Estonia',
      code: 'EE',
    },
    {
      name_en: 'Ethiopia',
      name_es: 'Etiopía',
      code: 'ET',
    },
    {
      name_en: 'Falkland Islands (Malvinas)',
      name_es: 'Islas Malvinas',
      code: 'FK',
    },
    {
      name_en: 'Faroe Islands',
      name_es: 'Islas Feroe',
      code: 'FO',
    },
    {
      name_en: 'Fiji',
      name_es: 'Fiyi',
      code: 'FJ',
    },
    {
      name_en: 'Finland',
      name_es: 'Finlandia',
      code: 'FI',
    },
    {
      name_en: 'France',
      name_es: 'Francia',
      code: 'FR',
    },
    {
      name_en: 'French Guiana',
      name_es: 'Guayana Francesa',
      code: 'GF',
    },
    {
      name_en: 'French Polynesia',
      name_es: 'Polinesia Francesa',
      code: 'PF',
    },
    {
      name_en: 'Gabon',
      name_es: 'Gabón',
      code: 'GA',
    },
    {
      name_en: 'Gambia',
      name_es: 'Gambia',
      code: 'GM',
    },
    {
      name_en: 'Georgia',
      name_es: 'Georgia',
      code: 'GE',
    },
    {
      name_en: 'Germany',
      name_es: 'Alemania',
      code: 'DE',
    },
    {
      name_en: 'Ghana',
      name_es: 'Ghana',
      code: 'GH',
    },
    {
      name_en: 'Gibraltar',
      name_es: 'Gibraltar',
      code: 'GI',
    },
    {
      name_en: 'Greece',
      name_es: 'Grecia',
      code: 'GR',
    },
    {
      name_en: 'Greenland',
      name_es: 'Groenlandia',
      code: 'GL',
    },
    {
      name_en: 'Grenada',
      name_es: 'Granada',
      code: 'GD',
    },
    {
      name_en: 'Guadeloupe',
      name_es: 'Guadalupe',
      code: 'GP',
    },
    {
      name_en: 'Guam',
      name_es: 'Guam',
      code: 'GU',
    },
    {
      name_en: 'Guatemala',
      name_es: 'Guatemala',
      code: 'GT',
    },
    {
      name_en: 'Guernsey',
      name_es: 'Guernsey',
      code: 'GG',
    },
    {
      name_en: 'Guinea',
      name_es: 'Guinea',
      code: 'GN',
    },
    {
      name_en: 'Guinea-Bissau',
      name_es: 'Guinea-Bisau',
      code: 'GW',
    },
    {
      name_en: 'Guyana',
      name_es: 'Guyana',
      code: 'GY',
    },
    {
      name_en: 'Haiti',
      name_es: 'Haití',
      code: 'HT',
    },
    {
      name_en: 'Holy See (Vatican City State)',
      name_es: 'Ciudad del Vaticano',
      code: 'VA',
    },
    {
      name_en: 'Honduras',
      name_es: 'Honduras',
      code: 'HN',
    },
    {
      name_en: 'Hong Kong',
      name_es: 'Hong Kong',
      code: 'HK',
    },
    {
      name_en: 'Hungary',
      name_es: 'Hungría',
      code: 'HU',
    },
    {
      name_en: 'Iceland',
      name_es: 'Islandia',
      code: 'IS',
    },
    {
      name_en: 'India',
      name_es: 'India',
      code: 'IN',
    },
    {
      name_en: 'Indonesia',
      name_es: 'Indonesia',
      code: 'ID',
    },
    {
      name_en: 'Iran, Islamic Republic of',
      name_es: 'Irán',
      code: 'IR',
    },
    {
      name_en: 'Iraq',
      name_es: 'Iraq',
      code: 'IQ',
    },
    {
      name_en: 'Ireland',
      name_es: 'Irlanda',
      code: 'IE',
    },
    {
      name_en: 'Isle of Man',
      name_es: 'Isla de Man',
      code: 'IM',
    },
    {
      name_en: 'Israel',
      name_es: 'Israel',
      code: 'IL',
    },
    {
      name_en: 'Italy',
      name_es: 'Italia',
      code: 'IT',
    },
    {
      name_en: 'Jamaica',
      name_es: 'Jamaica',
      code: 'JM',
    },
    {
      name_en: 'Japan',
      name_es: 'Japón',
      code: 'JP',
    },
    {
      name_en: 'Jersey',
      name_es: 'Jersey',
      code: 'JE',
    },
    {
      name_en: 'Jordan',
      name_es: 'Jordania',
      code: 'JO',
    },
    {
      name_en: 'Kazakhstan',
      name_es: 'Kazajistán',
      code: 'KZ',
    },
    {
      name_en: 'Kenya',
      name_es: 'Kenia',
      code: 'KE',
    },
    {
      name_en: 'Kiribati',
      name_es: 'Kiribati',
      code: 'KI',
    },
    {
      name_en: "Korea, Democratic People's Republic of",
      name_es: 'Corea del Norte',
      code: 'KP',
    },
    {
      name_en: 'Korea, Republic of',
      name_es: 'Corea del Sur',
      code: 'KR',
    },
    {
      name_en: 'Kosovo',
      name_es: 'Kosovo',
      code: 'XK',
    },
    {
      name_en: 'Kuwait',
      name_es: 'Kuwait',
      code: 'KW',
    },
    {
      name_en: 'Kyrgyzstan',
      name_es: 'Kirguistán',
      code: 'KG',
    },
    {
      name_en: "Lao People's Democratic Republic",
      name_es: 'Laos',
      code: 'LA',
    },
    {
      name_en: 'Latvia',
      name_es: 'Letonia',
      code: 'LV',
    },
    {
      name_en: 'Lebanon',
      name_es: 'Líbano',
      code: 'LB',
    },
    {
      name_en: 'Lesotho',
      name_es: 'Lesoto',
      code: 'LS',
    },
    {
      name_en: 'Liberia',
      name_es: 'Liberia',
      code: 'LR',
    },
    {
      name_en: 'Libyan Arab Jamahiriya',
      name_es: 'Libia',
      code: 'LY',
    },
    {
      name_en: 'Liechtenstein',
      name_es: 'Liechtenstein',
      code: 'LI',
    },
    {
      name_en: 'Lithuania',
      name_es: 'Lituania',
      code: 'LT',
    },
    {
      name_en: 'Luxembourg',
      name_es: 'Luxemburgo',
      code: 'LU',
    },
    {
      name_en: 'Macao',
      name_es: 'Macao',
      code: 'MO',
    },
    {
      name_en: 'Macedonia, The Former Yugoslav Republic of',
      name_es: 'República de Macedonia',
      code: 'MK',
    },
    {
      name_en: 'Madagascar',
      name_es: 'Madagascar',
      code: 'MG',
    },
    {
      name_en: 'Malawi',
      name_es: 'Malaui',
      code: 'MW',
    },
    {
      name_en: 'Malaysia',
      name_es: 'Malasia',
      code: 'MY',
    },
    {
      name_en: 'Maldives',
      name_es: 'Maldivas',
      code: 'MV',
    },
    {
      name_en: 'Mali',
      name_es: 'Malí',
      code: 'ML',
    },
    {
      name_en: 'Malta',
      name_es: 'Malta',
      code: 'MT',
    },
    {
      name_en: 'Marshall Islands',
      name_es: 'Islas Marshall',
      code: 'MH',
    },
    {
      name_en: 'Martinique',
      name_es: 'Martinica',
      code: 'MQ',
    },
    {
      name_en: 'Mauritania',
      name_es: 'Mauritania',
      code: 'MR',
    },
    {
      name_en: 'Mauritius',
      name_es: 'Mauricio',
      code: 'MU',
    },
    {
      name_en: 'Mayotte',
      name_es: 'Mayotte',
      code: 'YT',
    },
    {
      name_en: 'Mexico',
      name_es: 'México',
      code: 'MX',
    },
    {
      name_en: 'Micronesia, Federated States of',
      name_es: 'Estados Federados de Micronesia',
      code: 'FM',
    },
    {
      name_en: 'Moldova, Republic of',
      name_es: 'Moldavia',
      code: 'MD',
    },
    {
      name_en: 'Monaco',
      name_es: 'Monaco',
      code: 'MC',
    },
    {
      name_en: 'Mongolia',
      name_es: 'Mongolia',
      code: 'MN',
    },
    {
      name_en: 'Montenegro',
      name_es: 'Montenegro',
      code: 'ME',
    },
    {
      name_en: 'Montserrat',
      name_es: 'Montserrat',
      code: 'MS',
    },
    {
      name_en: 'Morocco',
      name_es: 'Marruecos',
      code: 'MA',
    },
    {
      name_en: 'Mozambique',
      name_es: 'Mozambique',
      code: 'MZ',
    },
    {
      name_en: 'Myanmar',
      name_es: 'Birmania',
      code: 'MM',
    },
    {
      name_en: 'Namibia',
      name_es: 'Namibia',
      code: 'NA',
    },
    {
      name_en: 'Nauru',
      name_es: 'Nauru',
      code: 'NR',
    },
    {
      name_en: 'Nepal',
      name_es: 'Nepal',
      code: 'NP',
    },
    {
      name_en: 'Netherlands',
      name_es: 'Holanda',
      code: 'NL',
    },
    {
      name_en: 'Netherlands Antilles',
      name_es: 'Antillas Holandesas',
      code: 'AN',
    },
    {
      name_en: 'New Caledonia',
      name_es: 'Nueva Caledonia',
      code: 'NC',
    },
    {
      name_en: 'New Zealand',
      name_es: 'Nueva Zelanda',
      code: 'NZ',
    },
    {
      name_en: 'Nicaragua',
      name_es: 'Nicaragua',
      code: 'NI',
    },
    {
      name_en: 'Niger',
      name_es: 'Niger',
      code: 'NE',
    },
    {
      name_en: 'Nigeria',
      name_es: 'Nigeria',
      code: 'NG',
    },
    {
      name_en: 'Niue',
      name_es: 'Niue',
      code: 'NU',
    },
    {
      name_en: 'NorfolkIsland',
      name_es: 'IslaNorfolk',
      code: 'NF',
    },
    {
      name_en: 'NorthernMarianaIslands',
      name_es: 'IslasMarianasdelNorte',
      code: 'MP',
    },
    {
      name_en: 'Norway',
      name_es: 'Noruega',
      code: 'NO',
    },
    {
      name_en: 'Oman',
      name_es: 'Omán',
      code: 'OM',
    },
    {
      name_en: 'Pakistan',
      name_es: 'Pakistán',
      code: 'PK',
    },
    {
      name_en: 'Palau',
      name_es: 'Palaos',
      code: 'PW',
    },
    {
      name_en: 'Panama',
      name_es: 'Panamá',
      code: 'PA',
    },
    {
      name_en: 'Papua New Guinea',
      name_es: 'Papúa Nueva Guinea',
      code: 'PG',
    },
    {
      name_en: 'Paraguay',
      name_es: 'Paraguay',
      code: 'PY',
    },
    {
      name_en: 'Peru',
      name_es: 'Perú',
      code: 'PE',
    },
    {
      name_en: 'Philippines',
      name_es: 'Filipinas',
      code: 'PH',
    },
    {
      name_en: 'Pitcairn',
      name_es: 'Islas Pitcairn',
      code: 'PN',
    },
    {
      name_en: 'Poland',
      name_es: 'Polonia',
      code: 'PL',
    },
    {
      name_en: 'Portugal',
      name_es: 'Portugal',
      code: 'PT',
    },
    {
      name_en: 'Puerto Rico',
      name_es: 'Puerto Rico',
      code: 'PR',
    },
    {
      name_en: 'Qatar',
      name_es: 'Qatar',
      code: 'QA',
    },
    {
      name_en: 'Romania',
      name_es: 'Rumania',
      code: 'RO',
    },
    {
      name_en: 'Russia',
      name_es: 'Rusia',
      code: 'RU',
    },
    {
      name_en: 'Rwanda',
      name_es: 'Ruanda',
      code: 'RW',
    },
    {
      name_en: 'Réunion',
      name_es: 'Reunion',
      code: 'RE',
    },
    {
      name_en: 'Saint Barthélemy',
      name_es: 'San Bartolome',
      code: 'BL',
    },
    {
      name_en: 'Saint Helena, Ascension and Tristan Da Cunha',
      name_es: 'Santa Elena, Ascensión y Tristán de Acuña',
      code: 'SH',
    },
    {
      name_en: 'Saint Kitts and Nevis',
      name_es: 'San Cristóbal y Nieves',
      code: 'KN',
    },
    {
      name_en: 'Saint Lucia',
      name_es: 'Santa Lucía',
      code: 'LC',
    },
    {
      name_en: 'Saint Martin',
      name_es: 'Isla de San Martín',
      code: 'MF',
    },
    {
      name_en: 'Saint Pierre and Miquelon',
      name_es: 'San Pedro y Miquelon',
      code: 'PM',
    },
    {
      name_en: 'Saint Vincent and the Grenadines',
      name_es: 'San Vicente y las Granadinas',
      code: 'VC',
    },
    {
      name_en: 'Samoa',
      name_es: 'Samoa',
      code: 'WS',
    },
    {
      name_en: 'San Marino',
      name_es: 'San Marino',
      code: 'SM',
    },
    {
      name_en: 'Saudi Arabia',
      name_es: 'Arabia Saudita',
      code: 'SA',
    },
    {
      name_en: 'Senegal',
      name_es: 'Senegal',
      code: 'SN',
    },
    {
      name_en: 'Serbia',
      name_es: 'Serbia',
      code: 'RS',
    },
    {
      name_en: 'Seychelles',
      name_es: 'Seychelles',
      code: 'SC',
    },
    {
      name_en: 'Sierra Leone',
      name_es: 'Sierra Leona',
      code: 'SL',
    },
    {
      name_en: 'Singapore',
      name_es: 'Singapur',
      code: 'SG',
    },
    {
      name_en: 'Slovakia',
      name_es: 'Eslovaquia',
      code: 'SK',
    },
    {
      name_en: 'Slovenia',
      name_es: 'Eslovenia',
      code: 'SI',
    },
    {
      name_en: 'Solomon Islands',
      name_es: 'Islas Salomón',
      code: 'SB',
    },
    {
      name_en: 'Somalia',
      name_es: 'Somalia',
      code: 'SO',
    },
    {
      name_en: 'South Africa',
      name_es: 'Sudáfrica',
      code: 'ZA',
    },
    {
      name_en: 'South Sudan',
      name_es: 'Sudán del Sur',
      code: 'SS',
    },
    {
      name_en: 'Spain',
      name_es: 'España',
      code: 'ES',
    },
    {
      name_en: 'Sri Lanka',
      name_es: 'Sri Lanka',
      code: 'LK',
    },
    {
      name_en: 'State of Palestine',
      name_es: 'Estado de Palestina',
      code: 'PS',
    },
    {
      name_en: 'Sudan',
      name_es: 'Sudán',
      code: 'SD',
    },
    {
      name_en: 'Suriname',
      name_es: 'Surinam',
      code: 'SR',
    },
    {
      name_en: 'Svalbard and Jan Mayen',
      name_es: 'Svalbard y Jan Mayen',
      code: 'SJ',
    },
    {
      name_en: 'Swaziland',
      name_es: 'Suazilandia',
      code: 'SZ',
    },
    {
      name_en: 'Sweden',
      name_es: 'Suecia',
      code: 'SE',
    },
    {
      name_en: 'Switzerland',
      name_es: 'Suiza',
      code: 'CH',
    },
    {
      name_en: 'Syrian Arab Republic',
      name_es: 'Siria',
      code: 'SY',
    },
    {
      name_en: 'Taiwan, Province of China',
      name_es: 'Taiwán',
      code: 'TW',
    },
    {
      name_en: 'Tayikistan',
      name_es: 'Tayikistán',
      code: 'TJ',
    },
    {
      name_en: 'Tanzania, United Republic of',
      name_es: 'Tanzania',
      code: 'TZ',
    },
    {
      name_en: 'Thailand',
      name_es: 'Tailandia',
      code: 'TH',
    },
    {
      name_en: 'Timor-Leste',
      name_es: 'Timor Oriental',
      code: 'TL',
    },
    {
      name_en: 'Togo',
      name_es: 'Togo',
      code: 'TG',
    },
    {
      name_en: 'Tokelau',
      name_es: 'Tokelau',
      code: 'TK',
    },
    {
      name_en: 'Tonga',
      name_es: 'Tonga',
      code: 'TO',
    },
    {
      name_en: 'Trinidad and Tobago',
      name_es: 'Trinidad y Tobago',
      code: 'TT',
    },
    {
      name_en: 'Tunisia',
      name_es: 'Túnez',
      code: 'TN',
    },
    {
      name_en: 'Turkey',
      name_es: 'Turquía',
      code: 'TR',
    },
    {
      name_en: 'Turkmenistan',
      name_es: 'Turkmenistán',
      code: 'TM',
    },
    {
      name_en: 'Turks and Caicos Islands',
      name_es: 'Islas Turcas y Caicos',
      code: 'TC',
    },
    {
      name_en: 'Tuvalu',
      name_es: 'Tuvalu',
      code: 'TV',
    },
    {
      name_en: 'Uganda',
      name_es: 'Uganda',
      code: 'UG',
    },
    {
      name_en: 'Ukraine',
      name_es: 'Ucrania',
      code: 'UA',
    },
    {
      name_en: 'United Arab Emirates',
      name_es: 'Emiratos Árabes Unidos',
      code: 'AE',
    },
    {
      name_en: 'United Kingdom',
      name_es: 'Reino Unido',
      code: 'GB',
    },
    {
      name_en: 'United States',
      name_es: 'Estados Unidos',
      code: 'US',
    },
    {
      name_en: 'Uruguay',
      name_es: 'Uruguay',
      code: 'UY',
    },
    {
      name_en: 'Uzbekistan',
      name_es: 'Uzbekistán',
      code: 'UZ',
    },
    {
      name_en: 'Vanuatu',
      name_es: 'Vanuatu',
      code: 'VU',
    },
    {
      name_en: 'Venezuela, Bolivarian Republic of',
      name_es: 'Venezuela',
      code: 'VE',
    },
    {
      name_en: 'Vietnam',
      name_es: 'Vietnam',
      code: 'VN',
    },
    {
      name_en: 'Virgin Islands, British',
      name_es: 'Islas Vírgenes Británicas',
      code: 'VG',
    },
    {
      name_en: 'Virgin Islands, U.S.',
      name_es: 'Islas Vírgenes de los Estados Unidos',
      code: 'VI',
    },
    {
      name_en: 'Wallis and Futuna',
      name_es: 'Wallis y Futuna',
      code: 'WF',
    },
    {
      name_en: 'Yemen',
      name_es: 'Yemen',
      code: 'YE',
    },
    {
      name_en: 'Zambia',
      name_es: 'Zambia',
      code: 'ZM',
    },
    {
      name_en: 'Zimbabwe',
      name_es: 'Zimbabue',
      code: 'ZW',
    },
    {
      name_en: 'Åland Islands',
      name_es: 'Åland',
      code: 'AX',
    },
  ].sort((a, b) => (a.name_es > b.name_es) - (a.name_es < b.name_es))

export default countries()
