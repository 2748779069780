import { GET_CONFIGS, GET_HISTORIC, SET_NEW_CONFIG, UPDATE_CONFIG, CHANGE_LOCALE } from 'constants/actionTypes'
import { CONFIG_API_ROUTE, GET_HISTORIC_API_ROUTE } from 'constants/apiRoutes'
import { apiFindData, apiPostData, apiPutData } from 'helpers/api'

export const getConfigs = (idCompany, idUser) => apiPostData(`${CONFIG_API_ROUTE}/get`, { idCompany, idUser }, GET_CONFIGS)

export const addNewConfig = (config) => apiPostData(CONFIG_API_ROUTE, config, SET_NEW_CONFIG)

export const updateConfig = (config) => apiPutData(CONFIG_API_ROUTE, { id: config?.idConfig, ...config }, UPDATE_CONFIG)

export const getHistoric = (idCompany) => apiFindData(GET_HISTORIC_API_ROUTE, { id: idCompany }, GET_HISTORIC)

export const changeLocale = (locale) => ({ type: CHANGE_LOCALE, payload: locale })
