import { FormControl, InputLabel, MenuItem, OutlinedInput, Select } from '@material-ui/core'
import styles from './SelectWhite.module.scss'

const SelectWhite = ({ options = [], field = {}, value = '', disabled = false, onChange }) => {
  const { label, name, required = false } = field
  const hasValue = Boolean(value)

  const input = <OutlinedInput notched={hasValue} label={label} classes={{ root: styles.inputRoot }} />

  return (
    <FormControl required={required}>
      <InputLabel variant="outlined" shrink={hasValue} classes={{ root: styles.label }}>
        {label}
      </InputLabel>

      <Select label={label} value={value} name={name} onChange={onChange} disabled={disabled} input={input}>
        {options.map((i) => (
          <MenuItem value={i.value} key={i.value}>
            {i.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default SelectWhite
