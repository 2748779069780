import React from 'react'
import { useCountValue } from '../../hooks/useCountValue'
import styles from './PreviewTotal.module.scss'

const PreviewTotal = ({ indicator = {} }) => {
  const { title, values } = indicator
  const { reduceByKey } = useCountValue(indicator)

  return (
    <div className={styles.wrapper}>
      <strong className={styles.title}>{title}</strong>

      <div className={styles.valueItemsWrapper} style={{ '--grid-value': indicator.w }}>
        {values.map((value, index) => (
          <div key={`total-${index}`} className={styles.valueItemWrapper}>
            <strong className={styles.valueItemTitle}>{value.header}</strong>
            <p className={styles.valueItemCount}>{reduceByKey(value.key)}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default PreviewTotal
