import aesjs from 'aes-js'
import { addMonths } from 'date-fns'
import JSEncrypt from 'jsencrypt'
import api from './paymentServicesApi'

const generateAESPairs = () => {
  const key = []
  let iv = 0
  for (let k = 0; k < 16; k++) {
    key.push(Math.floor(Math.random() * 255))
  }
  for (let k = 0; k < 16; k++) {
    iv = Math.floor(Math.random() * 255)
  }
  return {
    k: key,
    s: iv,
  }
}

const pack = (obj, pair_) => {
  const encrypt = new JSEncrypt()
  encrypt.setPublicKey(process.env.REACT_APP_GREEN_PAY_PUBLIC_KEY)

  const pair = pair_ !== undefined ? pair_ : generateAESPairs()
  const textBytes = aesjs.utils.utf8.toBytes(JSON.stringify(obj))
  const aesCtr = new aesjs.ModeOfOperation.ctr(pair.k, new aesjs.Counter(pair.s))
  const encryptedBytes = aesCtr.encrypt(textBytes)
  const encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes)
  const returnObj = {
    ld: encryptedHex,
    lk: encrypt.encrypt(JSON.stringify(pair)),
  }
  return returnObj
}

const encryptCard = (cc) => {
  const card = {
    card: {
      cardHolder: cc.name,
      expirationDate: {
        month: Number(cc.date.split('/')[0]),
        year: Number(cc.date.split('/')[1]),
      },
      cardNumber: cc.card.replace(/ /g, ''),
      cvc: cc.cvv,
      nickname: cc.name,
    },
  }

  return pack(card, undefined)
}

export const createSubscription = async (user, creditCard) => {
  const encryptedCard = encryptCard(creditCard)
  const { subscription_payments, idUser, firstName, lastName } = user

  const data = {
    userId: `${idUser}-${firstName}-${lastName}`,
    description: 'Suscripcion a PRO por 1 año',
    currency: 'USD',
    initialPayment: {
      amount: 0,
      description: 'No existe pago inicial',
    },
    subscription: [
      {
        startDate: subscription_payments?.length ? new Date().getTime() : addMonths(new Date(), 1).getTime(), // El usuario tiene 30 dias de prueba
        amount: 55,
        cadence: {
          mode: 'EVERY',
          unit: 'MONTH',
          every: 12,
        },
      },
    ],
    card: encryptedCard,
  }

  try {
    const response = await api.post('/greenpay/subscription', data)

    return response.data
  } catch (error) {
    console.error(error)
  }
}

export const updateSubscription = async (user, creditCard) => {
  const subscriptionId = user.subscription.subscription_id
  const encryptedCard = encryptCard(creditCard)

  const data = {
    card: encryptedCard,
    subscriptionId,
    user: `${user.idUser}-${user.firstName}-${user.lastName}`,
  }

  try {
    const response = await api.put('/greenpay/subscription', data)

    return response.data
  } catch (error) {
    console.error(error)
  }
}

export const cancelSubscription = async (subscriptionId) => {
  try {
    const response = await api.delete(`/greenpay/subscription`, {
      data: {
        subscriptionId,
      },
    })

    return response.data
  } catch (error) {
    console.error(error)
  }
}

export const getSubscription = async (subscriptionId) => {
  try {
    const response = await api.get(`/greenpay/subscription/${subscriptionId}`)

    return response.data
  } catch (error) {
    console.error(error)
  }
}

export const getSubscriptionHistory = async (subscriptionId) => {
  try {
    const response = await api.get(`/greenpay/subscription/${subscriptionId}/payments`)

    return response.data
  } catch (error) {
    console.error(error)
  }
}
