import { DOWNLOAD_FILE_BY_PATH } from 'constants/apiRoutes'

const getFileIcon = (filename) => {
  const arr = filename.split('.')
  const extension = arr[arr.length - 1]

  switch (extension) {
    case 'jpg':
    case 'png':
    case 'jpeg':
      return 'fas fa-file-image'
    case 'doc':
    case 'docx':
      return 'fas fa-file-word'
    case 'csv':
    case 'xlsx':
      return 'fas fa-file-excel'
    case 'pdf':
      return 'fas fa-file-pdf'
    default:
      return 'fas fa-file'
  }
}

const createUrl = (path) => {
  const regex = /#/gi

  return `${DOWNLOAD_FILE_BY_PATH}?path=${path.replace(regex, '%23')}`
}

export { getFileIcon, createUrl }
