import { getHoursWorked } from 'helpers/date'
import { useCallback } from 'react'
import useStub from './useStub'

const useStubHandlers = (setter, employee = {}, calculateDefaultStub) => {
  const { work_day, stubs } = employee
  const { defaultWorkedHors, deductions } = useStub(employee)

  const handleChangeType = useCallback(
    (e) => {
      const value = +e.target.value
      let hours = defaultWorkedHors[value]

      if (value === 5) {
        const time = getHoursWorked(work_day)
        const [data] = time ? time?.split(':') : []
        const currentMonthPayedHours = stubs.reduce((acc, stub) => {
          const currentMonth = new Date().getMonth()
          const stubMonth = new Date(stub?.date).getMonth()

          if (currentMonth === stubMonth && !stub?.IS_PENDGING) {
            return acc + stub?.workedHours
          }

          return acc
        }, 0)

        hours = (data ?? 0) - Number(currentMonthPayedHours) < 0 ? 0 : (data ?? 0) - Number(currentMonthPayedHours)
      }

      setter(calculateDefaultStub(value, hours))
    },
    [setter, defaultWorkedHors, work_day, stubs, calculateDefaultStub]
  )

  const handleChangeExtraPay = useCallback(
    (e) => {
      const value = +e.target.value
      setter((prev) => ({
        ...prev,
        extraPayment: value || '',
        extraPayments: value || '',
        totalPayment: prev.basePayment - prev.deductions + value,
      }))
    },
    [setter]
  )

  const handleChangeWorkingHours = useCallback(
    (e) => {
      const value = +e.target.value
      const newDeductions = value ? deductions(value)[4] : deductions(1)[4]
      const newBasePayment = (state) => value * state?.hourlyRate + (state?.extraHours ?? 0) * state.extraHourlyRate

      setter((prev) => ({
        ...prev,
        workedHours: value,
        deductions: newDeductions,
        basePayment: newBasePayment(prev),
        totalPayment: newBasePayment(prev) - newDeductions,
      }))
    },
    [setter, deductions]
  )

  const handleChangeExtraWorkingHours = useCallback(
    (e) => {
      const value = e.target.value

      setter((prev) => ({
        ...prev,
        extraHours: value,
        extraHoursPayment: value * prev.extraHourlyRate,
        basePayment: prev?.workedHours * prev?.hourlyRate,
        totalPayment: prev?.workedHours * prev?.hourlyRate + value * prev.extraHourlyRate - prev?.deductions,
      }))
    },
    [setter]
  )

  const handleChangeHourlyRate = useCallback(
    (e) => {
      const value = e.target.value
      setter((prev) => ({
        ...prev,
        hourlyRate: value,
        basePayment: prev?.workedHours * value,
        totalPayment: prev?.workedHours * value + (prev?.extraHours ?? 0 * prev.extraHourlyRate) - prev?.deductions,
      }))
    },
    [setter]
  )

  const handleChangeExtraHourlyRate = useCallback(
    (e) => {
      const value = e.target.value

      setter((prev) => ({
        ...prev,
        extraHourlyRate: value,
        extraHoursPayment: (prev?.extraHours ?? 0) * value,
        basePayment: prev?.workedHours * prev?.hourlyRate,
        totalPayment: prev?.workedHours * prev?.hourlyRate + (prev?.extraHours ?? 0) * value - prev?.deductions,
      }))
    },
    [setter]
  )

  return {
    handleChangeType,
    handleChangeExtraPay,
    handleChangeWorkingHours,
    handleChangeExtraWorkingHours,
    handleChangeHourlyRate,
    handleChangeExtraHourlyRate,
  }
}

export default useStubHandlers
