import { confirmDialog } from 'actions/modals'
import { useGoogleAuth } from 'helpers/google'
import useLocale from 'hooks/useLocale'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getNestedResources } from '../api/googleDriveApi'
import { useCreatePermission, useDeleteFile, useUpdateFile } from '../useDriveApi'
import { createDownloadableZip, promptDownload } from '../utils/createDownloadableZip'

export const useContextMenu = (fileId, options) => {
  const [searchParams] = useSearchParams()
  const { folderId } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const locale = useLocale()
  const { authToken } = useGoogleAuth()
  const { mutate: updateFile, mutateAsync: updateFileAsync } = useUpdateFile()
  const { mutateAsync: deleteFilePermanently } = useDeleteFile()
  const { mutateAsync: createPermission } = useCreatePermission()
  const { folders, files, onClose, onOpenSharingModal, onOpenMoveModal, onOpenFilePermissionsModal } = options || {}
  const isShared = searchParams.get('shared')
  const isTrashed = searchParams.get('trashed')

  const resource = useMemo(
    () => files?.find((file) => file.id === fileId) || folders?.find((folder) => folder.id === fileId),
    [fileId, files, folders]
  )
  const { name, id, capabilities } = resource || {}
  const previewFile = async () => {
    const { permissions, webViewLink } = resource
    const globalSharePermission = permissions?.find((item) => item.id === 'anyoneWithLink')

    if (globalSharePermission) return window.open(webViewLink, '_blank')
    else {
      const body = {
        role: 'writer',
        type: 'anyone',
      }

      await toast.promise(
        createPermission({ fileId, folderId, body }),
        {
          pending: locale === 'es' ? 'Creando permisos...' : 'Creating permissions...',
          success: locale === 'es' ? 'Compartido' : 'Shared',
          error: locale === 'es' ? 'No se pudo compartir el archivo' : 'Could not share file',
        },
        {
          autoClose: 2000,
        }
      )

      window.open(webViewLink, '_blank')
    }

    onClose()
  }

  const openFolder = () => {
    const { id } = resource
    window.scroll(0, 0)
    const buildLink = (id) => {
      const shared = isShared ? '?shared=true' : ''
      const trashed = isTrashed ? '?trashed=true' : ''

      return `/dashboard/managment/google-drive/folders/${id}${shared}${trashed}`
    }

    navigate(buildLink(id))
    onClose()
  }

  const downloadFile = async () => {
    const { id, name } = resource
    const extensions = {
      'application/vnd.google-apps.document': {
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        extension: '.docx',
      },
      'application/vnd.google-apps.spreadsheet': {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        extension: '.xlsx',
      },
      'application/vnd.google-apps.presentation': {
        type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        extension: '.pptx',
      },
      'text/plain': {
        type: 'text/plain',
        extension: '.txt',
      },
    }
    const toastId = toast.loading(locale === 'es' ? 'Preparando descarga...' : 'Preparing download...')
    const baseUrl = `https://www.googleapis.com/drive/v3/files/${id}`

    const downloadFile = (response) => {
      response.blob().then((file) => {
        const url = window.URL.createObjectURL(file)
        const link = document.createElement('a')
        link.download = name
        link.href = url
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    }

    const options = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }

    const res = await fetch(`${baseUrl}?alt=media`, options)

    if (res.status === 200) downloadFile(res)
    else {
      const mimeType = extensions[resource.mimeType]?.type
      const exportResponse = await fetch(`${baseUrl}/export?mimeType=${mimeType}`, options)

      downloadFile(exportResponse)
    }

    toast.update(toastId, {
      isLoading: false,
      type: 'success',
      render: locale === 'es' ? 'Archivo descargado!' : 'File downloaded!',
      autoClose: 2000,
    })
    onClose()
  }

  const downloadFolder = async () => {
    const toastId = toast.loading(locale === 'es' ? 'Preparando descarga...' : 'Preparing download...')
    const { id: folderId } = resource
    let files = await getNestedResources(folderId, authToken, name)

    let zip = createDownloadableZip(files)
    promptDownload(zip, name)
    toast.update(toastId, {
      isLoading: false,
      type: 'success',
      render: locale === 'es' ? 'Carpeta descargada!' : 'Folder downloaded!',
      autoClose: 2000,
    })
    onClose()
  }

  const moveFile = () => {
    const { id } = resource
    onOpenMoveModal(id)
  }

  const changeFilePermissions = () => {
    const { id } = resource
    onOpenFilePermissionsModal(id)
    onClose()
  }

  const renameFile = (newName) => {
    const { id } = resource

    updateFile({
      fileId: id,
      body: {
        name: newName,
      },
    })
    onClose()
  }

  const deleteFile = () => {
    const { id } = resource

    dispatch(
      confirmDialog({
        message: locale === 'es' ? '¿Estás seguro de eliminar este archivo?' : 'Are you sure you want to delete this file?',
        handlerAction: async () => {
          onClose()
          await updateFileAsync({
            fileId: id,
            body: {
              trashed: true,
            },
          })
        },
      })
    )
  }

  const restoreFile = async () => {
    onClose()
    await updateFileAsync({
      fileId: id,
      body: {
        trashed: false,
      },
    })
  }

  const deletePermamently = () => {
    dispatch(
      confirmDialog({
        message: locale === 'es' ? '¿Estás seguro de eliminar este archivo?' : 'Are you sure you want to delete this file?',
        handlerAction: async () => {
          onClose()
          await deleteFilePermanently({ id, authToken })
        },
      })
    )
  }

  const shareFile = () => {
    const { id } = resource
    onOpenSharingModal(id)
    onClose()
  }

  return {
    previewFile,
    downloadFile,
    renameFile,
    deleteFile,
    openFolder,
    shareFile,
    resourceName: name,
    moveFile,
    downloadFolder,
    changeFilePermissions,
    restoreFile,
    deletePermamently,
    canShare: capabilities?.canShare,
  }
}
