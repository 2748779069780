import { Button, LinearProgress, OutlinedInput } from '@material-ui/core'
import { sendSuccessMessage } from 'actions/modals'
import useLocale from 'hooks/useLocale'
import { useCreatePermission } from 'pages/GoogleDrivePage/useDriveApi'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import styles from './AddPeople.module.scss'

const AddPeople = ({ fileId }) => {
  const { folderId } = useParams()
  const dispatch = useDispatch()
  const locale = useLocale()
  const { mutateAsync: createPermission, isLoading } = useCreatePermission()
  const [email, setEmail] = useState('')

  const placeholder = locale === 'es' ? 'Agregar personas' : 'Add people'

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!email.trim()) return

    const body = {
      role: 'reader',
      type: 'user',
      emailAddress: email.trim(),
    }

    await createPermission({ body, folderId, fileId })
    dispatch(sendSuccessMessage(locale === 'es' ? 'Compartido correctamente' : 'Shared successfully'))
    setEmail('')
  }

  return (
    <>
      <form onSubmit={handleSubmit} className={styles.wrapper}>
        <OutlinedInput
          fullWidth
          name="email"
          type="email"
          variant="contained"
          color="primary"
          placeholder={placeholder}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={isLoading}
        />
        <Button color="primary" variant="contained" type="submit" disabled={!email.trim() || isLoading}>
          {locale === 'es' ? 'Compartir' : 'Share'}
        </Button>
      </form>
      <div style={{ width: '100%', marginTop: '8px' }}>{isLoading && <LinearProgress />}</div>
    </>
  )
}

export default AddPeople
