import classNames from 'classnames'
import { getSubscription } from 'constants/subscriptions'
import { formatDistanceToNow } from 'date-fns'
import { enUS, es } from 'date-fns/locale'
import useLocale from 'hooks/useLocale'
import useSubscription from 'hooks/useSubscription'
import React, { useEffect, useState } from 'react'
import PlanHeader from '../PlanHeader'
import RedeemCode from '../RedeemCode'
import Spacer from '../Spacer'
import HistorySection from './HistorySection'
import PaymentMethodSection from './PaymentMethodSection'
import CancelSubscriptionModal from './PaymentMethodSection/CancelSubscriptionModal'
import styles from './ProPlan.module.scss'

const formatEndDate = (endDate, locale) => {
  return formatDistanceToNow(new Date(endDate), {
    locale: { ...(locale === 'es' ? es : enUS) },
    addSuffix: true,
  })
}

const ProPlan = () => {
  const locale = useLocale()
  const { subscription } = useSubscription()
  const { subscription_id } = subscription

  const [data, setData] = useState(subscription)

  const bodyContent =
    locale === 'es'
      ? 'La versión PRO te da acceso ilimitado a todas las herramientas de DigitalPymes.'
      : 'The PRO version gives you unlimited access to all DigitalPymes tools.'

  useEffect(() => {
    const fetch = async () => {
      const data = await getSubscription(subscription_id)

      if (data) {
        setData((prev) => ({ ...data, backup: prev }))
      }
    }

    fetch()
  }, [subscription_id])

  return (
    <>
      <PlanHeader isPro />

      <section className={styles.wrapper}>
        <p className={styles.body}>{bodyContent}</p>

        <CancelSubscriptionModal />
      </section>

      <p className={classNames(styles.expirationDate, styles.body)}>
        {locale === 'es' ? 'Tu suscripción caducará' : 'Your subscription will expire'}{' '}
        <strong>{formatEndDate(data?.next_payment_date ?? data?.backup?.endDate ?? data?.endDate, locale)}</strong>
        {'.'}
      </p>

      <Spacer size={24} />
      <Spacer size={24} colorized={false} />

      <PaymentMethodSection />

      <HistorySection />

      <Spacer size={24} />
      <Spacer size={24} colorized={false} />

      <RedeemCode />
    </>
  )
}

export default ProPlan
