import { GET_ROLES, UPLOAD_ROLE, UPDATE_ROLE, DELETE_ROLE } from 'constants/actionTypes'

const initialState = {
  isLoadingRoles: true,
  roles: [],
}

const handlers = {
  [GET_ROLES]: (state, { payload = {} }) => {
    if (!payload?.roles) return { ...state, errorInRequest: true, isLoadingRoles: false }

    const roles = payload.roles.reduce((acc, item) => {
      acc.push({
        ...item.role,
        permissions: item.permissions,
      })
      return acc
    }, [])

    return { ...state, roles: roles, isLoadingRoles: false }
  },
  [UPLOAD_ROLE]: (state, { payload = {} }) => {
    if (!payload?.data) return { ...state, errorInRequest: true }

    const { role, permissions } = payload.data

    return {
      ...state,
      roles: state.roles.concat({
        ...role,
        permissions: permissions,
      }),
    }
  },
  [UPDATE_ROLE]: (state, { payload = {} }) => {
    if (!payload?.data) return { ...state, errorInRequest: true }

    const { role: updatedRole, permissions } = payload.data

    return {
      ...state,
      roles: state.roles.map((role) =>
        role.idCustomRole === updatedRole.idCustomRole ? { ...updatedRole, permissions: permissions } : role
      ),
    }
  },
  [DELETE_ROLE]: (state, { payload = {} }) => {
    if (!payload?.role) return { ...state, errorInRequest: true }

    return {
      ...state,
      roles: state.roles.filter(({ idCustomRole }) => idCustomRole !== payload?.role?.idCustomRole),
    }
  },
}

const roles = (state = initialState, action) => {
  const { type } = action

  return handlers[type] ? handlers[type](state, action) : state
}

export default roles
