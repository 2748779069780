import { Tooltip } from '@material-ui/core'
import { selectPage } from 'actions/datanalisis'
import classNames from 'classnames'
import BackButton from 'components/BackButton'
import ButtonIcon from 'components/common/ButtonIcon'
import LoadingSnackbar from 'components/common/LoadingSnackbar'
import LoadingSection from 'components/LoadingSection'
import useDatanalisis from 'hooks/useDatanalisis'
import useLocale from 'hooks/useLocale'
import useDatanalisisPage from 'pages/DatanalisisPage/useDatanalisisPage'
import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import EmptyPages from '../EmptyPages'
import IndicatorCreatorModal from '../IndicatorCreatorModal'
import styles from './DatanalisisLayout.module.scss'
import useCreatePage from './useCreatePage'

const AddPageButton = () => {
  const locale = useLocale()
  const { loadingPages } = useDatanalisis() ?? {}

  const [handleCreatePage, loading] = useCreatePage()

  return (
    <>
      <ButtonIcon
        icon={<i className="fas fa-plus-circle" />}
        fullWidth
        variant="text"
        color="primary"
        onClick={handleCreatePage}
        classes={{ root: styles.addButton }}
        disabled={loadingPages}
      >
        {locale === 'es' ? 'Agregar página' : 'Add page'}
      </ButtonIcon>

      <LoadingSnackbar open={loading} message={locale === 'es' ? 'Agregando página' : 'Adding page'} />
    </>
  )
}

const PageItem = React.forwardRef(({ page }, selectedPageWrapperRef) => {
  const dispatch = useDispatch()
  const { selectedPageId } = useDatanalisis() ?? {}

  const handleSelectPage = (page) => {
    selectedPageWrapperRef.current.scrollTo(0, 0)
    dispatch(selectPage(page))
  }

  const pageId = page.idDatanalisisPage
  const selected = pageId === selectedPageId

  return (
    <li className={classNames(styles.item, selected && styles.selected)} onClick={() => handleSelectPage(page)}>
      {page.title}
    </li>
  )
})

const DatanalisisLayout = ({ children }) => {
  const locale = useLocale()
  const { pages, loadingPages } = useDatanalisis() ?? {}
  const sectionTitle = locale === 'es' ? 'Análisis de Datos' : 'Datanalysis'
  const selectedPageWrapperRef = useRef(null)

  useDatanalisisPage()

  return (
    <main className={styles.wrapper}>
      <nav className={styles.navigation}>
        <Link to="/dashboard">
          <Tooltip title={locale === 'es' ? 'Página principal' : 'Homepage'}>
            <img src="/white-logo.svg" alt="digitalPymes" />
          </Tooltip>
        </Link>

        <BackButton rootClassName={styles.backButton} />

        <AddPageButton />

        <ul className={styles.pagesList}>
          {pages?.map((page) => (
            <PageItem ref={selectedPageWrapperRef} key={page.idDatanalisisPage} page={page} />
          ))}
        </ul>
      </nav>

      <section className={styles.selectedPageWrapper} ref={selectedPageWrapperRef}>
        <header className={styles.header}>
          <h1 className={styles.title}>{sectionTitle}</h1>
        </header>

        {loadingPages ? (
          <LoadingSection message={locale === 'es' ? 'Cargando paginas' : 'Loading pages'} className={styles.loadingPages} />
        ) : (
          !pages.length && <EmptyPages />
        )}

        {!loadingPages && children}

        <IndicatorCreatorModal />
      </section>
    </main>
  )
}

export default DatanalisisLayout
