import { Button } from '@material-ui/core'
import useLocale from 'hooks/useLocale'
import React from 'react'
import { useNavigate } from 'react-router'
import styles from './TrashButton.module.scss'

const SharedButton = () => {
  const locale = useLocale()
  const navigate = useNavigate()

  const handleClick = (e) => {
    navigate('/dashboard/managment/google-drive?trashed=true')
  }

  return (
    <Button
      startIcon={<i className="fas fa-trash" />}
      variant="contained"
      classes={{ root: styles.button }}
      onClick={handleClick}
    >
      {locale === 'es' ? 'Papelera' : 'Recycle Bin'}
    </Button>
  )
}

export default SharedButton
