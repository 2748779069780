import { Button, ClickAwayListener, Tooltip } from '@material-ui/core'
import { toggleIndicatorCreator } from 'actions/datanalisis'
import classNames from 'classnames'
import useDatanalisis from 'hooks/useDatanalisis'
import useLocale from 'hooks/useLocale'
import TextFieldWhite from 'pages/DatanalisisPage/components/TextFieldWhite'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useImmerReducer } from 'use-immer'
import FormHelper from '../FormHelper'
import SelectWhite from '../SelectWhite'
import GraphicSelector from './GraphicSelector'
import { DEFAULT_INDICATOR, handleChange, handleChangeIndicator, indicatorReducer, resetForm } from './helpers/indicatorReducer'
import { FORM_CONTENT, INDICATOR_TYPES, MODULES } from './helpers/modalContent'
import { validateIndicator } from './helpers/validateIndicator'
import useTableModel from './hooks/useTableModel'
import styles from './IndicatorCreatorModal.module.scss'
import IndicatorPreviewer from './IndicatorPreviewer'
import ValuesSelector from './ValuesSelector'

const IndicatorCreatorModal = () => {
  const dispatch = useDispatch()
  const locale = useLocale()
  const { isCreatingIndicator, editIndicator } = useDatanalisis()
  const [indicator, dispatchIndicator] = useImmerReducer(indicatorReducer, editIndicator ?? DEFAULT_INDICATOR)
  const [openPreview, setOpenPreview] = useState(false)

  const table = useTableModel(indicator)

  const handleResetForm = () => {
    dispatchIndicator(resetForm())
  }

  const handleCloseModal = () => {
    dispatch(toggleIndicatorCreator(false))
    handleResetForm()
  }

  const handleSubmitIndicator = (e) => {
    e.preventDefault()
    setOpenPreview(true)
  }

  useEffect(() => {
    if (editIndicator) {
      dispatchIndicator(handleChangeIndicator(editIndicator))
    }
  }, [dispatchIndicator, editIndicator])

  return (
    isCreatingIndicator && (
      <ClickAwayListener onClickAway={handleCloseModal} mouseEvent="onMouseUp">
        <div className={styles.wrapper}>
          <Tooltip arrow title={locale === 'es' ? 'Cerrar' : 'Close'} onClick={handleCloseModal}>
            <i className={classNames('far fa-circle-xmark', styles.closeButton)} />
          </Tooltip>

          <h2 className={styles.title}>{locale === 'es' ? 'Indicadores' : 'Indicators'}</h2>

          <FormHelper
            title={locale === 'es' ? 'Información general' : 'General information'}
            helperMessage={
              locale === 'es'
                ? 'Seleccione la información general del indicador'
                : 'Select the general information for the indicator'
            }
            className={styles.generalInformationHelper}
          />

          <form className={styles.form} onSubmit={handleSubmitIndicator}>
            <TextFieldWhite
              name="title"
              label={FORM_CONTENT.title}
              value={indicator.title}
              onChange={(e) => dispatchIndicator(handleChange(e))}
            />

            <SelectWhite
              field={{
                label: FORM_CONTENT.indicatorType,
                name: 'type',
              }}
              value={indicator.type}
              options={INDICATOR_TYPES}
              onChange={(e) => dispatchIndicator(handleChange(e))}
            />

            <GraphicSelector indicator={indicator} dispatchIndicator={dispatchIndicator} />

            <SelectWhite
              field={{
                label: FORM_CONTENT.module,
                name: 'table',
              }}
              value={indicator.table}
              options={MODULES}
              onChange={(e) => dispatchIndicator(handleChange(e))}
            />

            {/* Agregar un SELECT para seleccionar el submodulo
             * Tambien hay que cambiar el dispatcher para que, cuando se cambie de modulo, el campo `subTable` quede null
             */}
            {table?.hasSubModules && (
              <SelectWhite
                field={{
                  label: FORM_CONTENT.subTable,
                  name: 'subTable',
                }}
                value={indicator?.subTable ?? ''}
                options={table?.subModules.map((subModule) => ({ value: subModule.subTable, text: subModule[locale] }))}
                onChange={(e) => dispatchIndicator(handleChange(e))}
              />
            )}

            <ValuesSelector indicator={indicator} dispatchIndicator={dispatchIndicator} />

            <div className={styles.buttons}>
              <Button variant="outlined" color="primary" type="button" onClick={handleCloseModal}>
                {locale === 'es' ? 'Cancelar' : 'Cancel'}
              </Button>
              <Button variant="contained" color="primary" type="submit" disabled={!validateIndicator(indicator)}>
                {editIndicator ? (locale === 'es' ? 'Editar' : 'Edit') : locale === 'es' ? 'Agregar' : 'Add'}
              </Button>
            </div>
          </form>

          <IndicatorPreviewer indicator={indicator} open={openPreview} setOpen={setOpenPreview} resetForm={handleResetForm} />
        </div>
      </ClickAwayListener>
    )
  )
}

export default IndicatorCreatorModal
