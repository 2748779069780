import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { TABLE_SPECIFICATION } from '../helpers/modalContent'

const useTableData = (indicator = {}) => {
  const store = useSelector((state) => state)
  const table = TABLE_SPECIFICATION.find((el) => el.table === indicator.table)
  const data = useMemo(() => {
    const { hasSubModules, masterKey, groupingKey } = table

    if (hasSubModules) {
      const { subTable } = indicator

      return store?.[masterKey]?.[subTable]
    }

    return store?.[masterKey]?.[groupingKey]
  }, [table, store, indicator])

  return data
}

export default useTableData
