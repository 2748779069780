import { deleteCalendarAction, selectCalendarAction, selectCalendarActionContent } from 'actions/calendarActions'
import CalendarActionContentModal from 'components/CalendarActionContentModal'
import DataGridTable from 'components/DataGridTable/DataGridTable'
import useLocale from 'hooks/useLocale'
import usePermissions from 'hooks/usePermissions'
import useTableColumnWidth from 'hooks/useTableColumnWidth'
import React, { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { _renderContentType, _renderDate, _renderName } from './helpers'

const MarketingCampaignListTable = ({ calendarActions, setActionsModal }) => {
  const locale = useLocale()
  const dispatch = useDispatch()
  const { pagePermissions } = usePermissions()
  const permissions = pagePermissions(33, 34)
  const canWrite = permissions[1]
  const [getWidth, getFlex] = useTableColumnWidth()
  const [calendarActionContent, setCalendarActionContent] = useState(false)

  const COLUMNS = useMemo(() => {
    return [
      {
        headerName: locale === 'es' ? 'Nombre' : 'Name',
        field: 'name',
        sortable: false,
        isFilterField: true,
        flex: getFlex(),
        width: getWidth(140),
        renderCell: _renderName,
      },
      {
        headerName: locale === 'es' ? 'Tipo de contenido' : 'Content type',
        field: 'created_at',
        flex: getFlex(),
        width: getWidth(140),
        sortable: false,
        renderCell: (value) => _renderContentType({ ...value, locale }),
      },
      {
        headerName: locale === 'es' ? 'Fecha de inicio' : 'Start date',
        field: 'startDate',
        flex: getFlex(),
        width: getWidth(160),
        sortable: false,
        renderCell: _renderDate,
      },
      {
        headerName: locale === 'es' ? 'Fecha de fin' : 'End date',
        field: 'endDate',
        flex: getFlex(),
        width: getWidth(160),
        sortable: false,
        renderCell: _renderDate,
      },
    ]
  }, [locale, getFlex, getWidth])

  const ACTIONS = useMemo(() => {
    return {
      hideBasicActions: !canWrite,
      flex: getFlex(),
      width: getWidth(140),
      field: 'idCalendarAction',
      handlerEdit: ({ selectedObject }) => {
        dispatch(selectCalendarAction(selectedObject))
        setActionsModal(true)
      },
      queryDelete: deleteCalendarAction,
      actions: [
        {
          name: locale === 'es' ? 'Ver' : 'See',
          icon: 'fas fa-eye',
          onClick: ({ selectedObject }) => {
            setCalendarActionContent(true)
            dispatch(selectCalendarActionContent(selectedObject))
          },
        },
      ],
    }
  }, [canWrite, getFlex, getWidth, dispatch, setActionsModal, locale])

  return (
    <>
      <DataGridTable
        data={calendarActions
          .filter((_calendarAction) => !_calendarAction.copy)
          .sort((a, b) => new Date(b.startDate) - new Date(a.startDate))}
        columns={COLUMNS}
        actionsConfig={ACTIONS}
      />

      <CalendarActionContentModal open={calendarActionContent} setOpen={setCalendarActionContent} />
    </>
  )
}

export default MarketingCampaignListTable
