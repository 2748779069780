import { Button } from '@material-ui/core'
import Dialog from 'components/Dialog/Dialog'
import useLocale from 'hooks/useLocale'
import { useRef } from 'react'
import styles from './ReportErrorModal.module.scss'
import getContent from './content'
import useReportErrorModal from './useReportErrorModal'

const ReportErrorModal = ({ open, setOpen }) => {
  const locale = useLocale()
  const content = getContent(locale)
  const inputFileRef = useRef()

  const {
    states: { uploadedFiles },
    handlers: { onChangeFiles, onChangeDescription, onSubmit, onClose },
  } = useReportErrorModal(open, setOpen)

  return (
    <Dialog open={open} setOpen={setOpen} handleCloseAction={onClose} rootClassName={styles.wrapper}>
      <header className={styles.header}>
        <h5 className={styles.title}>{content?.title}</h5>
      </header>

      <section className={styles.modalContainer}>
        <form className={styles.form}>
          <textarea
            onChange={onChangeDescription}
            placeholder={content?.descriptionPlaceholder}
            className={styles.textarea}
            fullWidth
            variant="outlined"
          />

          <div className={styles.attachFilesContainer}>
            {uploadedFiles?.length ? (
              <div className={styles.attachFilesList}>
                {uploadedFiles?.map((_file) => (
                  <div className={styles.searchFilesText}>{_file?.name}</div>
                ))}
              </div>
            ) : (
              <span className={styles.searchFilesText}>{content?.attachFiles}</span>
            )}

            <Button
              disableElevation
              onClick={() => inputFileRef?.current?.click()}
              className={styles.searchFilesButton}
              variant="contained"
              color="primary"
            >
              {content?.searchButton}
            </Button>

            <input multiple onChange={onChangeFiles} type="file" ref={inputFileRef} className={styles.fileInputHidden} />
          </div>

          <Button onClick={onSubmit} disableElevation className={styles.sendButton} variant="contained" color="primary">
            {content?.sendButton}
          </Button>
        </form>
      </section>
    </Dialog>
  )
}

export default ReportErrorModal
