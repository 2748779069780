import { DEFAULT_FB_PAGE_CONFIG } from 'constants/configs'
import { getConfigValue } from 'helpers/configs'
import useConfigs from './useConfigs'
import useSocialMedia from './useSocialMedia'

const useFacebookConfigs = () => {
  const { fbPages } = useSocialMedia()
  const { configs } = useConfigs()

  const fbPageSelected = getConfigValue(DEFAULT_FB_PAGE_CONFIG, configs)?.value

  return {
    fbPages,
    fbPageSelected,
  }
}

export default useFacebookConfigs
