import { DELETE_AI_BLOCKS, GENERATE_TEXT_AI_BLOCKS, GET_AI_BLOCKS, SET_AI_BLOCKS, UPDATE_AI_BLOCKS } from 'constants/actionTypes'
import { AIBLOCKS_API_ROUTE } from 'constants/apiRoutes'
import { apiDeleteData, apiFindData, apiPostData, apiPutData } from 'helpers/api'

export const getAiBlocks = (idCompany) => apiFindData(AIBLOCKS_API_ROUTE, { id: idCompany || 0 }, GET_AI_BLOCKS)

export const addNewAiBlock = (aiBlock) => apiPostData(AIBLOCKS_API_ROUTE, aiBlock, SET_AI_BLOCKS)

export const updateAiBlock = (aiBlock) => apiPutData(AIBLOCKS_API_ROUTE, { id: aiBlock?.idAiBlock, ...aiBlock }, UPDATE_AI_BLOCKS)

export const deleteAiBlock = (idAiBlock) => apiDeleteData(AIBLOCKS_API_ROUTE, { id: idAiBlock }, DELETE_AI_BLOCKS)

export const generateTextAiBlock = (data) => apiPostData(`${AIBLOCKS_API_ROUTE}/generate`, data, GENERATE_TEXT_AI_BLOCKS)
