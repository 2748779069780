import { closeModal, nextPageStepperModal, sendErrorMessage, updateStepperModalData } from 'actions/modals'
import { registerUserWithCompany } from 'actions/users'
import Form from 'components/Form/Form'
import ERROR_MESSAGES from 'constants/errorMessages'
import { DASHBOARD_PAGE_URL } from 'constants/urls'
import useLocale from 'hooks/useLocale'
import useModals from 'hooks/useModals'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import getContent from './content'
import styles from './registryModal.module.scss'
import { STEPS_CONFIG } from './stepsConfig'
import { invalidStepData, validPasswords } from './validators'

const StepContainer = ({ stepKey, icon, modalFormConfig, lastStep }) => {
  const locale = useLocale()
  const content = getContent(locale)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { stepperModalData } = useModals()
  const stepData = stepperModalData?.[stepKey]

  const onSubmitNextStep = useCallback(
    (stepResponse, setIsLoadingData) => {
      setIsLoadingData(false)
      if (invalidStepData(stepResponse)) return dispatch(sendErrorMessage(ERROR_MESSAGES[locale].emptyData))

      dispatch(updateStepperModalData({ ...stepperModalData, [stepKey]: stepResponse }))
      dispatch(nextPageStepperModal())
    },
    [dispatch, stepperModalData, stepKey, locale]
  )

  const onSubmitCreateUser = useCallback(
    async (stepResponse, setIsLoadingData) => {
      const { password, repeatPassword } = stepResponse

      const isValidPasswords = validPasswords(password, repeatPassword)

      if (!isValidPasswords.valid) {
        dispatch(sendErrorMessage(ERROR_MESSAGES[locale]?.[isValidPasswords.errorMessageType]))
        return setIsLoadingData(false)
      }

      setIsLoadingData(true)

      const registerRes = await registerUserWithCompany({ ...(stepperModalData || {}), password })

      if (registerRes.error) {
        setIsLoadingData(false)

        return dispatch(sendErrorMessage(ERROR_MESSAGES[locale]?.[registerRes.errorMessageType]))
      }

      setIsLoadingData(false)
      dispatch(closeModal())
      navigate(`/${DASHBOARD_PAGE_URL}`)
    },
    [dispatch, locale, stepperModalData, navigate]
  )

  return (
    <div className={styles.stepContainer}>
      <div className={styles.stepTitle}>
        <i className={icon} />
        <span>{content.stepTitles?.[stepKey]}</span>
      </div>
      <span className={styles.stepDescription}>{content.stepDescriptions?.[stepKey] ?? ''}</span>
      <Form
        modalFormConfig={modalFormConfig(content, stepData)}
        className={styles.form}
        customOnSubmit={lastStep === stepKey ? onSubmitCreateUser : onSubmitNextStep}
      />
    </div>
  )
}

const RegistryUserSteps = STEPS_CONFIG.map((stepConfig) => (
  <StepContainer {...stepConfig} lastStep={`step${STEPS_CONFIG.length}`} />
))

export { RegistryUserSteps }
