import { flattenRoutes, getProps } from 'routes'
import {
  AI_BLOCKS_PAGE_URL,
  AI_TEXT_GENERATOR_PAGE_URL,
  BOARDS_PAGE_URL,
  CALENDAR_PAGE_URL,
  CASH_FLOW_URL,
  CLIENT_FORMS,
  COMPANY_FORM_CONFIG,
  COSTS_PAGE_URL,
  CUSTOMERS_PAGE_URL,
  DASHBOARD_PAGE_URL,
  DATANALISIS_URL,
  GOOGLE_DRIVE_PAGE,
  HISTORIC_PAGE_URL,
  HUMAN_RESOURCES_URL,
  IMAGES_BANK_URL,
  IMAGES_EDITOR_URL,
  INVENTORY_PAGE_URL,
  MARKETING_CAMPAIGNS,
  MESSAGES_PAGE_URL,
  PERSONAL_FINANCES_URL,
  POSTS_PAGE_URL,
  PROCESSES_PAGE,
  PROJECTS_PAGE_URL,
  QUOTATIONS_URL,
  REPORT_ERRORS_PAGE_URL,
  TOOLS_CONFIG_PAGE_URL,
  TOOLS_PAGE_URL,
  USERS_PAGE_URL,
} from './urls'

const DEFAULT_OPTIONS_ICON = 'fa fa-angle-right horizontal-icon'

const getHeaderItems = (locale) => {
  const headerLinks = [
    { icon: 'fas fa-home', name: locale === 'es' ? 'Inicio' : 'Home', link: `/${DASHBOARD_PAGE_URL}` },
    {
      icon: 'fas fa-photo-video',
      name: 'Social Media',
      options: [
        {
          icon: 'fas fa-calendar-alt',
          name: locale === 'es' ? 'Calendario' : 'Calendar',
          link: `/${DASHBOARD_PAGE_URL}/${CALENDAR_PAGE_URL}`,
          hideIf: ({ permissions }) => !(permissions[1] || permissions[2]),
          baseUrl: `/${CALENDAR_PAGE_URL}`,
        },
        {
          icon: 'fas fa-clone',
          name: locale === 'es' ? 'Publicaciones' : 'Posts',
          link: `/${DASHBOARD_PAGE_URL}/${POSTS_PAGE_URL}`,
          hideIf: ({ permissions }) => !(permissions[3] || permissions[4]),
          baseUrl: `/${POSTS_PAGE_URL}`,
        },
        {
          icon: 'fas fa-envelope-open-text',
          name: locale === 'es' ? 'Correos Masivos' : 'Mass Emails',
          link: `/${DASHBOARD_PAGE_URL}/${MESSAGES_PAGE_URL}`,
          hideIf: ({ permissions }) => !(permissions[5] || permissions[6]),
          baseUrl: `/${MESSAGES_PAGE_URL}`,
        },
        {
          icon: 'fas fa-poll-h',
          name: locale === 'es' ? 'Formularios' : 'Forms',
          link: `/${DASHBOARD_PAGE_URL}/${CLIENT_FORMS}`,
          hideIf: ({ permissions }) => !(permissions[7] || permissions[8]),
          baseUrl: `/${CLIENT_FORMS}`,
        },
        {
          icon: 'fas fa-bullhorn',
          name: locale === 'es' ? 'Campañas de Marketing' : 'Marketing Campaigns',
          link: `/${DASHBOARD_PAGE_URL}/${MARKETING_CAMPAIGNS}`,
          hideIf: ({ permissions }) => !(permissions[33] || permissions[34]),
          baseUrl: `/${MARKETING_CAMPAIGNS}`,
        },
        {
          icon: 'fas fa-image',
          name: locale === 'es' ? 'Editor de imagen' : 'Image editor',
          link: `/${IMAGES_EDITOR_URL}`,
          hideIf: ({ permissions }) => !(permissions[39] || permissions[40]),
        },
        {
          icon: 'fa-solid fa-image',
          name: locale === 'es' ? 'Banco de imágenes' : 'Images bank',
          link: `/${DASHBOARD_PAGE_URL}/${IMAGES_BANK_URL}`,
          hideIf: ({ permissions }) => !(permissions[35] || permissions[36]),
          baseUrl: `/${IMAGES_BANK_URL}`,
        },
        {
          icon: 'fa-regular fa-file-lines',
          name: locale === 'es' ? 'Generador de Texto AI' : 'AI Text Generator',
          link: `${AI_TEXT_GENERATOR_PAGE_URL}`,
          baseUrl: `/${AI_TEXT_GENERATOR_PAGE_URL}`,
        },
      ],
      baseUrl: '/social-media',
      hideIf: ({ permissions }) =>
        !(permissions[1] || permissions[2]) &&
        !(permissions[3] || permissions[4]) &&
        !(permissions[5] || permissions[6]) &&
        !(permissions[7] || permissions[8]) &&
        !(permissions[33] || permissions[34]) &&
        !(permissions[39] || permissions[40]),
    },
    {
      icon: 'fas fa-users',
      name: locale === 'es' ? 'Clientes' : 'Clients',
      link: `/${DASHBOARD_PAGE_URL}/${CUSTOMERS_PAGE_URL}/customers`,
      hideIf: ({ permissions }) => !(permissions[9] || permissions[10]),
      baseUrl: '/customers',
    },
    {
      icon: 'fas fa-hand-holding-usd',
      name: locale === 'es' ? 'Finanzas' : 'Finances',
      options: [
        {
          icon: 'fas fa-project-diagram',
          name: locale === 'es' ? 'Ingresos y Gastos' : 'Income and Expenses',
          link: `/${DASHBOARD_PAGE_URL}/${PROJECTS_PAGE_URL}`,
          hideIf: ({ permissions }) => !(permissions[11] || permissions[12]),
          baseUrl: `${PROJECTS_PAGE_URL}`,
        },
        {
          icon: 'fas fa-truck-loading',
          name: locale === 'es' ? 'Costos' : 'Costs',
          link: `/${DASHBOARD_PAGE_URL}/${COSTS_PAGE_URL}/products`,
          hideIf: ({ permissions }) => !(permissions[13] || permissions[14]),
          baseUrl: `${COSTS_PAGE_URL}`,
        },
        {
          icon: 'fa-solid fa-wallet',
          name: locale === 'es' ? 'Gastos personales' : 'Personal finances',
          link: `/${PERSONAL_FINANCES_URL}`,
          hideIf: ({ permissions }) => !(permissions[37] || permissions[38]),
        },
        {
          icon: 'fa-solid fa-cash-register',
          name: locale === 'es' ? 'Flujo de caja' : 'Cash flow',
          link: `/${DASHBOARD_PAGE_URL}/${CASH_FLOW_URL}`,
          hideIf: ({ permissions }) => !(permissions[41] || permissions[42]),
          baseUrl: `${CASH_FLOW_URL}`,
        },
        {
          icon: 'fa-solid fa-file-lines',
          name: locale === 'es' ? 'Cotizaciones' : 'Quotations',
          link: `/${DASHBOARD_PAGE_URL}/${QUOTATIONS_URL}`,
          hideIf: ({ permissions }) => !(permissions[43] || permissions[44]),
          baseUrl: `${QUOTATIONS_URL}`,
        },
      ],
      baseUrl: '/finances',
      hideIf: ({ permissions }) =>
        !(permissions[11] || permissions[12]) && !(permissions[13] || permissions[14]) && !(permissions[41] || permissions[42]),
    },
    {
      icon: 'fas fa-cogs',
      name: locale === 'es' ? 'Gestiones' : 'Management',
      options: [
        {
          icon: 'fab fa-google',
          name: 'Google Drive',
          link: `/${DASHBOARD_PAGE_URL}/${GOOGLE_DRIVE_PAGE}`,
          hideIf: ({ permissions }) => !(permissions[47] || permissions[48]),
          baseUrl: `${GOOGLE_DRIVE_PAGE}`,
        },
        {
          icon: 'fas fa-chalkboard-user',
          name: locale === 'es' ? 'Procesos' : 'Process',
          link: `/${DASHBOARD_PAGE_URL}/${PROCESSES_PAGE}`,
          hideIf: ({ permissions }) => !(permissions[15] || permissions[16]),
          baseUrl: `${PROCESSES_PAGE}`,
        },
        {
          icon: 'fas fa-wrench',
          name: locale === 'es' ? 'Herramientas' : 'Tools',
          link: `/${DASHBOARD_PAGE_URL}/${TOOLS_PAGE_URL}`,
          hideIf: ({ permissions }) => !(permissions[17] || permissions[18]),
          baseUrl: `${TOOLS_PAGE_URL}`,
        },
        {
          icon: 'fas fa-users-cog',
          name: locale === 'es' ? 'Recursos Humanos' : 'Human Resources',
          link: `/${DASHBOARD_PAGE_URL}/${HUMAN_RESOURCES_URL}/departments`,
          hideIf: ({ permissions }) => !(permissions[25] || permissions[26]),
          baseUrl: `${HUMAN_RESOURCES_URL}`,
        },
        {
          icon: 'fa-solid fa-clipboard-list',
          name: locale === 'es' ? 'Espacios de Trabajo' : 'Work Spaces',
          link: `/${DASHBOARD_PAGE_URL}/${BOARDS_PAGE_URL}`,
          hideIf: ({ permissions }) => !(permissions[27] || permissions[28]),
          baseUrl: `${BOARDS_PAGE_URL}`,
        },
        {
          icon: 'fa-solid fa-boxes-stacked',
          name: locale === 'es' ? 'Inventario' : 'Inventory',
          link: `/${DASHBOARD_PAGE_URL}/${INVENTORY_PAGE_URL}`,
          hideIf: ({ permissions }) => !(permissions[31] || permissions[32]),
          baseUrl: `${INVENTORY_PAGE_URL}`,
        },
        {
          icon: 'fa-solid fa-chart-pie',
          name: locale === 'es' ? 'Análisis de Datos' : 'Datanalysis',
          link: `/${DATANALISIS_URL}`,
          hideIf: ({ permissions }) => !(permissions[45] || permissions[4632]),
        },
      ],
      baseUrl: '/managment',
      hideIf: ({ permissions }) =>
        !(permissions[15] || permissions[16]) &&
        !(permissions[17] || permissions[18]) &&
        !(permissions[25] || permissions[26]) &&
        !(permissions[27] || permissions[28]) &&
        !(permissions[31] || permissions[32]) &&
        !(permissions[45] || permissions[46]) &&
        !(permissions[47] || permissions[48]),
    },
    {
      icon: 'fas fa-history',
      name: locale === 'es' ? 'Historial' : 'History',
      link: `/${DASHBOARD_PAGE_URL}/${HISTORIC_PAGE_URL}`,
      hideIf: ({ permissions }) => !(permissions[19] || permissions[20]),
      baseUrl: `/${HISTORIC_PAGE_URL}`,
    },
    {
      icon: 'fas fa-user-shield',
      name: 'Admin',
      options: [
        {
          icon: DEFAULT_OPTIONS_ICON,
          name: locale === 'es' ? 'Usuarios' : 'Users',
          link: `/${DASHBOARD_PAGE_URL}/${USERS_PAGE_URL}`,
          baseUrl: `${USERS_PAGE_URL}`,
        },
        {
          icon: DEFAULT_OPTIONS_ICON,
          name: locale === 'es' ? 'Herramientas' : 'Tools',
          link: `/${DASHBOARD_PAGE_URL}/${TOOLS_CONFIG_PAGE_URL}`,
          baseUrl: `${TOOLS_CONFIG_PAGE_URL}`,
        },
        {
          icon: DEFAULT_OPTIONS_ICON,
          name: locale === 'es' ? 'Formulario Empresa' : 'Company Form',
          link: `/${DASHBOARD_PAGE_URL}/${COMPANY_FORM_CONFIG}`,
          baseUrl: `${COMPANY_FORM_CONFIG}`,
        },
        {
          icon: DEFAULT_OPTIONS_ICON,
          name: locale === 'es' ? 'Reporte de errores' : 'Error reports',
          link: `/${DASHBOARD_PAGE_URL}/${REPORT_ERRORS_PAGE_URL}`,
          baseUrl: `${REPORT_ERRORS_PAGE_URL}`,
        },
        {
          icon: DEFAULT_OPTIONS_ICON,
          name: locale === 'es' ? 'Generador de Texto AI' : 'AI Text Generator',
          link: `/${DASHBOARD_PAGE_URL}/${AI_BLOCKS_PAGE_URL}`,
          baseUrl: `${AI_BLOCKS_PAGE_URL}`,
        },
      ],
      baseUrl: '/admin',
      hideIf: ({ type }) => !type || type === 2,
    },
  ]

  const result = headerLinks.map((el) => {
    if (el.options) {
      return {
        ...el,
        options: getProps(el.options),
      }
    }

    const { preloadComponent = null } = flattenRoutes.find((obj) => el.link === `/${DASHBOARD_PAGE_URL}/${obj.path}`) ?? {}

    return {
      ...el,
      preloadComponent,
    }
  })

  return result
}

export default getHeaderItems
