import {
  GET_INVENTORY_CATEGORIES,
  UPLOAD_INVENTORY_CATEGORY,
  UPDATE_INVENTORY_CATEGORY,
  DELETE_INVENTORY_CATEGORY,
  GET_INVENTORY_PROVIDERS,
  UPLOAD_INVENTORY_PROVIDER,
  DELETE_INVENTORY_PROVIDER,
  UPDATE_INVENTORY_PROVIDER,
  GET_INVENTORY_PRODUCTS,
  UPLOAD_INVENTORY_PRODUCT,
  UPDATE_INVENTORY_PRODUCT,
  DELETE_INVENTORY_PRODUCT,
  GET_INVENTORY_TRANSACTIONS,
  UPLOAD_INVENTORY_TRANSACTION,
  UPDATE_INVENTORY_TRANSACTION,
  DELETE_INVENTORY_TRANSACTION,
} from 'constants/actionTypes'

import { apiPostData, apiPutData, apiDeleteData, apiFindData } from 'helpers/api'
import {
  INVENTORY_CATEGORIES_ROUTE,
  INVENTORY_PROVIDERS_ROUTE,
  INVENTORY_PRODUCTS_ROUTE,
  INVENTORY_TRANSACTIONS_ROUTE,
} from 'constants/apiRoutes'

export const getInventoryCategories = (idCompany) =>
  apiFindData(INVENTORY_CATEGORIES_ROUTE, { id: idCompany }, GET_INVENTORY_CATEGORIES)

export const postInventoryCategory = (inventoryCategory) =>
  apiPostData(INVENTORY_CATEGORIES_ROUTE, inventoryCategory, UPLOAD_INVENTORY_CATEGORY)

export const updateInventoryCategory = (inventoryCategory) =>
  apiPutData(
    INVENTORY_CATEGORIES_ROUTE,
    { id: inventoryCategory?.idInventoryCategory, ...inventoryCategory },
    UPDATE_INVENTORY_CATEGORY
  )

export const deleteInventoryCategory = (idInventoryCategory) =>
  apiDeleteData(INVENTORY_CATEGORIES_ROUTE, { id: idInventoryCategory }, DELETE_INVENTORY_CATEGORY)

export const getInventoryProviders = (idCompany) =>
  apiFindData(INVENTORY_PROVIDERS_ROUTE, { id: idCompany }, GET_INVENTORY_PROVIDERS)

export const postInventoryProvider = (inventoryProvider) =>
  apiPostData(INVENTORY_PROVIDERS_ROUTE, inventoryProvider, UPLOAD_INVENTORY_PROVIDER)

export const updateInventoryProvider = (inventoryProvider) =>
  apiPutData(
    INVENTORY_PROVIDERS_ROUTE,
    { id: inventoryProvider?.idInventoryProvider, ...inventoryProvider },
    UPDATE_INVENTORY_PROVIDER
  )

export const deleteInventoryProvider = (idInventoryProvider) =>
  apiDeleteData(INVENTORY_PROVIDERS_ROUTE, { id: idInventoryProvider }, DELETE_INVENTORY_PROVIDER)

export const getInventoryProducts = (idCompany) =>
  apiFindData(INVENTORY_PRODUCTS_ROUTE, { id: idCompany }, GET_INVENTORY_PRODUCTS)

export const postInventoryProduct = (inventoryProduct) =>
  apiPostData(INVENTORY_PRODUCTS_ROUTE, inventoryProduct, UPLOAD_INVENTORY_PRODUCT)

export const updateInventoryProduct = (inventoryProduct) =>
  apiPutData(
    INVENTORY_PRODUCTS_ROUTE,
    { id: inventoryProduct?.idInventoryProduct, ...inventoryProduct },
    UPDATE_INVENTORY_PRODUCT
  )

export const deleteInventoryProduct = (idInventoryProduct) =>
  apiDeleteData(INVENTORY_PRODUCTS_ROUTE, { id: idInventoryProduct }, DELETE_INVENTORY_PRODUCT)

export const getInventoryTransactions = (idCompany) =>
  apiFindData(INVENTORY_TRANSACTIONS_ROUTE, { id: idCompany }, GET_INVENTORY_TRANSACTIONS)

export const postInventoryTransaction = (inventoryTransaction) =>
  apiPostData(INVENTORY_TRANSACTIONS_ROUTE, inventoryTransaction, UPLOAD_INVENTORY_TRANSACTION)

export const updateInventoryTransaction = (inventoryTransaction) =>
  apiPutData(
    INVENTORY_TRANSACTIONS_ROUTE,
    { id: inventoryTransaction?.idInventoryTransaction, ...inventoryTransaction },
    UPDATE_INVENTORY_TRANSACTION
  )

export const deleteInventoryTransaction = (idInventoryTransaction) =>
  apiDeleteData(INVENTORY_TRANSACTIONS_ROUTE, { id: idInventoryTransaction }, DELETE_INVENTORY_TRANSACTION)
