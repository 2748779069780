import { Button } from '@material-ui/core'
import Dialog from 'components/Dialog/Dialog'
import LoadingSection from 'components/LoadingSection'
import useLocale from 'hooks/useLocale'
import React, { useEffect, useState } from 'react'
import styles from './FilePreviewer.module.scss'

const FilePreviewer = ({ open, handleClose, file }) => {
  const locale = useLocale()
  const [loading, setLoading] = useState(true)
  const [fileObj, setFileObj] = useState(null)

  const { type, url, fileName } = fileObj ?? {}

  const renderPreview = () => {
    if (type?.includes('image')) {
      return (
        <div className={styles.noPreview}>
          <img src={url} alt={fileName} />
          <span className={styles.helper}>{fileName}</span>
        </div>
      )
    } else if (type?.includes('pdf')) {
      return <embed src={url} width="100%" height="99%" />
    } else if (type?.includes('video')) {
      return (
        <div className={styles.noPreview}>
          <video src={url} controls />
          <span className={styles.helper}>{fileName}</span>
        </div>
      )
    }

    return (
      <div className={styles.noPreview}>
        {loading ? (
          <LoadingSection />
        ) : (
          <>
            <p>{locale === 'es' ? 'No se puede previsualizar este archivo' : 'Cannot preview this file'}</p>
            <Button
              onClick={() => window.open(file)}
              variant="contained"
              color="primary"
              endIcon={<i className="fas fa-download" />}
            >
              {locale === 'es' ? 'Descargar' : 'Download'}
            </Button>
          </>
        )}
      </div>
    )
  }

  useEffect(() => {
    const fetch = async () => {
      setLoading(true)
      if (open) {
        const response = await window.fetch(file)
        const blob = await response.blob()
        const url = URL.createObjectURL(blob)
        const fileName = new URLSearchParams(response.url.split('?')[1]).get('filename')
        const type = response.headers.get('content-type')

        setFileObj({ url, fileName, type })
      }
      setLoading(false)
    }

    if (file) fetch()
    else setFileObj(null)
  }, [file, open])

  return (
    <Dialog
      maxWidth="lg"
      open={open}
      handleCloseAction={handleClose}
      title={locale === 'es' ? 'Previsualizar' : 'Preview'}
      rootClassName={styles.root}
    >
      <section className={styles.wrapper}>{renderPreview()}</section>
    </Dialog>
  )
}

export default FilePreviewer
