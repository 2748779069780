import { reduceByKey } from 'helpers/arrays'

const _parseSalaries = (yearPayments) => {
  const baseSalary = reduceByKey(yearPayments, 'basePayment')

  return baseSalary ?? 0
}

const _parseBonus = (payment) => {
  return payment / 12
}

export { _parseSalaries, _parseBonus }
