import { toggleIndicatorCreator } from 'actions/datanalisis'
import ButtonIcon from 'components/common/ButtonIcon'
import LoadingSnackbar from 'components/LoadingSnackbar/LoadingSnackbar'
import useLocale from 'hooks/useLocale'
import { useDispatch } from 'react-redux'
import styles from './EmptyIndicators.module.scss'

const EmptyIndicators = () => {
  const dispatch = useDispatch()
  const locale = useLocale()
  // const [loading, setLoading] = useState(false)

  return (
    <div className={styles.wrapper}>
      <figure className={styles.figure}>
        <i className="fa-solid fa-face-sad-tear" />
      </figure>

      <p className={styles.helper}>{locale === 'es' ? '¡No has creado ningún indicador!' : "You don't have any indicator!"}</p>

      <ButtonIcon
        icon={<i className="fas fa-plus-circle" />}
        variant="contained"
        color="primary"
        onClick={() => dispatch(toggleIndicatorCreator(true))}
      >
        {locale === 'es' ? 'Crear indicador' : 'Create indicator'}
      </ButtonIcon>

      <LoadingSnackbar open={false} message={locale === 'es' ? 'Agregando indicador' : 'Adding indicator'} />
    </div>
  )
}

export default EmptyIndicators
