import { CircularProgress, Tooltip } from '@material-ui/core'
import { deleteWorkDayBreak } from 'actions/humanResources'
import { formatDate } from 'helpers/date'
import useLocale from 'hooks/useLocale'
import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import styles from './index.module.scss'

const Item = ({ el, i, setter }) => {
  const dispatch = useDispatch()
  const locale = useLocale()

  const [loading, setLoading] = useState(false)

  const handleDeleteBreak = useCallback(async () => {
    setLoading(true)
    dispatch(await deleteWorkDayBreak(el?.idWorkDayBreak))
    setter((prev) => prev.filter((item) => item?.idWorkDayBreak !== el?.idWorkDayBreak))
    setLoading(false)
  }, [el?.idWorkDayBreak, setter, dispatch])

  return (
    <article className={styles.article}>
      <div>
        <strong>{locale === 'es' ? `Descanso #${++i}` : `Break #${i}`}:</strong>
        <span>
          {' '}
          {formatDate(el.startTime, 'HH:mm:ss', true)} - {formatDate(el.endTime, 'HH:mm:ss', true)}
        </span>
      </div>
      {loading ? (
        <CircularProgress />
      ) : (
        <Tooltip title={locale === 'es' ? 'Eliminar' : 'Delete'} onClick={handleDeleteBreak}>
          <i className="fas fa-trash-alt" />
        </Tooltip>
      )}
    </article>
  )
}

const BreaksShowcase = ({ data }) => {
  const [breaks, setBreaks] = useState(data ?? [])

  return (
    <section className={styles.breakShowcase}>
      {breaks?.map((el, i) => (
        <Item el={el} i={i} key={el?.idWorkDayBreak} setter={setBreaks} />
      ))}
    </section>
  )
}

export default BreaksShowcase
