import DateFnsUtils from '@date-io/date-fns'
import { LinearProgress } from '@material-ui/core'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { enUS, es } from 'date-fns/locale'
import useLocale from 'hooks/useLocale'
import React, { Suspense, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { BrowserRouter, useRoutes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import routes from 'routes'
import configureStore from 'store/configureStore'
import 'styles/CKEditorOverride.css'
import './index.scss'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

const queryClient = new QueryClient()

const INITIAL_STATE = {}

serviceWorkerRegistration.register()

const theme = createTheme({
  palette: {
    primary: {
      main: '#507938',
      light: '#7ea864',
      dark: '#244d0e',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#ff325e',
      light: '#ff6f8b',
      dark: '#c50035',
      contrastText: '#000000',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        padding: '16px',
        borderRadius: '6px',
      },
      label: {
        gap: '8px',
        alignContent: 'center',
      },
      outlined: {
        borderWidth: '2px !important',
      },
    },
  },
})

export const Fallback = () => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    let timeout = setTimeout(() => setShow(true), 300)
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return <>{show && <LinearProgress />}</>
}

const Routing = () => useRoutes(routes)
const store = configureStore(INITIAL_STATE)

const App = () => {
  const locale = useLocale()

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <MuiPickersUtilsProvider locale={locale === 'es' ? es : enUS} utils={DateFnsUtils}>
            <MuiThemeProvider theme={theme}>
              <BrowserRouter>
                <Suspense fallback={<Fallback />}>
                  <Routing />
                </Suspense>
              </BrowserRouter>
            </MuiThemeProvider>
          </MuiPickersUtilsProvider>
        </GoogleOAuthProvider>
      </QueryClientProvider>
    </>
  )
}

ReactDOM.render(
  <Provider store={store}>
    <App />
    <ToastContainer position="bottom-right" autoClose={false} />
  </Provider>,
  document.getElementById('root')
)
