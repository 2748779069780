import { useState } from 'react'

export const useFilePermissionsModal = (files, folders) => {
  const [open, setOpen] = useState(false)
  const [selectedId, setSelectedId] = useState(null)
  const selectedResource = files?.find((file) => file.id === selectedId) || folders?.find((folder) => folder.id === selectedId)

  const handleOpen = (id) => {
    setOpen(true)
    setSelectedId(id)
  }

  const handleClose = () => {
    setOpen(false)
    setTimeout(() => setSelectedId(null), 300)
  }

  return { open, selectedResource, handleOpen, handleClose }
}
