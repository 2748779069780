import classNames from 'classnames'
import { Footer } from 'components/Footer/Footer'
import MissingPage from 'components/MissingPage'
import usePermissions from 'hooks/usePermissions'
import PropTypes from 'prop-types'
import React, { forwardRef } from 'react'
import { Helmet } from 'react-helmet'
import styles from './page.module.scss'

const Page = forwardRef(({ children, title = '', fullWidth, read = 0, write = 0, containerClassName, ...rest }, ref) => {
  const { pagePermissions } = usePermissions()
  const [readPermission, writePermission] = pagePermissions(read, write)

  return (
    <div className={styles.pageRoot} ref={ref} {...rest}>
      <Helmet>
        <title>{`${title} - Digital Pymes`}</title>
      </Helmet>
      {writePermission || readPermission ? (
        fullWidth ? (
          children
        ) : (
          <div className={styles.pageWrapper}>
            <div className={classNames(styles.pageContainer, containerClassName)}>{children}</div>
            <Footer />
          </div>
        )
      ) : (
        <MissingPage />
      )}
    </div>
  )
})

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
}

export default Page
