import CrudPanel from 'components/CrudPanel/CrudPanel'
import useLocale from 'hooks/useLocale'
import useMoney from 'hooks/useMoney'
import PreviewStub from 'pages/HumanResourcesPage/Tabs/Employees/StubsModal/PreviewStub/PreviewStub'
import RecurrentStub from 'pages/HumanResourcesPage/Tabs/Employees/StubsModal/RecurrentStub'
import StubModal from 'pages/HumanResourcesPage/Tabs/Employees/StubsModal/StubModal/StubModal'
import StubsTable from 'pages/HumanResourcesPage/Tabs/Employees/StubsModal/StubsTable/StubsTable'
import useStub from 'pages/HumanResourcesPage/Tabs/Employees/StubsModal/hooks/useStub'
import React, { useCallback, useState } from 'react'
import Section from '../Section'
import StubsTotals from './StubsTotals'

const PaymentStubs = ({ employee }) => {
  const locale = useLocale()
  const { idCompany } = employee
  const { parseValue } = useMoney()
  const { defaultWorkedHors, defaultHourlyRate, defaultExtraHourlyRate, defaultBasePayment, deductions } = useStub(employee)

  const [stubModal, setStubModal] = useState(false)
  const [previewStub, setPreviewStub] = useState(false)
  const [selectedStub, setSelectedStub] = useState({})
  const [selectedStubId, setSelectedStubId] = useState(null)

  const calculateDefaultStub = useCallback(
    (type, hours = null) => {
      const totalHours = hours ?? defaultWorkedHors[type]

      return {
        idCompany,
        idEmployee: employee?.idEmployee,
        type,
        workedHours: totalHours,
        hourlyRate: parseValue(defaultHourlyRate),
        extraHourlyRate: parseValue(defaultExtraHourlyRate),
        deductions: deductions(totalHours)[type],
        basePayment: defaultBasePayment(type),
        totalPayment: defaultBasePayment(type) - deductions(totalHours)[type],
        extraHoursPayment: 0,
        reference: '',
      }
    },
    [
      defaultWorkedHors,
      defaultHourlyRate,
      defaultExtraHourlyRate,
      defaultBasePayment,
      deductions,
      idCompany,
      employee,
      parseValue,
    ]
  )

  const handleSelectStub = useCallback((obj) => {
    setSelectedStubId(obj?.idPaymentStub ?? null)
    setStubModal(true)
  }, [])

  const handlePreview = useCallback((obj) => {
    setSelectedStub(obj)
    setPreviewStub(true)
  }, [])

  const handleCloseStubModal = useCallback(() => {
    setStubModal(false)
    setSelectedStubId(null)
  }, [])

  const handleClosePreview = useCallback(() => {
    setPreviewStub(false)
    setTimeout(() => setSelectedStub({}), 150)
  }, [])

  return (
    <Section title={locale === 'es' ? 'Colillas de Pago' : 'Payment stubs'}>
      <StubsTotals employee={employee} />
      <RecurrentStub employee={employee} />

      <CrudPanel defaultName={locale === 'es' ? 'colilla de pago' : 'payment stub'} onClick={handleSelectStub} canWrite />

      <StubsTable
        data={employee?.stubs.sort((a, b) => new Date(b.date) - new Date(a.date)) ?? []}
        handleSelect={handleSelectStub}
        handlePreview={handlePreview}
        currency={employee?.currency}
      />

      <StubModal
        open={stubModal}
        setOpen={setStubModal}
        handleCloseAction={handleCloseStubModal}
        employee={employee}
        stub={selectedStub}
        setter={setSelectedStub}
        selectedId={selectedStubId}
        findStub={(id) => employee?.stubs.find((stub) => stub.idPaymentStub === id)}
        defaultStub={calculateDefaultStub(1)}
        calculateDefaultStub={calculateDefaultStub}
      />

      <PreviewStub
        open={previewStub}
        setOpen={setPreviewStub}
        handleClose={handleClosePreview}
        stub={selectedStub}
        employee={employee}
        canWrite
      />
    </Section>
  )
}

export default PaymentStubs
