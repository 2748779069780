import { ListItemIcon, MenuItem } from '@material-ui/core'
import useLocale from 'hooks/useLocale'
import React from 'react'
import { useContextMenu } from './useContextMenu'

function TrashedOptions({ styles, id, config }) {
  const { restoreFile, deletePermamently } = useContextMenu(id, config)
  const locale = useLocale()

  return (
    <>
      <MenuItem onClick={restoreFile}>
        <ListItemIcon classes={{ root: styles.menuIcon }}>
          <i className="fas fa-clock-rotate-left" />
        </ListItemIcon>

        {locale === 'es' ? 'Restaurar' : 'Restore'}
      </MenuItem>
      <MenuItem onClick={deletePermamently}>
        <ListItemIcon classes={{ root: styles.menuIcon }}>
          <i className="fas fa-trash-alt" />
        </ListItemIcon>

        {locale === 'es' ? 'Eliminar permanentemente' : 'Delete permanently'}
      </MenuItem>
    </>
  )
}

export default TrashedOptions
