import React, { useCallback } from 'react'
import styles from '../CalendarPage.module.scss'
import useLocale from 'hooks/useLocale'
import { createCalendar } from '../createCalendar'
import { getFullWeekDays } from '../CalendarPage'
import CalendarActionsFilters from '../CalendarActionsFilters'
import Calendar from '../Calendar'
import classNames from 'classnames'

const DayItem = ({
  value,
  day,
  posts = 0,
  emails = 0,
  events = 0,
  inactive = false,
  today = false,
  selected = false,
  onClick,
}) => {
  const locale = useLocale()

  return (
    <div
      value={value}
      onClick={onClick}
      className={classNames(styles.dayItem, inactive && styles.dayInactive, today && styles.today, selected && styles.selected)}
    >
      <span className={styles.dayText}>{day}</span>

      {posts > 0 && !inactive && (
        <span className={styles.postCount}>
          {posts} <span className={styles.complementary}>{posts > 1 ? 'Posts' : 'Post'}</span>
        </span>
      )}
      {emails > 0 && !inactive && (
        <span className={styles.massEmailCount}>
          {emails} <span className={styles.complementary}>{emails > 1 ? 'Emails' : 'Email'}</span>
        </span>
      )}
      {events > 0 && !inactive && (
        <span className={styles.eventsCount}>
          {events}{' '}
          <span className={styles.complementary}>
            {events > 1 ? (locale === 'es' ? 'Eventos' : 'Events') : locale === 'es' ? 'Evento' : 'Event'}
          </span>
        </span>
      )}
    </div>
  )
}

const CalendarRight = ({
  date,
  month,
  year,
  setMonth,
  setYear,
  setWeekDay,
  setDate,
  setChangingMonth,
  filterEvents,
  calendarActions,
  filters,
  setFilters,
}) => {
  const locale = useLocale()
  const currentDate = new Date()
  const today = currentDate.getDate()
  const currentMonth = currentDate.getMonth() + 1
  const currentYear = currentDate.getFullYear()
  const currentDay = getFullWeekDays(locale)[currentDate.getDay() === 0 ? 6 : currentDate.getDay() - 1]
  const { weeks, firstDayIndex, lastDayIndex, lastDayDate } = createCalendar(year, month)

  const counters = useCallback(
    (day) => {
      const temp = filterEvents(calendarActions, day, month, year)
      const counts = temp.reduce(
        (acc, _action) => {
          if (_action?.idMassEmail) acc.emails = acc.emails + 1
          else if (_action?.idPost) acc.posts = acc.posts + 1
          else if (_action.idCalendarEvent) acc.events = acc.events + 1

          return acc
        },
        { emails: 0, posts: 0, events: 0 }
      )

      return counts
    },
    [filterEvents, calendarActions, month, year]
  )

  const handleTodayClick = useCallback(() => {
    setMonth(currentMonth)
    setYear(currentYear)
    setWeekDay(currentDay)
    setDate(today)
    setChangingMonth(false)
  }, [currentMonth, currentYear, currentDay, today, setMonth, setYear, setWeekDay, setDate, setChangingMonth])

  const handleNextMonth = useCallback(() => {
    if (month === 12) {
      setMonth(1)
      setYear((prev) => prev + 1)
      setDate(1)
      return
    }

    setMonth((prev) => prev + 1)
    setDate(1)
    setChangingMonth(true)
  }, [month, setMonth, setYear, setDate, setChangingMonth])

  const handlePreviousMonth = useCallback(() => {
    if (month === 1) {
      setMonth(12)
      setYear((prev) => prev - 1)
      setDate(1)
      return
    }

    setMonth((prev) => prev - 1)
    setDate(1)
    setChangingMonth(true)
  }, [month, setMonth, setYear, setDate, setChangingMonth])

  const handleDayClick = (day, index, previousMonth = false, nextMonth = false) => {
    if (previousMonth) {
      if (month === 1) {
        setMonth(12)
        setYear((prev) => prev - 1)
      } else {
        setMonth((prev) => prev - 1)
      }
    } else if (nextMonth) {
      if (month === 12) {
        setMonth(1)
        setYear((prev) => prev + 1)
      } else {
        setMonth((prev) => prev + 1)
      }
    }

    setWeekDay(getFullWeekDays(locale)[index])
    setDate(day)
    setChangingMonth(false)
  }

  return (
    <Calendar
      currentYear={year}
      currentMonth={month}
      handleNextMonth={handleNextMonth}
      handlePreviousMonth={handlePreviousMonth}
      handleTodayClick={handleTodayClick}
      renderAfterHeader={<CalendarActionsFilters filters={filters} setFilters={setFilters} />}
    >
      {({ day, index, weekIndex }) => (
        <DayItem
          key={`${day}-${index}`}
          day={day}
          value={day}
          inactive={
            (weekIndex === 0 && index < firstDayIndex && day !== 1) ||
            (weekIndex + 1 === weeks.length && index >= lastDayIndex && day < lastDayDate)
          }
          onClick={() =>
            handleDayClick(
              day,
              index,
              weekIndex === 0 && index < firstDayIndex && day !== 1,
              weekIndex + 1 === weeks.length && index >= lastDayIndex && day < lastDayDate
            )
          }
          today={
            day === today &&
            month === currentMonth &&
            year === currentYear &&
            !(
              (weekIndex === 0 && index < firstDayIndex && day !== 1) ||
              (weekIndex + 1 === weeks.length && index >= lastDayIndex && day < lastDayDate)
            )
          }
          selected={day === date}
          posts={counters(day).posts}
          emails={counters(day).emails}
          events={counters(day).events}
        />
      )}
    </Calendar>
  )
}

export default CalendarRight
