import { Button, FormControl, MenuItem, Select, TextField } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import { sendSuccessMessage } from 'actions/modals'
import FileInput from 'components/FileInput/FileInput'
import LoadingSection from 'components/LoadingSection'
import Checkboxes from 'components/common/Checkboxes/Checkboxes'
import { addDays, format, isBefore, isSameDay } from 'date-fns'
import { daysDiff, formatDate } from 'helpers/date'
import useForm from 'hooks/useForm'
import useHumanResources from 'hooks/useHumanResources'
import useLocale from 'hooks/useLocale'
import useUsers from 'hooks/useUsers'
import { useVacations } from 'hooks/useVacations'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import styles from './FreeTimeForm.module.scss'

const FreeTimeForm = ({ data, onSubmit }) => {
  const locale = useLocale()
  const dispatch = useDispatch()
  const FREETIME_TYPES = [
    {
      text: {
        es: 'Vacaciones',
        en: 'Vacations',
      },
      value: 1,
    },
    {
      text: {
        es: 'Enfermedad',
        en: 'Sickness',
      },
      value: 2,
    },
    {
      text: {
        es: 'Graduación',
        en: 'Graduation',
      },
      value: 3,
    },
    {
      text: {
        es: 'Luto',
        en: 'Grief',
      },
      value: 4,
    },
    {
      text: {
        es: 'Otros',
        en: 'Others',
      },
      value: 5,
    },
  ]
  const { myUser } = useUsers()
  const { employees } = useHumanResources() ?? {}
  const employee = useMemo(
    () => (employees?.length ? employees?.find((el) => el.idEmployee === data?.idEmployee) : myUser?.employee) ?? {},
    [employees, myUser, data]
  )
  const { HAS_VACATIONS } = employee ?? {}
  const { availableVacations } = useVacations(employee)

  const fileRef = useRef()

  const [freeTime, setFreeTime] = useState(data)
  const [file, setFile] = useState('')
  const [loading, setLoading] = useState(false)
  const [oneDayTotalDays, setOneDayTotalDays] = useState(undefined)

  const handleChange = useForm(setFreeTime)

  const today = useMemo(() => {
    const value = new Date()
    value.setHours(0, 0, 0, 0)
    return value
  }, [])

  const tomorrow = useMemo(() => {
    const value = new Date()
    value.setDate(today.getDate() + 1)
    value.setHours(0, 0, 0, 0)
    return value
  }, [today])

  const totalDays = useMemo(() => {
    const dayDiff = daysDiff(freeTime?.startTime ?? today, freeTime?.endTime ?? tomorrow)
    const { schedule } = employee ?? {}

    if (!schedule) return dayDiff // El empleado no tiene un horario, asi que se devuelve la diferencia

    const notWorkingDays = schedule?.work_days?.map((_day) => (!_day.IS_WORKING ? _day.day : null)).filter((x) => x)
    let askedForDays = 0
    let startDay = new Date(freeTime?.startTime ?? today)
    let endDay = new Date(freeTime?.endTime ?? tomorrow)

    if (isSameDay(startDay, endDay)) {
      if (!oneDayTotalDays) setOneDayTotalDays(1)
      return 0
    }

    while (isBefore(startDay, endDay) || isSameDay(startDay, endDay)) {
      const day = format(startDay, 'EEEE').toLowerCase()
      if (!notWorkingDays.find((x) => x === day)) askedForDays++
      startDay = addDays(startDay, 1)
    }

    return askedForDays
  }, [freeTime, employee, today, tomorrow, oneDayTotalDays])

  const hasAvailableVacations =
    HAS_VACATIONS && (oneDayTotalDays ? oneDayTotalDays > availableVacations : totalDays > availableVacations || totalDays === 0)

  const handleOnSubmit = useCallback(
    async (e) => {
      e.preventDefault()
      setLoading(true)

      const data = new FormData()
      if (file) {
        data.append('file', file)
        data.append('filename', file.name)
      }
      for (const [key, value] of Object.entries(freeTime)) {
        data.append(key, value)
      }
      data.append('totalDays', oneDayTotalDays ?? totalDays)

      if (data.get('startTime') === 'null') data.append('startTime', formatDate(today, 'YYYY-MM-DD HH:mm:ss', true))
      if (data.get('endTime') === 'null') data.append('endTime', formatDate(tomorrow, 'YYYY-MM-DD HH:mm:ss', true))

      if (typeof onSubmit === 'function') {
        await onSubmit(data, setFreeTime)
        dispatch(sendSuccessMessage(locale === 'es' ? 'Solicitud creada correctamente' : 'Request created successfully'))
      }

      fileRef.current.clearInput()
      setLoading(false)
    },
    [dispatch, today, tomorrow, locale, onSubmit, file, freeTime, totalDays, oneDayTotalDays]
  )

  useEffect(() => {
    if (!data) return setFreeTime(undefined)

    const { startTime, endTime } = data
    const sameDay = isSameDay(new Date(startTime), new Date(endTime))

    setOneDayTotalDays(sameDay ? 1 : undefined)
    setFreeTime({
      ...data,
    })
  }, [data])

  useEffect(() => {
    if (totalDays !== 0) {
      setOneDayTotalDays(undefined)
    }
  }, [totalDays])

  const cantSend = useMemo(() => {
    if (new Date(freeTime?.startTime) > new Date(freeTime?.endTime)) return true

    if (oneDayTotalDays) {
      return freeTime?.type === 1 ? oneDayTotalDays > availableVacations : false
    }

    return (freeTime?.type === 1 && hasAvailableVacations) || totalDays < 0
  }, [freeTime, totalDays, hasAvailableVacations, oneDayTotalDays, availableVacations])

  return (
    <form className={styles.modalContent} onSubmit={handleOnSubmit}>
      <FormControl fullWidth required>
        <Select variant="outlined" value={freeTime?.type ?? 1} onChange={(e) => handleChange(e, 'type', 'number')}>
          {FREETIME_TYPES.filter((el) => (HAS_VACATIONS ? el : el.value !== 1)).map((i) => (
            <MenuItem value={i.value} key={i.value}>
              {i.text[locale]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <DatePicker
        fullWidth
        format="d MMMM yyyy"
        lang={locale}
        variant="inline"
        minutesStep={10}
        label={locale === 'es' ? 'Fecha de inicio' : 'Start date'}
        value={freeTime?.startTime ?? today}
        onChange={(e) => handleChange(e, 'startTime', 'date')}
        maxDateMessage={
          locale === 'es' ? 'No puede seleccionar una fecha mayor a la final' : "Cant't select a date after the end date"
        }
        inputVariant="outlined"
        InputProps={{
          endAdornment: <i className="fas fa-calendar" />,
        }}
      />
      <DatePicker
        fullWidth
        format="d MMMM yyyy"
        lang={locale}
        variant="inline"
        minutesStep={10}
        label={locale === 'es' ? 'Fecha de fin' : 'End date'}
        value={freeTime?.endTime ?? tomorrow}
        onChange={(e) => handleChange(e, 'endTime', 'date')}
        minDate={freeTime?.startTime ?? today}
        minDateMessage={
          locale === 'es' ? 'No se puede seleccionar una fecha menor a la inicial' : "Can't select a date before the start date"
        }
        inputVariant="outlined"
        InputProps={{
          endAdornment: <i className="fas fa-calendar" />,
        }}
      />

      {!totalDays ? (
        <Checkboxes
          options={[
            { label: locale === 'es' ? 'Día completo' : 'Full day', value: 1 },
            { label: locale === 'es' ? 'Medio día' : 'Half day', value: 0.5 },
          ]}
          title={'Cantidad de tiempo libre'}
          onChange={(e) => setOneDayTotalDays(+e.target.value)}
          defaultChecked={1}
        />
      ) : null}

      <div className={styles.dayDiff}>
        {locale === 'es' ? 'Total de dias seleccionados' : 'Total days selected'}: {oneDayTotalDays ?? totalDays}
      </div>

      <TextField
        multiline
        fullWidth
        variant="outlined"
        minRows={2}
        maxRows={4}
        label={locale === 'es' ? 'Nota' : 'Note'}
        value={freeTime?.notes ?? ''}
        onChange={(e) => handleChange(e, 'notes')}
      />

      <FileInput
        field={{
          name: 'field',
          id: 'field',
          icon: 'fas fa-paperclip',
          variant: 'outlined',
          label: locale === 'es' ? 'Seleccionar archivo' : 'Select file',
          accept: '*',
          required: false,
          InputLabelProps: {
            shrink: true,
          },
        }}
        onChange={(e) => setFile(e.target.files[0])}
        ref={fileRef}
      />

      {loading ? (
        <LoadingSection />
      ) : (
        <Button fullWidth color="primary" variant="contained" type="submit" classes={{ root: styles.button }} disabled={cantSend}>
          {locale === 'es' ? 'Crear' : 'Create'}
        </Button>
      )}
    </form>
  )
}

export default FreeTimeForm
