import freeTimeTypes from 'constants/freeTimeTypes'
import useLocale from 'hooks/useLocale'
import { useVacations } from 'hooks/useVacations'
import React from 'react'
import styles from './index.module.scss'
import useLicense from './useLicense'

const License = ({ employee }) => {
  const locale = useLocale()
  const { free_time: freeTime, HAS_VACATIONS } = employee

  const { availableVacations } = useVacations(employee)
  const { totalVacations, totalVacationsPerType } = useLicense(freeTime)

  return (
    <section className={styles.wrapper}>
      <section className={styles.days}>
        <h4>{locale === 'es' ? 'Licencia anual' : 'Yearly license'}</h4>
        <div>
          <p className={styles.totalDays}>
            <span>{totalVacations}</span> {locale === 'es' ? 'DÍAS' : 'DAYS'}
          </p>
        </div>
      </section>

      {HAS_VACATIONS ? (
        <section className={styles.days}>
          <h4>{locale === 'es' ? 'Vacaciones disponibles' : 'Available vacations'}</h4>
          <div>
            <p className={styles.totalDays}>
              <span>{availableVacations}</span> {locale === 'es' ? 'DÍAS' : 'DAYS'}
            </p>
          </div>
        </section>
      ) : null}

      <section className={styles.resumeOfDays}>
        {Object?.keys(totalVacationsPerType ?? {})?.map((key) => (
          <div key={key} className={styles.resumeType}>
            <figure className={styles.decoration} />
            <span>{freeTimeTypes.get(Number(key))['text'][locale]}</span>
            <span className={styles.total}>{totalVacationsPerType[key]}</span>
          </div>
        ))}
      </section>
    </section>
  )
}

export default License
