import { useParams } from 'react-router'
import { useUpdateFile } from '../useDriveApi'

export const useDragAndDrop = (id) => {
  const { folderId: currentFolder } = useParams()
  const { mutate: updateFile } = useUpdateFile()

  const dropItem = (item) => {
    if (currentFolder === id) return
    if (item.id === id) return

    const params = {
      addParents: id,
      removeParents: item?.parents?.join(',') || '',
    }

    updateFile({ fileId: item.id, folderId: id, params })
  }

  return { dropItem }
}
