import { Button } from '@material-ui/core'
import { sendErrorMessage } from 'actions/modals'
import { deleteSubscription } from 'actions/users'
import Dialog from 'components/Dialog/Dialog'
import LoadingSection from 'components/LoadingSection'
import SubscribeToProButton from 'components/SubscribeToProButton'
import { cancelSubscription } from 'constants/subscriptions'
import useLocale from 'hooks/useLocale'
import useUsers from 'hooks/useUsers'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import styles from './CancelSubscriptionModal.module.scss'

const CancelSubscriptionModal = () => {
  const locale = useLocale()
  const dispatch = useDispatch()
  const { myUser } = useUsers()
  const { subscription } = myUser

  const [openModal, setOpenModal] = useState(false)
  const [pending, setPending] = useState(false)
  const [confirmed, setConfirmed] = useState(false)

  const handleClose = () => {
    setOpenModal(false)
    if (confirmed) document.location.reload()
  }

  const handleConfirm = async () => {
    setPending(true)

    try {
      await cancelSubscription(subscription.subscription_id)

      const response = await deleteSubscription(subscription.idSubscription)

      dispatch(response)
      setConfirmed(true)
      setPending(false)
    } catch (error) {
      dispatch(sendErrorMessage(locale === 'es' ? 'Error al cancelar la suscripción' : 'Error cancelling subscription'))
      console.error(error)
    }
  }

  return (
    <>
      <Button variant="outlined" color="primary" onClick={() => setOpenModal(true)}>
        {locale === 'es' ? 'Cancelar' : 'Cancel'}
      </Button>

      <Dialog open={openModal} handleCloseAction={handleClose} maxWidth={confirmed && 'sm'}>
        {confirmed ? (
          <section className={styles.wrapper}>
            <img className={styles.logo} src="/logo.png" alt="DigitalPymes Logo" />

            <strong className={styles.confirmedHeader}>
              {locale === 'es' ? `¡HOLA, ${myUser.firstName}!` : `hello, ${myUser.firstName}!`}
            </strong>
            <p className={styles.confirmedHelper}>
              {locale === 'es' ? 'Lamentamos que te vayas.' : 'We are sorry you are leaving.'}
            </p>

            <p className={styles.confirmedBody}>
              {locale === 'es'
                ? 'Lamentamos mucho la cancelación de tu suscripción al plan PRO. Si cambias de opinión puedes volver a suscribirte dando click al siguiente bóton: '
                : 'We are sorry to cancel your subscription to the PRO plan. If you change your mind you can subscribe again by clicking the following button:'}
            </p>

            <SubscribeToProButton />
          </section>
        ) : (
          <section className={styles.wrapper}>
            <figure className={styles.warningIcon}>
              <i className="fas fa-exclamation" />
            </figure>

            <p className={styles.warningHelper}>
              {locale === 'es'
                ? '¿Estás seguro de querer cancelar tu suscripción al plan PRO?'
                : 'Are you sure you want to cancel your PRO subscription?'}
            </p>

            <p className={styles.warningFooter}>
              {locale === 'es'
                ? 'Nota: Al realizar la cancelación se volverá la version FREE del sistema, debes estar seguro de realizar esta acción.'
                : 'Note: By canceling the subscription, the FREE version of the system will be returned, you must be sure to perform this action.'}
            </p>

            {pending ? (
              <LoadingSection />
            ) : (
              <div className={styles.buttons}>
                <Button fullWidth variant="outlined" color="primary" onClick={handleClose}>
                  {locale === 'es' ? 'No' : 'No'}
                </Button>
                <Button fullWidth variant="contained" color="primary" onClick={handleConfirm}>
                  {locale === 'es' ? 'Si' : 'Yes'}
                </Button>
              </div>
            )}
          </section>
        )}
      </Dialog>
    </>
  )
}

export default CancelSubscriptionModal
