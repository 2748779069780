import { DRIVE_FILES_ROUTE, DRIVE_USERS_PERMISSIONS } from 'constants/apiRoutes'
import { apiDeleteData, apiGetData, apiPostData } from 'helpers/api'

const getPrivateFiles = async (idCompany) => await apiGetData(`${DRIVE_FILES_ROUTE}/${idCompany}`)
const postPrivateFile = async (file) => await apiPostData(DRIVE_FILES_ROUTE, file)
const deletePrivateFile = async ({ fileId, idCompany }) =>
  await apiDeleteData(`${DRIVE_FILES_ROUTE}/${idCompany}`, { id: fileId })

const getDrivePermissions = async ({ idUser, idCompany }) =>
  await apiGetData(`${DRIVE_USERS_PERMISSIONS}/${idUser ?? 0}?idCompany=${idCompany}`)

const postDrivePermissions = async (permission) => await apiPostData(DRIVE_USERS_PERMISSIONS, { permission })
const deleteDrivePermission = async (idPermission) =>
  await apiDeleteData(`${DRIVE_USERS_PERMISSIONS}/user/permission`, { id: idPermission })

export { getPrivateFiles, postPrivateFile, deletePrivateFile, getDrivePermissions, postDrivePermissions, deleteDrivePermission }
