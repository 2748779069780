import { FILTER_BY, FILTER_REVERSE_BY, GROUP_BY, GROUP_BY_SUBKEY, GROUP_BY_VALUE } from 'constants/dbTablesModels'
import useDbModel from './useDbModel'
import useTableData from './useTableData'

const reduceWithGroupBy = (key) => {
  let temp = {}

  return (acc, item) => {
    if (!temp[item[key]]) {
      temp[item[key]] = item[key]

      return ++acc
    }

    return +acc
  }
}

const reduceWithFilterBy = (key) => (acc, item) => {
  if (item[key] === null || !item[key]) return acc
  else return ++acc
}

const reduceWithReverseFilterBy = (key) => (acc, item) => {
  if (item[key] === null || !item[key]) return ++acc
  else return acc
}

const reduceWithGroupValueBy = (key, condition) => (acc, item) => {
  if (condition(item[key])) {
    return ++acc
  }

  return acc
}

const reduceWithSubkey = (key) => (acc, item) => {
  return acc + item?.[key]?.length ?? 0
}

export const useCountValue = (indicator = {}) => {
  const tableData = useTableData(indicator)
  const dbModel = useDbModel(indicator)
  const { totalizationFields } = dbModel

  const reduceByKey = (key) => {
    const currentField = totalizationFields?.find((field) => field.key === key)

    const { groupingMethod, fieldKey, filterCondition } = currentField ?? {}

    const reducerCallback = () => {
      switch (groupingMethod) {
        case FILTER_BY:
          return reduceWithFilterBy(fieldKey)
        case FILTER_REVERSE_BY:
          return reduceWithReverseFilterBy(fieldKey)
        case GROUP_BY_VALUE:
          return reduceWithGroupValueBy(fieldKey, filterCondition)
        case GROUP_BY:
          return reduceWithGroupBy(fieldKey)
        case GROUP_BY_SUBKEY:
          return reduceWithSubkey(fieldKey)
        default:
          return null
      }
    }

    const count = tableData?.reduce(reducerCallback(), 0)

    return count
  }

  return { reduceByKey }
}
