import { ButtonGroup, Card, IconButton, Tooltip } from '@material-ui/core'
import { DataGrid } from '@material-ui/data-grid'
import { confirmDialog, sendErrorMessage, sendSuccessMessage } from 'actions/modals'
import clsx from 'clsx'
import useCustomMuiStyles from 'hooks/useCustomMuiStyles'
import useLocale from 'hooks/useLocale'
import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { customMuiClasses } from './customMuiClasses'
import styles from './dataGridTable.module.scss'

const DEFAULT_PAGE_SIZE = 5

const Actions = ({
  id,
  actions = [],
  handlerEdit,
  queryDelete,
  onDeleteError,
  hideBasicActions,
  hideEdit,
  hideDelete,
  dispatch,
  selectedObject,
}) => {
  const locale = useLocale()

  const handlerDeleteAction = useCallback(() => {
    dispatch(
      confirmDialog({
        message: locale === 'es' ? 'Estas seguro de eliminar este registro?' : 'Are you sure you want to delete this register?',
        handlerAction: async () => {
          try {
            const res = await queryDelete(id)

            if (res?.payload?.error)
              return dispatch(
                sendErrorMessage(locale === 'es' ? 'No se ha podido eliminar el elemento' : 'Error when deleting this element')
              )

            dispatch(res)
            dispatch(sendSuccessMessage(locale === 'es' ? 'Elemento eliminado' : 'Item deleted'))
          } catch (error) {
            if (onDeleteError) onDeleteError({ error, dispatch })

            throw error
          }
        },
      })
    )
  }, [dispatch, id, queryDelete, locale, onDeleteError])

  const BASIC_ACTIONS = useMemo(() => {
    if (hideBasicActions) return []

    const editAction = { name: locale === 'es' ? 'Editar' : 'Edit', icon: 'far fa-edit', action: handlerEdit }
    const deleteAction = { name: locale === 'es' ? 'Eliminar' : 'Delete', icon: 'fas fa-trash-alt', action: handlerDeleteAction }

    return [hideEdit ? null : editAction, hideDelete ? null : deleteAction].filter(Boolean)
  }, [handlerEdit, handlerDeleteAction, hideEdit, hideDelete, hideBasicActions, locale])

  const customOnClick = (onClick) => () => onClick({ selectedObject })

  return (
    <ButtonGroup variant="text" color="default">
      {[...actions, ...BASIC_ACTIONS].map((action) =>
        action?.renderCell ? (
          <div key={action?.name}>{action?.renderCell(selectedObject)}</div>
        ) : (
          <Tooltip title={action?.name} key={action?.name}>
            <IconButton onClick={action?.action ?? customOnClick(action?.onClick)} className={styles.iconButton}>
              <i className={action?.icon}></i>
            </IconButton>
          </Tooltip>
        )
      )}
    </ButtonGroup>
  )
}

const _formatColumns = (columns = [], actionsConfig, dispatch, formattedData, locale, noActions) => {
  const actionConfig = {
    headerName: actionsConfig?.headerName ?? (locale === 'es' ? 'Acciones' : 'Actions'),
    renderHeader: actionsConfig?.renderHeader ?? null,
    field: actionsConfig?.field ?? 'id',
    width: actionsConfig?.width ?? 150,
    sortable: false,
    filterable: false,
    flex: actionsConfig?.flex,
    headerAlign: 'center',
    align: 'center',
    renderCell: ({ value }) => {
      const selectedObject = formattedData?.find((dataItem) => dataItem?.[actionsConfig?.field ?? 'id'] === value)

      return (
        <Actions
          {...actionsConfig}
          id={value}
          handlerEdit={() =>
            actionsConfig?.handlerEdit({
              dispatch,
              selectedObject,
            })
          }
          dispatch={dispatch}
          selectedObject={selectedObject}
        />
      )
    },
  }

  if (noActions) return columns

  return [...columns, actionConfig]
}

const filterModel = (columns, searchValue) => ({
  items:
    columns
      ?.filter(({ isFilterField }) => isFilterField)
      ?.map(({ field }, id) => ({ id, columnField: field, operatorValue: 'contains', value: searchValue })) ?? [],
})

const DataGridTable = ({
  pageSize,
  columns,
  actionsConfig,
  filterSearchValue,
  data,
  isLoading,
  rootClassName = '',
  noActions = false,
  customClasses = {},
  autoHeight = false,
}) => {
  const locale = useLocale()
  const dispatch = useDispatch()
  const formattedData = data?.map((dataRow, id) => ({ id, ...dataRow })) ?? []
  const formattedColumns = _formatColumns(columns, actionsConfig, dispatch, formattedData, locale, noActions)
  const classes = useCustomMuiStyles(customMuiClasses)

  return (
    <Card className={styles.dataGridTable} classes={{ root: clsx(rootClassName, styles.paper) }}>
      <DataGrid
        autoHeight={autoHeight}
        classes={{
          columnHeader: styles.cell,
          cell: styles.rowCell,
          root: styles.dataGrid,
          row: styles.row,
          ...customClasses,
        }}
        className={classes.root}
        columns={formattedColumns}
        rows={formattedData}
        loading={isLoading && !formattedData.length}
        pageSize={pageSize || DEFAULT_PAGE_SIZE}
        filterModel={filterModel(columns, filterSearchValue)}
        rowsPerPageOptions={[5]}
        disableColumnMenu
        showToolbar
        disableSelectionOnClick
      />
    </Card>
  )
}

export default DataGridTable
