import {
  GET_FORMS,
  UPLOAD_FORM,
  UPDATE_FORM,
  DELETE_FORM,
  UPLOAD_FORM_PAGE,
  UPDATE_FORM_PAGE,
  DELETE_FORM_PAGE,
  UPLOAD_FORM_QUESTION,
  UPDATE_FORM_QUESTION,
  DELETE_FORM_QUESTION,
  UPDATE_FORM_QUESTIONS_POSITIONS,
  UPDATE_FORM_FETCHED,
  UPDATE_ANSWERS_FETCHED,
  GET_ANSWERS,
  DELETE_ANSWER,
  UPDATE_QUESTION_OPTION,
  DELETE_QUESTION_OPTION,
} from 'constants/actionTypes'

const initialState = {
  isLoadingForms: true,
  forms: [],
  answers: [],
  formsFetched: [],
  answersFetched: [],
  answersPerQuestion: [],
}

const handlers = {
  [UPDATE_FORM_FETCHED]: (state, { payload = {} }) => ({ ...state, formsFetched: [...state.formsFetched, payload?.idForm] }),
  [UPDATE_ANSWERS_FETCHED]: (state, { payload = {} }) => ({
    ...state,
    answersFetched: [...state.answersFetched, payload?.idForm],
  }),
  [GET_FORMS]: (state, { payload = {} }) => {
    if (!payload?.forms) return { ...state, isLoadingForms: false, errorInRequest: true }

    return {
      ...state,
      isLoadingForms: false,
      forms: payload?.forms,
    }
  },
  [UPLOAD_FORM]: (state, { payload = {} }) => {
    if (!payload?.form) return { ...state, errorInRequest: true }

    return {
      ...state,
      forms: [payload?.form, ...state.forms],
    }
  },
  [UPDATE_FORM]: (state, { payload = {} }) => {
    if (!payload?.form) return { ...state, errorInRequest: true }

    return {
      ...state,
      forms: state.forms.map((form) => (form?.idForm === payload?.form?.idForm ? payload?.form : form)),
    }
  },
  [DELETE_FORM]: (state, { payload = {} }) => {
    if (!payload?.form) return { ...state, errorInRequest: true }

    return {
      ...state,
      forms: state.forms.filter((form) => form?.idForm !== payload?.form?.idForm),
    }
  },
  [UPLOAD_FORM_PAGE]: (state, { payload = {} }) => {
    if (!payload?.page) return { ...state, errorInRequest: true }

    const formId = payload?.page?.idForm

    return {
      ...state,
      forms: state.forms?.map((form) => {
        if (form.idForm === formId) {
          return {
            ...form,
            pages: [...form.pages, payload?.page],
          }
        } else return form
      }),
    }
  },
  [UPDATE_FORM_PAGE]: (state, { payload = {} }) => {
    if (!payload?.page) return { ...state, errorInRequest: true }

    const formId = payload?.page?.idForm

    return {
      ...state,
      forms: state.forms?.map((form) => {
        if (form.idForm === formId) {
          const pageId = payload?.page?.idFormPage

          return {
            ...form,
            pages: form.pages.map((_page) => {
              if (_page.idFormPage === pageId) {
                return payload?.page
              } else return _page
            }),
          }
        } else return form
      }),
    }
  },
  [DELETE_FORM_PAGE]: (state, { payload = {} }) => {
    if (!payload?.page) return { ...state, errorInRequest: true }

    const formId = payload?.page?.idForm
    const pageId = payload?.page?.idFormPage

    return {
      ...state,
      forms: state.forms.map((_form) => {
        if (formId === _form.idForm) {
          return {
            ..._form,
            pages: payload.pages.filter((_page) => _page.idFormPage !== pageId),
          }
        } else return _form
      }),
    }
  },
  [UPLOAD_FORM_QUESTION]: (state, { payload = {} }) => {
    if (!payload?.question) return { ...state, errorInRequest: true }

    const formId = payload?.question?.idForm

    return {
      ...state,
      forms: state?.forms?.map((form) => {
        if (formId === form.idForm) {
          return {
            ...form,
            questions: [...form?.questions, payload?.question],
          }
        } else return form
      }),
    }
  },
  [UPDATE_FORM_QUESTION]: (state, { payload = {} }) => {
    if (!payload?.question) return { ...state, errorInRequest: true }

    const formId = payload?.question?.idForm

    return {
      ...state,
      forms: state?.forms?.map((form) => {
        if (formId === form.idForm) {
          const questionId = payload?.question?.idFormQuestion

          return {
            ...form,
            questions: form?.questions.map((question) => {
              if (questionId === question.idFormQuestion) {
                return payload?.question
              } else return question
            }),
          }
        } else return form
      }),
    }
  },
  [DELETE_FORM_QUESTION]: (state, { payload = {} }) => {
    if (!payload?.question) return { ...state, errorInRequest: true }

    const formId = payload?.question?.idForm
    const questionId = payload?.question?.idFormQuestion

    return {
      ...state,
      forms: state?.forms?.map((form) => {
        if (formId === form.idForm) {
          return {
            ...form,
            questions: form?.questions.filter((_question) => _question.idFormQuestion !== questionId),
          }
        } else return form
      }),
    }
  },
  [UPDATE_FORM_QUESTIONS_POSITIONS]: (state, { payload = {} }) => {
    const incoming = payload.questions
    const formId = incoming[0].idForm
    const updateQuestions = (questions) =>
      questions.map((actual) => {
        const filtered = incoming.filter((question) => question.idFormQuestion === actual.idFormQuestion)

        return filtered.length ? filtered[0] : actual
      })

    return {
      ...state,
      forms: state.forms.map((_form) => {
        if (formId === _form.idForm) {
          return {
            ..._form,
            questions: updateQuestions(_form.questions),
          }
        } else return _form
      }),
    }
  },
  [GET_ANSWERS]: (state, { payload = {} }) => {
    if (!payload?.answers) return { ...state, errorInRequest: true }

    return {
      ...state,
      answers: [...state.answers, ...payload?.answers],
    }
  },
  [DELETE_ANSWER]: (state, { payload = {} }) => {
    if (!payload?.answer) return { ...state, errorInRequest: true }

    const formId = payload?.answer?.idForm
    const answerId = payload?.answer?.idFormAnswer

    return {
      ...state,
      forms: state.forms.map((_form) => {
        if (_form.idForm === formId) {
          return {
            ..._form,
            answers: _form.answers.filter((_answer) => _answer.idFormAnswer !== answerId),
          }
        } else return _form
      }),
    }
  },
  [UPDATE_QUESTION_OPTION]: (state, { payload = {} }) => {
    const questionId = payload?.question?.idFormQuestion
    const formId = payload?.question?.idForm
    const optionId = payload?.question?.option?.idOption ?? null

    if (payload?.edit) {
      return {
        ...state,
        forms: state.forms.map((_form) => {
          if (_form.idForm === formId) {
            return {
              ..._form,
              questions: _form.questions.map((_question) => {
                if (_question.idFormQuestion === questionId) {
                  return {
                    ..._question,
                    options: _question.options.map((_option) => {
                      if (_option.idOption === optionId) {
                        return payload?.question?.option
                      } else return _option
                    }),
                  }
                } else return _question
              }),
            }
          } else return _form
        }),
      }
    }

    return {
      ...state,
      forms: state.forms.map((_form) => {
        if (_form.idForm === formId) {
          return {
            ..._form,
            questions: _form.questions.map((_question) => {
              if (_question.idFormQuestion === questionId) {
                return {
                  ..._question,
                  options: [..._question.options, payload?.question?.option],
                }
              } else return _question
            }),
          }
        } else return _form
      }),
    }
  },
  [DELETE_QUESTION_OPTION]: (state, { payload = {} }) => {
    const questionId = payload?.question?.idFormQuestion
    const formId = payload?.question?.idForm
    const optionId = payload?.question?.id ?? null

    return {
      ...state,
      forms: state.forms.map((_form) => {
        if (_form.idForm === formId) {
          return {
            ..._form,
            questions: _form.questions.map((_question) => {
              if (_question.idFormQuestion === questionId) {
                return {
                  ..._question,
                  options: _question.options.filter((_option) => _option.idOption !== optionId),
                }
              } else return _question
            }),
          }
        } else return _form
      }),
    }
  },
}

const clientForms = (state = initialState, action) => {
  const { type } = action

  return handlers[type] ? handlers[type](state, action) : state
}

export default clientForms
