import { Button } from '@material-ui/core'
import { sendSuccessMessage } from 'actions/modals'
import Select from 'components/common/Select/Select'
import useLocale from 'hooks/useLocale'
import { useCreatePermission, useDeletePermission, useUpdatePermission } from 'pages/GoogleDrivePage/useDriveApi'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import styles from './GeneralAccess.module.scss'
import RoleSelector from './RoleSelector/RoleSelector'

const GeneralAccess = ({ data, fileId, isFolder }) => {
  const { folderId } = useParams()
  const dispatch = useDispatch()
  const locale = useLocale()
  const title = locale === 'es' ? 'Acceso general' : 'General access'
  const filteredData = data?.find((item) => item.id === 'anyoneWithLink')
  const { mutate: createPermission } = useCreatePermission()
  const { mutate: deletePermission } = useDeletePermission()
  const { mutate: updatePermission } = useUpdatePermission()

  const [state, setState] = useState(Boolean(filteredData))
  const [role, setRole] = useState(filteredData?.role || 'reader')

  const isPublic = Boolean(state)
  const icon = isPublic ? 'fas fa-globe' : 'fas fa-lock'
  const permissionText = isPublic
    ? locale === 'es'
      ? 'Cualquiera con el link puede ver este contenido'
      : 'Anyone with the link can view this content'
    : locale === 'es'
    ? 'Solo personas con acceso pueden ver este contenido'
    : 'Only people with access can view this content'

  const handleChangePrivacy = (e) => {
    const { value } = e.target
    setState(Boolean(value))

    if (!state) {
      const body = {
        role: 'reader',
        type: 'anyone',
      }

      createPermission({ fileId, body, folderId })
    } else {
      deletePermission({ fileId, permissionId: filteredData?.id, folderId })
    }
  }

  const handleChangePermissionRole = (e) => {
    setRole(e.target.value)
    const body = {
      role: e.target.value,
    }

    updatePermission({ fileId, permissionId: filteredData.id, body, folderId })
  }

  const handleCopyLink = () => {
    if (isFolder) {
      navigator.clipboard.writeText(`https://drive.google.com/drive/folders/${fileId}?usp=sharing`)
    } else {
      navigator.clipboard.writeText(`https://drive.google.com/file/d/${fileId}/view?usp=sharing`)
    }

    dispatch(sendSuccessMessage(locale === 'es' ? 'Link copiado' : 'Link copied'))
  }

  return (
    <section className={styles.wrapper}>
      <h3 className={styles.title}>{title}</h3>

      <div className={styles.accessWrapper}>
        <i className={icon} />

        <div className={styles.accessType}>
          <Select
            isRequired={false}
            options={[
              { value: true, text: locale === 'es' ? 'Cualquiera con el link' : 'Anyone with the link' },
              { value: false, text: locale === 'es' ? 'Restringido' : 'Restricted' },
            ]}
            value={state}
            onChange={handleChangePrivacy}
          />
          <p className={styles.permissionText}>{permissionText}</p>
        </div>

        {isPublic && <RoleSelector value={role} onChange={handleChangePermissionRole} />}
      </div>

      <Button startIcon={<i className="fas fa-paperclip" />} color="primary" variant="outlined" onClick={handleCopyLink}>
        {locale === 'es' ? 'Copiar link' : 'Copy link'}
      </Button>
    </section>
  )
}

export default GeneralAccess
