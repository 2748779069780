import { useContext } from 'react'
import { PersonalFinancesContext } from 'layouts/PersonalFinancesLayout/context/PersonalFinancesContext'

const usePersonalFinancesContext = () => {
  const value = useContext(PersonalFinancesContext)

  if (!value) {
    throw Error("Can't use context")
  }

  return value
}

export default usePersonalFinancesContext
