import { format } from 'date-fns'
import { _parseBonus, _parseSalaries } from './parseSalaries'

const getMonths = (yearPayments) => {
  const reduced = yearPayments.map((s) => s.date)
  const months = reduced.reduce((acc, date) => {
    const month = format(new Date(date), 'MMMM')
    if (!acc.includes(month)) {
      acc.push(month)
    }
    return acc
  }, [])

  return months
}

const _parseBonuses = (employees, selectedYear) => {
  const reduceEmployeesBonuses = (acc, employee) => {
    const { bonuses, idEmployee, name, startTime, currency, stubs } = employee

    const selectedYearPayedBonus = bonuses?.find((el) => el.year === selectedYear)
    const yearPayments = stubs?.filter((el) => {
      const date = new Date(el.date)
      const year = date.getFullYear()
      const month = date.getMonth() + 1

      if (month === 12 && year === selectedYear) {
        return false
      } else if (month === 12 && year === selectedYear - 1) {
        return true
      }

      return year === selectedYear && !el.IS_PENDING
    })

    if (selectedYearPayedBonus) {
      acc.push({
        name,
        startTime,
        currency,
        months: yearPayments.length,
        ...selectedYearPayedBonus,
      })
      return acc
    }

    const salary = _parseSalaries(yearPayments)

    const item = {
      idEmployee,
      name,
      startTime,
      currency,
      salary,
      year: selectedYear,
      months: getMonths(yearPayments).length,
      bonus: _parseBonus(salary),
      notPayed: true,
    }

    acc.push(item)

    return acc
  }

  return employees.reduce(reduceEmployeesBonuses, [])
}

export default _parseBonuses
