const CONTENT_BY_LOCALE = {
  es: {
    buttonName: 'Siguiente',
    registryButtonName: 'Registrarme',
    form: {
      companyName: 'Nombre de empresa',
      phone: 'Teléfono',
      email: 'Email',
      userFirstName: 'Nombre',
      userLastName: 'Apellidos',
      password: 'Contraseña',
      repeatPassword: 'Repetir contraseña',
      country: 'Pais',
      province: 'Provincia',
      district: 'Distrito',
    },
    stepTitles: { step1: 'Información de Empresa', step2: 'Creación de usuario', step3: 'Contraseña' },
    stepDescriptions: {
      step3: 'Debe contener mínimo: más de 6 caracteres, sin espacios, mayúsculas, minúsculas, números y símbolos.',
    },
  },
  en: {
    buttonName: 'Next',
    registryButtonName: 'Register',
    form: {
      companyName: 'Company name',
      phone: 'Phone',
      email: 'Email',
      userFirstName: 'First name',
      userLastName: 'Last name',
      password: 'Password',
      repeatPassword: 'Repeat password',
      country: 'Country',
      province: 'Province',
      district: 'Distric',
    },
    stepTitles: { step1: 'Company Information', step2: 'User information', step3: 'Password' },
    stepDescriptions: {
      step3: 'It must contain a minimum: more than 6 characters, without spaces, uppercase, lowercase, numbers and symbols. ',
    },
  },
}

const getContent = (locale) => CONTENT_BY_LOCALE[locale]

export default getContent
