//Datos usados de forma estática en el sistema
export const TYPES_OPTIONS = (locale) => [
  { value: 1, text: locale === 'es' ? 'Todos' : 'All' },
  { value: 2, text: locale === 'es' ? 'Por grupo' : 'By group' },
  { value: 3, text: locale === 'es' ? 'Por cliente' : 'By client' },
  { value: 4, text: locale === 'es' ? 'A usuarios' : 'To users' },
]

export const EMAIL_TYPES = (locale) =>
  new Map([
    [1, locale === 'es' ? 'Todos' : 'All'],
    [2, locale === 'es' ? 'Por grupo' : 'By group'],
    [3, locale === 'es' ? 'Por cliente' : 'By client'],
    [4, locale === 'es' ? 'A usuarios' : 'To users'],
  ])
