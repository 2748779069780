import DateFnsUtils from '@date-io/date-fns'
import { Button, Chip, TextField } from '@material-ui/core'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import Dialog from 'components/Dialog/Dialog'
import LoadingSection from 'components/LoadingSection'
import Select from 'components/common/Select/Select'
import { currencies } from 'constants/currencies'
import { enUS, es } from 'date-fns/locale'
import { formatDate } from 'helpers/date'
import useLocale from 'hooks/useLocale'
import useUsers from 'hooks/useUsers'
import React, { useMemo } from 'react'
import styles from './MarketingCampaignsModal.module.scss'
import useMarketingCampaignsModal from './useMarketingCampaignsModal'

const MarketingCampaignsModal = ({ open, toggleModal }) => {
  const locale = useLocale()
  const { companyUsers } = useUsers()

  const companyUsersEntities = useMemo(
    () =>
      companyUsers.reduce((acc, _user) => {
        acc[_user.idUser] = _user
        return acc
      }, {}),
    [companyUsers]
  )

  const {
    addingTitle,
    editingTitle,
    form,
    formLabels,
    addButtonTitle,
    editButtonTitle,
    pending,
    handleChangeForm,
    handleDeleteManager,
    handleSubmit,
    handleClose,
    selectedMarketingCampaign,
  } = useMarketingCampaignsModal(toggleModal)

  return (
    <Dialog open={open} handleCloseAction={handleClose} title={selectedMarketingCampaign ? editingTitle : addingTitle}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <TextField
          fullWidth
          required
          label={formLabels.name}
          variant="outlined"
          name="name"
          value={form?.name}
          onChange={(e) => handleChangeForm(e, e.target.value)}
        />

        <TextField
          fullWidth
          required
          label={formLabels.description}
          variant="outlined"
          name="description"
          value={form?.description}
          onChange={(e) => handleChangeForm(e, e.target.value)}
          minRows={4}
          maxRows={5}
          multiline
        />

        <TextField
          fullWidth
          label={formLabels.cost}
          variant="outlined"
          name="cost"
          value={form?.cost ? +form?.cost : ''}
          onChange={(e) => handleChangeForm(e, e.target.value)}
          type="number"
          inputProps={{
            step: 0.01,
            min: 0,
            max: 10000000000000,
          }}
        />

        <Select
          options={currencies()}
          name="currency"
          label={formLabels.currency}
          value={form?.currency}
          onChange={(e) => handleChangeForm(e, e.target.value)}
        />

        {companyUsers?.length ? (
          <section className={styles.managerSelector}>
            <Select
              options={companyUsers?.reduce(
                (acc, _user) => [...acc, { value: _user.idUser, text: `${_user.firstName} ${_user.lastName}` }],
                []
              )}
              name="managers"
              label={formLabels?.manager}
              value={form?.managers}
              onChange={(e) => handleChangeForm(e, e.target.value)}
              isRequired={false}
              multiple
            />

            <div className={styles.chipSection}>
              {form?.managers?.length ? (
                <>
                  {form.managers.map((_manager) => (
                    <Chip
                      key={_manager}
                      color="primary"
                      onDelete={() => handleDeleteManager(_manager)}
                      label={`${companyUsersEntities[_manager].firstName} ${companyUsersEntities[_manager].lastName}`}
                    />
                  ))}
                </>
              ) : (
                <p className={styles.emptyManagers}>
                  {locale === 'es' ? 'No hay encargados seleccionados...' : 'No managers selected...'}
                </p>
              )}
            </div>
          </section>
        ) : null}

        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale === 'es' ? es : enUS}>
          <DateTimePicker
            fullWidth
            required
            format="dd MMMM, yyyy | HH:mm"
            inputVariant="outlined"
            value={form?.startDate}
            label={formLabels?.startDate}
            onChange={(e) => handleChangeForm('startDate', formatDate(e, null, true))}
            minutesStep={5}
            maxDate={form?.endDate ?? new Date('2100-01-01')}
            hideTabs
            inputProps={{
              style: {
                cursor: 'pointer',
              },
            }}
          />
          <DateTimePicker
            fullWidth
            clearable
            format="dd MMMM, yyyy | HH:mm"
            inputVariant="outlined"
            value={form?.endDate}
            label={formLabels?.endDate}
            onChange={(e) => handleChangeForm('endDate', e ? formatDate(e, null, true) : null)}
            minutesStep={5}
            minDate={form?.startDate}
            hideTabs
            inputProps={{
              style: {
                cursor: 'pointer',
              },
            }}
          />
        </MuiPickersUtilsProvider>

        {pending ? (
          <LoadingSection />
        ) : (
          <Button fullWidth color="primary" variant="contained" type="submit" classes={{ root: styles.button }}>
            {selectedMarketingCampaign ? editButtonTitle : addButtonTitle}
          </Button>
        )}
      </form>
    </Dialog>
  )
}

export default MarketingCampaignsModal
