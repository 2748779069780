import classNames from 'classnames'
import styles from './ChartItem.module.scss'

const ChartItem = ({ chart, selected, handleSelectChart }) => {
  return (
    <div className={classNames(styles.wrapper, selected && styles.selected)} onClick={handleSelectChart}>
      <i className={classNames(chart.icon, styles.icon)} />
      {chart.text}
    </div>
  )
}

export default ChartItem
