const es = {
  fields: {
    employee: 'Nombre',
    date: 'Fecha de pago',
    month: 'Mes',
    workedHours: 'Horas trabajadas',
    extraHours: 'Horas extra',
    hourlyRate: 'Pago por hora',
    extraHourlyRate: 'Pago por hora extra',
    extraPayment: 'Pagos extra',
    extraPaymentDescription: 'Pagos extra - nota',
    basePayment: 'Pago base',
    deductions: 'Deducciones totales',
    totalPayment: 'Pago total',
    status: 'Estado',
    note: 'Notas',
    hoursPayment: 'Pago por horas trabajadas',
    extraHoursPayment: 'Pago por horas extra trabajadas',
    reference: 'Referencia de pago',
  },
  actions: {
    download: 'Descargar como',
    pdf: 'PDF',
    image: 'PNG',
    email: 'Enviar email',
  },
}

const en = {
  fields: {
    employee: 'Name',
    date: 'Payment date',
    month: 'Month',
    workedHours: 'Worked hours',
    extraHours: 'Extra hours',
    hourlyRate: 'Hourly rate',
    extraHourlyRate: 'Extra hourly rate',
    extraPayment: 'Extra payment',
    extraPaymentDescription: 'Extra payment - note',
    basePayment: 'Base payment',
    deductions: 'Total deductions',
    totalPayment: 'Total payment',
    status: 'Status',
    note: 'Notes',
    hoursPayment: 'Payment per worked hours',
    extraHoursPayment: 'Payment per extra worked hours',
    reference: 'Payment reference',
  },
  actions: {
    download: 'Download',
    pdf: 'PDF',
    image: 'PNG',
    email: 'Send email',
  },
}

const getContent = (locale) => (locale === 'es' ? es : en)

export default getContent
