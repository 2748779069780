import { getRandomColor } from 'constants/colors'
import React from 'react'
import { Area, AreaChart as RechartsAreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { reduceChartData } from './helpers'

const AreaChart = ({ values, chartData }) => {
  const color = getRandomColor(6)
  const xAxis = values?.find((el) => el.attribute === 'xAxis')
  const xAxisKey = xAxis.key

  const data = reduceChartData(chartData, xAxisKey)

  return (
    <ResponsiveContainer>
      <RechartsAreaChart width={730} height={250} data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
        <defs>
          <linearGradient id="grad-color" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={color} stopOpacity={0.8} />
            <stop offset="95%" stopColor={color} stopOpacity={0} />
          </linearGradient>
        </defs>
        <Tooltip />
        <XAxis dataKey="name" />
        <YAxis dataKey="count" />

        <Area isAnimationActive={false} type="monotone" dataKey="count" stroke={color} fillOpacity={1} fill="url(#grad-color)" />
      </RechartsAreaChart>
    </ResponsiveContainer>
  )
}

export default AreaChart
