import {
  DELETE_CUSTOMER,
  GET_CUSTOMERS,
  SET_NEW_CUSTOMER,
  UPDATE_CUSTOMER,
  GET_CONTACTS,
  SET_NEW_CONTACT,
  UPDATE_CONTACT,
  DELETE_CONTACT,
  LOADING_CONTACTS,
  MASSIVE_CUSTOMERS_UPLOAD,
} from 'constants/actionTypes'
import {
  CUSTOMERS_API_ROUTE,
  GET_CUSTOMERS_API_ROUTE,
  GET_CONTACS_API_ROUTE,
  CONTACS_API_ROUTE,
  MASSIVE_CLIENTS_UPLOAD,
} from 'constants/apiRoutes'
import { apiDeleteData, apiFindData, apiPostData, apiPutData } from 'helpers/api'

export const getCustomers = (idCompany) => apiFindData(GET_CUSTOMERS_API_ROUTE, { id: idCompany }, GET_CUSTOMERS)

export const addNewCustomer = (customer) => apiPostData(CUSTOMERS_API_ROUTE, customer, SET_NEW_CUSTOMER)

export const updateCustomer = (customer) =>
  apiPutData(CUSTOMERS_API_ROUTE, { id: customer?.idClient, ...customer }, UPDATE_CUSTOMER)

export const deleteCustomer = (idCustomer) => apiDeleteData(CUSTOMERS_API_ROUTE, { id: idCustomer }, DELETE_CUSTOMER)

export const getContacts = (idCustomer) => apiFindData(GET_CONTACS_API_ROUTE, { id: idCustomer }, GET_CONTACTS)

export const addNewContact = (contact) => apiPostData(CONTACS_API_ROUTE, contact, SET_NEW_CONTACT)

export const updateContact = (contact) => apiPutData(CONTACS_API_ROUTE, { id: contact?.idContact, ...contact }, UPDATE_CONTACT)

export const deleteContact = (idContact) => apiDeleteData(CONTACS_API_ROUTE, { id: idContact }, DELETE_CONTACT)

export const loadingContacts = () => ({ type: LOADING_CONTACTS, payload: { isLoadingContacts: true, contacts: [] } })

export const massiveClientsUpdate = (clients) => apiPostData(MASSIVE_CLIENTS_UPLOAD, { clients }, MASSIVE_CUSTOMERS_UPLOAD)
