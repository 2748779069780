import { deleteStub } from 'actions/humanResources'
import DataGridTable from 'components/DataGridTable/DataGridTable'
import { formatDate } from 'helpers/date'
import useLocale from 'hooks/useLocale'
import useMoney from 'hooks/useMoney'
import React, { useMemo } from 'react'
import StatusChip from '../StatusChip/StatusChip'

const StubsTable = ({
  data: stubs,
  currency,
  handleSelect,
  handlePreview,
  canWrite = true,
  rootClassName = '',
  pageSize = undefined,
}) => {
  const locale = useLocale()
  const { formatMoney } = useMoney()

  const COLUMNS = useMemo(
    () => [
      {
        headerName: locale === 'es' ? 'Fecha de emisión' : 'Date of issue',
        field: 'date',
        width: 200,
        sortable: false,
        flex: window.innerWidth <= 768 ? null : 1,
        renderCell: ({ value }) => formatDate(value, 'D MMMM YYYY', false),
      },
      {
        headerName: locale === 'es' ? 'Pago total' : 'Total payment',
        field: 'totalPayment',
        width: 200,
        sortable: false,
        flex: window.innerWidth <= 768 ? null : 1,
        renderCell: ({ value }) => formatMoney(value, currency),
      },
      {
        headerName: locale === 'es' ? 'Estado' : 'State',
        field: 'IS_PENDING',
        width: 150,
        sortable: false,
        flex: window.innerWidth <= 768 ? null : 1,
        renderCell: ({ value }) => <StatusChip value={value} />,
        headerAlign: 'center',
      },
    ],
    [locale, currency, formatMoney]
  )

  const ACTIONS = useMemo(
    () => ({
      hideBasicActions: !canWrite,
      width: 200,
      field: 'idPaymentStub',
      handlerEdit: ({ selectedObject }) => {
        handleSelect({ ...selectedObject, isEdit: true })
      },
      queryDelete: deleteStub,
      flex: window.innerWidth <= 768 ? null : 1,
      actions: [
        {
          name: locale === 'es' ? 'Previsualizar' : 'Preview',
          icon: 'fa-solid fa-eye',
          onClick: ({ selectedObject }) => handlePreview(selectedObject),
        },
      ],
    }),
    [handleSelect, locale, handlePreview, canWrite]
  )

  const data = stubs
    ?.sort((a, b) => new Date(b.date) - new Date(a.date))
    ?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    .map((el, index) => ({ ...el, index: stubs.length - index }))

  return <DataGridTable columns={COLUMNS} actionsConfig={ACTIONS} data={data} rootClassName={rootClassName} pageSize={pageSize} />
}

export default StubsTable
