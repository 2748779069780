function TotalizationField(es, en, key, fieldKey, groupingMethod, filterCondition) {
  this.es = es
  this.en = en
  this.key = key
  this.fieldKey = fieldKey ?? key
  this.groupingMethod = groupingMethod
  this.filterCondition = filterCondition
}

function ChartField(es, en, key) {
  this.es = es
  this.en = en
  this.key = key
}

function Model(totalizationFields, chartFields) {
  this.totalizationFields = totalizationFields?.map((field) => new TotalizationField(...field)) ?? []
  this.chartFields = chartFields?.map((field) => new ChartField(...field)) ?? []
}

export const FILTER_BY = 'filterByField'
export const FILTER_REVERSE_BY = 'filterReversedByField'
export const GROUP_BY = 'groupByField'
export const GROUP_BY_VALUE = 'groupByValue'
export const GROUP_BY_SUBKEY = 'groupBySubkey'

const CALENDAR_ACTIONS = new Model([
  ['ID', 'ID', 'idCalendarAction', null, FILTER_BY],
  ['Correos masivos', 'Mass emails', 'idMassEmail', null, FILTER_BY],
  ['Posts', 'Posts', 'idPost', null, FILTER_BY],
  ['Eventos', 'Events', 'idCalendarEvent', null, FILTER_BY],
])
const POSTS = new Model(
  [
    ['ID', 'ID', 'idPost', null, FILTER_BY],
    ['Post con imagen', 'Post with image', 'image', 'image', FILTER_BY],
    ['Post sin imagen', 'Post without image', 'image-reverse', 'image', FILTER_REVERSE_BY],
    ['Post pendientes', 'Pending posts', 'pending-posts', 'IS_PENDING', FILTER_BY],
    ['Posts publicados', 'Published posts', 'published-posts', 'IS_PENDING', FILTER_REVERSE_BY],
  ],
  [
    ['ID', 'ID', 'idMassEmail'],
    ['Publicaciones pendientes', 'Pending posts', 'IS_PENDING'],
  ]
)
const CUSTOMERS = new Model(
  [
    ['ID', 'ID', 'idClient', null, FILTER_BY],
    ['País', 'Country', 'country', null, GROUP_BY],
    ['Provincia', 'Province', 'province', null, GROUP_BY],
    ['Distrito', 'District', 'district', null, GROUP_BY],
    ['Canton', 'Cantón', 'canton', null, GROUP_BY],
    ['Cantidad de grupos', 'Group quantity', 'groupNumber', null, GROUP_BY],
  ],
  [
    ['ID', 'ID', 'idClient'],
    ['Grupo', 'Group', 'groupNumber'],
    ['País', 'Country', 'country'],
    ['Provincia', 'Province', 'province'],
    ['Distrito', 'District', 'district'],
    ['Canton', 'Cantón', 'canton'],
  ]
)
const MASS_EMAILS = new Model(
  [
    ['ID', 'ID', 'idMassEmail', null, FILTER_BY],
    ['Emails para todos', 'Emails for everyone', 'all-type', 'type', GROUP_BY_VALUE, (v) => +v === 1],
    ['Emails para grupos', 'Emails for groups', 'group-type', 'type', GROUP_BY_VALUE, (v) => +v === 2],
    ['Emails para clientes', 'Emails for clients', 'client-type', 'type', GROUP_BY_VALUE, (v) => +v === 3],
  ],
  [
    ['ID', 'ID', 'idMassEmail'],
    ['Tipo de correo', 'Mass email type', 'type'],
  ]
)
const FORMS = new Model(
  [
    ['ID', 'ID', 'idForm', null, FILTER_BY],
    ['Formulario públicos', 'Public forms', 'public-forms', 'IS_PUBLIC', FILTER_BY],
    ['Formularios privados', 'Private forms', 'private-forms', 'IS_PUBLIC', FILTER_REVERSE_BY],
  ],
  [
    ['ID', 'ID', 'idForm'],
    ['Público/Privado', 'Public/Private', 'IS_PUBLIC'],
  ]
)
const MARKETING_CAMPAINGS = new Model([['ID', 'ID', 'idMarketingCampaign', null, FILTER_BY]])
const INCOMES_AND_EXPENSES = new Model(
  [
    ['ID', 'ID', 'idProject', null, FILTER_BY],
    ['Proyectos activos', 'Active projects', 'active', 'status', GROUP_BY_VALUE, (v) => +v === 0],
    ['Proyectos pendientes', 'Pending projects', 'pending', 'status', GROUP_BY_VALUE, (v) => +v === 1],
    ['Proyectos culminados', 'Completed projects', 'complete', 'status', GROUP_BY_VALUE, (v) => +v === 2],
  ],
  [
    ['ID', 'ID', 'idProject'],
    ['Estado', 'Status', 'status'],
  ]
)
const QUOTATIONS = new Model(
  [
    ['ID', 'ID', 'idQuotation', null, FILTER_BY],
    ['Cotizaciones pendientes', 'Pending quotations', 'pending', 'status', GROUP_BY_VALUE, (v) => v === 'PENDING'],
    [
      'Cotizaciones en negociación',
      'In negotiation quotations',
      'negotiation',
      'status',
      GROUP_BY_VALUE,
      (v) => v === 'NEGOTIATION',
    ],
    ['Cotizaciones en proceso', 'In process quotations', 'process', 'status', GROUP_BY_VALUE, (v) => v === 'PROCESS'],
    ['Cotizaciones completadas', 'Completed quotations', 'completed', 'status', GROUP_BY_VALUE, (v) => v === 'COMPLETED'],
  ],
  [
    ['ID', 'ID', 'idQuotation'],
    ['Estado', 'Status', 'status'],
  ]
)

/****** SUB-MODULES ******/
const PRODUCTS = new Model(
  [
    ['ID', 'ID', 'idProductCost', null, FILTER_BY],
    ['Producto', 'Product', 'product', 'type', GROUP_BY_VALUE, (v) => +v === 1],
    ['Servicio', 'Service', 'service', 'type', GROUP_BY_VALUE, (v) => +v === 2],
    ['Mano de obra (totales)', 'Workforce (totals)', 'workforces', null, GROUP_BY_SUBKEY],
    ['Materias primas (totales)', 'Raw materials (totals)', 'raw_materials', null, GROUP_BY_SUBKEY],
  ],
  [
    ['ID', 'ID', 'idProductCost'],
    ['Tipo', 'Type', 'type'],
    ['Moneda', 'Currency', 'currency'],
  ]
)
const INDIRECT_COSTS = new Model(
  [
    ['ID', 'ID', 'idIndirectCost', null, FILTER_BY],
    ['Activos', 'Active', 'active', 'status', GROUP_BY_VALUE, (v) => +v === 1],
    ['Inactivos', 'Inactive', 'inactive', 'status', GROUP_BY_VALUE, (v) => +v === 2],
  ],
  [
    ['ID', 'ID', 'idIndirectCost'],
    ['Estado', 'Status', 'status'],
    ['Moneda', 'Currency', 'currency'],
  ]
)

const DEPARTMENTS = new Model(
  [
    ['ID', 'ID', 'idDepartment', null, FILTER_BY],
    ['Departamento sin encargado', 'Department without manager', 'idManager', null, GROUP_BY_VALUE, (v) => !v],
  ],
  [
    ['ID', 'ID', 'idDepartment'],
    ['Encargado', 'Manager', 'idManager'],
  ]
)
const POSITIONS = new Model(
  [['ID', 'ID', 'idPosition', null, FILTER_BY]],
  [
    ['ID', 'ID', 'idPosition'],
    ['Moneda', 'Currency', 'currency'],
  ]
)
const SCHEDULES = new Model(
  [
    ['ID', 'ID', 'idSchedule', null, FILTER_BY],
    ['Con horario', 'With schedule', 'with-range', 'range', GROUP_BY_VALUE, (v) => +v === 1],
    ['Sin horario', 'Without schedule', 'without-range', 'range', GROUP_BY_VALUE, (v) => +v === 2],
  ],
  [
    ['ID', 'ID', 'idSchedule'],
    ['Rango', 'Range', 'range'],
    ['Horas unicas', 'Unique hours', 'HAS_UNIQUE_HOURS'],
  ]
)
const DEDUCTIONS = new Model(
  [
    ['ID', 'ID', 'idDeduction', null, FILTER_BY],
    ['Porcentaje', 'Percentage', 'percentage', null, GROUP_BY_VALUE, (v) => +v > 0],
    ['Fija', 'Fixed', 'fixed', null, GROUP_BY_VALUE, (v) => +v > 0],
  ],
  [
    ['ID', 'ID', 'idDeduction'],
    ['Moneda', 'Currency', 'currency'],
  ]
)
const EMPLOYEES = new Model(
  [
    ['ID', 'ID', 'idEmployee', null, FILTER_BY],
    ['Contrato laboral', 'Work contract', 'contract-worker', 'contract', GROUP_BY_VALUE, (v) => +v === 1],
    [
      'Contrato por servicios profesionales',
      'Contract for professional services',
      'contract-freelancer',
      'contract',
      GROUP_BY_VALUE,
      (v) => +v === 2,
    ],
    ['Con vacaciones', 'With vacations', 'with-vacations', 'HAS_VACATIONS', GROUP_BY_VALUE, (v) => Boolean(v)],
    ['Sin vacaciones', 'Without vacations', 'without-vacations', 'HAS_VACATIONS', GROUP_BY_VALUE, (v) => !Boolean(v)],
    ['Con aguinaldos', 'With bonuses', 'with-bonuses', 'HAS_BONUSES', GROUP_BY_VALUE, (v) => Boolean(v)],
    ['Sin aguinaldos', 'Without bonuses', 'without-bonuses', 'HAS_BONUSES', GROUP_BY_VALUE, (v) => !Boolean(v)],
  ],
  [
    ['ID', 'ID', 'idEmployee'],
    ['Departamento', 'Department', 'idDepartment'],
    ['Posicion', 'Position', 'idPosition'],
    ['Horario', 'Schedule', 'idSchedule'],
    ['País', 'Country', 'country'],
    ['Tipo de contrato', 'Contract type', 'contract'],
    ['Moneda', 'Currency', 'currency'],
    ['Vacaciones', 'Vacations', 'HAS_VACATIONS'],
    ['Aguinaldos', 'Bonuses', 'HAS_BONUSES'],
  ]
)
const EVENTS = new Model(
  [
    ['ID', 'ID', 'idEvent', null, FILTER_BY],
    ['Eventos', 'Events', 'event', 'type', GROUP_BY_VALUE, (v) => +v === 1],
    ['Feriados', 'Holidays', 'holiday', 'type', GROUP_BY_VALUE, (v) => +v === 2],
  ],
  [
    ['ID', 'ID', 'idEvent'],
    ['Tipo', 'Type', 'type'],
  ]
)

const CATEGORIES = new Model([['ID', 'ID', 'idInventoryCategory', null, FILTER_BY]], [['ID', 'ID', 'idInventoryCategory']])
const PROVIDERS = new Model([['ID', 'ID', 'idInventoryProvider', null, FILTER_BY]], [['ID', 'ID', 'idInventoryProvider']])
const INVETORY_PRODUCTS = new Model(
  [['ID', 'ID', 'idInventoryProduct', null, FILTER_BY]],
  [
    ['ID', 'ID', 'idInventoryProduct'],
    ['Moneda', 'Currency', 'currency'],
  ]
)

const PERSONAL_FINANCES_CATEGORIES = () => [
  ['Educación', 'Education', 'education', 'category', GROUP_BY_VALUE, (v) => v === 'education'],
  ['Comida', 'Food', 'food', 'category', GROUP_BY_VALUE, (v) => v === 'food'],
  ['Tecnología', 'Tech', 'tech', 'category', GROUP_BY_VALUE, (v) => v === 'tech'],
  ['Salud', 'Health', 'health', 'category', GROUP_BY_VALUE, (v) => v === 'health'],
  ['Otros', 'Others', 'others', 'category', GROUP_BY_VALUE, (v) => v === 'others'],
]

const TRANSACTIONS = new Model(
  [['ID', 'ID', 'idPersonalFinanceTransaction', null, FILTER_BY], ...PERSONAL_FINANCES_CATEGORIES()],
  [
    ['ID', 'ID', 'idPersonalFinanceTransaction'],
    ['Tipo', 'Type', 'transactionType'],
    ['Categoria', 'Category', 'category'],
  ]
)
const SAVINGS = new Model(
  [['ID', 'ID', 'idPersonalFinanceSaving', null, FILTER_BY], ...PERSONAL_FINANCES_CATEGORIES()],
  [
    ['ID', 'ID', 'idPersonalFinanceSaving'],
    ['Categoria', 'Category', 'category'],
  ]
)
const CREDITS = new Model(
  [['ID', 'ID', 'idPersonalFinanceCredit', null, FILTER_BY], ...PERSONAL_FINANCES_CATEGORIES()],
  [
    ['ID', 'ID', 'idPersonalFinanceCredit'],
    ['Categoria', 'Category', 'category'],
  ]
)
const CARDS = new Model(
  [
    ['ID', 'ID', 'idPersonalFinanceCard', null, FILTER_BY],
    ['Débito', 'Debit', 'debit', 'type', GROUP_BY_VALUE, (v) => v === 'DEBIT'],
    ['Crédito', 'Credit', 'credit', 'type', GROUP_BY_VALUE, (v) => v === 'CREDIT'],
    ['Visa', 'Visa', 'visa', 'model', GROUP_BY_VALUE, (v) => v === 'visa'],
    ['Master Card', 'master Card', 'Master Card', 'model', GROUP_BY_VALUE, (v) => v === 'mastercard'],
    ['Alipay', 'Alipay', 'alipay', 'model', GROUP_BY_VALUE, (v) => v === 'alipay'],
    ['American Express', 'american-express', 'American Express', 'model', GROUP_BY_VALUE, (v) => v === 'american_express'],
    ['Discover', 'Discover', 'discover', 'model', GROUP_BY_VALUE, (v) => v === 'discover'],
    ['Hiper', 'Hiper', 'hiper', 'model', GROUP_BY_VALUE, (v) => v === 'hiper'],
    ['Maestro', 'Maestro', 'maestro', 'model', GROUP_BY_VALUE, (v) => v === 'maestro'],
    ['Mir', 'Mir', 'mir', 'model', GROUP_BY_VALUE, (v) => v === 'mir'],
    ['PayPal', 'PayPal', 'payPal', 'model', GROUP_BY_VALUE, (v) => v === 'paypal'],
  ],
  [
    ['ID', 'ID', 'idPersonalFinanceCard'],
    ['Modelo', 'Model', 'model'],
    ['Proveedor', 'Provider', 'provider'],
    ['Tipo', 'Type', 'type'],
  ]
)

const BOARDS = new Model(
  [
    ['ID', 'ID', 'idBoard', null, FILTER_BY],
    ['Públicos', 'Public', 'public-boards', 'IS_PRIVATE', GROUP_BY_VALUE, (v) => !Boolean(v)],
    ['Privados', 'Private', 'private-boards', 'IS_PRIVATE', GROUP_BY_VALUE, (v) => Boolean(v)],
    ['Tareas (totales)', 'Tasks (totals)', 'tasks', null, GROUP_BY_SUBKEY],
    ['Listas (totales)', 'Lists (totals)', 'lists', null, GROUP_BY_SUBKEY],
  ],
  [
    ['ID', 'ID', 'idBoard'],
    ['Privado/Público', 'Private/Public', 'IS_PRIVATE'],
  ]
)
const BOARDS_TEMPLATES = new Model([['ID', 'ID', 'idBoardTemplate', null, FILTER_BY]], [['ID', 'ID', 'idBoardTemplate']])

const CASH_FLOW_INCOMES = new Model(
  [
    ['ID', 'ID', 'idCashFlowIncome', null, FILTER_BY],
    ['Ingresos', 'Incomes', 'incomes', 'type', GROUP_BY_VALUE, (v) => +v === 1],
    ['Ventas', 'Sales', 'event', 'sales', GROUP_BY_VALUE, (v) => +v === 2],
    ['Ventas de productos', 'Products sales', 'product-sales', 'type', GROUP_BY_VALUE, (v) => +v === 3],
  ],
  [
    ['ID', 'ID', 'idCashFlowIncome'],
    ['Tipo', 'Type', 'type'],
  ]
)
const CASH_FLOW_EXPENSES = new Model(
  [
    ['ID', 'ID', 'idCashFlowExpense', null, FILTER_BY],
    ['Gastos', 'Expenses', 'expenses', 'type', GROUP_BY_VALUE, (v) => +v === 1],
    ['Compras', 'Purchases', 'purchases', 'type', GROUP_BY_VALUE, (v) => +v === 2],
    ['Gastos recurrentes', 'Recurrent expenses', 'recurrent-expenses', 'type', GROUP_BY_VALUE, (v) => +v === 3],
  ],
  [
    ['ID', 'ID', 'idCashFlowExpense'],
    ['Tipo', 'Type', 'type'],
  ]
)

const DB_TABLES_MODELS = {
  calendar_actions: CALENDAR_ACTIONS,
  posts: POSTS,
  customers: CUSTOMERS,
  mass_emails: MASS_EMAILS,
  forms: FORMS,
  marketing_campaigns: MARKETING_CAMPAINGS,
  projects: INCOMES_AND_EXPENSES,
  quotations: QUOTATIONS,
  'costs/productsCosts': PRODUCTS,
  'costs/indirectCosts': INDIRECT_COSTS,
  'personal_finances/personalFinanceTransactions': TRANSACTIONS,
  'personal_finances/personalFinanceSavings': SAVINGS,
  'personal_finances/personalFinanceCredits': CREDITS,
  'personal_finances/personalFinanceCards': CARDS,
  'human_resources/departments': DEPARTMENTS,
  'human_resources/positions': POSITIONS,
  'human_resources/schedules': SCHEDULES,
  'human_resources/deductions': DEDUCTIONS,
  'human_resources/employees': EMPLOYEES,
  'human_resources/events': EVENTS,
  'inventory/categories': CATEGORIES,
  'inventory/providers': PROVIDERS,
  'inventory/products': INVETORY_PRODUCTS,
  'boards/boards': BOARDS,
  'boards/boardTemplates': BOARDS_TEMPLATES,
  'cash_flows/baseIncomes': CASH_FLOW_INCOMES,
  'cash_flows/baseExpenses': CASH_FLOW_EXPENSES,
}

export default DB_TABLES_MODELS
