import { useEffect, useRef } from 'react'

const usePrevious = (value, dontUpdate) => {
  const ref = useRef()

  useEffect(() => {
    if (dontUpdate && ref.current) return

    ref.current = value
  })

  return ref.current
}

export default usePrevious
