import { Button, IconButton, Popover, Tooltip } from '@material-ui/core'
import classNames from 'classnames'
import LoadingSection from 'components/LoadingSection'
import useLocale from 'hooks/useLocale'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useGetFolderInfo, useUpdateFile } from '../useDriveApi'
import styles from './MovePopup.module.scss'

const getFolderId = (parents) => parents?.[0] ?? undefined

const MovePopup = ({ open, onClose, selectedResource, anchorEl, onCloseContextMenu }) => {
  const locale = useLocale()
  const { mutateAsync: updateFile, isLoading: isUpdating } = useUpdateFile()
  const { parents } = selectedResource || {}
  const parentFolderId = getFolderId(parents)
  const [folderId, setFolderId] = useState(parentFolderId)
  const [selectedFolder, setSelectedFolder] = useState(parentFolderId)
  const { data, isLoading } = useGetFolderInfo(folderId)
  const { current, children } = data || {}

  const navigateToFolder = (id) => {
    setFolderId(id)
  }

  const goBack = () => {
    navigateToFolder(getFolderId(current?.parents))
  }

  const handleSelectFolder = (id) => {
    setSelectedFolder(id)
  }

  const goNext = (id) => {
    navigateToFolder(id)
    setSelectedFolder(id)
  }

  const handleMove = async () => {
    const { parents, id } = selectedResource
    const currentParent = getFolderId(parents)

    if (currentParent === selectedFolder) {
      toast.info(locale === 'es' ? 'El archivo ya se encuentra en esta carpeta' : 'The file is already in this folder')

      return
    }

    const params = {
      addParents: selectedFolder,
      removeParents: parents?.join(',') || '',
    }

    await updateFile({ fileId: id, folderId: selectedFolder, params })
    onClose()
    onCloseContextMenu()
  }

  useEffect(() => {
    if (parentFolderId) setFolderId(parentFolderId)

    return () => {
      setFolderId(null)
    }
  }, [parentFolderId])

  useEffect(() => {
    if (!open) {
      setSelectedFolder(parentFolderId || undefined)
      setFolderId(parentFolderId || undefined)
    }
  }, [open, parentFolderId])

  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      classes={{
        paper: styles.paper,
      }}
    >
      {isLoading || isUpdating ? (
        <div className={styles.loadingState}>
          <LoadingSection />
        </div>
      ) : (
        <>
          <header className={styles.header}>
            {current?.parents && (
              <Tooltip arrow placement="top" title="Ir atras">
                <IconButton onClick={goBack}>
                  <i className="fas fa-arrow-left" />
                </IconButton>
              </Tooltip>
            )}

            <p>{current?.name}</p>
          </header>

          <section className={styles.foldersSection}>
            {children?.files?.map((folder) => (
              <div
                key={folder.id}
                className={classNames(styles.folder, selectedFolder === folder?.id && styles.folderActive)}
                onClick={() => handleSelectFolder(folder?.id)}
              >
                <img alt="" src={folder.iconLink} />
                <p>{folder.name}</p>
                <IconButton size="small" onClick={() => goNext(folder?.id)}>
                  <i className="fas fa-arrow-right" />
                </IconButton>
              </div>
            ))}
          </section>

          <footer className={styles.footer}>
            <Button size="small" onClick={onClose}>
              {locale === 'es' ? 'Cancelar' : 'Cancel'}
            </Button>

            <Button size="small" onClick={handleMove} variant="contained" color="primary">
              {locale === 'es' ? 'Mover' : 'Move'}
            </Button>
          </footer>
        </>
      )}
    </Popover>
  )
}

export default MovePopup
