import { formatDate } from 'helpers/date'
import useCalendarActions from 'hooks/useCalendarActions'
import useFacebookConfigs from 'hooks/useFacebookConfigs'
import useUsers from 'hooks/useUsers'
import { useCallback, useEffect, useMemo } from 'react'
import { useImmer } from 'use-immer'

const COEFF = 1000 * 60 * 10

const useScheduleModal = ({ day, month, year, idMarketingCampaign }) => {
  const { selectedCalendarAction } = useCalendarActions()
  const {
    myUser: {
      idUser,
      company: { idCompany },
    },
    companyUsers,
  } = useUsers()
  const { fbPageSelected } = useFacebookConfigs()

  const defaultCompanyUsers = useMemo(() => companyUsers.map((_user) => _user.idUser), [companyUsers])

  const INITIAL_STATE = useMemo(
    () => ({
      general: {
        id: 0,
        name: '',
        type: 1,
        time: new Date(Math.ceil(new Date().getTime() / COEFF) * COEFF),
        recurring: false,
        recurringType: 1,
        notInfinite: false,
        startDate: formatDate(`${year}-${month}-${day} 00:00:00`, undefined, true),
        endDate: null,
        idCompany,
        idUser,
        idMarketingCampaign,
      },
      massEmail: {
        idCompany,
        idUser,
        idMarketingCampaign,
        subject: '',
        type: 1,
        filterValue: '',
        contentData: '',
        file: null,
        image: null,
      },
      post: {
        idCompany,
        idUser,
        idMarketingCampaign,
        text: '',
        image_url: null,
        idFbPage: +fbPageSelected ?? 1,
        socialNetworks: 1,
      },
      marketingTask: {
        idMarketingCampaign,
        content: '',
        users: [],
      },
      event: {
        description: '',
        date: formatDate(`${year}-${month}-${day} 00:00:00`, undefined, true),
        startTime: formatDate(new Date(Math.ceil(new Date().getTime() / COEFF) * COEFF), 'HH:mm', true),
        endTime: formatDate(new Date(Math.ceil(new Date().getTime() / COEFF) * COEFF), 'HH:mm', true),
        isAllDay: false,
        notes: '',
        file: null,
        users: defaultCompanyUsers,
        reminders: [],
      },
    }),
    [day, year, month, idCompany, idUser, fbPageSelected, idMarketingCampaign, defaultCompanyUsers]
  )

  const [form, setForm] = useImmer(INITIAL_STATE)

  const cleanForm = useCallback(() => setForm(INITIAL_STATE), [INITIAL_STATE, setForm])

  useEffect(() => {
    cleanForm()
  }, [cleanForm])

  useEffect(() => {
    if (selectedCalendarAction) {
      const { idCalendarAction, startDate, endDate, IS_RECURRING, idMassEmail, originalId, originalDate, event } =
        selectedCalendarAction

      setForm({
        general: {
          ...selectedCalendarAction,
          id: idCalendarAction ? idCalendarAction : originalId,
          idCalendarAction: idCalendarAction ? idCalendarAction : originalId,
          time: new Date(Math.ceil(new Date(originalDate ?? startDate).getTime() / COEFF) * COEFF),
          notInfinite: !!endDate,
          type: idMassEmail ? 1 : 2,
          recurring: IS_RECURRING,
          startDate: originalDate ?? startDate,
          endDate: endDate,
        },
        event,
      })
    } else {
      cleanForm()
    }
  }, [selectedCalendarAction, setForm, cleanForm])

  return [form, setForm, cleanForm]
}

export default useScheduleModal
