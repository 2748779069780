import { differenceInMonths } from 'date-fns'
import { checkEndDate } from 'helpers/relativeDate'
import { useMemo } from 'react'

const getTakenVacations = (freeTimeRecords) => {
  const filterByApproved = (record) => +record.status === 2 || +record.status === 1
  const filterByType = (record) => +record.type === 1

  const reduce = (acc, record) => acc + record.totalDays

  return freeTimeRecords?.filter(filterByType)?.filter(filterByApproved)?.reduce(reduce, 0)
}

export const useVacations = (employee) => {
  const { free_time, startTime: start, endTime: end, HAS_VACATIONS } = employee

  const startDate = useMemo(() => new Date(start), [start])
  const endDate = useMemo(() => checkEndDate(end), [end])

  const accumulatedVacations = useMemo(
    () => (HAS_VACATIONS ? differenceInMonths(endDate, startDate) : 0),
    [HAS_VACATIONS, startDate, endDate]
  )

  const takenVacations = useMemo(() => (HAS_VACATIONS ? getTakenVacations(free_time) : 0), [HAS_VACATIONS, free_time])

  const availableVacations = useMemo(() => {
    if (!HAS_VACATIONS || !accumulatedVacations) return 0
    const value = accumulatedVacations - takenVacations

    return value <= 0 ? 0 : value
  }, [HAS_VACATIONS, accumulatedVacations, takenVacations])

  return {
    accumulatedVacations,
    takenVacations,
    availableVacations,
  }
}
