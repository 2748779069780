import { Button } from '@material-ui/core'
import { sendSuccessMessage } from 'actions/modals'
import LoadingSection from 'components/LoadingSection'
import useLocale from 'hooks/useLocale'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import styles from './ScheduleModalStep.module.scss'

const ScheduleModalStep = ({ renderComponent, title, onClick, nextStep = false, onNext = null, form }) => {
  const locale = useLocale()
  const dispatch = useDispatch()
  const [pendingAction, setPendingAction] = useState(false)

  const buttonMessage = nextStep ? (locale === 'es' ? 'Siguiente' : 'Next') : locale === 'es' ? 'Agendar' : 'Schedule'

  return (
    <main className={styles.mainContainer}>
      <h2 className={styles.title}>{title}</h2>
      {renderComponent}

      <section className={styles.actionSection}>
        {pendingAction ? (
          <LoadingSection />
        ) : (
          <Button
            fullWidth
            variant="contained"
            color="primary"
            classes={{ root: styles.buttonRoot }}
            onClick={async () => {
              if (nextStep) {
                if (typeof nextStep === 'function') {
                  let valid = nextStep(form)

                  if (!valid) return
                }

                return onNext()
              }

              setPendingAction(true)
              onClick().then(() => {
                dispatch(sendSuccessMessage(locale === 'es' ? 'Acción creada' : 'Action created'))
                setPendingAction(false)
              })
            }}
          >
            {buttonMessage}
          </Button>
        )}
      </section>
    </main>
  )
}

export default ScheduleModalStep
