import { formatDate } from 'helpers/date'
import { useCallback } from 'react'

const useForm = (setter) => {
  const handleChange = useCallback(
    (e, key, type = 'text') => {
      const value = () => {
        switch (type) {
          case 'text':
            return e.target.value
          case 'number':
            if (e.target) {
              const {
                target: { value },
              } = e
              if (value === 0) return 0

              return value ? +value : ''
            } else {
              return e ? +e : ''
            }

          case 'date':
            return e ? formatDate(e?.target?.value ?? e, undefined, true) : null
          default:
            return ''
        }
      }
      setter((prev) => ({ ...prev, [key]: value() }))
    },
    [setter]
  )

  return handleChange
}

export default useForm
