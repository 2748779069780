import useLocale from 'hooks/useLocale'
import MonthlyHours from 'pages/HumanResourcesPage/Tabs/Employees/WorkedHours/MonthlyHours'
import React from 'react'
import Section from '../Section'

const ByMonth = ({ employee }) => {
  const locale = useLocale()
  const { work_day: data } = employee

  return (
    <Section title={locale === 'es' ? 'Por mes' : 'By month'}>
      <MonthlyHours data={data?.filter((workDay) => Boolean(workDay.endTime))} />
    </Section>
  )
}

export default ByMonth
