import React, { useState } from 'react'
import styles from './MarketingCampaignViews.module.scss'
import { Tab, Tabs } from '@material-ui/core'
import ScheduleModal from 'components/ScheduleModal/ScheduleModal'
import useToggle from 'hooks/useToggle'
import useLocale from 'hooks/useLocale'
import MarketingCampaignCalendarView from './MarketingCampaignCalendarView'
import MarketingCampaignListView from './MarketingCampaignListView'

const MarketingCampaignViews = ({ idMarketingCampaign, calendarActions }) => {
  const locale = useLocale()
  const [actionsModal, setActionsModal] = useToggle(false)
  const [tab, setTab] = useState(0)
  const [dateValues, setDateValues] = useState({ day: 0, month: 0, year: 0 })

  const openScheduleAction = (day, month, year) => {
    setActionsModal()
    setDateValues({
      day,
      month,
      year,
    })
  }

  const SECTIONS = {
    0: () => <MarketingCampaignCalendarView calendarActions={calendarActions} openScheduleAction={openScheduleAction} />,
    1: () => <MarketingCampaignListView calendarActions={calendarActions} setActionsModal={setActionsModal} />,
  }

  return (
    <>
      <section className={styles.viewsContainer}>
        <header className={styles.header}>
          <h2 className={styles.title}>{locale === 'es' ? 'Planeamiento' : 'Planning'}</h2>

          <Tabs value={tab} onChange={(_, value) => setTab(value)} textColor="primary" indicatorColor="primary" centered>
            <Tab
              classes={{ root: styles.tab, selected: styles.tabSelected }}
              label={locale === 'es' ? 'Calendario' : 'Calendar'}
            />
            <Tab classes={{ root: styles.tab, selected: styles.tabSelected }} label={locale === 'es' ? 'Lista' : 'List'} />
          </Tabs>
        </header>

        <section className={styles.viewSection}>{SECTIONS[tab]()}</section>
      </section>

      <ScheduleModal
        open={actionsModal}
        setOpen={setActionsModal}
        idMarketingCampaign={idMarketingCampaign}
        day={dateValues.day}
        month={dateValues.month}
        year={dateValues.year}
      />
    </>
  )
}

export default MarketingCampaignViews
