import {
  DELETE_BOARD,
  DELETE_BOARD_LABEL,
  DELETE_BOARD_LIST,
  DELETE_BOARD_TASK,
  DELETE_BOARD_TASK_FILE,
  DELETE_BOARD_TEMPLATE,
  DELETE_BOARD_TEMPLATE_COLUMN,
  GET_BOARDS,
  GET_BOARD_TASKS,
  GET_BOARD_TEMPLATES,
  MOVE_COLUMN,
  UPDATE_BOARD,
  UPDATE_BOARD_LABEL,
  UPDATE_BOARD_LIST,
  UPDATE_BOARD_TASK,
  UPDATE_BOARD_TASKS_POSITION,
  UPDATE_BOARD_TASK_COMMENT,
  UPDATE_BOARD_TEMPLATE,
  UPDATE_BOARD_TEMPLATE_COLUMN,
  UPLOAD_BOARD,
  UPLOAD_BOARD_LABEL,
  UPLOAD_BOARD_LIST,
  UPLOAD_BOARD_TASK,
  UPLOAD_BOARD_TASK_COMMENT,
  UPLOAD_BOARD_TASK_FILE,
  UPLOAD_BOARD_TASK_HISTORY,
  UPLOAD_BOARD_TEMPLATE,
  UPLOAD_BOARD_TEMPLATE_COLUMN,
} from 'constants/actionTypes'

const initialState = {
  isLoadingBoards: true,
  isLoadingBoardTemplates: true,
  boards: [],
  boardTemplates: [],
}

const handlers = {
  [GET_BOARDS]: (state, { payload = {} }) => {
    if (!payload?.boards) return { ...state, errorInRequest: true, isLoadingBoards: false }

    return { ...state, boards: payload?.boards, errorInRequest: false, isLoadingBoards: false }
  },
  [UPLOAD_BOARD]: (state, { payload = {} }) => {
    if (!payload?.board) return { ...state, errorInRequest: true }

    return { ...state, boards: [payload?.board, ...state.boards], errorInRequest: false }
  },
  [UPDATE_BOARD]: (state, { payload = {} }) => {
    if (!payload?.board) return { ...state, errorInRequest: true }

    return {
      ...state,
      boards: state.boards.map((board) => (board.idBoard === payload?.board?.idBoard ? payload?.board : board)),
      errorInRequest: false,
    }
  },
  [DELETE_BOARD]: (state, { payload = {} }) => {
    if (!payload?.board) return { ...state, errorInRequest: true }

    return {
      ...state,
      boards: state.boards.filter(({ idBoard }) => idBoard !== payload?.board?.idBoard),
      errorInRequest: false,
    }
  },
  [GET_BOARD_TEMPLATES]: (state, { payload = {} }) => {
    if (!payload?.boardTemplates) return { ...state, errorInRequest: true, isLoadingBoardTemplates: false }

    return { ...state, boardTemplates: payload?.boardTemplates, errorInRequest: false, isLoadingBoardTemplates: false }
  },
  [UPLOAD_BOARD_TEMPLATE]: (state, { payload = {} }) => {
    if (!payload?.template) return { ...state, errorInRequest: true }

    return { ...state, boardTemplates: [...state.boardTemplates, { ...payload?.template, columns: [] }], errorInRequest: false }
  },
  [UPDATE_BOARD_TEMPLATE]: (state, { payload = {} }) => {
    if (!payload?.template) return { ...state, errorInRequest: true }

    return {
      ...state,
      boardTemplates: state.boardTemplates.map((boardTemplate) =>
        boardTemplate.idBoardTemplate === payload?.template?.idBoardTemplate
          ? { ...payload?.template, columns: boardTemplate?.columns }
          : boardTemplate
      ),
      errorInRequest: false,
    }
  },
  [DELETE_BOARD_TEMPLATE]: (state, { payload = {} }) => {
    if (!payload?.template) return { ...state, errorInRequest: true }
    return {
      ...state,
      boardTemplates: state.boardTemplates.filter(
        ({ idBoardTemplate }) => idBoardTemplate !== payload?.template?.idBoardTemplate
      ),
      errorInRequest: false,
    }
  },
  [UPLOAD_BOARD_TEMPLATE_COLUMN]: (state, { payload = {} }) => {
    if (!payload?.column) return { ...state, errorInRequest: true }

    const templateId = payload?.column?.idBoardTemplate

    return {
      ...state,
      boards: state.boards.map((board) => {
        if (board?.template?.idBoardTemplate === payload?.column?.idBoardTemplate) {
          return {
            ...board,
            template: {
              ...board?.template,
              columns: [...board?.template?.columns, payload?.column],
            },
          }
        }

        return board
      }),
      boardTemplates: state.boardTemplates.map((_template) => {
        if (_template.idBoardTemplate === templateId) {
          return {
            ..._template,
            columns: [..._template?.columns, payload?.column],
          }
        } else return _template
      }),
    }
  },
  [UPDATE_BOARD_TEMPLATE_COLUMN]: (state, { payload = {} }) => {
    if (!payload?.column) return { ...state, errorInRequest: true }

    return {
      ...state,
      boards: state.boards.map((board) => {
        if (board?.template?.idBoardTemplate === payload?.column?.idBoardTemplate) {
          return {
            ...board,
            template: {
              ...board?.template,
              columns: board?.template?.columns?.map((column) =>
                column.idBoardTemplateColumn === payload?.column?.idBoardTemplateColumn ? payload?.column : column
              ),
            },
          }
        }

        return board
      }),
      boardTemplates: state.boardTemplates.map((_template) => {
        if (_template?.idBoardTemplate === payload?.column?.idBoardTemplate) {
          return {
            ..._template,
            columns: _template?.columns?.map((_column) =>
              _column.idBoardTemplateColumn === payload?.column?.idBoardTemplateColumn ? payload?.column : _column
            ),
          }
        }

        return _template
      }),
    }
  },
  [DELETE_BOARD_TEMPLATE_COLUMN]: (state, { payload = {} }) => {
    if (!payload?.column) return { ...state, errorInRequest: true }

    const templateId = payload?.column?.idBoardTemplate
    const columnId = payload?.column?.idBoardTemplateColumn

    return {
      ...state,
      boards: state.boards.map((board) => {
        if (board?.template?.idBoardTemplate === payload?.column?.idBoardTemplate) {
          return {
            ...board,
            tasks: board?.tasks?.filter((_task) => _task.idBoardTemplateColumn !== columnId),
            template: {
              ...board?.template,
              columns: board?.template?.columns.filter(
                ({ idBoardTemplateColumn }) => idBoardTemplateColumn !== payload?.column?.idBoardTemplateColumn
              ),
            },
          }
        }

        return board
      }),
      boardTemplates: state.boardTemplates.map((_template) => {
        if (_template.idBoardTemplate === templateId) {
          return {
            ..._template,
            columns: _template.columns.filter((_column) => _column.idBoardTemplateColumn !== columnId),
          }
        } else return _template
      }),
    }
  },
  [MOVE_COLUMN]: (state, { payload = {} }) => {
    const { columns, templateId } = payload

    function updateColumn(_column) {
      const column = columns.find((column) => column.idBoardTemplateColumn === _column.idBoardTemplateColumn)
      if (!column) return _column

      return { ..._column, position: column.position }
    }

    return {
      ...state,
      boards: state.boards.map((board) => {
        if (board?.template?.idBoardTemplate === templateId) {
          return {
            ...board,
            template: {
              ...board?.template,
              columns: board?.template?.columns?.map(updateColumn),
            },
          }
        }

        return board
      }),
      boardTemplates: state.boardTemplates.map((_template) => {
        if (_template?.idBoardTemplate === templateId) {
          return {
            ..._template,
            columns: _template?.columns?.map(updateColumn),
          }
        }

        return _template
      }),
    }
  },
  [GET_BOARD_TASKS]: (state, { payload = {} }) => {
    const boardId = payload.boardId
    const boards = state.boards?.map((board) => {
      if (board.idBoard === boardId) {
        return { ...board, tasks: payload?.tasks }
      }

      return board
    })

    return { ...state, boards }
  },
  [UPLOAD_BOARD_TASK]: (state, { payload = {} }) => {
    if (!payload?.task) return { ...state, errorInRequest: true }

    return {
      ...state,
      boards: state.boards.map((board) => {
        if (board.idBoard === payload.task?.idBoard) {
          return {
            ...board,
            tasks: [...board.tasks, payload?.task],
          }
        }

        return board
      }),
    }
  },
  [UPDATE_BOARD_TASK]: (state, { payload = {} }) => {
    if (!payload?.task) return { ...state, errorInRequest: true }

    return {
      ...state,
      boards: state.boards.map((board) => {
        if (board.idBoard === payload.task?.idBoard) {
          return {
            ...board,
            tasks: board.tasks.map((el) =>
              el?.idBoardTask === payload?.task?.idBoardTask
                ? {
                    ...payload?.task,
                    idBoard: payload?.task?.destinationIdBoard ? payload?.task?.destinationIdBoard : payload?.task?.idBoard,
                  }
                : el
            ),
          }
        }

        return board
      }),
    }
  },
  [UPDATE_BOARD_TASKS_POSITION]: (state, { payload = {} }) => {
    if (!payload?.board) return { ...state, errorInRequest: true }

    return {
      ...state,
      boards: state.boards.map((board) => {
        if (board.idBoard === payload.board?.idBoard) {
          return {
            ...board,
            tasks: payload?.board?.updatedTasks,
          }
        }

        return board
      }),
    }
  },
  [DELETE_BOARD_TASK]: (state, { payload = {} }) => {
    if (!payload?.task) return { ...state, errorInRequest: true }

    return {
      ...state,
      boards: state.boards.map((board) => {
        if (board.idBoard === payload.task?.idBoard) {
          return {
            ...board,
            tasks: board.tasks.filter((el) => el?.idBoardTask !== payload?.task?.idBoardTask),
          }
        }

        return board
      }),
    }
  },
  [UPLOAD_BOARD_LABEL]: (state, { payload = {} }) => {
    if (!payload?.label) return { ...state, errorInRequest: true }

    return {
      ...state,
      boards: state.boards.map((board) =>
        board.idBoard === payload?.label?.idBoard
          ? {
              ...board,
              labels: [...board.labels, payload?.label],
            }
          : board
      ),
    }
  },
  [DELETE_BOARD_LABEL]: (state, { payload = {} }) => {
    if (!payload?.boards) return { ...state, errorInRequest: true }

    return {
      ...state,
      boards: payload?.boards,
    }
  },
  [UPDATE_BOARD_LABEL]: (state, { payload = {} }) => {
    if (!payload?.label) return { ...state, errorInRequest: true }

    return {
      ...state,
      boards: state.boards.map((board) =>
        board.idBoard === payload?.label?.idBoard
          ? {
              ...board,
              labels: board.labels.map((el) => (el.idBoardLabel === payload?.label?.idBoardLabel ? payload?.label : el)),
              tasks: board.tasks.map((task) => ({
                ...task,
                labels: task.labels.map((el) =>
                  el.idBoardLabel === payload?.label?.idBoardLabel ? { ...el, label: payload?.label } : el
                ),
              })),
            }
          : board
      ),
    }
  },
  [UPLOAD_BOARD_LIST]: (state, { payload = {} }) => {
    if (!payload?.list) return { ...state, errorInRequest: true }

    return {
      ...state,
      boards: state.boards.map((board) =>
        board.idBoard === payload?.list?.idBoard
          ? {
              ...board,
              lists: [...board.lists, payload?.list],
            }
          : board
      ),
    }
  },
  [UPDATE_BOARD_LIST]: (state, { payload = {} }) => {
    if (!payload?.list) return { ...state, errorInRequest: true }

    return {
      ...state,
      boards: state.boards.map((board) =>
        board.idBoard === payload?.list?.idBoard
          ? {
              ...board,
              lists: board.lists.map((boardList) =>
                boardList.idBoardList === payload?.list?.idBoardList ? payload?.list : boardList
              ),
            }
          : board
      ),
    }
  },
  [DELETE_BOARD_LIST]: (state, { payload = {} }) => {
    if (!payload?.list) return { ...state, errorInRequest: true }

    return {
      ...state,
      boards: state.boards.map((board) =>
        board.idBoard === payload?.list?.idBoard
          ? {
              ...board,
              lists: board.lists.filter((boardList) => boardList.idBoardList !== payload?.list?.idBoardList),
              tasks: board.tasks.filter((task) => task.idBoardList !== payload?.list?.idBoardList),
            }
          : board
      ),
    }
  },
  [UPLOAD_BOARD_TASK_FILE]: (state, { payload = {} }) => {
    if (payload?.error) return { ...state, errorInRequest: true }

    const taskId = payload?.file?.idBoardTask
    const boardId = payload?.file?.idBoard

    return {
      ...state,
      boards: state.boards.map((_board) => {
        if (_board.idBoard === boardId) {
          return {
            ..._board,
            tasks: _board.tasks.map((_task) => {
              if (_task.idBoardTask === taskId) {
                return {
                  ..._task,
                  files: [..._task.files, payload?.file],
                }
              } else return _task
            }),
          }
        } else return _board
      }),
    }
  },
  [DELETE_BOARD_TASK_FILE]: (state, { payload = {} }) => {
    if (payload?.error) return { ...state, errorInRequest: true }

    const taskId = payload?.file?.idBoardTask
    const boardId = payload?.file?.idBoard
    const fileId = payload?.file?.idTaskFile

    return {
      ...state,
      boards: state.boards.map((_board) => {
        if (_board.idBoard === boardId) {
          return {
            ..._board,
            tasks: _board.tasks.map((_task) => {
              if (_task.idBoardTask === taskId) {
                return {
                  ..._task,
                  files: _task.files.filter((_file) => _file.idTaskFile !== fileId),
                }
              } else return _task
            }),
          }
        } else return _board
      }),
    }
  },
  [UPLOAD_BOARD_TASK_COMMENT]: (state, { payload = {} }) => {
    if (!payload?.comment) return { ...state, errorInRequest: true }

    return {
      ...state,
      boards: state.boards.map((board) => ({
        ...board,
        tasks: board.tasks.map((task) => {
          if (task.idBoardTask === payload?.comment?.idBoardTask) {
            return {
              ...task,
              comments: [...task.comments, payload?.comment],
            }
          } else return task
        }),
      })),
    }
  },
  [UPDATE_BOARD_TASK_COMMENT]: (state, { payload = {} }) => {
    const comment = payload?.comment

    function mutateComments(comments) {
      return comments.map((el) => (el.idBoardTaskComment === comment?.idBoardTaskComment ? comment : el))
    }

    return {
      ...state,
      boards: state.boards.map((board) => ({
        ...board,
        tasks: board.tasks.map((task) => {
          if (task.idBoardTask === payload?.comment?.idBoardTask) {
            return {
              ...task,
              comments: mutateComments(task.comments),
            }
          } else return task
        }),
      })),
    }
  },
  [UPLOAD_BOARD_TASK_HISTORY]: (state, { payload = {} }) => {
    if (!payload?.history) return { ...state, errorInRequest: true }

    return {
      ...state,
      boards: state.boards.map((board) =>
        board.idBoard === payload?.history?.idBoard
          ? {
              ...board,
              tasks: board.tasks.map((task) =>
                task.idBoardTask === payload?.history?.idBoardTask
                  ? {
                      ...task,
                      histories: [...task?.histories, payload?.history],
                    }
                  : task
              ),
            }
          : board
      ),
    }
  },
}

const boards = (state = initialState, action) => {
  const { type } = action

  return handlers[type] ? handlers[type](state, action) : state
}

export default boards
