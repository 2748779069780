import classNames from 'classnames'
import React from 'react'
import styles from './MarkupContainer.module.scss'

const MarkupContainer = React.forwardRef(({ content, className, ...delegated }, ref) => {
  return (
    <div
      ref={ref}
      className={classNames(styles.content, className)}
      dangerouslySetInnerHTML={{ __html: content }}
      {...delegated}
    />
  )
})

export default MarkupContainer
