const es = {
  rightSection: {
    allRightsReserved: 'Derechos Reservados. ',
    developedBy: 'Desarrollado por ',
  },

  leftSection: {
    contact: {
      phoneNumber: '+506 8334 5067',
      email: 'cedecc.s.a@gmail.com',
    },

    policies: {
      privacy: 'Políticas de Privacidad',
      usage: 'Políticas de Uso',
      cookies: 'Políticas de Cookies',
    },

    reportErrors: 'Reportar Errores',
  },
}

const en = {
  rightSection: {
    allRightsReserved: 'All rights reserved. ',
    developedBy: 'Developed by ',
  },

  leftSection: {
    contact: {
      phoneNumber: '+506 8334 5067',
      email: 'cedecc.s.a@gmail.com',
    },

    policies: {
      privacy: 'Privacy Policies',
      usage: 'Usage Policies',
      cookies: 'Cookies Policies',
    },

    reportErrors: 'Report Errors',
  },
}

const getContent = (locale) => (locale === 'es' ? es : en)

export default getContent
