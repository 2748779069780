import React from 'react'
import styles from './index.module.scss'
import { Tooltip as MaterialTooltip } from '@material-ui/core'

const Tooltip = ({ title, icon, handleOnClick }) => {
  return (
    <MaterialTooltip
      title={title}
      onMouseDown={(e) => {
        e.preventDefault()
      }}
      onClick={(e) => {
        e.stopPropagation()
        e.preventDefault()
        handleOnClick(e)
      }}
    >
      <i className={`${icon} ${styles.icon}`} />
    </MaterialTooltip>
  )
}

export default Tooltip
