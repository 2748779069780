import { Tooltip } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { DASHBOARD_PAGE_URL } from 'constants/urls'
import { getSidebarRoutes } from '../../constants/sidebarRoutes'
import MenuLink from '../MenuLink'
import useLocale from 'hooks/useLocale'
import styles from './Sidebar.module.scss'

const Sidebar = () => {
  const locale = useLocale()
  const routes = getSidebarRoutes(locale)
  return (
    <aside className={styles.sidebar}>
      <figure>
        <Tooltip title={locale === 'es' ? 'Ir al inicio' : 'Back to home'}>
          <Link to={`/${DASHBOARD_PAGE_URL}`}>
            <img src="/logo-white.svg" alt="Digital Pymes white logo" />
          </Link>
        </Tooltip>
      </figure>

      <nav className={styles.navigation}>
        <ul className={styles.navigationList}>
          {routes.map((_route) => (
            <li key={_route.name}>
              <MenuLink route={_route} />
            </li>
          ))}
        </ul>
      </nav>
    </aside>
  )
}

export default Sidebar
