import { TextField, Tooltip } from '@material-ui/core'
import { deleteDatanalaisisPage, toggleIndicatorCreator, updateDatanalisisPage } from 'actions/datanalisis'
import { confirmDialog } from 'actions/modals'
import classNames from 'classnames'
import ButtonIcon from 'components/common/ButtonIcon'
import LoadingSnackbar from 'components/common/LoadingSnackbar'
import useDatanalisis from 'hooks/useDatanalisis'
import useLocale from 'hooks/useLocale'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import EmptyIndicators from '../EmptyIndicators'
import IndicatorsGrid from './IndicatorsGrid'
import styles from './SelectedPage.module.scss'

const SelectedPage = () => {
  const dispatch = useDispatch()
  const locale = useLocale()
  const { selectedPageId, pages } = useDatanalisis()
  const selectedPage = pages.find((page) => page.idDatanalisisPage === selectedPageId)
  const { indicators } = selectedPage ?? {}

  const [pageTitle, setPageTitle] = useState(selectedPage?.title)
  const [updating, setUpdating] = useState(false)

  const handleChangePageTitle = (event) => setPageTitle(event.target.value)

  const handleUpdateTitle = async () => {
    if (!pageTitle || pageTitle === selectedPage?.title) {
      // If the page title is not set
      setPageTitle(selectedPage?.title)

      return
    }

    setTimeout(() => setUpdating(true), 300)
    const res = await updateDatanalisisPage({
      ...selectedPage,
      id: selectedPage?.idDatanalisisPage,
      title: pageTitle,
    })

    setUpdating(false)
    setPageTitle(res.payload?.page?.title)
    dispatch(res)
  }

  const handleDeletePage = () => {
    const handlerAction = async () => {
      const res = await deleteDatanalaisisPage(selectedPage?.idDatanalisisPage)

      dispatch(res)
    }

    dispatch(
      confirmDialog({
        message: locale === 'es' ? '¿Esta seguro de querer eliminar esta página?' : 'Are you sure you want to delete this page?',
        handlerAction,
      })
    )
  }

  useEffect(() => {
    // Changes the title of the selected page
    setPageTitle(selectedPage?.title)
  }, [selectedPage])

  if (!selectedPage) return null

  return (
    <section className={styles.wrapper}>
      <main className={styles.mainContainer}>
        <div className={styles.pageActionsWrapper}>
          <TextField value={pageTitle} onChange={handleChangePageTitle} variant="outlined" onBlur={handleUpdateTitle} />

          <Tooltip arrow placement="top" title={locale === 'es' ? 'Eliminar página' : 'Delete page'} onClick={handleDeletePage}>
            <i className={classNames(styles.trashIcon, 'fas fa-trash-alt')} />
          </Tooltip>

          <ButtonIcon
            icon={<i className="fas fa-plus-circle" />}
            variant="text"
            onClick={() => dispatch(toggleIndicatorCreator(true))}
            classes={{ label: styles.addPageButtonLabel }}
          >
            {locale === 'es' ? 'Agregar indicador' : 'Add indicator'}
          </ButtonIcon>
        </div>
      </main>

      {!Boolean(indicators?.length) ? <EmptyIndicators /> : <IndicatorsGrid indicators={indicators} />}

      <LoadingSnackbar message={locale === 'es' ? 'Actualizando página' : 'Updating page'} open={updating} />
    </section>
  )
}

export default SelectedPage
