const _getValueById = (event, fieldId) => event?.target?.[fieldId]?.value
const _getFileById = (event, fieldId) => event?.target?.[fieldId]?.files[0]

const formObjectFormatter = (event, formConfig) =>
  formConfig?.reduce((acc, field) => {
    acc[field?.name] = field?.type === 'file' ? _getFileById(event, field?.name) : _getValueById(event, field?.name)

    return acc
  }, {})

export default formObjectFormatter
