import { GET_PROCESSES, UPLOAD_PROCESS, UPDATE_PROCESS, DELETE_PROCESS } from 'constants/actionTypes'

const initialState = {
  isLoadingProcesses: true,
  processes: [],
}

const handlers = {
  [GET_PROCESSES]: (state, { payload = {} }) => {
    if (!payload?.processes) return { ...state, errorInRequest: true, isLoadingProcesses: false }

    return { ...state, processes: payload?.processes, isLoadingProcesses: false }
  },
  [UPLOAD_PROCESS]: (state, { payload = {} }) => {
    if (!payload?.process) return { ...state, errorInRequest: true }

    return { ...state, processes: state.processes.concat(payload?.process) }
  },
  [UPDATE_PROCESS]: (state, { payload = {} }) => {
    if (!payload?.process) return { ...state, errorInRequest: true }

    return {
      ...state,
      processes: state.processes.map((project) =>
        project.idProcess === payload?.process?.idProcess ? payload?.process : project
      ),
    }
  },
  [DELETE_PROCESS]: (state, { payload = {} }) => {
    if (!payload?.process) return { ...state, errorInRequest: true }

    return {
      ...state,
      processes: state.processes.filter(({ idProcess }) => idProcess !== payload?.process?.idProcess),
    }
  },
}

const process = (state = initialState, action) => {
  const { type } = action

  return handlers[type] ? handlers[type](state, action) : state
}

export default process
