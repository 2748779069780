import { differenceInSeconds } from 'date-fns'

const DATE_UNITS = {
  second: 1,
  minute: 60,
  hour: 3600,
  day: 86400,
}

const es = {
  day: 'día',
  hour: 'hora',
  minute: 'minuto',
  second: 'segundo',
}

const en = {
  day: 'day',
  hour: 'hour',
  minute: 'minute',
  second: 'second',
}

const getRelativeTime = ({ nowDate, databaseDate, locale }) => {
  let relativeTime
  const secondsDiff = differenceInSeconds(nowDate, databaseDate)

  for (const [unit, secondsInUnit] of Object.entries(DATE_UNITS)) {
    if (secondsDiff >= secondsInUnit || unit === 'second') {
      const value = Math.floor(secondsDiff / secondsInUnit)
      relativeTime = { value, unit }
    }
  }

  const isPlural = relativeTime.value > 1

  return locale === 'es'
    ? `Hace ${relativeTime.value > 0 ? relativeTime.value : 1} ${es[relativeTime.unit]}${isPlural ? 's' : ''}`
    : `${relativeTime.value > 0 ? relativeTime.value : 1} ${en[relativeTime.unit]}${isPlural ? 's' : ''} ago`
}

export { getRelativeTime }
