import { TextField } from '@material-ui/core'
import Select from 'components/common/Select/Select'
import FileInput from 'components/FileInput/FileInput'
import useFacebookConfigs from 'hooks/useFacebookConfigs'
import useLocale from 'hooks/useLocale'
import React from 'react'
import styles from './ScheduleModalPost.module.scss'

const SOCIALMEDIAS = [
  { value: 1, text: 'Facebook' },
  { value: 2, text: 'Instagram' },
  { value: 3, text: 'Facebook e Instagram' },
]

const ScheduleModalPost = ({ form, setForm }) => {
  const locale = useLocale()
  const { post } = form
  const { fbPages } = useFacebookConfigs()

  const handleOnChange = (e, newValue) => {
    setForm((draft) => {
      draft.post[e.target.name] = newValue
    })
  }

  return (
    <form className={styles.form}>
      <TextField
        multiline
        required
        fullWidth
        name="text"
        variant="outlined"
        label={locale === 'es' ? 'Texto' : 'Text'}
        maxRows={5}
        minRows={4}
        value={post.text}
        onChange={(e) => handleOnChange(e, e.target.value)}
      />

      <FileInput
        field={{
          name: 'image_url',
          id: 'image_url',
          key: 'key-file',
          label: locale === 'es' ? 'Imagen' : 'Image',
        }}
        onChange={(e) => {
          setForm((draft) => {
            draft.post.image_url = e.target.files[0]
          })
        }}
      />

      <Select
        required
        label={locale === 'es' ? 'Página' : 'Page'}
        name="idFbPage"
        value={post.idFbPage}
        options={fbPages?.map(({ idFbPage, name }) => ({ value: idFbPage, text: name })) ?? []}
        onChange={(e) => handleOnChange(e, +e.target.value)}
      />

      <Select
        required
        label={locale === 'es' ? 'Redes Sociales' : 'Socialmedia'}
        name="socialNetworks"
        value={post.socialNetworks}
        options={SOCIALMEDIAS}
        onChange={(e) => handleOnChange(e, e.target.value)}
      />
    </form>
  )
}

export default ScheduleModalPost
