import { Chip } from '@material-ui/core'
import classNames from 'classnames'
import CKTextEditor from 'components/CKTextEditor'
import Select from 'components/common/Select/Select'
import useLocale from 'hooks/useLocale'
import useUsers from 'hooks/useUsers'
import React, { useMemo } from 'react'
import styles from './ScheduleModalCampaignTask.module.scss'

const ScheduleModalCampaignTask = ({ form, setForm }) => {
  const locale = useLocale()
  const { marketingTask } = form
  const { companyUsers } = useUsers()

  const companyUsersEntities = useMemo(() => {
    return companyUsers.reduce((acc, _user) => {
      acc[_user.idUser] = _user
      return acc
    }, {})
  }, [companyUsers])

  const handleDeleteUser = (userId) =>
    setForm((draft) => {
      draft.marketingTask.users = draft.marketingTask.users.filter((_user) => _user !== userId)
    })

  return (
    <form className={styles.form}>
      <div id="calendar-container">
        <CKTextEditor
          data={marketingTask.content || ''}
          onChange={(_, editor) => {
            const value = editor.getData()
            setForm((draft) => {
              draft.marketingTask.content = value
            })
          }}
          config={{ autoGrow_maxHeight: 200 }}
        />
      </div>
      {Boolean(companyUsers.length) && (
        <>
          <Select
            multiple
            isRequired={false}
            label={locale === 'es' ? 'Asignar usuarios' : 'Assign users'}
            value={marketingTask.users}
            onChange={(e) =>
              setForm((draft) => {
                draft.marketingTask.users = e.target.value
              })
            }
            options={companyUsers.map((user) => ({ value: user.idUser, text: `${user.firstName} ${user.lastName}` }))}
            classes={{ root: styles.selectRoot }}
          />

          <div className={classNames(styles.chipSection, styles.selectRoot)}>
            {marketingTask.users?.length ? (
              <>
                {marketingTask.users.map((_user) => (
                  <Chip
                    key={_user}
                    color="primary"
                    onDelete={() => handleDeleteUser(_user)}
                    label={`${companyUsersEntities[_user].firstName} ${companyUsersEntities[_user].lastName}`}
                  />
                ))}
              </>
            ) : (
              <p className={styles.emptyManagers}>
                {locale === 'es' ? 'No hay usuarios seleccionados...' : 'No users selected...'}
              </p>
            )}
          </div>
        </>
      )}
    </form>
  )
}

export default ScheduleModalCampaignTask
