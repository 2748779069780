import Select from 'components/common/Select/Select'
import useLocale from 'hooks/useLocale'
import React from 'react'

const RoleSelector = ({ value, onChange }) => {
  const locale = useLocale()
  const options = [
    { value: 'reader', text: locale === 'es' ? 'Lector' : 'Reader' },
    { value: 'commenter', text: locale === 'es' ? 'Comentarista' : 'Commenter' },
    { value: 'writer', text: locale === 'es' ? 'Editor' : 'Editor' },
  ]

  return (
    <div style={{ marginLeft: 'auto' }}>
      <Select options={options} isRequired={false} value={value} onChange={onChange} />
    </div>
  )
}

export default RoleSelector
