import DataGridTable from 'components/DataGridTable/DataGridTable'
import { getSubscriptionHistory } from 'constants/subscriptions'
import { format } from 'date-fns/esm'
import { enUS, es } from 'date-fns/locale'
import useLocale from 'hooks/useLocale'
import useMoney from 'hooks/useMoney'
import useTableColumnWidth from 'hooks/useTableColumnWidth'
import useUsers from 'hooks/useUsers'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import SectionHeader from '../../SectionHeader'

const HistorySection = () => {
  const locale = useLocale()
  const { formatMoney } = useMoney()
  const { myUser } = useUsers()

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  const renderDate = useCallback(
    ({ value }) => {
      const date = new Date(value)

      return format(date, 'dd MMMM, yyyy. h:mm aaaa', { locale: locale === 'es' ? es : enUS })
    },
    [locale]
  )

  const renderType = useCallback(
    ({ row }) => {
      if (row.id === 0) {
        return locale === 'es' ? 'Suscripción a Plan Pro' : 'Subscription to Pro Plan'
      }

      return locale === 'es' ? 'Renovación a Plan Pro' : 'Renewal to Pro Plan'
    },
    [locale]
  )

  const [getWidth, getFlex] = useTableColumnWidth()

  const COLUMNS = useMemo(
    () => [
      {
        headerName: locale === 'es' ? 'Transacción' : 'Transaction',
        field: 'type',
        width: getWidth(300),
        flex: getFlex(1),
        sortable: false,
        renderCell: renderType,
      },
      {
        headerName: locale === 'es' ? 'Monto' : 'Amount',
        field: 'amount',
        width: getWidth(300),
        flex: getFlex(1),
        sortable: false,
        renderCell: ({ row }) => formatMoney(row.payment_result.amount, 'usd'),
      },
      {
        headerName: locale === 'es' ? 'Fecha' : 'Date',
        field: 'payment_date',
        width: getWidth(300),
        flex: getFlex(2),
        sortable: false,
        renderCell: renderDate,
      },
    ],
    [locale, formatMoney, renderDate, renderType, getFlex, getWidth]
  )

  useEffect(() => {
    const fetch = async () => {
      const data = await getSubscriptionHistory(myUser?.subscription?.subscription_id)

      if (data?.payments) {
        setData(data?.payments)
      }

      setLoading(false)
    }

    fetch()
  }, [myUser?.subscription?.subscription_id])

  return (
    <>
      <SectionHeader title={locale === 'es' ? 'Historial de Transacciones' : 'Transactions history'} />

      <DataGridTable
        columns={COLUMNS}
        actionsConfig={{}}
        data={data.sort((a, b) => new Date(b.date) - new Date(a.date))}
        isLoading={loading}
        noActions
      />
    </>
  )
}

export default HistorySection
