import { Dialog, DialogContent, DialogTitle, TextField } from '@material-ui/core'
import algoliasearch from 'algoliasearch/lite'
import React from 'react'
import { Hits, InstantSearch, PoweredBy, SearchBox, useInstantSearch } from 'react-instantsearch-hooks-web'
import { HashLink } from 'react-router-hash-link'
import styles from './SearchModal.module.scss'

const EmptyQueryBoundary = ({ children, fallback }) => {
  const { indexUiState, results } = useInstantSearch()
  const { hits } = results
  const emptyHits = hits.length === 0

  if (emptyHits) {
    return <div className={styles.emptyQueryMessage}>No se encontraron resultados</div>
  }

  if (!indexUiState.query) {
    return (
      <div className={styles.emptyQueryMessage}>
        <p>Escribe algo para buscar</p>
      </div>
    )
  }

  return children
}

const Hit = ({ hit, onClose }) => {
  return (
    <HashLink to={`/help/${hit.url}`} onClick={onClose}>
      <strong className={styles.hitTitle}>{hit.title}</strong>
      <p className={styles.hitContent}>{hit.description.slice(0, 100).trim() + '...'}</p>
    </HashLink>
  )
}

const SearchModal = ({ open, onOpen, onClose }) => {
  const searchClient = algoliasearch('FYLLPEW4GZ', '95bc32491bf4d6bf05c55d1247431fc6')

  return (
    <InstantSearch searchClient={searchClient} indexName="dev_HELP">
      <div>
        <TextField
          disabled
          variant="outlined"
          onClick={onOpen}
          value="Buscar..."
          InputProps={{
            startAdornment: <i className="fas fa-search" />,
            endAdornment: <p className={styles.shortcut}>CTRL + K</p>,
          }}
          classes={{
            root: styles.searchField,
          }}
        />

        <Dialog
          fullWidth
          open={open}
          onClose={onClose}
          maxWidth="xs"
          classes={{
            paper: styles.dialogPaper,
          }}
        >
          <DialogTitle>
            <SearchBox
              autoFocus
              classNames={{
                submit: styles.noDisplay,
                submitIcon: styles.noDisplay,
                reset: styles.noDisplay,
                input: styles.searchInput,
                form: styles.searchForm,
              }}
              placeholder="Buscar..."
            />
          </DialogTitle>

          <DialogContent dividers>
            <EmptyQueryBoundary>
              <Hits
                classNames={{
                  list: styles.hitsList,
                  item: styles.hit,
                }}
                hitComponent={(props) => <Hit onClose={onClose} {...props} />}
              />
            </EmptyQueryBoundary>
          </DialogContent>

          <div className={styles.poweredByWrapper}>
            <PoweredBy
              classNames={{
                root: styles.poweredBy,
              }}
            />
          </div>
        </Dialog>
      </div>
    </InstantSearch>
  )
}

export default SearchModal
