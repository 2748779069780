import useUsers from './useUsers'

const useSubscription = () => {
  const { myUser } = useUsers() ?? {}
  const { subscription } = myUser

  return {
    subscription,
    isProUser: Boolean(subscription?.plan),
  }
}

export default useSubscription
