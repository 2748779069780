import axios from 'axios'

const CLIENT = axios.create({
  baseURL: 'https://www.googleapis.com/drive/v3',
})

export async function getFiles(
  folderId,
  authToken,
  filters = [
    "'me' in owners",
    'trashed = false',
    "mimeType != 'application/vnd.google-apps.folder'",
    folderId ? `'${folderId}' in parents` : "'root' in parents",
  ]
) {
  filters = filters.join(' and ')

  let response = await CLIENT.get('/files', {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    params: {
      corpora: 'user',
      q: filters,
      orderBy: 'name',
      fields: 'files(iconLink,name,thumbnailLink,id,webViewLink,webContentLink,parents,permissions,mimeType)',
    },
  })

  return response.data.files
}

export async function getFileBlob(fileId, authToken) {
  let response = await CLIENT.get(`/files/${fileId}?alt=media`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    responseType: 'blob',
  })

  let blob = await response.data
  return blob
}

export async function getFolders(folderId, authToken) {
  const filters = [
    "'me' in owners",
    'trashed = false',
    folderId ? `'${folderId}' in parents` : "'root' in parents",
    "mimeType = 'application/vnd.google-apps.folder'",
  ].join(' and ')

  let response = await CLIENT.get('/files', {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    params: {
      corpora: 'user',
      q: filters,
      orderBy: 'name',
      fields: 'files(iconLink,name,thumbnailLink,id,webViewLink,webContentLink,parents,permissions,mimeType)',
    },
  })

  return response.data.files
}

export async function getFilesAndFolders(folderId, authToken) {
  let filters = ["'me' in owners", 'trashed = false', folderId ? `'${folderId}' in parents` : "'root' in parents"]
  let filesAndFolders = await getFiles(folderId, authToken, filters)
  return filesAndFolders
}

export async function getNestedResources(rootFolderId, authToken, folderName) {
  let fileStructure = {
    folderName: folderName,
    files: [],
  }

  let filesAndFolders = await getFilesAndFolders(rootFolderId, authToken)

  for (let i = 0; i < filesAndFolders.length; i++) {
    let resource = filesAndFolders[i]
    let { id, name, mimeType } = resource
    const extensions = {
      'application/vnd.google-apps.document': {
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        extension: '.docx',
      },
      'application/vnd.google-apps.spreadsheet': {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        extension: '.xlsx',
      },
      'application/vnd.google-apps.presentation': {
        type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        extension: '.pptx',
      },
    }

    if (extensions[mimeType]) {
      const options = {
        headers: { Authorization: `Bearer ${authToken}` },
      }
      const extension = extensions[mimeType].extension
      const baseUrl = `https://www.googleapis.com/drive/v3/files/${id}`
      const type = extensions[resource.mimeType]?.type
      const exportResponse = await fetch(`${baseUrl}/export?mimeType=${type}`, options)
      const blob = await exportResponse.blob()
      fileStructure.files.push({
        id,
        name: `${name}${extension}`,
        mimeType: type,
        blob,
      })
    } else if (mimeType !== 'application/vnd.google-apps.folder') {
      let blob = await getFileBlob(id, authToken)
      fileStructure.files.push({
        id,
        name,
        blob,
        mimeType,
      })
    } else {
      let recursiveFiles = await getNestedResources(id, authToken, name)
      fileStructure.files.push(recursiveFiles)
    }
  }

  return fileStructure
}

export async function permanentlyDeleteFile(fileOrFolderId, authToken) {
  let res = await CLIENT.delete(`/files/${fileOrFolderId}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  })
  return res
}

export async function permanentlyDeleteAllFiles(filesOrFoldersIds, authToken) {
  await Promise.all(filesOrFoldersIds.map((id) => permanentlyDeleteFile(id, authToken)))
}
