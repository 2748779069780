export const INFINITE_TYPES = (() => {
  const locale = localStorage.getItem('locale')

  const entries = [
    {
      es: 'Tiempo indeterminado',
      en: 'Undetermined time',
      value: true,
    },
    {
      es: 'Tiempo determinado',
      en: 'Determined time',
      value: false,
    },
  ]

  return entries.map((e) => ({ text: e[locale], value: e.value }))
})()

export const RECURRENCY_DEFAULTS = (() => {
  const locale = localStorage.getItem('locale')
  const entries = [
    {
      es: 'Semanal',
      en: 'Weekly',
      value: 'weekly',
      days: 7,
    },
    {
      es: 'Quincenal',
      en: 'Biweekly',
      value: 'biweekly',
      days: 15,
    },
    {
      es: 'Mensual',
      en: 'Monthly',
      value: 'monthly',
      days: 30,
    },
    {
      es: 'Personalizado',
      en: 'Custom',
      value: 'custom',
      days: 1,
    },
  ]

  return entries.map((e) => ({ text: e[locale], value: e.value, days: e.days }))
})()

export const FORM_LABELS = (() => {
  const locale = localStorage.getItem('locale')

  return {
    // Recurrency
    nextDate: locale === 'es' ? 'Próxima fecha' : 'Next date',
    recurrencyTime: locale === 'es' ? 'Tiempo de la recurrencia' : 'Recurrency time',
    remainingTimes: locale === 'es' ? 'Veces restantes' : 'Remaining times',
    recurrencyDays: locale === 'es' ? 'Días de recurrencia' : 'Recurrency days',
    // Factory
    description: locale === 'es' ? 'Descripción' : 'Description',
    workedHours: locale === 'es' ? 'Horas trabajadas' : 'Worked hours',
    extraHours: locale === 'es' ? 'Horas extra trabajadas' : 'Extra hours worked',
    hourlyRate: locale === 'es' ? 'Pago por hora' : 'Hourly rate',
    extraHourlyRate: locale === 'es' ? 'Pago por hora extra' : 'Hourly extra rate',
    deductions: locale === 'es' ? 'Deducciones' : 'Deductions',
    extraPayment: locale === 'es' ? 'Pago extra' : 'Extra payment',
    extraPaymentDescription: locale === 'es' ? 'Descripción de pago extra' : 'Extra payment description',
    basePayment: locale === 'es' ? 'Pago base' : 'Base payment',
    extraHoursPayment: locale === 'es' ? 'Pago por horas extra' : 'Extra hours payment',
    totalPayment: locale === 'es' ? 'Total' : 'Total',
    reference: locale === 'es' ? 'Referencia' : 'Reference',
  }
})()
