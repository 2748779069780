const ERROR_MESSAGES = {
  es: {
    'auth/weak-password': 'La contraseña debería de tener mínimo 6 caracteres.',
    'auth/invalid-email': 'El correo electrónico tiene un formato incorrecto.',
    'auth/email-already-in-use': 'La dirección de correo electrónico ya está siendo utilizada por otra cuenta.',
    'auth/login-fail': 'El correo electrónico o la contraseña son incorrectas.',
    serverError: 'Hubo un error en el servidor, inténtelo de nuevo más tarde.',
    emptyData: 'No se puede continuar por que hay datos vacios.',
    'different-passwords': 'Las contraseñas no coinciden.',
    'password-with-spaces': 'La contraseña no puede contener espacios.',
    'password-without-uppercase': 'La contraseña debe tener al menos una mayúscula.',
    'password-without-lowercase': 'La contraseña debe tener al menos una minuscula.',
    'password-without-numbers': 'La contraseña debe tener al menos un número.',
    'password-without-symbols': 'La contraseña debe tener al menos un símbolo.',
    default: 'Ha ocurrido un error',
    invalidData: 'Los datos ingresados son inválidos',
  },
  en: {
    'auth/weak-password': 'Your password must be at least 6 characters long.',
    'auth/invalid-email': 'Your email address has an invalid format.',
    'auth/email-already-in-use': 'The email address is already in use by other account.',
    'auth/login-fail': 'The email or password are incorrect.',
    serverError: 'There was a server error. Try again later.',
    emptyData: 'Cannot continue because there is empty data.',
    'different-passwords': 'Passwords do not match.',
    'password-with-spaces': 'Password can not contain spaces.',
    'password-without-uppercase': 'Password must have at least one uppercase letter.',
    'password-without-lowercase': 'Password must have at least one lowercase letter.',
    'password-without-numbers': 'Password must have at least one number.',
    'password-without-symbols': 'Password must have at least one symbol.',
    default: 'An error has occurred.',
    invalidData: 'The entered data is invalid',
  },
}

export default ERROR_MESSAGES
