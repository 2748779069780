import BackButton from 'components/BackButton'
import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import { HashLink } from 'react-router-hash-link'
import data from '../data'
import { makeSlug } from '../utils'
import styles from './Tutorials.module.scss'

const Tutorials = () => {
  const location = useLocation()
  const { slug } = useParams()
  const { tutorials } = data
  const sectionData = tutorials.find((tutorial) => makeSlug(tutorial.title) === slug) ?? {}
  const { videos } = sectionData
  const [activeSection, setActiveSection] = useState(null)

  useEffect(() => {
    const { hash } = location
    if (!hash) {
      window.scroll(0, 0)
    }
  }, [location])

  useEffect(() => {
    const sections = document.getElementsByClassName(styles.videoTitle)

    const setter = () => {
      const currentSection = [...sections].find((section, index) => {
        const { top } = section.getBoundingClientRect()

        return top > 0 && top < window.innerHeight
      })

      if (currentSection && currentSection !== activeSection) {
        const getInnerText = makeSlug(currentSection.innerText)
        setActiveSection(getInnerText)
      }
    }

    setter()

    window.addEventListener('scroll', setter)
  }, [activeSection])

  return (
    <>
      <BackButton classes={{ root: styles.backButton }} link="/help" />

      <header className={styles.header}>
        <h1 className={styles.sectionTitle}>{sectionData.title}</h1>
        <p>{sectionData.description}</p>
      </header>

      <main className={styles.mainSection}>
        <aside className={styles.content}>
          <strong className={styles.contentTitle}>Contenido</strong>
          {videos?.map((video) => (
            <HashLink
              key={video.title}
              className={styles.contentLink}
              to={`#${makeSlug(video.title)}`}
              data-active={String(makeSlug(video.title) === activeSection)}
            >
              {video.title}
            </HashLink>
          ))}
        </aside>
        <section className={styles.tutorials}>
          {videos.map((video, index) => (
            <div key={video.title + index} className={styles.videoWrapper}>
              <div className={styles.videoContainer}>
                <iframe width="560" height="315" title={video.title} src={video.url} frameBorder="0" />
              </div>
              <h2 id={makeSlug(video.title)} className={styles.videoTitle}>
                {video.title}
              </h2>
              <p className={styles.videoDescription}>{video.description}</p>
            </div>
          ))}
        </section>
      </main>
    </>
  )
}

export default Tutorials
