import { getRandomColor } from 'constants/colors'
import React from 'react'
import { ResponsiveContainer, Treemap } from 'recharts'
import { reduceChartData } from './helpers'

const TreeMap = ({ values, chartData }) => {
  const value = values?.find((el) => el.attribute === 'value') ?? {}
  const { key: valueKey } = value
  const data = reduceChartData(chartData, valueKey)

  return (
    <ResponsiveContainer>
      <Treemap isAnimationActive={false} data={data} dataKey="count" stroke="#fff" fill={getRandomColor(6)} />
    </ResponsiveContainer>
  )
}

export default TreeMap
