import MONTHS from 'constants/months'

export const createCalendar = (year, month) => {
  const realMonth = month - 1
  const date = new Date(year, realMonth)
  const lastMonthDate = new Date(year, realMonth, 0)

  let daysInMonth = []
  const firstDay = date.getDay()
  let firstDayOfLastMonth = lastMonthDate.getDate() - (firstDay === 0 ? 0 : firstDay - 2)

  while (date.getMonth() === realMonth) {
    daysInMonth.push(date.getDate())

    date.setDate(date.getDate() + 1)
  }

  let weeks = []
  let firstDayIndex, lastDayIndex
  let lastDayDate = new Date(year, month, 0)

  let weeksCount = 0
  let nextMonthFirstDay = 1

  if (firstDay === 0) {
    firstDayIndex = 6
    let thisWeek = []
    for (let i = 0; i < 6; i++) {
      thisWeek.unshift(firstDayOfLastMonth - i)
    }
    thisWeek.push(daysInMonth[0])
    weeks.push(thisWeek)
    daysInMonth = daysInMonth.slice(1)
  }

  while (daysInMonth.length !== 0) {
    let week = []

    for (let i = 1; i < 8; i++) {
      if (weeksCount === 0 && i < firstDay) {
        week.push(firstDayOfLastMonth)
        firstDayOfLastMonth++
      } else if (daysInMonth.length === 0) {
        if (!lastDayIndex) lastDayIndex = i - 1

        week.push(nextMonthFirstDay)
        nextMonthFirstDay++
      } else {
        if (!firstDayIndex) firstDayIndex = i - 1

        week.push(daysInMonth[0])
        daysInMonth = daysInMonth.slice(1)
      }
    }
    weeks.push(week)
    weeksCount++
    week = []
  }

  const currentMonth = MONTHS[realMonth]
  const currentYear = year

  return { weeks, firstDayIndex, lastDayIndex, lastDayDate, currentMonth, currentYear }
}
