import { FormControlLabel, Checkbox as MUICheckbox } from '@material-ui/core'
import React from 'react'
import styles from './Checkbox.module.scss'

const Checkbox = ({ checked, value, label, onChange, ...props }) => {
  return (
    <FormControlLabel
      control={<MUICheckbox color="primary" checked={checked} value={value} onChange={onChange} {...props} />}
      label={label}
      classes={{ root: props.labelRootClassName }}
    />
  )
}

export default Checkbox
