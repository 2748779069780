import React from 'react'
import styles from './MassEmailContent.module.scss'
import { EMAIL_TYPES } from 'constants/sendMassEmailsOptions'
import useLocale from 'hooks/useLocale'
import ContentItem from '../ContentItem'
import { formatDate } from 'helpers/date'
import MarkupContainer from 'components/common/MarkupContainer'

const MassEmailContent = ({ content }) => {
  const locale = useLocale()
  const emailType = EMAIL_TYPES(locale).get(content?.type)

  return (
    <div className={styles.wrapper}>
      <section className={styles.infoSection}>
        <ContentItem centered title={locale === 'es' ? 'Asunto' : 'Subject'} content={content?.subject} />
        <div className={styles.subGrid}>
          <ContentItem centered borderInline title={locale === 'es' ? 'Tipo' : 'Type'} content={emailType} />
          <ContentItem centered title={locale === 'es' ? 'Hora' : 'Time'} content={formatDate(content?.date, 'HH:mm', true)} />
        </div>
      </section>

      <ContentItem
        title={locale === 'es' ? 'Contenido' : 'Content'}
        content={<MarkupContainer content={content?.content} className={styles.contentMarkup} />}
      />
    </div>
  )
}

export default MassEmailContent
