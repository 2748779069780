import BackButton from 'components/BackButton'
import SubscribeToProButton from 'components/SubscribeToProButton'
import useLocale from 'hooks/useLocale'
import React from 'react'
import styles from './NoProAccess.module.scss'

const NoProAccess = () => {
  const locale = useLocale()

  const header = locale === 'es' ? 'No tienes acceso a esta página' : "You don't have access to this page"
  const body =
    locale === 'es'
      ? 'Lo siento, para acceder a esta página, necesitas ser un usuario PRO. Vuelve a la página anterior, o suscríbete a un plan PRO.'
      : 'Sorry, to access this page you need to be a PRO user. Go back to the previous page or subscribe to a PRO plan.'

  return (
    <div className={styles.wrapper}>
      <strong className={styles.errorCode}>403</strong>

      <h1 className={styles.header}>{header}</h1>
      <p className={styles.body}>{body}</p>

      <SubscribeToProButton />
      <BackButton />
    </div>
  )
}

export default NoProAccess
