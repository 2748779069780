import { Tooltip } from '@material-ui/core'
import { deleteCalendarAction, selectCalendarAction } from 'actions/calendarActions'
import { confirmDialog, sendErrorMessage, sendSuccessMessage } from 'actions/modals'
import clsx from 'clsx'
import ViewMassEmailModal from 'components/ViewMassEmailModal/ViewMassEmailModal'
import { format } from 'date-fns'
import useLocale from 'hooks/useLocale'
import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import styles from './EventDetails.module.scss'

const EventDetails = ({ eventData, canWrite, setScheduleModal, onOpenActionContent, isLoadingResources }) => {
  const locale = useLocale()
  const [massEmailModal, setMassEmailModal] = useState({
    open: false,
    data: undefined,
  })

  const { idCalendarAction, idMassEmail, idPost, name, startDate, IS_RECURRING, IS_PENDING, originalId, copy } = eventData

  const dispatch = useDispatch()

  const handleDelete = useCallback(async () => {
    dispatch(
      confirmDialog({
        message: copy
          ? locale === 'es'
            ? 'Si borra este registro, se eliminarán las acciones pasadas y las siguientes. Desea continuar?'
            : 'If you delete this register, all the past and present actions will be deleted. Do you want to continue?'
          : locale === 'es'
          ? '¿Está seguro de querer eliminar este registro?'
          : 'Are you sure you want to delete this register?',
        handlerAction: async () => {
          const res = await deleteCalendarAction(idCalendarAction ?? originalId)

          if (res?.payload?.error)
            return dispatch(
              sendErrorMessage(locale === 'es' ? 'No se ha podido eliminar el elemento' : 'Item could not be removed')
            )

          dispatch(res)
          dispatch(sendSuccessMessage(locale === 'es' ? 'Acción eliminada' : 'Action deleted'))
        },
      })
    )
  }, [copy, originalId, idCalendarAction, dispatch, locale])

  const handleUpdate = useCallback(async () => {
    setScheduleModal(true)
    dispatch(selectCalendarAction(eventData))
  }, [setScheduleModal, dispatch, eventData])

  return (
    <>
      <li className={clsx(styles.event, { [styles.eventDone]: IS_PENDING === 0 })}>
        <strong>
          {(Boolean(IS_RECURRING) || copy) && (
            <Tooltip title={locale === 'es' ? 'Acción recurrente' : 'Recurrent action'} className={styles.sync}>
              <i className="fas fa-sync-alt" />
            </Tooltip>
          )}
          <span className={styles.eventType} style={{ '--color': idMassEmail ? '#9a9b9d' : idPost ? '#8cc0d6' : '#99cf90' }}>
            {idMassEmail ? 'Email' : idPost ? 'Post' : locale === 'es' ? 'Evento' : 'Event'} /{' '}
            {format(new Date(startDate), 'hh:mm a')}
          </span>
          {IS_PENDING === 0 ? (
            <span className={styles.checkmark}>
              <i className="fas fa-check" />
            </span>
          ) : (
            !Boolean(isLoadingResources) && (
              <span className={styles.eventButtons}>
                <Tooltip title={`${locale === 'es' ? 'Ver' : 'See'} ${idMassEmail ? 'email' : 'post'}`}>
                  <button onClick={() => onOpenActionContent(eventData)}>
                    <i className={clsx('fas fa-eye')} />
                  </button>
                </Tooltip>
                {canWrite && (
                  <>
                    <Tooltip title={locale === 'es' ? 'Editar' : 'Edit'}>
                      <button onClick={handleUpdate}>
                        <i className={clsx('far fa-edit')} />
                      </button>
                    </Tooltip>
                    <Tooltip title={`${locale === 'es' ? 'Eliminar' : 'Delete'} ${idMassEmail ? 'email' : 'post'}`}>
                      <button onClick={handleDelete}>
                        <i className={clsx('fas fa-trash-alt')} />
                      </button>
                    </Tooltip>
                  </>
                )}
              </span>
            )
          )}
        </strong>
        <span>{name}</span>
      </li>

      <ViewMassEmailModal
        open={massEmailModal.open}
        setOpen={() => setMassEmailModal((prev) => ({ ...prev, open: false }))}
        dataMassEmail={massEmailModal.data}
      />
    </>
  )
}

export default EventDetails
