import { FormControlLabel, Switch as MUISwitch } from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'
import styles from './Switch.module.scss'

const Switch = ({ checked, onChange, label, rootClass = '', disabled }) => {
  return (
    <FormControlLabel
      control={<MUISwitch color="primary" checked={checked} onChange={(_, value) => onChange(value)} />}
      label={label}
      labelPlacement="start"
      classes={{ root: classNames(rootClass) }}
      disabled={disabled}
    />
  )
}

export default Switch
