import useHumanResources from 'hooks/useHumanResources'
import useUsers from 'hooks/useUsers'
import { useMemo } from 'react'

const useEmployeeData = (editEmployee = {}) => {
  const { idUser } = editEmployee
  const { companyUsers: companyUsersData } = useUsers()
  const { positions: positionsData, departments: departmentsData, schedules: schedulesData } = useHumanResources()

  const departments = useMemo(
    () =>
      departmentsData.reduce((acc, i) => {
        acc.push({
          text: i.name,
          value: i.idDepartment,
        })

        return acc
      }, []),
    [departmentsData]
  )

  const positions = useMemo(
    () =>
      positionsData.reduce((acc, i) => {
        acc.push({
          text: i.name,
          value: i.idPosition,
        })

        return acc
      }, []),
    [positionsData]
  )

  const schedules = useMemo(
    () =>
      schedulesData.reduce((acc, i) => {
        acc.push({
          text: i.name,
          value: i.idSchedule,
        })

        return acc
      }, []),
    [schedulesData]
  )

  const companyUsers = useMemo(
    () =>
      companyUsersData
        .filter((el) => !el.employee || el?.idUser === idUser)
        .map((user) => ({
          text: `${user.firstName ?? ''} ${user.lastName ?? ''}`,
          value: user.idUser,
        })),
    [companyUsersData, idUser]
  )

  return { departments, positions, schedules, companyUsers }
}

export default useEmployeeData
