import { resetPassword } from 'actions/firebaseAuth'

const es = {
  title: 'Enviar cambio de contraseña al correo',
  button: 'Enviar',
  form: {
    email: 'Correo electrónico',
  },
}

const en = {
  title: 'Send password change to email',
  button: 'Send',
  form: {
    email: 'Email',
  },
}

const getContent = (locale) => (locale === 'es' ? es : en)

const resetPasswordFormConfig = () => {
  const locale = localStorage.getItem('locale')
  const content = getContent(locale)

  return {
    modalTitle: content.title,
    buttonName: content.button,
    form: [{ name: 'email', type: 'email', label: content.form.email, isAutoFocus: true }],
    registerAction: resetPassword,
  }
}

export default resetPasswordFormConfig
