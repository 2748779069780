import { formatDate } from 'helpers/date'
import moment from 'moment-timezone'

export const CHECKBOX_VALUES = [
  { es: 'A la hora del evento', en: 'At the event time', value: 1 },
  { es: '5 minutos antes', en: '5 minutes before', value: 2 },
  { es: '10 minutos antes', en: '10 minutes before', value: 3 },
  { es: '15 minutos antes', en: '15 minutes before', value: 4 },
  { es: '30 minutos antes', en: '30 minutes before', value: 5 },
  { es: 'Personalizar', en: 'Customize', value: 6 },
]

export const _calculateReminder = (type, startDate, time) => {
  const base = moment(startDate)
  const [hours, minutes] = time?.split(':') ?? ['00', '00']
  base.set({ hours, minutes })

  switch (type) {
    case 2:
      base.subtract(5, 'minutes')
      break
    case 3:
      base.subtract(10, 'minutes')
      break
    case 4:
      base.subtract(15, 'minutes')
      break
    case 5:
      base.subtract(30, 'minutes')
      break
    default:
      break
  }

  return formatDate(base, undefined, true)
}
