import { LinearProgress } from '@material-ui/core'
import { LOGIN_PAGE_URL } from 'constants/urls'
import Page from 'layouts/Page/Page'
import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { Link } from 'react-router-dom'
import usePoliciesPath from './policies/usePolicies.md'
import styles from './privacyPolicyPage.module.scss'

const LOGO_IMG = '/logo.svg'

export default function UsePolicyPage() {
  const [text, setText] = useState('')

  useEffect(() => {
    window.scrollTo(0, 0)
    fetch(usePoliciesPath)
      .then((response) => response.text())
      .then((text) => {
        setText(text)
      })
  }, [])

  return (
    <Page title={'TÉRMINOS Y CONDICIONES DE USO DE DIGITALPYMES.NET'} className={styles.privacyPolicyPage}>
      <Link to={LOGIN_PAGE_URL}>
        <img src={LOGO_IMG} alt="CEDECC Digital Pymes" className={styles.logo} />
      </Link>
      <h1>TÉRMINOS Y CONDICIONES DE USO DE DIGITALPYMES.NET</h1>

      {!text && <LinearProgress />}

      <ReactMarkdown className={styles.wrapper}>{text}</ReactMarkdown>
    </Page>
  )
}
