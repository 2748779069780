import { selectCalendarActionContent } from 'actions/calendarActions'
import Dialog from 'components/Dialog/Dialog'
import useCalendarActions from 'hooks/useCalendarActions'
import useLocale from 'hooks/useLocale'
import React from 'react'
import { useDispatch } from 'react-redux'
import CalendarActionContentComments from './CalendarActionContentComments'
import CalendarActionContentHeader from './CalendarActionContentHeader'
import CalendarEventContent from './CalendarEventContent'
import { getCalendarActionType } from './helpers'
import MarketingTaskContent from './MarketingTaskContent'
import MassEmailContent from './MassEmailContent'
import PostContent from './PostContent'
import styles from './CalendarActionContentModal.module.scss'

const CalendarActionContentModal = ({ open, setOpen }) => {
  const locale = useLocale()
  const dispatch = useDispatch()
  const { selectedCalendarActionContent } = useCalendarActions()
  const calendarActionType = getCalendarActionType(selectedCalendarActionContent)

  const handleClose = () => {
    setOpen(false)
    setTimeout(() => {
      dispatch(selectCalendarActionContent(null))
    }, 200)
  }

  const CONTENT_SECTIONS = {
    1: () => <PostContent content={selectedCalendarActionContent?.post} startDate={selectedCalendarActionContent?.startDate} />,
    2: () => <MassEmailContent content={selectedCalendarActionContent?.email} />,
    3: () => <MarketingTaskContent content={selectedCalendarActionContent?.campaign_task} />,
    4: () => <CalendarEventContent content={selectedCalendarActionContent?.event} />,
  }

  return (
    <Dialog
      handleCloseAction={handleClose}
      open={open}
      setOpen={setOpen}
      maxWidth="md"
      title={locale === 'es' ? 'Visualizar contenido' : 'Content viewer'}
      rootClassName={styles.root}
    >
      <CalendarActionContentHeader calendarAction={selectedCalendarActionContent} type={calendarActionType} />
      {CONTENT_SECTIONS?.[calendarActionType]?.()}
      {calendarActionType !== 4 && <CalendarActionContentComments calendarAction={selectedCalendarActionContent} />}
    </Dialog>
  )
}

export default CalendarActionContentModal
