const useLicense = (freeTime) => {
  const totalVacations = freeTime?.reduce(
    (acc, el) => (+el?.status === 2 ? acc + (el?.totalDays === 0 ? 0.5 : el?.totalDays) : acc),
    0
  )
  const totalVacationsPerType = freeTime?.reduce((acc, el) => {
    if (el?.status !== 2) return acc

    if (acc[el?.type]) {
      return {
        ...acc,
        [el?.type]: acc[el?.type] + (el?.totalDays === 0 ? 0.5 : el?.totalDays),
      }
    } else {
      return {
        ...acc,
        [el?.type]: el?.totalDays === 0 ? 0.5 : el?.totalDays,
      }
    }
  }, {})

  return {
    totalVacations,
    totalVacationsPerType,
  }
}

export default useLicense
