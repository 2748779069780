import { Button } from '@material-ui/core'
import React from 'react'
import styles from './ButtonIcon.module.scss'

const ButtonIcon = ({ icon, children, ...delegated }) => {
  return (
    <Button {...delegated} classes={{ label: styles.label, ...delegated.classes }}>
      {icon} {children}
    </Button>
  )
}

export default ButtonIcon
