import { InputAdornment, SvgIcon, TextField } from '@material-ui/core'
import Select from 'components/common/Select/Select'
import { currencies } from 'constants/currencies'
import useLocale from 'hooks/useLocale'
import { categoriesOptionsGenerator } from 'pages/PersonalFinancesPage/helpers/transactionCategoriesHelper'
import usePersonalFinancesContext from 'pages/PersonalFinancesPage/hooks/usePersonalFinancesContext'
import { Search as SearchIcon } from 'react-feather'
import styles from './HeaderFilters.module.scss'

const HeaderFilters = () => {
  const locale = useLocale()
  const CATEGORIES_OPTIONS = categoriesOptionsGenerator(locale)
  const {
    states: { currency, searchFilter, categoryFilter },
    handlers: { setCurrency, handleSearchFilter, handleCategoryFilter },
  } = usePersonalFinancesContext()

  const CURRENCIES = currencies()

  const handleChangeCurrency = (event) => {
    const { value } = event.target

    setCurrency(value)
    localStorage.setItem('currency', value)
  }

  return (
    <div className={styles.filtersContainer}>
      <Select
        defaultValue={currency}
        value={currency}
        label={locale === 'es' ? 'Moneda' : 'Currency'}
        name="currency"
        options={CURRENCIES}
        variant="outlined"
        onChange={handleChangeCurrency}
      />
      <Select
        displayEmpty
        emptyValue="Todos"
        label={locale === 'es' ? 'Filtrar por' : 'Filter by'}
        name="filter"
        options={CATEGORIES_OPTIONS}
        variant="outlined"
        value={categoryFilter}
        onChange={handleCategoryFilter}
      />
      <TextField
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SvgIcon fontSize="small" color="action">
                <SearchIcon />
              </SvgIcon>
            </InputAdornment>
          ),
        }}
        placeholder={locale === 'es' ? 'Buscar' : 'Search'}
        variant="outlined"
        className={styles.searchInput}
        value={searchFilter}
        onChange={handleSearchFilter}
      />
    </div>
  )
}

export default HeaderFilters
