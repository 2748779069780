import {
  isAfter as DateFnsIsAfter,
  isBefore as DateFnsIsBefore,
  isSameDay as DateFnsIsSameDay,
  addDays,
  addMonths,
  format,
  isAfter,
  isBefore,
} from 'date-fns'
import moment, { months } from 'moment-timezone'
import localization from 'moment/locale/es'
import { createCalendar } from 'pages/CalendarPage/createCalendar'

moment.defineLocale('es', localization)

export const formatDate = (date, format = undefined, utc = true) => {
  const locale = localStorage.getItem('locale') ?? 'es'
  moment.updateLocale(locale, localization)

  return moment(date)
    .utc(utc)
    .format(format ?? 'YYYY-MM-DD HH:mm:ss')
}

export const formatActionTime = (date, time) =>
  moment(`${date} ${time.getHours()}:${time.getMinutes()}`).format('YYYY-MM-DD HH:mm:ss')

export const lessThanToday = (date, compareDate) => new Date(date) < (compareDate ? new Date(compareDate) : new Date())

export const formatFullDate = (date) => {
  const locale = localStorage.getItem('locale')

  moment.locale(locale, localization)
  return moment(date).format('D MMMM YYYY | h:mm A')
}

export const getRecurringDates = (array) => {
  return array
    .reduce((acc, action) => {
      if (action['IS_PENDING'] === 1 && action['IS_RECURRING'] && !action.copy) {
        let recurrentActions = []
        let date = new Date(action.startDate)
        const lastDate = action.endDate ? new Date(action.endDate) : new Date()

        if (!action.endDate) lastDate.setDate(new Date(date).getDate() + 365)

        while (isBefore(new Date(date), new Date(lastDate))) {
          let newDate

          let newAction = {
            ...action,
            idCalendarAction: null,
            originalId: action.idCalendarAction,
            originalDate: action.startDate,
            copy: true,
          }

          switch (action.recurringType) {
            case 1:
              newDate = addDays(new Date(date), 1)
              break
            case 2:
              newDate = addDays(new Date(date), 7)
              break
            case 3:
              newDate = addMonths(new Date(date), 1)
              break
            default:
              break
          }

          date = format(newDate, 'yyyy-MM-dd HH:mm:00')
          newAction.startDate = date

          // La fecha es antes de la fecha final, pero no es el mismo dia
          // Siempre se quiere incluir el mismo dia
          if (isAfter(new Date(date), lastDate) && !DateFnsIsSameDay(new Date(date), lastDate)) {
            continue
          }

          recurrentActions.push(newAction)
        }

        acc.push([action, recurrentActions])
        return acc
      } else {
        acc.push(action)
        return acc
      }
    }, [])
    .flat(2)
}

export const getDate = (d) => {
  const date = new Date(d)

  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  const hours = date.getHours()
  const minutes = date.getMinutes()

  const fullTime = `${hours}:${minutes === 0 ? '00' : minutes}:00`
  const fullDate = `${year}-${month}-${day}`

  return {
    day,
    month,
    year,
    hours,
    minutes,
    fullTime,
    fullDate,
  }
}

export const compareHours = (date1, date2) => {
  const d1 = getDate(date1)
  const d2 = getDate(date2)

  return d1.fullTime !== d2.fullTime ? `${d1.fullDate} ${d2.fullTime}` : null
}

export const getDateWithHour = (hour) => {
  if (moment(hour).isValid()) {
    return hour
  }

  const [hours, minutes] = hour?.split(':')

  const date = new Date()
  date.setHours(hours)
  date.setMinutes(minutes)

  return date
}

export const daysDiff = (date1, date2) => {
  const d1 = moment(date1)
  const d2 = moment(date2)

  const ms = moment.duration(moment(d2).diff(d1)).asMilliseconds()
  const minutes = ms / 60000
  const hours = minutes / 60
  const days = Math.round(hours / 24)

  if (!days) {
    return 0
  }

  return days + 1
}

export const monthsDiff = (date1, date2 = null) => {
  let months
  const currentDate = new Date()
  let d1 = new Date(date1)
  let d2 = null

  if (!date2 || (date2 && moment(date2).isAfter(currentDate))) {
    d2 = currentDate
  } else {
    d2 = new Date(date2)
  }

  months = (d2.getFullYear() - d1.getFullYear()) * 12
  months -= d1.getMonth()
  months += d2.getMonth()
  return months <= 0 ? 0 : months
}

export const getMonthWeeks = () => {
  const today = moment()
  const thisYear = today.year()
  const thisMonth = today.month() + 1
  const firstDay = new Date(thisYear, thisMonth, 1).getDate()
  const lastDay = new Date(thisYear, thisMonth, 0).getDate()

  const { weeks: data } = createCalendar(thisYear, thisMonth)

  const weeks = data.map((week, weekIndex) => {
    return week
      .map((el, index) => {
        if (index !== 0 && index !== week.length - 1) return null

        const isFirstDay = index === 0
        const isLastDay = index === week.length - 1
        const isFirstWeek = weekIndex === 0
        const isLastWeek = weekIndex === data.length - 1

        if (isFirstDay && isFirstWeek && el > firstDay) return moment(`${thisYear}-${thisMonth - 1}-${el}`)
        else if (isLastDay && isLastWeek && el < lastDay) {
          const currentMonth = thisMonth + 1 > 12 ? 1 : thisMonth + 1
          const currentYear = thisMonth + 1 > 12 ? thisYear + 1 : thisYear

          return moment(`${currentYear}-${currentMonth}-${el}`)
        } else {
          return moment(`${thisYear}-${thisMonth}-${el}`)
        }
      })
      .filter((el) => !!el)
  })

  return weeks
}

export const msToTime = (ms) => {
  if (ms === 0) return undefined

  const time = moment.duration(ms)

  const parseValue = (value) => {
    return value === 0 && !!value ? '00' : value < 10 ? '0' + String(value) : value
  }

  return `${parseValue(time.hours())}:${parseValue(time.minutes())}:${parseValue(time.seconds())}`
}

export const getCurrentMonth = (locale) => {
  const today = moment()
  const current = today.month()

  return months(locale)[current]
}

export const isSameDay = (date, compareTo) => {
  const dateToCompare = moment(compareTo)
  const year = dateToCompare?.year()
  const dateWithouthYear = moment(date)?.format('MM-DD')
  const final = moment(`${year}-${dateWithouthYear}`)

  return final?.isSame(dateToCompare, 'day')
}

export const isOnRange = (dateString, range) => {
  if (!dateString) return false

  const [start, end, totalDays] = range
  const date = new Date(dateString.replace(/-/g, '/'))

  if (totalDays >= 2) {
    return (
      DateFnsIsSameDay(date, new Date(start)) || !(DateFnsIsBefore(date, new Date(start)) || DateFnsIsAfter(date, new Date(end)))
    )
  } else {
    return DateFnsIsSameDay(date, new Date(start)) || DateFnsIsSameDay(date, new Date(end))
  }
}

export const isToday = (d) => {
  const today = moment()

  return today.isSame(d, 'day')
}

// SOLO SCHEDULES
const renderDayHour = (workday) => {
  const locale = localStorage.getItem('locale')
  const firstHour = workday.startTime.split(':').slice(0, 2).join(':')
  const lastHour = workday.endTime.split(':').slice(0, 2).join(':')

  return `- ${firstHour} ${locale === 'es' ? 'a' : 'to'} ${lastHour}`
}

export const renderDayString = (workDay) => {
  const locale = localStorage.getItem('locale')
  const { startTime, endTime, locale: text } = workDay
  const firstHour = startTime.split(':').slice(0, 2).join(':')
  const lastHour = endTime.split(':').slice(0, 2).join(':')
  const timeString = `${firstHour} - ${lastHour}`

  return `${text?.medium[locale]}: ${timeString}`
}

export const renderDaysString = (workdays, withHours = true) => {
  const locale = localStorage.getItem('locale')
  let counter = 0
  let fullString = true

  for (const day of workdays) {
    if (counter < day.order) {
      fullString = false
    }
    counter++
  }

  if (fullString) {
    const first = workdays[0]
    const last = workdays[workdays.length - 1]

    return `${first?.locale?.medium[locale]} ${locale === 'es' ? 'a' : 'to'} ${last?.locale?.medium[locale]} ${
      withHours ? renderDayHour(first) : ''
    }`
  } else {
    return (
      workdays.reduce(
        (acc, item, index) => acc + (index === 0 ? item?.locale?.medium[locale] : `, ${item?.locale?.medium[locale]}`),
        ''
      ) +
      ' ' +
      (withHours ? renderDayHour(workdays[0]) : '')
    )
  }
}

// WORKED HOURS MODULE
const hourMinutesSecondsToString = (times, withSeconds = true) => {
  const [h, m, s] = times
  const addZero = (n) => (n < 10 ? `0${n}` : n)

  return `${addZero(h)}:${addZero(m)}${withSeconds ? `:${addZero(s)}` : ''}`
}

const secondsToTimeString = (s, withSeconds = true) => {
  let delta = Math.abs(s) / 1000
  const hours = Math.floor(delta / 3600)
  delta -= hours * 3600
  const minutes = Math.floor(delta / 60) % 60
  delta -= minutes * 60
  const seconds = Math.floor(delta % 60)

  return hourMinutesSecondsToString([hours, minutes, seconds])
}

export const timeDiff = (date1, date2 = null) => {
  const d1 = new Date(date1)
  const d2 = date2 ? new Date(date2) : new Date()
  const diff = +d2 - +d1

  if (!moment(diff).isValid()) {
    return ''
  }

  return secondsToTimeString(diff)
}

export const getHoursWorked = (data, withSeconds = false) => {
  if (!data?.length) return 0

  const time = data?.reduce(
    (acc, day) => {
      const { workedHours } = day
      const [hours, minutes, seconds] = workedHours?.split(':')

      return {
        hours: acc.hours + Number(hours),
        minutes: acc.minutes + Number(minutes),
        seconds: acc.seconds + Number(seconds),
      }
    },
    {
      hours: 0,
      minutes: 0,
      seconds: 0,
    }
  )
  const { hours, minutes, seconds } = time
  const hoursFromMinutes = Math.floor(minutes / 60)
  const minutesFromMinutes = minutes % 60
  const minutesFromSeconds = Math.floor(seconds / 60)
  const secondsFromSeconds = seconds % 60

  const totalHours = hours + hoursFromMinutes
  const totalMinutes = minutesFromMinutes + minutesFromSeconds
  const totalSeconds = secondsFromSeconds

  return hourMinutesSecondsToString([totalHours, totalMinutes, totalSeconds], withSeconds)
}

export const getHoursWeekWorked = (week, data) => {
  const [start, finish] = week
  const weekData = data?.filter((day) => {
    const date = new Date(day.startTime)

    return date >= start && date <= finish
  })

  return getHoursWorked(weekData, true)
}
