import { DASHBOARD_PAGE_URL, LOGIN_PAGE_URL } from 'constants/urls'
import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import { UID_COOKIE, DISABLE_MAINTENANCE } from 'constants/cookies'
import { useLocation, useNavigate } from 'react-router'
import { getMyUser } from 'actions/users'
import { useDispatch } from 'react-redux'
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay'
import OopsError from 'components/OopsError/OopsError'
import useLocale from 'hooks/useLocale'
import getContent from './content'
import useInstallApp from './useInstallApp'
import MobileInstall from 'components/MobileInstall/MobileInstall'
import { getRoles } from 'actions/roles'
import useUsers from 'hooks/useUsers'
import MaintenancePage from 'pages/MaintenancePage/MaintenancePage'
import { IN_MAINTENANCE } from 'env'

const _getUserData = async (dispatch, uidCookie, setLoading, setAuthError) => {
  setLoading(true)
  const response = await getMyUser(uidCookie)

  if (response?.payload?.error) {
    Cookies.remove(UID_COOKIE)
    setAuthError(true)
  }

  const { idCompany } = response.payload.company ?? {}
  dispatch(await getRoles(idCompany))

  dispatch(response)
  setLoading(false)
}

const AuthValidation = ({ children }) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const locale = useLocale()
  const { isLoadingMyUser } = useUsers()

  const uidCookie = Cookies.get(UID_COOKIE)
  const isLoginPage = pathname === LOGIN_PAGE_URL

  const [loading, setLoading] = useState(false)
  const [authError, setAuthError] = useState(false)
  const errorText = getContent(locale)

  useEffect(() => {
    if (isLoginPage ? !uidCookie : uidCookie || authError) {
      if (!uidCookie) return
      if (isLoadingMyUser) _getUserData(dispatch, uidCookie, setLoading, setAuthError)
    } else navigate(isLoginPage ? `/${DASHBOARD_PAGE_URL}` : `${LOGIN_PAGE_URL}`)
  }, [uidCookie, navigate, isLoginPage, dispatch, authError, isLoadingMyUser])

  const { event, activePopup, setActivePopup } = useInstallApp()

  if (IN_MAINTENANCE && !Cookies.get(DISABLE_MAINTENANCE)) return <MaintenancePage />

  if (loading) return <LoadingOverlay />

  if (authError)
    return (
      <OopsError
        title={errorText.title}
        description={errorText.description}
        link={LOGIN_PAGE_URL}
        buttonName={errorText.buttonName}
      />
    )

  return (
    <>
      {activePopup && <MobileInstall event={event} setActive={setActivePopup} />}
      {children}
    </>
  )
}

export default AuthValidation
