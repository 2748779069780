import { Chip, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import useHumanResources from 'hooks/useHumanResources'
import useLocale from 'hooks/useLocale'
import React, { useMemo } from 'react'
import styles from './index.module.scss'

const SelectedDepartment = ({ name, handleDelete }) => <Chip label={name} color="primary" onDelete={handleDelete} />

const DepartmentSelector = ({ data = [], setter, onUpdateEmployee }) => {
  const locale = useLocale()
  const { departments } = useHumanResources()
  const selectData = useMemo(() => departments?.map((el) => ({ text: el?.name, value: el?.idDepartment })), [departments])

  const selectedDepartmentsValue = useMemo(
    () =>
      data?.reduce((acc, el) => {
        if (el?.idDepartment) {
          acc.push(el?.idDepartment)
        } else {
          acc.push(el)
        }

        return acc
      }, []),
    [data]
  )

  const handleChange = (e) => {
    const { value } = e.target

    setter((state) => {
      return {
        ...state,
        departments: value,
      }
    })
  }

  const handleDelete = (id) => {
    setter((state) => {
      return {
        ...state,
        departments: state?.departments?.filter((el) => (el?.idDepartment ? +el?.idDepartment !== +id : +el !== +id)),
      }
    })
  }

  const selectedDepartmentsInfo = selectedDepartmentsValue?.map((el) => departments?.find((d) => d.idDepartment === el))

  return (
    <section>
      <FormControl fullWidth required variant="outlined">
        <InputLabel id="label-id-departments">{locale === 'es' ? 'Departamentos' : 'Departments'}</InputLabel>
        <Select
          multiple
          variant="outlined"
          value={selectedDepartmentsValue}
          onChange={handleChange}
          label={locale === 'es' ? 'Departamentos' : 'Departments'}
          labelId="label-id-departments"
        >
          {selectData?.map((option) => (
            <MenuItem value={option?.value} key={`key-${option?.value}`}>
              {option?.text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {selectedDepartmentsValue.length ? (
        <section className={styles.chipsContainer}>
          {selectedDepartmentsInfo?.map((department) => (
            <SelectedDepartment
              name={department?.name}
              id={department?.idDepartment}
              key={department?.idDepartment}
              handleDelete={() => handleDelete(department?.idDepartment)}
            />
          ))}
        </section>
      ) : null}
    </section>
  )
}

export default DepartmentSelector
