import React, { useState } from 'react'
import styles from './index.module.scss'
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core'

const Checkboxes = ({ options = [], defaultChecked, title, onChange }) => {
  const [checked, setChecked] = useState(String(defaultChecked) ?? '')

  const handleChange = (e) => {
    setChecked(e.target.value)

    if (typeof onChange === 'function') onChange(e)
  }

  return (
    <FormGroup classes={{ root: styles.root }}>
      <strong>{title}</strong>
      {options.map((option) => (
        <FormControlLabel
          key={option?.value}
          control={
            <Checkbox
              color="primary"
              checked={checked === String(option?.value)}
              value={String(option?.value)}
              onChange={handleChange}
            />
          }
          label={option?.label}
        />
      ))}
    </FormGroup>
  )
}

export default Checkboxes
