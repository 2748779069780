import {
  CLEAN_BONUS,
  DELETE_DEDUCTION,
  DELETE_DEPARTMENT,
  DELETE_EMPLOYEE,
  DELETE_EMPLOYEE_WORKED_HOURS,
  DELETE_EVENT,
  DELETE_FREE_TIME,
  DELETE_PAYMENT_STUB,
  DELETE_PAYMENT_STUB_FACTORY,
  DELETE_PAYMENT_STUB_RECURRENCY,
  DELETE_POSITION,
  DELETE_SCHEDULE,
  DELETE_WORKED_HOURS_BREAK,
  GET_DEDUCTIONS,
  GET_DEPARTMENTS,
  GET_EMPLOYEES,
  GET_EVENTS,
  GET_POSITIONS,
  GET_SCHEDULES,
  PAY_BONUS,
  UPDATE_DEDUCTION,
  UPDATE_DEPARTMENT,
  UPDATE_EMPLOYEE,
  UPDATE_EMPLOYEE_FREE_TIME,
  UPDATE_EMPLOYEE_WORKED_HOURS,
  UPDATE_EVENT,
  UPDATE_PAYMENT_STUB,
  UPDATE_PAYMENT_STUB_FACTORY,
  UPDATE_PAYMENT_STUB_RECURRENCY,
  UPDATE_POSITION,
  UPDATE_SCHEDULE,
  UPLOAD_DEDUCTION,
  UPLOAD_DEPARTMENT,
  UPLOAD_EMPLOYEE,
  UPLOAD_EVENT,
  UPLOAD_FREE_TIME,
  UPLOAD_PAYMENT_STUB,
  UPLOAD_PAYMENT_STUB_FACTORY,
  UPLOAD_PAYMENT_STUB_RECURRENCY,
  UPLOAD_POSITION,
  UPLOAD_SCHEDULE,
} from 'constants/actionTypes'

const initialState = {
  departments: [],
  positions: [],
  schedules: [],
  deductions: [],
  employees: [],
  events: [],
  isLoadingDepartments: true,
  isLoadingPositions: true,
  isLoadingSchedules: true,
  isLoadingDeductions: true,
  isLoadingEmployees: true,
  isLoadingEvents: [],
}

const handlers = {
  [GET_DEPARTMENTS]: (state, { payload = {} }) => {
    if (!payload?.departments) return { ...state, errorInRequest: true, isLoadingDepartments: false }

    return { ...state, isLoadingDepartments: false, departments: payload?.departments }
  },
  [UPLOAD_DEPARTMENT]: (state, { payload = {} }) => {
    if (!payload?.department) return { ...state, errorInRequest: true }

    return { ...state, departments: [payload?.department, ...state.departments] }
  },
  [UPDATE_DEPARTMENT]: (state, { payload = {} }) => {
    if (!payload?.department) return { ...state, errorInRequest: true }

    return {
      ...state,
      departments: state.departments.map((department) =>
        department?.idDepartment === payload?.department?.idDepartment ? payload?.department : department
      ),
      employees: payload?.employees,
    }
  },
  [DELETE_DEPARTMENT]: (state, { payload = {} }) => {
    if (!payload?.department) return { ...state, errorInRequest: true }

    return {
      ...state,
      departments: state.departments.filter(({ idDepartment }) => idDepartment !== payload?.department?.idDepartment),
      employees: payload?.employees,
    }
  },
  [GET_POSITIONS]: (state, { payload = {} }) => {
    if (!payload?.positions) return { ...state, errorInRequest: true, isLoadingPositions: false }

    return { ...state, isLoadingPositions: false, positions: payload?.positions }
  },
  [UPLOAD_POSITION]: (state, { payload = {} }) => {
    if (!payload?.position) return { ...state, errorInRequest: true }

    return { ...state, positions: [payload?.position, ...state.positions] }
  },
  [UPDATE_POSITION]: (state, { payload = {} }) => {
    if (!payload?.position) return { ...state, errorInRequest: true }

    return {
      ...state,
      positions: state.positions.map((position) =>
        position?.idPosition === payload?.position?.idPosition ? payload?.position : position
      ),
      employees: payload?.employees,
    }
  },
  [DELETE_POSITION]: (state, { payload = {} }) => {
    if (!payload?.position) return { ...state, errorInRequest: true }

    return {
      ...state,
      positions: state.positions.filter(({ idPosition }) => idPosition !== payload?.position?.idPosition),
      employees: payload?.employees,
    }
  },
  [GET_SCHEDULES]: (state, { payload = {} }) => {
    if (!payload?.schedules) return { ...state, errorInRequest: true, isLoadingSchedules: false }

    return {
      ...state,
      schedules: payload?.schedules,
      isLoadingSchedules: false,
    }
  },
  [UPLOAD_SCHEDULE]: (state, { payload = {} }) => {
    if (!payload?.schedule) return { ...state, errorInRequest: true }

    return {
      ...state,
      schedules: [payload?.schedule, ...state.schedules],
    }
  },
  [UPDATE_SCHEDULE]: (state, { payload = {} }) => {
    if (!payload?.schedule) return { ...state, errorInRequest: true }

    const id = payload?.schedule?.idSchedule

    return {
      ...state,
      schedules: state.schedules.map((schedule) => (id === schedule?.idSchedule ? payload?.schedule : schedule)),
      employees: payload?.employees,
    }
  },
  [DELETE_SCHEDULE]: (state, { payload = {} }) => {
    if (!payload?.schedule) return { ...state, errorInRequest: true }

    const id = payload?.schedule?.idSchedule

    return {
      ...state,
      schedules: state.schedules.filter(({ idSchedule }) => id !== idSchedule),
      employees: payload?.employees,
    }
  },
  [GET_EMPLOYEES]: (state, { payload = {} }) => {
    if (!payload?.employees) return { ...state, errorInRequest: true, isLoadingEmployees: false }

    return {
      ...state,
      employees: payload?.employees,
      isLoadingEmployees: false,
    }
  },
  [UPLOAD_EMPLOYEE]: (state, { payload = {} }) => {
    if (!payload?.employee) return { ...state, errorInRequest: true }

    return {
      ...state,
      employees: [payload?.employee, ...state.employees],
    }
  },
  [UPDATE_EMPLOYEE]: (state, { payload = {} }) => {
    if (!payload?.employee) return { ...state, errorInRequest: true }

    const id = payload?.employee?.idEmployee

    return {
      ...state,
      employees: state.employees.map((employee) => (id === employee?.idEmployee ? payload?.employee : employee)),
    }
  },
  [DELETE_EMPLOYEE]: (state, { payload = {} }) => {
    if (!payload?.employee) return { ...state, errorInRequest: true }

    const id = payload?.employee?.idEmployee

    return {
      ...state,
      employees: state.employees.filter(({ idEmployee }) => id !== idEmployee),
    }
  },
  [GET_DEDUCTIONS]: (state, { payload = {} }) => {
    if (!payload?.deductions) return { ...state, errorInRequest: true, isLoadingDeductions: false }

    return {
      ...state,
      deductions: payload?.deductions,
      isLoadingDeductions: false,
    }
  },
  [UPLOAD_DEDUCTION]: (state, { payload = {} }) => {
    if (!payload?.deduction) return { ...state, errorInRequest: true }

    return {
      ...state,
      deductions: [payload?.deduction, ...state.deductions],
      employees: payload?.employees,
    }
  },
  [UPDATE_DEDUCTION]: (state, { payload = {} }) => {
    if (!payload?.deduction) return { ...state, errorInRequest: true }

    const id = payload?.deduction?.idDeduction

    return {
      ...state,
      deductions: state.deductions.map((deduction) => (id === deduction?.idDeduction ? payload?.deduction : deduction)),
      employees: payload?.employees,
    }
  },
  [DELETE_DEDUCTION]: (state, { payload = {} }) => {
    if (!payload?.deduction) return { ...state, errorInRequest: true }

    const id = payload?.deduction?.idDeduction

    return {
      ...state,
      deductions: state.deductions.filter(({ idDeduction }) => id !== idDeduction),
      employees: payload?.employees,
    }
  },
  [UPLOAD_PAYMENT_STUB]: (state, { payload = {} }) => {
    if (!payload?.stub) return { ...state, errorInRequest: true }

    const employees = state.employees.map((e) => {
      if (e.idEmployee === payload?.stub?.idEmployee) {
        return {
          ...e,
          stubs: [payload?.stub, ...e.stubs].sort((a, b) => new Date(b.date) - new Date(a.date)),
        }
      }

      return e
    })

    return {
      ...state,
      employees,
    }
  },
  [UPDATE_PAYMENT_STUB]: (state, { payload = {} }) => {
    if (!payload?.stub) return { ...state, errorInRequest: true }

    const employees = state.employees.map((e) => {
      if (e.idEmployee === payload?.stub?.idEmployee) {
        return {
          ...e,
          stubs: e.stubs
            .map((s) => (s.idPaymentStub === payload?.stub?.idPaymentStub ? payload?.stub : s))
            .sort((a, b) => new Date(b.date) - new Date(a.date)),
        }
      }

      return e
    })

    return {
      ...state,
      employees,
    }
  },
  [DELETE_PAYMENT_STUB]: (state, { payload = {} }) => {
    if (!payload?.stub) return { ...state, errorInRequest: true }

    const employees = state.employees.map((e) => {
      if (e.idEmployee === payload?.stub?.idEmployee) {
        return {
          ...e,
          stubs: e.stubs
            .filter((s) => s.idPaymentStub !== payload?.stub?.idPaymentStub)
            .sort((a, b) => new Date(b.date) - new Date(a.date)),
        }
      }

      return e
    })

    return {
      ...state,
      employees,
    }
  },
  [UPDATE_EMPLOYEE_WORKED_HOURS]: (state, { payload = {} }) => {
    if (!payload?.workDay) return { ...state, errorInRequest: true }

    const id = payload?.workDay?.idEmployeeWorkDay
    const idEmployee = payload?.workDay?.idEmployee

    return {
      ...state,
      employees: state.employees.map((el) =>
        el.idEmployee === idEmployee
          ? {
              ...el,
              work_day: el.work_day.map((workDay) => (workDay.idEmployeeWorkDay === +id ? payload?.workDay : workDay)),
            }
          : el
      ),
    }
  },
  [DELETE_EMPLOYEE_WORKED_HOURS]: (state, { payload = {} }) => {
    if (!payload?.workDay) return { ...state, errorInRequest: true }

    const id = payload?.workDay?.idEmployeeWorkDay
    const idEmployee = payload?.workDay?.idEmployee

    return {
      ...state,
      employees: state.employees.map((el) =>
        el.idEmployee === idEmployee
          ? {
              ...el,
              work_day: el.work_day.filter((workDay) => workDay.idEmployeeWorkDay !== +id),
            }
          : el
      ),
    }
  },
  [DELETE_WORKED_HOURS_BREAK]: (state, { payload = {} }) => {
    if (!payload?.break) return { ...state, errorInRequest: true }

    const id = payload?.workDay?.idEmployeeWorkDay
    const idEmployee = payload?.workDay?.idEmployee

    return {
      ...state,
      employees: state.employees.map((el) =>
        el.idEmployee === idEmployee
          ? {
              ...el,
              work_day: el.work_day.map((workDay) => (workDay.idEmployeeWorkDay === +id ? payload?.workDay : workDay)),
            }
          : el
      ),
    }
  },
  [UPLOAD_FREE_TIME]: (state, { payload = {} }) => {
    if (!payload?.freeTime) return { ...state, errorInRequest: true }

    if (payload?.employees) {
      return {
        ...state,
        employees: payload?.employees,
      }
    }

    const idEmployee = payload?.freeTime?.idEmployee

    return {
      ...state,
      employees: state.employees.map((el) =>
        el.idEmployee === idEmployee
          ? {
              ...el,
              free_time: [payload?.freeTime, ...el.free_time],
            }
          : el
      ),
    }
  },
  [UPDATE_EMPLOYEE_FREE_TIME]: (state, { payload = {} }) => {
    if (!payload?.freeTime) return { ...state, errorInRequest: true }

    return {
      ...state,
      employees: payload?.employees,
    }
  },
  [DELETE_FREE_TIME]: (state, { payload = {} }) => {
    if (!payload?.freeTime) return { ...state, errorInRequest: true }

    return {
      ...state,
      employees: payload?.employees,
    }
  },
  [GET_EVENTS]: (state, { payload = {} }) => {
    if (!payload?.events) return { ...state, errorInRequest: true, isLoadingEvents: false }

    return {
      ...state,
      events: payload?.events,
      isLoadingEvents: false,
    }
  },
  [UPLOAD_EVENT]: (state, { payload = {} }) => {
    if (!payload?.event) return { ...state, errorInRequest: true }

    return {
      ...state,
      events: [payload?.event, ...state.events],
      employees: payload?.employees,
    }
  },
  [UPDATE_EVENT]: (state, { payload = {} }) => {
    if (!payload?.event) return { ...state, errorInRequest: true }

    const id = payload?.event?.idEvent

    return {
      ...state,
      events: state.events.map((event) => (id === event?.idEvent ? payload?.event : event)),
      employees: payload?.employees,
    }
  },
  [DELETE_EVENT]: (state, { payload = {} }) => {
    if (!payload?.event) return { ...state, errorInRequest: true }

    const id = payload?.event?.idEvent

    return {
      ...state,
      events: state.events.filter(({ idEvent }) => id !== idEvent),
      employees: payload?.employees,
    }
  },
  [PAY_BONUS]: (state, { payload = {} }) => {
    if (!payload?.bonus) return { ...state, errorInRequest: true }

    const employeeId = payload?.bonus?.idEmployee

    return {
      ...state,
      employees: state.employees.map((employee) => {
        if (employee.idEmployee === employeeId) {
          return {
            ...employee,
            bonuses: [...employee.bonuses, payload?.bonus],
          }
        }

        return employee
      }),
    }
  },
  [CLEAN_BONUS]: (state, { payload = {} }) => {
    if (!payload?.bonus) return { ...state, errorInRequest: true }

    const employeeId = payload?.bonus?.idEmployee

    return {
      ...state,
      employees: state.employees.map((employee) => {
        if (employee.idEmployee === employeeId) {
          return {
            ...employee,
            bonuses: employee.bonuses?.filter((bonus) => bonus.idEmployeeBonus !== payload?.bonus?.idEmployeeBonus),
          }
        }

        return employee
      }),
    }
  },
  [UPLOAD_PAYMENT_STUB_FACTORY]: (state, { payload = {} }) => {
    const employeeId = payload.idEmployee

    return {
      ...state,
      employees: state.employees.map((employee) => {
        if (employee.idEmployee === employeeId) {
          return {
            ...employee,
            stub_factory: payload,
          }
        }

        return employee
      }),
    }
  },
  [UPDATE_PAYMENT_STUB_FACTORY]: (state, { payload = {} }) => {
    const employeeId = payload.idEmployee

    return {
      ...state,
      employees: state.employees.map((employee) => {
        if (employee.idEmployee === employeeId) {
          return {
            ...employee,
            stub_factory: payload,
          }
        }

        return employee
      }),
    }
  },
  [DELETE_PAYMENT_STUB_FACTORY]: (state, { payload = {} }) => {
    const employeeId = payload.deletedId

    return {
      ...state,
      employees: state.employees.map((employee) => {
        if (employee.idEmployee === employeeId) {
          return {
            ...employee,
            stub_factory: null,
          }
        }

        return employee
      }),
    }
  },
  [UPLOAD_PAYMENT_STUB_RECURRENCY]: (state, { payload = {} }) => {
    const employeeId = payload.idEmployee

    return {
      ...state,
      employees: state.employees.map((employee) => {
        if (employee.idEmployee === employeeId) {
          return {
            ...employee,
            stub_recurrency: payload,
          }
        }

        return employee
      }),
    }
  },
  [UPDATE_PAYMENT_STUB_RECURRENCY]: (state, { payload = {} }) => {
    const employeeId = payload.idEmployee

    return {
      ...state,
      employees: state.employees.map((employee) => {
        if (employee.idEmployee === employeeId) {
          return {
            ...employee,
            stub_recurrency: payload,
          }
        }

        return employee
      }),
    }
  },
  [DELETE_PAYMENT_STUB_RECURRENCY]: (state, { payload = {} }) => {
    const employeeId = payload.deletedId

    return {
      ...state,
      employees: state.employees.map((employee) => {
        if (employee.idEmploye === employeeId) {
          return {
            ...employee,
            stub_recurrency: null,
          }
        }

        return employee
      }),
    }
  },
}

const humanResources = (state = initialState, action) => {
  const { type } = action

  return handlers[type] ? handlers[type](state, action) : state
}

export default humanResources
