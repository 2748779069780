import React, { useMemo } from 'react'
import useTableData from '../../hooks/useTableData'
import AreaChart from './AreaChart'
import BarsChart from './BarsChart'
import DonutChart from './DonutChart'
import FunnelChart from './FunnelChart'
import HalfMoonChart from './HalfMoonChart'
import LineChart from './LineChart'
import PieChart from './PieChart'
import styles from './PreviewChart.module.scss'
import TreeMap from './TreeMap'

const PreviewChart = ({ indicator = {} }) => {
  const { values, title, graphicType } = indicator
  const tableData = useTableData(indicator)

  const ChartType = useMemo(() => {
    switch (graphicType) {
      case 'bars':
        return BarsChart
      case 'line':
        return LineChart
      case 'pie':
        return PieChart
      case 'area':
        return AreaChart
      case 'donut':
        return DonutChart
      case 'half-moon':
        return HalfMoonChart
      case 'funnel':
        return FunnelChart
      case 'map':
        return TreeMap
      default:
        return null
    }
  }, [graphicType])

  return (
    <div className={styles.wrapper}>
      <strong className={styles.title}>{title}</strong>

      <div className={styles.chartWrapper}>{ChartType && <ChartType values={values} chartData={tableData} />}</div>
    </div>
  )
}

export default PreviewChart
