import {
  GET_REPORT_ERRORS,
  SET_REPORT_ERROR_LOADING_STATE,
  UPDATE_REPORT_ERROR,
  UPLOAD_REPORT_ERROR,
} from 'constants/actionTypes'

const initialState = {
  isLoadingReportErrors: true,
  errorInRequest: false,
  reportErrors: [],
}

const handlers = {
  [GET_REPORT_ERRORS]: (state, { payload = {} }) => {
    if (!payload?.reportErrors) return { ...state, errorInRequest: true, isLoadingReportErrors: false }

    return { ...state, reportErrors: payload?.reportErrors, isLoadingReportErrors: false }
  },
  [UPLOAD_REPORT_ERROR]: (state, { payload = {} }) => {
    if (!payload?.reportError) return { ...state, errorInRequest: true, isLoadingReportErrors: false }

    return { ...state, reportErrors: [...state.reportErrors, payload?.reportError] }
  },
  [UPDATE_REPORT_ERROR]: (state, { payload = {} }) => {
    if (!payload?.reportError) return { ...state, errorInRequest: true, isLoadingReportErrors: false }

    return {
      ...state,
      reportErrors: state?.reportErrors?.map((_reportError) => {
        if (_reportError?.idError === payload?.reportError?.idError) {
          return { ...payload?.reportError }
        }

        return _reportError
      }),
    }
  },
  [SET_REPORT_ERROR_LOADING_STATE]: (state, { payload }) => ({
    ...state,
    isLoadingReportErrors: payload,
  }),
}

const reportErrors = (state = initialState, action) => {
  const { type } = action

  return handlers[type] ? handlers[type](state, action) : state
}

export default reportErrors
