const IMPORT_FORMATS = {
  docx: 'application/vnd.google-apps.document',
  xlsx: 'application/vnd.google-apps.spreadsheet',
  pptx: 'application/vnd.google-apps.presentation',
}

export const getImportType = (file_name) => {
  const fileExtension = file_name.split('.')[1]
  return IMPORT_FORMATS[fileExtension]
}
