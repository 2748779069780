export const TOOLBAR_ACTIONS = [
  'heading',
  '|',
  'bold',
  'italic',
  'link',
  'bulletedList',
  'numberedList',
  '|',
  'uploadImage',
  'blockQuote',
  'insertTable',
  'undo',
  'redo',
]

export const EDITOR_CONFIG = {
  image: {
    toolbar: [],
  },
  toolbar: {
    items: TOOLBAR_ACTIONS,
  },
  link: {
    addTargetToExternalLinks: false,
  },
  mediaEmbed: {
    removeProviders: ['instagram', 'twitter', 'googleMaps', 'flickr', 'facebook', 'dailymotion', 'youtube', 'vimeo', 'spotify'],
  },
}
