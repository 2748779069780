import { useState } from 'react'

const useStepperModal = (initialStep = 1) => {
  const [step, setStep] = useState(initialStep)

  const onNext = () => setStep((step) => step + 1)
  const onPrev = () => setStep((step) => step - 1)

  return { step, setStep, onNext, onPrev }
}

export default useStepperModal
