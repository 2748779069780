import { Avatar } from '@material-ui/core'
import classNames from 'classnames'
import { getRelativeDate } from 'helpers/relativeDate'
import useLocale from 'hooks/useLocale'
import useMoney from 'hooks/useMoney'
import React, { useMemo } from 'react'
import styles from './EmployeeInformation.module.scss'

const InformationItem = ({ label, value, classes = {} }) => {
  return (
    <div className={styles.informationItem}>
      <span className={styles.informationItemTitle}>{label}</span>
      <span className={classNames(styles.informationItemValue, classes?.value)}>{value}</span>
    </div>
  )
}

const EmployeeInformation = ({ employee = {} }) => {
  const locale = useLocale()
  const { formatMoney } = useMoney()
  const { user } = employee
  const { avatarPhoto } = user ?? {}
  const departments = employee.departments.reduce(
    (acc, resource, index) => (index === 0 ? acc + resource.department.name : acc + `, ${resource.department.name}`),
    ''
  )

  const workedTime = useMemo(
    () => getRelativeDate(employee.startTime, employee?.endTime),
    [employee.startTime, employee?.endTime]
  )

  const availability = useMemo(() => {
    let availability = {
      text: locale === 'es' ? 'Disponible' : 'Available',
      value: true,
    }
    const freeTimes = employee.free_time

    if (Boolean(freeTimes.length)) {
      const isOnVacation = freeTimes.find((freeTime) => {
        const today = new Date()
        const startDate = new Date(freeTime.startTime)
        const endDate = new Date(freeTime.endTime)

        return today >= startDate && today <= endDate && freeTime.status === 2
      })

      if (isOnVacation) {
        availability = {
          text: locale === 'es' ? 'No disponible' : 'Unavailable',
          value: false,
        }
      }
    }

    return availability
  }, [locale, employee.free_time])

  return (
    <header className={styles.wrapper}>
      <div className={styles.avatarSection}>
        <h1 className={styles.title}>{employee.name}</h1>
        <Avatar
          classes={{
            root: styles.avatar,
          }}
          src={avatarPhoto}
        >
          {employee.name[0].toUpperCase()}
        </Avatar>
      </div>
      <div className={styles.informationSection}>
        <InformationItem label={locale === 'es' ? 'Correo electrónico' : 'Email'} value={employee.email} />
        <InformationItem
          label={locale === 'es' ? 'Salario mensual' : 'Monthly salary'}
          value={formatMoney(employee.totalSalary, employee.currency)}
        />
        <InformationItem label={locale === 'es' ? 'Cargo' : 'Position'} value={employee.position.name} />
        <InformationItem label={locale === 'es' ? 'Tiempo trabajado actual' : 'Time worked'} value={workedTime} />
        <InformationItem label={locale === 'es' ? 'Departamento' : 'Department'} value={departments} />
        <InformationItem
          label={locale === 'es' ? 'Disponibilidad' : 'Availability'}
          value={availability.text}
          classes={{
            value: availability.value ? styles.available : styles.unavailable,
          }}
        />
      </div>
    </header>
  )
}

export default EmployeeInformation
