import { getRandomColor } from 'constants/colors'
import React from 'react'
import { Line, LineChart as RechartsLineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { reduceChartData } from './helpers'

const LineChart = ({ values, chartData }) => {
  const xAxis = values?.find((el) => el.attribute === 'xAxis')
  const xAxisKey = xAxis.key

  const data = reduceChartData(chartData, xAxisKey)

  return (
    <ResponsiveContainer>
      <RechartsLineChart data={data}>
        <XAxis dataKey="name" />
        <YAxis dataKey="count" />
        <Tooltip />
        <Line isAnimationActive={false} type="monotone" dataKey="count" stroke={getRandomColor(6)} />
      </RechartsLineChart>
    </ResponsiveContainer>
  )
}

export default LineChart
