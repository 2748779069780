import { Avatar, Menu, MenuItem } from '@material-ui/core'
import { signOuFirebasetUser } from 'actions/firebaseAuth'
import {
  CONFIG_PAGE_URL,
  DASHBOARD_PAGE_URL,
  LOGIN_PAGE_URL,
  MY_COMPANY_PAGE_URL,
  MY_SUBSCRIPTION_PAGE_URL,
  PROFILE_PAGE_URL,
} from 'constants/urls'
import { useHideSubscriptionModule } from 'hooks/useHideSubscriptionModule'
import useLocale from 'hooks/useLocale'
import usePermissions from 'hooks/usePermissions'
import useUsers from 'hooks/useUsers'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { getProps } from 'routes'
import styles from './userMenu.module.scss'

const DEFAULT_OPTION_ICON = 'fa fa-angle-right horizontal-icon'
const ID_MENU = 'user-options-menu'

const MenuLink = (props) => {
  const { name, link, handleClose, icon, preload, baseUrl } = props
  const { pathname: currentPath } = useLocation()

  useEffect(() => {
    if (typeof preload === 'function') preload()
  }, [])

  return (
    <Link key={name} to={link} onClick={handleClose}>
      <MenuItem className={currentPath.includes(baseUrl) && styles.menuItem}>
        <i className={icon || DEFAULT_OPTION_ICON} />
        {name}
      </MenuItem>
    </Link>
  )
}

const UserMenu = () => {
  const locale = useLocale()
  const [anchorEl, setAnchorEl] = useState()
  const { myUser } = useUsers()
  const { getPermissions } = usePermissions()
  const permissions = getPermissions()
  const { firstName, type, avatarPhoto, isCompanyOwner } = myUser
  const hideSubscriptionModule = useHideSubscriptionModule()

  const ROL_BY_TYPE = useMemo(
    () => ({ 1: locale === 'es' ? 'Administrador' : 'Administrator', 2: locale === 'es' ? 'Cliente' : 'Client' }),
    [locale]
  )

  const MENU_OPTIONS = useMemo(() => {
    let items = [
      {
        icon: 'far fa-user',
        name: locale === 'es' ? 'Perfil' : 'Profile',
        link: `/${DASHBOARD_PAGE_URL}/${PROFILE_PAGE_URL}`,
        baseUrl: `${PROFILE_PAGE_URL}`,
      },
      {
        icon: 'fas fa-building',
        name: locale === 'es' ? 'Mi Empresa' : 'My company',
        link: `/${DASHBOARD_PAGE_URL}/${MY_COMPANY_PAGE_URL}/my-company`,
        baseUrl: `${MY_COMPANY_PAGE_URL}`,
        hideIf: !(permissions[21] || permissions[22]),
      },
      {
        icon: 'fas fa-tools',
        name: locale === 'es' ? 'Ajustes' : 'Settings',
        link: `/${DASHBOARD_PAGE_URL}/${CONFIG_PAGE_URL}`,
        baseUrl: `${CONFIG_PAGE_URL}`,
        hideIf: !(permissions[23] || permissions[24]),
      },
      {
        icon: 'fas fa-sign-out-alt',
        name: locale === 'es' ? 'Cerrar sesión' : 'Logout',
        onClick: async () => {
          await signOuFirebasetUser()

          window.location.href = LOGIN_PAGE_URL
        },
      },
    ]

    if (!hideSubscriptionModule) {
      items = [
        items[0],
        {
          icon: 'fas fa-crown',
          name: locale === 'es' ? 'Mi suscripción' : 'My subscription',
          link: `/${DASHBOARD_PAGE_URL}/${MY_SUBSCRIPTION_PAGE_URL}`,
          hideIf: !isCompanyOwner,
        },
        ...items.slice(1),
      ]
    }

    return getProps(items)
  }, [locale, permissions, hideSubscriptionModule, isCompanyOwner])

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback((name) => {
    setAnchorEl(null)
  }, [])

  return (
    <>
      <Avatar className={styles.userAvatar} src={avatarPhoto} aria-controls={ID_MENU} onClick={handleClick} />
      {Boolean(anchorEl) && (
        <Menu
          id={ID_MENU}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className={styles.userMenu}
        >
          <div className={styles.basicUserInfo}>
            <span>{firstName}</span>
            <span>{ROL_BY_TYPE?.[type] ?? 'User'}</span>
          </div>
          {MENU_OPTIONS?.filter(({ hideIf }) => !hideIf).map(({ icon, name, link, onClick, preloadComponent, baseUrl }) =>
            link ? (
              <MenuLink
                name={name}
                link={link}
                handleClose={handleClose}
                icon={icon}
                preload={preloadComponent}
                baseUrl={baseUrl}
              />
            ) : (
              <MenuItem
                key={name}
                onClick={() => {
                  onClick()
                  handleClose()
                }}
              >
                <i className={icon || DEFAULT_OPTION_ICON} />
                {name}
              </MenuItem>
            )
          )}
        </Menu>
      )}
    </>
  )
}

export default UserMenu
