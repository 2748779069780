import ButtonIcon from 'components/common/ButtonIcon'
import SubscribeToProModal from 'components/SubscribeToProModal'
import useLocale from 'hooks/useLocale'
import React, { useState } from 'react'

const SubscribeToProButton = ({ ...delegated }) => {
  const locale = useLocale()
  const [subscribeModalOpen, setSubscribeModalOpen] = useState(false)

  const handleSubscribeModalOpen = () => setSubscribeModalOpen(true)

  return (
    <>
      <ButtonIcon
        color="primary"
        variant="contained"
        icon={<i className="fas fa-crown" />}
        onClick={handleSubscribeModalOpen}
        {...delegated}
      >
        {locale === 'es' ? 'SUSCRIBIRSE A PRO' : 'SUBSCRIBE TO PRO'}
      </ButtonIcon>
      <SubscribeToProModal open={subscribeModalOpen} setOpen={setSubscribeModalOpen} />
    </>
  )
}

export default SubscribeToProButton
