import React from 'react'
import styles from './CalendarPage.module.scss'
import useLocale from 'hooks/useLocale'
import getMonths from 'constants/months'
import { getWeekDays } from './CalendarPage'
import { createCalendar } from './createCalendar'
import { CircularProgress } from '@material-ui/core'

const WeekDay = ({ day }) => <div className={styles.dayItem}>{day}</div>

const Calendar = ({
  children,
  isLoading,
  currentYear,
  currentMonth,
  handleNextMonth,
  handlePreviousMonth,
  handleTodayClick,
  renderAfterHeader = null,
  className = '',
}) => {
  const locale = useLocale()
  const { weeks, firstDayIndex, lastDayIndex, lastDayDate } = createCalendar(currentYear, currentMonth)

  const isInactiveDay = ({ weekIndex, index, day }) => {
    return (
      (weekIndex === 0 && index < firstDayIndex && day !== 1) ||
      (weekIndex + 1 === weeks.length && index >= lastDayIndex && day < lastDayDate)
    )
  }

  return (
    <section className={`${styles.calendarRight} ${className}`}>
      <header>
        <section className={styles.title}>
          <strong>{getMonths(locale)[currentMonth - 1]}</strong> {currentYear}
        </section>

        <section className={styles.buttons}>
          <button value={1} onClick={handlePreviousMonth} className={`${styles.button} ${styles.arrow}`}>
            <i className="fas fa-arrow-left" />
          </button>
          <button onClick={handleTodayClick} className={styles.button}>
            {locale === 'es' ? 'Hoy' : 'Today'}
          </button>
          <button value={1} onClick={handleNextMonth} className={`${styles.button} ${styles.arrow}`}>
            <i className="fas fa-arrow-right" />
          </button>
        </section>
      </header>

      {Boolean(renderAfterHeader) && renderAfterHeader}

      <main>
        <section className={styles.daysGrid}>
          <article className={styles.daysRow}>
            {getWeekDays(locale).map((day) => (
              <WeekDay key={`day-${day}`} day={day} />
            ))}
          </article>

          {isLoading ? (
            <section className={styles.chargingSection}>
              <CircularProgress color="primary" />
            </section>
          ) : (
            weeks?.map((week, weekIndex) => (
              <article key={`week-number-${weekIndex}`} className={styles.daysRow}>
                {week.map((day, index) =>
                  children({
                    day,
                    index,
                    weekIndex,
                    basicStyles: {
                      day: styles.dayItem,
                      dayText: styles.dayText,
                      inactive: styles.dayInactive,
                      today: styles.today,
                    },
                    isInactiveDay: isInactiveDay({ day, index, weekIndex }),
                  })
                )}
              </article>
            ))
          )}
        </section>
      </main>
    </section>
  )
}

export default Calendar
