import { isAfter, isBefore, isSameDay, isSameMonth } from 'date-fns'
import { isSameYear } from 'date-fns/esm'
import { pushItem, updateItem, deleteItem } from 'helpers/reducers'

export const parseRegisters = (arr, state) => {
  const { filters } = state

  const reduced = arr.reduce(
    (acc, item) => {
      const itemType = Number(item.type)
      acc[itemType] = [...acc[itemType], item]

      return acc
    },
    { 1: [], 2: [], 3: [] }
  )

  const filterBySearch = (item) => item?.name?.toLowerCase().includes(filters?.search?.toLowerCase())
  const filterByDate = (item) => {
    const type = filters.dateType
    const date = new Date(filters.date)
    const itemDate = new Date(item.date)

    switch (type) {
      case 1: //daily
        return isBefore(itemDate, date) || isSameDay(itemDate, date)
      case 3: //monthly
        return isSameMonth(itemDate, date)
      case 4: //annually
        return isSameYear(itemDate, date)
      case 2: // Date range
        const startDate = new Date(filters.startRange)
        const endDate = new Date(filters.endRange)
        const isBetween = isBefore(startDate, itemDate) && isAfter(endDate, itemDate)
        const sameDate = isSameDay(startDate, itemDate) || isSameDay(endDate, itemDate)

        return isBetween || sameDate

      default:
        return item
    }
  }

  return {
    1: reduced[1].filter(filterBySearch).filter(filterByDate),
    2: reduced[2].filter(filterBySearch).filter(filterByDate),
    3: reduced[3].filter(filterBySearch).filter(filterByDate),
  }
}

const mutateCashFlowChild = (state, payload, config) => {
  const { action, key, id } = config
  const base = state[key].all
  const newArray = action(base, payload, id)

  return {
    ...state,
    [key]: { ...parseRegisters(newArray, state), all: newArray },
  }
}

export const pushIncome = (state, payload) => mutateCashFlowChild(state, payload, { action: pushItem, key: 'incomes' })
export const updateIncome = (state, payload) =>
  mutateCashFlowChild(state, payload, { action: updateItem, id: 'idCashFlowIncome', key: 'incomes' })
export const deleteIncome = (state, payload) =>
  mutateCashFlowChild(state, payload, { action: deleteItem, id: 'idCashFlowIncome', key: 'incomes' })

export const pushExpense = (state, payload) => mutateCashFlowChild(state, payload, { action: pushItem, key: 'expenses' })
export const updateExpense = (state, payload) =>
  mutateCashFlowChild(state, payload, { action: updateItem, id: 'idCashFlowExpense', key: 'expenses' })
export const deleteExpense = (state, payload) =>
  mutateCashFlowChild(state, payload, { action: deleteItem, id: 'idCashFlowExpense', key: 'expenses' })
