import { updateConfig } from 'actions/configs'
import axios from 'axios'
import useConfigs from 'hooks/useConfigs'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getConfigValue } from './configs'
import { isObjectEmpty } from './objects'

const checkOAuthCredentials = (authConfig = {}) => {
  if (isObjectEmpty(authConfig)) return false

  const { updated_at } = authConfig
  const now = new Date()
  const updatedDate = new Date(updated_at)

  if ((now.getTime() - updatedDate.getTime()) / 1000 > 3600) return false

  return true
}

export const getRedirectUri = () => {
  const { protocol, hostname, port } = window.location

  return `${protocol}//${hostname}${port && `:${port}`}`
}

export const useGoogleAuth = () => {
  const { configs, isLoadingConfigs } = useConfigs()
  const [tokenError, setTokenError] = useState(false)

  const authConfig = useMemo(() => getConfigValue('authToken', configs), [configs])
  const refreshConfig = useMemo(() => getConfigValue('refreshToken', configs), [configs])

  const dispatch = useDispatch()
  const hasValidToken = checkOAuthCredentials(authConfig)

  useEffect(() => {
    const fetchNewToken = async () => {
      if (isObjectEmpty(authConfig ?? {}) || isObjectEmpty(refreshConfig ?? {})) {
        return
      }

      const url = 'https://www.googleapis.com/oauth2/v4/token'
      const credentials = {
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
        redirect_uri: getRedirectUri(),
      }

      const body = {
        ...credentials,
        refresh_token: refreshConfig?.value,
        grant_type: 'refresh_token',
      }

      try {
        const tokensRes = await axios.post(url, body)

        const { access_token } = tokensRes?.data
        dispatch(await updateConfig({ ...authConfig, value: access_token }))
      } catch (error) {
        setTokenError(true)
      }
    }

    if (!hasValidToken) {
      fetchNewToken()
    }
  }, [dispatch, authConfig, refreshConfig, hasValidToken])

  return {
    hasValidToken,
    authConfig,
    refreshConfig,
    isLoadingConfigs,
    tokenError,
    authToken: authConfig?.value,
    refreshToken: refreshConfig?.value,
  }
}
