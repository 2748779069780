import DateFnsUtils from '@date-io/date-fns'
import { Button, Divider, TextField } from '@material-ui/core'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import {
  createStubFactory,
  createStubRecurrency,
  getEmployees,
  updateStubFactory,
  updateStubRecurrency,
} from 'actions/humanResources'
import { sendErrorMessage, sendSuccessMessage } from 'actions/modals'
import Dialog from 'components/Dialog/Dialog'
import LoadingSection from 'components/LoadingSection'
import Select from 'components/common/Select/Select'
import useLocale from 'hooks/useLocale'
import useMoney from 'hooks/useMoney'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useImmer } from 'use-immer'
import useStub from '../../hooks/useStub'
import useStubHandlers from '../../hooks/useStubHandlers'
import styles from './RecurrentStubModal.module.scss'
import { FORM_LABELS, INFINITE_TYPES, RECURRENCY_DEFAULTS } from './helpers'

const NumericInput = ({ min = 1, step = '1', ...rest }) => (
  <TextField
    type="number"
    onKeyDown={(e) => {
      return ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
    }}
    inputProps={{
      min,
      step,
    }}
    {...rest}
  />
)

const RecurrentStubModal = ({ open, handleClose, employee }) => {
  const dispatch = useDispatch()
  const { idCompany, stub_factory, stub_recurrency, currency } = employee ?? {}
  const locale = useLocale()
  const title = locale === 'es' ? 'Agregar colilla recurrente' : 'Add recurrent stub'
  const { formatMoney } = useMoney()
  const { defaultWorkedHors, defaultHourlyRate, defaultExtraHourlyRate, deductions } = useStub(employee)

  const _getDefaults = useCallback((key) => {
    if (key === 'custom') {
      return {
        workedHours: 0,
        hourlyRate: defaultHourlyRate,
        extraHourlyRate: defaultExtraHourlyRate,
        basePayment: 0,
        deductions: 0,
        totalPayment: 0,
        extraHoursPayment: 0,
      }
    }
    const binding = {
      monthly: 1,
      biweekly: 2,
      weekly: 3,
    }
    const value = binding[key]

    return {
      workedHours: defaultWorkedHors[value],
      hourlyRate: defaultHourlyRate,
      extraHourlyRate: defaultExtraHourlyRate,
      basePayment: defaultWorkedHors[value] * defaultHourlyRate,
      deductions: deductions(defaultWorkedHors[value])[value],
      totalPayment: defaultWorkedHors[value] * defaultHourlyRate - deductions(defaultWorkedHors[value])[value],
      extraHoursPayment: 0,
    }
  }, []) // eslint-disable-line

  const DEFAULT_FORM = useMemo(
    () => ({
      ..._getDefaults('monthly'),
      nextDate: new Date(),
      isInfinite: true,
      remainingTimes: null,
      recurrency: 'monthly',
      recurrencyDays: 30,
    }),
    [_getDefaults]
  )

  const [form, setForm] = useImmer(DEFAULT_FORM)
  const [loading, setLoading] = useState(false)
  const {
    handleChangeWorkingHours,
    handleChangeExtraWorkingHours,
    handleChangeHourlyRate,
    handleChangeExtraHourlyRate,
    handleChangeExtraPay,
  } = useStubHandlers(setForm, employee)

  const isEdit = Boolean(form?.idPaymentStubFactory && form?.idPaymentStubRecurrency)

  const handleSubmit = async (e) => {
    e.preventDefault()
    const factoryAction = isEdit ? updateStubFactory : createStubFactory
    const recurrencyAction = isEdit ? updateStubRecurrency : createStubRecurrency

    const idEmployee = employee.idEmployee

    setLoading(true)

    try {
      const stubFactory = await factoryAction({ ...form, idEmployee })
      const stubRecurrency = await recurrencyAction({
        ...form,
        idEmployee,
        idPaymentStubFactory: stubFactory?.payload?.id,
      })

      dispatch(stubFactory)
      dispatch(stubRecurrency)
      dispatch(await getEmployees(idCompany))
      dispatch(sendSuccessMessage('Colilla recurrente creada'))
      setLoading(false)
      handleClose()
    } catch (error) {
      dispatch(sendErrorMessage('Error al crear la colilla recurrente'))
      setLoading(false)
    }
  }

  const handleChange = (e) => {
    const isNumber = e.target.type === 'number'
    let value = isNumber ? (isNaN(e.target.value) ? '' : Number(e.target.value)) : e.target.value

    if (!value) value = ''

    setForm((state) => {
      state[e.target.name] = value
    })
  }

  const handleChangeNextDate = (date) => {
    setForm((state) => {
      state.nextDate = date
    })
  }

  const handleChangeRecurrencyDays = (e) => {
    setForm((state) => ({
      ...state,
      recurrency: e.target.value,
      recurrencyDays: RECURRENCY_DEFAULTS.find((entry) => entry.value === e.target.value)?.days,
      ..._getDefaults(e.target.value),
    }))
  }

  useEffect(() => {
    if (stub_factory && stub_recurrency) {
      setForm({
        ...stub_factory,
        ...stub_recurrency,
        idPaymentStubFactory: stub_factory.id,
        idPaymentStubRecurrency: stub_recurrency.id,
        nextDate: stub_recurrency.nextDate.replace(/-/gi, '/').split('T')[0],
        isInfinite: Boolean(stub_recurrency.isInfinite),
        recurrency: RECURRENCY_DEFAULTS?.find((type) => type.days === stub_recurrency.recurrencyDays)?.value ?? 'custom',
      })
    } else {
      setForm(DEFAULT_FORM)
    }
  }, [stub_recurrency, stub_factory, setForm, DEFAULT_FORM])

  return (
    <Dialog
      title={title}
      open={open}
      handleCloseAction={() => {
        handleClose()
        if (!isEdit) {
          setForm(DEFAULT_FORM)
        }
      }}
    >
      <form onSubmit={handleSubmit}>
        <section className={styles.inputsSection}>
          <h4>{locale === 'es' ? 'Datos de la recurrencia' : 'Recurrency data'}</h4>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              fullWidth
              clearable
              disablePast
              required
              inputVariant="outlined"
              name="nextDate"
              format="dd/MM/yyyy"
              label={FORM_LABELS.nextDate}
              value={form.nextDate}
              onChange={handleChangeNextDate}
            />
          </MuiPickersUtilsProvider>

          <Select
            fullWidth
            isRequired
            options={INFINITE_TYPES}
            label={FORM_LABELS.recurrencyTime}
            name="isInfinite"
            value={form.isInfinite}
            onChange={(e) => {
              setForm((state) => {
                state.isInfinite = e.target.value
                if (!e.target.value) {
                  state.remainingTimes = 1
                } else {
                  state.remainingTimes = null
                }
              })
            }}
          />

          {!form.isInfinite && (
            <NumericInput
              fullWidth
              required
              variant="outlined"
              label={FORM_LABELS.remainingTimes}
              name="remainingTimes"
              value={form.remainingTimes}
              onKeyDown={(e) => {
                return ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
              }}
              onChange={handleChange}
            />
          )}

          <Select
            fullWidth
            required
            options={RECURRENCY_DEFAULTS}
            label={FORM_LABELS.recurrencyDays}
            name="recurrency"
            value={form.recurrency}
            onChange={handleChangeRecurrencyDays}
          />

          {form.recurrency === 'custom' && (
            <NumericInput
              fullWidth
              required
              variant="outlined"
              label={FORM_LABELS.recurrencyDays}
              name="recurrencyDays"
              value={form.recurrencyDays}
              onChange={handleChange}
            />
          )}
        </section>

        <Divider />

        <section className={styles.inputsSection}>
          <h4>{locale === 'es' ? 'Datos de la colilla de pago' : 'Payment stub data'}</h4>

          <TextField
            multiline
            fullWidth
            variant="outlined"
            name="description"
            onChange={handleChange}
            label={FORM_LABELS.description}
            value={form.description}
            minRows={3}
            maxRows={4}
          />

          <NumericInput
            required
            name="workedHours"
            value={form.workedHours}
            onChange={handleChangeWorkingHours}
            variant="outlined"
            label={FORM_LABELS.workedHours}
          />
          <NumericInput
            name="extraHours"
            value={form.extraHours}
            onChange={handleChangeExtraWorkingHours}
            variant="outlined"
            label={FORM_LABELS.extraHours}
          />
          <NumericInput
            required
            name="hourlyRate"
            value={form.hourlyRate}
            onChange={handleChangeHourlyRate}
            variant="outlined"
            label={FORM_LABELS.hourlyRate}
            step=".01"
          />
          <NumericInput
            required
            name="extraHourlyRate"
            value={form.extraHourlyRate}
            onChange={handleChangeExtraHourlyRate}
            variant="outlined"
            label={FORM_LABELS.extraHourlyRate}
            step=".01"
          />
          <NumericInput
            name="extraPayments"
            value={form.extraPayments}
            onChange={handleChangeExtraPay}
            variant="outlined"
            label={FORM_LABELS.extraPayment}
            step=".01"
          />
          <TextField
            multiline
            fullWidth
            variant="outlined"
            name="extraPaymentsDescription"
            onChange={handleChange}
            label={FORM_LABELS.extraPaymentDescription}
            value={form.extraPaymentsDescription}
            minRows={3}
            maxRows={4}
          />

          <TextField fullWidth variant="outlined" name="reference" onChange={handleChange} label={FORM_LABELS.reference} />

          <TextField
            required
            readOnly
            disabled={true}
            variant="outlined"
            value={formatMoney(form.deductions, currency)}
            label={FORM_LABELS.deductions}
          />
          <TextField
            required
            readOnly
            disabled={true}
            variant="outlined"
            value={formatMoney(form.basePayment, currency)}
            label={FORM_LABELS.basePayment}
          />
          <TextField
            readOnly
            disabled={true}
            variant="outlined"
            value={formatMoney(form.extraHoursPayment, currency)}
            label={FORM_LABELS.extraHoursPayment}
          />
          <TextField
            required
            readOnly
            disabled={true}
            variant="outlined"
            value={formatMoney(form.totalPayment, currency)}
            label={FORM_LABELS.totalPayment}
          />
        </section>

        {loading ? (
          <LoadingSection />
        ) : (
          <Button fullWidth variant="contained" color="primary" type="submit">
            {isEdit ? (locale === 'es' ? 'Guardar' : 'Save') : locale === 'es' ? 'Crear' : 'Create'}
          </Button>
        )}
      </form>
    </Dialog>
  )
}

export default RecurrentStubModal
