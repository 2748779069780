import DateFnsUtils from '@date-io/date-fns'
import { MobileStepper, Tooltip } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import {
  scheduleActionCampaignTask,
  scheduleActionEvent,
  scheduleActionMassEmail,
  scheduleActionPost,
  selectCalendarAction,
} from 'actions/calendarActions'
import { sendAlarmMessage } from 'actions/modals'
import Dialog from 'components/Dialog/Dialog'
import useCalendarActions from 'hooks/useCalendarActions'
import useLocale from 'hooks/useLocale'
import useStepperModal from 'hooks/useStepperModal'
import { isQuillEmpty } from 'hooks/useTextEditor'
import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import styles from './ScheduleModal.module.scss'
import ScheduleModalCampaignTask from './ScheduleModalCampaignTask'
import ScheduleModalConfiguration from './ScheduleModalConfiguration'
import ScheduleModalEventExtra from './ScheduleModalEventExtra'
import ScheduleModalEventGeneral from './ScheduleModalEventGeneral'
import ScheduleModalMassEmail from './ScheduleModalMassEmail'
import ScheduleModalPost from './ScheduleModalPost'
import ScheduleModalStep from './ScheduleModalStep'
import { parseCalendarAction } from './helpers/actions'
import useScheduleModal from './useScheduleModal'

const ScheduleModal = ({ open, setOpen, year, month, day, idMarketingCampaign = null }) => {
  const locale = useLocale()
  const dispatch = useDispatch()
  const [form, setForm, cleanForm] = useScheduleModal({ year, month, day, idMarketingCampaign })
  const { step, setStep, onNext, onPrev } = useStepperModal()
  const { selectedCalendarAction } = useCalendarActions()
  const formType = useMemo(() => form?.general?.type, [form.general.type])

  const handleClose = () => {
    setOpen(false)
    setTimeout(() => {
      dispatch(selectCalendarAction(null))
      setStep(1)
      cleanForm()
    }, 200)
  }

  const SECTIONS = {
    1: () => (
      <ScheduleModalConfiguration
        form={form}
        setForm={setForm}
        onNext={onNext}
        handleClose={handleClose}
        idMarketingCampaign={idMarketingCampaign}
      />
    ),
    2: () => {
      const _sectionInformation = () => {
        switch (formType) {
          case 1:
            return {
              component: <ScheduleModalMassEmail form={form} setForm={setForm} />,
              title: locale === 'es' ? 'Ajustes del correo masivo' : 'Mass email settings',
              action: () =>
                new Promise(async (resolve) => {
                  const parsedForm = parseCalendarAction(form)

                  await scheduleActionMassEmail(parsedForm, dispatch, handleClose)
                  resolve()
                }),
            }
          case 2:
            return {
              component: <ScheduleModalPost form={form} setForm={setForm} />,
              title: locale === 'es' ? 'Ajustes de la publicación' : 'Publication settings',
              action: () =>
                new Promise(async (resolve) => {
                  const parsedForm = parseCalendarAction(form)

                  await scheduleActionPost(parsedForm, dispatch, handleClose)
                  resolve()
                }),
            }
          case 3:
            return {
              component: <ScheduleModalCampaignTask form={form} setForm={setForm} />,
              title: locale === 'es' ? 'Ajustes de la tarea de marketing' : 'Marketing task settings',
              action: () =>
                new Promise(async (resolve) => {
                  const parsedForm = parseCalendarAction(form)

                  await scheduleActionCampaignTask(parsedForm, dispatch, handleClose)
                  resolve()
                }),
            }
          case 4:
            return {
              component: <ScheduleModalEventGeneral form={form} setForm={setForm} />,
              title: locale === 'es' ? 'Ajustes de evento' : 'Event settings',
              nextStep: (form) => {
                if (isQuillEmpty(form.event.description)) {
                  dispatch(sendAlarmMessage(locale === 'es' ? 'Debe ingresar una descripción' : 'You must enter a description'))

                  return false
                }

                return true
              },
            }
          default:
            return <></>
        }
      }

      const { component, title, action, nextStep } = _sectionInformation()

      return (
        <ScheduleModalStep
          renderComponent={component}
          title={title}
          onClick={action}
          nextStep={nextStep}
          onNext={onNext}
          form={form}
        />
      )
    },
    3: () => {
      const { component, title, action } = {
        component: <ScheduleModalEventExtra form={form} setForm={setForm} />,
        title: locale === 'es' ? 'Ajustes de evento' : 'Event settings',
        action: () =>
          new Promise(async (resolve, reject) => {
            const parsedForm = parseCalendarAction(form)

            if (isQuillEmpty(parsedForm.event.description)) {
              dispatch(
                sendAlarmMessage(
                  locale === 'es'
                    ? 'No puede crear un evento con una descripción vacía'
                    : "You can't create an event with an empty description"
                )
              )
              resolve()
              return
            }

            const formData = new FormData()

            if (parsedForm.event.file) {
              formData.append('file', parsedForm.event.file)
            }

            formData.append('data', JSON.stringify(parsedForm.event))

            await scheduleActionEvent({ ...parsedForm, event: formData, idMarketingCampaign }, dispatch, handleClose)
            resolve()
          }),
      }

      return <ScheduleModalStep renderComponent={component} title={title} onClick={action} />
    },
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Dialog
        disableEnforceFocus
        open={open}
        maxWidth="sm"
        fullWidth={true}
        handleCloseAction={handleClose}
        title={
          <div
            style={{
              display: 'flex',
              gap: '1rem',
              paddingTop: '6px',
            }}
          >
            {step > 1 ? (
              <Tooltip
                title={locale === 'es' ? 'Atras' : 'Back'}
                onClick={onPrev}
                arrow
                placement="top"
                style={{ cursor: 'pointer' }}
              >
                <i className="fas fa-chevron-left" />
              </Tooltip>
            ) : null}
            {locale === 'es' ? 'Agendar contenido' : 'Schedule content'}
          </div>
        }
      >
        {SECTIONS[step]()}

        {selectedCalendarAction ? null : (
          <MobileStepper
            variant="dots"
            steps={formType === 4 ? 3 : 2}
            position="static"
            activeStep={step - 1}
            classes={{ root: styles.stepper, dot: styles.dot }}
          />
        )}
      </Dialog>
    </MuiPickersUtilsProvider>
  )
}

export default ScheduleModal
