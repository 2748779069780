import { TextField } from '@material-ui/core'
import { createDeduction, updateDeduction } from 'actions/humanResources'
import { currencies } from './currencies'

const deductionsFormConfig = (editObject) => {
  const locale = localStorage.getItem('locale')

  return {
    name: locale === 'es' ? 'deducción' : 'deduction',
    isEdit: editObject?.isEdit ?? false,
    form: [
      {
        name: 'name',
        label: locale === 'es' ? 'Tipo de deducción' : 'Deduction type',
        defaultValue: editObject?.name ?? '',
      },
      {
        name: 'percentage',
        type: 'custom',
        component: ({ percentage }, setter) => (
          <TextField
            label={locale === 'es' ? 'Porcentaje' : 'Percentage'}
            variant={'outlined'}
            onChange={(e) => {
              setter((prev) => {
                return { ...prev, fixed: 0, percentage: Number(e.target.value) }
              })
            }}
            value={percentage ? percentage : percentage === 0 ? '' : editObject?.percentage ?? ''}
            type="number"
            inputProps={{
              min: 0,
              max: 100,
              step: '0.01',
            }}
            fullWidth
          />
        ),
      },
      {
        name: 'fixed',
        type: 'custom',
        component: ({ fixed }, setter) => (
          <TextField
            label={locale === 'es' ? 'Monto fijo' : 'Fixed amount'}
            variant={'outlined'}
            onChange={(e) => {
              setter((prev) => {
                return { ...prev, percentage: 0, fixed: Number(e.target.value) }
              })
            }}
            value={fixed ? fixed : fixed === 0 ? '' : editObject?.fixed ?? ''}
            type="number"
            inputProps={{
              min: 0,
              step: '0.01',
            }}
            fullWidth
          />
        ),
      },
      {
        name: 'currency',
        label: locale === 'es' ? 'Moneda' : 'Currency',
        options: currencies,
        defaultOptionSelected: editObject?.currency ?? 'crc',
      },
      {
        hiddenValues: {
          idDeduction: editObject?.idDeduction ?? null,
          idCompany: editObject?.idCompany ?? null,
          percentage: editObject?.percentage ?? undefined,
          fixed: editObject?.fixed ?? undefined,
        },
      },
    ],
    registerAction: createDeduction,
    editAction: updateDeduction,
  }
}

export default deductionsFormConfig
