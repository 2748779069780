import { Button, TextField } from '@material-ui/core'
import { sendErrorMessage } from 'actions/modals'
import Cleave from 'cleave.js/react'
import Dialog from 'components/Dialog/Dialog'
import { PaymentSuccess } from 'components/SubscribeToProModal/SubscribeToProModal'
import useLocale from 'hooks/useLocale'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import SectionHeader from '../SectionHeader'
import styles from './RedeemCode.module.scss'

const RedeemCode = () => {
  const locale = useLocale()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()

  const [code, setCode] = useState(searchParams.get('code'))
  const [successModalOpen, setSuccessModalOpen] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()

    const groups = code.split('-')
    const hasValidLength = groups.length === 3
    const hasValidSubGroupLength = groups.every((group) => group.length === 4)

    if (!hasValidLength || !hasValidSubGroupLength) {
      return dispatch(sendErrorMessage(locale === 'es' ? 'Este código no es válido' : 'This code is not valid'))
    }

    setSuccessModalOpen(true)
  }

  return (
    <>
      <SectionHeader
        title={locale === 'es' ? 'Canjear código' : 'Redeem code'}
        subTitle={locale === 'es' ? 'Ingrese el código' : 'Enter the code'}
      />

      <form className={styles.wrapper} onSubmit={handleSubmit}>
        <TextField
          required
          variant="outlined"
          InputProps={{
            inputComponent: Cleave,
            inputProps: {
              placeholder: 'XXXX-XXXX-XXXX',
              options: { blocks: [4, 4, 4], delimiter: '-' },
            },
          }}
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />

        <Button color="primary" variant="contained" type="submit">
          {locale === 'es' ? 'Canjear' : 'Redeem'}
        </Button>

        <Dialog open={successModalOpen} setOpen={setSuccessModalOpen}>
          <PaymentSuccess
            handleClose={() => setSuccessModalOpen(false)}
            title={locale === 'es' ? '¡El código se ha canjeado de forma exitosa!' : 'The code was redeemed successfully!'}
            body={locale === 'es' ? 'Disfruta de tu suscripción al plan PRO.' : 'Enjoy your PRO subscription'}
          />
        </Dialog>
      </form>
    </>
  )
}

export default RedeemCode
