import { Button, FormGroup, IconButton, Tooltip } from '@material-ui/core'
import classNames from 'classnames'
import Dialog from 'components/Dialog/Dialog'
import Checkbox from 'components/common/Checkbox'
import TextInputIcon from 'components/common/TextInputIcon'
import GROUPS from 'constants/defaultClientGroups'
import useClientGroups from 'hooks/useClientGroups'
import useCustomers from 'hooks/useCustomers'
import useLocale from 'hooks/useLocale'
import useToggle from 'hooks/useToggle'
import React, { useMemo, useState } from 'react'
import styles from './SelectGroupModal.module.scss'

const Search = ({ search, setSearch, hasAllSelected, handleSelectAll }) => {
  const locale = useLocale()

  const handleChange = (e) => setSearch(e.target.value)

  return (
    <div className={classNames(styles.searchWrapper, styles.section)}>
      <TextInputIcon
        variant="outlined"
        value={search}
        placeholder={locale === 'es' ? 'Buscar grupo' : 'Search group'}
        label={locale === 'es' ? 'Buscar' : 'Search'}
        classes={{ root: styles.searchInput }}
        onChange={handleChange}
        icon={<i className="fas fa-search" />}
      />

      {!Boolean(search) && (
        <Button
          variant={hasAllSelected ? 'contained' : 'outlined'}
          color="primary"
          classes={{ root: classNames(styles.selectAll, hasAllSelected && styles.allSelected) }}
          onClick={handleSelectAll}
        >
          {hasAllSelected
            ? locale === 'es'
              ? 'Todos seleccionados'
              : 'All selected'
            : locale === 'es'
            ? 'Seleccionar todos'
            : 'Select all'}
        </Button>
      )}
    </div>
  )
}

const GroupsCheckboxes = ({ groups, selectedGroups, handleSelectGroup }) => {
  const locale = useLocale()
  const { customers: companyClients } = useCustomers()
  const { clientGroups } = useClientGroups()

  const [openGroupClients, setOpenGroupClients] = useToggle(false)
  const [selectedGroup, setSelectedGroup] = useState(null)

  const handleModal = (id) => {
    setSelectedGroup(id)
    setOpenGroupClients()
  }

  const clients = useMemo(() => {
    if (+selectedGroup <= 5) {
      return companyClients.filter((client) => +selectedGroup === client.groupNumber)
    }

    return clientGroups?.find((g) => +selectedGroup === g.idClientGroup)?.clients
  }, [clientGroups, selectedGroup, companyClients])

  return (
    <div classNames={classNames(styles.section)}>
      <FormGroup classes={{ root: styles.checkboxesGroup }}>
        {groups?.length ? (
          groups.map((group) => (
            <section className={styles.checkboxWrapper}>
              <Checkbox
                key={group.value}
                checked={selectedGroups?.find((g) => g === +group.value) ?? false}
                value={group.value}
                label={group.text}
                onChange={handleSelectGroup}
              />
              <Tooltip
                arrow
                title={locale === 'es' ? 'Ver clientes' : 'See clients'}
                placement="top"
                onClick={() => handleModal(group.value)}
              >
                <IconButton size="small" classes={{ root: styles.seeClients }}>
                  <i className="fas fa-eye" style={{ fontSize: '.875rem' }} />
                </IconButton>
              </Tooltip>
            </section>
          ))
        ) : (
          <p className={styles.empty}>{locale === 'es' ? 'No hay grupos disponibles' : 'No available groups'}</p>
        )}
      </FormGroup>

      <Dialog
        open={openGroupClients}
        handleCloseAction={() => handleModal(null)}
        title={locale === 'es' ? 'Clientes' : 'Clients'}
      >
        {clients?.length ? (
          <ul className={styles.clientList}>
            {clients.map((client) => (
              <li>
                {client.name} | {client.email}
              </li>
            ))}
          </ul>
        ) : (
          <p className={styles.empty}>{locale === 'es' ? 'No hay clientes en este grupo' : 'No clients on this group'}</p>
        )}
      </Dialog>
    </div>
  )
}

const SelectGroupModal = ({ selected, setSelected }) => {
  const locale = useLocale()
  const { clientGroups } = useClientGroups()

  const [openModal, setOpenModal] = useToggle(false)
  const [search, setSearch] = useState('')

  const groups = useMemo(() => {
    const defaultGroups = GROUPS(locale)
    const parsedGroups = clientGroups?.filter((g) => g.clients.length)?.map((g) => ({ text: g.name, value: g.idClientGroup }))

    return defaultGroups.concat(parsedGroups).filter((g) => g.text.toLowerCase().includes(search.toLowerCase()))
  }, [locale, search, clientGroups])
  const hasAllSelected = selected.length === groups.length

  const handleSelectGroup = (e) => {
    const groupId = +e.target.value
    const isSelected = selected.find((g) => g === groupId)

    if (isSelected) setSelected(selected.filter((group) => group !== groupId))
    else setSelected([...selected, groupId])
  }

  const handleSelectAll = () => {
    if (hasAllSelected) setSelected([])
    else setSelected(groups.map((g) => g.value))
  }

  return (
    <>
      <Button
        disableElevation
        variant="contained"
        color={selected?.length ? 'primary' : ''}
        classes={{ root: classNames(styles.button, selected?.length && styles.hasSelected) }}
        onClick={setOpenModal}
      >
        {locale === 'es' ? 'Seleccionar grupos ' : 'Select groups '}({selected?.length})
      </Button>

      <Dialog
        open={openModal}
        handleCloseAction={setOpenModal}
        title={locale === 'es' ? 'Seleccionar grupos' : 'Select groups'}
        maxWidth="sm"
        rootClassName={styles.wrapper}
      >
        <Search search={search} setSearch={setSearch} hasAllSelected={hasAllSelected} handleSelectAll={handleSelectAll} />
        <GroupsCheckboxes groups={groups} selectedGroups={selected} handleSelectGroup={handleSelectGroup} />
      </Dialog>
    </>
  )
}

export default SelectGroupModal
