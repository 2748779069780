import { useState } from 'react'

const useToggle = (initialState = false) => {
  const [toggle, setToggle] = useState(initialState)

  const toggler = (forceState = null) => setToggle((state) => forceState ?? !state)

  return [toggle, toggler]
}

export default useToggle
