import {
  GET_COMPANY_PRODUCTS_COSTS,
  UPLOAD_PRODUCTS_COST,
  UPDATE_PRODUCTS_COST,
  DELETE_PRODUCTS_COST,
  GET_COMPANY_INDIRECT_COSTS,
  UPLOAD_INDIRECT_COST,
  UPDATE_INDIRECT_COST,
  DELETE_INDIRECT_COST,
  UPDATE_PRODUCT_INDIRECT_COST,
  UPLOAD_PRODUCT_WORKFORCE,
  UPDATE_PRODUCT_WORKFORCE,
  DELETE_PRODUCT_WORKFORCE,
  UPLOAD_RAW_MATERIAL,
  UPDATE_RAW_MATERIAL,
  DELETE_RAW_MATERIAL,
} from 'constants/actionTypes'

const initialState = {
  isLoadingProductsCosts: true,
  isLoadingIndirectsCosts: true,
  productsCosts: [],
  indirectCosts: [],
}

const handlers = {
  [GET_COMPANY_PRODUCTS_COSTS]: (state, { payload = {} }) => {
    if (!payload?.productsCosts) return { ...state, errorInRequest: true, isLoadingProductsCosts: false }

    return { ...state, productsCosts: payload?.productsCosts, errorInRequest: false, isLoadingProductsCosts: false }
  },
  [UPLOAD_PRODUCTS_COST]: (state, { payload = {} }) => {
    if (!payload?.productCost) return { ...state, errorInRequest: true }

    return { ...state, productsCosts: [payload?.productCost, ...state.productsCosts], errorInRequest: false }
  },
  [UPDATE_PRODUCTS_COST]: (state, { payload = {} }) => {
    if (!payload?.productCost) return { ...state, errorInRequest: true }

    return {
      ...state,
      productsCosts: state.productsCosts.map((productCost) =>
        productCost.idProductCost === payload?.productCost?.idProductCost ? payload?.productCost : productCost
      ),
      errorInRequest: false,
    }
  },
  [DELETE_PRODUCTS_COST]: (state, { payload = {} }) => {
    if (!payload?.productCost) return { ...state, errorInRequest: true }

    return {
      ...state,
      productsCosts: state.productsCosts.filter(({ idProductCost }) => idProductCost !== payload?.productCost?.idProductCost),
      errorInRequest: false,
    }
  },
  [GET_COMPANY_INDIRECT_COSTS]: (state, { payload = {} }) => {
    if (!payload?.indirectCosts) return { ...state, errorInRequest: true, isLoadingIndirectsCosts: false }

    return { ...state, indirectCosts: payload?.indirectCosts, errorInRequest: false, isLoadingIndirectsCosts: false }
  },
  [UPLOAD_INDIRECT_COST]: (state, { payload = {} }) => {
    if (!payload?.indirectCost) return { ...state, errorInRequest: true }

    return { ...state, indirectCosts: [payload?.indirectCost, ...state.indirectCosts], errorInRequest: false }
  },
  [UPDATE_INDIRECT_COST]: (state, { payload = {} }) => {
    if (!payload?.indirectCost) return { ...state, errorInRequest: true }

    return {
      ...state,
      indirectCosts: state.indirectCosts.map((indirectCost) =>
        indirectCost.idIndirectCost === payload?.indirectCost?.idIndirectCost ? payload?.indirectCost : indirectCost
      ),
      errorInRequest: false,
    }
  },
  [DELETE_INDIRECT_COST]: (state, { payload = {} }) => {
    if (!payload?.indirectCost) return { ...state, errorInRequest: true }

    return {
      ...state,
      indirectCosts: state?.indirectCosts?.filter(
        ({ idIndirectCost }) => idIndirectCost !== payload?.indirectCost?.idIndirectCost
      ),
      errorInRequest: false,
    }
  },
  [UPLOAD_PRODUCT_WORKFORCE]: (state, { payload = {} }) => {
    if (!payload?.workforce) return { ...state, errorInRequest: true }

    const costId = Number(payload?.workforce?.idProductCost)

    return {
      ...state,
      productsCosts: state.productsCosts.map((_product) => {
        if (_product.idProductCost === costId) {
          return {
            ..._product,
            workforces: [payload?.workforce, ..._product.workforces],
          }
        } else return _product
      }),
    }
  },
  [UPDATE_PRODUCT_WORKFORCE]: (state, { payload = {} }) => {
    if (!payload?.workforce) return { ...state, errorInRequest: true }

    const costId = Number(payload?.workforce?.idProductCost)
    const workforceId = Number(payload?.workforce?.idWorkforce)

    return {
      ...state,
      productsCosts: state.productsCosts.map((_product) => {
        if (_product.idProductCost === costId) {
          return {
            ..._product,
            workforces: _product.workforces.map((_workforce) => {
              if (_workforce.idWorkforce === workforceId) {
                return payload?.workforce
              } else return _workforce
            }),
          }
        } else return _product
      }),
    }
  },
  [DELETE_PRODUCT_WORKFORCE]: (state, { payload = {} }) => {
    if (!payload?.workforce) return { ...state, errorInRequest: true }

    const costId = Number(payload?.workforce?.idProductCost)
    const workforceId = Number(payload?.workforce?.idWorkforce)

    return {
      ...state,
      productsCosts: state.productsCosts.map((_product) => {
        if (_product.idProductCost === costId) {
          return {
            ..._product,
            workforces: _product.workforces.filter((_workforce) => _workforce.idWorkforce !== workforceId),
          }
        } else return _product
      }),
    }
  },
  [UPLOAD_RAW_MATERIAL]: (state, { payload = {} }) => {
    if (!payload?.rawMaterial) return { ...state, errorInRequest: true }

    const costId = Number(payload?.rawMaterial?.idProductCost)

    return {
      ...state,
      productsCosts: state.productsCosts.map((_product) => {
        if (_product.idProductCost === costId) {
          return {
            ..._product,
            raw_materials: [payload?.rawMaterial, ..._product.raw_materials],
          }
        } else return _product
      }),
    }
  },
  [UPDATE_RAW_MATERIAL]: (state, { payload = {} }) => {
    if (!payload?.rawMaterial) return { ...state, errorInRequest: true }

    const costId = Number(payload?.rawMaterial?.idProductCost)
    const rawMaterialId = payload?.rawMaterial?.idRawMaterial

    return {
      ...state,
      productsCosts: state.productsCosts.map((_product) => {
        if (_product.idProductCost === costId) {
          return {
            ..._product,
            raw_materials: _product.raw_materials.map((_rawMaterial) => {
              if (_rawMaterial.idRawMaterial === rawMaterialId) {
                return payload?.rawMaterial
              } else return _rawMaterial
            }),
          }
        } else return _product
      }),
    }
  },
  [DELETE_RAW_MATERIAL]: (state, { payload = {} }) => {
    if (!payload?.rawMaterial) return { ...state, errorInRequest: true }

    const costId = Number(payload?.rawMaterial?.idProductCost)
    const rawMaterialId = payload?.rawMaterial?.idRawMaterial

    return {
      ...state,
      productsCosts: state.productsCosts.map((_product) => {
        if (_product.idProductCost === costId) {
          return {
            ..._product,
            raw_materials: _product.raw_materials.filter((_rawMaterial) => _rawMaterial.idRawMaterial !== rawMaterialId),
          }
        } else return _product
      }),
    }
  },
  [UPDATE_PRODUCT_INDIRECT_COST]: (state, { payload = {} }) => {
    if (!payload?.productIndirectCost) return { ...state, errorInRequest: true }

    const costId = payload?.productIndirectCost?.idProductCost
    const productIndirectCostId = payload?.productIndirectCost?.idProductIndirectCost

    if (payload?.type === 2) {
      return {
        ...state,
        productsCosts: state.productsCosts.map((_product) => {
          if (_product.idProductCost === costId) {
            return {
              ..._product,
              indirect_costs: _product.indirect_costs.map((_indirectCost) => {
                if (_indirectCost?.idProductIndirectCost === productIndirectCostId) {
                  return payload?.productIndirectCost
                } else return _indirectCost
              }),
            }
          } else return _product
        }),
      }
    } else if (payload?.type === 1) {
      return {
        ...state,
        productsCosts: state.productsCosts.map((_product) => {
          if (_product.idProductCost === costId) {
            return {
              ..._product,
              indirect_costs: [..._product.indirect_costs, payload?.productIndirectCost],
            }
          } else return _product
        }),
      }
    }

    return { ...state }
  },
}

const costs = (state = initialState, action) => {
  const { type } = action

  return handlers[type] ? handlers[type](state, action) : state
}

export default costs
