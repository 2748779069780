import { Button, IconButton } from '@material-ui/core'
import { DASHBOARD_PAGE_URL } from 'constants/urls'
import useScreen from 'hooks/useScreen'
import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './header.module.scss'
import UserMenu from './UserMenu/UserMenu'
import MenuIcon from '@material-ui/icons/Menu'
import MobileHeaderMenu from 'components/MobileHeaderMenu/MobileHeaderMenu'
import { isMobileOrTablet } from 'helpers/breackpoints'
import useUsers from 'hooks/useUsers'

const LOGO_IMG = '/horizontal-logo.svg'

const HeaderAiTextGenerator = () => {
  const [openMobileMenu, setOpenMobileMenu] = useState(false)
  const { breackpoint } = useScreen()
  const isDevice = isMobileOrTablet(breackpoint)

  const onClickOpenMobileMenu = useCallback(() => setOpenMobileMenu(true), [])
  const { myUser } = useUsers()
  return (
    <div className={styles.header}>
      <div className={styles.content}>
        {isDevice && (
          <IconButton aria-label="menu" onClick={onClickOpenMobileMenu}>
            <MenuIcon />
          </IconButton>
        )}
        <Link to={`/${DASHBOARD_PAGE_URL}`}>
          <img src={LOGO_IMG} alt="" />
        </Link>

        {myUser?.type ? (
          <UserMenu />
        ) : (
          <Button variant="contained" color="primary" className="header-button" component={Link} to="/">
            Acceder
          </Button>
        )}
      </div>
      <MobileHeaderMenu open={openMobileMenu} setOpen={setOpenMobileMenu} />
    </div>
  )
}

export default HeaderAiTextGenerator
