import React from 'react'
import Dialog from 'components/Dialog/Dialog'
import Form from 'components/Form/Form'
import useLocale from 'hooks/useLocale'

const FormDialog = ({ open, setOpen, modalFormConfig }) => {
  const locale = useLocale()
  const { name, isEdit, modalTitle } = modalFormConfig

  return (
    <Dialog
      open={open}
      setOpen={setOpen}
      title={modalTitle || `${isEdit ? (locale === 'es' ? 'Editar' : 'Edit') : locale === 'es' ? 'Agregar' : 'Add'} ${name}`}
    >
      <Form setOpen={setOpen} modalFormConfig={modalFormConfig} />
    </Dialog>
  )
}

export default FormDialog
