import React from 'react'
import styles from './PostContent.module.scss'
import useLocale from 'hooks/useLocale'
import { formatDate } from 'helpers/date'
import ContentItem from '../ContentItem'

const PostContent = ({ content, startDate }) => {
  const locale = useLocale()
  const { text, image, socialNetworks } = content ?? {}

  return (
    <div className={styles.mainContainer}>
      <section className={styles.imageContainer}>
        <strong>{locale === 'es' ? 'Imagen' : 'Image'}</strong>
        {image ? <img src={image} alt={text} /> : '-'}
      </section>
      <section className={styles.informationSection}>
        <ContentItem
          noMarginBottom
          borderRight
          title={locale === 'es' ? 'Redes Sociales' : 'Socialmedias'}
          content={socialNetworks}
        />
        <ContentItem
          noMarginBottom
          centered
          title={locale === 'es' ? 'Hora' : 'Time'}
          content={formatDate(startDate, 'HH:mm', true)}
        />
        <ContentItem
          title={locale === 'es' ? 'Texto' : 'Text'}
          content={<div className={styles.content}>{text}</div>}
          className={styles.textContainer}
        />
      </section>
    </div>
  )
}

export default PostContent
