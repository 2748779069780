import { useToggle } from '@mantine/hooks'
import { TextField } from '@material-ui/core'
import CKTextEditor from 'components/CKTextEditor'
import FileInput from 'components/FileInput/FileInput'
import SelectCustomersModal from 'components/MassEmailModal/SelectCustomersModal/SelectCustomersModal'
import SelectGroupModal from 'components/MassEmailModal/SelectGroupModal'
import Select from 'components/common/Select/Select'
import { TYPES_OPTIONS } from 'constants/localData'
import useCustomers from 'hooks/useCustomers'
import useLocale from 'hooks/useLocale'
import useUsers from 'hooks/useUsers'
import React from 'react'
import styles from './ScheduleModalMassEmail.module.scss'

const ScheduleModalMassEmail = ({ setForm, form }) => {
  const locale = useLocale()
  const { myUser, users } = useUsers()
  const { customers } = useCustomers()

  const { massEmail } = form
  const [selectCustomers, toggleSelectCustomers] = useToggle()

  const handleChange = (e, newValue = null, key = null) => {
    setForm((draft) => {
      draft.massEmail[key ?? e.target.name] = newValue ?? e.target.value
    })
  }

  const handleChangeType = (e) => {
    setForm((draft) => {
      const newType = +e.target.value

      draft.massEmail.type = newType

      switch (newType) {
        case 1:
        default:
          draft.massEmail.filterValue = ''
          break
        case 2:
        case 3:
        case 4:
          draft.massEmail.filterValue = []
          break
      }
    })
  }

  const handleAddFile = (e) => {
    setForm((draft) => {
      draft.massEmail.file = e.target.files[0]
    })
  }

  const handleChangeFilterVallue = (value) =>
    setForm((draft) => {
      draft.massEmail.filterValue = value
    })

  const massEmailTypes = myUser?.type === 2 ? TYPES_OPTIONS(locale).filter((item) => item.value !== 4) : TYPES_OPTIONS(locale)

  const EMAIL_TYPE_SELECTION = {
    1: () => null,
    2: () => <SelectGroupModal selected={massEmail?.filterValue} setSelected={handleChangeFilterVallue} />,
    3: () => (
      <SelectCustomersModal
        open={selectCustomers}
        selectedCustomers={massEmail?.filterValue}
        setOpen={toggleSelectCustomers}
        setSelectedCustomers={handleChangeFilterVallue}
        customers={customers}
        type={1}
      />
    ),
    4: () => (
      <SelectCustomersModal
        open={selectCustomers}
        selectedCustomers={massEmail?.filterValue}
        setOpen={toggleSelectCustomers}
        setSelectedCustomers={(value) =>
          setForm((draft) => {
            draft.massEmail.filterValue = value
          })
        }
        customers={users.filter((user) => user.company !== null)}
        type={2}
      />
    ),
  }

  return (
    <form className={styles.form}>
      <TextField
        fullWidth
        required
        name="subject"
        variant="outlined"
        label={locale === 'es' ? 'Asunto' : 'Subject'}
        value={massEmail.subject}
        onChange={handleChange}
      />
      <Select
        isRequired
        name="type"
        value={massEmail.type}
        options={massEmailTypes}
        label={locale === 'es' ? 'Tipo' : 'Type'}
        onChange={handleChangeType}
      />

      {EMAIL_TYPE_SELECTION[massEmail?.type]()}

      <div id="calendar-container">
        <CKTextEditor
          data={massEmail.content ?? ''}
          onChange={(_, editor) => {
            const data = editor.getData()
            handleChange('', data, 'content')
          }}
          config={{ autoGrow_maxHeight: 200 }}
        />
      </div>

      <FileInput
        field={{
          name: 'file',
          icon: 'fas fa-paperclip',
          id: 'file',
          key: 'key-file',
          label: locale === 'es' ? 'Subir archivos' : 'Upload files',
        }}
        onChange={handleAddFile}
      />
    </form>
  )
}

export default ScheduleModalMassEmail
