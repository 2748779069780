import React, { useMemo } from 'react'
import styles from './StatusChip.module.scss'
import { Chip } from '@material-ui/core'
import useLocale from 'hooks/useLocale'

const StatusChip = ({ value }) => {
  const locale = useLocale()

  const params = useMemo(() => {
    switch (value) {
      case 1:
        return {
          es: 'Pendiente',
          en: 'Pending',
          class: styles.pendingLabel,
        }
      case 2:
      case 0:
        return {
          es: 'Aprobado',
          en: 'Approved',
          class: styles.approvedLabel,
        }
      case 3:
        return {
          es: 'Rechazado',
          en: 'Rejected',
          class: styles.rejectedLabel,
        }
      default:
        return ''
    }
  }, [value])

  return <Chip classes={{ root: params.class }} label={params[locale]} />
}

export default StatusChip
