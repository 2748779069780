import { GET_MASS_EMAILS, SEND_MASS_EMAIL } from 'constants/actionTypes'
import {
  MASS_EMAILS_API_ROUTE,
  SEND_MASS_EMAIL_API_ROUTE,
  STORE_IMAGE_API_ROUTE,
  SCHEDULE_MASS_EMAIL_API_ROUTE,
} from 'constants/apiRoutes'
import { apiFindData, apiPostFormData, apiPostImage } from 'helpers/api'
import { getApiStoreImageRoute } from 'helpers/apiStoreImages'

export const sendMassEmail = (massEmail) => apiPostFormData(SEND_MASS_EMAIL_API_ROUTE, massEmail, SEND_MASS_EMAIL)

export const scheduleMassEmail = async (massEmail, dispatch) => {
  const scheduleEmail = await apiPostFormData(SCHEDULE_MASS_EMAIL_API_ROUTE, massEmail, SEND_MASS_EMAIL)

  return scheduleEmail
}

export const getMassEmails = (idCompany) => apiFindData(MASS_EMAILS_API_ROUTE, { id: idCompany }, GET_MASS_EMAILS)

export const uploadImage = async (image) => {
  const serverImageUrl = await apiPostImage(STORE_IMAGE_API_ROUTE, { image })
  if (serverImageUrl?.payload?.error) return false
  const imageRoute = getApiStoreImageRoute(serverImageUrl)

  return imageRoute
}
