import useLocale from 'hooks/useLocale'
import React, { useRef } from 'react'
import Folder from './FileTypes/Folder'
import styles from './GeneralStyles.module.scss'

const DriveFolders = ({ isLoading, folders, onContextMenu, isTrashed }) => {
  const locale = useLocale()
  const wrapperRef = useRef(null)

  if (isLoading) return null
  if (!isLoading && !folders?.length) return null

  return (
    <div ref={wrapperRef}>
      {!isTrashed && (
        <div className={styles.header}>
          <h2 className={styles.title}>
            <i className="fas fa-folder" />
            {locale === 'es' ? 'Carpetas' : 'Folders'}
          </h2>
        </div>
      )}

      <section className={styles.wrapper}>
        {folders?.map(({ name, id, iconLink, parents }) => (
          <Folder key={id} id={id} name={name} icon={iconLink} parents={parents} onContextMenu={onContextMenu} />
        ))}
      </section>
    </div>
  )
}

export default DriveFolders
