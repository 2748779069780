import { sendErrorMessage } from 'actions/modals'
import { isQuillEmpty } from 'hooks/useTextEditor'

const FORM_KEYS = {
  1: 'massEmail',
  2: 'post',
  3: 'marketingTask',
  4: 'event',
}

const validateMassEmail = (email, onError) => {
  const { subject, content, type, filterValue } = email
  const locale = localStorage.getItem('locale')
  let validate = true

  if (type !== 1 && !Boolean(filterValue?.length)) {
    onError(locale === 'es' ? 'Escoja un grupo/cliente/usuario' : 'Choose a group/client/user')
    validate = false
  }

  if (!Boolean(subject?.trim()) || isQuillEmpty(content)) {
    onError(locale === 'es' ? 'El asunto y el contenido son requeridos' : 'The subject and the content are required')
    validate = false
  }

  return validate
}

const validatePost = (post, onError) => {
  const { text } = post
  const locale = localStorage.getItem('locale')
  let validate = true

  if (!Boolean(text.trim())) {
    onError(locale === 'es' ? 'El texto es requerido' : 'The text is required')
    validate = false
  }

  return validate
}

const validateMakertingTask = (task, onError) => {
  const { content } = task
  const locale = localStorage.getItem('locale')
  let validate = true

  if (isQuillEmpty(content)) {
    onError(locale === 'es' ? 'El contenido es requerido' : 'The content is required')
    validate = false
  }

  return validate
}

const validateEvent = () => true

const VALIDATOR = {
  massEmail: validateMassEmail,
  post: validatePost,
  marketingTask: validateMakertingTask,
  event: validateEvent,
}

export const validateCalendarActionForm = (form, dispatch) => {
  const formType = FORM_KEYS[form.general.type]
  const formData = form[formType]

  const onError = (message) => dispatch(sendErrorMessage(message))

  return VALIDATOR[formType](formData, onError)
}
