import { useState } from 'react'

export const useContextMenu = () => {
  const [contextMenuAnchor, setContextMenuAnchor] = useState(null)

  const handleContextMenu = (event) => {
    event.preventDefault()
    setContextMenuAnchor(event.currentTarget)
  }

  const handleCloseContextMenu = () => {
    setContextMenuAnchor(null)
  }

  return { contextMenuAnchor, handleContextMenu, handleCloseContextMenu }
}
