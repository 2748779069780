import React from 'react'
import styles from './CalendarActionContentHeader.module.scss'
import { formatDate } from 'helpers/date'
import useLocale from 'hooks/useLocale'
import { calendarActionTypeName } from '../helpers'
import ContentItem from '../ContentItem'

const CalendarActionEventHeader = ({ calendarAction, title }) => {
  const locale = useLocale()
  const { startDate, originalDate, event } = calendarAction ?? {}
  const { startTime, endTime, isAllDay } = event ?? {}

  const sameTime = isAllDay || startTime === endTime

  const formatTime = (time) => {
    const [hours, minutes] = time.split(':')

    return `${hours}:${minutes}`
  }

  return (
    <div className={styles.headerItemsContainer} style={{ gridTemplateColumns: sameTime ? '' : 'repeat(4, 1fr)' }}>
      <ContentItem title={locale === 'es' ? 'Tipo de contenido' : 'Content type'} content={title} centered borderLeft />

      <ContentItem
        title={locale === 'es' ? 'Fecha' : 'Date'}
        content={formatDate(originalDate ?? startDate, 'DD/MM/YYYY', true)}
        centered
        borderInline
      />

      {sameTime ? (
        <ContentItem title={locale === 'es' ? 'Hora' : 'Start'} content={locale === 'es' ? 'Todo el día' : 'All day'} centered />
      ) : (
        <>
          <ContentItem
            title={locale === 'es' ? 'Hora de inicio' : 'Start time'}
            content={formatTime(startTime)}
            centered
            borderRight
          />
          <ContentItem title={locale === 'es' ? 'Hora de fin' : 'End time'} content={formatTime(endTime)} centered />
        </>
      )}
    </div>
  )
}

const CalendarActionContentHeader = ({ calendarAction, type }) => {
  const locale = useLocale()
  const { startDate, endDate, name, originalDate } = calendarAction ?? {}

  const actionTypeTitle = calendarActionTypeName(type, locale)

  return (
    <header className={styles.mainContent}>
      <h2 className={styles.title}>{name}</h2>

      {type === 4 ? (
        <CalendarActionEventHeader calendarAction={calendarAction} title={actionTypeTitle} />
      ) : (
        <div className={styles.headerItemsContainer}>
          <ContentItem
            title={locale === 'es' ? 'Tipo de contenido' : 'Content type'}
            content={actionTypeTitle}
            centered
            borderLeft
          />
          <ContentItem
            title={locale === 'es' ? 'Fecha de inicio' : 'Start date'}
            content={formatDate(originalDate ?? startDate, 'DD/MM/YYYY', true)}
            centered
            borderInline
          />
          <ContentItem
            title={locale === 'es' ? 'Fecha de fin' : 'End date'}
            content={endDate ? formatDate(endDate, 'DD/MM/YYYY', true) : '-'}
            centered
          />
        </div>
      )}
    </header>
  )
}

export default CalendarActionContentHeader
