import { Button } from '@material-ui/core'
import { selectCalendarActionContent } from 'actions/calendarActions'
import clsx from 'clsx'
import CalendarActionContentModal from 'components/CalendarActionContentModal'
import EventDetails from 'components/EventDetails/EventDetails'
import LoadingSection from 'components/LoadingSection'
import NoProAccess from 'components/NoProAccess'
import ScheduleModal from 'components/ScheduleModal/ScheduleModal'
import getMonths from 'constants/months'
import { isSameDay } from 'date-fns'
import { useHideSubscriptionModule } from 'hooks/useHideSubscriptionModule'
import useLocale from 'hooks/useLocale'
import usePermissions from 'hooks/usePermissions'
import useSubscription from 'hooks/useSubscription'
import Page from 'layouts/Page/Page'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import CalendarLegends from './CalendarLegends'
import styles from './CalendarPage.module.scss'
import CalendarRight from './CalendarRight'
import { createCalendar } from './createCalendar'
import RecurrentActionsSection from './RecurrentActionsSection'
import useCalendarPage from './useCalendarPage'

export const getWeekDays = (locale) =>
  locale === 'es' ? ['Lun', 'Mar', 'Mier', 'Jue', 'Vier', 'Sab', 'Dom'] : ['Mon', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']

export const getFullWeekDays = (locale) =>
  locale === 'es'
    ? ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo']
    : ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

const CalendarPage = () => {
  const locale = useLocale()
  const dispatch = useDispatch()
  const { calendarActions, loadingResources, isLoadingExtraResources, setFilters, filters } = useCalendarPage()
  const { getPermissions } = usePermissions()
  const permissions = getPermissions()
  const { isProUser } = useSubscription()

  const currentDate = new Date()
  const today = currentDate.getDate()
  const currentMonth = currentDate.getMonth() + 1
  const currentYear = currentDate.getFullYear()
  const currentDay = getFullWeekDays(locale)[currentDate.getDay() === 0 ? 6 : currentDate.getDay() - 1]

  const [month, setMonth] = useState(currentMonth)
  const [year, setYear] = useState(currentYear)
  const [date, setDate] = useState(today)
  const [weekDay, setWeekDay] = useState(currentDay)

  const { firstDayIndex } = createCalendar(year, month)

  const [scheduleModal, setScheduleModal] = useState(false)
  const [changingMonth, setChangingMonth] = useState(false)
  const [calendarActionContent, setCalendarActionContent] = useState(false)

  const hideSubscriptionModule = useHideSubscriptionModule()

  const onOpenActionContent = (action) => {
    dispatch(selectCalendarActionContent(action))
    setCalendarActionContent(true)
  }

  const filterEvents = (array, day, month, year) => {
    const filtered = array.filter((item) => {
      const today = new Date(year, month - 1, day)
      const date = new Date(item.startDate)

      return isSameDay(today, date)
    })

    return filtered
  }

  const events = useMemo(
    () => filterEvents(calendarActions, date, month, year).sort((a, b) => new Date(a.date) - new Date(b.date)),
    [calendarActions, date, month, year]
  )

  useEffect(() => {
    if (!changingMonth) return

    setWeekDay(getFullWeekDays(locale)[firstDayIndex])
  }, [firstDayIndex, changingMonth, locale])

  if (!isProUser && !hideSubscriptionModule) return <NoProAccess />

  return (
    <Page title={locale === 'es' ? 'Calendario' : 'Calendar'} write={1} read={2}>
      {loadingResources ? (
        <LoadingSection message={locale === 'es' ? 'Cargando recurso...' : 'Loading resources...'} />
      ) : (
        <>
          {' '}
          <RecurrentActionsSection />
          <main className={styles.mainContainer}>
            <section className={styles.calendarLeft}>
              <header>
                <span className={styles.today}>
                  {date === today && month === currentMonth && (locale === 'es' ? 'HOY' : 'TODAY')}
                </span>
                <h1 className={styles.fullDate}>{`${weekDay}, ${getMonths(locale)[month - 1]} ${date}`}</h1>
              </header>

              <section className={styles.eventsWrapper}>
                <section className={styles.eventsContainer}>
                  <h3 className={styles.title}>{events.length} Items</h3>

                  <ul className={styles.events}>
                    {date > 0 && events.length === 0 ? (
                      <p className={styles.noEvents}>
                        {locale === 'es' ? 'No hay eventos en esta fecha.' : 'No events in this date.'}
                      </p>
                    ) : (
                      calendarActions &&
                      filterEvents(calendarActions, date, month, year)?.map((event, index) => (
                        <EventDetails
                          key={event.copy ? `eventCopy-${index}` : event.idCalendarAction}
                          eventData={event}
                          canWrite={permissions[1]}
                          setScheduleModal={setScheduleModal}
                          onOpenActionContent={onOpenActionContent}
                          isLoadingResources={isLoadingExtraResources}
                        />
                      ))
                    )}
                  </ul>
                </section>
                <footer className={styles.footer}>
                  {permissions[1] && (
                    <Button
                      onClick={() => setScheduleModal(true)}
                      className={styles.button}
                      disabled={
                        (year === currentYear && month < currentMonth) ||
                        (year === currentYear && month <= currentMonth && date < today) ||
                        year < currentYear ||
                        isLoadingExtraResources
                      }
                    >
                      <i className={clsx(['fas fa-plus', styles.icon])} /> {locale === 'es' ? 'Agendar' : 'Schedule'}
                    </Button>
                  )}
                </footer>
              </section>
              <CalendarLegends />
            </section>

            <CalendarRight
              date={date}
              month={month}
              year={year}
              setMonth={setMonth}
              setYear={setYear}
              setWeekDay={setWeekDay}
              setDate={setDate}
              setChangingMonth={setChangingMonth}
              filterEvents={filterEvents}
              calendarActions={calendarActions}
              setFilters={setFilters}
              filters={filters}
            />

            <ScheduleModal open={scheduleModal} setOpen={setScheduleModal} year={year} month={month} day={date} />
            <CalendarActionContentModal open={calendarActionContent} setOpen={setCalendarActionContent} />
          </main>
        </>
      )}
    </Page>
  )
}

export default CalendarPage
