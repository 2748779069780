import { Button } from '@material-ui/core'
import { DateTimePicker } from '@material-ui/pickers'
import { updateWorkDay } from 'actions/humanResources'
import { sendErrorMessage, sendSuccessMessage } from 'actions/modals'
import Dialog from 'components/Dialog/Dialog'
import LoadingSection from 'components/LoadingSection'
import { format, isBefore } from 'date-fns'
import useLocale from 'hooks/useLocale'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import BreaksShowcase from './BreaksShowcase'
import styles from './EditWorkedHoursModal.module.scss'

const EditWorkedHoursModal = ({ open, selected, onClose }) => {
  const dispatch = useDispatch()
  const locale = useLocale()
  const [form, setForm] = useState({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (open) {
      setForm({
        startTime: selected?.startTime,
        endTime: selected?.endTime,
      })
    } else {
      setForm({})
    }
  }, [open, selected])

  const handleChange = (name) => (value) => {
    setForm((prev) => ({ ...prev, [name]: value }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const { startTime, endTime } = form
    const startTimeDate = new Date(startTime)
    const endTimeDate = new Date(endTime)

    if (isBefore(endTimeDate, startTimeDate)) {
      const message =
        locale === 'es' ? 'La fecha de fin no puede ser menor a la de inicio' : 'End date cannot be before start date'
      dispatch(sendErrorMessage(message))

      return
    }

    try {
      setLoading(true)
      dispatch(
        await updateWorkDay({
          ...selected,
          startTime: format(startTimeDate, 'yyyy-MM-dd HH:mm:ss'),
          endTime: format(endTime, 'yyyy-MM-dd HH:mm:ss'),
        })
      )
      setLoading(false)
      onClose()
      dispatch(sendSuccessMessage(locale === 'es' ? 'Jornada editada' : 'Worked hours edited'))
    } catch (error) {
      const message = locale === 'es' ? 'Error al editar la jornada' : 'Error while editing worked hours'
      dispatch(sendErrorMessage(message))
    }
  }

  return (
    <Dialog open={open} handleCloseAction={onClose} title={locale === 'es' ? 'Editar jornada' : 'Edit worked hours'}>
      <form onSubmit={handleSubmit} className={styles.wrapper}>
        <DateTimePicker
          required
          inputVariant="outlined"
          label={locale === 'es' ? 'Tiempo de inicio' : 'Start time'}
          value={form?.startTime}
          onChange={handleChange('startTime')}
          format="dd/MM/yyyy, hh:mma"
          InputProps={{
            endAdornment: <i className="fas fa-clock" />,
          }}
          ampm
        />
        <DateTimePicker
          required
          inputVariant="outlined"
          label={locale === 'es' ? 'Tiempo de fin' : 'End time'}
          value={form?.endTime}
          onChange={handleChange('endTime')}
          format="dd/MM/yyyy, hh:mma"
          InputProps={{
            endAdornment: <i className="fas fa-clock" />,
          }}
          ampm
        />

        <BreaksShowcase data={selected?.breaks} />

        {loading ? (
          <LoadingSection />
        ) : (
          <Button fullWidth color="primary" variant="contained" type="submit">
            {locale === 'es' ? 'Guardar cambios' : 'Save changes'}
          </Button>
        )}
      </form>
    </Dialog>
  )
}

export default EditWorkedHoursModal
