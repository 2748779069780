import React from 'react'
import styles from './MarketingCampaignInformation.module.scss'
import useLocale from 'hooks/useLocale'
import { formatDate } from 'helpers/date'
import useMoney from 'hooks/useMoney'
import { Tooltip } from '@material-ui/core'
import MarketingCampaignsModal from 'pages/MarketingCampaignsPage/MarketingCampaignsModal'
import useToggle from 'hooks/useToggle'
import { useDispatch } from 'react-redux'
import { selectMarketingCampaing } from 'actions/marketingCampaigns'

const reduceManagers = (acc, manager, index) => {
  const getManagerName = (user) => `${user.firstName} ${user.lastName}`

  if (index >= 1) return `${acc}, ${getManagerName(manager.user)}`

  return getManagerName(manager.user)
}

const DescriptionItem = ({ title, content, className = '' }) => (
  <div className={styles.descriptionItem}>
    <strong>{title}</strong>
    <p className={className}>{content}</p>
  </div>
)

const MarketingCampaignInformation = ({ campaign = null }) => {
  const dispatch = useDispatch()
  const locale = useLocale()
  const { formatMoney } = useMoney()
  const { name, description, startDate, endDate, managers, cost, currency } = campaign

  const [open, setToggle] = useToggle()

  const onOpenEditModal = () => {
    setToggle(true)
    dispatch(selectMarketingCampaing(campaign))
  }

  return (
    <>
      <section>
        <h1 className={styles.title}>{name}</h1>

        <section className={styles.information}>
          <section className={styles.description}>
            <DescriptionItem
              title={locale === 'es' ? 'Descripción' : 'Description'}
              content={description.trim().length ? description : '-'}
            />
          </section>
          <section className={styles.deatils}>
            <DescriptionItem
              title={locale === 'es' ? 'Fecha de inicio' : 'Start date'}
              content={formatDate(startDate, 'DD/MM/YYYY | HH:mm', true)}
            />
            <DescriptionItem
              title={locale === 'es' ? 'Fecha de fin' : 'End date'}
              content={endDate ? formatDate(endDate, 'DD/MM/YYYY | HH:mm', true) : '-'}
            />
            <DescriptionItem
              title={locale === 'es' ? 'Encargado' : 'Manager'}
              content={managers?.length ? managers?.reduce(reduceManagers, '') : '-'}
            />
            <DescriptionItem
              title={locale === 'es' ? 'Costo' : 'Cost'}
              content={Boolean(cost) ? formatMoney(cost, currency) : '-'}
            />
          </section>
          <section className={styles.editIconSection}>
            <Tooltip title={locale === 'es' ? 'Editar' : 'Edit'} placement="top" arrow>
              <i className="fas fa-edit" onClick={onOpenEditModal} />
            </Tooltip>
          </section>
        </section>
      </section>
      <MarketingCampaignsModal open={open} toggleModal={setToggle} />
    </>
  )
}

export default MarketingCampaignInformation
