import { InputAdornment, TextField } from '@material-ui/core'
import React from 'react'

const TextInputIcon = ({ icon, position = 'start', ...delegated }) => {
  return (
    <TextField
      InputProps={{
        startAdornment: <InputAdornment position={position}>{icon}</InputAdornment>,
      }}
      {...delegated}
    />
  )
}

export default TextInputIcon
