import { Avatar } from '@material-ui/core'
import MarkupContainer from 'components/common/MarkupContainer'
import { getRelativeTime } from 'helpers/relativeTime'
import useLocale from 'hooks/useLocale'
import React from 'react'
import styles from './Comment.module.scss'

const Comment = ({ _comment, shouldRenderTime = false }) => {
  const locale = useLocale()
  const { author, comment, created_at } = _comment

  const relativeTime = getRelativeTime({ nowDate: new Date(), databaseDate: new Date(created_at), locale })

  return (
    <article className={styles.commentContainer}>
      <Avatar src={author?.avatarPhoto} />

      <section className={styles.comment}>
        <header className={styles.header}>
          <p className={styles.author}>
            <span>{author?.firstName}</span>
            {locale === 'es' ? ' comentó' : ' commented'}:
          </p>

          {shouldRenderTime && <span className={styles.relativeTime}>{relativeTime}</span>}
        </header>

        <MarkupContainer content={comment} className={styles.commentContent} />
      </section>
    </article>
  )
}

export default Comment
