import useLocale from 'hooks/useLocale'
import { useRef } from 'react'
import { useQueryClient } from 'react-query'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { useAbout, useCreateFile, useUploadFile } from '../../useDriveApi'

const importFormats = {
  docx: 'application/vnd.google-apps.document',
  xlsx: 'application/vnd.google-apps.spreadsheet',
  pptx: 'application/vnd.google-apps.presentation',
}

export const useCreateMenu = (handleClose) => {
  const queryClient = useQueryClient()
  const inputRef = useRef(null)
  const folderInputRef = useRef(null)
  const locale = useLocale()
  const { data: aboutData } = useAbout()
  const { usage, limit } = aboutData?.storageQuota ?? {}
  const { mutate: createFile } = useCreateFile()
  const rawUploadFile = useUploadFile()
  const { folderId } = useParams()

  const handleCreate = (e) => {
    const dataLimit = limit - usage
    const submitFile = async (file) => {
      const { type, name, size } = file

      if (type === 'text/plain') {
        toast.error(locale === 'es' ? 'No se permite subir archivos de texto' : 'Text files are not allowed', {
          autoClose: 2000,
        })

        return
      }

      const getImportType = () => {
        const fileExtension = name.split('.')[1]

        return importFormats[fileExtension] ?? type
      }

      const metadata = {
        mimeType: getImportType(),
        name: name,
        parents: [folderId ? folderId : 'root'],
      }

      const metadataBlob = new Blob([JSON.stringify(metadata)], { type: 'application/json' })
      const body = new FormData()

      body.append('file', file)
      body.append('metadata', metadataBlob)

      const headers = {
        'Content-Type': type,
        'Content-Length': size,
      }

      createFile({ body, headers })
    }

    const filesSizesReducer = (acc, file) => acc + file.size

    const { files: filesData } = e.target
    const files = Array.from(filesData)
    const filesSize = files.reduce(filesSizesReducer, 0)

    if (filesSize > dataLimit) {
      toast.error(
        locale === 'es'
          ? 'No tienes espacio suficiente para subir estos archivos'
          : "You don't have enough space to upload these files"
      )

      inputRef.current.value = null
      return
    }

    Array.from(files).forEach(submitFile)
    inputRef.current.value = null
    handleClose()
  }

  const handleCreateFolder = (name) => {
    const metadata = {
      mimeType: 'application/vnd.google-apps.folder',
      name: name ? name : 'New Folder',
      parents: [folderId ? folderId : 'root'],
    }

    const metadataBlob = new Blob([JSON.stringify(metadata)], { type: 'application/json' })
    const body = new FormData()
    body.append('metadata', metadataBlob)

    createFile({ body })
    handleClose()
  }

  const handleCreateGoogleFile = (type) => (name) => {
    const metadata = {
      mimeType: importFormats[type],
      name: name,
      parents: [folderId ? folderId : 'root'],
    }

    const metadataBlob = new Blob([JSON.stringify(metadata)], { type: 'application/json' })
    const body = new FormData()
    body.append('metadata', metadataBlob)

    createFile({ body, isGoogleFile: true })
    handleClose()
  }

  const handleCreateFromFolder = async (e) => {
    const wrapper = async () => {
      const filesToUpload = e.target.files
      // Creamos los paths de la carpeta
      const paths = Array.from(filesToUpload).reduce((acc, file) => {
        const tmp = file.webkitRelativePath.split('/')
        tmp.pop()

        const tempString = tmp.toString()
        if (!acc.includes(tempString)) {
          acc.push(tempString)
        }

        return acc
      }, [])
      const foldersEntities = {}

      // Creamos las carpetas vacias
      for (let i in paths) {
        const folder = paths[i]
        const folderName = folder.split(',').pop()
        const parentFolderName = folder.split(',').slice(0, -1).join(',')
        const parentId = foldersEntities[parentFolderName] ?? folderId ?? 'root'
        const metadata = {
          mimeType: 'application/vnd.google-apps.folder',
          name: folderName,
          parents: [parentId],
        }

        const metadataBlob = new Blob([JSON.stringify(metadata)], { type: 'application/json' })
        const body = new FormData()
        body.append('metadata', metadataBlob)
        const data = await rawUploadFile({ body })
        foldersEntities[folder] = data.id
      }

      Array.from(filesToUpload).forEach((file) => {
        const { type, size } = file
        const hasImportType = importFormats[type]
        const relativePath = file.webkitRelativePath.split('/')
        const fileName = relativePath.pop()
        const folderName = relativePath.slice(0).join(',')
        const parent = foldersEntities[folderName]

        const metadata = {
          mimeType: hasImportType ?? type,
          name: fileName,
          parents: [parent],
        }

        const metadataBlob = new Blob([JSON.stringify(metadata)], { type: 'application/json' })
        const body = new FormData()

        body.append('file', file)
        body.append('metadata', metadataBlob)

        const headers = {
          'Content-Type': type,
          'Content-Length': size,
        }

        rawUploadFile({ body, headers, isGoogleFile: hasImportType })
      })

      folderInputRef.current.value = null
    }

    await toast.promise(
      wrapper,
      {
        pending: locale === 'es' ? 'Subiendo archivos' : 'Uploading files',
        success: locale === 'es' ? 'Archivos subidos' : 'Files uploaded',
        error: locale === 'es' ? 'Error al subir archivos' : 'Error uploading files',
      },
      {
        autoClose: 2000,
      }
    )

    queryClient.invalidateQueries(['files', folderId || 'root'])
    queryClient.invalidateQueries(['folders', folderId || 'root'])
    queryClient.invalidateQueries(['sharedFiles', folderId || 'root'])
    queryClient.invalidateQueries(['sharedFolders', folderId || 'root'])
    queryClient.invalidateQueries(['about'])
  }

  return { inputRef, folderInputRef, handleCreateFromFolder, handleCreate, handleCreateFolder, handleCreateGoogleFile }
}
