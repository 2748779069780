import {
  GET_QUOTATIONS,
  UPLOAD_QUOTATION,
  UPDATE_QUOTATION,
  DELETE_QUOTATION,
  UPLOAD_QUOTATION_COMMENT,
} from 'constants/actionTypes'

const initialState = {
  isLoadingQuotations: true,
  quotations: [],
  errorInRequest: false,
}

const handlers = {
  [GET_QUOTATIONS]: (state, { payload = {} }) => {
    if (!payload?.quotations) return { ...state, errorInRequest: true, isLoadingQuotations: false }

    return { ...state, quotations: payload?.quotations, isLoadingQuotations: false }
  },
  [UPLOAD_QUOTATION]: (state, { payload = {} }) => {
    if (!payload?.quotation) return { ...state, errorInRequest: true, isLoadingQuotations: false }

    return { ...state, quotations: [...state.quotations, payload?.quotation] }
  },
  [UPDATE_QUOTATION]: (state, { payload = {} }) => {
    if (!payload?.quotation) return { ...state, errorInRequest: true, isLoadingQuotations: false }

    return {
      ...state,
      quotations: state?.quotations?.map((quotation) => {
        if (quotation?.idQuotation === payload?.quotation?.idQuotation) {
          return payload?.quotation
        }

        return quotation
      }),
    }
  },
  [DELETE_QUOTATION]: (state, { payload = {} }) => {
    if (!payload?.quotation) return { ...state, errorInRequest: true }

    return {
      ...state,
      quotations: state.quotations.filter(({ idQuotation }) => idQuotation !== payload?.quotation?.idQuotation),
    }
  },
  [UPLOAD_QUOTATION_COMMENT]: (state, action) => {
    const {
      payload: { comment },
    } = action

    const quotationId = comment?.idQuotation
    const quotations = state.quotations.map((quotation) => {
      if (quotation.idQuotation === quotationId) {
        return { ...quotation, comments: [...quotation.comments, comment] }
      }

      return quotation
    })

    return { ...state, quotations }
  },
}

const quotations = (state = initialState, action) => {
  const { type } = action

  return handlers[type] ? handlers[type](state, action) : state
}

export default quotations
