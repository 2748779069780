import React from 'react'
import { CircularProgress, Snackbar } from '@material-ui/core'

const LoadingSnackbar = ({ open }) => {
  return (
    <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={open}>
      <CircularProgress color="secondary" />
    </Snackbar>
  )
}

export default LoadingSnackbar
