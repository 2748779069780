import { getCalendarActions } from 'actions/calendarActions'
import { getClientGroups } from 'actions/clientGroups'
import { getConfigs } from 'actions/configs'
import { getCustomers } from 'actions/customers'
import { getMarketingCampaigns } from 'actions/marketingCampaigns'
import { getFbPages } from 'actions/socialmedia'
import { getCompanyUsers, getUsers } from 'actions/users'
import useCalendarActions from 'hooks/useCalendarActions'
import useClientGroups from 'hooks/useClientGroups'
import useCompany from 'hooks/useCompany'
import useConfigs from 'hooks/useConfigs'
import useCustomers from 'hooks/useCustomers'
import useMarketing from 'hooks/useMarketing'
import useSocialMedia from 'hooks/useSocialMedia'
import useUsers from 'hooks/useUsers'
import { useMemo } from 'react'
import { useParams } from 'react-router'
import useFetchResource from 'hooks/useFetchResource'

const useMarketingCampaignPage = () => {
  const { id } = useParams()
  const { idCompany } = useCompany()
  const { marketingCampaigns, isLoadingMarketingCampaigns } = useMarketing()
  const { isLoadingCompanyUsers, isLoadingUsers } = useUsers()
  const { isLoadingCalendarActions, calendarActions } = useCalendarActions()
  const { isLoadingClientGroups } = useClientGroups()
  const { isLoadingCustomers } = useCustomers()
  const { isLoadingConfigs } = useConfigs()
  const { isLoadingFbPages } = useSocialMedia()

  const promises = useMemo(
    () => [
      getMarketingCampaigns(idCompany),
      getCalendarActions(idCompany),
      getClientGroups(idCompany),
      getCustomers(idCompany),
      getCompanyUsers(idCompany),
      getFbPages(idCompany),
      getConfigs(idCompany),
      getUsers(),
    ],
    [idCompany]
  )

  useFetchResource(promises)

  const currentCampaign = marketingCampaigns?.find((_campaign) => _campaign.idMarketingCampaign === Number(id))

  const filteredActions = useMemo(
    () => calendarActions?.filter((action) => action.idMarketingCampaign && action.idMarketingCampaign === Number(id)),
    [calendarActions, id]
  )

  const isPending =
    isLoadingMarketingCampaigns ||
    isLoadingCalendarActions ||
    isLoadingClientGroups ||
    isLoadingCustomers ||
    isLoadingUsers ||
    isLoadingCompanyUsers ||
    isLoadingConfigs ||
    isLoadingFbPages

  return { isPending, currentCampaign, calendarActions: filteredActions }
}

export default useMarketingCampaignPage
