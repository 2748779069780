const EMPTY = ''

// eslint-disable-next-line eqeqeq
export const invalidStepData = (stepData) => Object?.values?.(stepData)?.some((dataValue) => dataValue.trim() == EMPTY)

const _errorObject = (errorMessageType) => ({
  valid: false,
  errorMessageType,
})

export const validPasswords = (password, repeatedPassword) => {
  if (password.length < 6) return _errorObject('auth/weak-password')

  if (/\s/.test(password)) return _errorObject('password-with-spaces')

  if (!/[A-Z]/.test(password)) return _errorObject('password-without-uppercase')

  if (!/[a-z]/.test(password)) return _errorObject('password-without-lowercase')

  if (!/[0-9]/.test(password)) return _errorObject('password-without-numbers')

  if (!/[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/.test(password)) return _errorObject('password-without-symbols')

  if (password !== repeatedPassword) return _errorObject('different-passwords')

  return { valid: true }
}
