import {
  COMPANY_FORMS,
  COMPANY_FORMS_ANSWERS,
  COMPANY_FORMS_PAGES,
  COMPANY_FORMS_QUESTIONS,
  COMPANY_FORMS_QUESTIONS_ANSWERS,
  UPDATE_LIST_POSITIONS,
} from 'constants/apiRoutes'
import { apiFindData, apiPostData, apiPutData, apiDeleteData } from 'helpers/api'
import {
  GET_FORMS,
  UPLOAD_FORM,
  UPDATE_FORM,
  DELETE_FORM,
  GET_FORM_PAGES,
  UPLOAD_FORM_PAGE,
  UPDATE_FORM_PAGE,
  DELETE_FORM_PAGE,
  GET_FORM_QUESTIONS,
  UPLOAD_FORM_QUESTION,
  UPDATE_FORM_QUESTION,
  DELETE_FORM_QUESTION,
  UPDATE_COMPANY_INFO_QUESTIONS_POSITIONS,
  UPDATE_FORM_QUESTIONS_POSITIONS,
  GET_ANSWERS,
  DELETE_ANSWER,
  UPDATE_INFO_QUESTION_OPTION,
  DELETE_INFO_QUESTION_OPTION,
  UPDATE_QUESTION_OPTION,
  DELETE_QUESTION_OPTION,
} from 'constants/actionTypes'

export const getForms = (idCompany) => apiFindData(COMPANY_FORMS, { id: idCompany }, GET_FORMS)

export const createForm = (form) => apiPostData(COMPANY_FORMS, form, UPLOAD_FORM)

export const updateForm = (form) => apiPutData(COMPANY_FORMS, { ...form, id: form?.idForm }, UPDATE_FORM)

export const deleteForm = (idForm) => apiDeleteData(COMPANY_FORMS, { id: idForm }, DELETE_FORM)

export const getFormPages = (idForm) => apiFindData(COMPANY_FORMS_PAGES, { id: idForm }, GET_FORM_PAGES)

export const createFormPage = (page) => apiPostData(COMPANY_FORMS_PAGES, page, UPLOAD_FORM_PAGE)

export const updateFormPage = (page) => apiPutData(COMPANY_FORMS_PAGES, { ...page, id: page?.idFormPage }, UPDATE_FORM_PAGE)

export const deleteFormPage = (idFormPage) => apiDeleteData(COMPANY_FORMS_PAGES, { id: idFormPage }, DELETE_FORM_PAGE)

export const getFormQuestions = (idForm) => apiFindData(COMPANY_FORMS_QUESTIONS, { id: idForm }, GET_FORM_QUESTIONS)

export const createFormQuestion = (question) => apiPostData(COMPANY_FORMS_QUESTIONS, question, UPLOAD_FORM_QUESTION)

export const updateFormQuestion = (question) =>
  apiPutData(COMPANY_FORMS_QUESTIONS, { ...question, id: question?.idFormQuestion }, UPDATE_FORM_QUESTION)

export const deleteFormQuestion = (idFormQuestion) =>
  apiDeleteData(COMPANY_FORMS_QUESTIONS, { id: idFormQuestion }, DELETE_FORM_QUESTION)

export const updateListsPositions = (request) =>
  apiPostData(
    UPDATE_LIST_POSITIONS,
    request,
    request.companyInfoQuestions ? UPDATE_COMPANY_INFO_QUESTIONS_POSITIONS : UPDATE_FORM_QUESTIONS_POSITIONS
  )

export const getAnswers = (idFormAnswer) => apiFindData(COMPANY_FORMS_ANSWERS, { id: idFormAnswer }, GET_ANSWERS)

export const deleteAnswer = (idFormAnswer) => apiDeleteData(COMPANY_FORMS_ANSWERS, { id: idFormAnswer }, DELETE_ANSWER)

export const addAnswers = (request) => apiPostData(COMPANY_FORMS_QUESTIONS_ANSWERS, request)

export const addOption = (question, option) =>
  question?.idCompanyInfoQuestion
    ? {
        type: UPDATE_INFO_QUESTION_OPTION,
        payload: {
          question: {
            ...question,
            option,
          },
        },
      }
    : {
        type: UPDATE_QUESTION_OPTION,
        payload: {
          question: {
            ...question,
            option,
          },
        },
      }

export const editOption = (question, option) =>
  question?.idCompanyInfoQuestion
    ? {
        type: UPDATE_INFO_QUESTION_OPTION,
        payload: {
          edit: true,
          question: {
            ...question,
            option,
          },
        },
      }
    : {
        type: UPDATE_QUESTION_OPTION,
        payload: {
          edit: true,
          question: {
            ...question,
            option,
          },
        },
      }

export const deleteOption = (question, id) =>
  question?.idCompanyInfoQuestion
    ? {
        type: DELETE_INFO_QUESTION_OPTION,
        payload: {
          question: {
            ...question,
            id: id,
          },
        },
      }
    : {
        type: DELETE_QUESTION_OPTION,
        payload: {
          question: {
            ...question,
            id: id,
          },
        },
      }
