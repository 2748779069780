import classNames from 'classnames'
import { COOKIES_POLICY_PAGE_URL, PRIVACY_POLICY_PAGE_URL, USE_POLICY_PAGE_URL } from 'constants/urls'
import useLocale from 'hooks/useLocale'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './Footer.module.scss'
import ReportErrorModal from './components/ReportErrorModal/ReportErrorModal'
import getContent from './content'

export const Footer = ({ rootClassName = '' }) => {
  const locale = useLocale()
  const content = getContent(locale)
  const currentYear = new Date().getFullYear()
  const INGENIUST_URL = 'https://www.ingeniust.net'

  const [openReportErrorModal, setOpenReportErrorModal] = useState(false)

  return (
    <footer className={classNames(styles.footer, rootClassName)}>
      <div className={styles.leftSection}>
        <img className={styles.logo} src="/logo-white.svg" alt="Digital Pymes White Logo" />

        <div className={styles.copyrightText}>
          <span>{`DigitalPymes ${currentYear} `}&copy; - </span>
          <span>{content?.rightSection?.allRightsReserved}</span>
          <span>
            {content?.rightSection?.developedBy}
            <a className={styles.link} href={INGENIUST_URL}>
              Ingeniust
            </a>{' '}
            & Creative Studios
          </span>
        </div>
      </div>

      <div className={styles.rightSection}>
        <section className={styles.list}>
          <div className={styles.listContainer}>
            <a className={styles.contactsLinks} href={`tel:${content?.leftSection?.contact?.phoneNumber}`}>
              <i className={`fa-solid fa-phone ${styles.icon}`} />
              {content?.leftSection?.contact?.phoneNumber}
            </a>
            <a className={styles.contactsLinks} href={`mailto:${content?.leftSection?.contact?.email}`}>
              <i className={`fa-solid fa-envelope ${styles.icon}`} />
              {content?.leftSection?.contact?.email}
            </a>
          </div>
        </section>

        <section className={styles.list}>
          <div className={styles.listContainer}>
            <div className={styles.policiesLinkContainer}>
              <Link to={PRIVACY_POLICY_PAGE_URL} className={styles.policyLink}>
                {content?.leftSection?.policies?.privacy}
              </Link>
            </div>

            <div className={styles.policiesLinkContainer}>
              <Link to={USE_POLICY_PAGE_URL} className={styles.policyLink}>
                {content?.leftSection?.policies?.usage}
              </Link>
            </div>

            <div className={styles.policiesLinkContainer}>
              <Link to={COOKIES_POLICY_PAGE_URL} className={styles.policyLink}>
                {content?.leftSection?.policies?.cookies}
              </Link>
            </div>
          </div>
        </section>

        <section className={styles.list}>
          <div className={styles.listContainer}>
            <button className={styles.reportErrorsButton} onClick={() => setOpenReportErrorModal(true)}>
              {content?.leftSection?.reportErrors}
            </button>
          </div>
          <div className={styles.listContainer}>
            <div className={styles.policiesLinkContainer}>
              <Link to="/help" className={styles.policyLink}>
                {locale === 'es' ? 'Ayuda' : 'Help'}
              </Link>
            </div>
          </div>
        </section>
      </div>

      <ReportErrorModal open={openReportErrorModal} setOpen={setOpenReportErrorModal} />
    </footer>
  )
}
