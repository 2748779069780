import { Menu } from '@material-ui/core'
import React, { useState } from 'react'
import NamingModal from '../Header/NewButton/NamingModal'
import styles from './ContextMenu.module.scss'
import ContextOptions from './ContextOptions'
import TrashedOptions from './TrashedOptions'
import { useContextMenu } from './useContextMenu'

const ContextMenu = ({
  isTrashed,
  anchorEl,
  onClose,
  folders,
  files,
  onOpenSharingModal,
  onOpenMoveModal,
  onOpenFilePermissionsModal,
  canCreateInShared,
}) => {
  const [open, setOpen] = useState(false)
  const { type, id } = anchorEl?.dataset || {}
  const contextMenuConfig = {
    folders,
    files,
    onClose,
    onOpenSharingModal,
    onOpenMoveModal,
    onOpenFilePermissionsModal,
  }

  const { renameFile, resourceName } = useContextMenu(id, contextMenuConfig)

  const isFolder = type === 'folder'

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        anchorOrigin={{
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        classes={{ paper: styles.wrapper }}
      >
        {isTrashed ? (
          <TrashedOptions styles={styles} config={contextMenuConfig} id={id} />
        ) : (
          <ContextOptions
            config={contextMenuConfig}
            id={id}
            isFolder={isFolder}
            styles={styles}
            handleOpenNamingMenu={() => setOpen(true)}
            canCreateInShared={canCreateInShared}
          />
        )}
      </Menu>

      <NamingModal
        onClose={() => setOpen(false)}
        open={open}
        onCreateFile={(name) => {
          renameFile(name)
          setOpen(false)
        }}
        defaultName={resourceName}
      />
    </>
  )
}

export default ContextMenu
