import { TextField } from '@material-ui/core'
import styles from './TextFieldWhite.module.scss'

const TextFieldWhite = ({ ...delegated }) => {
  return (
    <TextField
      {...delegated}
      variant="outlined"
      InputProps={{
        classes: { focused: styles.focused, root: styles.inputRoot },
        ...delegated.InputProps,
      }}
      InputLabelProps={{
        classes: {
          root: styles.label,
        },
        ...delegated.InputLabelProps,
      }}
    />
  )
}

export default TextFieldWhite
