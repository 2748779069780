import React, { useState } from 'react'
import styles from './MarketingCampaignCalendarView.module.scss'
import useCalendar from 'hooks/useCalendar'
import Calendar from 'pages/CalendarPage/Calendar'
import DayItem from './DayItem'
import CalendarActionContentModal from 'components/CalendarActionContentModal'
import { useDispatch } from 'react-redux'
import { selectCalendarActionContent } from 'actions/calendarActions'

const MarketingCampaignCalendarView = ({ openScheduleAction, calendarActions }) => {
  const dispatch = useDispatch()
  const { year, month, handleNextMonth, handlePreviousMonth, handleClickToday } = useCalendar()

  const [calendarActionContent, setCalendarActionContent] = useState(false)

  const onOpenActionContent = (action) => {
    dispatch(selectCalendarActionContent(action))
    setCalendarActionContent(true)
  }

  return (
    <>
      <Calendar
        currentYear={year}
        currentMonth={month}
        handleNextMonth={handleNextMonth}
        handlePreviousMonth={handlePreviousMonth}
        handleTodayClick={handleClickToday}
        className={styles.calendarBase}
      >
        {(props) => (
          <DayItem
            {...props}
            year={year}
            month={month}
            openScheduleAction={openScheduleAction}
            calendarActions={calendarActions}
            onOpenActionContent={onOpenActionContent}
          />
        )}
      </Calendar>

      <CalendarActionContentModal open={calendarActionContent} setOpen={setCalendarActionContent} />
    </>
  )
}

export default MarketingCampaignCalendarView
