import { useState } from 'react'

const useFetch = (action) => {
  const [isLoading, setLoading] = useState(false)

  const fetchData = async (params) => {
    setLoading(true)
    let response

    try {
      if (typeof action === 'function') {
        response = await action(params)
      }
    } catch (error) {
      console.error(error)
    }

    setLoading(false)
    return response
  }

  return {
    fetchData,
    isLoading,
    setLoading,
  }
}

export default useFetch
