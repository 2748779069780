import useDriveSlice from 'hooks/useDriveSlice'
import useUsers from 'hooks/useUsers'
import { useCallback } from 'react'

export const useFilePermissions = (id) => {
  const { myUser } = useUsers()
  const { privateFiles, userPermissions } = useDriveSlice()

  const getFilePermissionProps = useCallback(
    (fileId) => {
      const checkIfIsPrivateFile = (file) => file.fileId === fileId
      const findPermissionByUser = (permission) => permission.fileId === fileId && permission.idUser === myUser.idUser
      const checkIfWriter = (permission) => (Boolean(permission) ? permission.type === 'writer' : false)
      const checkIfReader = (permission) => (Boolean(permission) ? permission.type === 'reader' : false)

      const isPrivateFile = Boolean(privateFiles?.find(checkIfIsPrivateFile))
      const isWriter = isPrivateFile && checkIfWriter(userPermissions.find(findPermissionByUser))
      const isReader = isPrivateFile && checkIfReader(userPermissions.find(findPermissionByUser))

      return { isPrivateFile, isWriter, isReader }
    },
    [privateFiles, userPermissions, myUser.idUser]
  )

  const { isPrivateFile, isWriter, isReader } = getFilePermissionProps(id)

  return { isPrivateFile, isWriter, isReader, getFilePermissionProps }
}
