import { IconButton, TextField, Tooltip } from '@material-ui/core'
import clsx from 'clsx'
import useLocale from 'hooks/useLocale'
import React, { useCallback, useImperativeHandle, useRef, useState } from 'react'
import styles from './FileInput.module.scss'

const FileInput = React.forwardRef(({ className, onChange, onClear, index, field, canWrite = true, disabled = false }, ref) => {
  const locale = useLocale()
  const [fileName, setFileName] = useState('')

  const inputRef = useRef()
  useImperativeHandle(ref, () => ({
    clearInput: () => {
      setFileName('')
      inputRef.current.value = ''
    },
  }))

  const handleOnChange = useCallback(
    (event) => {
      if (onChange) {
        onChange(event)
      }
      setFileName(event?.target?.files?.[0]?.name ?? '')
    },
    [onChange]
  )

  return (
    <div className={clsx([styles.wrapper, className])}>
      <label htmlFor={field?.name} className={styles.label}>
        <i className={field?.icon ? field?.icon : 'fas fa-image'} />
        {fileName ? fileName : field?.label}
      </label>
      {Boolean(fileName) && onClear && (
        <Tooltip arrow placement="top" title={locale === 'es' ? 'Limpiar' : 'Clean'}>
          <IconButton classes={{ root: styles.clearButton }} onClick={onClear}>
            <i className="fas fa-close" />
          </IconButton>
        </Tooltip>
      )}
      <TextField
        disabled={!canWrite || disabled}
        name={field?.name}
        type={'file'}
        accept={field?.accept ?? '*'}
        className={styles.fileInput}
        variant={'outlined'}
        required={field?.required ?? true}
        fullWidth
        onChange={handleOnChange}
        id={field?.name ?? `id-${index}`}
        label={field?.label}
        autoFocus={field?.isAutoFocus}
        InputLabelProps={{
          shrink: field?.shrinkLabel,
        }}
        inputProps={{ ...field, ref: inputRef }}
      />
    </div>
  )
})

export default FileInput
