import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { COUPONS_ROUTE } from 'constants/apiRoutes'
import { apiDeleteData, apiGetData, apiPostData, apiPutData } from 'helpers/api'

const initialState = {
  coupons: [],
  isLoadingCoupons: true,
  selectedCouponId: null,
  couponModal: false,
  couponPreviewer: false,
  creatingCoupon: false,
}

export const fetchCoupons = createAsyncThunk('GET_COUPONS', async () => await apiGetData(COUPONS_ROUTE, {}))
export const createCoupon = createAsyncThunk('CREATE_COUPON', async (coupon) => await apiPostData(COUPONS_ROUTE, coupon))
export const updateCoupon = createAsyncThunk(
  'UPDATE_COUPON',
  async (coupon) => await apiPutData(COUPONS_ROUTE, { ...coupon, id: coupon.idCoupon })
)
export const deleteCoupon = createAsyncThunk(
  'DELETE_COUPON',
  async (idCoupon) => await apiDeleteData(COUPONS_ROUTE, { id: idCoupon })
)
export const sendCouponEmail = async (builder) => await apiPostData(`${COUPONS_ROUTE}/send-coupon`, builder)

const couponSlice = createSlice({
  name: 'coupons',
  initialState,
  reducers: {
    selectCoupon: (state, { payload }) => {
      state.selectedCouponId = payload
    },
    toggleCouponModal: (state) => {
      state.couponModal = !state.couponModal
    },
    toggleCouponPreviewer: (state) => {
      state.couponPreviewer = !state.couponPreviewer
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCoupons.fulfilled, (state, action) => {
        state.coupons = action.payload
        state.isLoadingCoupons = false
      })
      .addCase(createCoupon.fulfilled, (state, { payload }) => {
        state.coupons = [payload, ...state.coupons]
        state.creatingCoupon = false
      })
      .addCase(updateCoupon.fulfilled, (state, { payload }) => {
        state.coupons = state.coupons.map((coupon) => (coupon.idCoupon === payload.idCoupon ? payload : coupon))
        state.creatingCoupon = false
      })
      .addCase(createCoupon.pending, (state) => {
        state.creatingCoupon = true
      })
      .addCase(updateCoupon.pending, (state) => {
        state.creatingCoupon = true
      })
      .addCase(deleteCoupon.fulfilled, (state, { payload }) => {
        state.coupons = state.coupons.filter((coupon) => coupon.idCoupon !== payload.idCoupon)
      })
  },
})

export const { selectCoupon, toggleCouponModal, toggleCouponPreviewer } = couponSlice.actions
export default couponSlice.reducer
