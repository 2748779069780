import { Popover } from '@material-ui/core'
import freeTimeTtypes from 'constants/freeTimeTypes'
import { isOnRange, isToday } from 'helpers/date'
import useLocale from 'hooks/useLocale'
import moment from 'moment-timezone'
import { nanoid } from 'nanoid'
import React, { useMemo, useRef, useState } from 'react'
import styles from './styles.module.scss'

const isBirthday = (date, compareTo) => {
  const dateToCompare = moment(compareTo)
  const year = dateToCompare?.year()
  const dateWithouthYear = moment(date)?.format('MM-DD')
  const final = moment(`${year}-${dateWithouthYear}`)

  return final?.isSame(dateToCompare, 'day')
}

const isSameDay = (date, compareTo) => {
  const date1 = moment(date)
  const date2 = moment(compareTo)

  return date1?.isSame(date2, 'day')
}

const VacationBadge = ({ label, icon, ...delegated }) => (
  <div className={`${styles.vacationLabel} ${styles.labelBase}`} {...delegated}>
    <i className={icon} />
    {label}
  </div>
)
const EventLabel = ({ label, icon, ...delegated }) => (
  <div className={`${styles.eventLabel} ${styles.labelBase}`} {...delegated}>
    <i className={icon} />
    {label}
  </div>
)
const BirthdayLabel = ({ label, icon, ...delegated }) => (
  <div className={`${styles.birthdayLabel} ${styles.labelBase}`} {...delegated}>
    <i className={icon} />
    {label}
  </div>
)

const renderFirstLabel = (item, locale, openDetails) => {
  if (item?.idEmployeeFreeTime) {
    return (
      <VacationBadge
        icon={getItemIcon(item.type)}
        label={freeTimeTtypes?.get(item.type)['text'][locale]}
        onClick={() => openDetails(item)}
      />
    )
  } else if (item?.idEvent) {
    return (
      <EventLabel
        icon={getItemIcon(item?.event?.type === 1 ? 6 : 7)}
        label={item?.event?.name}
        onClick={() => openDetails(item)}
      />
    )
  } else if (item?.type === 'birthday') {
    return (
      <BirthdayLabel
        icon={getItemIcon(item.type)}
        label={locale === 'es' ? 'Cumpleaños' : 'Birthday'}
        onClick={() => openDetails(item)}
      />
    )
  }
}

const getItemIcon = (type) => {
  switch (type) {
    case 1:
      return 'fa-solid fa-plane'
    case 2:
      return 'fa-solid fa-bacterium'
    case 3:
      return 'fa-solid fa-graduation-cap'
    case 4:
      return 'fa-solid fa-heart-crack'
    case 5:
      return 'fa-solid fa-question'
    case 6:
      return 'fa-solid fa-calendar'
    case 7:
      return 'fa-solid fa-umbrella-beach'
    case 8:
      return 'fa-solid fa-cake-candles'
    default:
      return
  }
}

const PopupItem = ({ record, openDetails }) => {
  const locale = useLocale()
  const type = useMemo(() => {
    if (record?.idEmployeeFreeTime) {
      return record?.type
    } else if (record?.idEvent) {
      return +record?.type === 1 ? 6 : 7
    } else if (record?.type === 'birthday') {
      return 8
    }
  }, [record])

  const label = useMemo(() => {
    if (record?.idEmployeeFreeTime) {
      return freeTimeTtypes.get(record.type)['text'][locale]
    } else if (record?.idEvent) {
      return record?.event?.name
    } else if (record?.type === 'birthday') {
      return locale === 'es' ? 'Cumpleaños' : 'Birthday'
    }
  }, [record, locale])

  const colorClass = useMemo(() => {
    if (record?.idEmployeeFreeTime) {
      return styles.vacation
    } else if (record?.idEvent) {
      return styles.event
    } else if (record?.type === 'birthday') {
      return styles.birthday
    }
  }, [record])

  return (
    <li className={`${styles.popupItem} ${colorClass}`} onClick={() => openDetails(record)}>
      <i className={getItemIcon(type)}></i>
      <span className={styles.label}>{label}</span>
    </li>
  )
}

const DayItem = ({ day, classNames, inactive, date, data, openDetails }) => {
  const locale = useLocale()
  const { day: dayClass, inactive: inactiveClass, today: todayClassName, dayText: dayTextClassName } = classNames
  const today = isToday(date)
  const ref = useRef(null)
  const [popup, setPopup] = useState(false)
  const isMobile = window.innerWidth < 768

  const dayRecords = useMemo(() => {
    return data?.filter((el) => {
      if (el?.idEmployeeFreeTime) {
        return isOnRange(date, [el?.startTime, el?.endTime, el?.totalDays])
      } else if (el?.idEvent) {
        return isSameDay(date, el?.event?.date)
      } else if (el?.type === 'birthday') {
        return isBirthday(date, el?.date)
      }

      return false
    })
  }, [date, data])

  const [firstRecord] = dayRecords ?? []

  const handleSeeMore = () => {
    setPopup(true)
  }

  return (
    <>
      <div
        className={`${dayClass} ${inactive ? inactiveClass : ''} ${today ? todayClassName : ''}`}
        onTouchStart={() => {
          if (dayRecords?.length) {
            handleSeeMore()
          }
        }}
        ref={ref}
      >
        <div className={dayTextClassName}>{day}</div>

        {firstRecord && renderFirstLabel(firstRecord, locale, openDetails)}
        {(dayRecords?.length >= 2 || !!(isMobile && dayRecords?.length)) && (
          <button onClick={handleSeeMore} className={styles.more}>
            <i className="fas fa-plus-circle" />
            {isMobile ? null : locale === 'es' ? 'Ver más' : 'More'}
          </button>
        )}
      </div>

      <Popover
        open={popup}
        anchorEl={ref.current}
        onClose={() => setPopup(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <ul className={styles.popupContainer}>
          {dayRecords?.slice(isMobile ? 0 : 1)?.map((record) => (
            <PopupItem record={record} key={nanoid()} openDetails={openDetails} />
          ))}
        </ul>
      </Popover>
    </>
  )
}

export default DayItem
