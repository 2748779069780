import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { isThisMonth } from 'date-fns'
import { formatDate, getCurrentMonth, getHoursWeekWorked, getHoursWorked, getMonthWeeks } from 'helpers/date'
import useLocale from 'hooks/useLocale'
import React from 'react'
import styles from './index.module.scss'

const MonthlyHours = ({ data }) => {
  const locale = useLocale()
  const weeks = getMonthWeeks()
  const filteredData = data?.filter((el) => {
    return isThisMonth(new Date(el.startTime))
  })

  const totalHours = () => {
    const time = getHoursWorked(filteredData)
    const [hours, minutes] = time ? time?.split(':') : []

    return !!(hours && minutes) ? `${hours}:${minutes} ${(locale === 'es' ? 'horas' : 'hours').toUpperCase()}` : 'N/A'
  }

  return (
    <main className={styles.monthlyMain}>
      <section className={styles.resumen}>
        <h4>{getCurrentMonth(locale)}</h4>
        <p>{totalHours()}</p>
      </section>
      <section className={styles.details}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell classes={{ root: styles.tableHeader }}>{locale === 'es' ? 'Fecha' : 'Date'}</TableCell>
                <TableCell classes={{ root: styles.tableHeader }}>{locale === 'es' ? 'Total' : 'Total'}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {weeks.map((w, i) => (
                <TableRow key={`week-${i}`}>
                  <TableCell classes={{ root: styles.date }}>{`${formatDate(w[0], 'D MMM YYYY')} - ${formatDate(
                    w[1],
                    'D MMM YYYY'
                  )}`}</TableCell>
                  <TableCell>{getHoursWeekWorked(w, filteredData) ?? 'N/A'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </section>
    </main>
  )
}

export default MonthlyHours
