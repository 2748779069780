import React, { useCallback } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import { ERROR_SNACKBAR } from 'constants/snackbarSeverities'
import usePrevious from 'hooks/usePrevious'
import { useDispatch } from 'react-redux'
import { resetSnackbarMessage } from 'actions/modals'

const AUTO_HIDE_DURATION_DEFAULT = 2200
const DEFAULT_MESSAGE = 'Ha ocurrido un error, por favor intentalo de nuevo.'

const SnackbarMessage = ({ open, setOpen, autoHideDuration, severity, message, anchorOrigin }) => {
  const dispatch = useDispatch()

  const handleCloseSnackbar = useCallback(
    (e, reason) => {
      if (reason !== 'clickaway') {
        dispatch(resetSnackbarMessage())
        setOpen(false)
      }
    },
    [setOpen, dispatch]
  )

  const prevMessage = usePrevious(message)
  const prevSeverity = usePrevious(severity)

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration || AUTO_HIDE_DURATION_DEFAULT}
      onClose={handleCloseSnackbar}
      anchorOrigin={anchorOrigin || { vertical: 'bottom', horizontal: 'center' }}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={handleCloseSnackbar}
        severity={severity || prevSeverity || ERROR_SNACKBAR}
      >
        {message || prevMessage || DEFAULT_MESSAGE}
      </MuiAlert>
    </Snackbar>
  )
}

export default SnackbarMessage
