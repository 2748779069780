import { ENV_SELECTED } from 'env'

const REPLACE_WORD = 'public'

const ENVS = {
  local: 'http://localhost:8000/storage',
  qa: 'https://qa-digital-pymes-service.ingeniust.com/storage',
  prod: 'https://digital-pymes-service.ingeniust.com/storage',
}

export const getApiStoreImageRoute = ({ imageUrl: imageServerRoute }) =>
  imageServerRoute?.replace(REPLACE_WORD, `${ENVS[ENV_SELECTED]}`)
