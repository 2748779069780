import {
  GET_CASH_FLOW,
  UPLOAD_CASH_FLOW,
  UPDATE_CASH_FLOW,
  DELETE_CASH_FLOW,
  UPLOAD_CASH_FLOW_INCOME,
  UPDATE_CASH_FLOW_INCOME,
  DELETE_CASH_FLOW_INCOME,
  UPLOAD_CASH_FLOW_EXPENSE,
  UPDATE_CASH_FLOW_EXPENSE,
  DELETE_CASH_FLOW_EXPENSE,
  UPDATE_CASH_FLOW_FILTER,
  UPDATE_CASH_FLOW_CURRENCY,
} from 'constants/actionTypes'

import { CASH_FLOW_EXPENSE_ROUTE, CASH_FLOW_INCOME_ROUTE, CASH_FLOW_ROUTE } from 'constants/apiRoutes'
import { apiFindData, apiPostData, apiPutData, apiDeleteData } from 'helpers/api'

export const getCashFlows = (id) => apiFindData(CASH_FLOW_ROUTE, { id }, GET_CASH_FLOW)
export const postCashFlow = (cashFlow) => apiPostData(CASH_FLOW_ROUTE, cashFlow, UPLOAD_CASH_FLOW)
export const updateCashFlow = (cashFlow) =>
  apiPutData(CASH_FLOW_ROUTE, { ...cashFlow, id: cashFlow?.idCashFlow }, UPDATE_CASH_FLOW)
export const deleteCashFlow = (id) => apiDeleteData(CASH_FLOW_ROUTE, { id }, DELETE_CASH_FLOW)

export const postCashFlowIncome = (income) => apiPostData(CASH_FLOW_INCOME_ROUTE, income, UPLOAD_CASH_FLOW_INCOME)
export const updateCashFlowIncome = (income) =>
  apiPutData(CASH_FLOW_INCOME_ROUTE, { ...income, id: income?.idCashFlowIncome }, UPDATE_CASH_FLOW_INCOME)
export const deleteCashFlowIncome = (id) => apiDeleteData(CASH_FLOW_INCOME_ROUTE, { id }, DELETE_CASH_FLOW_INCOME)

export const postCashFlowExpense = (expense) => apiPostData(CASH_FLOW_EXPENSE_ROUTE, expense, UPLOAD_CASH_FLOW_EXPENSE)
export const updateCashFlowExpense = (expense) =>
  apiPutData(CASH_FLOW_EXPENSE_ROUTE, { ...expense, id: expense?.idCashFlowExpense }, UPDATE_CASH_FLOW_EXPENSE)
export const deleteCashFlowExpense = (id) => apiDeleteData(CASH_FLOW_EXPENSE_ROUTE, { id }, DELETE_CASH_FLOW_EXPENSE)

export const updateCashFlowFilter = (obj) => ({ type: UPDATE_CASH_FLOW_FILTER, payload: obj })
export const updateCashFlowCurrency = (currency) => ({ type: UPDATE_CASH_FLOW_CURRENCY, payload: currency })
