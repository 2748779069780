import { Avatar, Button, TextField } from '@material-ui/core'
import { sendSuccessMessage } from 'actions/modals'
import Dialog from 'components/Dialog/Dialog'
import LoadingSection from 'components/LoadingSection'
import Select from 'components/common/Select/Select'
import useDriveSlice from 'hooks/useDriveSlice'
import useLocale from 'hooks/useLocale'
import useUsers from 'hooks/useUsers'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { addDrivePermission, makeDriveFilePrivate, makeDriveFilePublic, updateDrivePermission } from 'slices/driveSlice'
import { postDrivePermissions } from 'slices/driveSlice/api'
import styles from './PermissionsModal.module.scss'
import PeopleWithAccess from './components/PeopleWithAccess'

const FILE_OPTIONS = (locale) => [
  {
    value: 'PUBLIC',
    text: locale === 'es' ? 'Público' : 'Public',
  },
  {
    value: 'PRIVATE',
    text: locale === 'es' ? 'Privado' : 'Private',
  },
]

const PermissionsModal = ({ open, onClose, selected = {} }) => {
  const locale = useLocale()
  const dispatch = useDispatch()
  const { isLoadingPermissions, privateFiles, userPermissions } = useDriveSlice()

  const { companyUsers, myUser } = useUsers()
  const { company } = myUser ?? {}
  const { idCompany } = company ?? {}
  const { name, id: fileId } = selected

  const isPrivateFile = Boolean(privateFiles?.find((f) => f.fileId === fileId))

  const [access, setAccess] = useState(isPrivateFile ? 'PRIVATE' : 'PUBLIC')
  const [selectedUsers, setSelectedUsers] = useState([])
  const [filteredUsers, setFilteredUsers] = useState(companyUsers)

  const isPrivate = access === 'PRIVATE'
  const title = locale === 'es' ? `Configurar Permisos para "${name}"` : `Configure Permissions for "${name}"`

  const handleAccessChange = (event) => {
    const { value } = event.target
    const action = value === 'PUBLIC' ? makeDriveFilePublic : makeDriveFilePrivate

    const body = {
      fileId,
      idCompany,
    }

    dispatch(action(body))
    setAccess(value)
  }

  const handleUserSearch = (e) => {
    let { value } = e.target
    const checkProp = (prop) => prop.toLowerCase().includes(value.toLowerCase())
    let filtered = companyUsers
      .filter((u) => !selectedUsers.find((s_u) => s_u.idUser === u.idUser))
      .filter((user) => checkProp(user?.firstName) || checkProp(user?.lastName))

    setFilteredUsers(filtered)
  }

  const handleSelectUser = async (userToSelect) => {
    const checkUser = (user) => user.idUser === userToSelect.idUser

    const permissionBody = {
      fileId,
      idCompany,
      idUser: userToSelect.idUser,
      type: 'reader',
    }

    const newSelectedUsers = [...selectedUsers, { ...permissionBody, user: userToSelect }]
    const payload = { ...permissionBody, user: userToSelect }

    dispatch(addDrivePermission(payload))
    setSelectedUsers(newSelectedUsers)
    setFilteredUsers(newSelectedUsers.filter((user) => checkUser(user)))

    const resourceToAddId = await postDrivePermissions(payload)
    dispatch(updateDrivePermission(resourceToAddId))
  }

  useEffect(() => {
    if (!open) {
      setAccess('PUBLIC')
      setSelectedUsers([])
      setFilteredUsers(companyUsers)
    }
  }, [open, companyUsers])

  useEffect(() => {
    setAccess(isPrivateFile ? 'PRIVATE' : 'PUBLIC')

    if (isPrivateFile) {
      const currentPermissions = userPermissions.filter((p) => p.fileId === fileId)

      setSelectedUsers(currentPermissions)
      setFilteredUsers(companyUsers.filter((u) => !currentPermissions.find((p) => p.idUser === u.idUser)))
    }
  }, [isPrivateFile, userPermissions, fileId, companyUsers])

  return (
    <Dialog maxWidth="sm" open={open} handleCloseAction={onClose} title={title}>
      {isLoadingPermissions ? (
        <LoadingSection />
      ) : (
        <>
          <Select
            fullWidth
            label={locale === 'es' ? 'Acceso' : 'Access'}
            name="access"
            value={access}
            options={FILE_OPTIONS(locale)}
            onChange={handleAccessChange}
          />

          {isPrivate && (
            <div className={styles.userSearchWrapper}>
              <TextField
                onChange={handleUserSearch}
                fullWidth
                label={locale === 'es' ? 'Buscar usuario' : 'Search user'}
                variant="outlined"
              />

              <div className={styles.userResults}>
                {filteredUsers.map((user) => (
                  <div key={user?.idUser} className={styles.userResult} onClick={() => handleSelectUser(user)}>
                    <Avatar src={user?.avatarPhoto} />
                    <p>
                      {user.firstName} {user.lastName}
                    </p>
                  </div>
                ))}

                {!!!filteredUsers.length && (
                  <p className={styles.noUsers}>{locale === 'es' ? 'No hay resultados...' : 'No results...'}</p>
                )}
              </div>
            </div>
          )}

          {isPrivate ? (
            <PeopleWithAccess users={selectedUsers} fileId={fileId} />
          ) : (
            <div className={styles.warning}>
              {locale === 'es'
                ? 'Todas los usuarios tienen acceso a este archivo, tanto en Digital Pymes como en Google Drive.'
                : 'All users have access to this file, both in Digital Pymes and in Google Drive.'}
            </div>
          )}

          <footer>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                dispatch(sendSuccessMessage(locale === 'es' ? 'Cambios guardados' : 'Changes saved'))
                onClose()
              }}
            >
              {locale === 'es' ? 'Guardar Cambios' : 'Save Changes'}
            </Button>
          </footer>
        </>
      )}
    </Dialog>
  )
}

export default PermissionsModal
