import classNames from 'classnames'
import ButtonIcon from 'components/common/ButtonIcon'
import useLocale from 'hooks/useLocale'
import React from 'react'
import { useNavigate } from 'react-router'
import styles from './BackButton.module.scss'

const BackButton = ({ link, rootClassName, title, ...delegated }) => {
  const locale = useLocale()
  const navigate = useNavigate()

  const goBack = () => {
    navigate(link ?? -1)
  }
  return (
    <ButtonIcon
      color="primary"
      icon={<i className="fas fa-arrow-left" />}
      onClick={goBack}
      classes={{ root: classNames(styles.root, rootClassName) }}
      {...delegated}
    >
      {locale === 'es' ? 'Volver' : 'Go back'}
    </ButtonIcon>
  )
}

export default BackButton
