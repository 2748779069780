import {
  BOARDS_PAGE_URL,
  CALENDAR_PAGE_URL,
  CASH_FLOW_URL,
  CLIENT_FORMS,
  COMPANY_FORM_CONFIG,
  CONFIG_PAGE_URL,
  COOKIES_POLICY_PAGE_URL,
  COSTS_PAGE_TABS_URL,
  COSTS_PAGE_URL,
  COST_PAGE_TABS_URL,
  COST_PAGE_URL,
  CUSTOMERS_PAGE_TABS_URL,
  CUSTOMERS_PAGE_URL,
  DASHBOARD_PAGE_URL,
  FORM,
  FORM_TABS_URL,
  GOOGLE_DRIVE_PAGE,
  HELP_PAGE_URL,
  HISTORIC_PAGE_URL,
  HUMAN_RESOURCES_URL,
  IMAGES_BANK_URL,
  IMAGES_EDITOR_URL,
  INVENTORY_PAGE_URL,
  INVENTORY_TABS_URL,
  LOGIN_PAGE_URL,
  MARKETING_CAMPAIGNS,
  MESSAGES_PAGE_URL,
  MY_COMPANY_PAGE_TABS_URL,
  MY_COMPANY_PAGE_URL,
  MY_SUBSCRIPTION_PAGE_URL,
  PERSONAL_FINANCES_URL,
  POSTS_PAGE_URL,
  PRIVACY_POLICY_PAGE_URL,
  PROCESSES_PAGE,
  PROCESS_PAGE,
  PROFILE_PAGE_URL,
  PROJECTS_PAGE_URL,
  PROJECT_PAGE_URL,
  PRO_LANDING_PAGE_URL,
  PUBLIC_FORM,
  QUOTATIONS_URL,
  QUOTATION_URL,
  REPORT_ERRORS_PAGE_URL,
  REPORT_ROUTE,
  TOOLS_CONFIG_PAGE_URL,
  TOOLS_PAGE_URL,
  USERS_PAGE_URL,
  USE_POLICY_PAGE_URL,
  AI_BLOCKS_PAGE_URL,
  AI_TEXT_GENERATOR_PAGE_URL,
} from 'constants/urls'
import { lazy } from 'react'
// Componet imports
import AuthValidation from 'components/AuthValidation/AuthValidation'
import datanalisisRouter from 'constants/datanalisisRouter'
import { getEnvConfig } from 'helpers/env'
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout'
import PersonalFinancesLayout from 'layouts/PersonalFinancesLayout/PersonalFinancesLayout'
import EmployeePage from 'pages/EmployeePage'
import GoogleDrivePage from 'pages/GoogleDrivePage/GoogleDrivePage'
import Help from 'pages/Help'
import HelpLayout from 'pages/Help/HelpLayout'
import Tutorials from 'pages/Help/Tutorials'
import LoginPage from 'pages/LoginPage/LoginPage'
import MarketingCampaignPage from 'pages/MarketingCampaignPage'
import MySubscriptionPage from 'pages/MySubscriptionPage'
import CookiesPolicyPage from 'pages/Policies/CookiesPolicyPage'
import UsePolicyPage from 'pages/Policies/UsePolicyPage'
import ProLandingPage from 'pages/ProLandingPage'
import AiTextGeneratorPage from 'pages/AiTextGeneratorPage/AiTextGeneratorPage'
import { Navigate } from 'react-router'
import AiTextGeneratorLayout from 'layouts/AiTextGeneratorLayout/AiTextGeneratorLayout'
import AiTextGeneratorItemPage from 'pages/AiTextGeneratorPage/AiTextGeneratorItemPage/AiTextGeneratorItemPage'

// Lazy routes
const LazyWithPreload = (factory) => {
  const Component = lazy(
    () =>
      new Promise((resolve, reject) => {
        const hasRefreshed = JSON.parse(window.sessionStorage.getItem('retry-lazy-refreshed') || 'false')

        factory()
          .then((component) => {
            window.sessionStorage.setItem('retry-lazy-refreshed', 'false') // success so reset the refresh
            resolve(component)
          })
          .catch((error) => {
            if (!hasRefreshed) {
              // not been refreshed yet
              window.sessionStorage.setItem('retry-lazy-refreshed', 'true') // we are now going to refresh
              return window.location.reload() // refresh the page
            }

            console.error(error)
            reject(error) // there was an error
          })
      })
  )
  Component.preload = factory

  return Component
}

const CalendarPage = LazyWithPreload(() => import('pages/CalendarPage/CalendarPage'))
const CompanyInfoConfigPage = LazyWithPreload(() => import('pages/CompanyInfoConfigPage/CompanyInfoConfigPage'))
const ConfigPage = LazyWithPreload(() => import('pages/ConfigPage/ConfigPage'))
const HistoricPage = LazyWithPreload(() => import('pages/HistoricPage/HistoricPage'))
const HomePage = LazyWithPreload(() => import('pages/HomePage/HomePage'))
const CustomersPage = LazyWithPreload(() => import('pages/CustomersPage/CustomersPage'))
const MessagesPage = LazyWithPreload(() => import('pages/MessagesPage/MessagesPage'))
const MyCompanyPage = LazyWithPreload(() => import('pages/MyCompanyPage/MyCompanyPage'))
const PostsPage = LazyWithPreload(() => import('pages/PostsPage/PostsPage'))
const PrivacyPolicyPage = LazyWithPreload(() => import('pages/Policies/PrivacyPolicyPage'))
const ProfilePage = LazyWithPreload(() => import('pages/ProfilePage/ProfilePage'))
const ReportPage = LazyWithPreload(() => import('pages/ReportPage/ReportPage'))
const ProjectsPage = LazyWithPreload(() => import('pages/ProjectsPage/Projects'))
const ProjectPage = LazyWithPreload(() => import('pages/ProjectsPage/ProjectPage/ProjectPage'))
const ToolsConfigPage = LazyWithPreload(() => import('pages/ToolsConfigPage/ToolsConfigPage'))
const ToolsPage = LazyWithPreload(() => import('pages/ToolsPage/ToolsPage'))
const UsersPage = LazyWithPreload(() => import('pages/UsersPage/UsersPage'))
const FormsPage = LazyWithPreload(() => import('pages/FormsPage/FormsPage'))
const FormPage = LazyWithPreload(() => import('pages/FormPage/FormPage'))
const PublicForm = LazyWithPreload(() => import('pages/PublicForm/PublicForm'))
const CostsPage = LazyWithPreload(() => import('pages/CostsPage/CostsPage'))
const CostPage = LazyWithPreload(() => import('pages/CostPage/CostPage'))
const ProcessesPage = LazyWithPreload(() => import('components/ProcessesPage/ProcessesPage'))
const ProcessPage = LazyWithPreload(() => import('pages/ProcessPage/ProcessPage'))
const HumanResourcesPage = LazyWithPreload(() => import('pages/HumanResourcesPage/HumanResourcesPage'))
const BoardsPage = LazyWithPreload(() => import('pages/BoardsPage/BoardsPage'))
const BoardPage = LazyWithPreload(() => import('pages/BoardsPage/BoardPage/BoardPage'))
const InventoryPage = LazyWithPreload(() => import('pages/InventoryPage/InventoryPage'))
const ErrorPage = LazyWithPreload(() => import('pages/404/404'))
const ReportErrorsPage = LazyWithPreload(() => import('pages/ReportErrorsPage/ReportErrorsPage'))
const MarketingCampaignsPage = LazyWithPreload(() => import('pages/MarketingCampaignsPage'))
const ImageEditor = LazyWithPreload(() => import('pages/ImageEditor'))
const ImagesBankPage = LazyWithPreload(() => import('pages/ImagesBankPage'))
const CashFlowPage = LazyWithPreload(() => import('pages/CashFlowPage'))
const QuotationsPage = LazyWithPreload(() => import('pages/QuotationsPage'))
const QuotationPage = LazyWithPreload(() => import('pages/QuotationsPage/QuotationPage'))
const ResumePage = LazyWithPreload(() => import('pages/PersonalFinancesPage/views/ResumePage'))
const IncomesPage = LazyWithPreload(() => import('pages/PersonalFinancesPage/views/IncomesPage'))
const ExpensesPage = LazyWithPreload(() => import('pages/PersonalFinancesPage/views/ExpensesPage'))
const SavingsPage = LazyWithPreload(() => import('pages/PersonalFinancesPage/views/SavingsPage'))
const CreditsPage = LazyWithPreload(() => import('pages/PersonalFinancesPage/views/CreditsPage'))
const CardsPage = LazyWithPreload(() => import('pages/PersonalFinancesPage/views/CardsPage'))
const AiBlocksPage = LazyWithPreload(() => import('pages/AiBlocksPage/AiBlocksPage'))

const hideProSubscriptionModule = getEnvConfig('REACT_APP_HIDE_SUBSCRIPTION_MODULE', false)

const routes = [
  { path: LOGIN_PAGE_URL, element: <LoginPage /> },
  { path: PRO_LANDING_PAGE_URL, element: hideProSubscriptionModule ? <Navigate to="/dashboard" /> : <ProLandingPage /> },
  {
    path: HELP_PAGE_URL,
    element: <HelpLayout />,
    children: [
      { index: true, element: <Help /> },
      { path: ':slug', element: <Tutorials /> },
    ],
  },
  {
    path: '/dashboard',
    element: <DashboardLayout />,
    children: [
      { index: true, element: <HomePage />, preloadComponent: () => HomePage.preload() },
      {
        path: CALENDAR_PAGE_URL,
        element: <CalendarPage />,
        preloadComponent: () => CalendarPage.preload(),
      },
      {
        path: CUSTOMERS_PAGE_URL,
        element: <Navigate to="/dashboard/customers/customers" replace />,
        preloadComponent: () => CustomersPage.preload(),
      },
      { path: CUSTOMERS_PAGE_TABS_URL, element: <CustomersPage />, preloadComponent: () => CustomersPage.preload() },
      { path: POSTS_PAGE_URL, element: <PostsPage />, preloadComponent: () => PostsPage.preload() },
      { path: HISTORIC_PAGE_URL, element: <HistoricPage />, preloadComponent: () => HistoricPage.preload() },
      {
        path: MESSAGES_PAGE_URL,
        element: <MessagesPage />,
        preloadComponent: () => MessagesPage.preload(),
      },
      { path: CONFIG_PAGE_URL, element: <ConfigPage />, preloadComponent: () => ConfigPage.preload() },
      { path: PROFILE_PAGE_URL, element: <ProfilePage />, preloadComponent: () => ProfilePage.preload() },
      { path: TOOLS_PAGE_URL, element: <ToolsPage />, preloadComponent: () => ToolsPage.preload() },
      {
        path: USERS_PAGE_URL,
        element: <UsersPage />,
        preloadComponent: () => UsersPage.preload(),
      },
      { path: TOOLS_CONFIG_PAGE_URL, element: <ToolsConfigPage />, preloadComponent: () => ToolsConfigPage.preload() },
      { path: COMPANY_FORM_CONFIG, element: <CompanyInfoConfigPage />, preloadComponent: () => CompanyInfoConfigPage.preload() },
      {
        path: MY_COMPANY_PAGE_URL,
        element: <Navigate to="dashboard/my-company/my-company" replace />,
        preloadComponent: () => MyCompanyPage.preload(),
      },
      {
        path: MY_SUBSCRIPTION_PAGE_URL,
        element: <MySubscriptionPage />,
      },
      { path: MY_COMPANY_PAGE_TABS_URL, element: <MyCompanyPage />, preloadComponent: () => MyCompanyPage.preload() },
      { path: PROJECTS_PAGE_URL, element: <ProjectsPage />, preloadComponent: () => ProjectsPage.preload() },
      { path: PROJECT_PAGE_URL, element: <ProjectPage />, preloadComponent: () => ProjectPage.preload() },
      { path: CLIENT_FORMS, element: <FormsPage />, preloadComponent: () => FormsPage.preload() },
      {
        path: FORM,
        element: <Navigate to="dashboard/social-media/forms/:id/:tab" />,
        preloadComponent: () => FormPage.preload(),
      },
      { path: FORM_TABS_URL, element: <FormPage />, preloadComponent: () => FormPage.preload() },
      {
        path: IMAGES_BANK_URL,
        element: <ImagesBankPage />,
        preloadComponent: () => ImagesBankPage.preload(),
      },
      {
        path: COSTS_PAGE_URL,
        element: <Navigate to="dashobard/costs/products" />,
        preloadComponent: () => CostsPage.preload(),
      },
      {
        path: COSTS_PAGE_TABS_URL,
        element: <CostsPage />,
        preloadComponent: () => CostsPage.preload(),
      },
      {
        path: CASH_FLOW_URL,
        element: <CashFlowPage />,
        preloadComponent: () => CashFlowPage.preload(),
      },
      {
        path: QUOTATIONS_URL,
        element: <QuotationsPage />,
        preloadComponent: () => QuotationsPage.preload(),
      },
      {
        path: QUOTATION_URL,
        element: <QuotationPage />,
        preloadComponent: () => QuotationPage.preload(),
      },
      {
        path: COST_PAGE_URL,
        element: <Navigate to="dashboard/costs/cost/:id/workforce" replace />,
        preloadComponent: () => CostPage.preload(),
      },
      {
        path: GOOGLE_DRIVE_PAGE,
        children: [
          { index: true, element: <GoogleDrivePage /> },
          { path: `folders/:folderId`, element: <GoogleDrivePage /> },
          { path: `shared`, element: <GoogleDrivePage /> },
        ],
      },
      { path: COST_PAGE_TABS_URL, element: <CostPage />, preloadComponent: () => CostPage.preload() },
      { path: PROCESSES_PAGE, element: <ProcessesPage />, preloadComponent: () => ProcessesPage.preload() },
      {
        path: HUMAN_RESOURCES_URL,
        preloadComponent: () => HumanResourcesPage.preload(),
        children: [
          {
            index: true,
            element: <Navigate to="/dashboard/human-resources/departments" replace />,
          },
          {
            path: ':tab',
            element: <HumanResourcesPage />,
          },
          {
            path: ':tab/:subTab',
            element: <HumanResourcesPage />,
          },
          {
            path: 'employees/:id',
            element: <EmployeePage />,
          },
        ],
      },
      {
        path: BOARDS_PAGE_URL,
        preloadComponent: () => BoardsPage.preload(),
        children: [
          { index: true, element: <BoardsPage /> },
          { path: ':id', element: <Navigate to="board" replace /> },
          { path: ':id/:tab', element: <BoardPage /> },
        ],
      },
      { path: INVENTORY_PAGE_URL, element: <InventoryPage />, preloadComponent: () => InventoryPage.preload() },
      { path: INVENTORY_TABS_URL, element: <InventoryPage />, preloadComponent: () => InventoryPage.preload() },
      {
        path: MARKETING_CAMPAIGNS,
        children: [
          { index: true, element: <MarketingCampaignsPage /> },
          { path: ':id', element: <MarketingCampaignPage /> },
        ],
        preloadComponent: () => MarketingCampaignsPage.preload(),
      },
      {
        path: REPORT_ERRORS_PAGE_URL,
        element: <ReportErrorsPage />,
        preloadComponent: () => ReportErrorsPage.preload(),
      },
      {
        path: AI_BLOCKS_PAGE_URL,
        element: <AiBlocksPage />,
        preloadComponent: () => AiBlocksPage.preload(),
      },
    ],
  },
  {
    path: PERSONAL_FINANCES_URL,
    element: <PersonalFinancesLayout />,
    children: [
      {
        path: ``,
        element: <Navigate to="/finances/personal/resume" replace />,
        preloadComponent: () => HomePage.preload(),
      },

      {
        path: `resume`,
        element: <ResumePage />,
        preloadComponent: () => ResumePage.preload(),
      },

      {
        path: `incomes`,
        element: <IncomesPage />,
        preloadComponent: () => IncomesPage.preload(),
      },

      {
        path: `expenses`,
        element: <ExpensesPage />,
        preloadComponent: () => ExpensesPage.preload(),
      },

      {
        path: `savings`,
        element: <SavingsPage />,
        preloadComponent: () => SavingsPage.preload(),
      },

      {
        path: `credits`,
        element: <CreditsPage />,
        preloadComponent: () => CreditsPage.preload(),
      },

      {
        path: `cards`,
        element: <CardsPage />,
        preloadComponent: () => CardsPage.preload(),
      },
    ],
  },
  {
    path: REPORT_ROUTE,
    element: (
      <AuthValidation>
        <ReportPage />
      </AuthValidation>
    ),
  },
  {
    path: AI_TEXT_GENERATOR_PAGE_URL,
    element: <AiTextGeneratorLayout />,
    children: [
      { index: true, element: <AiTextGeneratorPage />, preloadComponent: () => AiTextGeneratorPage.preload() },
      { path: ':id', element: <AiTextGeneratorItemPage /> },
    ],
  },
  { path: PROCESS_PAGE, element: <ProcessPage /> },
  { path: IMAGES_EDITOR_URL, element: <ImageEditor />, preloadComponent: () => ImageEditor.preload() },
  { path: PUBLIC_FORM, element: <PublicForm /> },
  {
    path: PRIVACY_POLICY_PAGE_URL,
    element: <PrivacyPolicyPage />,
  },
  {
    path: USE_POLICY_PAGE_URL,
    element: <UsePolicyPage />,
  },
  {
    path: COOKIES_POLICY_PAGE_URL,
    element: <CookiesPolicyPage />,
  },
  datanalisisRouter,
  { path: '*', element: <ErrorPage /> },
]

const flatRoutes = () => {
  const getElements = (arr) =>
    arr.reduce((acc, el) => {
      acc.push(el)
      return acc
    }, [])

  const flatRoutes = routes
    .map((el) => {
      if (el.children) {
        return getElements(el.children)
      }

      return el
    })
    .flat()
    .filter((el) => el.path !== '/')

  return flatRoutes
}

export const flattenRoutes = flatRoutes()

export const getProps = (arr) =>
  arr.map((element) => {
    const { preloadComponent = null } =
      flattenRoutes.find((route) => element.link === `/${DASHBOARD_PAGE_URL}/${route.path}`) ?? {}
    return {
      ...element,
      preloadComponent,
    }
  })

export default routes
