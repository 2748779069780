const customMuiClasses = {
  root: {
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },

    '& .MuiDataGrid-columnHeader': {
      padding: '0 10px',
    },
  },
}

export { customMuiClasses }
