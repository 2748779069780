import { useDispatch } from 'react-redux'
import Dialog from 'components/Dialog/Dialog'
import useModals from 'hooks/useModals'
import { previousPageStepperModal } from 'actions/modals'
import styles from './stepperModal.module.scss'

const StepperModal = ({ open, pages, handleClose, stepperModalConfig }) => {
  const dispatch = useDispatch()
  const { title } = stepperModalConfig

  const { stepperModalCurrentPage } = useModals()

  const handlePreviousPage = () => {
    dispatch(previousPageStepperModal())
  }

  return (
    <Dialog
      open={open}
      handleCloseAction={handleClose}
      handlePreviousPage={handlePreviousPage}
      stepperModalCurrentPage={stepperModalCurrentPage}
      title={title}
      maxWidth="sm"
    >
      <div className={styles.pagesContainer}>{pages.filter((_, index) => index === stepperModalCurrentPage)}</div>
      <div className={styles.stepperCounter}>
        {pages.map((_, index) =>
          index === stepperModalCurrentPage ? (
            <span key={index} className={`${styles.stepperActiveDot} ${styles.stepperDot}`}></span>
          ) : (
            <span key={index} className={styles.stepperDot} />
          )
        )}
      </div>
    </Dialog>
  )
}

export default StepperModal
