import { DELETE_TOOL, GET_TOOLS, SET_NEW_TOOL, UPDATE_TOOL } from 'constants/actionTypes'

const initialState = {
  tools: [],
  isLoadingTools: true,
  errorInRequest: false,
}

const handlers = {
  [GET_TOOLS]: (state, { payload = {} }) => {
    if (!payload?.tools) return { ...state, isLoadingTools: false, errorInRequest: true }

    return {
      ...state,
      tools: payload?.tools?.reverse(),
      isLoadingTools: false,
      errorInRequest: false,
    }
  },
  [SET_NEW_TOOL]: (state, { payload = {} }) => {
    if (!payload?.tool) return { ...state, errorInRequest: true }

    return {
      ...state,
      tools: [payload?.tool, ...state?.tools],
      errorInRequest: false,
    }
  },
  [UPDATE_TOOL]: (state, { payload = {} }) => {
    if (!payload?.tool) return { ...state, errorInRequest: true }

    return {
      ...state,
      tools: state?.tools?.map((tool) => (tool?.idTool === payload?.tool?.idTool ? payload?.tool : tool)),
      errorInRequest: false,
    }
  },
  [DELETE_TOOL]: (state, { payload = {} }) => {
    if (!payload?.tool) return { ...state, errorInRequest: true }

    return {
      ...state,
      tools: state?.tools?.filter(({ idTool }) => idTool !== payload?.tool?.idTool) ?? [],
      errorInRequest: false,
    }
  },
}

const tools = (state = initialState, action) => {
  const { type } = action

  return handlers[type] ? handlers[type](state, action) : state
}

export default tools
