import React from 'react'
import Calendar from './Calendar'
import Requests from './Requests'

const FreeTime = ({ employee }) => {
  return (
    <>
      <Requests employee={employee} />
      <Calendar employee={employee} />
    </>
  )
}

export default FreeTime
