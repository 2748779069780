export const addCookie = (name, value, expires) =>
  (document.cookie = `${name}=${value}${expires ? `; Path=/; expires=${expires}` : ''}`)

export const readCookie = (name) =>
  decodeURIComponent(
    document.cookie.replace(
      // eslint-disable-next-line no-useless-escape
      new RegExp('(?:(?:^|.*;)\\s*' + name.replace(/[\-\.\+\*]/g, '\\$&') + '\\s*\\=\\s*([^;]*).*$)|^.*$'),
      '$1'
    )
  ) || null

export const deleteCookie = (name) => (document.cookie = `${name}=''; Path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC`)
