import {
  GET_COMPANY_PROJECTS,
  UPLOAD_PROJECT,
  UPDATE_PROJECT,
  DELETE_PROJECT,
  GET_MOVEMENTS,
  UPLOAD_MOVEMENT,
  UPDATE_MOVEMENT,
  DELETE_MOVEMENT,
  LOADING_MOVEMENTS,
} from 'constants/actionTypes'

const initialState = {
  isLoadingProjects: true,
  isLoadingMovements: true,
  errorInRequest: false,
  projects: [],
  projectMovements: [],
}

const handlers = {
  [GET_COMPANY_PROJECTS]: (state, { payload = {} }) => {
    if (!payload?.projects) return { ...state, errorInRequest: true, isLoadingProjects: false }

    return { ...state, projects: payload?.projects, errorInRequest: false, isLoadingProjects: false }
  },
  [UPLOAD_PROJECT]: (state, { payload = {} }) => {
    if (!payload?.project) return { ...state, errorInRequest: true }

    return { ...state, projects: state.projects.concat(payload?.project), errorInRequest: false }
  },
  [UPDATE_PROJECT]: (state, { payload = {} }) => {
    if (!payload?.project) return { ...state, errorInRequest: true }

    return {
      ...state,
      projects: state.projects.map((project) => (project.idProject === payload?.project?.idProject ? payload?.project : project)),
      errorInRequest: false,
    }
  },
  [DELETE_PROJECT]: (state, { payload = {} }) => {
    if (!payload?.project) return { ...state, errorInRequest: true }

    return {
      ...state,
      projects: state.projects.filter(({ idProject }) => idProject !== payload?.project?.idProject),
      errorInRequest: false,
    }
  },
  [LOADING_MOVEMENTS]: (state, { payload = {} }) => ({ ...state, ...payload }),
  [GET_MOVEMENTS]: (state, { payload = {} }) => {
    if (!payload?.movements) return { ...state, errorInRequest: true, isLoadingMovements: false }

    return { ...state, projectMovements: payload?.movements, errorInRequest: false, isLoadingMovements: false }
  },
  [UPLOAD_MOVEMENT]: (state, { payload = {} }) => {
    if (!payload?.movement) return { ...state, errorInRequest: true }

    return { ...state, projectMovements: [payload?.movement, ...state.projectMovements], errorInRequest: false }
  },
  [UPDATE_MOVEMENT]: (state, { payload = {} }) => {
    if (!payload?.movement) return { ...state, errorInRequest: true }

    return {
      ...state,
      projectMovements: state.projectMovements.map((movement) =>
        movement.idMovement === payload?.movement?.idMovement ? payload?.movement : movement
      ),
      errorInRequest: false,
    }
  },
  [DELETE_MOVEMENT]: (state, { payload = {} }) => {
    if (!payload?.movement) return { ...state, errorInRequest: true }

    return {
      ...state,
      projectMovements: state.projectMovements.filter(({ idMovement }) => idMovement !== payload?.movement?.idMovement),
      errorInRequest: false,
    }
  },
}

const projects = (state = initialState, action) => {
  const { type } = action

  return handlers[type] ? handlers[type](state, action) : state
}

export default projects
