import React, { useRef, useState } from 'react'
import styles from './ChangeStatus.module.scss'
import { CircularProgress, Popover } from '@material-ui/core'
import Tooltip from 'components/common/Tooltip/Tooltip'
import useLocale from 'hooks/useLocale'
import { useDispatch } from 'react-redux'
import { updateStub } from 'actions/humanResources'

const PopupItem = ({ label, checked = false, handleClick }) => (
  <div className={styles.popupItem} onClick={handleClick}>
    {checked && <i className="fas fa-check" />}
    {label}
  </div>
)

const ChangeStatus = ({ data = {} }) => {
  const locale = useLocale()
  const dispatch = useDispatch()
  const ref = useRef()

  const { IS_PENDING } = data ?? {}

  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleOnClick = () => {
    setOpen(true)
  }

  const handleSelectStatus = async (status) => {
    setOpen(false)
    setLoading(true)
    dispatch(await updateStub({ ...data, IS_PENDING: status }))
    setLoading(false)
  }

  return (
    <div ref={ref} className={styles.container}>
      {loading ? (
        <CircularProgress size="1.5rem" />
      ) : (
        <>
          <Tooltip
            title={locale === 'es' ? 'Cambiar estado' : 'Change status'}
            icon="fas fa-edit"
            handleOnClick={handleOnClick}
          />
          <Popover
            open={open}
            onClose={() => setOpen(false)}
            anchorEl={ref.current}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
          >
            <PopupItem
              checked={IS_PENDING === 0}
              label={locale === 'es' ? 'Aprobado' : 'Approved'}
              handleClick={() => handleSelectStatus(0)}
            />
            <PopupItem
              checked={IS_PENDING === 1}
              label={locale === 'es' ? 'Pendiente' : 'Pending'}
              handleClick={() => handleSelectStatus(1)}
            />
          </Popover>
        </>
      )}
    </div>
  )
}

export default ChangeStatus
