import {
  CREATE_USER_EMPLOYEE_WORK_DAY,
  CREATE_USER_EMPLOYEE_WORK_DAY_BREAK,
  DELETE_COMPANY_USER,
  DELETE_SUBSCRIPTION,
  DELETE_USER,
  DELETE_USER_ASSET,
  GET_COMPANY_USERS,
  GET_MY_USER,
  GET_USERS,
  SET_NEW_COMPANY_USER,
  SET_NEW_USER,
  SET_NEW_USER_COMPANY,
  SUBSCRIBE_TO_PRO,
  UPDATE_COMPANY_USER,
  UPDATE_MY_COMPANY_LOGO,
  UPDATE_MY_USER,
  UPDATE_SUBSCRIPTION,
  UPDATE_USER,
  UPDATE_USER_AVATAR,
  UPDATE_USER_EMPLOYEE_WORK_DAY,
  UPDATE_USER_EMPLOYEE_WORK_DAY_BREAK,
  UPLOAD_USER_FREE_TIME,
} from 'constants/actionTypes'
import {
  COMPANIES_API_ROUTE,
  COMPANY_USERS_API_ROUTE,
  DELETE_IMAGE_ROUTE,
  FREE_TIME_ROUTE,
  ROLS_API_ROUTE,
  STORE_IMAGE_API_ROUTE,
  SUBSCRIPTIONS_ROUTE,
  USERS_API_ROUTE,
  WORK_DAY_BREAKS_ROUTE,
  WORK_DAY_ROUTE,
} from 'constants/apiRoutes'
import { UID_COOKIE } from 'constants/cookies'
import {
  apiDeleteData,
  apiFindData,
  apiGetData,
  apiPostData,
  apiPostFormDataWithData,
  apiPostImage,
  apiPutData,
} from 'helpers/api'
import { getApiStoreImageRoute } from 'helpers/apiStoreImages'
import Cookies from 'js-cookie'
import { createFirebaseUser } from './firebaseAuth'

export const getMyUser = (uid) => apiFindData(USERS_API_ROUTE, { id: uid }, GET_MY_USER)

export const updateMyUser = (myUser) => apiPutData(USERS_API_ROUTE, { id: myUser?.idUser, ...myUser }, UPDATE_MY_USER)

export const getUsers = () => apiGetData(USERS_API_ROUTE, {}, GET_USERS)

export const newCompanyUser = async (companyUser) => {
  try {
    const { email, password } = companyUser || {}
    const uid = await createFirebaseUser(email, password)
    const user = await apiPostData(USERS_API_ROUTE, { ...companyUser, uid }, SET_NEW_USER)
    const company = await apiPostData(
      COMPANIES_API_ROUTE,
      { ...companyUser, ownerIdUser: user.payload.user.idUser },
      SET_NEW_USER_COMPANY
    )

    const companyUserPayload = {
      payload: {
        user: {
          ...user.payload.user,
          company: {
            ...company.payload.company,
          },
        },
      },
      type: SET_NEW_USER_COMPANY,
    }

    return companyUserPayload
  } catch (error) {
    return { type: SET_NEW_USER_COMPANY, payload: { error: true, details: error } }
  }
}

export const createUserWithCompany = async (companyUser) => {
  try {
    const { email, password } = companyUser || {}
    const uid = await createFirebaseUser(email, password)

    if (uid?.error) return uid

    const user = await apiPostData(USERS_API_ROUTE, { ...companyUser, uid }, SET_NEW_USER)
    const company = await apiPostData(
      COMPANIES_API_ROUTE,
      { ...companyUser, email: companyUser?.companyEmail ?? companyUser?.email, ownerIdUser: user.payload.user.idUser },
      SET_NEW_USER_COMPANY
    )

    const companyUserPayload = {
      payload: {
        user: {
          ...user.payload.user,
          company: {
            ...company.payload.company,
          },
        },
      },
      type: SET_NEW_USER_COMPANY,
    }

    return companyUserPayload
  } catch (error) {
    return { type: SET_NEW_USER_COMPANY, payload: { error: true, details: error } }
  }
}

export const createUserForCompany = async (userCompany) => {
  const { email, password } = userCompany || {}
  const uid = await createFirebaseUser(email, password)

  if (uid?.error) return uid

  const user = await apiPostData(USERS_API_ROUTE, { ...userCompany, uid }, SET_NEW_COMPANY_USER)

  await apiPostData(ROLS_API_ROUTE, { idCompany: userCompany.idCompany, idUser: user.payload.user.idUser }, SET_NEW_COMPANY_USER)

  const companyUserPayload = {
    payload: {
      user: user.payload.user,
    },
    type: SET_NEW_COMPANY_USER,
  }

  return companyUserPayload
}

export const updateUserWithCompany = async (companyUser) => {
  const userU = await apiPutData(USERS_API_ROUTE, { id: companyUser?.idUser, ...companyUser }, UPDATE_USER)
  const companyU = await apiPutData(
    COMPANIES_API_ROUTE,
    {
      id: companyUser?.idCompany,
      ...companyUser,
      ownerIdUser: companyUser?.idUser,
      email: companyUser?.companyEmail ?? companyUser?.email,
    },
    UPDATE_USER
  )

  const companyUserPayload = {
    payload: {
      user: {
        ...userU.payload.user,
        company: {
          ...companyU.payload.company,
        },
      },
    },
    type: UPDATE_USER,
  }

  return companyUserPayload
}

export const registerUserWithCompany = async (newCompanyUser) => {
  const { step1: companyInfo, step2: userInfo, password } = newCompanyUser
  const { country, province, canton, district, companyName, companyPhone, companyEmail } = companyInfo
  const { userFirstName, userLastName, userEmail, userPhone } = userInfo

  const firebaseUserRes = await createFirebaseUser(userEmail, password)

  if (firebaseUserRes?.error) return { error: true, errorMessageType: firebaseUserRes.code }

  const locationInfo = { country, province, canton, district }

  const userRes = await apiPostData(USERS_API_ROUTE, {
    uid: firebaseUserRes,
    idRole: 1,
    type: 2,
    firstName: userFirstName,
    lastName: userLastName,
    email: userEmail,
    telefono: userPhone,
    ...locationInfo,
  })

  if (userRes?.error) return { error: true, errorMessageType: 'default' }

  const companyRes = await apiPostData(COMPANIES_API_ROUTE, {
    ownerIdUser: userRes.user.idUser,
    name: companyName,
    telefono: companyPhone,
    email: companyEmail,
    ...locationInfo,
  })

  if (companyRes?.error) return { error: true, errorMessageType: 'default' }

  Cookies.set(UID_COOKIE, firebaseUserRes)

  return { error: false }
}

export const updateCompanyUser = async (companyUser) => {
  const userU = await apiPutData(USERS_API_ROUTE, { id: companyUser?.idUser, ...companyUser }, UPDATE_USER)
  const companyU = await apiPutData(
    COMPANIES_API_ROUTE,
    { id: companyUser?.idCompany, ...companyUser, ownerIdUser: companyUser?.idUser },
    UPDATE_USER
  )

  const companyUserPayload = {
    payload: {
      user: {
        ...userU.payload.user,
        company: {
          ...companyU.payload.company,
        },
      },
    },
    type: UPDATE_USER,
  }

  return companyUserPayload
}

export const getCompanyUsers = (idCompany) => apiFindData(COMPANY_USERS_API_ROUTE, { id: idCompany }, GET_COMPANY_USERS)

export const newUserByCompany = async (userCompany) => {
  const { email, password } = userCompany || {}
  const uid = await createFirebaseUser(email, password)

  if (uid.error) {
    return {
      payload: {
        error: true,
      },
    }
  }

  const user = await apiPostData(USERS_API_ROUTE, { ...userCompany, uid }, SET_NEW_COMPANY_USER)

  await apiPostData(ROLS_API_ROUTE, { idCompany: userCompany.idCompany, idUser: user.payload.user.idUser }, SET_NEW_COMPANY_USER)

  const companyUserPayload = {
    payload: {
      user: user.payload.user,
    },
    type: SET_NEW_COMPANY_USER,
  }
  return companyUserPayload
}

export const updateUserByCompany = async (userCompany) => {
  const userU = await apiPutData(USERS_API_ROUTE, { id: userCompany?.idUser, ...userCompany }, UPDATE_COMPANY_USER)

  const companyUserPayload = {
    payload: {
      user: userU.payload.user,
    },
    type: UPDATE_COMPANY_USER,
  }

  return companyUserPayload
}

export const deleteUser = (idUser) => apiDeleteData(USERS_API_ROUTE, { id: idUser }, DELETE_USER)

export const deleteCompanyUser = (idUser) =>
  apiDeleteData(USERS_API_ROUTE, { id: idUser, config: { data: { deleteCompany: true } } }, DELETE_COMPANY_USER)

export const updateCompany = (company) => apiPutData(COMPANIES_API_ROUTE, { id: company?.idCompany, ...company }, UPDATE_MY_USER)

export const deleteProfileAsset = async (body) => apiPostData(DELETE_IMAGE_ROUTE, body, DELETE_USER_ASSET)

export const uploadProfileAvatar = async ({ args, image }) => {
  const serverImageUrl = await apiPostImage(STORE_IMAGE_API_ROUTE, { image })

  if (serverImageUrl?.payload?.error)
    return {
      type: UPDATE_USER_AVATAR,
      payload: {
        error: true,
      },
    }

  const imageRoute = getApiStoreImageRoute(serverImageUrl)

  const { myUser } = args
  const { idUser } = myUser

  return apiPutData(USERS_API_ROUTE, { id: idUser, ...myUser, avatarPhoto: imageRoute }, UPDATE_USER_AVATAR)
}

export const uploadCompanyLogo = async ({ args, image }) => {
  const serverImageUrl = await apiPostImage(STORE_IMAGE_API_ROUTE, { image })

  if (serverImageUrl?.payload?.error)
    return {
      type: UPDATE_MY_COMPANY_LOGO,
      payload: {
        error: true,
      },
    }

  const imageRoute = getApiStoreImageRoute(serverImageUrl)

  const { company } = args

  return apiPutData(COMPANIES_API_ROUTE, { id: company?.idCompany, ...company, companyLogo: imageRoute }, UPDATE_MY_COMPANY_LOGO)
}

export const createUserEmployeeWorkDay = async (workDay) => apiPostData(WORK_DAY_ROUTE, workDay, CREATE_USER_EMPLOYEE_WORK_DAY)

export const updateUserEmployeeWorkDay = async (workDay) =>
  apiPutData(WORK_DAY_ROUTE, { ...workDay, id: workDay?.idEmployeeWorkDay }, UPDATE_USER_EMPLOYEE_WORK_DAY)

export const createUserEmployeeWorkDayBreak = async (workDayBreak) =>
  apiPostData(WORK_DAY_BREAKS_ROUTE, workDayBreak, CREATE_USER_EMPLOYEE_WORK_DAY_BREAK)

export const updateUserEmployeeWorkDayBreak = async (workDayBreak) =>
  apiPutData(WORK_DAY_BREAKS_ROUTE, { ...workDayBreak, id: workDayBreak?.idWorkDayBreak }, UPDATE_USER_EMPLOYEE_WORK_DAY_BREAK)

export const createFreeTime = (freeTime) => apiPostFormDataWithData(FREE_TIME_ROUTE, freeTime, UPLOAD_USER_FREE_TIME)

// SUBSCRIPTIONS ACTIONS
export const subscribeToPro = (subscription) => apiPostData(SUBSCRIPTIONS_ROUTE, subscription, SUBSCRIBE_TO_PRO)

export const updateSubscription = (subscription) => apiPutData(SUBSCRIPTIONS_ROUTE, subscription, UPDATE_SUBSCRIPTION)

export const deleteSubscription = (idSubscription) =>
  apiDeleteData(SUBSCRIPTIONS_ROUTE, { id: idSubscription }, DELETE_SUBSCRIPTION)
