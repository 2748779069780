import { DialogContent, IconButton } from '@material-ui/core'
import CoreDialog from '@material-ui/core/Dialog'
import { closeModal, closeStepperModal, confirmDialog } from 'actions/modals'
import clsx from 'clsx'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import styles from './dialog.module.scss'

const Dialog = ({
  open,
  setOpen,
  title,
  children,
  rootClassName,
  maxWidth,
  closeButtonStyles,
  fixedWidth,
  handleCloseAction,
  handlePreviousPage,
  stepperModalCurrentPage,
  className,
  disableEnforceFocus,
  paperClass,
}) => {
  const dispatch = useDispatch()
  const handleClose = useCallback(() => {
    if (handleCloseAction) return handleCloseAction()

    if (setOpen) {
      setOpen(false)
      dispatch(closeModal())
      dispatch(confirmDialog({ open: false }))
      return
    }

    dispatch(closeStepperModal())
  }, [setOpen, dispatch, handleCloseAction])

  return (
    <CoreDialog
      onClick={(event) => event.stopPropagation()}
      classes={{ paper: clsx(styles.paper, paperClass) }}
      PaperProps={{ style: { width: fixedWidth } }}
      disableEnforceFocus={disableEnforceFocus || false}
      className={className}
      fullWidth={true}
      maxWidth={maxWidth || 'xs'}
      open={open ?? false}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
    >
      <div className={`${styles.dialogHeader}`}>
        <div className={styles.titleContainer}>
          {handlePreviousPage && stepperModalCurrentPage !== 0 && (
            <IconButton className={styles.backButton} onClick={handlePreviousPage}>
              <i className="fas fa-angle-left" />
            </IconButton>
          )}
          <h3 className={styles.title} id="max-width-dialog-title">
            {title}
          </h3>
        </div>
        <i style={closeButtonStyles} className={`${styles.close} far fa-times-circle`} onClick={handleClose} />
      </div>
      <DialogContent classes={{ root: `${styles.contentContainer} ${rootClassName}` }}>{children}</DialogContent>
    </CoreDialog>
  )
}

export default Dialog
