const colors = ['#5aca90', '#55ba84', '#54b782', '#4fa475', '#4ea072', '#4c9b6e', '#499167', '#477e5a', '#446b4c', '#55785c']

export const getRandomColor = (index) => {
  // get random color from index
  // const index = Math.floor(Math.random() * colors.length)

  return colors[index % colors.length]
}

export default colors
