import classNames from 'classnames'
import useCompany from 'hooks/useCompany'
import useLocale from 'hooks/useLocale'
import React from 'react'
import { useParams } from 'react-router'
import NewButton from '../Header/NewButton/NewButton'
import { useFilePermissions } from '../hooks/useFilePermissions'
import styles from './EmptyFolder.module.scss'

const EmptyFolder = ({ isShared, isTrashed, canCreateInShared }) => {
  const locale = useLocale()
  const { isOwner } = useCompany()
  const { folderId } = useParams()
  const { getFilePermissionProps } = useFilePermissions()
  const { isWriter, isPrivateFile } = getFilePermissionProps(folderId)
  const canCreateFiles = isOwner ? true : isPrivateFile ? isWriter && folderId : true

  const hiddeNew = isTrashed || (isShared && !canCreateInShared)

  return (
    <div className={styles.wrapper}>
      <i className={classNames('fas fa-folder-open', styles.icon)} />

      <h2>{locale === 'es' ? 'Esta carpeta está vacía' : 'This folder is empty'}</h2>

      {!hiddeNew && (
        <p>{locale === 'es' ? 'Arrastre un archivo, o use el boton de "nuevo"' : 'Drop a file, or use the "New" button'}</p>
      )}
      {!hiddeNew && canCreateFiles && <NewButton />}
    </div>
  )
}

export default EmptyFolder
