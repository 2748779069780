import React, { useCallback } from 'react'
import styles from './LocaleSelection.module.scss'
import useLocale from 'hooks/useLocale'
import { MenuItem, Select } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { changeLocale } from 'actions/configs'

const LocaleSelection = () => {
  const dispatch = useDispatch()
  const locale = useLocale()

  const localeChange = useCallback(
    (event) => {
      dispatch(changeLocale(event.target.value))
    },
    [dispatch]
  )

  return (
    <Select
      onChange={localeChange}
      classes={{ root: styles.root }}
      defaultValue={locale}
      renderValue={(value) => (value === 'es' ? '🇪🇸' : '🇺🇸')}
      disableUnderline
    >
      <MenuItem value="es">🇪🇸 Español</MenuItem>
      <MenuItem value="en">🇺🇸 English</MenuItem>
    </Select>
  )
}

export default LocaleSelection
