import { deleteStubFactory, deleteStubRecurrency } from 'actions/humanResources'
import { confirmDialog, sendSuccessMessage } from 'actions/modals'
import ButtonIcon from 'components/common/ButtonIcon'
import { format } from 'date-fns'
import { enUS, es } from 'date-fns/locale'
import useLocale from 'hooks/useLocale'
import useToggle from 'hooks/useToggle'
import React from 'react'
import { useDispatch } from 'react-redux'
import styles from './RecurrentStub.module.scss'
import RecurrentStubModal from './RecurrentStubModal'

const RecurrentStub = ({ employee }) => {
  const locale = useLocale()
  const dispatch = useDispatch()
  const { stub_factory, stub_recurrency } = employee ?? {}

  const hasRecurrency = Boolean(stub_recurrency && stub_factory)

  const [openModal, setOpenModal] = useToggle(false)

  const handleCloseModal = () => {
    setOpenModal()
  }

  const formatDate = (date) => {
    return format(new Date(date.replace(/-/gi, '/').split('T')[0]), 'dd MMMM yyyy', {
      locale: locale === 'es' ? es : enUS,
    })
  }

  const handleCancel = () => {
    dispatch(
      confirmDialog({
        message:
          locale === 'es' ? '¿Estás seguro de eliminar esta colilla recurrente?' : 'Are you sure to delete this recurrent stub?',
        handlerAction: async () => {
          dispatch(await deleteStubFactory(stub_factory.id))
          dispatch(await deleteStubRecurrency(stub_recurrency.id))
          dispatch(sendSuccessMessage(locale === 'es' ? 'Colilla recurrente eliminada' : 'Recurrent stub deleted'))
        },
      })
    )
  }

  return (
    <>
      <div className={styles.wrapper}>
        <section className={styles.informationSection}>
          <strong>{locale === 'es' ? 'Colilla recurrente' : 'Recurrent stub'}</strong>
          <p className={styles.recurrencyHelper}>
            {hasRecurrency
              ? locale === 'es'
                ? `La proxima colilla de pago se generará el día ${formatDate(stub_recurrency?.nextDate)}`
                : `The next payment stub will be generated the day ${formatDate(stub_recurrency?.nextDate)}`
              : locale === 'es'
              ? 'Este empleado no tiene una colilla recurrente...'
              : 'This employee does not have a recurrent stub...'}
          </p>
        </section>

        {hasRecurrency ? (
          <div className={styles.buttons}>
            <ButtonIcon
              onClick={handleCancel}
              icon={<i className="fas fa-trash-alt" />}
              variant="text"
              color="primary"
              type="submit"
            >
              {locale === 'es' ? 'Eliminar' : 'Delete'}
            </ButtonIcon>
            <ButtonIcon
              onClick={setOpenModal}
              icon={<i className="fas fa-edit" />}
              variant="contained"
              color="primary"
              type="submit"
            >
              {locale === 'es' ? 'Editar' : 'Edit'}
            </ButtonIcon>
          </div>
        ) : (
          <ButtonIcon onClick={setOpenModal} variant="outlined" color="primary" icon={<i className="fas fa-circle-plus" />}>
            {locale === 'es' ? 'Crear colilla recurrente' : 'Create recurrent stub'}
          </ButtonIcon>
        )}
      </div>

      <RecurrentStubModal open={openModal} handleClose={handleCloseModal} employee={employee} />
    </>
  )
}

export default RecurrentStub
