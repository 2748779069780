import {
  DELETE_BOARD,
  DELETE_BOARD_LABEL,
  DELETE_BOARD_LIST,
  DELETE_BOARD_TASK,
  DELETE_BOARD_TASK_FILE,
  DELETE_BOARD_TEMPLATE,
  DELETE_BOARD_TEMPLATE_COLUMN,
  GET_BOARDS,
  GET_BOARD_TASKS,
  GET_BOARD_TEMPLATES,
  MOVE_COLUMN,
  UPDATE_BOARD,
  UPDATE_BOARD_LIST,
  UPDATE_BOARD_TASK,
  UPDATE_BOARD_TASK_COMMENT,
  UPDATE_BOARD_TEMPLATE,
  UPDATE_BOARD_TEMPLATE_COLUMN,
  UPLOAD_BOARD,
  UPLOAD_BOARD_LABEL,
  UPLOAD_BOARD_LIST,
  UPLOAD_BOARD_TASK,
  UPLOAD_BOARD_TASK_COMMENT,
  UPLOAD_BOARD_TASK_FILE,
  UPLOAD_BOARD_TASK_HISTORY,
  UPLOAD_BOARD_TEMPLATE,
  UPLOAD_BOARD_TEMPLATE_COLUMN,
} from 'constants/actionTypes'

import {
  BOARDS_ROUTE,
  BOARD_LABELS_ROUTE,
  BOARD_LISTS_ROUTE,
  BOARD_TASKS_COMMENTS_ROUTE,
  BOARD_TASKS_FILES_ROUTE,
  BOARD_TASKS_HISTORIES_ROUTE,
  BOARD_TASKS_POSITION_ROUTE,
  BOARD_TASKS_ROUTE,
  BOARD_TEMPLATES_ROUTE,
  BOARD_TEMPLATE_COLUMNS_ROUTE,
} from 'constants/apiRoutes'
import { apiDeleteData, apiFindData, apiPostData, apiPostFile, apiPutData } from 'helpers/api'

// BOARDS
export const getCompanyBoards = (idCompany) => apiFindData(BOARDS_ROUTE, { id: idCompany }, GET_BOARDS)

export const postBoard = (board) => apiPostData(BOARDS_ROUTE, board, UPLOAD_BOARD)

export const updateBoard = (board) => apiPutData(BOARDS_ROUTE, { ...board, id: board?.idBoard }, UPDATE_BOARD)

export const deleteBoard = (idBoard) => apiDeleteData(BOARDS_ROUTE, { id: idBoard }, DELETE_BOARD)

// TEMPLATES
export const getCompanyBoardTemplates = (idCompany) => apiFindData(BOARD_TEMPLATES_ROUTE, { id: idCompany }, GET_BOARD_TEMPLATES)

export const postBoardTemplate = (boardTemplate) => apiPostData(BOARD_TEMPLATES_ROUTE, boardTemplate, UPLOAD_BOARD_TEMPLATE)

export const updateBoardTemplate = (boardTemplate) =>
  apiPutData(BOARD_TEMPLATES_ROUTE, { ...boardTemplate, id: boardTemplate?.idBoardTemplate }, UPDATE_BOARD_TEMPLATE)

export const deleteBoardTemplate = (idBoardTemplate) =>
  apiDeleteData(BOARD_TEMPLATES_ROUTE, { id: idBoardTemplate }, DELETE_BOARD_TEMPLATE)

export const postBoardTemplateColumn = (boardTemplateColumn) =>
  apiPostData(BOARD_TEMPLATE_COLUMNS_ROUTE, boardTemplateColumn, UPLOAD_BOARD_TEMPLATE_COLUMN)

export const updateBoardTemplateColumn = (boardTemplateColumn) =>
  apiPutData(
    BOARD_TEMPLATE_COLUMNS_ROUTE,
    { ...boardTemplateColumn, id: boardTemplateColumn?.idBoardTemplateColumn },
    UPDATE_BOARD_TEMPLATE_COLUMN
  )

export const deleteBoardTemplateColumn = (idBoardTemplateColumn) =>
  apiDeleteData(BOARD_TEMPLATE_COLUMNS_ROUTE, { id: idBoardTemplateColumn }, DELETE_BOARD_TEMPLATE_COLUMN)

// TASKS
export const getBoardTasks = (idBoard) => apiFindData(BOARD_TASKS_ROUTE, { id: idBoard }, GET_BOARD_TASKS)

export const postBoardTask = (boardTask) => apiPostData(BOARD_TASKS_ROUTE, boardTask, UPLOAD_BOARD_TASK)

export const updateBoardTask = (boardTask, config = {}) =>
  apiPutData(BOARD_TASKS_ROUTE, { id: boardTask?.idBoardTask, ...boardTask }, UPDATE_BOARD_TASK, config)

export const updateBoardTasksPosition = (updatedTasks, config = {}) =>
  apiPostData(BOARD_TASKS_POSITION_ROUTE, updatedTasks, config)

export const deleteBoardTask = (idBoardTask) => apiDeleteData(BOARD_TASKS_ROUTE, { id: idBoardTask }, DELETE_BOARD_TASK)

// LABELS
export const postBoardLabel = (boardLabel) => apiPostData(BOARD_LABELS_ROUTE, boardLabel, UPLOAD_BOARD_LABEL)

export const updateBoardLabel = (boardLabel) =>
  apiPutData(BOARD_LABELS_ROUTE, { ...boardLabel, id: boardLabel?.idBoardLabel }, UPLOAD_BOARD_LABEL)

export const deleteBoardLabel = (id) => apiDeleteData(BOARD_LABELS_ROUTE, { id }, DELETE_BOARD_LABEL)

// LISTS
export const postBoardList = (boardList) => apiPostData(BOARD_LISTS_ROUTE, boardList, UPLOAD_BOARD_LIST)

export const updateBoardList = (boardList) =>
  apiPutData(BOARD_LISTS_ROUTE, { ...boardList, id: boardList?.idBoardList }, UPDATE_BOARD_LIST)

export const deleteBoardList = (idBoardList) => apiDeleteData(BOARD_LISTS_ROUTE, { id: idBoardList }, DELETE_BOARD_LIST)

// TASKS FILE
export const createTaskFile = (form) => apiPostFile(BOARD_TASKS_FILES_ROUTE, form, UPLOAD_BOARD_TASK_FILE)
export const deleteTaskFile = (id) => apiDeleteData(BOARD_TASKS_FILES_ROUTE, { id }, DELETE_BOARD_TASK_FILE)

export const postBoardTaskComment = (boardTaskComment) =>
  apiPostData(BOARD_TASKS_COMMENTS_ROUTE, boardTaskComment, UPLOAD_BOARD_TASK_COMMENT)
export const updateBoardTaskComment = (id, message) =>
  apiPutData(BOARD_TASKS_COMMENTS_ROUTE, { id, message }, UPDATE_BOARD_TASK_COMMENT)
export const postBoardTaskHistory = (boardTaskHistory) =>
  apiPostData(BOARD_TASKS_HISTORIES_ROUTE, boardTaskHistory, UPLOAD_BOARD_TASK_HISTORY)

export const moveColumnPositions = ({ columns, templateId }) => {
  return {
    type: MOVE_COLUMN,
    payload: { columns, templateId },
  }
}
