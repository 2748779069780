import { Button, CircularProgress, FormControl, MenuItem, Select, TextField } from '@material-ui/core'
import { createStub, updateStub } from 'actions/humanResources'
import { sendErrorMessage, sendSuccessMessage } from 'actions/modals'
import Dialog from 'components/Dialog/Dialog'
import paymentTypes from 'constants/paymentTypes'
import { format } from 'date-fns'
import useForm from 'hooks/useForm'
import useLocale from 'hooks/useLocale'
import useMoney from 'hooks/useMoney'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import useStubHandlers from '../hooks/useStubHandlers'
import styles from './StubModal.module.scss'

const StubModal = ({ open, setOpen, handleCloseAction, selectedId, findStub, defaultStub, employee, calculateDefaultStub }) => {
  const locale = useLocale()
  const dispatch = useDispatch()
  const [stub, setForm] = useState({})
  const { isEdit } = stub

  const handleChange = useForm(setForm)
  const {
    handleChangeType,
    handleChangeExtraPay,
    handleChangeWorkingHours,
    handleChangeExtraWorkingHours,
    handleChangeHourlyRate,
    handleChangeExtraHourlyRate,
  } = useStubHandlers(setForm, employee, calculateDefaultStub)
  const { formatMoney } = useMoney()

  const [loading, setLoading] = useState(false)

  const title = useMemo(() => {
    const editTitle = locale === 'es' ? 'Editar colilla' : 'Edit stub'
    const title = locale === 'es' ? 'Agregar colilla' : 'Add stub'

    return isEdit ? editTitle : title
  }, [locale, isEdit])

  const buttonText = useMemo(() => {
    const editTitle = locale === 'es' ? 'Guardar' : 'Save'
    const title = locale === 'es' ? 'Generar' : 'Generate'

    return isEdit ? editTitle : title
  }, [locale, isEdit])

  const formattedDate = useMemo(() => {
    if (!stub?.date) return format(new Date(), 'yyyy-MM-dd')

    const temp = new Date(stub?.date)
    const year = temp.getUTCFullYear()
    const month = temp.getUTCMonth()
    const day = temp.getUTCDate()

    const date = format(new Date(year, month, day), 'yyyy-MM-dd')

    return date
  }, [stub?.date])

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault()
      setLoading(true)

      const action = isEdit ? updateStub : createStub
      const message = isEdit
        ? locale === 'es'
          ? 'Colilla actualizada'
          : 'Stub updated'
        : locale === 'es'
        ? 'Colilla creada'
        : 'Stub created'

      try {
        dispatch(await action(stub))
        dispatch(sendSuccessMessage(message))
        handleCloseAction()
      } catch (error) {
        dispatch(sendErrorMessage(locale === 'es' ? 'Hubo un error en el servidor' : 'Error server'))
        console.error(error.message)
      }

      setLoading(false)
    },
    [stub, isEdit, locale, handleCloseAction, dispatch]
  )

  useEffect(() => {
    if (!selectedId) {
      setForm(defaultStub)
    } else {
      const stub = findStub(selectedId)
      setForm({
        ...stub,
        isEdit: true,
      })
    }
  }, [selectedId, findStub, defaultStub])

  useEffect(() => {
    if (!open) setForm(defaultStub)
  }, [open, defaultStub])

  return (
    <Dialog open={open} setOpen={setOpen} title={title} handleCloseAction={handleCloseAction}>
      <h2 className={styles.employeeName}>{employee?.name}</h2>

      <form onSubmit={handleSubmit} className={styles.contentContainer}>
        <article className={styles.inputControl}>
          <label htmlFor="IS_PENDING">{locale === 'es' ? 'Estado' : 'Status'}:</label>
          <FormControl variant="outlined" fullWidth required>
            <Select value={stub?.IS_PENDING ?? 1} onChange={(e) => handleChange(e, 'IS_PENDING', 'number')}>
              <MenuItem value={0}>{locale === 'es' ? 'Aprobado' : 'Aproved'}</MenuItem>
              <MenuItem value={1}>{locale === 'es' ? 'Pendiente' : 'Pending'}</MenuItem>
            </Select>
          </FormControl>
        </article>

        <article className={styles.inputControl}>
          <label htmlFor="type">{locale === 'es' ? 'Tipo de pago' : 'Payment type'}:</label>
          <FormControl variant="outlined" fullWidth required>
            <Select value={stub?.type ?? 1} onChange={handleChangeType}>
              {paymentTypes.map((i) => (
                <MenuItem value={i.value} key={i.value}>
                  {i.text[locale]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </article>

        <article className={styles.inputControl}>
          <label htmlFor="reference">{locale === 'es' ? 'Referencia de pago' : 'Payment reference'}:</label>
          <TextField
            id="reference"
            variant="outlined"
            value={stub?.reference ?? ''}
            onChange={(e) => handleChange(e, 'reference')}
            fullWidth
          />
        </article>

        <article className={styles.inputControl}>
          <label htmlFor="date">{locale === 'es' ? 'Fecha de pago' : 'Payment date'}:</label>
          <TextField
            id="date"
            type="date"
            variant="outlined"
            value={formattedDate}
            onChange={(e) => handleChange(e, 'date', 'date')}
            disabled={stub?.type === 5}
            fullWidth
          />
        </article>

        <article className={styles.inputControl}>
          <label htmlFor="workedHours">{locale === 'es' ? 'Horas trabajadas' : 'Worked hours'}:</label>
          <TextField
            required
            id="workedHours"
            type="number"
            variant="outlined"
            value={stub?.workedHours || ''}
            onChange={handleChangeWorkingHours}
            inputProps={{
              min: 0,
            }}
            fullWidth
            disabled={stub?.type === 5}
          />
        </article>

        {stub.type === 5 ? null : (
          <article className={styles.inputControl}>
            <label htmlFor="extraHours">{locale === 'es' ? 'Horas extra' : 'Extra hours'}:</label>
            <TextField
              id="extraHours"
              type="number"
              variant="outlined"
              value={stub?.extraHours ?? ''}
              onChange={handleChangeExtraWorkingHours}
              inputProps={{
                min: 0,
              }}
              fullWidth
            />
          </article>
        )}

        <article className={styles.inputControl}>
          <label htmlFor="hourlyRate">{locale === 'es' ? 'Pago por hora' : 'Hourly rate'}:</label>
          <TextField
            required
            id="hourlyRate"
            type="number"
            variant="outlined"
            value={stub?.hourlyRate ?? ''}
            onChange={handleChangeHourlyRate}
            inputProps={{
              min: 0.01,
              step: '0.01',
            }}
            fullWidth
          />
        </article>

        {stub.type === 5 ? null : (
          <>
            <article className={styles.inputControl}>
              <label htmlFor="extraHourlyRate">{locale === 'es' ? 'Pago por hora extra' : 'Extra hourly rate'}:</label>
              <TextField
                id="extraHourlyRate"
                type="number"
                variant="outlined"
                value={stub?.extraHourlyRate}
                onChange={handleChangeExtraHourlyRate}
                inputProps={{
                  min: 0,
                  step: '0.01',
                }}
                fullWidth
                disabled={stub.type === 5}
              />
            </article>

            <article className={styles.inputControl}>
              <label htmlFor="extraPayment">{locale === 'es' ? 'Pago extra' : 'Extra payment'}:</label>
              <TextField
                id="extraPayment"
                type="number"
                variant="outlined"
                value={stub?.extraPayment}
                onChange={handleChangeExtraPay}
                inputProps={{
                  min: 0,
                  step: '0.01',
                }}
                fullWidth
              />
            </article>

            <article className={styles.inputControl}>
              <label htmlFor="extraPaymentDescription">{locale === 'es' ? 'Nota - pago extra' : 'Extra payment - note'}:</label>
              <TextField
                id="extraPaymentDescription"
                type="text"
                variant="outlined"
                value={stub?.extraPaymentDescription ?? ''}
                onChange={(e) => handleChange(e, 'extraPaymentDescription')}
                fullWidth
                multiline
                rows={3}
                placeholder="N/A"
              />
            </article>
          </>
        )}

        <article className={styles.inputControl}>
          <label htmlFor="basePayment">{locale === 'es' ? 'Pago base' : 'Pago base'}:</label>
          <TextField
            id="basePayment"
            type="text"
            variant="outlined"
            value={formatMoney(stub?.basePayment, employee?.currency) ?? ''}
            disabled
            fullWidth
          />
        </article>

        {stub.type === 5 ? null : (
          <article className={styles.inputControl}>
            <label htmlFor="extraHoursPayment">{locale === 'es' ? 'Pago horas extra' : 'Extra hours payment'}:</label>
            <TextField
              id="extraHoursPayment"
              type="text"
              variant="outlined"
              value={formatMoney(stub?.extraHoursPayment, employee?.currency) ?? ''}
              disabled
              fullWidth
            />
          </article>
        )}

        <article className={styles.inputControl}>
          <label htmlFor="deductions">{locale === 'es' ? 'Deducciones' : 'Deductions'}:</label>
          <TextField
            id="deductions"
            type="text"
            variant="outlined"
            value={formatMoney(stub?.deductions, employee?.currency) ?? ''}
            disabled
            fullWidth
          />
        </article>

        <article className={styles.inputControl}>
          <label htmlFor="totalPayment">{locale === 'es' ? 'Pago total' : 'Total payment'}:</label>
          <TextField
            id="totalPayment"
            type="text"
            variant="outlined"
            value={formatMoney(stub?.totalPayment, employee?.currency) ?? ''}
            disabled
            fullWidth
          />
        </article>

        <article className={styles.inputControl}>
          <label htmlFor="description">{locale === 'es' ? 'Nota' : 'Note'}:</label>
          <TextField
            id="description"
            type="text"
            variant="outlined"
            value={stub?.description ?? ''}
            onChange={(e) => handleChange(e, 'description')}
            fullWidth
            multiline
            rows={3}
            placeholder="N/A"
          />
        </article>

        <section className={styles.loading}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Button
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              classes={{ root: styles.button }}
              disabled={stub?.workedHours <= 0 && !stub?.extraHours}
            >
              {buttonText}
            </Button>
          )}
        </section>
      </form>
    </Dialog>
  )
}

export default StubModal
