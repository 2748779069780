import React from 'react'
import ByMonth from './ByMonth'
import ByWeek from './ByWeek'

const WorkedHours = ({ employee }) => {
  return (
    <>
      <ByMonth employee={employee} />
      <ByWeek employee={employee} />
    </>
  )
}

export default WorkedHours
