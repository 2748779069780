import axios from 'axios'
import { useGoogleAuth } from 'helpers/google'
import useLocale from 'hooks/useLocale'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { permanentlyDeleteAllFiles, permanentlyDeleteFile } from './api/googleDriveApi'

const client = axios.create({
  baseURL: 'https://www.googleapis.com/drive/v3',
})

const uploadClient = axios.create({
  baseURL: 'https://www.googleapis.com/upload/drive/v3',
})

export const useUploadFile = () => {
  const { authToken } = useGoogleAuth()
  const locale = useLocale()

  let response
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    params: {
      corpora: 'user',
      orderBy: 'name',
      fields: 'parents,thumbnailLink,iconLink,name,id,webViewLink,webContentLink,permissions',
    },
  }

  const uploadFile = async ({ body, headers, isGoogleFile }) => {
    if (isGoogleFile) {
      response = await toast.promise(uploadClient.post('/files', body, requestConfig), {
        pending: locale === 'es' ? 'Creando archivo...' : 'Creating file...',
        success: locale === 'es' ? 'Archivo creado' : 'File created',
        error: locale === 'es' ? 'Error al crear el archivo' : 'Error creating file',
      })
    } else {
      response = await uploadClient.post('/files', body, {
        ...requestConfig,
        ...headers,
      })

      return response.data
    }
  }

  return uploadFile
}

export const useGetFile = (id) => {
  const { authToken, hasValidToken } = useGoogleAuth()

  const queryFn = () =>
    client
      .get(`/files/${id}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: {
          fields: 'capabilities',
        },
      })
      .then((res) => res?.data)
      .catch(() => undefined)

  const options = {
    enabled: !!authToken && hasValidToken,
    refetchInterval: 300000,
  }

  return useQuery(['fileInformation', id], queryFn, options)
}

export const useFiles = (id = null) => {
  const { authToken, hasValidToken } = useGoogleAuth()
  const filters = [
    "'me' in owners",
    'trashed = false',
    "mimeType != 'application/vnd.google-apps.folder'",
    id ? `'${id}' in parents` : "'root' in parents",
  ].join(' and ')

  const queryFn = () =>
    client
      .get('/files', {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: {
          corpora: 'user',
          q: filters,
          orderBy: 'name',
          fields:
            'files(iconLink,name,thumbnailLink,id,webViewLink,webContentLink,parents,permissions,mimeType,capabilities(canShare))',
        },
      })
      .then((res) => res.data.files)

  const options = {
    enabled: !!authToken && hasValidToken,
    refetchInterval: 300000,
  }

  return useQuery(['files', id || 'root'], queryFn, options)
}

export const useSharedFiles = (id = null) => {
  const { authToken, hasValidToken } = useGoogleAuth()
  const filters = [
    id ? undefined : 'sharedWithMe',
    'trashed = false',
    "mimeType != 'application/vnd.google-apps.folder'",
    id ? `'${id}' in parents` : undefined,
  ]
    .filter(Boolean)
    .join(' and ')

  const queryFn = () =>
    client
      .get(`/files`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: {
          corpora: 'user',
          q: filters,
          orderBy: 'name',
          fields:
            'files(iconLink,name,thumbnailLink,id,webViewLink,webContentLink,parents,permissions,mimeType,capabilities(canShare))',
        },
      })
      .then((res) => res.data.files)

  const options = {
    enabled: !!authToken && hasValidToken,
    refetchInterval: 300000,
  }

  return useQuery(['sharedFiles', id || 'root'], queryFn, options)
}

export const useFolders = (id) => {
  const { authToken, hasValidToken } = useGoogleAuth()
  const filters = [
    "'me' in owners",
    'trashed = false',
    "mimeType = 'application/vnd.google-apps.folder'",
    id ? `'${id}' in parents` : "'root' in parents",
  ].join(' and ')

  const queryFn = () =>
    client
      .get('/files', {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: {
          corpora: 'user',
          orderBy: 'name',
          q: filters,
          fields: 'files(id,name,iconLink,parents,permissions,capabilities(canShare))',
        },
      })
      .then((res) => res.data.files)

  const options = {
    enabled: !!authToken && hasValidToken,
    refetchInterval: 300000,
  }

  return useQuery(['folders', id || 'root'], queryFn, options)
}

export const useSharedFolders = (folderId) => {
  const { authToken, hasValidToken } = useGoogleAuth()
  const filters = [
    folderId ? undefined : 'sharedWithMe',
    "mimeType = 'application/vnd.google-apps.folder'",
    folderId ? `'${folderId}' in parents` : undefined,
  ]
    .filter(Boolean)
    .join(' and ')

  const queryFn = () =>
    client
      .get('/files', {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: {
          corpora: 'user',
          orderBy: 'name',
          q: filters,
          fields: 'files(id,name,iconLink,parents,capabilities(canShare))',
        },
      })
      .then((res) => {
        return res.data.files
      })

  const options = {
    enabled: !!authToken && hasValidToken,
    refetchInterval: 300000,
  }

  return useQuery(['sharedFolders', folderId || 'root'], queryFn, options)
}

export const useTrashedFiles = () => {
  const { authToken, hasValidToken } = useGoogleAuth()
  const filters = [
    "'me' in owners",
    'trashed = true',
    "mimeType != 'application/vnd.google-apps.folder'",
    "'root' in parents",
  ].join(' and ')

  const queryFn = () =>
    client
      .get('/files', {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: {
          corpora: 'user',
          q: filters,
          orderBy: 'name',
          fields: 'files(iconLink,name,thumbnailLink,id,webViewLink,webContentLink,parents,permissions,mimeType)',
        },
      })
      .then((res) => res.data.files)

  const options = {
    enabled: !!authToken && hasValidToken,
    refetchInterval: 300000,
  }

  return useQuery(['trashedFiles'], queryFn, options)
}

export const useTrashedFolders = () => {
  const { authToken, hasValidToken } = useGoogleAuth()
  const filters = ["'me' in owners", "mimeType = 'application/vnd.google-apps.folder'", 'trashed = true'].join(' and ')

  const queryFn = () =>
    client
      .get('/files', {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: {
          corpora: 'user',
          orderBy: 'name',
          q: filters,
          fields: 'files(id,name,iconLink,parents,permissions)',
        },
      })
      .then((res) => res.data.files)

  const options = {
    enabled: !!authToken && hasValidToken,
    refetchInterval: 300000,
  }

  return useQuery(['trashedFolders'], queryFn, options)
}

export const useFolderBreadcrumbs = (id) => {
  const { authToken, hasValidToken } = useGoogleAuth()

  let parents = []
  let breadcrumbs = []

  const fetchFolder = async (folderId) => {
    const response = await client.get(`/files/${folderId}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        corpora: 'user',
        fields: 'name,id,parents',
      },
    })

    const { data } = response

    parents = data?.parents ?? []

    return data
  }

  const queryFn = async () => {
    const currentFolder = await fetchFolder(id)
    breadcrumbs.push(currentFolder)

    while (parents.length > 0) {
      const nextFolder = await fetchFolder(parents[0])
      breadcrumbs.push(nextFolder)
    }

    return breadcrumbs.reverse()
  }

  const options = {
    enabled: !!authToken && hasValidToken,
  }

  return useQuery(['file', id], queryFn, options)
}

export const useCreateFile = () => {
  const locale = useLocale()
  const { folderId: currentFolder } = useParams()

  const queryClient = useQueryClient()
  const { authToken, hasValidToken } = useGoogleAuth()

  const queryFn = async ({ body, headers, isGoogleFile }) => {
    let toastId = null
    let response = {}

    const onUploadProgress = (progressEvent) => {
      const progress = progressEvent.loaded / progressEvent.total
      const percentage = Math.round(progress * 100)
      const loadingMessage = (
        <p>
          {locale === 'es' ? `Subiendo archivo... ` : `Uploading... `} <strong>({percentage}%)</strong>
        </p>
      )

      if (progress === 1) {
        toast.success(locale === 'es' ? 'Archivo guardado!' : 'File saved!', { autoClose: 5000 })
        toast.done(toastId)

        return
      }

      if (toastId === null) {
        toastId = toast(loadingMessage, {
          progress,
        })
      }

      toast.update(toastId, { progress, render: loadingMessage, closeButton: false })
    }
    const requestConfig = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        ...headers,
      },
      params: {
        corpora: 'user',
        orderBy: 'name',
        fields: 'parents,thumbnailLink,iconLink,name,id,webViewLink,webContentLink,permissions',
      },
    }

    if (isGoogleFile) {
      response = await toast.promise(uploadClient.post('/files', body, requestConfig), {
        pending: locale === 'es' ? 'Creando archivo...' : 'Creating file...',
        success: locale === 'es' ? 'Archivo creado' : 'File created',
        error: locale === 'es' ? 'Error al crear el archivo' : 'Error creating file',
      })
    } else {
      response = await uploadClient.post('/files', body, {
        ...requestConfig,
        onUploadProgress,
      })

      return response.data
    }

    return response.data
  }

  const options = {
    enabled: !!authToken && hasValidToken,
    onSuccess: () => {
      queryClient.invalidateQueries(['files', currentFolder || 'root'])
      queryClient.invalidateQueries(['folders', currentFolder || 'root'])
      queryClient.invalidateQueries(['sharedFiles', currentFolder || 'root'])
      queryClient.invalidateQueries(['sharedFolders', currentFolder || 'root'])
      queryClient.invalidateQueries(['about'])
    },
  }

  return useMutation(queryFn, options)
}

export const useUpdateFile = () => {
  const { authToken, hasValidToken } = useGoogleAuth()
  const { folderId: currentFolder } = useParams()
  const locale = useLocale()
  const queryClient = useQueryClient()

  const queryFn = async ({ fileId, folderId, body, params = {} }) => {
    const response = await client.patch(`/files/${fileId}`, body, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        corpora: 'user',
        fields: 'iconLink,name,thumbnailLink,id,webViewLink,webContentLink,parents,permissions',
        ...params,
      },
    })

    return { ...response.data, folderId }
  }

  const options = {
    enabled: !!authToken && hasValidToken,
    onSuccess: (data) => {
      const folderId = currentFolder || 'root'

      queryClient.invalidateQueries(['files', folderId])
      queryClient.invalidateQueries(['folders', folderId])
      queryClient.invalidateQueries(['sharedFiles', folderId || 'root'])
      queryClient.invalidateQueries(['sharedFolders', folderId || 'root'])
      queryClient.invalidateQueries(['about'])
      queryClient.invalidateQueries(['trashedFiles'])
      queryClient.invalidateQueries(['trashedFolders'])

      if (data.trashed) {
        toast.success(locale === 'es' ? 'Archivo eliminado!' : 'File deleted!', { autoClose: 2000 })
      } else {
        toast.success(locale === 'es' ? 'Archivo actualizado!' : 'File updated!', { autoClose: 2000 })
      }
    },
  }

  return useMutation(queryFn, options)
}

export const useAbout = () => {
  const { authToken, hasValidToken } = useGoogleAuth()

  const queryFn = async () => {
    const response = await client.get('/about', {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        fields: 'storageQuota',
      },
    })

    return response.data
  }

  const options = {
    enabled: !!authToken && hasValidToken,
  }

  return useQuery('about', queryFn, options)
}

export const useEmptyTrash = () => {
  const queryClient = useQueryClient()

  return useMutation(({ ids, authToken }) => permanentlyDeleteAllFiles(ids, authToken), {
    onSuccess: () => {
      queryClient.invalidateQueries(['trashedFiles'])
      queryClient.invalidateQueries(['trashedFolders'])
    },
  })
}

export const useDeleteFile = () => {
  const queryClient = useQueryClient()

  return useMutation(({ id, authToken }) => permanentlyDeleteFile(id, authToken), {
    onSuccess: () => {
      queryClient.invalidateQueries(['trashedFiles'])
      queryClient.invalidateQueries(['trashedFolders'])
    },
  })
}

export const useGetFolderInfo = (folderId) => {
  const { authToken, hasValidToken } = useGoogleAuth()

  const queryFn = async () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    }

    if (!folderId) return null

    const { data: current } = await client.get(`/files/${folderId}`, {
      headers,
      params: {
        fields: 'id,name,parents',
      },
    })

    const { data: children } = await client.get(`/files`, {
      headers,
      params: {
        fields: 'files(id,name,iconLink)',
        q: `mimeType = 'application/vnd.google-apps.folder' and '${folderId}' in parents`,
      },
    })

    return { current, children }
  }

  const options = {
    enabled: !!authToken && hasValidToken,
  }

  return useQuery(['folderInfo', folderId], queryFn, options)
}

// PERMISSIONS

export const useCreatePermission = () => {
  const { authToken, hasValidToken } = useGoogleAuth()
  const { folderId: currentFolder } = useParams()
  const queryClient = useQueryClient()

  const queryFn = async ({ fileId, body, folderId }) => {
    const response = await client.post(`/files/${fileId}/permissions`, body, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        corpora: 'user',
        fields: '*',
      },
    })

    return { ...response.data, folderId }
  }

  const options = {
    enabled: !!authToken && hasValidToken,
    onSuccess: (data) => {
      queryClient.invalidateQueries(['files', currentFolder || 'root'])
      queryClient.invalidateQueries(['folders', currentFolder || 'root'])
    },
  }

  return useMutation(queryFn, options)
}

export const useUpdatePermission = () => {
  const { authToken, hasValidToken } = useGoogleAuth()
  const { folderId: currentFolder } = useParams()
  const queryClient = useQueryClient()

  const queryFn = async ({ fileId, permissionId, body, folderId }) => {
    const response = await client.patch(`/files/${fileId}/permissions/${permissionId}`, body, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        corpora: 'user',
        fields: '*',
      },
    })

    return { ...response.data, folderId }
  }

  const options = {
    enabled: !!authToken && hasValidToken,
    onSuccess: (data) => {
      queryClient.invalidateQueries(['files', currentFolder || 'root'])
      queryClient.invalidateQueries(['folders', currentFolder || 'root'])
    },
  }

  return useMutation(queryFn, options)
}

export const useDeletePermission = () => {
  const { authToken, hasValidToken } = useGoogleAuth()
  const { folderId: currentFolder } = useParams()
  const queryClient = useQueryClient()

  const queryFn = async ({ fileId, permissionId, folderId }) => {
    const response = await client.delete(`/files/${fileId}/permissions/${permissionId}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        corpora: 'user',
        fields: '*',
      },
    })

    return { ...response.data, folderId }
  }

  const options = {
    enabled: !!authToken && hasValidToken,
    onSuccess: (data) => {
      queryClient.invalidateQueries(['files', currentFolder || 'root'])
      queryClient.invalidateQueries(['folders', currentFolder || 'root'])
    },
  }

  return useMutation(queryFn, options)
}
