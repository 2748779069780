import { sendAlarmMessage } from 'actions/modals'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import useLocale from './useLocale'
import useToggle from './useToggle'

export function isQuillEmpty(value) {
  if ((value?.replace(/<(.|\n)*?>/g, '')?.trim()?.length === 0 && !value?.includes('<img')) || !value) {
    return true
  }

  return false
}

const useTextEditor = (initialState = null) => {
  const locale = useLocale()
  const dispatch = useDispatch()
  const commentSectionRef = useRef()

  const [commenting, toggleCommenting] = useToggle()
  const [comment, setComment] = useState(initialState ?? '')

  const isCommentEmpty = isQuillEmpty(comment)

  const onChange = (e) => setComment(e)

  const onSave = async (callback) => {
    if (isCommentEmpty) {
      dispatch(sendAlarmMessage(locale === 'es' ? 'No puedes guardar un comentario vacio' : "You can't save an empty comment"))
    }

    if (typeof callback === 'function') {
      await callback()
    } else return

    toggleCommenting()
    setComment('')
  }

  const scrollToBottom = () => {
    const goTo = commentSectionRef.current.scrollHeight
    commentSectionRef.current.scrollTop = goTo
  }

  useLayoutEffect(() => {
    if (commentSectionRef?.current) {
      scrollToBottom()
    }
  }, [commentSectionRef])

  useEffect(() => {
    if (initialState) {
      setComment(initialState)
    }
  }, [initialState])

  return {
    commenting,
    toggleCommenting,
    comment,
    commentSectionRef,
    isCommentEmpty,
    isQuillEmpty,
    setComment,
    onChange,
    onSave,
    scrollToBottom,
  }
}

export default useTextEditor
