import React, { useMemo } from 'react'
import { FormGroup, TextField } from '@material-ui/core'
import { DateTimePicker } from '@material-ui/pickers'
import { sendErrorMessage } from 'actions/modals'
import Checkbox from 'components/common/Checkbox'
import { isSameDay } from 'date-fns'
import { formatDate } from 'helpers/date'
import useLocale from 'hooks/useLocale'
import { useDispatch } from 'react-redux'
import { CHECKBOX_VALUES, _calculateReminder } from './helpers'
import styles from './ScheduleModalEventExtra.module.scss'
import FileInput from 'components/FileInput/FileInput'

const ScheduleModalEventExtra = ({ form, setForm }) => {
  const locale = useLocale()
  const dispatch = useDispatch()
  const { event } = form

  const personalizedReminder = useMemo(() => event?.reminders?.find((reminder) => reminder.option === '6'), [event.reminders])

  const onCheckedBoxClick = (e) => {
    const isChecking = e.target.checked
    const value = e.target.value

    setForm((form) => {
      if (isChecking) {
        form.event.reminders = [
          ...form.event.reminders,
          { option: value, date: _calculateReminder(Number(value), event?.date, event?.startTime) },
        ]
      } else {
        form.event.reminders = form.event.reminders.filter((_option) => _option.option !== value)
      }
    })
  }

  const onPersonalizedReminderChange = (date) => {
    const time = formatDate(date, 'HH:mm', true)
    const sameDate = isSameDay(new Date(date), new Date(event.date))

    const [hours, minutes] = event?.startTime?.split(':') ?? ['00', '00']
    const [targetHours, targetMinutes] = time?.split(':')

    if (sameDate && (targetHours > hours || (hours === targetHours && targetMinutes > minutes))) {
      dispatch(sendErrorMessage(locale === 'es' ? 'Escoja una hora antes del evento' : 'Choose a time before the event'))
      return
    }

    setForm((form) => {
      const index = form.event.reminders.findIndex((reminder) => reminder.option === '6')
      form.event.reminders[index] = { ...form.event.reminders[index], date: formatDate(date, undefined, true) }
    })
  }

  const onSetFile = (e) => {
    const file = e.target.files[0]

    setForm((form) => {
      form.event.file = file
      form.event.filename = file.name
    })
  }

  return (
    <section>
      <section className={styles.remindersSection}>
        <FormGroup classes={{ root: styles.formGroupRoot }}>
          <p className={styles.formGroupLabel}>{locale === 'es' ? 'Recordatorios' : 'Reminders'}</p>
          {CHECKBOX_VALUES.filter((option) => {
            if (event.isAllDay) return option.value === 6
            else return option
          }).map((option) => (
            <Checkbox
              key={option.value}
              label={option[locale]}
              value={option.value}
              checked={event?.reminders?.some((reminder) => reminder?.option === String(option?.value))}
              onChange={onCheckedBoxClick}
            />
          ))}
        </FormGroup>

        {Boolean(personalizedReminder) && (
          <DateTimePicker
            fullWidth
            disablePast
            format="dd MMMM, yyyy | h:mm a"
            maxDate={event.date}
            label={locale === 'es' ? 'Fecha de recordatorio' : 'Date of reminder'}
            value={personalizedReminder.date}
            onChange={onPersonalizedReminderChange}
            minutesStep={5}
          />
        )}
      </section>

      <FileInput
        field={{
          name: 'file',
          label: locale === 'es' ? 'Adjuntar archivo' : 'Attacht file',
          icon: 'fas fa-paperclip',
          required: false,
          index: 1,
        }}
        onChange={onSetFile}
      />

      <TextField
        fullWidth
        multiline
        variant="outlined"
        minRows={4}
        maxRows={5}
        label={locale === 'es' ? 'Notas' : 'Notes'}
        value={event.notes}
        onChange={(e) =>
          setForm((form) => {
            form.event.notes = e.target.value
          })
        }
        classes={{ root: styles.textFieldRoot }}
      />
    </section>
  )
}

export default ScheduleModalEventExtra
