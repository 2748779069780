import SubscribeToProButton from 'components/SubscribeToProButton'
import useLocale from 'hooks/useLocale'
import React from 'react'
import PlanHeader from '../PlanHeader'
import RedeemCode from '../RedeemCode'
import Spacer from '../Spacer'
import styles from './FreePlan.module.scss'

const FreePlan = () => {
  const locale = useLocale()

  const paragraphContent =
    locale === 'es'
      ? 'La versión gratuita te da acceso limitado a ciertas herramientas de  DigitalPymes.'
      : 'The free version gives you limited access to some tools of DigitalPymes.'

  return (
    <div>
      <PlanHeader />

      <div className={styles.contentContainer}>
        <p>{paragraphContent}</p>
        <SubscribeToProButton />
      </div>

      <Spacer />
      <Spacer colorized={false} size={24} />

      <RedeemCode />
    </div>
  )
}

export default FreePlan
