import { createFreeTime, deleteFreeTime } from 'actions/humanResources'
import DataGridTable from 'components/DataGridTable/DataGridTable'
import Dialog from 'components/Dialog/Dialog'
import ButtonIcon from 'components/common/ButtonIcon'
import freeTimeTypes from 'constants/freeTimeTypes'
import { formatDate } from 'helpers/date'
import useLocale from 'hooks/useLocale'
import { useVacations } from 'hooks/useVacations'
import FreeTimeForm from 'pages/HumanResourcesPage/Tabs/Employees/FreeTimeModal/FreeTimeForm'
import DetailsModal from 'pages/HumanResourcesPage/Tabs/Employees/FreeTimeModal/Requests/DetailsModal/DetailsModal'
import License from 'pages/HumanResourcesPage/Tabs/Employees/FreeTimeModal/Requests/License/License'
import RequestHandler from 'pages/HumanResourcesPage/Tabs/Employees/FreeTimeModal/Requests/RequestHandler'
import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import Section from '../Section'
import styles from './styles.module.scss'

const Requests = ({ employee }) => {
  const dispatch = useDispatch()
  const locale = useLocale()
  const { idEmployee, HAS_VACATIONS, free_time: data } = employee
  const [open, setOpen] = useState(false)
  const [openDetails, setOpenDetails] = useState(false)
  const [selectedObjectId, setSelectedObjectId] = useState(null)
  const { availableVacations } = useVacations(employee)

  const vacationType = useCallback((value) => freeTimeTypes.get(value)?.['text']?.[locale], [locale])

  const selectedObject = useMemo(
    () => data?.filter((el) => el.idEmployeeFreeTime === selectedObjectId)?.[0],
    [data, selectedObjectId]
  )

  const handleOpen = (obj) => {
    setOpenDetails(true)
    setSelectedObjectId(obj.idEmployeeFreeTime)
  }

  const handleClose = () => {
    setOpenDetails(false)
    setTimeout(() => setSelectedObjectId(null), 200)
  }

  const handleCreateFreeTime = useCallback(
    async (data, setFreeTime) => {
      dispatch(await createFreeTime(data))
      setFreeTime({
        idEmployee,
        startTime: null,
        endTime: null,
        status: 1,
        type: 1,
      })
      setOpen(false)
    },
    [dispatch, idEmployee]
  )

  const COLUMNS = useMemo(
    () => [
      {
        headerName: locale === 'es' ? 'Nombre' : 'Name',
        field: 'type',
        width: 150,
        sortable: false,
        renderCell: ({ value }) => <div className={styles.nameCol}>{vacationType(value)}</div>,
      },
      {
        headerName: locale === 'es' ? 'Fecha de inicio' : 'Start time',
        field: 'startTime',
        width: 150,
        sortable: false,
        renderCell: ({ value }) => formatDate(value, 'DD/MM/YYYY'),
      },
      {
        headerName: locale === 'es' ? 'Fecha de fin' : 'End time',
        field: 'endTime',
        width: 150,
        sortable: false,
        renderCell: ({ value }) => formatDate(value, 'DD/MM/YYYY'),
      },
      {
        headerName: locale === 'es' ? 'Días' : 'Days',
        field: 'totalDays',
        width: 100,
        sortable: false,
        renderCell: ({ value }) => (+value === 0 ? 0.5 : +value),
      },
      {
        headerName: locale === 'es' ? 'Estado' : 'Status',
        field: 'status',
        width: 150,
        sortable: false,
        renderCell: ({ value, row }) => <RequestHandler value={value} obj={row} />,
      },
    ],
    [locale, vacationType]
  )

  const ACTIONS = useMemo(
    () => ({
      hideEdit: true,
      width: 150,
      field: 'idEmployeeFreeTime',
      queryDelete: deleteFreeTime,
      actions: [
        {
          name: locale === 'es' ? 'Ver detalles' : 'Details',
          icon: 'fas fa-eye',
          onClick: ({ selectedObject }) => handleOpen(selectedObject),
        },
      ],
    }),
    [locale]
  )

  return (
    <Section title={locale === 'es' ? 'Solicitudes' : 'Requests'}>
      <div className={styles.firstSection}>
        <ButtonIcon
          classes={{ root: styles.createButton }}
          icon={<i className="fas fa-circle-plus" />}
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
        >
          {locale === 'es' ? 'Crear solicitud manual' : 'Create manual request'}
        </ButtonIcon>

        <License employee={employee} />
      </div>

      <DataGridTable actionsConfig={ACTIONS} columns={COLUMNS} data={data} />

      <DetailsModal handleClose={handleClose} open={openDetails} data={selectedObject} />
      <Dialog
        title={locale === 'es' ? 'Crear solicitud manual' : 'Create manual request'}
        open={open}
        handleCloseAction={() => setOpen(false)}
      >
        <FreeTimeForm
          data={{
            idEmployee,
            startTime: null,
            endTime: null,
            status: 2,
            type: availableVacations !== 0 && HAS_VACATIONS ? 1 : 2,
          }}
          onSubmit={handleCreateFreeTime}
        />
      </Dialog>
    </Section>
  )
}

export default Requests
