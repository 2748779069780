import { DELETE_QUOTATION, GET_QUOTATIONS, UPDATE_QUOTATION, UPLOAD_QUOTATION } from 'constants/actionTypes'

import { QUOTATIONS_ROUTE } from 'constants/apiRoutes'
import { apiFindData, apiPostData, apiPutData, apiDeleteData } from 'helpers/api'
import { UPLOAD_QUOTATION_COMMENT } from '../constants/actionTypes'
import { QUOTATIONS_COMMENTS_ROUTE } from '../constants/apiRoutes'

export const getCompanyQuotations = (idCompany) => apiFindData(QUOTATIONS_ROUTE, { id: idCompany }, GET_QUOTATIONS)

export const postQuotation = (quotation) => apiPostData(QUOTATIONS_ROUTE, quotation, UPLOAD_QUOTATION)

export const updateQuotation = async (quotation) =>
  apiPutData(QUOTATIONS_ROUTE, { ...quotation, id: quotation?.idQuotation }, UPDATE_QUOTATION)

export const deleteQuotation = (idQuotation) => apiDeleteData(QUOTATIONS_ROUTE, { id: idQuotation }, DELETE_QUOTATION)

export const uploadQuotationComment = (comment) => apiPostData(QUOTATIONS_COMMENTS_ROUTE, comment, UPLOAD_QUOTATION_COMMENT)
