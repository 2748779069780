import { getDeductions, getDeparments, getEmployees, getPositions, getSchedules } from 'actions/humanResources'
import { getCompanyUsers } from 'actions/users'
import useCompany from 'hooks/useCompany'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

const useEmployees = () => {
  const dispatch = useDispatch()
  const { idCompany } = useCompany()

  useEffect(() => {
    const fetch = async () => {
      if (!idCompany) return

      dispatch(await getEmployees(idCompany))
    }

    fetch()
  }, [dispatch, idCompany])

  useEffect(() => {
    const fetch = async () => {
      if (!idCompany) return

      dispatch(await getDeparments(idCompany))
    }

    fetch()
  }, [dispatch, idCompany])

  useEffect(() => {
    const fetch = async () => {
      if (!idCompany) return

      dispatch(await getSchedules(idCompany))
    }

    fetch()
  }, [dispatch, idCompany])

  useEffect(() => {
    const fetch = async () => {
      if (!idCompany) return

      dispatch(await getCompanyUsers(idCompany))
    }

    fetch()
  }, [dispatch, idCompany])

  useEffect(() => {
    const fetch = async () => {
      if (!idCompany) return

      dispatch(await getPositions(idCompany))
    }

    fetch()
  }, [dispatch, idCompany])

  useEffect(() => {
    const fetch = async () => {
      if (!idCompany) return

      dispatch(await getDeductions(idCompany))
    }

    fetch()
  }, [dispatch, idCompany])
}

export default useEmployees
