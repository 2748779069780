import {
  DELETE_CALENDAR_ACTION,
  GET_CALENDAR_ACTIONS,
  SELECT_CALENDAR_ACTION,
  SELECT_CALENDAR_ACTION_CONTENT,
  UPDATE_CALENDAR_ACTION,
  UPLOAD_CALENDAR_ACTION,
  UPLOAD_CALENDAR_ACTION_COMMENT,
} from 'constants/actionTypes'
import { getRecurringDates } from 'helpers/date'

const initialState = {
  isLoadingCalendarActions: true,
  calendarActions: [],
  originalActions: [],
  selectedCalendarAction: null,
  selectedCalendarActionContent: null,
}

const handlers = {
  [GET_CALENDAR_ACTIONS]: (state, { payload = {} }) => {
    if (!payload?.calendarActions) return { ...state, errorInRequest: true }

    const calendarActions = getRecurringDates(payload.calendarActions)

    return {
      ...state,
      isLoadingCalendarActions: false,
      calendarActions,
      originalActions: payload.calendarActions.filter((el) => !el.idMarketingCampaign),
    }
  },
  [UPLOAD_CALENDAR_ACTION]: (state, { payload = {} }) => {
    if (!payload?.calendarAction) return { ...state, errorInRequest: true }

    const { IS_RECURRING } = payload.calendarAction

    const calendarActions = IS_RECURRING
      ? [...state.calendarActions, ...getRecurringDates([payload.calendarAction])]
      : [...state.calendarActions, payload.calendarAction]

    return { ...state, calendarActions: calendarActions }
  },
  [UPDATE_CALENDAR_ACTION]: (state, { payload = {} }) => {
    if (!payload?.calendarAction) return { ...state, errorInRequest: true }

    const { IS_RECURRING } = payload.calendarAction

    const calendarActions = IS_RECURRING
      ? state.calendarActions
          .filter(
            ({ idCalendarAction, originalId }) => (idCalendarAction ?? originalId) !== payload.calendarAction.idCalendarAction
          )
          .concat(getRecurringDates([payload.calendarAction]))
      : state.calendarActions.map((action) =>
          action.idCalendarAction === payload.calendarAction.idCalendarAction ? payload.calendarAction : action
        )

    return {
      ...state,
      calendarActions: calendarActions,
    }
  },
  [DELETE_CALENDAR_ACTION]: (state, { payload = {} }) => {
    if (!payload?.calendarAction) return { ...state, errorInRequest: true }

    return {
      ...state,
      calendarActions: state.calendarActions.filter(
        ({ idCalendarAction, originalId }) => (idCalendarAction ?? originalId) !== payload.calendarAction.idCalendarAction
      ),
    }
  },
  [UPLOAD_CALENDAR_ACTION_COMMENT]: (state, { payload = {} }) => {
    if (!payload?.comment) return { ...state, errorInRequest: true }

    const calendarActionId = payload?.comment.idCalendarAction

    const calendarActions = state.calendarActions.map((_action) => {
      if (_action.idCalendarAction === calendarActionId) {
        return {
          ..._action,
          comments: [...(_action.comments ?? []), payload.comment],
        }
      } else return _action
    })
    const selectedAction = calendarActions.find((_calendarAction) => _calendarAction.idCalendarAction === calendarActionId)

    return { ...state, calendarActions: calendarActions, selectedCalendarActionContent: selectedAction }
  },
  [SELECT_CALENDAR_ACTION]: (state, { payload }) => {
    return {
      ...state,
      selectedCalendarAction: payload,
    }
  },
  [SELECT_CALENDAR_ACTION_CONTENT]: (state, { payload }) => {
    return {
      ...state,
      selectedCalendarActionContent: payload,
    }
  },
}

const calendarActions = (state = initialState, action) => {
  const { type } = action

  return handlers[type] ? handlers[type](state, action) : state
}

export default calendarActions
