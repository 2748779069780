import { useLayoutEffect, useState } from 'react'

const useIsOverflowing = (ref) => {
  const [overflowY, setOverflowY] = useState(undefined)
  const [overflowX, setOverflowX] = useState(undefined)

  useLayoutEffect(() => {
    const { current } = ref ?? {}

    const trigger = () => {
      const hasOverflowY = current.scrollHeight > current.clientHeight
      const hasOverflowX = current.scrollWidth > current.clientWidth

      setOverflowY(hasOverflowY)
      setOverflowX(hasOverflowX)
    }

    if (current) {
      if ('ResizeObserver' in window) {
        new ResizeObserver(trigger).observe(current)
      }

      trigger()
    }
  }, [ref])

  return { overflowX, overflowY }
}

export default useIsOverflowing
