import { Tooltip } from '@material-ui/core'
import classNames from 'classnames'
import useLocale from 'hooks/useLocale'
import SelectWhite from 'pages/DatanalisisPage/components/SelectWhite'
import TextFieldWhite from 'pages/DatanalisisPage/components/TextFieldWhite'
import React from 'react'
import styles from './ValueItem.module.scss'

const ValueItem = ({ value, index, fieldsOptions, onDeleteValue, onChangeValue }) => {
  const locale = useLocale()

  return (
    <div className={styles.valueItemWrapper}>
      <header className={styles.valueItemHeader}>
        <strong className={styles.valueItemTitle}>
          {locale === 'es' ? 'Valor' : 'Value'} #{++index}
        </strong>

        <Tooltip arrow placement="top" title={locale === 'es' ? 'Eliminar' : 'Delete'} onClick={onDeleteValue}>
          <i className={classNames('fas fa-trash-alt', styles.deleteIcon)} />
        </Tooltip>
      </header>

      <SelectWhite
        field={{
          label: locale === 'es' ? 'Valor' : 'Value',
          name: 'key',
        }}
        options={fieldsOptions}
        onChange={onChangeValue}
        value={value.key}
      />

      <TextFieldWhite name="header" label={locale === 'es' ? 'Titulo' : 'Title'} value={value.header} onChange={onChangeValue} />
    </div>
  )
}

export default ValueItem
