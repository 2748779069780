import {
  GET_INFO_PAGES,
  GET_INFO_QUESTIONS,
  UPLOAD_INFO_PAGE,
  UPLOAD_INFO_QUESTION,
  UPDATE_INFO_PAGE,
  UPDATE_INFO_QUESTION,
  DELETE_INFO_PAGE,
  DELETE_INFO_QUESTION,
  GET_INFO_ANSWER,
  UPLOAD_INFO_ANSWER,
  UPDATE_INFO_ANSWER,
  UPDATE_COMPANY_INFO_QUESTIONS_POSITIONS,
  UPDATE_INFO_QUESTION_OPTION,
  DELETE_INFO_QUESTION_OPTION,
} from 'constants/actionTypes'

const initialState = {
  isLoadingCompanyPages: true,
  isLoadingCompanyQuestions: true,
  isLoadingCompanyAnswers: true,
  errorInRequest: false,
  companyInfoPages: [],
  companyInfoQuestions: [],
  companyAnswers: [],
}

const handlers = {
  [GET_INFO_PAGES]: (state, { payload = {} }) => {
    if (!payload?.pages) return { ...state, errorInRequest: true, isLoadingCompanyPages: false }

    return { ...state, companyInfoPages: payload?.pages, errorInRequest: false, isLoadingCompanyPages: false }
  },
  [UPLOAD_INFO_PAGE]: (state, { payload = {} }) => {
    if (!payload?.page) return { ...state, errorInRequest: true }

    return { ...state, companyInfoPages: state.companyInfoPages.concat(payload?.page), errorInRequest: false }
  },
  [UPDATE_INFO_PAGE]: (state, { payload = {} }) => {
    if (!payload?.page) return { ...state, errorInRequest: true }

    return {
      ...state,
      companyInfoPages: state.companyInfoPages.map((page) =>
        page.idCompanyInfoPage === payload?.page?.idCompanyInfoPage ? payload?.page : page
      ),
      errorInRequest: false,
    }
  },
  [DELETE_INFO_PAGE]: (state, { payload = {} }) => {
    if (!payload?.page) return { ...state, errorInRequest: true }

    return {
      ...state,
      companyInfoPages: payload.pages.filter(({ idCompanyInfoPage }) => idCompanyInfoPage !== payload?.page?.idCompanyInfoPage),
      errorInRequest: false,
    }
  },
  [GET_INFO_QUESTIONS]: (state, { payload = {} }) => {
    if (!payload?.questions) return { ...state, errorInRequest: true, isLoadingCompanyQuestions: false }

    const questions = payload?.questions.reduce((acc, question) => {
      acc.push({
        ...question.question,
        options: question.options,
      })
      return acc
    }, [])

    return { ...state, companyInfoQuestions: questions, errorInRequest: false, isLoadingCompanyQuestions: false }
  },
  [UPLOAD_INFO_QUESTION]: (state, { payload = {} }) => {
    if (!payload?.question) return { ...state, errorInRequest: true }

    return {
      ...state,
      companyInfoQuestions: state.companyInfoQuestions.concat({
        ...payload?.question,
        options: [],
      }),
      errorInRequest: false,
    }
  },
  [UPDATE_INFO_QUESTION]: (state, { payload = {} }) => {
    if (!payload?.question) return { ...state, errorInRequest: true }

    return {
      ...state,
      companyInfoQuestions: state.companyInfoQuestions.map((question) =>
        question.idCompanyInfoQuestion === payload?.question?.idCompanyInfoQuestion
          ? {
              ...payload?.question,
              options: question?.options,
            }
          : question
      ),
      errorInRequest: false,
    }
  },
  [DELETE_INFO_QUESTION]: (state, { payload = {} }) => {
    if (!payload?.question) return { ...state, errorInRequest: true }

    return {
      ...state,
      companyInfoQuestions: state.companyInfoQuestions.filter(
        ({ idCompanyInfoQuestion }) => idCompanyInfoQuestion !== payload?.question?.idCompanyInfoQuestion
      ),
      errorInRequest: false,
    }
  },
  [GET_INFO_ANSWER]: (state, { payload = {} }) => {
    if (!payload?.answers) return { ...state, errorInRequest: true }

    return {
      ...state,
      isLoadingCompanyAnswers: false,
      companyAnswers: payload?.answers,
    }
  },
  [UPLOAD_INFO_ANSWER]: (state, { payload = {} }) => {
    if (!payload?.answer) return { ...state, errorInRequest: true }

    return {
      ...state,
      companyAnswers: state?.companyAnswers?.concat(payload?.answer),
    }
  },
  [UPDATE_INFO_ANSWER]: (state, { payload = {} }) => {
    if (!payload?.answer) return { ...state, errorInRequest: true }

    return {
      ...state,
      companyAnswers: state?.companyAnswers?.map((answer) =>
        answer.idCompanyInfoAnswer === payload?.answer?.idCompanyInfoAnswer ? payload?.answer : answer
      ),
    }
  },
  [UPDATE_COMPANY_INFO_QUESTIONS_POSITIONS]: (state, { payload = {} }) => {
    if (!payload?.questions) return { ...state, errorInRequest: true }

    const incoming = payload.questions
    const reordered = state.companyInfoQuestions.map((actual) => {
      const filtered = incoming.filter((question) => question.idCompanyInfoQuestion === actual.idCompanyInfoQuestion)

      return filtered.length ? filtered[0] : actual
    })

    return {
      ...state,
      companyInfoQuestions: reordered,
      errorInRequest: false,
    }
  },
  [UPDATE_INFO_QUESTION_OPTION]: (state, { payload = {} }) => {
    if (payload?.edit) {
      return {
        ...state,
        companyInfoQuestions: state.companyInfoQuestions.map((question) =>
          question?.idCompanyInfoQuestion === payload?.question?.idCompanyInfoQuestion
            ? {
                ...payload?.question,
                options: question?.options?.map((option) =>
                  option?.idOption === payload?.question?.option?.idOption ? payload?.question?.option : option
                ),
              }
            : question
        ),
      }
    }

    return {
      ...state,
      companyInfoQuestions: state.companyInfoQuestions.map((question) =>
        question.idCompanyInfoQuestion === payload?.question?.idCompanyInfoQuestion
          ? {
              ...payload?.question,
              options: [...question.options, payload?.question?.option],
            }
          : question
      ),
    }
  },
  [DELETE_INFO_QUESTION_OPTION]: (state, { payload = {} }) => {
    return {
      ...state,
      companyInfoQuestions: state.companyInfoQuestions.map((question) =>
        question.idCompanyInfoQuestion === payload?.question?.idCompanyInfoQuestion
          ? {
              ...payload?.question,
              options: question.options.filter((o) => o.idOption !== payload?.question?.id),
            }
          : question
      ),
    }
  },
}

const companyInformation = (state = initialState, action) => {
  const { type } = action

  return handlers[type] ? handlers[type](state, action) : state
}

export default companyInformation
