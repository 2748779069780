import {
  DELETE_CALENDAR_ACTION,
  GET_CALENDAR_ACTIONS,
  SELECT_CALENDAR_ACTION,
  SELECT_CALENDAR_ACTION_CONTENT,
  UPDATE_CALENDAR_ACTION,
  UPLOAD_CALENDAR_ACTION,
  UPLOAD_CALENDAR_ACTION_COMMENT,
} from 'constants/actionTypes'
import { CALENDAR_ACTIONS_COMMENT_ROUTE, CALENDAR_ACTIONS_ROUTE, CALENDAR_EVENTS_ROUTE } from 'constants/apiRoutes'
import { apiDeleteData, apiFindData, apiPostData, apiPostFormDataWithData, apiPutData } from 'helpers/api'
import { validateCalendarActionForm } from 'helpers/formValidation'
import { postMarketingCampaignTask } from './marketingCampaigns'
import { scheduleMassEmail } from './messages'
import { schedulePost } from './socialmedia'

export const getCalendarActions = (idCompany) => apiFindData(CALENDAR_ACTIONS_ROUTE, { id: idCompany }, GET_CALENDAR_ACTIONS)

export const updateCalendarAction = (calendarAction) => apiPutData(CALENDAR_ACTIONS_ROUTE, calendarAction, UPDATE_CALENDAR_ACTION)

export const deleteCalendarAction = (idCalendarAction) =>
  apiDeleteData(CALENDAR_ACTIONS_ROUTE, { id: idCalendarAction }, DELETE_CALENDAR_ACTION)

export const postCalendarAction = (postAction) => apiPostData(CALENDAR_ACTIONS_ROUTE, postAction, UPLOAD_CALENDAR_ACTION)

export const uploadCalendarActionComment = (comment) =>
  apiPostData(CALENDAR_ACTIONS_COMMENT_ROUTE, comment, UPLOAD_CALENDAR_ACTION_COMMENT)

export const uploadCalendarEvent = (event) => apiPostFormDataWithData(CALENDAR_EVENTS_ROUTE, event)

// SELECTORS
export const selectCalendarAction = (calendarAction) => ({
  type: SELECT_CALENDAR_ACTION,
  payload: calendarAction,
})

export const selectCalendarActionContent = (calendarActionContent) => ({
  type: SELECT_CALENDAR_ACTION_CONTENT,
  payload: calendarActionContent,
})

// CALENDAR ACTION SCHEDULE BY TYPE
export const scheduleActionMassEmail = async (form, dispatch, handleClose) => {
  const isValidated = validateCalendarActionForm(form, dispatch)
  if (!isValidated) return

  const { general, massEmail } = form

  const massEmailResponse = await scheduleMassEmail(massEmail)
  const actionResponse = await postCalendarAction({ ...general, idMassEmail: massEmailResponse.payload.massEmail.idMassEmail })

  dispatch(actionResponse)
  handleClose()
  dispatch(massEmailResponse)
}

export const scheduleActionPost = async (form, dispatch, handleClose) => {
  const isValidated = validateCalendarActionForm(form, dispatch)
  if (!isValidated) return

  const { general, post } = form

  const postResponse = await schedulePost(post)
  const actionResponse = await postCalendarAction({ ...general, idPost: postResponse.payload.post.idPost })

  dispatch(actionResponse)
  handleClose()
  dispatch(postResponse)
}

export const scheduleActionCampaignTask = async (form, dispatch, handleClose) => {
  const isValidated = validateCalendarActionForm(form, dispatch)
  if (!isValidated) return

  const { general, marketingTask } = form

  const marketingCamapignTaskResponse = await postMarketingCampaignTask(marketingTask)
  const actionResponse = await postCalendarAction({
    ...general,
    idMarketingCampaign: marketingCamapignTaskResponse.marketingCampaignTask.idMarketingCampaign,
    idMarketingCampaignTask: marketingCamapignTaskResponse.marketingCampaignTask.idMarketingCampaignTask,
  })

  dispatch(actionResponse)
  handleClose()
}

export const scheduleActionEvent = async (form, dispatch, handleClose) => {
  const isValidated = validateCalendarActionForm(form, dispatch)
  if (!isValidated) return

  const { general, event, idMarketingCampaign } = form

  const calendarEventResponse = await uploadCalendarEvent(event)
  const actionResponse = await postCalendarAction({
    ...general,
    idMarketingCampaign: idMarketingCampaign ?? null,
    idCalendarEvent: calendarEventResponse?.event?.idCalendarEvent,
  })

  dispatch(actionResponse)
  handleClose()
}
