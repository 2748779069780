import { sendErrorMessage, sendSuccessMessage } from 'actions/modals'
import { uploadReportError } from 'actions/reportErrors'
import { UPLOAD_REPORT_ERROR } from 'constants/actionTypes'
import getReportErrorStatuses from 'constants/reportErrorModalStatuses'
import useLocale from 'hooks/useLocale'
import useUsers from 'hooks/useUsers'
import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import getContent from './content'

const INITIAL_MODAL_DATA = {
  description: '',
  idUser: null,
  status: null,
  files: [],
}

const useReportErrorModal = (open, setOpen) => {
  const dispatch = useDispatch()
  const locale = useLocale()
  const content = getContent(locale)
  const reportErrorStatuses = getReportErrorStatuses(locale)
  const { myUser } = useUsers() ?? {}
  const [modalData, setModalData] = useState(INITIAL_MODAL_DATA)
  const [uploadedFiles, setUploadedFiles] = useState([])

  const onChangeDescription = useCallback(
    (event) => {
      setModalData({ ...modalData, description: event.target.value })
    },
    [modalData]
  )

  const onChangeFiles = useCallback(
    (event) => {
      setUploadedFiles(Array.from(event?.target?.files))
      setModalData({ ...modalData, files: event?.target?.files })
    },
    [modalData]
  )

  const onClose = useCallback(() => {
    setModalData(INITIAL_MODAL_DATA)
    setUploadedFiles([])
    setOpen(false)
  }, [setOpen])

  const onSubmit = useCallback(
    async (event) => {
      const newReportError = new FormData()

      newReportError.append('description', modalData?.description)
      newReportError.append('idUser', myUser?.idUser)
      newReportError.append('status', reportErrorStatuses.PENDING.value)

      for (let i = 0; i < modalData.files.length; i++) {
        newReportError.append(`file-${i}`, modalData.files[i])
      }

      newReportError.forEach((value, key) => {
        console.log(key, typeof value)
      })

      if (!modalData?.description.trim().length) {
        return dispatch(sendErrorMessage(content?.invalidReportError))
      }

      dispatch(sendSuccessMessage(content?.successReportMessage))
      onClose()

      const response = (await uploadReportError(newReportError)) ?? {}
      dispatch({
        type: UPLOAD_REPORT_ERROR,
        payload: {
          reportError: response?.data?.reportError,
        },
      })
    },
    [modalData, myUser, dispatch, reportErrorStatuses, onClose, content]
  )

  return {
    states: {
      modalData,
      uploadedFiles,
    },
    handlers: {
      onChangeDescription,
      onChangeFiles,
      onSubmit,
      onClose,
    },
  }
}

export default useReportErrorModal
