import { Tooltip } from '@material-ui/core'
import { deleteCalendarAction } from 'actions/calendarActions'
import { confirmDialog, sendErrorMessage, sendSuccessMessage } from 'actions/modals'
import classNames from 'classnames'
import { isBefore } from 'date-fns'
import { isToday } from 'helpers/date'
import useIsOverflowing from 'hooks/useIsOverflowing'
import useLocale from 'hooks/useLocale'
import React, { useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import styles from './DayItem.module.scss'

const _getBackgrounColor = (action) => {
  const { idMassEmail, idPost, idMarketingCampaignTask, idCalendarEvent } = action

  if (idMassEmail) return '#9A9B9D'
  else if (idPost) return '#8CC0D6'
  else if (idMarketingCampaignTask) return '#E7A748'
  else if (idCalendarEvent) return '#99cf90'
  else return ''
}

const CalendarChip = ({ onClick, action }) => {
  const dispatch = useDispatch()
  const locale = useLocale()

  const [isHover, setIsHover] = useState(false)
  const ref = useRef()
  const { overflowX } = useIsOverflowing(ref)

  const handleDelete = async (e) => {
    e.stopPropagation()
    dispatch(
      confirmDialog({
        message: action.copy
          ? locale === 'es'
            ? 'Si borra este registro, se eliminarán las acciones pasadas y las siguientes. Desea continuar?'
            : 'If you delete this register, all the past and present actions will be deleted. Do you want to continue?'
          : locale === 'es'
          ? '¿Está seguro de querer eliminar este registro?'
          : 'Are you sure you want to delete this register?',
        handlerAction: async () => {
          const res = await deleteCalendarAction(action?.idCalendarAction ?? action?.originalId)

          if (res?.payload?.error)
            return dispatch(
              sendErrorMessage(locale === 'es' ? 'No se ha podido eliminar el elemento' : 'Item could not be removed')
            )

          dispatch(res)
          dispatch(sendSuccessMessage(locale === 'es' ? 'Acción eliminada' : 'Action deleted'))
        },
      })
    )
  }

  return (
    <Tooltip title={overflowX ? action.name : ''} arrow placement="top">
      <div
        ref={ref}
        onMouseEnter={() => setIsHover(true)}
        onMouseDown={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        className={styles.action}
        style={{ backgroundColor: _getBackgrounColor(action) }}
        onClick={(e) => onClick(e, action)}
      >
        {action.name}
        {isHover && <i className={classNames(['fas fa-times-circle', styles.deleteIcon])} onClick={handleDelete} />}
      </div>
    </Tooltip>
  )
}

const DayItem = (props) => {
  const locale = useLocale()
  const {
    basicStyles: { day: dayClassName, inactive: inactiveClassName, today: todayClassName, dayText: dayTextClassName },
    day,
    year,
    month,
    isInactiveDay,
    openScheduleAction,
    calendarActions,
    onOpenActionContent,
  } = props

  const filteredCalendarActions = useMemo(() => {
    const filtered = calendarActions.filter((_action) => {
      const actionDate = new Date(_action.startDate)

      const actionDay = actionDate.getDate()
      const actionMonth = actionDate.getMonth() + 1
      const actionYear = actionDate.getFullYear()

      return actionDay === day && actionMonth === month && actionYear === year && !isInactiveDay
    })

    return filtered
  }, [calendarActions, day, month, year, isInactiveDay])

  const date = !isInactiveDay && `${year}-${month}-${day}`
  const today = isToday(date)
  const isPastDate = today ? false : isBefore(new Date(date), new Date())

  return (
    <div className={classNames([dayClassName, isInactiveDay && inactiveClassName, today && todayClassName, styles.day])}>
      <p className={dayTextClassName}>{day}</p>

      {Boolean(filteredCalendarActions.length) &&
        filteredCalendarActions?.map((_action) => (
          <CalendarChip
            key={_action.idCalendarAction}
            onClick={(e) => {
              e.stopPropagation()
              onOpenActionContent(_action)
            }}
            action={_action}
          />
        ))}

      {Boolean(!isInactiveDay) && !isPastDate && (
        <Tooltip
          title={locale === 'es' ? 'Agendar' : 'Schedule'}
          arrow
          classes={{ tooltip: styles.tooltipColors, tooltipArrow: styles.tooltipColors }}
          onClick={() => openScheduleAction(day, month, year)}
        >
          <i className={classNames(['fas fa-plus-circle', styles.addButton])} />
        </Tooltip>
      )}
    </div>
  )
}

export default DayItem
