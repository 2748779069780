import {
  GET_REPORT_ERRORS,
  SET_REPORT_ERROR_LOADING_STATE,
  UPDATE_REPORT_ERROR,
  UPLOAD_REPORT_ERROR,
} from 'constants/actionTypes'
import { REPORT_ERRORS_ROUTE } from 'constants/apiRoutes'
import { apiGetData, apiPostFile, apiPutData } from 'helpers/api'

export const getReportErrors = () => apiGetData(REPORT_ERRORS_ROUTE, {}, GET_REPORT_ERRORS)

export const uploadReportError = (reportError) => apiPostFile(REPORT_ERRORS_ROUTE, reportError, UPLOAD_REPORT_ERROR)

export const updateReportError = (reportError) =>
  apiPutData(REPORT_ERRORS_ROUTE, { id: reportError?.idError, ...reportError }, UPDATE_REPORT_ERROR)

export const setReportErrorLoadingState = (loadingState) => ({ type: SET_REPORT_ERROR_LOADING_STATE, payload: loadingState })
