import classNames from 'classnames'
import React from 'react'
import styles from './Spacer.module.scss'

const Spacer = ({ size = 48, colorized = true }) => {
  return (
    <figure
      className={classNames(styles.main, colorized && styles.colorized)}
      style={{
        '--size': `${size}px`,
      }}
    />
  )
}

export default Spacer
