import { GET_FB_PAGES, GET_POSTS, SET_NEW_POST } from 'constants/actionTypes'
import {
  DELETE_FB_PAGES_BY_TOKEN_API_ROUTE,
  GET_FB_PAGES_API_ROUTE,
  GET_FB_PAGES_BY_TOKEN_API_ROUTE,
  POSTS_API_ROUTE,
  SCHEDULE_POST_API_ROUTE,
  STORE_IMAGE_API_ROUTE,
} from 'constants/apiRoutes'
import { apiFindData, apiPostData, apiPostImage } from 'helpers/api'
import { getApiStoreImageRoute } from 'helpers/apiStoreImages'

const SOCIAL_NETWORKS = { 1: [1], 2: [2], 3: [1, 2] }

export const getFbPagesByToken = (fbUser) => apiPostData(GET_FB_PAGES_BY_TOKEN_API_ROUTE, fbUser, GET_FB_PAGES)

export const getFbPages = (idCompany) => apiFindData(GET_FB_PAGES_API_ROUTE, { id: idCompany }, GET_FB_PAGES)

export const getPosts = (idCompany) => apiFindData(POSTS_API_ROUTE, { id: idCompany }, GET_POSTS)

export const addNewPost = async (post) => {
  const socialNetworks = SOCIAL_NETWORKS?.[Number(post?.socialNetworks)] ?? SOCIAL_NETWORKS[1]
  const postImage = post?.image_url

  if (!postImage) return apiPostData(POSTS_API_ROUTE, { ...post, socialNetworks }, SET_NEW_POST)

  const serverImageUrl = await apiPostImage(STORE_IMAGE_API_ROUTE, { image: postImage })
  const imageRoute = getApiStoreImageRoute(serverImageUrl)

  return apiPostData(POSTS_API_ROUTE, { ...post, image_url: imageRoute, socialNetworks }, SET_NEW_POST)
}

export const schedulePost = async (post, dispatch) => {
  const socialNetworks = SOCIAL_NETWORKS?.[Number(post?.socialNetworks)] ?? SOCIAL_NETWORKS[1]
  const postImage = post?.image_url

  if (!postImage) {
    const schedulePost = await apiPostData(SCHEDULE_POST_API_ROUTE, { ...post, socialNetworks, isPending: true }, SET_NEW_POST)

    return schedulePost
  }

  const serverImageUrl = await apiPostImage(STORE_IMAGE_API_ROUTE, { image: postImage })
  const imageRoute = getApiStoreImageRoute(serverImageUrl)

  const schedulePost = await apiPostData(
    SCHEDULE_POST_API_ROUTE,
    { ...post, image_url: imageRoute, socialNetworks },
    SET_NEW_POST
  )

  return schedulePost
}

export const deleteFbPagesByToken = (fbUser) => apiPostData(DELETE_FB_PAGES_BY_TOKEN_API_ROUTE, fbUser, GET_FB_PAGES)
