import DB_TABLES_MODELS from 'constants/dbTablesModels'

const useDbModel = (indicator = {}) => {
  const { table, subTable } = indicator
  const key = subTable ? `${table}/${subTable}` : table

  const dbModel = DB_TABLES_MODELS[key] ?? {}

  return dbModel
}

export default useDbModel
