import usePersonalFinances from 'hooks/usePersonalFinances'
import usePersonalFinancesPage from 'pages/PersonalFinancesPage/hooks/usePersonalFinancesPage'
import { createContext, useCallback, useMemo, useState } from 'react'

export const PersonalFinancesContext = createContext({})

const PersonalFinancesContextProvider = ({ children }) => {
  usePersonalFinancesPage()
  const { personalFinanceTransactions, personalFinanceSavings, personalFinanceCards } = usePersonalFinances()

  const [currency, setCurrency] = useState(() => localStorage.getItem('currency') || 'crc')
  const [searchFilter, setSearchFilter] = useState('')
  const [categoryFilter, setCategoryFilter] = useState('')
  const [isOpenTransactionModal, setIsOpenTransactionModal] = useState(false)
  const [selectedTransaction, setSelectedTransaction] = useState({})

  const [isOpenSavingsModal, setIsOpenSavingsModal] = useState(false)
  const [isOpenDepositSavingsModal, setIsOpenDepositSavingsModal] = useState(false)
  const [isOpenWithdrawSavingsModal, setIsOpenWithdrawSavingsModal] = useState(false)
  const [selectedSaving, setSelectedSaving] = useState({})

  const [isOpenCreditModal, setIsOpenCreditModal] = useState(false)
  const [selectedCredit, setSelectedCredit] = useState({})

  const [selectedCardId, setSelectedCardId] = useState(null)
  const selectedCard =
    personalFinanceCards?.find(({ idPersonalFinanceCard }) => +idPersonalFinanceCard === +selectedCardId) || null

  const currentTransactions = useMemo(() => {
    const filteredTransactionsBySearch = personalFinanceTransactions.filter((_transaction) =>
      _transaction.name.toLowerCase().includes(searchFilter.toLocaleLowerCase())
    )

    const filteredTransactionsByCategory = categoryFilter
      ? filteredTransactionsBySearch.filter((_transaction) => _transaction.category === categoryFilter)
      : filteredTransactionsBySearch

    if (selectedCard) {
      return filteredTransactionsByCategory.filter(
        (_transaction) => _transaction?.card?.idPersonalFinanceCard === selectedCard?.idPersonalFinanceCard
      )
    }

    return filteredTransactionsByCategory
  }, [personalFinanceTransactions, selectedCard, searchFilter, categoryFilter])

  const currentSavings = useMemo(() => {
    const filteredSavingsBySearch = personalFinanceSavings.filter((_transaction) =>
      _transaction.name.toLowerCase().includes(searchFilter.toLocaleLowerCase())
    )
    const filteredSavingsByCategory = categoryFilter
      ? filteredSavingsBySearch.filter((_transaction) => _transaction.category === categoryFilter)
      : filteredSavingsBySearch

    if (selectedCard) {
      return filteredSavingsByCategory.filter(
        (_transaction) => _transaction?.card?.idPersonalFinanceCard === selectedCard?.idPersonalFinanceCard
      )
    }

    return filteredSavingsByCategory
  }, [personalFinanceSavings, selectedCard, searchFilter, categoryFilter])

  const handleSearchFilter = useCallback((event) => setSearchFilter(event.target.value), [])
  const handleCategoryFilter = useCallback((event) => setCategoryFilter(event.target.value), [])

  const handleToggleCard = useCallback(
    (card) => {
      if (!selectedCard || card?.idPersonalFinanceCard !== selectedCard?.idPersonalFinanceCard) {
        return setSelectedCardId(card?.idPersonalFinanceCard)
      }

      return setSelectedCardId(null)
    },
    [selectedCard]
  )

  const handleUnselectCard = useCallback(() => {
    setSelectedCardId(null)
  }, [])

  const handleOpenTransactionModal = useCallback((transaction) => {
    setSelectedTransaction(transaction)
    setIsOpenTransactionModal(true)
  }, [])

  const handleCloseTransactionModal = useCallback((action) => {
    if (typeof action === 'function') action()

    setSelectedTransaction({})
    setIsOpenTransactionModal(false)
  }, [])

  const handleOpenSavingsModal = useCallback(() => {
    setIsOpenSavingsModal(true)
  }, [])

  const handleCloseSavingsModal = useCallback(() => setIsOpenSavingsModal(false), [])

  const handleOpenDepositSavingsModal = useCallback((saving) => {
    setSelectedSaving(saving)
    setIsOpenDepositSavingsModal(true)
  }, [])

  const handleCloseDepositSavingsModal = useCallback(() => {
    setSelectedSaving({})
    setIsOpenDepositSavingsModal(false)
  }, [])

  const handleOpenWithdrawSavingsModal = useCallback((saving) => {
    setSelectedSaving(saving)
    setIsOpenWithdrawSavingsModal(true)
  }, [])

  const handleCloseWithdrawSavingsModal = useCallback(() => {
    setSelectedSaving({})
    setIsOpenWithdrawSavingsModal(false)
  }, [])

  const handleOpenCreditModal = useCallback((credit) => {
    setSelectedCredit(credit)
    setIsOpenCreditModal(true)
  }, [])

  const handleCloseCreditModal = useCallback(() => {
    setSelectedCredit({})
    setIsOpenCreditModal(false)
  }, [])

  const providerValues = {
    handlers: {
      setIsOpenTransactionModal,
      handleOpenTransactionModal,
      handleCloseTransactionModal,
      handleOpenSavingsModal,
      handleCloseSavingsModal,
      handleOpenDepositSavingsModal,
      handleCloseDepositSavingsModal,
      handleOpenWithdrawSavingsModal,
      handleCloseWithdrawSavingsModal,
      handleOpenCreditModal,
      handleCloseCreditModal,
      handleToggleCard,
      handleUnselectCard,
      handleSearchFilter,
      handleCategoryFilter,
      setCurrency,
    },
    states: {
      isOpenTransactionModal,
      isOpenSavingsModal,
      isOpenDepositSavingsModal,
      isOpenWithdrawSavingsModal,
      isOpenCreditModal,
      selectedCredit,
      selectedTransaction,
      selectedSaving,
      selectedCard,
      currentSavings,
      currentTransactions,
      searchFilter,
      categoryFilter,
      currency,
    },
  }
  return <PersonalFinancesContext.Provider value={providerValues}>{children}</PersonalFinancesContext.Provider>
}

export default PersonalFinancesContextProvider
