import { Outlet } from 'react-router-dom'
import AuthValidation from 'components/AuthValidation/AuthValidation'
import Header from 'components/Header/Header'
import HeaderMenu from 'components/HeaderMenu/HeaderMenu'
import ModalsControler from 'components/ModalsControler/ModalsControler'
import { isMobileOrTablet } from 'helpers/breackpoints'
import useScreen from 'hooks/useScreen'
import { Suspense } from 'react'
import { Fallback } from 'index'

const DashboardLayout = () => {
  const { breackpoint } = useScreen()

  return (
    <AuthValidation>
      <Header />
      {!isMobileOrTablet(breackpoint) && <HeaderMenu />}

      <Suspense fallback={<Fallback />}>
        <Outlet />
      </Suspense>

      <ModalsControler />
    </AuthValidation>
  )
}

export default DashboardLayout
