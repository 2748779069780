import MissingPage from 'components/MissingPage'
import { useHideSubscriptionModule } from 'hooks/useHideSubscriptionModule'
import useLocale from 'hooks/useLocale'
import useSubscription from 'hooks/useSubscription'
import useUsers from 'hooks/useUsers'
import Page from 'layouts/Page/Page'
import React from 'react'
import FreePlan from './FreePlan'
import styles from './MySubscriptionPage.module.scss'
import ProPlan from './ProPlan'
import SectionHeader from './SectionHeader'

const MySubscriptionPage = () => {
  const locale = useLocale()
  const { myUser } = useUsers()
  const { isCompanyOwner } = myUser
  const { isProUser } = useSubscription()
  const hideSubscriptionModule = useHideSubscriptionModule()

  const title = locale === 'es' ? 'Mi Suscripción' : 'My subscription'

  if (!isCompanyOwner || hideSubscriptionModule) {
    return <MissingPage />
  }

  return (
    <Page title={title}>
      <h1>{title}</h1>

      <div className={styles.wrapper}>
        <SectionHeader
          title={
            <>
              {locale === 'es' ? 'Plan actual' : 'Actual plan'}
              <i className="fas fa-crown" />
            </>
          }
        />

        {isProUser ? <ProPlan /> : <FreePlan />}

        {/* <FreePlan /> */}
        {/* <CouponPlan /> */}
      </div>
    </Page>
  )
}

export default MySubscriptionPage
