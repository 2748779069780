import React from 'react'
import classNames from 'classnames'
import styles from './ContentItem.module.scss'

const ContentItem = ({
  title,
  content,
  centered = false,
  noMarginBottom = false,
  borderInline = false,
  borderLeft = false,
  borderRight = false,
  className = '',
}) => (
  <div
    className={classNames([
      styles.contentItem,
      centered && styles.alignCenter,
      noMarginBottom && styles.marginBottom0,
      borderInline && styles.borderInline,
      borderLeft && styles.borderLeft,
      borderRight && styles.borderRight,
      className,
    ])}
  >
    <strong>{title}</strong>
    {content}
  </div>
)

export default ContentItem
