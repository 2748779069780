import useUsers from './useUsers'
import useArray from './useArray'
import PERMISSIONS from 'constants/permissions'
import useRoles from './useRoles'

const usePermissions = () => {
  const { isOnArray, findItem } = useArray()
  const { myUser } = useUsers() ?? {}
  const { roles } = useRoles()
  const { idRole, permissions } = myUser ?? {}

  const role = findItem(roles, idRole, 'idCustomRole')
  const { IS_ACTIVE: isRoleActive } = role ?? {}

  const getPermissions = () => {
    if (idRole === 1) {
      // Role Admin

      return PERMISSIONS.reduce((acc, item) => {
        const read = item.permissions.read
        const write = item.permissions.write

        return { ...acc, [write]: true, [read]: true }
      }, {})
    } else if (!isRoleActive || idRole === 2) {
      // Role Default

      return PERMISSIONS.reduce((acc, item) => {
        const module = item.name
        const read = item.permissions.read
        const write = item.permissions.write

        if (module.split(' ').join('').toLocaleLowerCase() === 'mycompany') return { ...acc, [write]: false, [read]: true }

        return { ...acc, [write]: true, [read]: true }
      }, {})
    } else {
      // Other Role

      return PERMISSIONS.reduce((acc, item) => {
        const read = item.permissions.read
        const write = item.permissions.write

        return { ...acc, [write]: isOnArray(permissions ?? [], write), [read]: isOnArray(permissions ?? [], read) }
      }, {})
    }
  }

  const pagePermissions = (read, write) => {
    const userPermissions = getPermissions()

    if (!read && !write) return [true, true]

    return [userPermissions[read], userPermissions[write]]
  }

  return {
    pagePermissions,
    getPermissions,
  }
}

export default usePermissions
