import { Link, useLocation } from 'react-router-dom'
import styles from './MenuLink.module.scss'

const MenuLink = ({ route }) => {
  const { pathname } = useLocation()

  return (
    <Link to={route.link}>
      <span className={`${styles.navigationLink} ${pathname === route.link ? styles.activeLink : null}`}>
        <i className={route.icon} />
        {route.name}
      </span>
    </Link>
  )
}

export default MenuLink
