import React, { useState } from 'react'
import { Dialog } from '@material-ui/core'
import CrudPanel from 'components/CrudPanel/CrudPanel'
import RecurrentActionsTable from './RecurrentActionsTable/RecurrentActionsTable'
import styles from './RecurrentActionsModal.module.scss'
import useLocale from 'hooks/useLocale'
import usePermissions from 'hooks/usePermissions'

const RecurrentActionsModal = ({ open, setOpen }) => {
  const locale = useLocale()
  const [searchValue, setSearchValue] = useState()
  const { getPermissions } = usePermissions()
  const permissions = getPermissions()
  const canWrite = permissions[1]

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth={'md'} fullWidth>
      <main className={styles.modal}>
        <CrudPanel
          defaultName="recurrencias"
          title={locale === 'es' ? 'Gestionar recurrencias' : 'Manage recurrent actions'}
          setSearchValue={setSearchValue}
          modalFormConfig={null}
        />
        <RecurrentActionsTable searchValue={searchValue} canWrite={canWrite} />
      </main>
    </Dialog>
  )
}

export default RecurrentActionsModal
