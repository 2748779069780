import { useMediaQuery } from '@material-ui/core'
import classNames from 'classnames'
import useLocale from 'hooks/useLocale'
import React, { useState } from 'react'
import { useDrag } from 'react-dnd'
import { useParams } from 'react-router'
import { dragItemsTypes } from '../dragItemsTypes'
import { useCreatePermission } from '../useDriveApi'
import styles from './File.module.scss'

const File = ({ id, name, icon, thumbnail, previewLink, onContextMenu, parents, permissions }) => {
  const { folderId } = useParams()
  const locale = useLocale()
  const isMobile = useMediaQuery('(max-width: 768px)')
  const { mutateAsync: createPermission } = useCreatePermission()
  const [isThumbnailError, setIsThumbnailError] = useState(false)

  const [{ isDragging }, drag] = useDrag(() => ({
    type: dragItemsTypes.FILE,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    item: {
      id,
      parents: parents,
      type: dragItemsTypes.FILE,
    },
  }))

  const handleOpenPreview = async () => {
    const globalSharePermission = permissions?.find((item) => item.id === 'anyoneWithLink')

    if (globalSharePermission) return window.open(previewLink, '_blank')
    else {
      const body = {
        role: 'writer',
        type: 'anyone',
      }

      await createPermission({ fileId: id, folderId, body })
      window.open(previewLink, '_blank')
    }
  }

  return (
    <div
      ref={drag}
      key={id}
      className={classNames(styles.wrapper, isDragging && styles.dragging)}
      onClick={isMobile ? handleOpenPreview : null}
      onDoubleClick={handleOpenPreview}
      onContextMenu={onContextMenu}
      data-id={id}
      data-type="file"
    >
      <figure className={styles.thumbnailWrapper}>
        {thumbnail ? (
          !isThumbnailError ? (
            <img
              draggable={false}
              referrerPolicy="no-referrer"
              src={thumbnail}
              alt={name}
              onError={({ currentTarget }) => {
                setIsThumbnailError(true)
                currentTarget.onerror = null
              }}
            />
          ) : (
            <div className={styles.emptyThumbnail}>
              <i className="fas fa-eye-slash" />
              <p>{locale === 'es' ? 'Sin miniatura' : 'No thumbnail'}</p>
            </div>
          )
        ) : (
          <div className={styles.emptyThumbnail}>
            <i className="fas fa-eye-slash" />
            <p>{locale === 'es' ? 'Sin miniatura' : 'No thumbnail'}</p>
          </div>
        )}
      </figure>

      <div className={styles.titleContainer}>
        <figure className={styles.iconContainer}>
          <img draggable={false} width={16} height={16} referrerPolicy="no-referrer" src={icon} alt="" />
        </figure>
        <strong className={styles.title} title={name}>
          {name}
        </strong>
      </div>
    </div>
  )
}

export default File
