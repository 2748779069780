import AuthValidation from 'components/AuthValidation/AuthValidation'
import ModalsControler from 'components/ModalsControler/ModalsControler'
import NoProAccess from 'components/NoProAccess'
import { isMobileOrTablet } from 'helpers/breackpoints'
import { useHideSubscriptionModule } from 'hooks/useHideSubscriptionModule'
import useScreen from 'hooks/useScreen'
import useSubscription from 'hooks/useSubscription'
import { Fallback } from 'index'
import { Suspense } from 'react'
import { Outlet } from 'react-router'
import Header from './components/Header'
import Sidebar from './components/Sidebar/index'
import PersonalFinancesContextProvider from './context/PersonalFinancesContext'
import styles from './PersonalFinancesLayout.module.scss'

const PersonalFinancesLayout = () => {
  const { breackpoint } = useScreen()
  const { isProUser } = useSubscription()
  const hideSubscriptionModule = useHideSubscriptionModule()

  return (
    <AuthValidation>
      {!isProUser && !hideSubscriptionModule ? (
        <NoProAccess />
      ) : (
        <PersonalFinancesContextProvider>
          <div className={styles.container}>
            {!isMobileOrTablet(breackpoint) && <Sidebar />}
            <div className={styles.routes}>
              <Suspense fallback={<Fallback />}>
                <Header />
                <Outlet />
              </Suspense>
            </div>
          </div>

          <ModalsControler />
        </PersonalFinancesContextProvider>
      )}
    </AuthValidation>
  )
}

export default PersonalFinancesLayout
