import { applyMiddleware, combineReducers, compose, createStore } from 'redux'

// Reducers
import boards from 'reducers/boards'
import calendarActions from 'reducers/calendarActions'
import cashFlow from 'reducers/cashFlow'
import clientGroups from 'reducers/clientGroups'
import companyInformation from 'reducers/companyInformation'
import configs from 'reducers/configs'
import costs from 'reducers/costs'
import customers from 'reducers/customers'
import datanalisis from 'reducers/datanalisis'
import clientForms from 'reducers/forms'
import humanResources from 'reducers/humanResources'
import inventory from 'reducers/inventory'
import marketing from 'reducers/marketingCampaigns'
import messages from 'reducers/messages'
import modals from 'reducers/modals'
import personalFinances from 'reducers/personalFinances'
import process from 'reducers/process'
import projects from 'reducers/projects'
import quotations from 'reducers/quotations'
import reportErrors from 'reducers/reportErrors'
import roles from 'reducers/roles'
import socialmedia from 'reducers/socialmedia'
import tools from 'reducers/tools'
import users from 'reducers/users'
import thunk from 'redux-thunk'
import coupons from 'slices/couponsSlice'
import drive from 'slices/driveSlice/driveSlice'
import aiBlocks from 'reducers/aiBlocks'

const REDUCERS = {
  boards,
  calendarActions,
  cashFlow,
  clientGroups,
  companyInformation,
  configs,
  costs,
  customers,
  datanalisis,
  clientForms,
  humanResources,
  inventory,
  marketing,
  messages,
  modals,
  personalFinances,
  process,
  projects,
  quotations,
  reportErrors,
  roles,
  socialmedia,
  tools,
  users,
  coupons,
  drive,
  aiBlocks,
}

const middleware = applyMiddleware(thunk)

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose

const configureStore = (preloadedState = {}) => {
  const rootReducer = combineReducers(REDUCERS)

  return createStore(rootReducer, preloadedState, composeEnhancers(middleware))
}

export default configureStore
