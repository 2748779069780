import React from 'react'
import styles from './FormHelper.module.scss'

const FormHelper = ({ title, helperMessage, ...delegated }) => {
  return (
    <div {...delegated}>
      <h4 className={styles.title}>{title}</h4>
      <p className={styles.titleHelper}>{helperMessage}</p>
    </div>
  )
}

export default FormHelper
