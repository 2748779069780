import {
  DELETE_CUSTOMER,
  GET_CONTACTS,
  GET_CUSTOMERS,
  SET_NEW_CONTACT,
  SET_NEW_CUSTOMER,
  UPDATE_CUSTOMER,
  UPDATE_CONTACT,
  DELETE_CONTACT,
  LOADING_CONTACTS,
  MASSIVE_CUSTOMERS_UPLOAD,
} from 'constants/actionTypes'

const initialState = {
  customers: [],
  contacts: [],
  errorInRequest: false,
  isLoadingCustomers: true,
  isLoadingContacts: true,
}

const handlers = {
  [GET_CUSTOMERS]: (state, { payload = {} }) => {
    if (!payload?.customers) return { ...state, isLoadingCustomers: false, errorInRequest: true }

    return {
      ...state,
      customers: payload?.customers?.reverse(),
      isLoadingCustomers: false,
      errorInRequest: false,
    }
  },
  [SET_NEW_CUSTOMER]: (state, { payload = {} }) => {
    if (!payload?.customer) return { ...state, errorInRequest: true }

    const { groupNumber } = payload?.customer

    return {
      ...state,
      customers: [{ ...(payload?.customer ?? {}), groupNumber: Number(groupNumber) }, ...state?.customers],
      errorInRequest: false,
    }
  },
  [UPDATE_CUSTOMER]: (state, { payload = {} }) => {
    if (!payload?.customer) return { ...state, errorInRequest: true }

    const { groupNumber } = payload?.customer

    return {
      ...state,
      customers: state?.customers?.map((customer) =>
        customer?.idClient === payload?.customer?.idClient
          ? { ...(payload?.customer ?? {}), groupNumber: Number(groupNumber) }
          : customer
      ),
      errorInRequest: false,
    }
  },
  [DELETE_CUSTOMER]: (state, { payload = {} }) => {
    if (!payload?.customer) return { ...state, errorInRequest: true }

    return {
      ...state,
      customers: state?.customers?.filter(({ idClient }) => idClient !== payload?.customer?.idClient) ?? [],
      errorInRequest: false,
    }
  },
  [GET_CONTACTS]: (state, { payload = {} }) => {
    if (!payload?.contacts) return { ...state, isLoadingContacts: false, errorInRequest: true }

    return {
      ...state,
      contacts: payload?.contacts,
      isLoadingContacts: false,
      errorInRequest: false,
    }
  },
  [SET_NEW_CONTACT]: (state, { payload = {} }) => {
    if (!payload?.contact) return { ...state, errorInRequest: true }

    return {
      ...state,
      contacts: [...state?.contacts, payload?.contact],
      errorInRequest: false,
    }
  },
  [UPDATE_CONTACT]: (state, { payload = {} }) => {
    if (!payload?.contact) return { ...state, errorInRequest: true }

    return {
      ...state,
      contacts: state?.contacts?.map((contact) =>
        contact?.idContact === payload?.contact?.idContact ? payload?.contact : contact
      ),
      errorInRequest: false,
    }
  },
  [DELETE_CONTACT]: (state, { payload = {} }) => {
    if (!payload?.contact) return { ...state, errorInRequest: true }

    return {
      ...state,
      contacts: state?.contacts?.filter(({ idContact }) => idContact !== payload?.contact?.idContact) ?? [],
      errorInRequest: false,
    }
  },
  [LOADING_CONTACTS]: (state, { payload = {} }) => {
    return {
      ...state,
      ...payload,
    }
  },
  [MASSIVE_CUSTOMERS_UPLOAD]: (state, { payload = {} }) => {
    return {
      ...state,
      customers: [...payload.clients, ...state.customers],
    }
  },
}

const customers = (state = initialState, action) => {
  const { type } = action

  return handlers[type] ? handlers[type](state, action) : state
}

export default customers
