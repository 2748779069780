import { getAiBlocks } from 'actions/aiBlocks'
import useCompany from 'hooks/useCompany'
import useFetchResource from 'hooks/useFetchResource'
import { useMemo } from 'react'

const useAiBlocksPageLoadData = () => {
  const { idCompany } = useCompany()

  const promises = useMemo(() => [getAiBlocks(idCompany)], [idCompany])

  useFetchResource(promises)
}

export const getAiBlocksFunction = async () => {
  return await getAiBlocks()
}

export default useAiBlocksPageLoadData
