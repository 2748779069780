import { InputAdornment, TextField } from '@material-ui/core'
import useLocale from 'hooks/useLocale'
import React from 'react'
import styles from './CalendarActionsFilters.module.scss'
import CALENDAR_ACTION_TYPES from '../calendarActionTypes'
import Select from 'components/common/Select/Select'
import classNames from 'classnames'

const CalendarActionsFilters = ({ filters, setFilters }) => {
  const locale = useLocale()

  const handleChange = (e) => {
    setFilters((filters) => {
      filters[e.target.name] = e.target.value
    })
  }
  const typesOptions = CALENDAR_ACTION_TYPES.filter((_option) => _option.value !== 3).map((_option) => ({
    text: _option[locale],
    value: _option.value,
    key: _option.value,
  }))

  return (
    <section className={styles.wrapper}>
      <TextField
        fullWidth
        variant="outlined"
        name="search"
        autoComplete="off"
        label={locale === 'es' ? 'Buscar accion' : 'Search action'}
        value={filters?.search}
        onChange={handleChange}
        InputLabelProps={{
          shrink: filters?.search,
          classes: { root: classNames(styles.label, filters?.search.length && styles.noPaddingLeft) },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <i className="fas fa-search" />
            </InputAdornment>
          ),
        }}
      />

      <Select
        displayEmpty
        name="type"
        label={locale === 'es' ? 'Mostrar' : 'Show'}
        emptyValue={locale === 'es' ? 'Todos' : 'All'}
        value={filters.type}
        options={typesOptions}
        onChange={handleChange}
        isRequired={false}
      />
    </section>
  )
}

export default CalendarActionsFilters
