import React, { useMemo } from 'react'
import Dialog from 'components/Dialog/Dialog'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Container from '@material-ui/core/Container'
import styles from './viewMassEmailModal.module.scss'
import { TYPES_OPTIONS } from 'constants/sendMassEmailsOptions'
import { formatDate } from 'helpers/date'
import useLocale from 'hooks/useLocale'

const ViewMassEmailModal = ({ open, setOpen, dataMassEmail }) => {
  const locale = useLocale()

  const getOption = (value) => TYPES_OPTIONS(locale).find((opt) => opt.value === parseInt(value))?.text

  const HEAD_DATA = useMemo(
    () => [
      {
        title: locale === 'es' ? 'Fecha' : 'Date',
        data: formatDate(dataMassEmail?.created_at, 'YYYY-MM-DD h:mm A'),
        key: dataMassEmail?.id,
      },
      { title: locale === 'es' ? 'Asunto' : 'Subject', data: dataMassEmail?.subject, key: dataMassEmail?.id },
      { title: locale === 'es' ? 'Tipo' : 'Type', data: getOption(dataMassEmail?.type), key: dataMassEmail?.id },
    ],
    [locale, dataMassEmail]
  )

  return (
    <Dialog
      title={locale === 'es' ? 'Visualización de correo' : 'Mass email visualization'}
      open={open}
      setOpen={setOpen}
      maxWidth={'md'}
    >
      <Table aria-label="simple table">
        <TableBody>
          {HEAD_DATA.map((item) => (
            <TableRow>
              <TableCell className={styles.row__table}>
                <b>{item.title}</b>
              </TableCell>
              <TableCell>{item?.data}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Container className={styles.container__email} dangerouslySetInnerHTML={{ __html: dataMassEmail?.content }}></Container>
    </Dialog>
  )
}

export default ViewMassEmailModal
