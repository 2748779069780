import { getCalendarActions } from 'actions/calendarActions'
import { getClientGroups } from 'actions/clientGroups'
import { getCustomers } from 'actions/customers'
import { getMassEmails } from 'actions/messages'
import { getPosts } from 'actions/socialmedia'
import { getCompanyUsers, getUsers } from 'actions/users'
import useCalendarActions from 'hooks/useCalendarActions'
import useClientGroups from 'hooks/useClientGroups'
import useCustomers from 'hooks/useCustomers'
import useMessages from 'hooks/useMessages'
import useSocialMedia from 'hooks/useSocialMedia'
import useUsers from 'hooks/useUsers'
import usePostsLoadData from 'pages/PostsPage/usePostsLoadData'
import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useImmer } from 'use-immer'

const useCalendarPage = () => {
  const dispatch = useDispatch()
  const { myUser, isLoadingMyUser, isLoadingUsers } = useUsers()
  const { idUser, company } = myUser || {}
  const { idCompany } = company || {}
  const { calendarActions: data, isLoadingCalendarActions } = useCalendarActions()
  const { isLoadingCompanyUsers } = useUsers()
  const { isLoadingClientGroups } = useClientGroups()
  const { isLoadingMassEmails } = useMessages()
  const { isLoadingCustomers } = useCustomers()
  const { isLoadingPosts } = useSocialMedia()

  const [filters, setFilters] = useImmer({ search: '', type: '' })

  const calendarActions = useMemo(
    () =>
      data
        ?.filter((calendarAction) => !calendarAction.idMarketingCampaign)
        .filter((calendarAction) =>
          filters.search.trim() ? calendarAction.name.toLowerCase().includes(filters.search.toLowerCase()) : true
        )
        .filter((calendarAction) => {
          switch (filters.type) {
            case 1:
              return calendarAction?.idPost
            case 2:
              return calendarAction?.idMassEmail
            case 4:
              return calendarAction?.idCalendarEvent
            default:
              return calendarAction
          }
        }),
    [data, filters]
  )

  usePostsLoadData()
  useEffect(() => {
    const fetch = async () => {
      if (idCompany) {
        dispatch(await getCalendarActions(idCompany))

        const promises = [
          getMassEmails(idCompany),
          getPosts(idCompany),
          getClientGroups(idCompany),
          getCustomers(idCompany),
          getUsers(),
        ]

        Promise.all(promises).then((responses) => responses.forEach((x) => dispatch(x)))
      }
    }

    fetch()
  }, [dispatch, idCompany])

  useEffect(() => {
    let cancel = false
    if (isLoadingCompanyUsers && !cancel && idCompany) {
      const fetch = async () => {
        dispatch(await getCompanyUsers(idCompany))
      }

      fetch()
    }

    return () => {
      cancel = true
    }
  }, [isLoadingCompanyUsers, idCompany, dispatch])

  const loadingResources = isLoadingMyUser || isLoadingCalendarActions
  const isLoadingExtraResources =
    isLoadingClientGroups || isLoadingMassEmails || isLoadingCustomers || isLoadingPosts || isLoadingUsers

  return { idCompany, idUser, calendarActions, loadingResources, isLoadingExtraResources, filters, setFilters }
}

export default useCalendarPage
