export const DEFAULT_INDICATOR = {
  title: '',
  type: '',
  table: '',
  graphicType: null,
  subTable: null,
  values: [],
}

export const indicatorReducer = (draft, action) => {
  const { payload } = action

  switch (action.type) {
    case 'CHANGE_INDICATOR':
      return { ...draft, ...payload }
    case 'CHANGE_VALUE':
      const key = payload.key
      if (key === 'table') {
        draft.subTable = null
      }

      if (key === 'table' && draft.type === 'totalization') {
        draft.values = []
      }

      if (key === 'type') {
        draft.values = []
        draft.graphicType = null
      }

      draft[key] = payload.value
      break
    case 'ADD_VALUE':
      draft.values = [payload, ...draft.values]

      break
    case 'UPDATE_VALUE':
      draft.values = draft.values.map((v) => (v.id === payload.id ? payload : v))

      break
    case 'DELETE_VALUE':
      draft.values = draft.values.filter((v) => v.id !== payload)

      break
    case 'RESET_FORM':
      return DEFAULT_INDICATOR
    case 'CHANGE_GRAPHIC_TYPE':
      draft.graphicType = payload

      break
    default:
      return draft
  }
}

export const handleChangeIndicator = (payload) => ({ type: 'CHANGE_INDICATOR', payload })
export const handleChange = (e) => {
  const { value, name } = e.target

  return {
    type: 'CHANGE_VALUE',
    payload: {
      value,
      key: name,
    },
  }
}

export const resetForm = () => ({ type: 'RESET_FORM' })

export const handleAddValue = (value) => ({ type: 'ADD_VALUE', payload: value })
export const handleChangeValue = (value) => ({ type: 'UPDATE_VALUE', payload: value })
export const handleDeleteValue = (id) => ({ type: 'DELETE_VALUE', payload: id })

export const changeGraphicType = (type) => ({ type: 'CHANGE_GRAPHIC_TYPE', payload: type })
