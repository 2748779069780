import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import Faqs from './Faqs'
import styles from './Help.module.scss'
import data from './data'
import { makeHash, makeSlug } from './utils'

const Help = () => {
  const location = useLocation()
  const { tutorials } = data

  useEffect(() => {
    const { hash } = location
    if (hash) {
      const id = decodeURI(hash.replace('#', ''))
      const element = document.getElementById(id)
      if (element) {
        element.scrollIntoView(true)
        element.open = true
      }
    } else {
      window.scroll(0, 0)
    }
  }, [location])

  return (
    <>
      <section className={styles.contentSection}>
        <h1 className={styles.title}>Ayuda</h1>
        <p className={styles.paragraph}>
          Bienvenido a la página de ayuda de DigitalPymes. Aquí podrás encontrar información sobre cómo usar los módulos de la
          plataforma. Si tienes alguna duda o sugerencia, por favor contáctanos al número{' '}
          <a href="tel:+506-8334-5067"> +506 8334 5067</a> o por correo a{' '}
          <a href="mailto:cedecc.s.a@gmail.com"> cedecc.s.a@gmail.com</a>.
        </p>
      </section>

      <section className={styles.contentSection}>
        <h2 className={styles.title}>Tutoriales</h2>
        <p className={styles.paragraph}>
          Aprende a utilizar las funcionalidades de DigitalPymes con nuestros tutoriales en formato de video.
        </p>

        <section className={styles.tutorials}>
          {tutorials.map((tutorial, index) => (
            <div key={tutorial.title + index}>
              <h3>
                <Link to={`${makeSlug(tutorial.title)}`} className={styles.tutorialTitle}>
                  <i className={tutorial.icon} />
                  {tutorial.title}
                </Link>
              </h3>

              <ul className={styles.tutorialLinks}>
                {tutorial.videos.slice(0, 4).map((video, index) => (
                  <li key={video.title + index}>
                    <HashLink to={makeSlug(tutorial.title) + makeHash(video.title)} className={styles.tutorialLink}>
                      {video.title}
                    </HashLink>
                  </li>
                ))}
                {Boolean(tutorial.videos.length > 4) && (
                  <li>
                    <Link to={`${makeSlug(tutorial.title)}`} className={styles.tutorialLink}>
                      Ver más...
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          ))}
        </section>
      </section>

      <section className={styles.contentSection}>
        <h2 className={styles.title}>Preguntas frecuentes</h2>
        <p className={styles.paragraph}>
          ¿Tienes preguntas? Antes de contactarnos, por favor revisa esta sección de preguntas frecuentes. Si no encuentras lo que
          buscas, por favor contáctanos.
        </p>

        <Faqs />
      </section>
    </>
  )
}

export default Help
