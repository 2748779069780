import styles from './MarketingCampaignListTable.module.scss'
import { formatDate } from 'helpers/date'

// Table Render Functions
export const _renderName = ({ value }) => <div className={styles.columnName}>{value}</div>

export const _renderDate = ({ value }) => (value ? formatDate(value, 'DD/MM/YYYY', true) : '-')

export const _renderContentType = ({ row, locale }) => {
  const postContent = locale === 'es' ? 'Publicación' : 'Post'
  const emailContent = locale === 'es' ? 'Correo masivo' : 'Mass email'
  const marketingTaskContent = locale === 'es' ? 'Tarea de marketing' : 'Marketing task'

  const { idPost, idMassEmail, idMarketingCampaignTask } = row

  if (idPost) return postContent
  else if (idMassEmail) return emailContent
  else if (idMarketingCampaignTask) return marketingTaskContent
  else return ''
}
