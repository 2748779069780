import { Button } from '@material-ui/core'
import { sendErrorMessage } from 'actions/modals'
import { updateSubscription as updateUserSubscription } from 'actions/users'
import CreditCardForm from 'components/CreditCardForm'
import Dialog from 'components/Dialog/Dialog'
import { getCreditCardByNumber } from 'components/SubscribeToProModal/creditCardUtils'
import { updateSubscription } from 'constants/subscriptions'
import useLocale from 'hooks/useLocale'
import useUsers from 'hooks/useUsers'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import styles from './UpdateCardModal.module.scss'

const UpdateCardModal = () => {
  const locale = useLocale()
  const dispatch = useDispatch()
  const { myUser } = useUsers()

  const [openModal, setOpenModal] = useState(false)
  const [confirmed, setConfirmed] = useState(false)
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState({
    card: '',
    name: '',
    date: '',
    cvv: '',
  })

  const handleClose = () => {
    setOpenModal(false)
    setForm({})
    setTimeout(() => {
      setConfirmed(false)
    }, 200)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)

    try {
      const response = await updateSubscription(myUser, form)

      if (!response) throw new Error('Error updating subscription')

      dispatch(
        await updateUserSubscription({
          id: myUser.payment_method.id,
          creditCardBrand: getCreditCardByNumber(form?.card)?.brand,
          LAST_DIGITS: form.card.slice(-4),
        })
      )
      setLoading(false)
      setConfirmed(true)
    } catch (error) {
      dispatch(sendErrorMessage(locale === 'es' ? 'Error al actualizar la tarjeta' : 'Error updating card'))
      setLoading(false)

      return
    }
  }

  return (
    <>
      <Button color="primary" variant="outlined" onClick={() => setOpenModal(true)}>
        {locale === 'es' ? 'Actualizar' : 'Update'}
      </Button>

      <Dialog
        open={openModal}
        handleCloseAction={handleClose}
        title={!confirmed && locale === 'es' ? 'Actualizar tarjeta' : 'Update card'}
      >
        {confirmed ? (
          <section className={styles.wrapper}>
            <figure className={styles.successIcon}>
              <i className="fas fa-check" />
            </figure>

            <p className={styles.warningHelper}>
              {locale === 'es' ? '¡El método de pago se eliminó de forma exitosa!' : 'Payment method deleted successfully!'}
            </p>

            <div className={styles.buttons}>
              <Button fullWidth variant="contained" color="primary" onClick={handleClose}>
                {locale === 'es' ? 'Aceptar' : 'Accept'}
              </Button>
            </div>
          </section>
        ) : (
          <CreditCardForm form={form} setForm={setForm} handleSubmit={handleSubmit} pendingSubmit={loading} isUpdate />
        )}
      </Dialog>
    </>
  )
}

export default UpdateCardModal
