import { GET_FB_PAGES, GET_POSTS, SET_NEW_POST } from 'constants/actionTypes'

const initialState = {
  fbPages: [],
  isLoadingFbPages: true,
  errorInRequest: false,
  posts: [],
  isLoadingPosts: true,
}

const handlers = {
  [GET_FB_PAGES]: (state, { payload = {} }) => {
    if (!payload?.pages) return { ...state, isLoadingFbPages: false, errorInRequest: true }

    return {
      ...state,
      fbPages: payload?.pages,
      isLoadingFbPages: false,
      errorInRequest: false,
    }
  },
  [GET_POSTS]: (state, { payload = {} }) => {
    if (!payload?.posts) return { ...state, isLoadingPosts: false, errorInRequest: true }

    return {
      ...state,
      posts: payload?.posts,
      isLoadingPosts: false,
      errorInRequest: false,
    }
  },
  [SET_NEW_POST]: (state, { payload = {} }) => {
    if (!payload?.post) return { ...state, errorInRequest: true }

    return {
      ...state,
      posts: [payload?.post, ...state?.posts],
      errorInRequest: false,
    }
  },
}

const socialmedia = (state = initialState, action) => {
  const { type } = action

  return handlers[type] ? handlers[type](state, action) : state
}

export default socialmedia
