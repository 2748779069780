import useLocale from 'hooks/useLocale'
import styles from './CalendarLegends.module.scss'
import React from 'react'
import classNames from 'classnames'

const CalendarLegends = () => {
  const locale = useLocale()

  return (
    <section className={styles.wrapper}>
      <strong className={styles.legends}>{locale === 'es' ? 'Contenido' : 'Content'}</strong>
      <p className={classNames(styles.base, styles.events)}>{locale === 'es' ? 'Eventos' : 'Events'}</p>
      <p className={classNames(styles.base, styles.post)}>{locale === 'es' ? 'Publicación' : 'Post'}</p>
      <p className={classNames(styles.base, styles.email)}>{locale === 'es' ? 'Correo masivo' : 'Mass email'}</p>
    </section>
  )
}

export default CalendarLegends
