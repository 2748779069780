import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import React, { useRef } from 'react'
import { EDITOR_CONFIG } from './config'
import { attatchUploadAdapter } from './uploadAdapter'

const CKTextEditor = (props) => {
  const { toolbarConfig, config, ...delegated } = props
  const editor = useRef()

  return (
    <>
      <CKEditor
        ref={editor}
        editor={ClassicEditor}
        config={{
          ...EDITOR_CONFIG,
          toolbar: {
            items: toolbarConfig ?? EDITOR_CONFIG.toolbar.items,
          },
          ...(config ?? {}),
        }}
        onReady={attatchUploadAdapter}
        {...delegated}
      />
    </>
  )
}

export default CKTextEditor
