import { Breadcrumbs, CircularProgress, Tooltip } from '@material-ui/core'
import classNames from 'classnames'
import useLocale from 'hooks/useLocale'
import React, { useMemo } from 'react'
import { useDrop } from 'react-dnd'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useDragAndDrop } from '../FileTypes/useDragAndDrop'
import { dragItemsTypes } from '../dragItemsTypes'
import { useFolderBreadcrumbs } from '../useDriveApi'
import styles from './GoogleDriveBreadcrumbs.module.scss'

const getHref = (folder, isShared, isTrashed) => {
  const { lastLink, id } = folder
  const trashedSearch = isTrashed ? '?trashed=true' : ''
  const sharedSearch = isShared ? '?shared=true' : ''

  const addSearchs = (link) => `${link}${trashedSearch}${sharedSearch}`

  if (lastLink) {
    return addSearchs('/dashboard/managment/google-drive')
  }

  return addSearchs(`/dashboard/managment/google-drive/folders/${id}`)
}

const BreadcrumbLink = ({ name, id, parents, lastLink, isActive }) => {
  const { dropItem } = useDragAndDrop(id)
  const [searchParams] = useSearchParams()
  const isShared = searchParams.get('shared')
  const isTrashed = searchParams.get('trashed')

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: [dragItemsTypes.FILE, dragItemsTypes.FOLDER],
      drop: dropItem,
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
      item: {
        id,
        parents,
        type: dragItemsTypes.FOLDER,
      },
    }),
    []
  )

  return (
    <div ref={drop} className={classNames(isOver && styles.over)}>
      <Tooltip title={name}>
        <Link
          to={getHref({ parents, id, lastLink }, isShared, isTrashed)}
          className={classNames(styles.baseLink, isActive && styles.active)}
        >
          {name}
        </Link>
      </Tooltip>
    </div>
  )
}

const GoogleDriveBreadcrumbs = ({ folderId }) => {
  const locale = useLocale()
  const navigate = useNavigate()
  const { data: breadcrumbsData, isLoading } = useFolderBreadcrumbs(folderId)

  const [searchParams] = useSearchParams()
  const isShared = searchParams.get('shared')
  const isTrashed = searchParams.get('trashed')
  const breadcrumbs = useMemo(() => {
    if (isTrashed) {
      const newValue = {
        id: 'trashed',
        name: locale === 'es' ? 'Papelera' : 'Trash',
      }

      return [newValue, ...(breadcrumbsData ?? [])]
    }

    if (isShared) {
      const newValue = {
        id: 'shared',
        name: locale === 'es' ? 'Compartidos' : 'Shared',
      }

      return [newValue, ...(breadcrumbsData ?? [])]
    }

    return breadcrumbsData
  }, [breadcrumbsData, isShared, isTrashed, locale])

  return (
    <div className={styles.wrapper}>
      {!isLoading && (
        <Tooltip title={locale === 'es' ? 'Volver' : 'Back'} onClick={() => navigate(-1)}>
          <i className={classNames('fas fa-arrow-left', styles.goBack)} />
        </Tooltip>
      )}

      <Breadcrumbs
        separator={<i className={classNames(styles.separator, 'fas fa-chevron-right')} />}
        classes={{ root: styles.breadcrumbs }}
        style={{
          '--hide': isLoading ? 'hidden' : 'revert',
        }}
      >
        {isLoading ? (
          <CircularProgress size={20} classes={{ colorPrimary: styles.loadingColor }} />
        ) : (
          breadcrumbs?.map((item, index) => (
            <BreadcrumbLink key={item.id} isActive={index === breadcrumbs.length - 1} lastLink={index === 0} {...item} />
          ))
        )}
      </Breadcrumbs>
    </div>
  )
}

export default GoogleDriveBreadcrumbs
