import React from 'react'
import styles from './SectionHeader.module.scss'

const SectionHeader = ({ title, subTitle }) => {
  return (
    <>
      <h2 className={styles.header}>{title}</h2>
      {subTitle && <p className={styles.headerHelper}>{subTitle}</p>}
    </>
  )
}

export default SectionHeader
