import {
  DELETE_DATANALISIS_INDICATOR,
  DELETE_DATANALISIS_PAGE,
  GET_DATANALISIS_PAGES,
  SELECT_DATANALISIS_PAGE,
  TOGGLE_INDICATOR_CREATOR,
  UPDATE_DATANALISISIS_INDICATOR,
  UPDATE_DATANALISISIS_PAGE,
  UPDATE_DATANALISIS_INDICATOR_LAYOUT,
  UPLOAD_DATANALISIS_INDICATOR,
  UPLOAD_DATANALISIS_PAGE,
} from 'constants/actionTypes'
import {
  DATANALISIS_INDICATORS_ROUTE,
  DATANALISIS_INDICATORS_UPDATE_LAYOUT_ROUTE,
  DATANALISIS_PAGES_ROUTE,
} from 'constants/apiRoutes'
import { apiDeleteData, apiFindData, apiPostData, apiPutData } from 'helpers/api'

export const getDatanalisisPages = (id) => apiFindData(DATANALISIS_PAGES_ROUTE, { id }, GET_DATANALISIS_PAGES)
export const uploadDatanalisisPage = (page) => apiPostData(DATANALISIS_PAGES_ROUTE, page, UPLOAD_DATANALISIS_PAGE)
export const updateDatanalisisPage = (page) => apiPutData(DATANALISIS_PAGES_ROUTE, page, UPDATE_DATANALISISIS_PAGE)
export const deleteDatanalaisisPage = (id) => apiDeleteData(DATANALISIS_PAGES_ROUTE, { id }, DELETE_DATANALISIS_PAGE)
export const selectPage = (page) => ({ type: SELECT_DATANALISIS_PAGE, payload: page })
export const toggleIndicatorCreator = (state, item) => ({ type: TOGGLE_INDICATOR_CREATOR, payload: { state, item } })

export const uploadDatanalisisIndicator = (indicator) =>
  apiPostData(DATANALISIS_INDICATORS_ROUTE, indicator, UPLOAD_DATANALISIS_INDICATOR)
export const updateDatanalisisIndicator = (indicator) =>
  apiPutData(DATANALISIS_INDICATORS_ROUTE, indicator, UPDATE_DATANALISISIS_INDICATOR)
export const deleteDatanalaisisIndicator = (id) =>
  apiDeleteData(DATANALISIS_INDICATORS_ROUTE, { id }, DELETE_DATANALISIS_INDICATOR)

export const updateDatanalisisIndicatorsLayout = (data) =>
  apiPostData(DATANALISIS_INDICATORS_UPDATE_LAYOUT_ROUTE, data, UPDATE_DATANALISIS_INDICATOR_LAYOUT)
