import OopsError from 'components/OopsError/OopsError'
import useLocale from 'hooks/useLocale'
import React from 'react'

const MissingPage = () => {
  const locale = useLocale()

  return (
    <OopsError
      title={locale === 'es' ? 'Esta pagina no existe' : "This page doesn't exist"}
      description={
        locale === 'es'
          ? 'Parece que no tienes permiso para ver este contenido, o la pagina no existe.'
          : "It looks like you don't have permission to see this content, or this page doesn't exist."
      }
      link="/dashboard"
      buttonName="Homepage"
    />
  )
}

export default MissingPage
