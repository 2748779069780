import { Collapse, Divider, Drawer, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import getHeaderItems from 'constants/headerMenu'
import React, { useEffect, useState } from 'react'
import clsx from 'clsx'

import { useCallback } from 'react'
import { Link } from 'react-router-dom'
import styles from './mobileHeaderMenu.module.scss'
import useScreen from 'hooks/useScreen'
import useLocale from 'hooks/useLocale'
import useUsers from 'hooks/useUsers'
import usePermissions from 'hooks/usePermissions'

const DEFAULT_ACHOR = 'left'

const MenuItem = ({
  icon,
  name,
  link,
  onClick,
  className,
  children,
  currentPath,
  isItemWithOptions,
  baseUrl = null,
  preloadComponent,
}) => {
  useEffect(() => {
    if (typeof preloadComponent === 'function') preloadComponent()
  }, [])

  return (
    <Link
      className={clsx(styles.menuItem, className, {
        [styles.active]: link === '/dashboard' ? link === currentPath : currentPath?.includes(link ?? baseUrl),
      })}
      to={isItemWithOptions ? currentPath : `${link}`}
    >
      <ListItem button key={name} className={className} onClick={onClick}>
        <ListItemIcon>{<i className={icon} />}</ListItemIcon>
        <ListItemText primary={name} />
        {children}
      </ListItem>
    </Link>
  )
}

const MenuItemWithOptions = (props) => {
  const { options, onClick, getPermissions } = props

  const [open, setOpen] = useState()
  const { currentPath } = props

  const onClickOpen = useCallback(() => setOpen(!open), [open])

  return (
    <>
      <MenuItem {...props} onClick={onClickOpen} isItemWithOptions>
        {open ? <ExpandLess /> : <ExpandMore />}
      </MenuItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Divider />
        <List component="div" disablePadding>
          {options
            .filter(({ hideIf }) => !hideIf?.({ permissions: getPermissions() }))
            .map((optionProps, index) => (
              <MenuItem
                key={`menu-item-${index}`}
                {...optionProps}
                currentPath={currentPath}
                className={styles.optionItem}
                onClick={onClick}
              />
            ))}
          <MenuItem />
        </List>
      </Collapse>
    </>
  )
}

const MobileHeaderMenu = ({ open, setOpen, anchor }) => {
  const { currentPath } = useScreen()
  const { myUser } = useUsers()
  const { type } = myUser || {}
  const { getPermissions } = usePermissions()
  const locale = useLocale()

  const onClickClose = useCallback(() => setOpen(false), [setOpen])

  return (
    <Drawer anchor={anchor || DEFAULT_ACHOR} open={open} onClose={onClickClose}>
      <div className={styles.drawerList}>
        <List>
          {getHeaderItems(locale)
            .filter(({ hideIf }) => !hideIf?.({ type, permissions: getPermissions() }))
            .map((props) =>
              props?.link ? (
                <MenuItem key={props.name} {...props} currentPath={currentPath} onClick={onClickClose} />
              ) : (
                <MenuItemWithOptions
                  key={props.name}
                  {...props}
                  currentPath={currentPath}
                  onClick={onClickClose}
                  getPermissions={getPermissions}
                />
              )
            )}
        </List>
      </div>
    </Drawer>
  )
}

export default MobileHeaderMenu
