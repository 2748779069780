import classNames from 'classnames'
import { getCreditCardByBrand } from 'components/SubscribeToProModal/creditCardUtils'
import { format } from 'date-fns'
import { enUS, es } from 'date-fns/locale'
import useLocale from 'hooks/useLocale'
import useSubscription from 'hooks/useSubscription'
import useUsers from 'hooks/useUsers'
import SectionHeader from 'pages/MySubscriptionPage/SectionHeader'
import React from 'react'
import styles from './PaymentMethodSection.module.scss'
import UpdateCardModal from './UpdateCardModal'

const PaymentMethodSection = () => {
  const locale = useLocale()
  const { myUser } = useUsers()
  const { payment_method } = myUser
  const { subscription } = useSubscription()
  const { endDate } = subscription

  const expirationDate = format(new Date(endDate), 'dd MMMM yyyy', { locale: locale === 'es' ? es : enUS })

  return (
    <>
      <SectionHeader title={locale === 'es' ? 'Metodo de pago' : 'Payment method'} />

      <div className={classNames(styles.wrapper, styles.paymentMethod)}>
        <section>
          {payment_method ? (
            <div className={styles.card}>
              {getCreditCardByBrand(payment_method?.brand).icon}

              <span>•••• •••• •••• {payment_method.LAST_DIGITS}</span>
            </div>
          ) : (
            <div className={styles.card}>
              <p className={classNames(styles.body, styles.noCardBody)} style={{ fontSize: '1rem' }}>
                {locale === 'es'
                  ? 'Sin información de pago. Agrega un método de pago para que puedas disfrutar del plan PRO sin ningún problema.'
                  : 'No payment information. Add a payment method to enjoy the PRO plan without any problem.'}
              </p>
            </div>
          )}

          {payment_method && (
            <p className={styles.body}>
              {locale === 'es'
                ? `Tu próxima fecha de pago es el ${expirationDate}.`
                : `Your next payment date is the ${expirationDate}.`}
            </p>
          )}
        </section>

        <UpdateCardModal />
      </div>
    </>
  )
}

export default PaymentMethodSection
