const CONTENT_BY_LOCALE = {
  es: {
    title: 'Ha ocurrido un error',
    description: 'Al parecer el usuario registrado presenta errores, por favor vuelve a intentarlo.',
    buttonName: 'Ir al Login',
  },
  en: {
    title: 'Error occurred',
    description: 'Apparently, the registered user has errors, please try again.',
    buttonName: 'Go to Login',
  },
}
const getContent = (locale) => CONTENT_BY_LOCALE[locale]

export default getContent
