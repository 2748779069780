import SubscribeToProButton from 'components/SubscribeToProButton'
import useLocale from 'hooks/useLocale'
import React from 'react'
import styles from './Hero.module.scss'

const CONTENT = {
  header: {
    es: 'Suscríbete a PRO',
    en: 'Subscribe to PRO',
  },
  subheader: {
    es: 'Suscríbete al plan PRO para acceder a más funcionalidades de DigitalPymes.',
    en: 'Subscribe to the PRO plan to access more functionalities of DigitalPymes.',
  },
  button: {
    es: 'SUSCRIBIRSE A PRO',
    en: 'Subscribe to PRO',
  },
}

const Hero = () => {
  const locale = useLocale()

  return (
    <section className={styles.wrapper}>
      <div className={styles.text}>
        <h1 className={styles.header}>{CONTENT.header[locale]}</h1>
        <p className={styles.subheader}>{CONTENT.subheader[locale]}</p>
        <SubscribeToProButton />
      </div>
      <div className={styles.image}>
        <img src="/pro-hero-banner.png" alt="hero" />
      </div>
    </section>
  )
}

export default Hero
