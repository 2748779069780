import {
  GET_MARKETING_CAMPAIGNS,
  UPLOAD_MARKETING_CAMPAIGN,
  UPDATE_MARKETING_CAMPAIGN,
  DELETE_MARKETING_CAMPAIGN,
  UPLOAD_MARKETING_CAMPAIGN_COMMENT,
  SELECT_MARKETING_CAMPAIGN,
} from 'constants/actionTypes'

const initialState = {
  isLoadingMarketingCampaigns: true,
  marketingCampaigns: [],
  selectedMarketingCampaign: null,
}

const handlers = {
  [GET_MARKETING_CAMPAIGNS]: (state, { payload = {} }) => {
    if (!payload?.campaigns) return { ...state, errorInRequest: true, isLoadingMarketingCampaigns: false }

    return { ...state, marketingCampaigns: payload?.campaigns, isLoadingMarketingCampaigns: false }
  },
  [UPLOAD_MARKETING_CAMPAIGN]: (state, { payload = {} }) => {
    if (!payload?.campaign) return { ...state, errorInRequest: true }

    return { ...state, marketingCampaigns: [payload?.campaign, ...state.marketingCampaigns] }
  },
  [UPDATE_MARKETING_CAMPAIGN]: (state, { payload = {} }) => {
    if (!payload?.campaign) return { ...state, errorInRequest: true }

    return {
      ...state,
      marketingCampaigns: state.marketingCampaigns.map((campaign) =>
        campaign.idMarketingCampaign === payload?.campaign?.idMarketingCampaign ? payload?.campaign : campaign
      ),
    }
  },
  [DELETE_MARKETING_CAMPAIGN]: (state, { payload = {} }) => {
    if (!payload?.campaign) return { ...state, errorInRequest: true }

    return {
      ...state,
      marketingCampaigns: state.marketingCampaigns.filter(
        ({ idMarketingCampaign }) => idMarketingCampaign !== payload?.campaign?.idMarketingCampaign
      ),
    }
  },
  [UPLOAD_MARKETING_CAMPAIGN_COMMENT]: (state, { payload = {} }) => {
    if (!payload?.comment) return { ...state, errorInRequest: true }

    const campaignId = payload.comment.idMarketingCampaign

    return {
      ...state,
      marketingCampaigns: state.marketingCampaigns.map((_campaign) => {
        if (_campaign.idMarketingCampaign === campaignId) {
          return {
            ..._campaign,
            comments: [..._campaign.comments, payload?.comment],
          }
        } else return _campaign
      }),
    }
  },
  [SELECT_MARKETING_CAMPAIGN]: (state, { payload }) => {
    return {
      ...state,
      selectedMarketingCampaign: payload,
    }
  },
}

const marketing = (state = initialState, action) => {
  const { type } = action

  return handlers[type] ? handlers[type](state, action) : state
}

export default marketing
