import { Chip } from '@material-ui/core'
import { TimePicker } from '@material-ui/pickers'
import { sendErrorMessage } from 'actions/modals'
import classNames from 'classnames'
import CKTextEditor from 'components/CKTextEditor'
import Select from 'components/common/Select/Select'
import Switch from 'components/common/Switch'
import { formatDate, getDateWithHour } from 'helpers/date'
import useLocale from 'hooks/useLocale'
import useUsers from 'hooks/useUsers'
import React, { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import styles from './ScheduleModalEventGeneral.module.scss'

const ScheduleModalEventGeneral = ({ form, setForm }) => {
  const locale = useLocale()
  const dispatch = useDispatch()
  const { event } = form ?? {}
  const { companyUsers } = useUsers()

  const [selectUsers, setSelectUsers] = useState(false)

  const companyUsersEntities = useMemo(
    () =>
      companyUsers.reduce((acc, _user) => {
        acc[_user.idUser] = _user
        return acc
      }, {}),
    [companyUsers]
  )

  const onChangeTextEditor = (_, editor) => {
    const value = editor.getData()
    setForm((form) => {
      form.event.description = value
    })
  }

  const onChangeAllDay = (value) =>
    setForm((form) => {
      form.event.startTime = formatDate(form.general.time, 'HH:mm', true)
      form.event.endTime = formatDate(form.general.time, 'HH:mm', true)

      form.event.isAllDay = value
    })

  const onChangeTime = (value) => {
    const time = formatDate(value, 'HH:mm', true)

    const [hours, minutes] = event.startTime.split(':')
    const [targetHours, targetMinutes] = time.split(':')

    if (targetHours < hours || (hours === targetHours && targetMinutes < minutes)) {
      dispatch(sendErrorMessage(locale === 'es' ? 'Escoja una hora en el futuro' : 'Choose a time in the future'))
      return
    }

    setForm((form) => {
      form.event.endTime = time
    })
  }

  const onSelectUser = (value) => {
    setForm((form) => {
      form.event.users = value
    })
  }

  const handleDeleteUser = (_user) =>
    setForm((form) => {
      form.event.users = form.event.users.filter((item) => item !== _user)
    })

  return (
    <section>
      <div id="calendar-container">
        <CKTextEditor data={event.description ?? ''} onChange={onChangeTextEditor} config={{ autoGrow_maxHeight: 200 }} />
      </div>
      <Switch
        checked={event.isAllDay}
        onChange={onChangeAllDay}
        rootClass={styles.rootClass}
        label={locale === 'es' ? 'Todo el día' : 'All day'}
      />
      {!event.isAllDay && (
        <section className={styles.timePickers}>
          <TimePicker
            fullWidth
            disabled
            minutesStep={10}
            label={locale === 'es' ? 'Hora de inicio' : 'Start time'}
            value={getDateWithHour(event.startTime)}
          />
          <TimePicker
            fullWidth
            minutesStep={10}
            label={locale === 'es' ? 'Hora de fin' : 'End time'}
            value={getDateWithHour(event.endTime)}
            onChange={onChangeTime}
          />
        </section>
      )}
      <Switch
        disabled={!companyUsers.length}
        checked={selectUsers}
        onChange={(value) => {
          setForm((form) => {
            if (value) {
              form.event.users = []
            } else {
              form.event.users = companyUsers.map((_user) => _user.idUser)
            }
          })
          setSelectUsers(value)
        }}
        rootClass={classNames(styles.rootClass, styles.usersSwitch)}
        label={locale === 'es' ? 'Asignar a usuarios' : 'Aisgn users'}
      />
      {Boolean(selectUsers) && (
        <>
          <Select
            fullWidth
            multiple
            options={companyUsers?.map((_user) => ({ value: _user.idUser, text: `${_user.firstName} ${_user.lastName}` }))}
            name="users"
            label={locale === 'es' ? 'Asignar usuarios' : 'Assign users'}
            value={event.users}
            onChange={(e) => onSelectUser(e.target.value)}
            isRequired={false}
          />

          <div className={styles.usersChips}>
            {event.users.map((_user) => (
              <Chip
                key={_user}
                color="primary"
                onDelete={() => handleDeleteUser(_user)}
                label={`${companyUsersEntities[_user].firstName} ${companyUsersEntities[_user].lastName}`}
              />
            ))}
          </div>
        </>
      )}
    </section>
  )
}

export default ScheduleModalEventGeneral
