import { Button, Checkbox, FormControlLabel, FormGroup, InputAdornment, SvgIcon, TextField } from '@material-ui/core'
import clsx from 'clsx'
import Dialog from 'components/Dialog/Dialog'
import useLocale from 'hooks/useLocale'
import React, { useCallback, useEffect, useState } from 'react'
import { Search as SearchIcon } from 'react-feather'
import styles from './SelectCustomersModal.module.scss'

const ClientCheckbox = ({ customer, handleSelect, checked, type }) => (
  <FormGroup row onChange={() => handleSelect(customer.idClient ?? customer.idUser)}>
    <FormControlLabel
      classes={{ root: styles.labelRoot }}
      label={
        type === 1
          ? `${customer.name} ${customer?.email ? `(${customer.email})` : ''}`
          : `${customer.firstName} ${customer.lastName} | ${customer.company.name}`
      }
      control={<Checkbox checked={checked} color="primary" id={customer.id} />}
    />
  </FormGroup>
)

const SelectCustomersModal = ({ open, setOpen, selectedCustomers, setSelectedCustomers, customers, type = 1 }) => {
  const locale = useLocale()
  const [clients, setClients] = useState([...customers])
  const [selectAll, setSelectAll] = useState(false)
  const [searchRef, setSearchRef] = useState()

  const handleSearch = useCallback((event) => {
    setSearchRef(event.target.value.toLowerCase())
  }, [])

  const handleSelect = useCallback(
    (idClient) => {
      if (selectedCustomers.indexOf(idClient) >= 0) {
        const newSelectedCustomers = selectedCustomers.filter((customer) => customer !== idClient)
        setSelectedCustomers(newSelectedCustomers)

        return
      }

      const newSelectedCustomers = [...selectedCustomers, idClient]
      setSelectedCustomers(newSelectedCustomers)
    },
    [selectedCustomers, setSelectedCustomers]
  )

  const handleSelectAll = useCallback(() => {
    if (selectedCustomers.length === clients.length) {
      setSelectedCustomers([])

      return
    } else {
      const newSelectedCustomers = clients.map((client) => client.idClient ?? client.idUser)
      setSelectedCustomers([...newSelectedCustomers])

      return
    }
  }, [selectedCustomers, setSelectedCustomers, clients])

  useEffect(() => {
    if (selectedCustomers.length !== customers.length) {
      setSelectAll(false)
    } else if (selectedCustomers.length === customers.length) {
      setSelectAll(true)
    }
  }, [selectedCustomers, customers])

  useEffect(() => {
    if (!searchRef) return setClients([...customers.filter((client) => client.email)])

    const regex = new RegExp(`${searchRef}`, 'gi')
    const filteredClients = customers
      .filter((client) => client.email)
      .filter((client) => regex.test(client.name ?? `${client.firstName} ${client.lastName}`))

    setClients(filteredClients)
  }, [searchRef, customers])

  return (
    <>
      <Button
        variant="contained"
        className={clsx(styles.selectClientsButton, { [styles.selectedClients]: selectedCustomers.length > 0 })}
        disableElevation
        onClick={() => setOpen(true)}
      >
        {type === 1
          ? locale === 'es'
            ? 'Seleccionar clientes '
            : 'Select clients '
          : locale === 'es'
          ? 'Seleccionar usuarios '
          : 'Select users '}
        ({selectedCustomers.length})
      </Button>

      <Dialog
        title={
          type === 1
            ? locale === 'es'
              ? 'Seleccione sus clientes'
              : 'Select your clients'
            : locale === 'es'
            ? 'Seleccione a los usuarios'
            : 'Select the users'
        }
        open={open}
        setOpen={setOpen}
        maxWidth={'sm'}
        disableEnforceFocus={true}
        rootClassName={styles.dialogRoot}
      >
        <section className={styles.searchBar}>
          <TextField
            onChange={handleSearch}
            value={searchRef}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            placeholder={
              type === 1
                ? locale === 'es'
                  ? 'Buscar cliente'
                  : 'Search client'
                : locale === 'es'
                ? 'Buscar usuarios'
                : 'Search users'
            }
            variant="outlined"
          />
          {!searchRef && (
            <Button
              onClick={handleSelectAll}
              variant="outlined"
              size="small"
              className={clsx(styles.selectAll, { [styles.allSelected]: selectedCustomers.length === clients.length })}
              fullWidth
            >
              {selectAll
                ? locale === 'es'
                  ? 'Todos seleccionados'
                  : 'All selected'
                : locale === 'es'
                ? 'Seleccionar todos'
                : 'Select all'}
            </Button>
          )}
        </section>

        <section className={styles.clients}>
          {clients.length ? (
            clients.map((client) => (
              <ClientCheckbox
                type={type}
                key={client.idClient}
                customer={client}
                handleSelect={handleSelect}
                checked={
                  client.idClient
                    ? selectedCustomers.indexOf(client.idClient) >= 0
                    : selectedCustomers.indexOf(client.idUser) >= 0
                }
              />
            ))
          ) : (
            <p className={styles.clientNotFound}>No se encontraron clientes con ese nombre...</p>
          )}
        </section>
      </Dialog>
    </>
  )
}

export default SelectCustomersModal
