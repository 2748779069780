import { Avatar, Menu, MenuItem } from '@material-ui/core'
import { DASHBOARD_PAGE_URL } from 'constants/urls'
import useLocale from 'hooks/useLocale'
import useUsers from 'hooks/useUsers'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { getProps } from 'routes'
import styles from './userMenu.module.scss'

const DEFAULT_OPTION_ICON = 'fa fa-angle-right horizontal-icon'
const ID_MENU = 'user-options-menu'

const MenuLink = (props) => {
  const { name, link, handleClose, icon, preload, baseUrl } = props
  const { pathname: currentPath } = useLocation()

  useEffect(() => {
    if (typeof preload === 'function') preload()
  }, [preload])

  return (
    <Link key={name} to={link} onClick={handleClose}>
      <MenuItem className={currentPath.includes(baseUrl) && styles.menuItem}>
        <i className={icon || DEFAULT_OPTION_ICON} />
        {name}
      </MenuItem>
    </Link>
  )
}

const UserMenu = () => {
  const locale = useLocale()
  const [anchorEl, setAnchorEl] = useState()
  const { myUser } = useUsers()
  const { firstName, type, avatarPhoto } = myUser

  const ROL_BY_TYPE = useMemo(
    () => ({ 1: locale === 'es' ? 'Administrador' : 'Administrator', 2: locale === 'es' ? 'Cliente' : 'Client' }),
    [locale]
  )

  const MENU_OPTIONS = useMemo(() => {
    let items = [
      {
        icon: 'fa fa-bar-chart',
        name: 'Dashboard',
        link: `/${DASHBOARD_PAGE_URL}`,
        baseUrl: DASHBOARD_PAGE_URL,
      },
    ]
    return getProps(items)
  }, [])

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback((name) => {
    setAnchorEl(null)
  }, [])

  return (
    <>
      <Avatar className={styles.userAvatar} src={avatarPhoto} aria-controls={ID_MENU} onClick={handleClick} />
      {Boolean(anchorEl) && (
        <Menu
          id={ID_MENU}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className={styles.userMenu}
        >
          <div className={styles.basicUserInfo}>
            <span>{firstName}</span>
            <span>{ROL_BY_TYPE?.[type] ?? 'User'}</span>
          </div>
          {MENU_OPTIONS?.filter(({ hideIf }) => !hideIf).map(({ icon, name, link, onClick, preloadComponent, baseUrl }) =>
            link ? (
              <MenuLink
                name={name}
                link={link}
                handleClose={handleClose}
                icon={icon}
                preload={preloadComponent}
                baseUrl={baseUrl}
              />
            ) : (
              <MenuItem
                key={name}
                onClick={() => {
                  onClick()
                  handleClose()
                }}
              >
                <i className={icon || DEFAULT_OPTION_ICON} />
                {name}
              </MenuItem>
            )
          )}
        </Menu>
      )}
    </>
  )
}

export default UserMenu
