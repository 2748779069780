import { Button } from '@material-ui/core'
import Dialog from 'components/Dialog/Dialog'
import getContent from 'constants/cookiesAlert'
import { COOKIES_POLICY_PAGE_URL, PRIVACY_POLICY_PAGE_URL, USE_POLICY_PAGE_URL } from 'constants/urls'
import useLocale from 'hooks/useLocale'
import { useCallback } from 'react'
import { Link } from 'react-router-dom'
import styles from './CookiesAlertModal.module.scss'

export const CookiesAlertModal = ({ open, setOpen }) => {
  const locale = useLocale()
  const content = getContent(locale)

  const onAccept = useCallback(() => {
    localStorage.setItem('digitalPymes-cookiesAlert', 'true')

    setOpen(false)
  }, [setOpen])

  return (
    <Dialog open={open} setOpen={setOpen}>
      <section className={styles.cookiesDialogContainer}>
        <header className={styles.header}>
          <h3>{content?.title}</h3>
        </header>

        <div className={styles.content}>
          <p>{content?.content}</p>
        </div>

        <div className={styles.getMoreDetails}>
          <p>
            {content?.getMoreDetails?.firstPart}
            <Link to={`/${PRIVACY_POLICY_PAGE_URL}`} className={styles.link}>
              {content?.getMoreDetails?.link}
            </Link>
            {', '}
            <Link to={`/${USE_POLICY_PAGE_URL}`} className={styles.link}>
              {locale === 'es' ? 'Políticas de Uso' : 'Terms of Use'}
            </Link>
            {', y '}
            <Link to={`/${COOKIES_POLICY_PAGE_URL}`} className={styles.link}>
              {locale === 'es' ? 'Políticas de Cookies' : 'Cookies Policy'}
            </Link>
            {content?.getMoreDetails?.lastPart}
          </p>
        </div>

        <Button disableElevation onClick={onAccept} className={styles.acceptButton} color="primary" variant="contained" fullWidth>
          {content?.acceptButton}
        </Button>
      </section>
    </Dialog>
  )
}
