import React, { useState } from 'react'
import TextEditor from 'components/TextEditor'
import styles from './MarketingCampaignCommets.module.scss'
import useLocale from 'hooks/useLocale'
import useUsers from 'hooks/useUsers'
import { useParams } from 'react-router'
import { postMarketingCampaignComment } from 'actions/marketingCampaigns'
import { useDispatch } from 'react-redux'
import LoadingSection from 'components/LoadingSection'
import { Button } from '@material-ui/core'
import useTextEditor from 'hooks/useTextEditor'
import Comment from 'components/Comment'

const MarketingCampaignCommets = ({ comments }) => {
  const locale = useLocale()
  const dispatch = useDispatch()
  const { id } = useParams()
  const { myUser } = useUsers()

  const {
    commentSectionRef,
    commenting,
    comment,
    toggleCommenting,
    isCommentEmpty,
    scrollToBottom,
    onChange,
    onSave,
    setComment,
  } = useTextEditor()
  const [pendingComment, setPendingComment] = useState(false)

  const handleSaveComment = () =>
    onSave(
      () =>
        new Promise(async (resolve) => {
          setPendingComment(true)
          const response = await postMarketingCampaignComment({
            idUser: myUser.idUser,
            idMarketingCampaign: Number(id),
            comment,
          })

          dispatch(response)

          scrollToBottom()
          setPendingComment(false)

          resolve(response)
        })
    )

  return (
    <section className={styles.mainContainer}>
      <h2 className={styles.title}>{locale === 'es' ? 'Comentarios' : 'Comments'}</h2>

      <section className={styles.commentsSection} ref={commentSectionRef}>
        {comments.length ? (
          comments.map((_comment) => <Comment key={_comment.idMarketingCampaignComment} _comment={_comment} />)
        ) : (
          <p className={styles.emptyComments}>{locale === 'es' ? 'No hay comentarios...' : 'No comments...'}</p>
        )}
      </section>

      <section className={styles.comment}>
        {commenting ? (
          <>
            <TextEditor value={comment} onChange={onChange} readOnly={pendingComment} editorRootClass={styles.textEditor} />
            {pendingComment ? (
              <section style={{ marginTop: '1rem' }}>
                <LoadingSection />
              </section>
            ) : (
              <section className={styles.buttons}>
                <Button
                  color="primary"
                  variant="text"
                  onClick={() => {
                    toggleCommenting()
                    setComment('')
                  }}
                >
                  {locale === 'es' ? 'Cancelar' : 'Cancel'}
                </Button>

                <Button color="primary" variant="contained" onClick={handleSaveComment} disabled={isCommentEmpty}>
                  {locale === 'es' ? 'Guardar' : 'Save'}
                </Button>
              </section>
            )}
          </>
        ) : (
          <section className={styles.createComment} onClick={toggleCommenting}>
            <i className="fas fa-comment" />
            <p>{locale === 'es' ? 'Comentar...' : 'Comment...'}</p>
          </section>
        )}
      </section>
    </section>
  )
}

export default MarketingCampaignCommets
