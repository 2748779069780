import { CircularProgress } from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'
import styles from './LoadingSection.module.scss'

const LoadingSection = ({ message = '', className = '' }) => {
  return (
    <section className={classNames(styles.container, className)}>
      <CircularProgress />
      {message ? <p>{message}</p> : null}
    </section>
  )
}

export default LoadingSection
