import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'

const useScreen = () => {
  const [breackpoint, setBreackpoint] = useState({})
  const { pathname } = useLocation()

  useEffect(() => {
    const handleResize = () => {
      setBreackpoint(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return { breackpoint, currentPath: pathname }
}

export default useScreen
