import React from 'react'
import styles from './LoadingSnackbar.module.scss'
import { CircularProgress, Snackbar, SnackbarContent } from '@material-ui/core'

const LoadingSnackbar = ({ open, message }) => {
  const loadingMessage = (
    <div className={styles.loadingMessage}>
      <CircularProgress size={16} />
      <p className={styles.message}>{message}</p>
    </div>
  )

  return (
    <Snackbar open={open} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
      <SnackbarContent message={loadingMessage} />
    </Snackbar>
  )
}

export default LoadingSnackbar
