export const TABLE_SPECIFICATION = [
  {
    es: 'Calendario',
    en: 'Calendar',
    table: 'calendar_actions',
    masterKey: 'calendarActions',
    groupingKey: 'originalActions',
  },
  {
    es: 'Publicaciones',
    en: 'Posts',
    table: 'posts',
    masterKey: 'socialmedia',
    groupingKey: 'posts',
  },
  {
    es: 'Correos masivos',
    en: 'Mass emails',
    table: 'mass_emails',
    masterKey: 'messages',
    groupingKey: 'massEmails',
  },
  {
    es: 'Formularios',
    en: 'Forms',
    table: 'forms',
    masterKey: 'clientForms',
    groupingKey: 'forms',
  },
  {
    es: 'Campañas  de marketing',
    en: 'Marketing campaigns',
    table: 'marketing_campaigns',
    masterKey: 'marketing',
    groupingKey: 'marketingCampaigns',
  },
  {
    es: 'Clientes',
    en: 'Clients',
    table: 'customers',
    masterKey: 'customers',
    groupingKey: 'customers',
  },
  {
    es: 'Ingresos y gastos',
    en: 'Incomes and expenses',
    table: 'projects',
    masterKey: 'projects',
    groupingKey: 'projects',
  },
  {
    es: 'Cotizaciones',
    en: 'Quotations',
    table: 'quotations',
    masterKey: 'quotations',
    groupingKey: 'quotations',
  },
  {
    es: 'Costos',
    en: 'Costs',
    table: 'costs',
    masterKey: 'costs',
    hasSubModules: true,
    subModules: [
      {
        es: 'Productos',
        en: 'Products',
        subTable: 'productsCosts',
        groupingKey: 'productCosts',
      },
      {
        es: 'Costos indirectos',
        en: 'Indirect costs',
        subTable: 'indirectCosts',
        groupingKey: 'indirectCosts',
      },
    ],
  },
  {
    es: 'Finanzas personales',
    en: 'Personal finances',
    table: 'personal_finances',
    masterKey: 'personalFinances',
    hasSubModules: true,
    subModules: [
      {
        es: 'Transacciones',
        en: 'Transactions',
        subTable: 'personalFinanceTransactions',
        groupingKey: 'personalFinanceTransactions',
      },
      {
        es: 'Ahorros',
        en: 'Savings',
        subTable: 'personalFinanceSavings',
        groupingKey: 'personalFinanceSavings',
      },
      {
        es: 'Creditos',
        en: 'Creditos',
        subTable: 'personalFinanceCredits',
        groupingKey: 'personalFinanceCredits',
      },
      {
        es: 'Tarjetas',
        en: 'Cards',
        subTable: 'personalFinanceCards',
        groupingKey: 'personalFinanceCards',
      },
    ],
  },
  {
    es: 'Recursos humanos',
    en: 'Human resources',
    hasSubModules: true,
    table: 'human_resources',
    masterKey: 'humanResources',
    subModules: [
      {
        es: 'Departamentos',
        en: 'Departments',
        subTable: 'departments',
        groupingKey: 'departments',
      },
      {
        es: 'Posiciones',
        en: 'Positiones',
        subTable: 'positions',
        groupingKey: 'positions',
      },
      {
        es: 'Horarios',
        en: 'Schedules',
        subTable: 'schedules',
        groupingKey: 'schedules',
      },
      {
        es: 'Deducciones',
        en: 'Deductions',
        subTable: 'deductions',
        groupingKey: 'deductions',
      },
      {
        es: 'Empleados',
        en: 'Employees',
        subTable: 'employees',
        groupingKey: 'employees',
      },
      {
        es: 'Eventos',
        en: 'Events',
        subTable: 'events',
        groupingKey: 'events',
      },
    ],
  },
  {
    es: 'Inventario',
    en: 'Inventory',
    table: 'inventory',
    masterKey: 'inventory',
    hasSubModules: true,
    subModules: [
      {
        es: 'Categorias',
        en: 'Categories',
        subTable: 'categories',
        groupingKey: 'categories',
      },
      {
        es: 'Proveedores',
        en: 'Providers',
        subTable: 'providers',
        groupingKey: 'providers',
      },
      {
        es: 'Productos',
        en: 'Products',
        subTable: 'products',
        groupingKey: 'products',
      },
    ],
  },
  {
    es: 'Espacios de trabajo',
    en: 'Workspaces',
    table: 'boards',
    masterKey: 'boards',
    hasSubModules: true,
    subModules: [
      {
        es: 'Espacios de trabajo',
        en: 'Workspaces',
        subTable: 'boards',
        groupingKey: 'boards',
      },
      {
        es: 'Plantillas',
        en: 'Templates',
        subTable: 'boardTemplates',
        groupingKey: 'boardsTemplates',
      },
    ],
  },
  {
    es: 'Flujo de caja',
    en: 'Cash flow',
    table: 'cash_flows',
    masterKey: 'cashFlow',
    hasSubModules: true,
    subModules: [
      {
        es: 'Ingresos',
        en: 'Incomes',
        subTable: 'baseIncomes',
        groupingKey: 'baseIncomes',
      },
      {
        es: 'Gastos',
        en: 'Expenses',
        subTable: 'baseExpenses',
        groupingKey: 'baseExpenses',
      },
    ],
  },
]

export const FORM_CONTENT = (() => {
  const locale = localStorage.getItem('locale')

  const content = {
    title: {
      es: 'Título de indicador',
      en: 'Indicator title',
    },
    module: {
      es: 'Apartado del sistema',
      en: 'System module',
    },
    subModule: {
      es: 'Sub-apartado del sistema',
      en: 'System sub-module',
    },
    indicatorType: {
      es: 'Tipo de indicador',
      en: 'Indicator type',
    },
    values: {
      es: 'Valores',
      en: 'Values',
    },
    subTable: {
      es: 'Sub-apartado del sistema',
      en: 'System sub-module',
    },
  }

  return Object.entries(content).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: value[locale],
    }),
    {}
  )
})()

export const INDICATOR_TYPES = (() => {
  const locale = localStorage.getItem('locale')

  return [
    {
      text: locale === 'es' ? 'Totalizaciones' : 'Totalizations',
      value: 'totalization',
    },
    {
      text: locale === 'es' ? 'Gráficos' : 'Graphics',
      value: 'graphic',
    },
  ]
})()

export const MODULES = (() => {
  const locale = localStorage.getItem('locale')
  const content = TABLE_SPECIFICATION

  return content.map((value) => {
    return {
      text: value[locale],
      value: value.table,
    }
  })
})()
