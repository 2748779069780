import AuthValidation from 'components/AuthValidation/AuthValidation'
import { Footer } from 'components/Footer/Footer'
import React from 'react'
import Functionalities from './components/Functionalities'
import Hero from './components/Hero'
import PageHeader from './components/PageHeader'
import Plans from './components/Plans'
import styles from './ProLandingPage.module.scss'

const ProLandingPage = () => {
  return (
    <AuthValidation>
      <PageHeader />
      <main className={styles.wrapper}>
        <Hero />
        <Plans />
        <Functionalities />
      </main>
      <Footer rootClassName={styles.footer} />
    </AuthValidation>
  )
}

export default ProLandingPage
