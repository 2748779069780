import { useMediaQuery } from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { dragItemsTypes } from '../dragItemsTypes'
import styles from './Folder.module.scss'
import { useDragAndDrop } from './useDragAndDrop'

const Folder = ({ id, name, icon, onContextMenu, parents }) => {
  const navigate = useNavigate()
  const isMobile = useMediaQuery('(max-width: 768px)')
  const [searchParams] = useSearchParams()
  const isShared = searchParams.get('shared')
  const isTrashed = searchParams.get('trashed')

  const { dropItem } = useDragAndDrop(id)

  const openFolder = () => {
    if (isTrashed) {
      toast.info('No puedes abrir carpetas en la papelera.', {
        autoClose: 2000,
        theme: 'colored',
      })

      return
    }

    window.scroll(0, 0)
    const buildLink = (id) => {
      const shared = isShared ? '?shared=true' : ''
      const trashed = isTrashed ? '?trashed=true' : ''

      return `/dashboard/managment/google-drive/folders/${id}${shared}${trashed}`
    }

    navigate(buildLink(id))
  }

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: [dragItemsTypes.FILE, dragItemsTypes.FOLDER],
      drop: dropItem,
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
      item: {
        id,
        parents,
        type: dragItemsTypes.FOLDER,
      },
    }),
    []
  )

  const [{ isDragging }, drag] = useDrag(() => ({
    type: dragItemsTypes.FOLDER,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    item: {
      id,
      type: dragItemsTypes.FOLDER,
    },
  }))

  return (
    <div
      className={classNames(styles.overflowHidden, isOver && styles.over)}
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
      }}
    >
      <div
        ref={drop}
        className={styles.wrapper}
        onClick={isMobile ? openFolder : null}
        onDoubleClick={openFolder}
        onContextMenu={onContextMenu}
        data-id={id}
        data-type="folder"
      >
        <figure className={styles.iconContainer}>
          <img draggable={false} width={18} height={18} referrerPolicy="no-referrer" src={icon} alt="" />
        </figure>
        <strong className={styles.title} title={name}>
          {name}
        </strong>
      </div>
    </div>
  )
}

export default Folder
