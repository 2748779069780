// **************
// Breakpoints
// **************
const MD = 768
const LG = 1024
const XL = 1366

export const isMobile = (breackpoint) => breackpoint <= MD

export const isMobileOrTablet = (breackpoint) => breackpoint <= LG

export const isDesktop = (breackpoint) => breackpoint >= XL
