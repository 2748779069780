import Header from 'components/Header/Header'
import HeaderMenu from 'components/HeaderMenu/HeaderMenu'
import { isMobileOrTablet } from 'helpers/breackpoints'
import useScreen from 'hooks/useScreen'
import React from 'react'

const PageHeader = () => {
  const { breackpoint } = useScreen()

  return (
    <div>
      <Header />
      {!isMobileOrTablet(breackpoint) && <HeaderMenu />}
    </div>
  )
}

export default PageHeader
