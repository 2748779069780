import React from 'react'
import { Breadcrumbs as MUIBreadcrumbs } from '@material-ui/core'
import { Link } from 'react-router-dom'
import useLocale from 'hooks/useLocale'
import styles from './Breadcrumbs.module.scss'

const Breadcrumbs = ({ campaignName }) => {
  const locale = useLocale()

  return (
    <MUIBreadcrumbs separator=">" classes={{ root: styles.root }}>
      <Link to=".." className={styles.breacrumsLink}>
        {locale === 'es' ? 'Campañas de Marketing' : 'Marketing Campaign'}
      </Link>
      <Link to="." className={`${styles.breacrumsLink} ${styles.selected}`}>
        {campaignName}
      </Link>
    </MUIBreadcrumbs>
  )
}

export default Breadcrumbs
