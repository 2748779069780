const { useRef } = require('react')

/**
 * useApi create la referencia del `aborter` y devuelve una funcion
 * para poder hacer uso de la cancelacion de eventos
 *
 * @param {function} callback
 * @returns function
 */
export default function useCancelApi(callback) {
  const abortRef = useRef({})

  const executor = async (...params) => {
    abortRef.current.abort?.()

    if (typeof callback === 'function') callback(abortRef, ...params)
  }

  return [executor, abortRef]
}
