const getReportErrorStatuses = (locale) => ({
  PENDING: {
    value: 'PENDING',
    color: '#585858',
    text: locale === 'es' ? 'Pendiente' : 'Pending',
  },

  IN_DEVELOPMENT: {
    value: 'IN_DEVELOPMENT',
    color: '#6386E0',
    text: locale === 'es' ? 'En desarrollo' : 'In development',
  },
  COMPLETED: {
    value: 'COMPLETED',
    color: '#A0C28B',
    text: locale === 'es' ? 'Completado' : 'Completed',
  },
})

export default getReportErrorStatuses
