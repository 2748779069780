import { ENV_SELECTED } from 'env'

const ENVS = {
  local: 'http://localhost:8000/api/',
  qa: 'https://qa-digital-pymes-service.ingeniust.com/api/',
  prod: 'https://digital-pymes-service.ingeniust.com/api/',
}

const END_POINT_ENV = ENVS[ENV_SELECTED]

// Users
export const USERS_API_ROUTE = `${END_POINT_ENV}users`
export const COMPANY_USERS_API_ROUTE = `${END_POINT_ENV}listUsers`

// Rols
export const ROLS_API_ROUTE = `${END_POINT_ENV}rols`

// Companies
export const COMPANIES_API_ROUTE = `${END_POINT_ENV}company`

// Customers
export const CUSTOMERS_API_ROUTE = `${END_POINT_ENV}client`
export const PARSE_CLIENTS_FILE = `${END_POINT_ENV}client/parse-clients-file`
export const MASSIVE_CLIENTS_UPLOAD = `${END_POINT_ENV}client/massive-clients-upload`
export const GET_CUSTOMERS_API_ROUTE = `${END_POINT_ENV}listClient`

// Contacs
export const CONTACS_API_ROUTE = `${END_POINT_ENV}contact`
export const GET_CONTACS_API_ROUTE = `${END_POINT_ENV}listContact`

// Configs
export const CONFIG_API_ROUTE = `${END_POINT_ENV}configs`
export const CONFIG_VALIDATE_EMAIL = `${END_POINT_ENV}configs/testGmail`

// Routes
export const GET_HISTORIC_API_ROUTE = `${END_POINT_ENV}historic`

// SocialMedia
export const POSTS_API_ROUTE = `${END_POINT_ENV}posts`
export const GET_FB_PAGES_BY_TOKEN_API_ROUTE = `${END_POINT_ENV}posts/getFbPagesByToken`
export const GET_FB_PAGES_API_ROUTE = `${END_POINT_ENV}posts/getFbPages`
export const SCHEDULE_POST_API_ROUTE = `${END_POINT_ENV}posts/schedulePost`
export const DELETE_FB_PAGES_BY_TOKEN_API_ROUTE = `${END_POINT_ENV}posts/deleteFbPagesByToken`

// Images
export const STORE_IMAGE_API_ROUTE = `${END_POINT_ENV}uploadImage`
export const DELETE_IMAGE_ROUTE = `${END_POINT_ENV}deleteUserAsset`

// Tools
export const TOOLS_API_ROUTE = `${END_POINT_ENV}tools`

// Messages
export const MASS_EMAILS_API_ROUTE = `${END_POINT_ENV}mail`
export const SEND_MASS_EMAIL_API_ROUTE = `${END_POINT_ENV}mail/send`
export const SCHEDULE_MASS_EMAIL_API_ROUTE = `${END_POINT_ENV}mail/scheduleMail`
export const SEND_REPORT = `${END_POINT_ENV}mail/report`

// Dashboard
export const GET_CUSTOMERS_SUM_STAT = `${END_POINT_ENV}getCustomersSum`
export const GET_USERS_SUM_STAT = `${END_POINT_ENV}getUsersSum`
export const GET_MASS_EMAILS_SUM_STAT = `${END_POINT_ENV}getMassEmailsSum`
export const GET_POSTS_SUM_STAT = `${END_POINT_ENV}getPostsSum`
export const GET_COMPANIES_SUM_STAT = `${END_POINT_ENV}getCompaniesSum`

// Calendar Actions
export const CALENDAR_ACTIONS_ROUTE = `${END_POINT_ENV}calendarActions`
export const CALENDAR_ACTIONS_COMMENT_ROUTE = `${END_POINT_ENV}calendarActions/comment`
export const CALENDAR_EVENTS_ROUTE = `${END_POINT_ENV}calendarEvent`

// Client Groups
export const CLIENT_GROUPS_ROUTE = `${END_POINT_ENV}clientGroups`

// Projects
export const PROJECTS_ROUTE = `${END_POINT_ENV}projects`
export const MOVEMENTS_ROUTE = `${END_POINT_ENV}movements`

// Company Information
export const COMPANY_INFORMATION_PAGES = `${END_POINT_ENV}info-pages`
export const COMPANY_INFOMARTION_QUESTIONS = `${END_POINT_ENV}info-questions`
export const COMPANY_INFOMARTION_ANSWERS = `${END_POINT_ENV}info-pages/answers`

// Forms
export const COMPANY_FORMS = `${END_POINT_ENV}client-forms`
export const COMPANY_FORMS_PAGES = `${END_POINT_ENV}client-forms/pages`
export const COMPANY_FORMS_QUESTIONS = `${END_POINT_ENV}client-forms/questions`
export const UPDATE_LIST_POSITIONS = `${END_POINT_ENV}client-forms/update-positions`
export const COMPANY_FORMS_ANSWERS = `${END_POINT_ENV}client-forms/answers`
export const COMPANY_FORMS_QUESTIONS_ANSWERS = `${END_POINT_ENV}client-forms/questions-answers`
export const ANSWERS_PER_QUESTION_ROUTE = `${END_POINT_ENV}client-forms/answers-per-question`
export const QUESTION_OPTION_ROUTE = `${END_POINT_ENV}client-forms/option`
export const CREATE_EXCEL = (id) => `${END_POINT_ENV}create-excel/${id}`

// Costs
export const PRODUCTS_COSTS_ROUTE = `${END_POINT_ENV}productsCosts`
export const INDIRECT_COSTS_ROUTE = `${END_POINT_ENV}indirectsCosts`
export const WORKFORCE_ROUTE = `${END_POINT_ENV}workforce`
export const RAW_MATERIAL_ROUTE = `${END_POINT_ENV}rawMaterials`
export const PRODUCT_INDIRECT_COST_ROUTE = `${END_POINT_ENV}productIndirectCosts`

// Processes
export const PROCESS_ROUTE = `${END_POINT_ENV}process`
export const PROCESS_SHAPE_ROUTE = `${END_POINT_ENV}process-shapes`

// Custom roles
export const CUSTOM_ROLES_ROUTE = `${END_POINT_ENV}custom-roles`

// Human Resources
export const DEPARTMENTS_ROUTE = `${END_POINT_ENV}departments`
export const POSITIONS_ROUTE = `${END_POINT_ENV}positions`
export const SCHEDULES_ROUTE = `${END_POINT_ENV}schedules`
export const EMPLOYESS_ROUTE = `${END_POINT_ENV}employees`
export const DEDUCTIONS_ROUTE = `${END_POINT_ENV}deductions`
export const PAYMENT_STUBS_ROUTE = `${END_POINT_ENV}payment-stubs`
export const PAYMENT_STUB_FACTORY_ROUTE = `${END_POINT_ENV}payment-stubs/factory`
export const PAYMENT_STUB_RECURRENCY_ROUTE = `${END_POINT_ENV}payment-stubs/recurrency`
export const EMPLOYEE_FILES_ROUTE = `${END_POINT_ENV}employee-files`
export const UPDATE_DEDUCTIONS_ROUTE = `${END_POINT_ENV}employees/update-deductions`
export const DOWNLOAD_EMPLOYEE_FILE = `${END_POINT_ENV}download-file`
export const WORK_DAY_ROUTE = `${END_POINT_ENV}work-days`
export const WORK_DAY_BREAKS_ROUTE = `${END_POINT_ENV}work-days/breaks`
export const FREE_TIME_ROUTE = `${END_POINT_ENV}free-time`
export const EVENTS_ROUTE = `${END_POINT_ENV}events`
export const BONUSES_ROUTE = `${END_POINT_ENV}bonuses`

// Boards
export const BOARDS_ROUTE = `${END_POINT_ENV}boards`
export const BOARD_TEMPLATES_ROUTE = `${END_POINT_ENV}boardTemplates`
export const BOARD_TEMPLATE_COLUMNS_ROUTE = `${END_POINT_ENV}boardTemplateColumns`
export const BOARD_TEMPLATE_COLUMNS_MOVE_ROUTE = `${END_POINT_ENV}boardTemplateColumns/move`
export const BOARD_TASKS_ROUTE = `${END_POINT_ENV}boardTasks`
export const BOARD_TASKS_COMMENTS_ROUTE = `${END_POINT_ENV}boardTasks/comments`
export const BOARD_TASKS_HISTORIES_ROUTE = `${END_POINT_ENV}boardTasks/histories`
export const BOARD_TASKS_POSITION_ROUTE = `${END_POINT_ENV}boardTasks/position`
export const BOARD_LABELS_ROUTE = `${END_POINT_ENV}boardLabels`
export const BOARD_LISTS_ROUTE = `${END_POINT_ENV}boardLists`
export const BOARD_TASKS_FILES_ROUTE = `${END_POINT_ENV}taskFiles`
export const BOARD_TASK_FILE_DOWNLOAD_ROUTE = `${END_POINT_ENV}download-task-file`

// Marketing
export const MARKETING_CAMPAIGNS_ROUTE = `${END_POINT_ENV}marketing-campaign`
export const MARKETING_CAMPAIGNS_COMMENT_ROUTE = `${END_POINT_ENV}marketing-campaign/comment`
export const MARKETING_CAMPAIGNS_TASK_ROUTE = `${END_POINT_ENV}marketing-campaign/task`

// Report errors
export const REPORT_ERRORS_ROUTE = `${END_POINT_ENV}reportErrors`
export const DOWNLOAD_REPORT_ERROR_FILE_ROUTE = `${END_POINT_ENV}reportErrors/download-file`

// Inventory
export const INVENTORY_CATEGORIES_ROUTE = `${END_POINT_ENV}inventory/categories`
export const INVENTORY_PROVIDERS_ROUTE = `${END_POINT_ENV}inventory/providers`
export const INVENTORY_PRODUCTS_ROUTE = `${END_POINT_ENV}inventory/products`
export const INVENTORY_TRANSACTIONS_ROUTE = `${END_POINT_ENV}inventory/transactions`

// Files
export const DOWNLOAD_FILE_BY_PATH = `${END_POINT_ENV}download/file`

// Images Bank
export const IMAGES_BANK_RECOMMENDATIONS_ROUTE = `${END_POINT_ENV}imagesBank/recommendations`
export const IMAGES_BANK_SEARCH_ROUTE = `${END_POINT_ENV}imagesBank/search`
export const IMAGES_BANK_FAVORITES_ROUTE = `${END_POINT_ENV}imagesBank/favorites`

// Personal Finances
export const PERSONAL_FINANCES_TRANSACTIONS_ROUTE = `${END_POINT_ENV}personal-finances/transactions`
export const PERSONAL_FINANCES_SAVINGS_ROUTE = `${END_POINT_ENV}personal-finances/savings`
export const PERSONAL_FINANCES_SAVINGS_CHANGE_AMOUNT_ROUTE = `${END_POINT_ENV}personal-finances/savings/amount`
export const PERSONAL_FINANCES_CREDITS_ROUTE = `${END_POINT_ENV}personal-finances/credits`
export const PERSONAL_FINANCES_CARDS_ROUTE = `${END_POINT_ENV}personal-finances/cards`

export const INVENTORY_CREATE_EXCEL_ROUTE = (idCompany, params) => `${END_POINT_ENV}inventory/create-excel/${idCompany}?${params}`

// Cash Flow
export const CASH_FLOW_ROUTE = `${END_POINT_ENV}cash-flow`
export const CASH_FLOW_INCOME_ROUTE = `${END_POINT_ENV}cash-flow/incomes`
export const CASH_FLOW_EXPENSE_ROUTE = `${END_POINT_ENV}cash-flow/expenses`
export const DOWNLOAD_CASH_FLOW_EXCEL_ROUTE = `${END_POINT_ENV}download-cash-flow-excel`

// Quotations
export const QUOTATIONS_ROUTE = `${END_POINT_ENV}quotations`
export const QUOTATIONS_COMMENTS_ROUTE = `${END_POINT_ENV}quotations/comment`

// Data Analisis
export const DATANALISIS_PAGES_ROUTE = `${END_POINT_ENV}data-analysis/pages`
export const DATANALISIS_INDICATORS_ROUTE = `${END_POINT_ENV}data-analysis/indicators`
export const DATANALISIS_INDICATORS_UPDATE_LAYOUT_ROUTE = `${END_POINT_ENV}data-analysis/indicators/update-layout`

// Coupons
export const COUPONS_ROUTE = `${END_POINT_ENV}coupons`

// Subscriptions
export const SUBSCRIPTIONS_ROUTE = `${END_POINT_ENV}subscription`

// Permissions
export const PERMISSIONS_ROUTE = `${END_POINT_ENV}permissions`

// Drive Routes
export const DRIVE_USERS_PERMISSIONS = `${END_POINT_ENV}google-drive/permissions`
export const DRIVE_FILES_ROUTE = `${END_POINT_ENV}google-drive`

// Ai Blocks
export const AIBLOCKS_API_ROUTE = `${END_POINT_ENV}ai-blocks`
