import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Link,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import styles from './loginPage.module.scss'

import { signInFirebaseUser } from 'actions/firebaseAuth'
import { openModal, openStepperModal, sendErrorMessage } from 'actions/modals'
import AuthValidation from 'components/AuthValidation/AuthValidation'
import { Footer } from 'components/Footer/Footer'
import LocaleSelection from 'components/LocaleSelection/LocaleSelection'
import ModalsControler from 'components/ModalsControler/ModalsControler'
import { UID_COOKIE } from 'constants/cookies'
import ERROR_MESSAGES from 'constants/errorMessages'
import resetPasswordFormConfig from 'constants/resetPasswordFormConfig'
import { INGENIUST_PAGE, PRIVACY_POLICY_PAGE_URL } from 'constants/urls'
import useLocale from 'hooks/useLocale'
import Cookies from 'js-cookie'
import Page from 'layouts/Page/Page'
import { useDispatch } from 'react-redux'
import { CookiesAlertModal } from './CookiesAlertModal/CookiesAlertModal'
import { RegistryUserSteps } from './RegistryModal/RegistryModal'
import getContent from './content'

const LOGO_IMG = '/logo.svg'

const Copyright = () => {
  const locale = useLocale()
  const { copyright } = getContent(locale)

  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="/">
        CEDECC Social Media
      </Link>{' '}
      {new Date().getFullYear()}
      {'. '}
      {copyright.develop}
      <a href={INGENIUST_PAGE} target="_blank" rel="noreferrer">
        Ingeniust
      </a>
      <br />
      {copyright.terms}
      <a href={`/${PRIVACY_POLICY_PAGE_URL}`}>{copyright.privacy}</a>
    </Typography>
  )
}

const LoginPage = () => {
  const locale = useLocale()
  const dispatch = useDispatch()
  const { form, language } = getContent(locale)
  const { emailLabel, passwordLabel, rememberMeToolTip, rememberMeLabel, loadingText, login, register, forgot } = form

  const [rememberChecked, setRememberChecked] = useState(false)
  const [openCookiesAlertModal, setOpenCookiesAlertModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleChangeRemember = useCallback(
    (event) => {
      setRememberChecked(event?.target?.checked)
    },
    [setRememberChecked]
  )

  const handlerLoginSubmit = useCallback(
    async (event) => {
      event.preventDefault()
      setLoading(true)

      const target = event?.target
      const email = target?.email?.value
      const password = target?.password?.value
      const remember = target?.remember?.checked

      await signInFirebaseUser(email, password, remember)

      const uidCookie = Cookies.get(UID_COOKIE)

      setLoading(false)
      if (!uidCookie) return dispatch(sendErrorMessage(ERROR_MESSAGES[locale]['auth/login-fail']))
    },
    [dispatch, locale]
  )

  const onClickRegistryUser = useCallback(
    () => dispatch(openStepperModal(RegistryUserSteps, { title: form.register })),
    [dispatch, form.register]
  )

  const onClickResetPassword = useCallback(() => dispatch(openModal(resetPasswordFormConfig())), [dispatch])

  useEffect(() => {
    const locale = localStorage.getItem('locale')
    const cookiesAlert = localStorage.getItem('digitalPymes-cookiesAlert')

    if (!locale) {
      localStorage.setItem('locale', 'es')
    }

    if (!cookiesAlert) {
      setOpenCookiesAlertModal(true)
    }
  }, [])

  return (
    <AuthValidation>
      <Page title="Login" fullWidth>
        <div className={styles.wrapper}>
          <Grid container component="main" className={styles.root}>
            <Grid item xs={false} sm={4} md={7} className={styles.image} />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
              <div className={styles.paper}>
                <img src={LOGO_IMG} alt="MyForm" className={styles.logo} />
                <form className={styles.form} onSubmit={handlerLoginSubmit}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label={emailLabel}
                    name="email"
                    autoComplete="email"
                    autoFocus
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label={passwordLabel}
                    type="password"
                    id="password"
                    autoComplete="current-password"
                  />

                  <Tooltip title={rememberMeToolTip} aria-label="info">
                    <FormControlLabel
                      control={
                        <Checkbox id="remember" checked={rememberChecked} onChange={handleChangeRemember} color="primary" />
                      }
                      label={rememberMeLabel}
                    />
                  </Tooltip>
                  <Button type="submit" fullWidth variant="contained" color="primary" className={styles.button}>
                    {loading ? (
                      <>
                        <CircularProgress className={styles.loadingIcon} color="primary" />
                        {loadingText}
                      </>
                    ) : (
                      login
                    )}
                  </Button>
                  <Button type="button" fullWidth variant="outlined" color="primary" onClick={onClickRegistryUser}>
                    <i className="fas fa-user-plus" /> {register}
                  </Button>
                  <section className={styles.languageContainer}>
                    {language}
                    <LocaleSelection />
                  </section>
                  <Box mt={5}>
                    <span className={styles.resetPasswordButton} onClick={onClickResetPassword}>
                      <i className="fas fa-key" />
                      {forgot}
                    </span>
                  </Box>
                </form>
              </div>
            </Grid>
          </Grid>

          <Footer />
        </div>
      </Page>

      <ModalsControler />
      <CookiesAlertModal open={openCookiesAlertModal} setOpen={setOpenCookiesAlertModal} />
    </AuthValidation>
  )
}

export default LoginPage
