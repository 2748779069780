import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import useUsers from './useUsers'

const useFetchResource = (action) => {
  const dispatch = useDispatch()
  const { myUser } = useUsers()
  const { company } = myUser ?? {}
  const { idCompany } = company ?? {}

  useEffect(() => {
    const fetch = async () => {
      if (!idCompany) return

      if (action.length) {
        Promise.all(action).then((responses) => responses.forEach((x) => dispatch(x)))
      }
    }

    fetch()
  }, [dispatch, idCompany]) /* eslint-disable-line */
}

export default useFetchResource
