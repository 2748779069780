import { currencies } from 'constants/currencies'
import useArray from 'hooks/useArray'
import useCosts from 'hooks/useCosts'
import { useCallback, useMemo } from 'react'

const useMoney = (idProductCost = null, pdf = false) => {
  const CURRENCIES = currencies()
  const { findItem } = useArray()
  const { productsCosts } = useCosts()
  const product = useMemo(() => {
    if (idProductCost) {
      return findItem(productsCosts, +idProductCost, 'idProductCost')
    } else {
      return null
    }
  }, [findItem, productsCosts, idProductCost])
  const { currency } = product ?? {}

  const parseValue = (n) => Number(String(n)).toFixed(2)

  const formatMoney = useCallback(
    (v, defaultCurrency = null) => {
      const isNegative = Number(v) < 0
      const value = isNegative ? v * -1 : v
      const shouldAddDecimal = String(value).split('.')[1]?.length === 1

      const parsedValue = Number(value).toLocaleString('es-AR', {
        minimumFractionDigits: shouldAddDecimal ? 2 : 0,
        maximumFractionDigits: 2,
      })

      switch (defaultCurrency ?? currency) {
        case 'crc':
          return `${isNegative ? '-' : ''}${pdf ? '¢' : '₡'}${parsedValue}`
        case 'usd':
          return `${isNegative ? '-' : ''}$${parsedValue}`
        case 'bzd':
          return `${isNegative ? '-' : ''}${parsedValue} BZD`
        case 'gtq':
          return `${isNegative ? '-' : ''}Q${parsedValue}`
        case 'nhl':
          return `${isNegative ? '-' : ''}${parsedValue}L`
        case 'nio':
          return `${isNegative ? '-' : ''}C$${parsedValue}`
        case 'pab':
          return `${isNegative ? '-' : ''}B/.${parsedValue}`
        case 'svc':
          return `${isNegative ? '-' : ''}${parsedValue} SVC`
        default:
          return `${isNegative ? '-' : ''}${pdf ? '¢' : '₡'}${parsedValue}`
      }
    },
    [currency, pdf]
  )

  const currencyName = useCallback(
    (currency) => {
      const item = findItem(CURRENCIES, currency, 'value')
      return item.text
    },
    [findItem, CURRENCIES]
  )

  return { formatMoney, currencyName, parseValue }
}

export default useMoney
