import React, { useCallback } from 'react'
import useLocale from 'hooks/useLocale'
import styles from './MobileInstall.module.scss'

const MobileInstall = ({ event, setActive }) => {
  const locale = useLocale()

  const handleInstallClick = useCallback(() => {
    setActive(false)
    event?.prompt()

    event?.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the install prompt')
      } else {
        console.log('User dismissed the install prompt')
      }
    })
  }, [event, setActive])

  return (
    <div className={styles.mainContainer}>
      <i className={`fas fa-times ${styles.close}`} onClick={() => setActive(false)} />

      <figure>
        <img src="/ios/50.png" alt="" />
      </figure>

      <div className={styles.messageContainer}>
        <p>{locale === 'es' ? 'Instala la version movil' : 'Install the mobile version'}</p>
        <button onClick={handleInstallClick}>{locale === 'es' ? 'Instalar' : 'Install'}</button>
      </div>
    </div>
  )
}

export default MobileInstall
