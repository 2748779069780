import { Button, InputAdornment, SvgIcon, TextField, Tooltip } from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { openModal, openStepperModal } from 'actions/modals'
import SubscribeToProButton from 'components/SubscribeToProButton'
import { useHideSubscriptionModule } from 'hooks/useHideSubscriptionModule'
import useLocale from 'hooks/useLocale'
import useSubscription from 'hooks/useSubscription'
import React, { useCallback } from 'react'
import { Search as SearchIcon } from 'react-feather'
import { useDispatch } from 'react-redux'
import styles from './crudPanel.module.scss'

const CrudPanel = ({
  title,
  headerActions = <></>,
  setSearchValue,
  extraCrudButtons,
  modalFormConfig,
  stepperModalPages,
  stepperModalConfig,
  disabledButton,
  defaultName,
  crudButtonText,
  onClick = null,
  canWrite = false,
  isStepper = false,
  proUserCondition = false,
  proUserConditionMessage = '',
}) => {
  const locale = useLocale()
  const dispatch = useDispatch()
  const { isProUser } = useSubscription()

  const { name } = modalFormConfig || {}
  const hideSubscriptionModule = useHideSubscriptionModule()

  const handlerOnChangeSearch = useCallback(
    (event) => {
      const value = event.target.value

      setSearchValue?.(value)
    },
    [setSearchValue]
  )

  const onClickButton = useCallback(() => {
    isStepper ? dispatch(openStepperModal(stepperModalPages, stepperModalConfig)) : dispatch(openModal(modalFormConfig))
  }, [dispatch, modalFormConfig, stepperModalPages, isStepper, stepperModalConfig])

  return (
    <>
      <header className={styles.header}>
        <h1 className={styles.crudTitle}>{title}</h1>
        {headerActions}
      </header>
      <div className={styles.crudPanel}>
        <TextField
          onChange={handlerOnChangeSearch}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon fontSize="small" color="action">
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            ),
          }}
          placeholder={`${locale === 'es' ? 'Buscar' : 'Search'} ${name || defaultName}`}
          variant="outlined"
          className={styles.searchInput}
        />

        {(modalFormConfig || stepperModalPages || onClick) && canWrite && (
          <div className={styles.crudButtonsContainer}>
            {!hideSubscriptionModule && !isProUser && typeof proUserCondition === 'boolean' && proUserCondition ? (
              <Tooltip arrow placement="top" title={proUserConditionMessage}>
                <div>
                  <SubscribeToProButton />
                </div>
              </Tooltip>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  className={styles.crudButton}
                  onClick={onClick ?? onClickButton}
                  disabled={disabledButton | proUserCondition?.loading}
                >
                  <AddCircleIcon />
                  {crudButtonText ? crudButtonText : `${locale === 'es' ? 'Agregar' : 'Add'} ${name || defaultName}`}
                </Button>
                {extraCrudButtons &&
                  extraCrudButtons.map(({ action, text, icon }) => (
                    <Button
                      disabled={proUserCondition?.loading}
                      className={styles.crudButton}
                      variant="outlined"
                      color="primary"
                      onClick={action}
                    >
                      {icon}
                      {text}
                    </Button>
                  ))}
              </>
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default CrudPanel
