import { postMarketingCampaign, selectMarketingCampaing, updateMarketingCampaign } from 'actions/marketingCampaigns'
import { sendSuccessMessage } from 'actions/modals'
import useLocale from 'hooks/useLocale'
import useMarketing from 'hooks/useMarketing'
import useUsers from 'hooks/useUsers'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useImmer } from 'use-immer'

const INITIAL_MARKETING_CAMPAIGN = {
  name: '',
  description: '',
  cost: '',
  currency: 'crc',
  managers: [],
  startDate: new Date(),
  endDate: null,
}

const FORM_LABELS = {
  es: {
    name: 'Nombre',
    description: 'Descripción',
    cost: 'Costo',
    currency: 'Moneda',
    manager: 'Asignar encargado',
    startDate: 'Fecha y hora de inicio',
    endDate: 'Fecha y hora de fin',
  },
  en: {
    name: 'Name',
    description: 'Description',
    cost: 'Cost',
    currency: 'Currency',
    manager: 'Asign manager',
    startDate: 'Start date and time',
    endDate: 'End date and time',
  },
}

const useMarketingCampaignsModal = (toggleModal) => {
  const locale = useLocale()
  const dispatch = useDispatch()
  const {
    myUser: {
      company: { idCompany },
    },
  } = useUsers() ?? {}
  const { selectedMarketingCampaign } = useMarketing()
  const [form, setForm] = useImmer(INITIAL_MARKETING_CAMPAIGN)
  const [pending, setPending] = useState(false)

  const addingTitle = locale === 'es' ? 'Crear campaña de marketing' : 'Create marketing campaign'
  const editingTitle = `${locale === 'es' ? 'Editar' : 'Edit'} ${selectedMarketingCampaign?.name}`

  const addButtonTitle = locale === 'es' ? 'Crear' : 'Create'
  const editButtonTitle = locale === 'es' ? 'Guardar' : 'Save'

  const handleChangeForm = (event, newValue) => {
    setForm((form) => {
      if (!event?.target) {
        form[event] = newValue

        return
      }

      form[event.target.name] = newValue
    })
  }

  const handleDeleteManager = (_manager) =>
    setForm((form) => {
      form.managers = form.managers.filter((item) => item !== _manager)
    })

  const handleClose = () => {
    toggleModal()

    setTimeout(() => {
      setForm(INITIAL_MARKETING_CAMPAIGN)
      dispatch(selectMarketingCampaing(null))
    }, 200)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const isEdit = Boolean(form.idMarketingCampaign)

    setPending(true)
    const action = isEdit ? updateMarketingCampaign : postMarketingCampaign
    const snackbarMessage = isEdit
      ? locale === 'es'
        ? 'Guardado exitosamente'
        : 'Saved successfully'
      : locale === 'es'
      ? 'Creado exitosamente'
      : 'Created successfully'
    dispatch(await action({ ...form, idCompany }))
    dispatch(sendSuccessMessage(snackbarMessage))
    setPending(false)

    handleClose()
  }

  useEffect(() => {
    if (selectedMarketingCampaign)
      setForm({ ...selectedMarketingCampaign, managers: selectedMarketingCampaign?.managers?.map((_manager) => _manager.idUser) })
  }, [selectedMarketingCampaign, setForm])

  return {
    addingTitle,
    editingTitle,
    form,
    addButtonTitle,
    editButtonTitle,
    formLabels: FORM_LABELS[locale],
    pending,
    handleChangeForm,
    setForm,
    handleDeleteManager,
    handleSubmit,
    handleClose,
    selectedMarketingCampaign,
  }
}

export default useMarketingCampaignsModal
