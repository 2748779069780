import { Button, DialogActions, Divider, TextField } from '@material-ui/core'
import Dialog from 'components/Dialog/Dialog'
import useLocale from 'hooks/useLocale'
import React, { useEffect, useState } from 'react'
import styles from './NewButton.module.scss'

const NamingModal = ({ open, onClose, onCreateFile, defaultName }) => {
  const locale = useLocale()
  const [name, setName] = useState('')

  useEffect(() => {
    if (defaultName) {
      setName(defaultName)
    }
  }, [defaultName])

  return (
    <Dialog
      open={open}
      handleCloseAction={() => {
        onClose()
        setName('')
      }}
      title={locale === 'es' ? 'Crear' : 'Create'}
    >
      <TextField
        fullWidth
        name="name"
        type="text"
        label={locale === 'es' ? 'Nombre' : 'Name'}
        value={name}
        onChange={(e) => setName(e.target.value)}
        variant="outlined"
        classes={{
          root: styles.input,
        }}
      />

      <Divider />

      <DialogActions>
        <Button color="default" variant="text" onClick={onClose} classes={{ root: styles.cancelButton }}>
          {locale === 'es' ? 'Cancelar' : 'Cancel'}
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            onCreateFile(name)
            setName('')
          }}
        >
          {defaultName ? (locale === 'es' ? 'Guardar' : 'Save') : locale === 'es' ? 'Crear' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default NamingModal
