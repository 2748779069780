import { sendErrorMessage, sendSuccessMessage } from 'actions/modals'
import { SEND_REPORT } from 'constants/apiRoutes'
import { sendReport } from 'helpers/api'
import useLocale from 'hooks/useLocale'
import useMoney from 'hooks/useMoney'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

const usePreviewStub = (employee, id) => {
  const dispatch = useDispatch()
  const locale = useLocale()
  const ref = useRef()
  const { formatMoney: formatMoneyPDF } = useMoney(null, true)

  const { stubs, name } = employee ?? {}
  const stub = useMemo(() => stubs?.find((el) => el.idPaymentStub === id?.idPaymentStub), [id, stubs])

  const [email, setEmail] = useState(employee?.email ?? '')
  const [sendingEmail, setSendingEmail] = useState(false)

  const handleDownload = async (type) => {
    const title = (extension) => `${locale === 'es' ? 'Colilla de pago' : 'Payment stub'} - #${id?.index}${extension}`

    if (type === 1) {
      const pdf = new jsPDF('p', 'px', [ref.current.clientWidth, ref.current.clientHeight + 160])
      const dom = ref.current
      pdf.html(dom, {
        callback: function (doc) {
          doc.save(title('.pdf'))
        },
      })
    } else if (type === 2) {
      html2canvas(ref.current, {
        useCORS: true,
      }).then((canvas) => {
        const blob = canvas.toDataURL('image/png', 1.0)
        const link = document.createElement('a')
        link.download = title('.png')
        link.href = blob
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    }
  }

  const handleSendStub = async () => {
    setSendingEmail(true)
    const title = `${locale === 'es' ? 'Colilla de pago' : 'Payment stub'} - #${id?.index}, ${name}`
    const pdf = new jsPDF('p', 'px', [ref.current.clientWidth, ref.current.clientHeight + 160])
    const dom = ref.current

    pdf.html(dom, {
      callback: async (doc) => {
        const file = new File([doc.output('blob')], `${title}.pdf`)
        let data = new FormData()
        data.append('email', email)
        data.append('report', file)
        data.append('subject', title)

        const res = await sendReport(SEND_REPORT, data)

        if (res.error) {
          console.error(res.message)
          dispatch(sendErrorMessage(locale === 'es' ? 'Hubo un error al enviar el correo' : 'Error sending the email'))
        } else {
          dispatch(sendSuccessMessage(locale === 'es' ? 'Se envio el correo exitosamente' : 'Succesfully sended email'))
        }

        setSendingEmail(false)
      },
    })
  }

  useEffect(() => {
    setEmail(employee?.email ?? '')
  }, [employee?.email])

  return {
    handleDownload,
    handleSendStub,
    formatMoneyPDF,
    sendingEmail,
    email,
    setEmail,
    stub,
    ref,
  }
}

export default usePreviewStub
