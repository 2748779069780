const freeTimeTypes = new Map([
  [
    1,
    {
      text: {
        es: 'Vacaciones',
        en: 'Vacations',
      },
      value: 1,
    },
  ],
  [
    2,
    {
      text: {
        es: 'Enfermedad',
        en: 'Sickness',
      },
      value: 2,
    },
  ],
  [
    3,
    {
      text: {
        es: 'Graduación',
        en: 'Graduation',
      },
      value: 3,
    },
  ],
  [
    4,
    {
      text: {
        es: 'Luto',
        en: 'Grief',
      },
      value: 4,
    },
  ],
  [
    5,
    {
      text: {
        es: 'Otros',
        en: 'Others',
      },
      value: 5,
    },
  ],
])

export default freeTimeTypes
