import DateFnsUtils from '@date-io/date-fns'
import { CircularProgress, IconButton } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import MuiSelect from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import { Clear as ClearIcon, Event as EventIcon } from '@material-ui/icons'
import { DatePicker as MuiDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { closeModal, sendErrorMessage, sendSuccessMessage } from 'actions/modals'
import FileInput from 'components/FileInput/FileInput'
import Select from 'components/common/Select/Select'
import ERROR_MESSAGES from 'constants/errorMessages'
import { enUS, es } from 'date-fns/locale'
import { formatDate } from 'helpers/date'
import useLocale from 'hooks/useLocale'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styles from './form.module.scss'
import formObjectFormatter from './formater'

const FIELD_MULTILINE_ROWS = 3

const Field = ({ field, index, setGeneralStates, canWrite }) => {
  const handleChangeValue = useCallback(
    (event) => {
      const selectedValue = event.target.value

      setGeneralStates((prev) => ({ ...prev, [field?.name]: selectedValue }))
    },
    [setGeneralStates, field]
  )
  return (
    <TextField
      name={field?.name}
      variant={'outlined'}
      required={field?.isRequired ?? true}
      fullWidth
      id={field?.id ?? field?.name ?? `id-${index}`}
      key={`key-${field?.id ?? field?.name}`}
      label={field?.label}
      autoFocus={field?.isAutoFocus}
      type={field?.type ?? 'text'}
      defaultValue={field?.defaultValue ?? ''}
      multiline={field?.multiline}
      minRows={field?.multiline && FIELD_MULTILINE_ROWS}
      disabled={!canWrite || field?.disabled}
      InputLabelProps={{
        shrink: field?.shrinkLabel,
      }}
      inputProps={{ ...field }}
      onChange={handleChangeValue}
    />
  )
}

const SelectOptions = ({ field, generalStates, setGeneralStates, canWrite }) => {
  const options = Array?.isArray(field?.options) ? field?.options : field?.options?.({ generalStates })
  const [selectedValue, setSelectedValue] = useState(() => {
    if (field.displayEmpty) return field?.defaultOptionSelected

    return field?.defaultOptionSelected ?? options?.[0]?.value
  })

  useEffect(() => {
    if (!options?.find(({ value }) => value === selectedValue) && !field?.displayEmpty) {
      setSelectedValue(options?.[0]?.value)
    }
  }, [options, selectedValue, field])

  const handleChangeValue = useCallback(
    (event) => {
      const selectedValue = event.target.value
      setSelectedValue(selectedValue)

      if (typeof field?.onChange === 'function') return field?.onChange(event, setGeneralStates)

      setGeneralStates({ ...generalStates, [field?.name]: selectedValue })
    },
    [setGeneralStates, generalStates, field]
  )

  return field?.displayEmpty ? (
    <Select
      fullWidth
      id={`select${field?.id ?? field?.name}`}
      value={selectedValue}
      label={field?.label}
      name={field.name}
      onChange={handleChangeValue}
      disabled={!canWrite || field?.disabled}
      options={options}
      isRequired={field?.isRequired ?? true}
      displayEmpty={field?.displayEmpty ?? false}
      emptyValue={field?.emptyValue}
    />
  ) : (
    <FormControl
      disabled={!canWrite || field?.disabled}
      variant="outlined"
      fullWidth
      required={field?.isRequired ?? true}
      key={`key-form-control-${field?.name}`}
    >
      <InputLabel id={`${field?.name}-label`} key={`key-${field?.name}-label`}>
        {field?.label}
      </InputLabel>
      <MuiSelect
        id={`select${field?.id ?? field?.name}`}
        key={`key-${field?.id ?? field?.name}`}
        value={selectedValue}
        onChange={handleChangeValue}
        label={field?.label}
        inputProps={{ id: `${field?.id ?? field?.name}` }}
      >
        {options?.map((option) => (
          <MenuItem value={option?.value} key={`key-${option?.value}`}>
            {option?.text}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  )
}

const DatePicker = ({ field, generalStates, setGeneralStates, canWrite }) => {
  const [selectedDate, setSelectedDate] = useState(field?.defaultValue)
  const locale = useLocale()

  const handleChangeValue = useCallback(
    (newDate) => {
      setSelectedDate(newDate)
      setGeneralStates({ ...generalStates, [field?.name]: formatDate(newDate, 'YYYY-MM-DD') })
    },

    [setGeneralStates, generalStates, field]
  )

  const handleClearValue = useCallback(
    (event) => {
      event.stopPropagation()
      setSelectedDate(null)
      setGeneralStates({ ...generalStates, [field?.name]: null })
    },
    [field, generalStates, setGeneralStates]
  )

  return (
    <MuiPickersUtilsProvider locale={locale === 'es' ? es : enUS} utils={DateFnsUtils}>
      <MuiDatePicker
        name={field?.name}
        emptyLabel="dd-MM-yyyy"
        id={`select${field?.id ?? field?.name}`}
        key={`key-${field?.id ?? field?.name}`}
        label={field?.label}
        format="yyyy-MM-dd"
        PopoverProps={{ anchorOrigin: { horizontal: 'right', vertical: 'bottom' } }}
        InputProps={{
          endAdornment: (
            <>
              {field?.clareable && selectedDate && (
                <IconButton onClick={handleClearValue}>
                  <ClearIcon />
                </IconButton>
              )}

              <IconButton>
                <EventIcon />
              </IconButton>
            </>
          ),
        }}
        style={{ width: '100%' }}
        variant="inline"
        inputVariant="outlined"
        value={selectedDate}
        onChange={handleChangeValue}
      />
    </MuiPickersUtilsProvider>
  )
}

const Form = ({ setOpen, modalFormConfig, nextPageHandler, className, canWrite = true, customOnSubmit }) => {
  const locale = useLocale()
  const dispatch = useDispatch()
  const [isLoadingData, setIsLoadingData] = useState(false)
  const [generalStates, setGeneralStates] = useState({})
  const { isEdit, isStepper, form, registerAction, editAction, buttonName, isScheduling, scheduleAction, submitter } =
    modalFormConfig
  const action = isEdit ? editAction : isScheduling ? scheduleAction : registerAction

  const handlerSubmit = useCallback(
    async (event) => {
      event.preventDefault()
      setIsLoadingData(true)

      const formattedFormObject = formObjectFormatter(event, form)
      const hiddenValues = form?.find(({ hiddenValues }) => hiddenValues)?.hiddenValues ?? {}
      const res = await action({ ...formattedFormObject, ...hiddenValues, ...generalStates }, dispatch)

      if (res?.formError) {
        setIsLoadingData(false)

        return
      }

      if (res?.payload?.error) {
        setIsLoadingData(false)
        setOpen?.(false)
        dispatch(closeModal())

        return dispatch(sendErrorMessage(res?.message ?? ERROR_MESSAGES[locale].default))
      }

      dispatch(res)
      setIsLoadingData(false)
      setOpen?.(false)
      dispatch(closeModal())
      dispatch(
        sendSuccessMessage(
          isEdit
            ? locale === 'es'
              ? 'Se guardo correctamente'
              : 'Saved correctly'
            : isScheduling
            ? locale === 'es'
              ? 'Se agendó correctamente'
              : 'Scheduled correctly'
            : locale === 'es'
            ? 'Se agrego correctamente'
            : 'Added correctly'
        )
      )
    },
    [setIsLoadingData, form, action, dispatch, isEdit, setOpen, isScheduling, locale, generalStates]
  )

  const handlerCustomOnSubmit = useCallback(
    (event) => {
      event.preventDefault()
      const formattedFormObject = formObjectFormatter(event, form)

      customOnSubmit(formattedFormObject, setIsLoadingData)
    },
    [customOnSubmit, form]
  )

  return (
    <form
      onSubmit={(e) => {
        setIsLoadingData(true)
        const formattedFormObject = formObjectFormatter(e, form)
        const hiddenValues = form?.find(({ hiddenValues }) => hiddenValues)?.hiddenValues ?? {}

        if (submitter && typeof submitter === 'function') {
          submitter(e, { ...formattedFormObject, ...hiddenValues, ...generalStates }, setIsLoadingData)
        } else if (customOnSubmit) {
          handlerCustomOnSubmit(e)
        } else {
          handlerSubmit(e)
        }
      }}
      className={`${className} ${styles.form}`}
    >
      <Grid container spacing={2}>
        {form
          ?.filter(({ hide, hiddenValues }) => !hide && !hiddenValues)
          ?.map((field, index) => {
            return (
              <Grid item xs={12} sm={field?.midSize ? 6 : 12} key={`key-grid-${index}`}>
                {field?.options ? (
                  <SelectOptions
                    canWrite={canWrite}
                    field={field}
                    generalStates={generalStates}
                    setGeneralStates={setGeneralStates}
                  />
                ) : field?.type === 'file' ? (
                  <FileInput canWrite={canWrite} key={`key-${field?.id ?? field?.name}`} field={field} index={index} />
                ) : field?.type === 'custom' ? (
                  field?.component(generalStates, setGeneralStates, field?.defaultValue)
                ) : field?.type === 'muiDate' ? (
                  <DatePicker
                    field={field}
                    canWrite={canWrite}
                    generalStates={generalStates}
                    setGeneralStates={setGeneralStates}
                  />
                ) : (
                  <Field canWrite={canWrite} field={field} index={index} setGeneralStates={setGeneralStates} />
                )}
              </Grid>
            )
          })}
      </Grid>

      {isLoadingData ? (
        <div className={styles.loadingIconContainer}>
          <CircularProgress color="primary" />
        </div>
      ) : isStepper ? (
        <Button
          fullWidth
          type="button"
          disabled={!canWrite}
          onClick={nextPageHandler}
          variant="contained"
          color="primary"
          className={styles.button}
        >
          {locale === 'es' ? 'Siguiente' : 'Next'}
        </Button>
      ) : (
        <Button disabled={!canWrite} type="submit" fullWidth variant="contained" color={'primary'} className={styles.button}>
          {buttonName ||
            (isEdit && (locale === 'es' ? 'Guardar' : 'Save')) ||
            (isScheduling && (locale === 'es' ? 'Agendar' : 'Schedule')) ||
            (locale === 'es' ? 'Agregar' : 'Add')}
        </Button>
      )}
    </form>
  )
}

export default Form
