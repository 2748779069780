import axios from 'axios'

const axiosParams = {
  baseURL: process.env.NODE_ENV === 'production' ? '' : 'http://localhost:8001/api/',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${process.env.REACT_APP_PAYMENT_SERVICES_API_TOKEN}`,
  },
}

const axiosInstace = axios.create(axiosParams)

const api = (axios) => ({
  get: (url, config = {}) => axios.get(url, config),
  post: (url, data, config = {}) => axios.post(url, data, config),
  put: (url, data, config = {}) => axios.put(url, data, config),
  delete: (url, config = {}) => axios.delete(url, config),
})

export default api(axiosInstace)
