const es = {
  title: 'Reportar Errores',
  descriptionPlaceholder: 'Descripción',
  attachFiles: 'Adjuntar archivo',
  searchButton: 'Buscar',
  sendButton: 'Enviar',
  invalidReportError: 'Descripción inválida.',
  successReportMessage: 'Reporte de error enviado exitosamente.',
}

const en = {
  title: 'Report Errors',
  descriptionPlaceholder: 'Description',
  attachFiles: 'Attach file',
  searchButton: 'Search',
  sendButton: 'Send',
  invalidReportError: 'Invalid description.',
  successReportMessage: 'Report error sent successfully.',
}

const getContent = (locale) => (locale === 'es' ? es : en)

export default getContent
