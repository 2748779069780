import { Button, CircularProgress } from '@material-ui/core'
import { confirmDialog } from 'actions/modals'
import Dialog from 'components/Dialog/Dialog'
import useLocale from 'hooks/useLocale'
import usePrevious from 'hooks/usePrevious'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styles from './confirmDialog.module.scss'

const DialogActions = ({ setOpen, setLoading, handlerAction, dispatch }) => {
  const locale = useLocale()
  const handlerClose = useCallback(() => {
    setOpen(false)
    dispatch(confirmDialog({ open: false }))
  }, [setOpen, dispatch])

  const handlerAccept = useCallback(async () => {
    if (!handlerAction) return handlerClose()

    try {
      setLoading(true)
      await handlerAction()
      handlerClose()
      setLoading(false)
    } catch (error) {
      setLoading(false)
      throw error
    }
  }, [handlerClose, handlerAction, setLoading])

  return (
    <section className={styles.actions}>
      <Button color="primary" variant="text" onClick={handlerClose}>
        {locale === 'es' ? 'Cancelar' : 'Cancel'}
      </Button>
      <Button color="primary" variant="contained" autoFocus onClick={handlerAccept}>
        {locale === 'es' ? 'Aceptar' : 'Accept'}
      </Button>
    </section>
  )
}

const ConfirmDialog = ({ open, setOpen, dialogConfig }) => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const { message, extraMessage, handlerAction } = dialogConfig || {}
  const prevMessage = usePrevious(message)

  useEffect(() => {
    if (!open) setLoading(false)
  }, [open])

  return (
    <Dialog open={open} setOpen={setOpen} title={message || prevMessage || ''}>
      {extraMessage && (
        <p
          className={styles.extraMessage}
          dangerouslySetInnerHTML={{
            __html: extraMessage,
          }}
        />
      )}

      <div className={styles.footer}>
        {loading ? (
          <CircularProgress className={styles.loading} />
        ) : (
          <DialogActions setOpen={setOpen} setLoading={setLoading} handlerAction={handlerAction} dispatch={dispatch} />
        )}
      </div>
    </Dialog>
  )
}

export default ConfirmDialog
