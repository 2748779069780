import {
  DELETE_PERSONAL_FINANCE_CARD,
  DELETE_PERSONAL_FINANCE_CREDIT,
  DELETE_PERSONAL_FINANCE_SAVING,
  DELETE_PERSONAL_FINANCE_TRANSACTION,
  GET_PERSONAL_FINANCE_CARDS,
  GET_PERSONAL_FINANCE_CREDITS,
  GET_PERSONAL_FINANCE_SAVINGS,
  GET_PERSONAL_FINANCE_TRANSACTIONS,
  UPDATE_PERSONAL_FINANCE_CARD,
  UPDATE_PERSONAL_FINANCE_CREDIT,
  UPDATE_PERSONAL_FINANCE_SAVING_AMOUNT,
  UPDATE_PERSONAL_FINANCE_TRANSACTION,
  UPLOAD_PERSONAL_FINANCE_CARD,
  UPLOAD_PERSONAL_FINANCE_CREDIT,
  UPLOAD_PERSONAL_FINANCE_SAVING,
  UPLOAD_PERSONAL_FINANCE_TRANSACTION,
} from 'constants/actionTypes'

const initialState = {
  personalFinanceTransactions: [],
  personalFinanceSavings: [],
  personalFinanceCredits: [],
  personalFinanceCards: [],
  isLoadingPersonalFinanceCards: true,
  isLoadingPersonalFinanceTransactions: true,
  isLoadingPersonalFinanceSavings: true,
  isLoadingPersonalFinanceCredits: true,
  errorInRequest: false,
}

const handlers = {
  [GET_PERSONAL_FINANCE_TRANSACTIONS]: (state, { payload = {} }) => {
    if (!payload?.personalFinanceTransactions)
      return { ...state, isLoadingPersonalFinanceTransactions: false, errorInRequest: true }

    return {
      ...state,
      personalFinanceTransactions: payload?.personalFinanceTransactions,
      isLoadingPersonalFinanceTransactions: false,
    }
  },
  [UPLOAD_PERSONAL_FINANCE_TRANSACTION]: (state, { payload = {} }) => {
    if (!payload?.personalFinanceTransaction) return { ...state, errorInRequest: true }

    const transactionCard = payload?.personalFinanceTransaction?.card

    return {
      ...state,
      personalFinanceTransactions: [...state?.personalFinanceTransactions, payload?.personalFinanceTransaction],
      personalFinanceCards: transactionCard
        ? state.personalFinanceCards.map((card) => {
            if (card.idPersonalFinanceCard === transactionCard.idPersonalFinanceCard) return transactionCard

            return card
          })
        : state.personalFinanceCards,
    }
  },
  [UPDATE_PERSONAL_FINANCE_TRANSACTION]: (state, { payload = {} }) => {
    if (!payload?.personalFinanceTransaction) return { ...state, errorInRequest: true }
    const transactionCard = payload?.personalFinanceTransaction?.card

    return {
      ...state,
      personalFinanceTransactions: state.personalFinanceTransactions.map((personalFinanceTransaction) =>
        personalFinanceTransaction.idPersonalFinanceTransaction ===
        payload?.personalFinanceTransaction?.idPersonalFinanceTransaction
          ? payload?.personalFinanceTransaction
          : personalFinanceTransaction
      ),
      personalFinanceCards: transactionCard
        ? state.personalFinanceCards.map((card) =>
            card.idPersonalFinanceCard === transactionCard.idPersonalFinanceCard ? transactionCard : card
          )
        : state.personalFinanceCards,
    }
  },
  [DELETE_PERSONAL_FINANCE_TRANSACTION]: (state, { payload = {} }) => {
    if (!payload?.personalFinanceTransaction) return { ...state, errorInRequest: true }

    const transactionCard = payload?.personalFinanceTransaction?.card

    return {
      ...state,
      personalFinanceTransactions: state.personalFinanceTransactions.filter(
        ({ idPersonalFinanceTransaction }) =>
          idPersonalFinanceTransaction !== payload?.personalFinanceTransaction?.idPersonalFinanceTransaction
      ),
      personalFinanceCards: transactionCard
        ? state.personalFinanceCards.map((card) =>
            card.idPersonalFinanceCard === transactionCard.idPersonalFinanceCard ? transactionCard : card
          )
        : state.personalFinanceCards,
    }
  },
  [GET_PERSONAL_FINANCE_SAVINGS]: (state, { payload = {} }) => {
    if (!payload?.personalFinanceSavings) return { ...state, isLoadingPersonalFinanceSavings: false, errorInRequest: true }

    return {
      ...state,
      personalFinanceSavings: payload?.personalFinanceSavings,
      isLoadingPersonalFinanceSavings: false,
    }
  },
  [UPLOAD_PERSONAL_FINANCE_SAVING]: (state, { payload = {} }) => {
    if (!payload?.personalFinanceSaving) return { ...state, errorInRequest: true }

    return {
      ...state,
      personalFinanceSavings: [...state?.personalFinanceSavings, payload?.personalFinanceSaving],
    }
  },
  [UPDATE_PERSONAL_FINANCE_SAVING_AMOUNT]: (state, { payload = {} }) => {
    if (!payload?.personalFinanceSaving) return { ...state, errorInRequest: true }

    return {
      ...state,
      personalFinanceSavings: state.personalFinanceSavings.map((personalFinanceSaving) =>
        personalFinanceSaving.idPersonalFinanceSaving === payload?.personalFinanceSaving?.idPersonalFinanceSaving
          ? payload?.personalFinanceSaving
          : personalFinanceSaving
      ),
    }
  },
  [DELETE_PERSONAL_FINANCE_SAVING]: (state, { payload = {} }) => {
    if (!payload?.personalFinanceSaving) return { ...state, errorInRequest: true }

    return {
      ...state,
      personalFinanceSavings: state.personalFinanceSavings.filter(
        ({ idPersonalFinanceSaving }) => idPersonalFinanceSaving !== payload?.personalFinanceSaving?.idPersonalFinanceSaving
      ),
    }
  },
  [GET_PERSONAL_FINANCE_CREDITS]: (state, { payload = {} }) => {
    if (!payload?.personalFinanceCredits) return { ...state, isLoadingPersonalFinanceCredits: false, errorInRequest: true }

    return {
      ...state,
      personalFinanceCredits: payload?.personalFinanceCredits,
      isLoadingPersonalFinanceCredits: false,
    }
  },
  [UPLOAD_PERSONAL_FINANCE_CREDIT]: (state, { payload = {} }) => {
    if (!payload?.personalFinanceCredit) return { ...state, errorInRequest: true }

    return {
      ...state,
      personalFinanceCredits: [...state?.personalFinanceCredits, payload?.personalFinanceCredit],
    }
  },
  [UPDATE_PERSONAL_FINANCE_CREDIT]: (state, { payload = {} }) => {
    if (!payload?.personalFinanceCredit) return { ...state, errorInRequest: true }

    return {
      ...state,
      personalFinanceCredits: state.personalFinanceCredits.map((personalFinanceCredit) =>
        personalFinanceCredit.idPersonalFinanceCredit === payload?.personalFinanceCredit?.idPersonalFinanceCredit
          ? payload?.personalFinanceCredit
          : personalFinanceCredit
      ),
    }
  },
  [DELETE_PERSONAL_FINANCE_CREDIT]: (state, { payload = {} }) => {
    if (!payload?.personalFinanceCredit) return { ...state, errorInRequest: true }

    return {
      ...state,
      personalFinanceCredits: state.personalFinanceCredits.filter(
        ({ idPersonalFinanceCredit }) => idPersonalFinanceCredit !== payload?.personalFinanceCredit?.idPersonalFinanceCredit
      ),
    }
  },
  [GET_PERSONAL_FINANCE_CARDS]: (state, { payload = {} }) => {
    if (!payload?.personalFinanceCards) return { ...state, isLoadingPersonalFinanceCards: false, errorInRequest: true }

    return {
      ...state,
      personalFinanceCards: payload?.personalFinanceCards,
      isLoadingPersonalFinanceCards: false,
    }
  },

  [UPLOAD_PERSONAL_FINANCE_CARD]: (state, { payload = {} }) => {
    if (!payload?.personalFinanceCard) return { ...state, errorInRequest: true }

    return {
      ...state,
      personalFinanceCards: [...state?.personalFinanceCards, payload?.personalFinanceCard],
    }
  },

  [UPDATE_PERSONAL_FINANCE_CARD]: (state, { payload = {} }) => {
    if (!payload?.personalFinanceCard) return { ...state, errorInRequest: true }

    return {
      ...state,
      personalFinanceCards: state.personalFinanceCards.map((personalFinanceCard) =>
        personalFinanceCard.idPersonalFinanceCard === payload?.personalFinanceCard?.idPersonalFinanceCard
          ? payload?.personalFinanceCard
          : personalFinanceCard
      ),
    }
  },

  [DELETE_PERSONAL_FINANCE_CARD]: (state, { payload = {} }) => {
    if (!payload?.personalFinanceCard) return { ...state, errorInRequest: true }

    return {
      ...state,
      personalFinanceCards: state.personalFinanceCards.filter(
        ({ idPersonalFinanceCard }) => idPersonalFinanceCard !== payload?.personalFinanceCard?.idPersonalFinanceCard
      ),
    }
  },
}

const personalFinances = (state = initialState, action) => {
  const { type } = action

  return handlers[type] ? handlers[type](state, action) : state
}

export default personalFinances
