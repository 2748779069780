import { Button } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import styles from './styles.module.scss'

function TrashFilesButton({ onOpen, disabled, ...rest }) {
  return (
    <div className={styles.wrapper}>
      <p>Los elementos de la papelera se eliminan definitivamente después de 30 días.</p>

      <Button
        disabled={disabled}
        onClick={onOpen}
        classes={{
          root: clsx(styles.button, !disabled && styles.buttonActive),
        }}
        {...rest}
      >
        Vaciar papelera
      </Button>
    </div>
  )
}

export default TrashFilesButton
