import {
  OPEN_STEPPER_MODAL,
  CONFIRM_DIALOG,
  OPEN_FORM_MODAL,
  UPDATE_SNACKBAR_MESSAGE,
  OPEN_POST_MODAL,
  CLOSE_STEPPER_MODAL,
  UPDATE_STEPPER_MODAL_DATA,
  NEXT_PAGE_STEPPER_MODAL,
  PREVIOUS_PAGE_STEPPER_MODAL,
} from 'constants/actionTypes'

const initialState = {
  openModal: false,
  modalFormConfig: {},
  snackbarMessage: {},
  confirmDialog: {},
  openStepperModal: false,
  stepperModalPages: [],
  stepperModalCurrentPage: 0,
  stepperModalData: {},
  stepperModalConfig: {},
  postModal: false,
  postModalInformation: {},
}

const handlers = {
  [OPEN_FORM_MODAL]: (state, { payload = {} }) => {
    return {
      ...state,
      ...payload,
    }
  },
  [UPDATE_SNACKBAR_MESSAGE]: (state, { payload = {} }) => {
    return {
      ...state,
      snackbarMessage: payload,
    }
  },
  [CONFIRM_DIALOG]: (state, { payload = {} }) => {
    return {
      ...state,
      confirmDialog: payload,
    }
  },

  [OPEN_POST_MODAL]: (state, { payload = {} }) => {
    return { ...state, ...payload }
  },

  [OPEN_STEPPER_MODAL]: (state, { payload = {} }) => {
    return { ...state, ...payload }
  },

  [NEXT_PAGE_STEPPER_MODAL]: (state, { payload = {} }) => {
    return {
      ...state,
      stepperModalCurrentPage:
        state.stepperModalCurrentPage + 1 < state.stepperModalPages.length
          ? state.stepperModalCurrentPage + 1
          : state.stepperModalPages.lenght - 1,
    }
  },

  [PREVIOUS_PAGE_STEPPER_MODAL]: (state, { payload = {} }) => {
    return {
      ...state,
      stepperModalCurrentPage: state.stepperModalCurrentPage - 1 >= 0 ? state.stepperModalCurrentPage - 1 : 0,
    }
  },

  [UPDATE_STEPPER_MODAL_DATA]: (state, { payload = {} }) => {
    return { ...state, stepperModalData: { ...state.stepperModalData, ...payload } }
  },

  [CLOSE_STEPPER_MODAL]: (state, { payload = {} }) => {
    return { ...state, openStepperModal: false, stepperModalData: {}, stepperModalCurrentPage: 0 }
  },
}

const modals = (state = initialState, action) => {
  const { type } = action

  return handlers[type] ? handlers[type](state, action) : state
}

export default modals
