import firebase from 'firebase'

import { COOKIE_EXPIRES_DAYS, UID_COOKIE } from 'constants/cookies'
import { getEnvConfig } from 'helpers/env'
import Cookies from 'js-cookie'

const firebaseConfig = getEnvConfig('REACT_APP_FIREBASE_CONFIG', {})

firebase.initializeApp(firebaseConfig)
firebase.analytics()

export const createFirebaseUser = async (email, password) =>
  await firebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then(({ user, error }) => {
      if (error) throw error

      return user?.uid
    })
    .catch((error) => ({ error: true, ...(error || {}) }))

export const signInFirebaseUser = async (email, password, remember) =>
  await firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then(({ user }) => {
      const uid = user?.uid

      if (remember) return Cookies.set(UID_COOKIE, uid)

      let expiresDate = new Date()
      expiresDate.setDate(expiresDate.getDate() + COOKIE_EXPIRES_DAYS)

      Cookies.set(UID_COOKIE, uid, expiresDate)
    })
    .catch(() => Cookies.remove(UID_COOKIE))

export const signOuFirebasetUser = async () =>
  await firebase
    .auth()
    .signOut()
    .then(() => Cookies.remove(UID_COOKIE))
    .catch(() => Cookies.remove(UID_COOKIE))

export const resetPassword = async ({ email }) => {
  const res = await firebase
    .auth()
    .sendPasswordResetEmail(email)
    .then(() => 'correo enviado')
    .catch((error) => error)

  return { payload: res, type: 'RESET_PASSWORD' }
}
