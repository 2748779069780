import { deleteCalendarAction, selectCalendarAction } from 'actions/calendarActions'
import DataGridTable from 'components/DataGridTable/DataGridTable'
import ScheduleModal from 'components/ScheduleModal/ScheduleModal'
import { formatFullDate } from 'helpers/date'
import useLocale from 'hooks/useLocale'
import useCalendarPage from 'pages/CalendarPage/useCalendarPage'
import React, { useMemo, useState } from 'react'

const getType = (params) => {
  const locale = localStorage.getItem('locale')

  return params.getValue(params.id, 'idMassEmail')
    ? locale === 'es'
      ? 'Correo Masivo'
      : 'Mass email'
    : locale === 'es'
    ? 'Publicación'
    : 'Post'
}

const getRecurringType = (params) => {
  const locale = localStorage.getItem('locale')

  if (params.value === 1) {
    return locale === 'es' ? 'Diario' : 'Daily'
  } else if (params.value === 2) {
    return locale === 'es' ? 'Semanal' : 'Weekly'
  } else {
    return locale === 'es' ? 'Mensual' : 'Monthly'
  }
}

const RecurrentActionsTable = ({ searchValue, canWrite }) => {
  const locale = useLocale()

  const COLUMNS = useMemo(
    () => [
      {
        headerName: locale === 'es' ? 'Título' : 'Title',
        field: 'name',
        width: 200,
        isFilterField: true,
        sortable: true,
      },
      {
        headerName: locale === 'es' ? 'Tipo' : 'Type',
        field: 'type',
        width: 150,
        valueGetter: getType,
        sortable: false,
      },
      {
        headerName: locale === 'es' ? 'Fecha' : 'Date',
        field: 'startDate',
        valueFormatter: (params) => formatFullDate(params.value),
        width: 200,
        sortable: false,
      },
      {
        headerName: locale === 'es' ? 'Recurrencia' : 'Recurrent type',
        field: 'recurringType',
        valueFormatter: getRecurringType,
        width: 200,
        sortable: false,
      },
    ],
    [locale]
  )
  const getActionsConfig = useMemo(
    () => ({
      hideBasicActions: !canWrite,
      headerName: locale === 'es' ? 'Acciones' : 'Actions',
      width: 100,
      field: 'idCalendarAction',
      queryDelete: deleteCalendarAction,
      handlerEdit: ({ selectedObject, dispatch }) => {
        setScheduleModal(true)
        dispatch(selectCalendarAction(selectedObject))
      },
    }),
    [locale, canWrite]
  )

  const [scheduleModal, setScheduleModal] = useState(false)

  const { calendarActions, isLoadingCalendarActions } = useCalendarPage()

  const filteredActions = useMemo(() => {
    return calendarActions
      .filter((action) => action['IS_PENDING'] && action['IS_RECURRING'] && !action.copy && !action.idMarketingCampaign)
      .sort((a, b) => {
        const aDate = new Date(a.created_at).getTime()
        const bDate = new Date(b.created_at).getTime()

        return bDate - aDate
      })
  }, [calendarActions])

  return (
    <>
      <DataGridTable
        columns={COLUMNS}
        data={filteredActions}
        actionsConfig={getActionsConfig}
        isLoading={isLoadingCalendarActions}
        filterSearchValue={searchValue}
      />

      <ScheduleModal open={scheduleModal} setOpen={setScheduleModal} />
    </>
  )
}

export default RecurrentActionsTable
