import { Button } from '@material-ui/core'
import useLocale from 'hooks/useLocale'
import React from 'react'
import { useNavigate } from 'react-router'
import styles from './SharedButton.module.scss'

const SharedButton = () => {
  const locale = useLocale()
  const navigate = useNavigate()
  const handleClick = () => {
    navigate('/dashboard/managment/google-drive?shared=true')
  }

  return (
    <>
      <Button
        startIcon={<i className="fas fa-share" />}
        variant="contained"
        classes={{ root: styles.button }}
        onClick={handleClick}
      >
        {locale === 'es' ? 'Archivos Compartidos' : 'Shared Files'}
      </Button>
    </>
  )
}

export default SharedButton
