import { Tooltip } from '@material-ui/core'
import classNames from 'classnames'
import MarkupContainer from 'components/common/MarkupContainer'
import { downloadByUrl } from 'helpers/downloadLink'
import { createUrl, getFileIcon } from 'helpers/files'
import useLocale from 'hooks/useLocale'
import React from 'react'
import ContentItem from '../ContentItem'
import styles from './CalendarEventContent.module.scss'

const CalendarEventContent = ({ content }) => {
  const locale = useLocale()

  const reduceUsers = content.users.reduce((acc, _user, index) => {
    if (index > 0) return `${acc}, ${_user.user.firstName} ${_user.user.lastName}`

    return `${_user.user.firstName} ${_user.user.lastName}`
  }, '')

  return (
    <div>
      <ContentItem
        title={locale === 'es' ? 'Descripción' : 'Description'}
        content={<MarkupContainer content={content.description} className={styles.description} />}
        className={styles.descriptionWrapper}
      />
      <ContentItem
        title={locale === 'es' ? 'Participantes' : 'Participantes'}
        content={reduceUsers}
        className={styles.descriptionWrapper}
      />

      {content.file && (
        <section className={styles.fileSection}>
          <strong>{locale === 'es' ? 'Archivos adjuntos' : 'Files'}</strong>
          <div className={styles.file}>
            <div className={styles.fileInfo}>
              <i className={getFileIcon(content.file.filename)} />
              <p>{content.file.filename}</p>
            </div>

            <Tooltip
              onClick={() => {
                downloadByUrl(createUrl(`public/calendar-event/${content.idCalendarEvent}/${content.file.filename}`))
              }}
              arrow
              placement="top"
              title={locale === 'es' ? 'Descargar' : 'Download'}
            >
              <i className={classNames('fas fa-download', styles.icon)} />
            </Tooltip>
          </div>
        </section>
      )}
    </div>
  )
}

export default CalendarEventContent
