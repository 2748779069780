import { Tab, Tabs } from '@material-ui/core'
import useLocale from 'hooks/useLocale'
import React, { useState } from 'react'
import styles from './EmployeeTabs.module.scss'
import FreeTime from './FreeTime'
import PaymentStubs from './PaymentStubs'
import Profile from './Profile'
import WorkedHours from './WorkedHours'

const EmployeeTabs = ({ employee }) => {
  const locale = useLocale()
  const [tab, setTab] = useState(0)

  const getNotifications = (freeTime) => {
    const count = freeTime?.reduce((acc, item) => (item?.status === 1 ? acc + 1 : acc), 0)

    return count === 0 ? null : count
  }

  const handleChangeTab = (event, newValue) => {
    setTab(newValue)
  }

  const tabClasses = { root: styles.tab, selected: styles.tabSelected }

  return (
    <section className={styles.wrapper}>
      <header className={styles.header}>
        <h2 className={styles.headerTitle}>Contenido</h2>

        <Tabs
          value={tab}
          onChange={handleChangeTab}
          allowScrollButtonsMobile
          variant="scrollable"
          indicatorColor="primary"
          scrollButtons="auto"
          classes={{
            root: styles.tabsRoot,
          }}
        >
          <Tab classes={tabClasses} label={locale === 'es' ? 'Perfil' : 'Profile'} />
          <Tab
            classes={tabClasses}
            label={
              locale === 'es' ? (
                <div className={styles.freeTimeHeaderWrapper}>
                  <p>Tiempo libre</p>
                  {getNotifications(employee.free_time) && (
                    <span className={styles.badge}>{getNotifications(employee.free_time)}</span>
                  )}
                </div>
              ) : (
                'Free Time'
              )
            }
          />
          <Tab classes={tabClasses} label={locale === 'es' ? 'Colilla de Pago' : 'Payment Stubs'} />
          <Tab classes={tabClasses} label={locale === 'es' ? 'Horas Trabajadas' : 'Worked Hours'} />
        </Tabs>
      </header>

      <section>
        {tab === 0 && <Profile employee={employee} />}
        {tab === 1 && <FreeTime employee={employee} />}
        {tab === 2 && <PaymentStubs employee={employee} />}
        {tab === 3 && <WorkedHours employee={employee} />}
      </section>
    </section>
  )
}

export default EmployeeTabs
