import { getConfigs } from 'actions/configs'
import { getFbPages, getPosts } from 'actions/socialmedia'
import useCompany from 'hooks/useCompany'
import useFetchResource from 'hooks/useFetchResource'
import useUsers from 'hooks/useUsers'
import { useMemo } from 'react'

const usePostsLoadData = () => {
  const { myUser } = useUsers()
  const { idCompany } = useCompany()
  const { idUser } = myUser ?? {}

  const promises = useMemo(() => {
    if (idUser && idCompany) {
      return [getConfigs(idCompany, idUser), getFbPages(idCompany), getPosts(idCompany)]
    }
  }, [idCompany, idUser])

  useFetchResource(promises)
}

export default usePostsLoadData
