import useLocale from 'hooks/useLocale'
import React, { useMemo } from 'react'
import FormHelper from '../../FormHelper'
import useTableModel from '../hooks/useTableModel'
import GraphicsValues from './GraphicsValues'
import TotalizationValues from './TotalizationValues'

const ValuesSelector = ({ indicator, dispatchIndicator }) => {
  const locale = useLocale()
  const table = useTableModel(indicator)

  const Component = useMemo(() => {
    const components = {
      totalization: TotalizationValues,
      graphic: GraphicsValues,
    }

    return components[indicator.type]
  }, [indicator.type])

  if (!(indicator?.type && indicator?.table)) return null
  if (indicator?.type === 'graphic' && !indicator?.graphicType) return null
  if (table?.hasSubModules && !indicator?.subTable) return null

  return (
    <div>
      <FormHelper
        title={locale === 'es' ? 'Valores' : 'Values'}
        helperMessage={
          locale === 'es'
            ? 'Seleccione los valores correspondientes para el indicador'
            : 'Select the corresponding values for the indicator'
        }
      />

      {Component && <Component indicator={indicator} dispatchIndicator={dispatchIndicator} />}
    </div>
  )
}

export default ValuesSelector
