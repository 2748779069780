import { Button, CircularProgress, Tooltip } from '@material-ui/core'
import { createEmployeeFile, deleteEmployeeFile, getEmployeeFiles } from 'actions/humanResources'
import { sendErrorMessage } from 'actions/modals'
import FileInput from 'components/FileInput/FileInput'
import FilePreviewer from 'components/FilePreviewer'
import LoadingSection from 'components/LoadingSection'
import { DOWNLOAD_EMPLOYEE_FILE } from 'constants/apiRoutes'
import { getFileIcon } from 'helpers/files'
import useLocale from 'hooks/useLocale'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import Section from '../Section'
import styles from './styles.module.scss'

const FileItem = ({ file, setFiles, handleSeeFile, fileLink }) => {
  const locale = useLocale()
  const [deleting, setDeleting] = useState(false)

  const handleDelete = async () => {
    setDeleting(true)
    await deleteEmployeeFile(+file?.IdEmployeeFile)
    setFiles((prev) => prev.filter(({ IdEmployeeFile }) => IdEmployeeFile !== file?.IdEmployeeFile))
    setDeleting(false)
  }

  return (
    <article className={styles.fileArticle}>
      <i className={getFileIcon(file.filename)} />
      <p className={styles.name}>{file.filename}</p>

      <section className={styles.actions}>
        {deleting ? (
          <CircularProgress />
        ) : (
          <>
            <Tooltip title={locale === 'es' ? 'Ver archivo' : 'See file'}>
              <i className="fas fa-eye" style={{ '--color': '#343434' }} onClick={() => handleSeeFile(fileLink)} />
            </Tooltip>
            <Tooltip title={locale === 'es' ? 'Descargar archivo' : 'Download file'}>
              <i className="fas fa-download" style={{ '--color': '#507938' }} onClick={() => window.open(fileLink)} />
            </Tooltip>
            <Tooltip title={locale === 'es' ? 'Eliminar archivo' : 'Delete file'}>
              <i className="fas fa-trash-alt" style={{ '--color': '#c9342f' }} onClick={handleDelete} />
            </Tooltip>
          </>
        )}
      </section>
    </article>
  )
}

const EmployeeFiles = ({ employee }) => {
  const dispatch = useDispatch()
  const { idEmployee } = employee
  const locale = useLocale()
  const fileRef = useRef()

  const [files, setFiles] = useState([])
  const [file, setFile] = useState('')
  const [loading, setLoading] = useState(true)
  const [uploading, setUploading] = useState(false)
  const [openPreviewModal, setOpenPreviewModal] = useState(false)
  const [previewingFile, setPreviewingFile] = useState(null)

  const handleClear = () => {
    setFile('')
    if (fileRef.current) {
      fileRef.current.clearInput()
    }
  }

  const handleSubmit = async (e) => {
    setUploading(true)
    const formData = new FormData()
    formData.append('file', file)
    formData.append('fileName', file?.name)
    formData.append('idEmployee', idEmployee)

    const res = await createEmployeeFile(formData)

    if (res?.data?.error) {
      setUploading(false)
      setFile('')
      dispatch(sendErrorMessage(locale === 'es' ? 'Hubo un error en el servidor' : 'There was a server error'))
      return
    }

    setFiles((prev) => [...prev, res?.data?.file])
    setFile('')
    setUploading(false)
    fileRef.current.clearInput()
  }

  const handleClosePreviewModal = () => {
    setOpenPreviewModal(false)
    setTimeout(() => {
      setPreviewingFile(null)
    }, 200)
  }

  const handleSeeFile = (link) => {
    setPreviewingFile(link)
    setOpenPreviewModal(true)
  }

  useEffect(() => {
    if (!idEmployee) return

    const fetch = async () => {
      const res = await getEmployeeFiles(idEmployee)
      setFiles(res?.files)
      setLoading(false)
    }

    fetch()
  }, [idEmployee])

  return (
    <Section title={locale === 'es' ? 'Expediente' : 'Records'}>
      {loading ? (
        <LoadingSection />
      ) : (
        <section>
          <header className={styles.filesHeader}>
            <h3>{locale === 'es' ? 'Archivos' : 'Files'}</h3>

            <div>
              <FileInput
                className={styles.uploadFileInput}
                ref={fileRef}
                field={{
                  name: 'file',
                  icon: 'fas fa-paperclip',
                  variant: 'outlined',
                  required: true,
                  key: 'key-file',
                  label: locale === 'es' ? 'Subir archivo' : 'Upload file',
                  InputLabelProps: {
                    shrink: true,
                  },
                }}
                onChange={(e) => setFile(e.target.files[0])}
                onClear={handleClear}
              />

              {uploading ? (
                <LoadingSection />
              ) : (
                Boolean(file) && (
                  <Button onClick={handleSubmit} color="primary" variant="contained" fullWidth disabled={!file?.name?.length}>
                    {locale === 'es' ? 'Guardar archivo' : 'Save file'}
                  </Button>
                )
              )}
            </div>
          </header>

          {files.length ? (
            <section className={styles.filesSection}>
              {files.map((file) => (
                <FileItem
                  key={file.IdEmployeeFile}
                  file={file}
                  setFiles={setFiles}
                  handleSeeFile={handleSeeFile}
                  fileLink={`${DOWNLOAD_EMPLOYEE_FILE}?filename=${file?.filename}&id=${file.idEmployee}`}
                />
              ))}
            </section>
          ) : (
            <p className={styles.helper}>{locale === 'es' ? 'No hay archivos' : 'No files'}</p>
          )}

          <FilePreviewer file={previewingFile} open={openPreviewModal} handleClose={handleClosePreviewModal} />
        </section>
      )}
    </Section>
  )
}

export default EmployeeFiles
