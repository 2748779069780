import { useCallback } from 'react'

const useTableColumnWidth = () => {
  const currentWindowsSize = window.innerWidth

  const getWidth = useCallback((width = 100) => (currentWindowsSize <= 768 ? width : null), [currentWindowsSize])
  const getFlex = useCallback((flex = 1) => (currentWindowsSize <= 768 ? null : flex), [currentWindowsSize])

  return [getWidth, getFlex]
}

export default useTableColumnWidth
