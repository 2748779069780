import useUsers from './useUsers'

const useCompany = () => {
  const { myUser } = useUsers()
  const { company } = myUser || {}
  const isOwner = company?.ownerIdUser === myUser?.idUser

  return { ...company, isOwner } ?? {}
}

export default useCompany
