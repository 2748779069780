import classNames from 'classnames'
import useLocale from 'hooks/useLocale'
import useMoney from 'hooks/useMoney'
import _parseBonuses from 'pages/HumanResourcesPage/Tabs/Bonuses/helpers/parseBonuses'
import React, { useMemo } from 'react'
import styles from './styles.module.scss'

const TotalItem = ({ value, title, valueClass }) => {
  return (
    <div className={styles.totalItem}>
      <p className={styles.totalItemTitle}>{title}</p>
      <p className={classNames(styles.totalItemValue, valueClass)}>{value}</p>
    </div>
  )
}

const StubsTotals = ({ employee }) => {
  const locale = useLocale()
  const { stubs, currency } = employee
  const { formatMoney } = useMoney()

  const total = useMemo(() => stubs.reduce((acc, s) => acc + s.totalPayment, 0), [stubs])
  const totalBonus = useMemo(() => {
    const [currentBonus] = _parseBonuses([employee], new Date().getFullYear())

    return currentBonus.bonus
  }, [employee])
  const stubsToPay = useMemo(() => stubs.filter((s) => s.IS_PENDING).length, [stubs])

  const formatStubsToPay = (s) => (locale === 'es' ? `${s} por pagar` : `${s} to pay`)

  return (
    <div className={styles.wrapper}>
      <TotalItem value={formatMoney(total, currency)} title={locale === 'es' ? 'Total pagado' : 'Total payed'} />
      <TotalItem value={formatMoney(totalBonus || 0, currency)} title={locale === 'es' ? 'Aguinaldo actual' : 'Actual bonus'} />
      <TotalItem
        value={stubsToPay === 0 ? (locale === 'es' ? 'Ninguna' : 'None') : formatStubsToPay(stubsToPay)}
        title={locale === 'es' ? 'Colillas pendientes' : 'Pending stubs'}
        valueClass={stubsToPay === 0 ? styles.totalItemValueGreen : styles.totalItemValueRed}
      />
    </div>
  )
}

export default StubsTotals
