import useLocale from 'hooks/useLocale'
import WeeklyHours from 'pages/HumanResourcesPage/Tabs/Employees/WorkedHours/WeeklyHours'
import React from 'react'
import Section from '../Section'

const ByWeek = ({ employee, defaultTitle, hideActions }) => {
  const locale = useLocale()
  const { work_day: data } = employee
  const title = defaultTitle ? defaultTitle : locale === 'es' ? 'Por semana' : 'By week'

  return (
    <Section title={title}>
      <WeeklyHours data={data} hideActions={hideActions} />
    </Section>
  )
}

export default ByWeek
