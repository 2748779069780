import { Button } from '@material-ui/core'
import Dialog from 'components/Dialog/Dialog'
import LoadingSection from 'components/LoadingSection'
import { useGoogleAuth } from 'helpers/google'
import useLocale from 'hooks/useLocale'
import React from 'react'
import { toast } from 'react-toastify'
import { useEmptyTrash } from '../useDriveApi'

const EmptyTrashModal = ({ open, onClose, files }) => {
  const locale = useLocale()
  const title = locale === 'es' ? `Vaciar Papelera` : `Empty Trash"`
  const { authToken } = useGoogleAuth()
  const { mutateAsync: emtpyTrash, isLoading } = useEmptyTrash()

  const handleOnClick = async () => {
    let ids = files.map(({ id }) => id)
    await emtpyTrash(
      { ids, authToken },
      {
        onSuccess: () => {
          toast.success(locale === 'es' ? 'Papelera vaciada' : 'Trash emptied', {
            autoClose: 2000,
          })
          onClose()
        },
        onError: () => {
          toast.error(locale === 'es' ? 'Error al vaciar la papelera' : 'Error emptying trash', {
            autoClose: 2000,
          })
        },
      }
    )
  }

  return (
    <Dialog maxWidth="sm" open={open} handleCloseAction={onClose} title={title}>
      <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>
        ¿Estás seguro de que quieres eliminar todo el contenido de la papelera?
      </h2>
      <div style={{ textAlign: 'center' }}>
        <p style={{ textAlign: 'center', marginBottom: '20px', fontStyle: 'italic' }}>Esta acción es irreversible</p>
        {isLoading ? (
          <LoadingSection />
        ) : (
          <Button variant="contained" color="primary" onClick={handleOnClick}>
            Si, entiendo y quiero continuar
          </Button>
        )}
      </div>
    </Dialog>
  )
}

export default EmptyTrashModal
