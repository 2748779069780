import MarkupContainer from 'components/common/MarkupContainer'
import useLocale from 'hooks/useLocale'
import React, { useMemo } from 'react'
import ContentItem from '../ContentItem'
import styles from './MarketingTaskContent.module.scss'

const MarketingTaskContent = ({ content }) => {
  const locale = useLocale()
  const { content: taskDescription, users } = content ?? {}
  const managers = useMemo(
    () =>
      users?.reduce((acc, _user, index) => {
        const name = `${_user.user.firstName} ${_user.user.lastName}`

        if (index > 0) return `${acc}, ${name}`
        else return name
      }, ''),
    [users]
  )

  return (
    <div className={styles.content}>
      <h3 className={styles.descriptionHeader}>{locale === 'es' ? 'Descripción ' : 'Description'}</h3>
      <MarkupContainer content={taskDescription} className={styles.description} />

      <div className={styles.managersContainer}>
        <ContentItem title={locale === 'es' ? 'Encargado' : 'Manager'} content={users.length ? managers : '-'} />
      </div>
    </div>
  )
}

export default MarketingTaskContent
