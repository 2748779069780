import classNames from 'classnames'
import React from 'react'
import styles from './Section.module.scss'

const Section = ({ children, title, className }) => {
  return (
    <section className={classNames(styles.wrapper, className)}>
      <h2 className={styles.title}>{title}</h2>

      {children}
    </section>
  )
}

export default Section
