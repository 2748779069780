import { Button } from '@material-ui/core'
import useCompany from 'hooks/useCompany'
import React from 'react'
import { useNavigate, useParams } from 'react-router'
import { useFilePermissions } from '../hooks/useFilePermissions'
import DataUsage from './DataUsage'
import styles from './Header.module.scss'
import NewButton from './NewButton/NewButton'
import SharedButton from './SharedButton/SharedButton'
import TrashButton from './TrashButton/TrashButton'

const Header = ({ hideNew, isOutsideMyDrive, isFolderEmpty }) => {
  const navigate = useNavigate()
  const { isOwner } = useCompany()
  const { folderId } = useParams()
  const { getFilePermissionProps } = useFilePermissions()
  const { isWriter, isPrivateFile } = getFilePermissionProps(folderId)
  const canCreateFiles = isFolderEmpty ? false : isOwner ? true : isPrivateFile ? isWriter && folderId : true

  return (
    <div className={styles.wrapper}>
      <div className={styles.buttons_row}>
        {!hideNew && (
          <>
            {canCreateFiles && <NewButton />}

            {!isOutsideMyDrive && (
              <>
                <SharedButton />
                <TrashButton />
              </>
            )}
          </>
        )}

        {isOutsideMyDrive && (
          <Button
            startIcon={<i className="fas fa-home" />}
            variant="contained"
            classes={{ root: styles.button }}
            onClick={() => navigate('/dashboard/managment/google-drive')}
          >
            Inicio
          </Button>
        )}
      </div>
      <DataUsage />
    </div>
  )
}

export default Header
