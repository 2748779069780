const reduceChartData = (data, reducerKey) => {
  if (!data) return []

  const reducer = (acc, item) => {
    const currentValue = item[reducerKey]

    if (!acc[currentValue]) {
      acc[currentValue] = {
        name: currentValue ?? 'N/A',
        count: 0,
      }
    }

    acc[currentValue].count++

    return acc
  }

  return Object.entries(data?.reduce(reducer, {}))?.map((value) => {
    return value[1]
  })
}

export { reduceChartData }
