import { ENV_SELECTED } from 'env'

// **************
// Routing
// **************
export const LOGIN_PAGE_URL = '/'
export const HELP_PAGE_URL = '/help'
export const DASHBOARD_PAGE_URL = 'dashboard'
export const CUSTOMERS_PAGE_URL = 'customers'
export const CUSTOMERS_PAGE_TABS_URL = 'customers/:tab'
export const HISTORIC_PAGE_URL = 'historic'
export const PROFILE_PAGE_URL = 'perfil'
export const MY_COMPANY_PAGE_URL = 'my-company'
export const MY_SUBSCRIPTION_PAGE_URL = 'my-subscription'
export const MY_COMPANY_PAGE_TABS_URL = 'my-company/:tab'
export const CONFIG_PAGE_URL = 'config'

// Social Media
export const CALENDAR_PAGE_URL = 'social-media/calendar'
export const POSTS_PAGE_URL = 'social-media/posts'
export const MESSAGES_PAGE_URL = 'social-media/messages'
export const CLIENT_FORMS = 'social-media/forms'
export const FORM = 'social-media/forms/:id'
export const MARKETING_CAMPAIGNS = 'social-media/marketing-campaigns'
export const FORM_TABS_URL = 'social-media/forms/:id/:tab'
export const IMAGES_BANK_URL = 'social-media/images-bank'

// Finances
export const PROJECTS_PAGE_URL = 'finances/projects'
export const PROJECT_PAGE_URL = 'finances/projects/:id'
export const COSTS_PAGE_URL = 'finances/costs'
export const COSTS_PAGE_TABS_URL = 'finances/costs/:tab'
export const COST_PAGE_URL = 'finances/costs/cost/:id'
export const COST_PAGE_TABS_URL = 'finances/costs/cost/:id/:tab'
export const PERSONAL_FINANCES_URL = 'finances/personal'
export const QUOTATIONS_URL = 'finances/quotations'
export const QUOTATION_URL = 'finances/quotations/:id'

// Managment
export const GOOGLE_DRIVE_PAGE = 'managment/google-drive'
export const PROCESSES_PAGE = 'managment/processes'
export const PROCESS_PAGE = 'managment/processes/:id'
export const TOOLS_PAGE_URL = 'managment/tools'
export const HUMAN_RESOURCES_URL = 'managment/human-resources'
export const HUMAN_RESOURCES_TABS_URL = 'managment/human-resources/:tab'
export const HUMAN_RESOURCES_SUBTABS_URL = 'managment/human-resources/:tab/:subTab'
export const BOARDS_PAGE_URL = 'managment/boards'
export const INVENTORY_PAGE_URL = 'managment/inventory'
export const INVENTORY_TABS_URL = 'managment/inventory/:tab'
export const INVENTORY_SUBTABS_URL = 'managment/inventory/:tab/:subTab'
export const INVENTORY_REPORT_URL = 'managment/report/inventory'

// Images Editor
export const IMAGES_EDITOR_URL = 'images-editor'

// Cash Flow
export const CASH_FLOW_URL = 'finances/cash-flow'

// Datanalisis
export const DATANALISIS_URL = 'data-analisis'

// Admin
export const USERS_PAGE_URL = 'admin/users/:tab'
export const TOOLS_CONFIG_PAGE_URL = 'admin/config-tools'
export const COMPANY_FORM_CONFIG = 'admin/company-form-config'
export const REPORT_ERRORS_PAGE_URL = 'admin/error-reports'
export const AI_BLOCKS_PAGE_URL = 'admin/ai-blocks'

// Other routes
export const REPORT_ROUTE = 'pdf/report/:type/:id'
export const PUBLIC_FORM = 'public-form/:idForm'
export const PUBLIC_FORM_SUCCESS = 'public-form/:idForm/success'
export const PRO_LANDING_PAGE_URL = 'pro'

export const PRIVACY_POLICY_PAGE_URL = '/politica-de-privacidad'
export const USE_POLICY_PAGE_URL = '/politica-de-uso'
export const COOKIES_POLICY_PAGE_URL = '/politica-de-cookies'
export const AI_TEXT_GENERATOR_PAGE_URL = '/generador-texto-ia'

// **************
// External Urls
// **************
export const INGENIUST_PAGE = 'https://ingeniust.net/'

export const PUBLIC_FORM_URL_BY_ENV = {
  qa: 'https://qa-digital-pymes.vercel.app/public-form/',
  local: 'http://localhost:3000/public-form/',
  prod: 'https://digitalpymes.net/public-form/',
}?.[ENV_SELECTED]
