import { Dialog, DialogContent, Typography } from '@material-ui/core'
import { closePostModal } from 'actions/modals'
import { Facebook, FacebookAndInstagram, Instagram } from 'components/PostTable/PostTable'
import { formatDate } from 'helpers/date'
import useLocale from 'hooks/useLocale'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import styles from './PostModal.module.scss'

const socialNetworkIcons = (value) => {
  const socialNetworks = value.split(',')

  return socialNetworks.length === 1 && socialNetworks[0] === 'Facebook' ? (
    <Facebook socialNetworks={socialNetworks} />
  ) : socialNetworks.length === 1 && socialNetworks[0] === 'Instagram' ? (
    <Instagram socialNetworks={socialNetworks} />
  ) : (
    <FacebookAndInstagram socialNetworks={socialNetworks} />
  )
}

const PostModal = ({ open, postInformation }) => {
  const locale = useLocale()
  const dispatch = useDispatch()
  const { text, image, socialNetworks, created_at } = postInformation?.selectedObject ?? {}

  const formattedDate = formatDate(created_at ?? '', 'YYYY-MM-DD h:mm A')
  const icons = open && socialNetworkIcons(socialNetworks)

  const handleClose = useCallback(() => dispatch(closePostModal()), [dispatch])

  return (
    open && (
      <Dialog open={open} onClose={handleClose}>
        <DialogContent className={styles.dialog}>
          {image.length !== 0 && (
            <figure className={styles.imageWrapper}>
              <img src={image} alt={text} className={styles.coverImage} />
            </figure>
          )}
          <Typography className={styles.postContent}>{text}</Typography>
          <footer className={styles.footer}>
            <Typography className={styles.footerText}>
              <strong>{locale === 'es' ? 'Redes Sociales' : 'Socialnetworks'}:</strong> {icons}
            </Typography>
            <Typography className={styles.footerText}>
              <strong>{locale === 'es' ? 'Fecha de publicación' : 'Publication date'}:</strong> {formattedDate}
            </Typography>
          </footer>
        </DialogContent>
      </Dialog>
    )
  )
}

export default PostModal
