import { formatDate } from 'helpers/date'

export const parseCalendarAction = (calendarAction) => {
  return {
    ...calendarAction,
    general: {
      ...calendarAction.general,
      startDate: `${formatDate(calendarAction.general.startDate, 'YYYY-MM-DD', true)} ${formatDate(
        calendarAction.general.time,
        'HH:mm:00',
        true
      )}`,
    },
  }
}
