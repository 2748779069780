import { nanoid } from 'nanoid'

function Graphic(type, icon, options, lang) {
  this.type = type
  this.icon = icon
  this.es = lang.es
  this.en = lang.en
  this.options = options.map((option) => new Option(...option, nanoid())) || []
}

function Option(key, lang, id) {
  this.key = key
  this.id = id
  this.es = lang.es
  this.en = lang.en
}

const CHARTS_MODELS = (() => {
  const locale = localStorage.getItem('locale')
  const content = [
    new Graphic(
      'bars',
      'fa-solid fa-chart-simple',
      [
        ['xAxis', { es: 'Eje X', en: 'X Axis' }],
        ['yAxis', { es: 'Eje Y', en: 'Y Axis' }],
      ],
      { es: 'Gráfico de barras', en: 'Bar chart' }
    ),
    new Graphic('pie', 'fa-solid fa-chart-pie', [['value', { es: 'Valor', en: 'Value' }]], {
      es: 'Gráfico ciruclar',
      en: 'Pie chart',
    }),
    new Graphic('donut', 'fa-solid fa-chart-pie', [['value', { es: 'Valor', en: 'Value' }]], {
      es: 'Gráfico donut',
      en: 'Donut chart',
    }),
    new Graphic('half-moon', 'fa-solid fa-chart-pie', [['value', { es: 'Valor', en: 'Value' }]], {
      es: 'Gráfico ciruclar recto',
      en: 'Straight angle pie chart',
    }),
    new Graphic(
      'line',
      'fa-solid fa-chart-line',
      [
        ['xAxis', { es: 'Eje X', en: 'X Axis' }],
        ['yAxis', { es: 'Eje Y', en: 'Y Axis' }],
      ],
      {
        es: 'Gráfico lineal',
        en: 'Line chart',
      }
    ),
    new Graphic(
      'area',
      'fa-solid fa-chart-area',
      [
        ['xAxis', { es: 'Eje X', en: 'X Axis' }],
        ['yAxis', { es: 'Eje Y', en: 'Y Axis' }],
      ],
      {
        es: 'Gráfico de area',
        en: 'Area chart',
      }
    ),
    new Graphic('funnel', 'fa-solid fa-filter', [['value', { es: 'Valor', en: 'Value' }]], {
      es: 'Gráfico de embudo',
      en: 'Funnel chart',
    }),
    new Graphic('map', 'fa-solid fa-map', [['value', { es: 'Valor', en: 'Value' }]], {
      es: 'Gráfico mapa',
      en: 'Tree map chart',
    }),
  ]

  return content.map((element, index) => ({
    id: index,
    text: element[locale],
    type: element.type,
    icon: element.icon,
    options: element.options,
  }))
})()

export default CHARTS_MODELS
