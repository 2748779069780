import { saveAs } from 'file-saver'
import JSZip from 'jszip'

export function createDownloadableZip(fileStructureObject, zip = new JSZip()) {
  let { files, folderName } = fileStructureObject

  if (folderName !== undefined) {
    zip = zip.folder(folderName)
  }

  if (files?.length > 0) {
    files?.forEach((file) => {
      if (file.hasOwnProperty('folderName')) {
        createDownloadableZip(file, zip)
      } else {
        zip.file(file.name, file.blob)
      }
    })
  }

  return zip
}

export function promptDownload(zip, name) {
  zip.generateAsync({ type: 'blob' }).then(function (content) {
    saveAs(content, name)
  })
}
