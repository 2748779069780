import { isMobileOrTablet } from 'helpers/breackpoints'
import useLocale from 'hooks/useLocale'
import useScreen from 'hooks/useScreen'
import HeaderFilters from '../HeaderFilters'
import MobileMenu from '../MobileMenu'
import styles from './Header.module.scss'

const Header = () => {
  const { breackpoint } = useScreen()
  const locale = useLocale()

  return (
    <header className={styles.headerContainer}>
      <div className={styles.header}>
        {isMobileOrTablet(breackpoint) && <MobileMenu />}
        <h1 className={styles.title}>{locale === 'es' ? 'Gastos Personales' : 'Personal finances'}</h1>
      </div>

      <HeaderFilters />
    </header>
  )
}

export default Header
