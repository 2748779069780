import { Button, CircularProgress, TextField, Tooltip } from '@material-ui/core'
import classNames from 'classnames'
import Dialog from 'components/Dialog/Dialog'
import { formatDate } from 'helpers/date'
import useLocale from 'hooks/useLocale'
import useMoney from 'hooks/useMoney'
import useUsers from 'hooks/useUsers'
import React, { useState } from 'react'
import StatusChip from '../StatusChip/StatusChip'
import usePreviewStub from '../hooks/usePreviewStub'
import ChangeStatus from './ChangeStatus'
import styles from './PreviewStub.module.scss'
import getContent from './content'
import PDF from './pdf'

const Item = ({ title, value }) => (
  <article className={styles.control}>
    <p className={styles.label}>{title}</p>
    <p className={styles.value}>{value}</p>
  </article>
)

const PreviewStub = ({ open, setOpen, stub: stubId, employee, handleClose, canWrite = false }) => {
  const { handleDownload, handleSendStub, formatMoneyPDF, sendingEmail, email, setEmail, stub, ref } = usePreviewStub(
    employee,
    stubId
  )
  const { myUser } = useUsers()
  const {
    company: { companyLogo, name: companyName },
  } = myUser ?? {}

  const { name, currency, bankAccount } = employee ?? {}
  const locale = useLocale()
  const { fields, actions } = getContent(locale)
  const { formatMoney } = useMoney()

  const [copied, setCopied] = useState(null)

  const {
    date,
    workedHours,
    extraHours,
    hourlyRate,
    extraHourlyRate,
    basePayment,
    deductions,
    totalPayment,
    IS_PENDING,
    description,
    extraPayment,
    extraPaymentDescription,
    extraHoursPayment,
  } = stub ?? {}

  return (
    <Dialog
      open={open}
      setOpen={setOpen}
      title={locale === 'es' ? 'Colilla de pago' : 'Payment stub'}
      handleCloseAction={handleClose}
      maxWidth="sm"
      rootClassName={styles.root}
      paperClass={styles.paper}
    >
      <section className={styles.content}>
        <section className={styles.information}>
          <header className={styles.header}>
            <div>
              <h4 className={styles.title}>{name}</h4>
              <p className={styles.bankAccount}>
                <strong>{locale === 'es' ? 'Cuenta bancaria: ' : 'Bank account: '}</strong>
                {bankAccount ?? '-'}
                {Boolean(bankAccount) && (
                  <Tooltip arrow placement="top" title={copied ? copied : locale === 'es' ? 'Copiar' : 'Copy'}>
                    <i
                      className={classNames('fas fa-copy', styles.copy)}
                      onClick={() => {
                        setCopied('Copiado!')
                        navigator.clipboard.writeText(bankAccount)

                        setTimeout(() => {
                          setCopied(null)
                        }, 600)
                      }}
                    />
                  </Tooltip>
                )}
              </p>
            </div>

            <article className={styles.status}>
              <StatusChip value={IS_PENDING} />
              {canWrite && <ChangeStatus value={IS_PENDING} data={stub} />}
            </article>
          </header>

          <section className={styles.firstSection}>
            <Item value={formatDate(date, 'DD MMMM YYYY', false)} title={fields.date} />
            <Item value={workedHours} title={fields.workedHours} />
            <Item value={extraHours ?? 0} title={fields.extraHours} />
          </section>

          <section className={styles.secondSection}>
            <h5>{locale === 'es' ? 'Detalles' : 'Deatails'}</h5>

            {window.innerWidth <= 768 ? null : (
              <Item value={locale === 'es' ? 'Monto' : 'Amount'} title={locale === 'es' ? 'Descripción' : 'Description'} />
            )}
            <Item value={formatMoney(hourlyRate, currency)} title={fields.hourlyRate} />
            <Item value={formatMoney(extraHourlyRate, currency)} title={fields.extraHourlyRate} />
            <Item value={formatMoney(basePayment, currency)} title={fields.hoursPayment} />
            <Item value={formatMoney(extraHoursPayment, currency)} title={fields.extraHoursPayment} />
            <Item value={formatMoney(deductions, currency)} title={fields.deductions} />
            <Item value={formatMoney(extraPayment ?? 0, currency)} title={fields.extraPayment} />
            <Item value={extraPaymentDescription ?? 'N/A'} title={fields.extraPaymentDescription} />
          </section>

          <section className={styles.thirdSection}>
            <Item value={formatMoney(totalPayment, currency)} title={fields.totalPayment} />
            <Item value={description ?? 'N/A'} title={fields.note} />
          </section>
        </section>

        <section className={styles.actions}>
          <section className={styles.downloads}>
            <p>{actions.download}:</p>
            <div className={styles.downloadTypes}>
              <div className={styles.downloadType} onClick={() => handleDownload(1)}>
                <i className="fas fa-file-pdf" />
                <span>{actions.pdf}</span>
              </div>
              <div className={styles.downloadType} onClick={() => handleDownload(2)}>
                <i className="fas fa-image" />
                <span>{actions.image}</span>
              </div>
            </div>
          </section>

          <section className={styles.email}>
            <p>{actions.email}:</p>
            <div>
              <TextField size="small" type="email" variant="outlined" value={email} onChange={(e) => setEmail(e.target.value)} />
              {sendingEmail ? (
                <CircularProgress size={24} />
              ) : (
                <Button color="primary" variant="contained" onClick={handleSendStub}>
                  <i className="fa-solid fa-paper-plane" />
                </Button>
              )}
            </div>
          </section>
        </section>

        <section className={styles.imageBlob}>
          <PDF
            ref={ref}
            stub={stub}
            employee={employee}
            locale={locale}
            formatMoney={formatMoneyPDF}
            companyLogo={companyLogo}
            companyName={companyName}
            image
          />
        </section>
      </section>
    </Dialog>
  )
}

export default PreviewStub
