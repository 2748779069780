const es = {
  title: 'Este sitio web utiliza cookies',
  content: 'DigitalPymes utiliza cookies para ayudarnos a proporcionar, proteger y mejorar nuestros servicios. ',
  getMoreDetails: {
    firstPart: 'Ver nuestras ',
    link: 'Políticas de Privacidad',
    lastPart: ' para más detalles.',
  },
  acceptButton: 'Aceptar',
}

const en = {
  title: 'This website uses cookies',
  content: 'DigitalPymes uses cookies to help us to provide, protect and improve our services. ',
  getMoreDetails: {
    firstPart: 'See our ',
    link: 'Privacy Policies',
    lastPart: ' for more details.',
  },
  acceptButton: 'Accept',
}

const getLocale = (locale) => (locale === 'es' ? es : en)

export default getLocale
