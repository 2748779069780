import React from 'react'
import { Button } from '@material-ui/core'
import { uploadCalendarActionComment } from 'actions/calendarActions'
import TextEditor from 'components/TextEditor'
import useLocale from 'hooks/useLocale'
import useTextEditor from 'hooks/useTextEditor'
import useUsers from 'hooks/useUsers'
import { useDispatch } from 'react-redux'
import styles from './CalendarActionContentComments.module.scss'
import LoadingSection from 'components/LoadingSection'
import useToggle from 'hooks/useToggle'
import Comment from 'components/Comment'

const CalendarActionContentComments = ({ calendarAction }) => {
  const locale = useLocale()
  const dispatch = useDispatch()
  const {
    myUser: { idUser },
  } = useUsers() ?? {}
  const { idCalendarAction } = calendarAction ?? {}
  const {
    scrollToBottom,
    commentSectionRef,
    commenting,
    comment,
    onChange,
    onSave,
    toggleCommenting,
    isCommentEmpty,
    setComment,
  } = useTextEditor()
  const [pendingComment, setPendingComment] = useToggle()

  const handleOnSave = () =>
    onSave(
      async () =>
        new Promise(async (resolve) => {
          setPendingComment()

          const response = await uploadCalendarActionComment({ comment, idCalendarAction, idUser })

          dispatch(response)
          setPendingComment()
          scrollToBottom()
          resolve(response)
        })
    )

  const handleCancel = () => {
    toggleCommenting()
    setComment('')
  }

  return (
    <>
      <section className={styles.commentsWrapper} ref={commentSectionRef}>
        <strong className={styles.commentsTitle}>{locale === 'es' ? 'Comentarios' : 'Comments'}</strong>
        {!calendarAction?.comments?.length ? (
          <p className={styles.commentsEmpty}>{locale === 'es' ? 'No hay comentarios...' : 'No comments...'}</p>
        ) : (
          calendarAction?.comments?.map((_comment) => <Comment key={_comment.idCalendarActionComment} _comment={_comment} />)
        )}
      </section>
      <section className={styles.wrapper}>
        {commenting ? (
          <>
            <TextEditor value={comment} onChange={onChange} editorRootClass={styles.textEditor} />

            <div className={styles.buttons}>
              {pendingComment ? (
                <LoadingSection />
              ) : (
                <>
                  <Button variant="text" color="primary" onClick={handleCancel}>
                    {locale === 'es' ? 'Cancelar' : 'Cancel'}
                  </Button>
                  <Button variant="contained" color="primary" disabled={isCommentEmpty} onClick={handleOnSave}>
                    {locale === 'es' ? 'Comentar' : 'Comment'}
                  </Button>
                </>
              )}
            </div>
          </>
        ) : (
          <div className={styles.toggler} onClick={toggleCommenting}>
            <i className="fas fa-comment" /> <p>{locale === 'es' ? 'Comentar...' : 'Comment...'}</p>
          </div>
        )}
      </section>
    </>
  )
}

export default CalendarActionContentComments
