import { GET_AI_BLOCKS, SET_AI_BLOCKS, UPDATE_AI_BLOCKS, DELETE_AI_BLOCKS, GENERATE_TEXT_AI_BLOCKS } from 'constants/actionTypes'

const initialState = {
  aiBlocks: [],
  errorInRequest: false,
  isLoadingAiBlocks: true,
}

const handlers = {
  [GET_AI_BLOCKS]: (state, { payload = {} }) => {
    if (!payload?.aiBlocks) return { ...state, isLoadingAiBlocks: false, errorInRequest: true }

    return {
      ...state,
      aiBlocks: payload?.aiBlocks ?? [],
      isLoadingAiBlocks: false,
      errorInRequest: false,
    }
  },
  [SET_AI_BLOCKS]: (state, { payload = {} }) => {
    if (!payload?.aiBlock) return { ...state, errorInRequest: true }
    return {
      ...state,
      aiBlocks: [{ ...(payload?.aiBlock ?? {}) }, ...state?.aiBlocks],
      errorInRequest: false,
    }
  },
  [UPDATE_AI_BLOCKS]: (state, { payload = {} }) => {
    if (!payload?.aiBlock) return { ...state, errorInRequest: true }
    return {
      ...state,
      aiBlocks: state?.aiBlocks?.map((aiBlock) =>
        aiBlock?.idAiBlock === payload?.aiBlock?.idAiBlock ? { ...(payload?.aiBlock ?? {}) } : aiBlock
      ),
      errorInRequest: false,
    }
  },
  [DELETE_AI_BLOCKS]: (state, { payload = {} }) => {
    if (!payload?.aiBlock) return { ...state, errorInRequest: true }

    return {
      ...state,
      aiBlocks: state?.aiBlocks?.filter(({ idAiBlock }) => idAiBlock !== payload?.aiBlock?.idAiBlock) ?? [],
      errorInRequest: false,
    }
  },
  [GENERATE_TEXT_AI_BLOCKS]: (state, { payload = {} }) => {
    if (!payload?.text) return { ...state, errorInRequest: true }
    return {
      ...state,
      generateText: payload?.text ?? '',
      errorInRequest: false,
    }
  },
}

const aiBlocks = (state = initialState, action) => {
  const { type } = action

  return handlers[type] ? handlers[type](state, action) : state
}

export default aiBlocks
