import {
  DELETE_CASH_FLOW,
  DELETE_CASH_FLOW_EXPENSE,
  DELETE_CASH_FLOW_INCOME,
  GET_CASH_FLOW,
  UPDATE_CASH_FLOW,
  UPDATE_CASH_FLOW_CURRENCY,
  UPDATE_CASH_FLOW_EXPENSE,
  UPDATE_CASH_FLOW_FILTER,
  UPDATE_CASH_FLOW_INCOME,
  UPLOAD_CASH_FLOW,
  UPLOAD_CASH_FLOW_EXPENSE,
  UPLOAD_CASH_FLOW_INCOME,
} from 'constants/actionTypes'
import {
  deleteExpense,
  deleteIncome,
  parseRegisters,
  pushExpense,
  pushIncome,
  updateExpense,
  updateIncome,
} from 'helpers/cashFlow.reducer'

const initialState = {
  cashFlow: null,
  incomes: {
    all: [],
    1: [],
    2: [],
    3: [],
  },
  expenses: {
    all: [],
    1: [],
    2: [],
    3: [],
  },
  filters: {
    search: '',
    dateType: 1,
    date: new Date(),
    startRange: new Date(),
    endRange: new Date(),
  },
  currency: 'crc',
  isLoadingCashFlow: true,
}

const handlers = {
  [GET_CASH_FLOW]: (state, { payload }) => {
    const currentCashFlow = payload?.cashFlows?.[0]
    if (!currentCashFlow) return state

    const { incomes, expenses, ...cashFlow } = currentCashFlow
    const parsedIncomes = { ...parseRegisters(incomes, state), all: incomes }
    const parsedExpenses = { ...parseRegisters(expenses, state), all: expenses }

    return {
      ...state,
      cashFlow,
      incomes: parsedIncomes,
      expenses: parsedExpenses,
      baseIncomes: incomes,
      baseExpenses: expenses,
      isLoadingCashFlow: false,
    }
  },
  [UPLOAD_CASH_FLOW]: (state, { payload }) => {
    const cashFlow = payload?.cashFlow
    if (!cashFlow) return state

    return {
      ...state,
      cashFlow,
    }
  },
  [UPDATE_CASH_FLOW]: (state, { payload }) => {
    const cashFlow = payload?.cashFlow
    if (!cashFlow) return state

    return {
      ...state,
      cashFlow,
    }
  },
  [DELETE_CASH_FLOW]: (state, { payload }) => {
    if (payload?.cashFlow) return initialState
    else return state
  },
  [UPLOAD_CASH_FLOW_INCOME]: (state, { payload }) => {
    const income = payload?.income
    if (!income) return state

    return pushIncome(state, income)
  },
  [UPDATE_CASH_FLOW_INCOME]: (state, { payload }) => {
    const income = payload?.income
    if (!income) return state

    return updateIncome(state, income)
  },
  [DELETE_CASH_FLOW_INCOME]: (state, { payload }) => {
    const income = payload?.income
    if (!income) return state

    return deleteIncome(state, income)
  },
  [UPLOAD_CASH_FLOW_EXPENSE]: (state, { payload }) => {
    const expense = payload?.expense
    if (!expense) return state

    return pushExpense(state, expense)
  },
  [UPDATE_CASH_FLOW_EXPENSE]: (state, { payload }) => {
    const expense = payload?.expense
    if (!expense) return state

    return updateExpense(state, expense)
  },
  [DELETE_CASH_FLOW_EXPENSE]: (state, { payload }) => {
    const expense = payload?.expense
    if (!expense) return state

    return deleteExpense(state, expense)
  },
  [UPDATE_CASH_FLOW_FILTER]: (state, { payload }) => {
    const newState = {
      ...state,
      filters: {
        ...state.filters,
        ...payload,
      },
    }
    const parsedIncomes = { ...parseRegisters(state.incomes.all, newState), all: state.incomes.all }
    const parsedExpenses = { ...parseRegisters(state.expenses.all, newState), all: state.expenses.all }

    return {
      ...newState,
      incomes: parsedIncomes,
      expenses: parsedExpenses,
    }
  },
  [UPDATE_CASH_FLOW_CURRENCY]: (state, { payload: currency }) => {
    return { ...state, currency }
  },
}

const cashFlow = (state = initialState, action) => {
  const { type } = action

  return handlers[type] ? handlers[type](state, action) : state
}

export default cashFlow
