const CARDS = [
  {
    name: 'Visa',
    length: '13,16',
    prefixes: '4',
    checkdigit: true,
    icon: <img width={30} height={30} src="/cards/visa.svg" alt="" />,
    brand: 'visa',
  },
  {
    name: 'AmEx',
    length: '15',
    prefixes: '34,37',
    checkdigit: true,
    icon: <img width={30} height={30} src="/cards/amex.svg" alt="" />,
    brand: 'american-express',
  },
  {
    name: 'Discover',
    length: '16',
    prefixes: '6011,622,64,65',
    checkdigit: true,
    icon: <img width={30} height={30} src="/cards/discover.svg" alt="" />,
    brand: 'discover',
  },
  {
    name: 'MasterCard',
    length: '16',
    prefixes: '51,52,53,54,55',
    checkdigit: true,
    icon: <img width={30} height={30} src="/cards/mastercard.svg" alt="" />,
    brand: 'mastercard',
  },
  {
    name: 'Maestro',
    length: '12,13,14,15,16,18,19',
    prefixes: '5018,5020,5038,6304,6759,6761,6762,6763',
    checkdigit: true,
    icon: <img width={30} height={30} src="/cards/maestro.svg" alt="" />,
    brand: 'maestro',
  },
]

export const getCreditCardByNumber = (creditCardNumber) => {
  let cardNumber = creditCardNumber
  cardNumber = cardNumber.replace(/\s/g, '')

  if (!cardNumber || cardNumber.length < 4) return { icon: <i className="fas fa-credit-card" /> }

  const card = CARDS.find(({ prefixes }) => {
    const prefixesGroup = prefixes.split(',')
    let validPrefix = false

    prefixesGroup.forEach((prefix) => {
      if (cardNumber.startsWith(prefix)) validPrefix = true
    })

    return validPrefix
  })

  if (!card) return { icon: <i className="fas fa-credit-card" /> }
  else return card
}

export const getCreditCardByBrand = (brand) => {
  const card = CARDS.find((card) => card.brand === brand)

  if (!card) return null
  else return card
}
