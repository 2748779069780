import { formatDate } from 'helpers/date'
import React, { useMemo } from 'react'
import getContent from './content'

const Item = ({ title, value, titleStyles, valueStyles, controlStyles = {} }) => (
  <article style={controlStyles}>
    <p style={titleStyles}>{title}</p>
    <p style={valueStyles}>{value}</p>
  </article>
)

const PDF = React.forwardRef(({ employee, stub, locale, formatMoney, image = false, companyLogo, companyName }, ref) => {
  const { fields } = getContent(locale)

  const { name, currency } = employee ?? {}
  const {
    date,
    workedHours,
    extraHours,
    hourlyRate,
    extraHourlyRate,
    basePayment,
    deductions,
    totalPayment,
    IS_PENDING,
    description,
    extraPayment,
    extraPaymentDescription,
    extraHoursPayment,
    reference,
  } = stub ?? {}
  const totalDeductions = employee.deductions.reduce((acc, deduction) => {
    if (deduction.IS_ACTIVE) {
      acc.push({
        title: deduction.deduction.name,
        value: deduction.deduction.percentage ? (deduction.deduction.percentage / 100) * basePayment : deduction.deduction.fixed,
      })
    }

    return acc
  }, [])

  const styles = {
    main: {
      width: '800px',
      minHeight: '1000px',
      padding: '16px',
      fontSize: image ? '14px' : '10px',
      display: 'grid',
      alignItems: 'center',
    },
    header: {
      display: 'flex',
      gap: '16px',
      paddingRight: '16px',
      paddingLeft: '16px',
      margin: 0,
      reference: {
        control: {
          position: 'absolute',
          right: '32px',
        },
        title: {
          fontWeight: 'bold',
          backgroundColor: '#ededed',
          border: '1px solid #E6E5E5',
          borderBottom: 'none',
          padding: '16px',
        },
        label: {
          border: '1px solid #E6E5E5',
          padding: '16px',
        },
      },
    },
    titleSection: {
      display: 'grid',
      justifyItems: 'center',
      companyTitle: { fontSize: '30px', fontWeight: 'bold', marginTop: '-16px', marginBottom: '20px', color: '#507938' },
      title: {
        fontSize: '30px',
        fontWeight: 'bold',
        marginTop: '0',
        marginBottom: '8px',
      },
      control: {
        fontSize: '20px',
        marginBottom: '16px',
      },
      subTitle: {
        fontWeight: 'bold',
        float: 'left',
        marginRight: '8px',
      },
      label: {
        float: 'right',
      },
    },
    generalInformation: {
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
      justifyItems: 'center',
      textAlign: 'center',
      width: '80%',
      marginLeft: 'auto',
      marginRight: 'auto',
      item: {
        control: {
          width: '100%',
        },
        title: {
          fontWeight: 'bold',
          backgroundColor: '#ededed',
          border: '1px solid #E6E5E5',
          borderBottom: 'none',
          padding: '12px',
        },
        label: {
          border: '1px solid #E6E5E5',
          padding: '12px',
        },
      },
    },
    details: {
      width: '80%',
      marginLeft: 'auto',
      marginRight: 'auto',
      title: {
        textAlign: 'center',
        fontSize: '20px',
        marginBottom: '8px',
        marginTop: '8px',
      },
      item: {
        control: {
          width: '100%',
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
        },
        title: {
          fontWeight: 'bold',
          border: '1px solid #E6E5E5',
          borderBottom: 'none',
          padding: '12px',
        },
        label: {
          border: '1px solid #E6E5E5',
          borderBottom: 'none',
          padding: '12px',
        },
      },
    },
    total: {
      justifySelf: 'flex-end',
      marginTop: '16px',
      width: '60%',
      marginRight: '10%',
      title: {
        textAlign: 'center',
        fontSize: '30px',
        marginBottom: '8px',
      },
      item: {
        control: {
          width: '100%',
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
        },
        title: {
          fontWeight: 'bold',
          backgroundColor: '#ededed',
          border: '1px solid #E6E5E5',
          borderBottom: 'none',
          padding: '12px',
        },
        label: {
          border: '1px solid #E6E5E5',
          borderBottom: 'none',
          padding: '12px',
        },
      },
    },
  }

  const statusLabel = useMemo(() => {
    const p = locale === 'es' ? 'Pendiente' : 'Pending'
    const np = locale === 'es' ? 'Aprobado' : 'Aprove'

    return IS_PENDING ? p : np
  }, [locale, IS_PENDING])

  return (
    <section ref={ref} style={styles.main}>
      <header style={styles.header}>
        <img
          src={companyLogo ? companyLogo + '?not-from-cache-please' : '/logo.png'}
          alt="Digital Pymes"
          width={companyLogo ? 300 : 240}
          crossOrigin="anonymous"
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: '12px',
          }}
        />
        {reference && (
          <Item
            controlStyles={styles.header.reference.control}
            titleStyles={styles.header.reference.title}
            valueStyles={styles.header.reference.label}
            value={reference}
            title={fields.reference}
          />
        )}
      </header>

      <section style={styles.titleSection}>
        {!companyLogo && <strong style={styles.titleSection.companyTitle}>{companyName}</strong>}

        <h1 style={styles.titleSection.title}>{locale === 'es' ? 'Colilla de pago' : 'Payment stub'}</h1>
        <Item
          controlStyles={styles.titleSection.control}
          titleStyles={styles.titleSection.subTitle}
          valueStyles={styles.titleSection.label}
          title={`${fields.employee}:`}
          value={name}
        />
        <Item
          controlStyles={styles.titleSection.control}
          titleStyles={styles.titleSection.subTitle}
          valueStyles={styles.titleSection.label}
          title={`${fields.month}:`}
          value={formatDate(date, 'MMMM YYYY', false)}
        />
      </section>

      <section style={styles.generalInformation}>
        <Item
          controlStyles={styles.generalInformation.item.control}
          titleStyles={styles.generalInformation.item.title}
          valueStyles={styles.generalInformation.item.label}
          value={statusLabel}
          title={fields.status}
        />
        <Item
          controlStyles={styles.generalInformation.item.control}
          titleStyles={styles.generalInformation.item.title}
          valueStyles={styles.generalInformation.item.label}
          value={formatDate(date, 'DD MMMM YYYY', false)}
          title={fields.date}
        />
        <Item
          controlStyles={styles.generalInformation.item.control}
          titleStyles={styles.generalInformation.item.title}
          valueStyles={styles.generalInformation.item.label}
          value={workedHours}
          title={fields.workedHours}
        />
        <Item
          controlStyles={styles.generalInformation.item.control}
          titleStyles={styles.generalInformation.item.title}
          valueStyles={styles.generalInformation.item.label}
          value={extraHours ?? 0}
          title={fields.extraHours}
        />
      </section>

      <section style={styles.details}>
        <h2 style={styles.details.title}>Detalles</h2>

        <Item
          controlStyles={{ ...styles.details.item.control, fontWeight: 'bold', backgroundColor: '#ededed' }}
          titleStyles={styles.details.item.title}
          valueStyles={styles.details.item.label}
          value={locale === 'es' ? 'Monto' : 'Amount'}
          title={locale === 'es' ? 'Descripción' : 'Description'}
        />
        <Item
          controlStyles={styles.details.item.control}
          titleStyles={styles.details.item.title}
          valueStyles={styles.details.item.label}
          value={formatMoney(hourlyRate, currency)}
          title={fields.hourlyRate}
        />
        <Item
          controlStyles={styles.details.item.control}
          titleStyles={styles.details.item.title}
          valueStyles={styles.details.item.label}
          value={formatMoney(extraHourlyRate, currency)}
          title={fields.extraHourlyRate}
        />
        <Item
          controlStyles={styles.details.item.control}
          titleStyles={styles.details.item.title}
          valueStyles={styles.details.item.label}
          value={formatMoney(basePayment, currency)}
          title={fields.hoursPayment}
        />
        <Item
          controlStyles={styles.details.item.control}
          titleStyles={styles.details.item.title}
          valueStyles={styles.details.item.label}
          value={formatMoney(extraHoursPayment, currency)}
          title={fields.extraHoursPayment}
        />
        {totalDeductions?.map((deduction) => (
          <Item
            controlStyles={styles.details.item.control}
            titleStyles={styles.details.item.title}
            valueStyles={{ ...styles.details.item.label, color: 'red' }}
            value={`-${formatMoney(deduction.value, currency)}`}
            title={deduction.title}
          />
        ))}
        <Item
          controlStyles={styles.details.item.control}
          titleStyles={styles.details.item.title}
          valueStyles={styles.details.item.label}
          value={formatMoney(deductions, currency)}
          title={fields.deductions}
        />
        <Item
          controlStyles={styles.details.item.control}
          titleStyles={styles.details.item.title}
          valueStyles={styles.details.item.label}
          value={formatMoney(extraPayment ?? 0, currency)}
          title={fields.extraPayment}
        />
        <Item
          controlStyles={styles.details.item.control}
          titleStyles={{ ...styles.details.item.title, borderBottom: '1px solid #E6E5E5' }}
          valueStyles={{ ...styles.details.item.label, borderBottom: '1px solid #E6E5E5' }}
          value={extraPaymentDescription ?? 'N/A'}
          title={fields.extraPaymentDescription}
        />
      </section>

      <section style={styles.total}>
        <Item
          controlStyles={styles.total.item.control}
          titleStyles={styles.total.item.title}
          valueStyles={styles.total.item.label}
          value={formatMoney(totalPayment, currency)}
          title={fields.totalPayment}
        />
        <Item
          controlStyles={styles.total.item.control}
          titleStyles={{ ...styles.total.item.title, borderBottom: '1px solid #E6E5E5' }}
          valueStyles={{ ...styles.total.item.label, borderBottom: '1px solid #E6E5E5' }}
          value={description ?? 'N/A'}
          title={fields.note}
        />
      </section>
    </section>
  )
})

export default PDF
