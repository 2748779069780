import { Avatar, IconButton, Tooltip } from '@material-ui/core'
import useLocale from 'hooks/useLocale'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { addDrivePermission, deleteDrivePermission } from 'slices/driveSlice'
import { postDrivePermissions } from 'slices/driveSlice/api'
import Select from '../../../../components/common/Select/Select'
import styles from './PeopleWithAccess.module.scss'

const GOOGLE_DRIVE_PERMISSIONS_ROLES = [
  {
    value: 'writer',
    text: 'Escritor',
  },
  {
    value: 'reader',
    text: 'Lector',
  },
]

export default function PeopleWithAccess({ users }) {
  const locale = useLocale()

  return (
    <>
      <h2 style={{ marginBottom: '10px' }}>Personas con acceso</h2>
      <div className={styles.wrapper}>
        {users?.map((user) => (
          <User key={user?.idUser} {...user} />
        ))}

        {users?.length === 0 && (
          <p className={styles.helper}>
            {locale === 'es' ? 'No hay usuarios con permisos' : 'There is no users with permissions'}
          </p>
        )}
      </div>
    </>
  )
}

function User({ user, type: userType, ...rest }) {
  const dispatch = useDispatch()

  const [role, setRole] = useState(userType)
  const { fileId, idUser, idCompany, id } = rest
  const { firstName, lastName, email, avatarPhoto } = user

  const handleChange = async (e) => {
    let { value } = e.target
    const body = {
      idUser,
      idCompany,
      fileId,
      type: value,
    }

    const action = addDrivePermission({ user, id, ...body })
    dispatch(action)
    setRole(value)
    await postDrivePermissions(action.payload)
  }

  const handleDeletePermission = () => {
    dispatch(deleteDrivePermission(id))
  }

  return (
    <div className={styles.userPermission}>
      <div>
        <Avatar src={avatarPhoto} />
        <div>
          <p className={styles.name}>
            {firstName} {lastName}
          </p>
          <p className={styles.email}>{email}</p>
        </div>
      </div>
      <div className={styles.rolContainer}>
        <Tooltip title="Eliminar permiso">
          <IconButton size="small" onClick={handleDeletePermission} disabled={!id}>
            <i className="fas fa-trash-alt" />
          </IconButton>
        </Tooltip>

        <Select
          fullWidth={false}
          isRequired={false}
          label="Rol"
          name="type"
          value={role}
          options={GOOGLE_DRIVE_PERMISSIONS_ROLES}
          onChange={handleChange}
        />
      </div>
    </div>
  )
}
