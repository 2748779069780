import { useState } from 'react'

const useCalendar = () => {
  const currentDate = new Date()
  const currentMonth = currentDate.getMonth() + 1
  const currentYear = currentDate.getFullYear()

  const [month, setMonth] = useState(currentMonth)
  const [year, setYear] = useState(currentYear)

  const handleNextMonth = () => {
    if (month === 12) {
      setMonth(1)
      setYear((prev) => prev + 1)

      return
    }

    setMonth((prev) => prev + 1)
  }

  const handlePreviousMonth = () => {
    if (month === 1) {
      setMonth(12)
      setYear((prev) => prev - 1)

      return
    }

    setMonth((prev) => prev - 1)
  }

  const handleClickToday = () => {
    setMonth(currentMonth)
    setYear(currentYear)
  }

  return {
    year,
    month,
    handleNextMonth,
    handlePreviousMonth,
    handleClickToday,
  }
}

export default useCalendar
