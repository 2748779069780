import {
  CLEAN_BONUS,
  DELETE_DEDUCTION,
  DELETE_DEPARTMENT,
  DELETE_EMPLOYEE,
  DELETE_EMPLOYEE_WORKED_HOURS,
  DELETE_EVENT,
  DELETE_FREE_TIME,
  DELETE_PAYMENT_STUB,
  DELETE_PAYMENT_STUB_FACTORY,
  DELETE_PAYMENT_STUB_RECURRENCY,
  DELETE_POSITION,
  DELETE_SCHEDULE,
  DELETE_WORKED_HOURS_BREAK,
  GET_DEDUCTIONS,
  GET_DEPARTMENTS,
  GET_EMPLOYEES,
  GET_EVENTS,
  GET_POSITIONS,
  GET_SCHEDULES,
  PAY_BONUS,
  UPDATE_DEDUCTION,
  UPDATE_DEPARTMENT,
  UPDATE_EMPLOYEE,
  UPDATE_EMPLOYEE_FREE_TIME,
  UPDATE_EMPLOYEE_WORKED_HOURS,
  UPDATE_EVENT,
  UPDATE_PAYMENT_STUB,
  UPDATE_PAYMENT_STUB_FACTORY,
  UPDATE_PAYMENT_STUB_RECURRENCY,
  UPDATE_POSITION,
  UPDATE_SCHEDULE,
  UPLOAD_DEDUCTION,
  UPLOAD_DEPARTMENT,
  UPLOAD_EMPLOYEE,
  UPLOAD_EVENT,
  UPLOAD_FREE_TIME,
  UPLOAD_PAYMENT_STUB,
  UPLOAD_PAYMENT_STUB_FACTORY,
  UPLOAD_PAYMENT_STUB_RECURRENCY,
  UPLOAD_POSITION,
  UPLOAD_SCHEDULE,
} from 'constants/actionTypes'
import {
  BONUSES_ROUTE,
  DEDUCTIONS_ROUTE,
  DEPARTMENTS_ROUTE,
  EMPLOYEE_FILES_ROUTE,
  EMPLOYESS_ROUTE,
  EVENTS_ROUTE,
  FREE_TIME_ROUTE,
  PAYMENT_STUBS_ROUTE,
  PAYMENT_STUB_FACTORY_ROUTE,
  PAYMENT_STUB_RECURRENCY_ROUTE,
  POSITIONS_ROUTE,
  SCHEDULES_ROUTE,
  UPDATE_DEDUCTIONS_ROUTE,
  WORK_DAY_BREAKS_ROUTE,
  WORK_DAY_ROUTE,
} from 'constants/apiRoutes'
import { apiDeleteData, apiFindData, apiPostData, apiPostFile, apiPostFormDataWithData, apiPutData } from 'helpers/api'

// Departments
const getDeparments = (idCompany) => apiFindData(DEPARTMENTS_ROUTE, { id: idCompany }, GET_DEPARTMENTS)
const createDeparment = (department) => apiPostData(DEPARTMENTS_ROUTE, department, UPLOAD_DEPARTMENT)
const updateDeparment = (department) =>
  apiPutData(DEPARTMENTS_ROUTE, { ...department, id: department?.idDepartment }, UPDATE_DEPARTMENT)
const deleteDeparment = (idDepartment) => apiDeleteData(DEPARTMENTS_ROUTE, { id: idDepartment }, DELETE_DEPARTMENT)

// Positions
const getPositions = (idCompany) => apiFindData(POSITIONS_ROUTE, { id: idCompany }, GET_POSITIONS)
const createPosition = (position) => apiPostData(POSITIONS_ROUTE, position, UPLOAD_POSITION)
const updatePosition = (position) => apiPutData(POSITIONS_ROUTE, { ...position, id: position?.idPosition }, UPDATE_POSITION)
const deletePosition = (idPosition) => apiDeleteData(POSITIONS_ROUTE, { id: idPosition }, DELETE_POSITION)

// Schedules
const getSchedules = (id) => apiFindData(SCHEDULES_ROUTE, { id }, GET_SCHEDULES)
const createSchedule = (schedule) => apiPostData(SCHEDULES_ROUTE, schedule, UPLOAD_SCHEDULE)
const updateSchedule = (schedule) => apiPutData(SCHEDULES_ROUTE, { ...schedule, id: schedule.idSchedule }, UPDATE_SCHEDULE)
const deleteSchedule = (id) => apiDeleteData(SCHEDULES_ROUTE, { id }, DELETE_SCHEDULE)

// Employees
const getEmployees = (id) => apiFindData(EMPLOYESS_ROUTE, { id }, GET_EMPLOYEES)
const createEmployee = (employee) => apiPostData(EMPLOYESS_ROUTE, employee, UPLOAD_EMPLOYEE)
const updateEmployee = (employee) => apiPutData(EMPLOYESS_ROUTE, { ...employee, id: employee.idEmployee }, UPDATE_EMPLOYEE)
const deleteEmployee = (id) => apiDeleteData(EMPLOYESS_ROUTE, { id }, DELETE_EMPLOYEE)

// Employees File
const getEmployeeFiles = (id) => apiFindData(EMPLOYEE_FILES_ROUTE, { id })
const createEmployeeFile = (form) => apiPostFile(EMPLOYEE_FILES_ROUTE, form)
const deleteEmployeeFile = (id) => apiDeleteData(EMPLOYEE_FILES_ROUTE, { id })

// Deductions
const getDeductions = (id) => apiFindData(DEDUCTIONS_ROUTE, { id }, GET_DEDUCTIONS)
const createDeduction = (deduction) => apiPostData(DEDUCTIONS_ROUTE, deduction, UPLOAD_DEDUCTION)
const updateDeduction = (deduction) => apiPutData(DEDUCTIONS_ROUTE, { ...deduction, id: deduction.idDeduction }, UPDATE_DEDUCTION)
const deleteDeduction = (id) => apiDeleteData(DEDUCTIONS_ROUTE, { id }, DELETE_DEDUCTION)
const updateEmployeesDeductions = (employee) => apiPostData(UPDATE_DEDUCTIONS_ROUTE, employee, UPDATE_EMPLOYEE)

// Payment stubs
const createStub = (stub) => apiPostData(PAYMENT_STUBS_ROUTE, stub, UPLOAD_PAYMENT_STUB)
const updateStub = (stub) => apiPutData(PAYMENT_STUBS_ROUTE, { ...stub, id: stub.idPaymentStub }, UPDATE_PAYMENT_STUB)
const deleteStub = (id) => apiDeleteData(PAYMENT_STUBS_ROUTE, { id }, DELETE_PAYMENT_STUB)

// Payment stubs - factory
const createStubFactory = (stub) => apiPostData(PAYMENT_STUB_FACTORY_ROUTE, stub, UPLOAD_PAYMENT_STUB_FACTORY)
const updateStubFactory = (stub) =>
  apiPutData(PAYMENT_STUB_FACTORY_ROUTE, { ...stub, id: stub.idPaymentStubFactory }, UPDATE_PAYMENT_STUB_FACTORY)
const deleteStubFactory = (id) => apiDeleteData(PAYMENT_STUB_FACTORY_ROUTE, { id }, DELETE_PAYMENT_STUB_FACTORY)

// Payment stubs - recurrency
const createStubRecurrency = (stub) => apiPostData(PAYMENT_STUB_RECURRENCY_ROUTE, stub, UPLOAD_PAYMENT_STUB_RECURRENCY)
const updateStubRecurrency = (stub) =>
  apiPutData(PAYMENT_STUB_RECURRENCY_ROUTE, { ...stub, id: stub.idPaymentStubRecurrency }, UPDATE_PAYMENT_STUB_RECURRENCY)
const deleteStubRecurrency = (id) => apiDeleteData(PAYMENT_STUB_RECURRENCY_ROUTE, { id }, DELETE_PAYMENT_STUB_RECURRENCY)

// Worked hours
const updateWorkDay = (workedDay) =>
  apiPutData(WORK_DAY_ROUTE, { ...workedDay, id: workedDay.idEmployeeWorkDay }, UPDATE_EMPLOYEE_WORKED_HOURS)
const deleteWorkDay = (id) => apiDeleteData(WORK_DAY_ROUTE, { id }, DELETE_EMPLOYEE_WORKED_HOURS)
const deleteWorkDayBreak = (id) => apiDeleteData(WORK_DAY_BREAKS_ROUTE, { id }, DELETE_WORKED_HOURS_BREAK)

// Free time
const createFreeTime = (freeTime) => apiPostFormDataWithData(FREE_TIME_ROUTE, freeTime, UPLOAD_FREE_TIME)
const updateFreeTime = (freeTime) =>
  apiPutData(FREE_TIME_ROUTE, { ...freeTime, id: freeTime?.idEmployeeFreeTime }, UPDATE_EMPLOYEE_FREE_TIME)
const deleteFreeTime = (id) => apiDeleteData(FREE_TIME_ROUTE, { id }, DELETE_FREE_TIME)

// Events
const getEvents = (id) => apiFindData(EVENTS_ROUTE, { id }, GET_EVENTS)
const createEvent = (event) => apiPostData(EVENTS_ROUTE, event, UPLOAD_EVENT)
const updateEvent = (event) => apiPutData(EVENTS_ROUTE, { ...event, id: event.idEvent }, UPDATE_EVENT)
const deleteEvent = (id) => apiDeleteData(EVENTS_ROUTE, { id }, DELETE_EVENT)

// Bonuses
const payBonus = (bonus) => apiPostData(BONUSES_ROUTE, bonus, PAY_BONUS)
const cleanBonus = (id) => apiDeleteData(BONUSES_ROUTE, { id }, CLEAN_BONUS)

export {
  getDeparments,
  createDeparment,
  updateDeparment,
  deleteDeparment,
  getPositions,
  createPosition,
  updatePosition,
  deletePosition,
  getSchedules,
  createSchedule,
  updateSchedule,
  deleteSchedule,
  getEmployees,
  createEmployee,
  updateEmployee,
  deleteEmployee,
  getEmployeeFiles,
  createEmployeeFile,
  deleteEmployeeFile,
  getDeductions,
  createDeduction,
  updateDeduction,
  deleteDeduction,
  updateEmployeesDeductions,
  createStub,
  updateStub,
  deleteStub,
  createFreeTime,
  updateFreeTime,
  deleteFreeTime,
  updateWorkDay,
  deleteWorkDay,
  deleteWorkDayBreak,
  getEvents,
  createEvent,
  updateEvent,
  deleteEvent,
  payBonus,
  cleanBonus,
  createStubRecurrency,
  updateStubRecurrency,
  deleteStubRecurrency,
  createStubFactory,
  updateStubFactory,
  deleteStubFactory,
}
