import { FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import { updateEmployee } from 'actions/humanResources'
import { getCompanyUsers } from 'actions/users'
import countries from 'constants/countries'
import { currencies } from 'constants/currencies'
import { addDays, subDays } from 'date-fns'
import { formatDate } from 'helpers/date'
import useHumanResources from 'hooks/useHumanResources'
import useLocale from 'hooks/useLocale'
import DepartmentSelector from 'pages/HumanResourcesPage/Tabs/Employees/RegisterModal/DepartmentSelector/DepartmentSelector'
import useEmployeeData from 'pages/HumanResourcesPage/Tabs/Employees/RegisterModal/useData'
import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useImmerReducer } from 'use-immer'
import Section from '../Section'
import styles from './styles.module.scss'

const LABELS = {
  es: {
    name: 'Nombre',
    dni: 'DNI',
    birthday: 'Fecha de nacimiento',
    country: 'País',
    email: 'Correo Electronico',
    department: 'Departamento',
    position: 'Puesto',
    contract: 'Tipo de contrato',
    schedule: 'Horarios',
    baseSalary: 'Salario base',
    currency: 'Moneda',
    startDate: 'Fecha de inicio',
    endDate: 'Fecha de fin',
    user: 'Usuario',
    bankAccount: 'Cuenta bancaria',
    vacations: '¿Tiene vacaciones?',
    bonuses: '¿Tiene aguinaldo?',
  },
  en: {
    name: 'Name',
    dni: 'Identity number',
    birthday: 'Birthday',
    country: 'Country',
    email: 'Email',
    department: 'Department',
    position: 'Position',
    contract: 'Contract type',
    schedule: 'Schedule',
    baseSalary: 'Base salary',
    currency: 'Currency',
    startDate: 'Start date',
    endDate: 'End date',
    user: 'User',
    bankAccount: 'Bank account',
    vacations: 'Has vacations?',
    bonuses: 'Has bonuses?',
  },
}

const FORM_REDUCER_ACTIONS = {
  ON_CHANGE: 'ON_CHANGE',
  ON_CHANGE_POSITION: 'ON_CHANGE_POSITION',
  ON_CHANGE_DATE: 'ON_CHANGE_DATE',
  ON_CHANGE_BOOLEAN: 'ON_CHANGE_BOOLEAN',
  ON_FULL_STATE_CHANGE: 'ON_FULL_STATE_CHANGE',
}

const formReducer = (draft, action) => {
  switch (action.type) {
    case FORM_REDUCER_ACTIONS.ON_CHANGE:
      draft[action.field] = action.value

      return
    case FORM_REDUCER_ACTIONS.ON_CHANGE_POSITION:
      draft.idPosition = action.value
      draft.baseSalary = action.position.baseSalary
      draft.currency = action.position.currency

      return
    case FORM_REDUCER_ACTIONS.ON_CHANGE_DATE:
      draft[action.field] = action.date

      return
    case FORM_REDUCER_ACTIONS.ON_CHANGE_BOOLEAN:
      draft[action.field] = +action.value

      return
    case FORM_REDUCER_ACTIONS.ON_FULL_STATE_CHANGE:
      return { ...action.state, shouldUpdate: true }
    default:
      return draft
  }
}

const EmployeeDetails = ({ employee }) => {
  const dispatch = useDispatch()
  const locale = useLocale()
  const { positions: companyPositions } = useHumanResources()
  const [form, dispatchForm] = useImmerReducer(formReducer, { ...employee, shouldUpdate: false })
  const { positions, schedules, companyUsers } = useEmployeeData(form)

  const handleChange = (e) => {
    const { name, value } = e.target
    dispatchForm({ type: FORM_REDUCER_ACTIONS.ON_CHANGE, field: name, value })
  }

  const handleChangePosition = (e) => {
    const { value } = e?.target ?? ''
    const position = companyPositions.find((position) => +position.idPosition === +value)
    dispatchForm({ type: FORM_REDUCER_ACTIONS.ON_CHANGE_POSITION, value, position })
  }

  const handleChangeDate = (date, field) => {
    dispatchForm({ type: FORM_REDUCER_ACTIONS.ON_CHANGE_DATE, date, field })
  }

  const handleChangeBoolean = (e) => {
    const { name, value } = e.target
    dispatchForm({ type: FORM_REDUCER_ACTIONS.ON_CHANGE_BOOLEAN, field: name, value })
  }

  const handleChangeDepartments = (getNewState) => {
    const state = getNewState(form)
    dispatchForm({ type: FORM_REDUCER_ACTIONS.ON_FULL_STATE_CHANGE, state })
  }

  const updateUser = useCallback(async () => {
    dispatch(await updateEmployee({ ...form }))
  }, [dispatch, form])

  useEffect(() => {
    updateUser()
  }, [form?.departments.length]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Section title={locale === 'es' ? 'Detalles personales' : 'Personal details'}>
      <section className={styles.formWrapper}>
        <section>
          <TextField
            type="text"
            variant="outlined"
            label={LABELS[locale].name}
            name="name"
            value={form?.name}
            onChange={handleChange}
            onBlur={updateUser}
            fullWidth
            required
          />

          <TextField
            type="text"
            variant="outlined"
            label={LABELS[locale].dni}
            name="dni"
            value={form?.dni}
            onChange={handleChange}
            fullWidth
            required
            onBlur={updateUser}
          />

          <DatePicker
            fullWidth
            label={LABELS[locale].birthday}
            name="birthday"
            value={form?.birthday ? formatDate(form?.birthday) : null}
            onChange={(date) => handleChangeDate(date, 'birthday')}
            inputVariant="outlined"
            format="dd/MM/yyyy"
            clearable
            required
            onBlur={updateUser}
          />

          <FormControl variant="outlined" fullWidth required>
            <InputLabel>{LABELS[locale].country}</InputLabel>
            <Select
              onBlur={updateUser}
              name="country"
              label={locale === 'es' ? 'País' : 'Country'}
              value={form?.country ?? 'Costa Rica'}
              onChange={handleChange}
            >
              {countries.map((i) => (
                <MenuItem value={i.name_es} key={i.name_es}>
                  {i[`name_${locale}`]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            onBlur={updateUser}
            type="text"
            variant="outlined"
            label={LABELS[locale].email}
            name="email"
            value={form?.email}
            onChange={handleChange}
            required
            fullWidth
          />

          <DepartmentSelector onUpdateEmployee={updateUser} data={form?.departments ?? []} setter={handleChangeDepartments} />

          <FormControl variant="outlined" fullWidth required>
            <InputLabel>{LABELS[locale].position}</InputLabel>
            <Select
              onBlur={updateUser}
              name="position"
              label={LABELS[locale].position}
              value={form?.idPosition}
              onChange={handleChangePosition}
            >
              {positions?.map((i) => (
                <MenuItem value={i.value} key={i.value}>
                  {i.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl variant="outlined" fullWidth required>
            <InputLabel>{LABELS[locale].contract}</InputLabel>
            <Select
              onBlur={updateUser}
              name="contract"
              label={LABELS[locale].contract}
              value={form?.contract}
              onChange={handleChangeBoolean}
            >
              <MenuItem value={1}>{locale === 'es' ? 'Contrato laboral' : 'Work contract'}</MenuItem>
              <MenuItem value={2}>
                {locale === 'es' ? 'Contrato por servicios profesionales' : 'Contract for professional services'}
              </MenuItem>
            </Select>
          </FormControl>

          <FormControl variant="outlined" fullWidth required>
            <InputLabel>{LABELS[locale].schedule}</InputLabel>
            <Select
              onBlur={updateUser}
              name="idSchedule"
              label={LABELS[locale].schedule}
              value={form?.idSchedule}
              onChange={handleChangeBoolean}
            >
              {schedules?.map((i) => (
                <MenuItem value={i.value} key={i.value}>
                  {i.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </section>

        <section>
          <FormControl variant="outlined" fullWidth required>
            <InputLabel>{LABELS[locale].bonuses}</InputLabel>
            <Select
              onBlur={updateUser}
              name="HAS_BONUSES"
              label={LABELS[locale].bonuses}
              value={form?.HAS_BONUSES}
              onChange={handleChangeBoolean}
            >
              <MenuItem value={0}>{locale === 'es' ? 'No' : 'No'}</MenuItem>
              <MenuItem value={1}>{locale === 'es' ? 'Si' : 'Yes'}</MenuItem>
            </Select>
          </FormControl>

          <FormControl variant="outlined" fullWidth required>
            <InputLabel>{locale === 'es' ? 'Es activo?' : 'Is active?'}</InputLabel>
            <Select
              onBlur={updateUser}
              name="IS_ACTIVE"
              label={locale === 'es' ? 'Es activo?' : 'Is active?'}
              value={form?.IS_ACTIVE}
              onChange={handleChangeBoolean}
            >
              <MenuItem value={0}>{locale === 'es' ? 'No' : 'No'}</MenuItem>
              <MenuItem value={1}>{locale === 'es' ? 'Si' : 'Yes'}</MenuItem>
            </Select>
          </FormControl>

          <FormControl variant="outlined" fullWidth required>
            <InputLabel>{LABELS[locale].vacations}</InputLabel>
            <Select
              onBlur={updateUser}
              name="HAS_VACATIONS"
              label={LABELS[locale].vacations}
              value={form?.HAS_VACATIONS}
              onChange={handleChangeBoolean}
            >
              <MenuItem value={0}>{locale === 'es' ? 'No' : 'No'}</MenuItem>
              <MenuItem value={1}>{locale === 'es' ? 'Si' : 'Yes'}</MenuItem>
            </Select>
          </FormControl>

          <TextField
            onBlur={updateUser}
            type="number"
            variant="outlined"
            label={LABELS[locale].baseSalary}
            value={form?.baseSalary}
            name="baseSalary"
            onChange={handleChangeBoolean}
            fullWidth
            required
            inputProps={{
              step: '0.01',
              min: 0,
            }}
          />

          <FormControl variant="outlined" fullWidth required>
            <InputLabel>{LABELS[locale].currency}</InputLabel>
            <Select
              onBlur={updateUser}
              name="currency"
              label={LABELS[locale].currency}
              value={form?.currency}
              onChange={handleChange}
            >
              {currencies().map((i) => (
                <MenuItem value={i.value} key={i.value}>
                  {i.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            onBlur={updateUser}
            type="text"
            variant="outlined"
            label={LABELS[locale].bankAccount}
            name="bankAccount"
            value={form?.bankAccount}
            onChange={handleChange}
            fullWidth
          />

          <DatePicker
            fullWidth
            onBlur={updateUser}
            name="startTime"
            label={LABELS[locale].startDate}
            value={form?.startTime ? formatDate(form?.startTime) : null}
            onChange={(date) => handleChangeDate(date, 'startTime')}
            inputVariant="outlined"
            format="dd/MM/yyyy"
            clearable
            required
            maxDate={form?.endTime ? subDays(new Date(form?.endTime), 1) : undefined}
          />

          <DatePicker
            fullWidth
            onBlur={updateUser}
            name="endTime"
            label={LABELS[locale].endDate}
            minDate={form?.startTime ? addDays(new Date(form?.startTime), 1) : undefined}
            value={form?.endTime ? formatDate(form?.endTime) : null}
            onChange={(date) => handleChangeDate(date, 'endTime')}
            inputVariant="outlined"
            format="dd/MM/yyyy"
            clearable
          />

          <FormControl variant="outlined" fullWidth>
            <InputLabel shrink variant="outlined">
              {LABELS[locale].user}
            </InputLabel>
            <Select
              onBlur={async () => {
                await updateUser()
                dispatch(await getCompanyUsers(form?.idCompany))
              }}
              name="idUser"
              displayEmpty
              notched
              label={LABELS[locale].user}
              value={form?.idUser || ''}
              onChange={handleChangeBoolean}
            >
              <MenuItem value="">{locale === 'es' ? 'Sin usuario' : 'No user'}</MenuItem>
              {companyUsers?.map((i) => (
                <MenuItem value={i.value} key={i.value}>
                  {i.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </section>
      </section>
    </Section>
  )
}

export default EmployeeDetails
