class Faq {
  constructor(question, answer) {
    this.question = question
    this.answer = answer
  }
}

class Tutorial {
  constructor(title, description, videos, icon) {
    this.title = title
    this.description = description
    this.videos = videos
    this.icon = icon
  }
}

class Video {
  constructor(title, description, url) {
    this.title = title
    this.description = description
    this.url = url
  }
}

const HELP_CONTENT = {
  tutorials: [
    new Tutorial(
      'Inicio',
      'Crea una cuenta o haz login',
      [
        // Ejemplo de como se agrega un video
        new Video(
          'Cómo crear una cuenta',
          'En este tutorial aprenderás a crear una cuenta en DigitalPymes.',
          'https://www.loom.com/embed/b44bff120b4b42c7b71c0f1a19c012a2'
        ),
      ],
      'fas fa-home'
    ),
    new Tutorial('Calendario', '', [], 'fas fa-calendar-alt'),
    new Tutorial('Publicaciones', '', [], 'fas fa-clone'),
    new Tutorial('Correos Masivos', '', [], 'fas fa-envelope-open-text'),
    new Tutorial('Formularios', '', [], 'fas fa-poll-h'),
    new Tutorial('Campañas de Marketing', '', [], 'fas fa-bullhorn'),
    new Tutorial('Editor de imágenes', '', [], 'fas fa-image'),
    new Tutorial('Banco de imágenes', '', [], 'fas fa-image'),
    new Tutorial('Clientes', '', [], 'fas fa-user'),
    new Tutorial('Ingresos y Gastos', '', [], 'fas fa-chart-line'),
    new Tutorial('Costos', '', [], 'fas fa-money-bill-wave'),
    new Tutorial('Gastos personales', '', [], 'fas fa-wallet'),
    new Tutorial('Flujo de caja', '', [], 'fas fa-cash-register'),
    new Tutorial('Cotizaciones', '', [], 'fas fa-file-invoice-dollar'),
    new Tutorial('Procesos', '', [], 'fas fa-tasks'),
    new Tutorial('Herramientas', '', [], 'fas fa-tools'),
    new Tutorial('Recursos Humanos', '', [], 'fas fa-users'),
    new Tutorial('Espacios de trabajo', '', [], 'fas fa-diagram-project'),
    new Tutorial('Inventario', '', [], 'fas fa-boxes'),
    new Tutorial('Análisis de Datos', '', [], 'fas fa-chart-bar'),
    new Tutorial('Historial', '', [], 'fas fa-history'),
    new Tutorial('Perfil', '', [], 'fas fa-user-circle'),
    new Tutorial('Mi Empresa', '', [], 'fas fa-building'),
    new Tutorial('Ajustes', '', [], 'fas fa-cog'),
  ],
  faqs: [
    new Faq(
      '¿Un usuario puede pertenecer a más de una empresa?',
      'Cuando un usuario se registra desde la pagina principal de inicio de sesión, lo esta haciendo siendo dueño de la empresa que esta configurando, pero si es un usuario agregado directamente desde “Mi empresa → Usuarios”, este sera participe de la misma empresa que lo agrego, sin embargo en ambos casos no se puede tener varias empresas relacionadas para un mismo usuario, ya que esta relación es la que define en que empresa se guardara la información que gestione. '
    ),
    new Faq(
      '¿Una empresa puede contener más de un usuario?',
      'Si, una empresa puede registrar varios usuarios internos a su empresa y esto lo puede realizar desde el icono/foto del usuario en la parte superior derecha, luego Mi empresa y por ultimo en el tab llamado “Usuarios”.'
    ),
    new Faq(
      '¿La información es compartida en toda la empresa del usuario?',
      'Depende del módulo, DigitalPymes cuenta con módulos de empresa y módulos personales, por lo que solamente los de empresa si son compartidos de forma general de la empresa relacionada con el usuario, pero los módulos que son individuales actualmente pueden ser: Editor de imagen, Banco de imágenes, Gastos personales, Historial, etc.'
    ),
    new Faq(
      '¿Cómo puedo eliminar un usuario?',
      'Actualmente no es posible eliminar un usuario dentro del sistema de DigitalPymes, sin embargo ofrecemos el servicio al cliente para poder solicitar esta eliminación manual completa de información del usuario, por medio de WhatsApp al numero: +506 8334 5067, es importante mencionar que la única información necesaria a suministrar para realizar esta acción es el correo utilizado y verificación de la autoría del mismo.'
    ),
    new Faq(
      '¿Cómo puedo recuperar mi contraseña?',
      'Actualmente en la pagina de inicio de sesión contamos con un botón llamado “Olvide mi contraseña” el cual al ingresar el correo del usuario deberá enviar un email a la cuenta con el link correspondiente para que el usuario pueda cambiar su contraseña, es importante mencionar que este mismo podría aparecer en la carpeta de “Spam”, en caso que se tenga algún error con este proceso automatizado, puede solicitarse también mediante el servicio al cliente en alguno de nuestros canales habilitados.'
    ),
    new Faq(
      '¿Cualquier dispositivo es compatible con el sistema?',
      'No, el sistema fue construido con tecnologías actuales con el fin de brindar una mejor optimización y escalabilidad, por lo que no cualquier dispositivo puede ser compatible, es importante contemplar que necesitas de una buena conexión de internet que sea estable y así el sistema no presente irregularidades, ademas mantener actualizado tu dispositivo a las versiones mas recientes y el dispositivo no puede ser muy antiguo ya que esto podría causar algunos funcionamientos inestables del sistema, se recomienda utilizar no mas de 10 años de antigüedad.'
    ),
    new Faq(
      '¿DigitalPymes es compatible con varios idiomas?',
      'Si, actualmente nuestro sistema permite la traducción de dos idiomas (Español e Ingles), esta puede ser configurada antes de iniciar sesión en la misma pagina o bien puede ser cambiada en la parte superior donde se encuentra una bandera referente al idioma o bien el abreviado de la misma.'
    ),
    new Faq(
      '¿Dónde puedo reportar un error en DigitalPymes?',
      'Actualmente contamos con un botón en la parte inferior (ultima sección de color negro) donde podrás realizar reporte de errores, este mismo se llamada “Reportar Errores” y se puede visualizar en cualquier parte donde estes en el sistema a excepción de módulos de pantalla completa, en el cual recomendamos siempre adjuntar imágenes que demuestren el error y describir bien lo que fue realizado para lograr obtenerlo. En caso que quieras realizar el reporte directamente, lo puedes hacer mediante el servicio al cliente al numero: +506 8334 5067.'
    ),
    new Faq(
      '¿Con quién me puedo contactar si tengo alguna duda de DigitalPymes?',
      'Si cuentas con alguna duda puedes contactarte con nuestro servicio al cliente directamente al WhatsApp al numero +506 8334 5067 o bien mandarnos un correo electrónico a soporte@digitalpymes.net.'
    ),
    new Faq(
      '¿Puedo eliminar un usuario de mi empresa?',
      'Actualmente no contamos con la funcionalidad de eliminar usuarios de “mi empresa” directamente desde DigitalPymes, sin embargo ofrecemos el servicio al cliente para poder solicitar esta eliminación manual completa de información del usuario, por medio de WhatsApp al numero: +506 8334 5067.'
    ),
  ],
}

export default HELP_CONTENT
