export const getCalendarActionType = (_calendarAction) => {
  const { idPost, idMassEmail, idMarketingCampaignTask, idCalendarEvent } = _calendarAction ?? {}

  if (idPost) return 1
  else if (idMassEmail) return 2
  else if (idMarketingCampaignTask) return 3
  else if (idCalendarEvent) return 4
  else return null
}

export const calendarActionTypeName = (type, locale) => {
  const postContent = locale === 'es' ? 'Publicación' : 'Post'
  const emailContent = locale === 'es' ? 'Correo masivo' : 'Mass email'
  const marketingTaskContent = locale === 'es' ? 'Tarea de marketing' : 'Marketing task'
  const eventContent = locale === 'es' ? 'Evento' : 'Event'

  switch (type) {
    case 1:
      return postContent
    case 2:
      return emailContent
    case 3:
      return marketingTaskContent
    case 4:
      return eventContent
    default:
      return ''
  }
}
