import { openPostModal } from 'actions/modals'
import clsx from 'clsx'
import DataGridTable from 'components/DataGridTable/DataGridTable'
import { formatDate } from 'helpers/date'
import useLocale from 'hooks/useLocale'
import useSocialMedia from 'hooks/useSocialMedia'
import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import styles from './PostTable.module.scss'

export const Facebook = ({ socialNetworks }) => (
  <span className={styles.labelContainer}>
    <i className={clsx(['fab fa-facebook', styles.facebookIcon])} /> {socialNetworks[0]}
  </span>
)

export const Instagram = ({ socialNetworks }) => (
  <span className={styles.labelContainer}>
    <i className={clsx(['fab fa-instagram', styles.instagramIcon])} /> {socialNetworks[0]}
  </span>
)

export const FacebookAndInstagram = ({ socialNetworks }) => (
  <>
    <span className={styles.labelContainer}>
      <i className={clsx(['fab fa-facebook', styles.facebookIcon])} /> {`${socialNetworks[0]}`}
    </span>
    <span className={styles.labelContainer}>/</span>
    <span className={styles.labelContainer}>
      <i className={clsx(['fab fa-instagram', styles.instagramIcon])} /> {socialNetworks[1]}
    </span>
  </>
)

const PostTable = ({ searchValue }) => {
  const locale = useLocale()
  const dispatch = useDispatch()
  const { posts, isLoadingPosts } = useSocialMedia()

  const COLUMNS = useMemo(() => {
    return [
      {
        headerName: locale === 'es' ? 'Redes sociales' : 'Socialnetwork',
        field: 'socialNetworks',
        width: 200,
        sortable: false,
        renderCell: ({ value }) => {
          const socialNetworks = value.split(',')
          return socialNetworks.length === 1 && socialNetworks[0] === 'Facebook' ? (
            <Facebook socialNetworks={socialNetworks} />
          ) : socialNetworks.length === 1 && socialNetworks[0] === 'Instagram' ? (
            <Instagram socialNetworks={socialNetworks} />
          ) : (
            <FacebookAndInstagram socialNetworks={socialNetworks} />
          )
        },
      },
      {
        headerName: locale === 'es' ? 'Texto' : 'Content',
        field: 'text',
        width: 500,
        sortable: false,
        isFilterField: true,
      },
      {
        headerName: locale === 'es' ? 'Fecha' : 'Date',
        field: 'created_at',
        width: 250,
        sortable: false,
        renderCell: ({ value }) => formatDate(value, 'YYYY-MM-DD h:mm A'),
      },
    ]
  }, [locale])

  const getActionsConfig = useCallback(
    (dispatch) => ({
      width: 100,
      field: 'idPost',
      hideBasicActions: true,
      actions: [
        {
          name: locale === 'es' ? 'Ver Publicación' : 'See post',
          icon: 'fas fa-eye',
          onClick: (selectedObject) => dispatch(openPostModal(selectedObject)),
        },
      ],
    }),
    [locale]
  )

  return (
    <DataGridTable
      columns={COLUMNS}
      data={posts.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))}
      actionsConfig={getActionsConfig(dispatch)}
      isLoading={isLoadingPosts}
      filterSearchValue={searchValue}
    />
  )
}

export default PostTable
