import { LinearProgress } from '@material-ui/core'
import BackButton from 'components/BackButton'
import Page from 'layouts/Page/Page'
import React from 'react'
import Breadcrumbs from './Breadcrumbs'
import MarketingCampaignCommets from './MarketingCampaignCommets'
import MarketingCampaignInformation from './MarketingCampaignInformation'
import MarketingCampaignViews from './MarketingCampaignViews/MarketingCampaignViews'
import useMarketingCampaignPage from './useMarketingCampaignPage'

const MarketingCampaignPage = () => {
  const { isPending, currentCampaign, calendarActions } = useMarketingCampaignPage()

  return (
    <Page write={33} read={34} title={currentCampaign?.name ?? '...'}>
      {isPending ? (
        <LinearProgress />
      ) : (
        <>
          <BackButton />
          <Breadcrumbs campaignName={currentCampaign.name} />
          <MarketingCampaignInformation campaign={currentCampaign} />
          <MarketingCampaignViews idMarketingCampaign={currentCampaign.idMarketingCampaign} calendarActions={calendarActions} />
          <MarketingCampaignCommets comments={currentCampaign.comments} />
        </>
      )}
    </Page>
  )
}

export default MarketingCampaignPage
