import RichTextEditor from '@mantine/rte'
import { uploadImage } from 'actions/messages'
import classNames from 'classnames'
import React, { useEffect, useMemo } from 'react'
import styles from './TextEditor.module.scss'

const TextEditor = React.forwardRef(({ value, onChange, autoFocus = true, editorRootClass = '', ...props }, editorRef) => {
  const controls = useMemo(
    () => [
      ['bold', 'italic', 'underline', 'clean'],
      ['link', 'h1', 'h2', 'h3', 'h4'],
      ['alignLeft', 'alignCenter', 'alignRight'],
      ['unorderedList', 'orderedList', 'blockquote', 'codeBlock', 'image'],
    ],
    []
  )
  const classes = useMemo(() => {
    return {
      root: classNames(styles.textEditorRoot, editorRootClass),
      toolbar: styles.toolbar,
      toolbarControl: styles.toolbarControl,
    }
  }, [editorRootClass])

  useEffect(() => {
    if (autoFocus && editorRef?.current) {
      editorRef?.current?.focus()
    }
  })

  const handleUploadImage = (image) => {
    return new Promise(async (resolve, reject) => {
      const urlImage = await uploadImage(image)

      if (!urlImage) {
        return reject(new Error('Upload failed'))
      }

      resolve(urlImage)
    })
  }

  return (
    <RichTextEditor
      ref={editorRef}
      value={value}
      onChange={onChange}
      controls={controls}
      classNames={classes}
      onImageUpload={handleUploadImage}
      data-editor={true}
      {...props}
    />
  )
})

export default TextEditor
