import LoadingSection from 'components/LoadingSection'
import useLocale from 'hooks/useLocale'
import React, { useRef } from 'react'
import { toast } from 'react-toastify'
import File from './FileTypes/File'
import styles from './GeneralStyles.module.scss'
import { useCreateFile } from './useDriveApi'
import { getImportType } from './utils/getImportType'

const DriveFiles = ({ files, isLoading, onContextMenu, isTrashed }) => {
  const locale = useLocale()
  const wrapperRef = useRef(null)
  const { mutate: createFile } = useCreateFile()
  const folderId = ''

  if (isLoading) return null
  if (!isLoading && !files?.length) return null

  const onDrop = (e) => {
    let { files } = e.dataTransfer

    for (let i = 0; i < files.length; i++) {
      let { type, name } = files[i]

      if (type === 'text/plain') {
        toast.error(locale === 'es' ? 'No se permite subir archivos de texto' : 'Text files are not allowed', {
          autoClose: 2000,
        })

        return
      }

      const metadata = {
        mimeType: getImportType(name) ?? type,
        name: name,
        parents: [folderId ? folderId : 'root'],
      }

      const metadataBlob = new Blob([JSON.stringify(metadata)], { type: 'application/json' })
      const body = new FormData()

      body.append('file', files[i])
      body.append('metadata', metadataBlob)

      const headers = {
        'Content-Type': type,
      }

      createFile({ body, headers })
    }
  }

  return (
    <section ref={wrapperRef} onDrop={onDrop}>
      {!isTrashed && (
        <div className={styles.header}>
          <h2 className={styles.title}>
            <i className="fas fa-file" />
            {locale === 'es' ? 'Archivos' : 'Files'}
          </h2>
        </div>
      )}

      {isLoading ? (
        <LoadingSection />
      ) : (
        <section className={styles.wrapper}>
          {files?.map(({ iconLink, name, thumbnailLink, id, webViewLink, parents, permissions }) => (
            <File
              key={id}
              icon={iconLink}
              name={name}
              thumbnail={thumbnailLink}
              id={id}
              parents={parents}
              previewLink={webViewLink}
              onContextMenu={onContextMenu}
              permissions={permissions}
            />
          ))}
        </section>
      )}
    </section>
  )
}

export default DriveFiles
