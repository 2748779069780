import React, { useCallback, useState } from 'react'
import styles from '../FreeTimeModal.module.scss'
import useLocale from 'hooks/useLocale'
import { useDispatch } from 'react-redux'
import { updateFreeTime } from 'actions/humanResources'
import { CircularProgress, Tooltip } from '@material-ui/core'
import StatusChip from '../../StubsModal/StatusChip/StatusChip'

const RequestHandler = ({ value, obj }) => {
  const locale = useLocale()
  const dispatch = useDispatch()
  const [loading, isLoading] = useState(false)

  const handleOnClick = useCallback(
    async (type) => {
      isLoading(true)

      dispatch(
        await updateFreeTime({
          ...obj,
          status: !!type ? 2 : 3,
        })
      )

      isLoading(false)
    },
    [dispatch, obj]
  )

  if (loading) {
    return (
      <section className={styles.requestLoading}>
        <CircularProgress />
      </section>
    )
  }

  return value === 1 ? (
    <div className={styles.requestHandlerPending}>
      <Tooltip title={locale === 'es' ? 'Aprobar' : 'Approve'} onClick={() => handleOnClick(1)}>
        <i className={`fas fa-check ${styles.approve}`} />
      </Tooltip>
      <Tooltip title={locale === 'es' ? 'Rechazar' : 'Reject'} onClick={() => handleOnClick(0)}>
        <i className={`fas fa-times ${styles.reject}`} />
      </Tooltip>
    </div>
  ) : (
    <StatusChip value={value} />
  )
}

export default RequestHandler
