export const validateIndicator = (indicator) => {
  const checkProp = (prop) => Boolean(prop)

  const validateValues = (value) => {
    const { header, key } = value
    const hasHeader = checkProp(header?.trim())
    const hasKey = checkProp(key)

    return hasHeader && hasKey
  }

  if (!indicator || typeof indicator !== 'object') {
    return false
  }

  const { title, type, table, values } = indicator
  const hasTitle = checkProp(title?.trim())
  const hasType = checkProp(type)
  const hasTable = checkProp(table)
  const hasValues = checkProp(values?.length)

  const correctValues = values?.filter(validateValues)
  const hasCorrectValues = correctValues.length === values?.length && correctValues.length

  return hasTitle && hasType && hasTable && hasValues && hasCorrectValues
}
