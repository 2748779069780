import { Button } from '@material-ui/core'
import useLocale from 'hooks/useLocale'
import React, { useState } from 'react'
import CreationMenu from './CreationMenu'
import styles from './NewButton.module.scss'
import { useDataUsage } from './useDataUsage'
const NewButton = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const { canUploadFile } = useDataUsage()
  const locale = useLocale()

  return (
    <>
      <Button
        startIcon={<i className="fas fa-plus" />}
        variant="contained"
        color="primary"
        classes={{ root: styles.button }}
        onClick={(e) => {
          setAnchorEl(e.target)
        }}
        disabled={!canUploadFile}
      >
        {locale === 'es' ? 'Nuevo' : 'New'}
      </Button>

      <CreationMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
    </>
  )
}

export default NewButton
