import { useCallback, useMemo } from 'react'

const useStub = (employee) => {
  const { schedule, baseSalary, totalDeductions } = employee ?? {}
  const { work_days: workDays, hours, HAS_UNIQUE_HOURS, startTime, endTime, range } = schedule ?? {}

  const workingDays = useMemo(() => workDays?.filter((day) => !!day.IS_WORKING), [workDays])

  const getHoursDifferences = (end, start) => {
    const endTime = Number(end.split(':')[0])
    const startTime = Number(start.split(':')[0])

    return endTime - startTime
  }

  const monthlyHours = useMemo(() => {
    if (hours) {
      return hours * workingDays?.length * 4
    } else if (HAS_UNIQUE_HOURS && range === 1) {
      return getHoursDifferences(endTime, startTime) * 4
    } else if (!HAS_UNIQUE_HOURS && range === 1) {
      const hours = workingDays.reduce((acc, day) => acc + getHoursDifferences(day.endTime, day.startTime), 0)
      return hours * workingDays?.length * 4
    } else if (range === 2) {
      return 8 * workingDays?.length * 4
    }

    return undefined
  }, [workingDays, hours, HAS_UNIQUE_HOURS, endTime, startTime, range])

  const defaultWorkedHors = useMemo(
    () => ({
      1: monthlyHours,
      2: monthlyHours / 2,
      3: monthlyHours / 4,
      4: 1,
    }),
    [monthlyHours]
  )

  const defaultHourlyRate = useMemo(() => +(baseSalary / monthlyHours), [baseSalary, monthlyHours])
  const defaultExtraHourlyRate = useMemo(() => defaultHourlyRate * 2, [defaultHourlyRate])

  const deductions = useMemo(() => {
    const deductionsByHour = totalDeductions / monthlyHours

    return (workedHours) => ({
      1: totalDeductions,
      2: totalDeductions / 2,
      3: totalDeductions / 4,
      4: deductionsByHour * workedHours,
      5: deductionsByHour * workedHours,
    })
  }, [totalDeductions, monthlyHours])

  const defaultBasePayment = useCallback(
    (type = null) => defaultWorkedHors[type ?? '1'] * defaultHourlyRate,
    [defaultWorkedHors, defaultHourlyRate]
  )

  return { defaultWorkedHors, defaultHourlyRate, defaultExtraHourlyRate, defaultBasePayment, deductions }
}

export default useStub
