import { getMyUser } from 'actions/users'
import HeaderAiTextGenerator from 'components/HeaderAiTextGenerator/HeaderAiTextGenerator'
import { UID_COOKIE } from 'constants/cookies'
import useUsers from 'hooks/useUsers'
import Cookies from 'js-cookie'
import { Suspense, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getRoles } from 'actions/roles'
import { Fallback } from 'index'
import { Outlet } from 'react-router'

const AiTextGeneratorLayout = () => {
  const _getUserData = async (dispatch, uidCookie, setLoading, setAuthError) => {
    setLoading(true)
    const response = await getMyUser(uidCookie)

    if (response?.payload?.error) {
      Cookies.remove(UID_COOKIE)
      setAuthError(true)
    }

    const { idCompany } = response.payload.company ?? {}
    dispatch(await getRoles(idCompany))

    dispatch(response)
    setLoading(false)
  }

  const dispatch = useDispatch()
  const { isLoadingMyUser } = useUsers()

  const uidCookie = Cookies.get(UID_COOKIE)

  const [, setLoading] = useState(false)
  const [, setAuthError] = useState(false)

  useEffect(() => {
    if (!uidCookie) return
    if (isLoadingMyUser) _getUserData(dispatch, uidCookie, setLoading, setAuthError)
  }, [uidCookie, dispatch, isLoadingMyUser])

  return (
    <>
      <HeaderAiTextGenerator />
      <Suspense fallback={<Fallback />}>
        <Outlet />
      </Suspense>
    </>
  )
}

export default AiTextGeneratorLayout
