const paymentTypes = [
  {
    text: {
      es: 'Mensual',
      en: 'Monthly',
    },
    value: 1,
  },
  {
    text: {
      es: 'Quincenal',
      en: 'Biweekly',
    },
    value: 2,
  },
  {
    text: {
      es: 'Semanal',
      en: 'Weekly',
    },
    value: 3,
  },
  {
    text: {
      es: 'Tiempo registrado',
      en: 'Registered time',
    },
    value: 5,
  },
  {
    text: {
      es: 'Personalizado',
      en: 'Custom',
    },
    value: 4,
  },
]

export default paymentTypes
