import { useMemo } from 'react'
import useUsers from 'hooks/useUsers'
import {
  getPersonalFinanceCards,
  getPersonalFinanceCredits,
  getPersonalFinanceSavings,
  getPersonalFinanceTransactions,
} from 'actions/personalFinances'
import useFetchResource from 'hooks/useFetchResource'

const usePersonalFinancesPage = () => {
  const { myUser } = useUsers()
  const id = myUser?.idUser ?? null

  const promises = useMemo(
    () =>
      id
        ? [
            getPersonalFinanceTransactions(id),
            getPersonalFinanceSavings(id),
            getPersonalFinanceCredits(id),
            getPersonalFinanceCards(id),
          ]
        : [],
    [id]
  )

  useFetchResource(promises)
}

export default usePersonalFinancesPage
