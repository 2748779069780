import React, { useEffect, useMemo, useState } from 'react'
import Page from 'layouts/Page/Page'
import useLocale from 'hooks/useLocale'
import styles from './styles.module.scss'
import { getAiBlocksFunction } from 'pages/AiBlocksPage/useAiBlocksPageLoadData'
import { CircularProgress, Grid, InputAdornment, SvgIcon, TextField } from '@material-ui/core'
import { Search as SearchIcon } from 'react-feather'
import { AI_TEXT_GENERATOR_PAGE_URL } from 'constants/urls'
import { Center } from '@mantine/core'

const BlockList = ({ blocks }) => {
  return (
    <div className="mt-4">
      <Grid container spacing={3}>
        {blocks?.map(({ title, description, idAiBlock, icon }) => (
          <Grid item xs={12} md={4}>
            <div className={styles.blockCard}>
              <a href={`${AI_TEXT_GENERATOR_PAGE_URL}/${idAiBlock}`} rel="noreferrer" key={`toolTip-${idAiBlock}`}>
                <div className={styles.toolItem}>
                  <div className={styles.itemTitle}>
                    <div className={styles.iconContainer}>
                      <span className="material-icons md-36">{icon}</span>
                    </div>
                    <h4>{title}</h4>
                  </div>
                  <span>{description}</span>
                </div>
              </a>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

const AiTextGeneratorPage = () => {
  const locale = useLocale()
  const [aiBlocks, setAiBlocks] = useState([])
  const [isLoadingAiBlocks, setIsLoadingAiBlocks] = useState(true)

  const [searchValue, setSearchValue] = useState('')

  const filteredBlocks = useMemo(() => {
    return aiBlocks.filter(({ title }) => title.toLocaleLowerCase().includes(searchValue))
  }, [aiBlocks, searchValue])

  const getAiBlocksLocal = async () => {
    setIsLoadingAiBlocks(true)
    const response = await getAiBlocksFunction()
    const data = response?.payload?.aiBlocks ?? []
    setAiBlocks(data)
    setIsLoadingAiBlocks(false)
  }

  useEffect(() => {
    getAiBlocksLocal()
  }, [])

  return (
    <Page title={locale === 'es' ? 'Generador de texto AI' : 'AI Text Generator'} write={9} read={10} className={styles.page}>
      <header className={styles.header}>
        <h1 className={styles.aiBlockTitle}>Genera Texto con IA</h1>
      </header>
      <p className={styles.aiBlockSubTitle}>
        En esta pagina encontraras una serie de generadores de texto con inteligencia artificial para lo que necesites realizar
      </p>

      <div className={styles.filterPanel}>
        <TextField
          onChange={(event) => setSearchValue(event.target.value.toLocaleLowerCase())}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon fontSize="small" color="action">
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            ),
          }}
          placeholder={locale === 'es' ? 'Buscar bloque' : 'Search blocks'}
          variant="outlined"
          className={styles.searchInput}
        />
      </div>
      {isLoadingAiBlocks ? (
        <div style={{ marginTop: '100px' }}>
          <Center>
            <div className={styles.loaderContainer}>
              <CircularProgress className={styles.loader} color="primary" />
            </div>
          </Center>
        </div>
      ) : (
        <>
          {filteredBlocks.length === 0 ? (
            <div className="mt-10">
              <Center>
                <span className="material-icons" style={{ fontSize: '90px', textAlign: 'center', color: 'grey' }}>
                  search_off
                </span>
              </Center>

              <Center>
                <p style={{ fontSize: '1.5rem', color: 'grey' }}>
                  {locale === 'es' ? 'No se encontraron resultados' : 'No results found'}
                </p>
              </Center>
            </div>
          ) : (
            <BlockList blocks={filteredBlocks} />
          )}
        </>
      )}
    </Page>
  )
}

export default AiTextGeneratorPage
