const PERMISSIONS = [
  {
    name: 'Calendar',
    locale: {
      es: 'Modulo de calendario',
      en: 'Calendar module',
    },
    permissions: {
      write: 1,
      read: 2,
    },
  },
  {
    name: 'Posts',
    locale: {
      es: 'Modulo de publicaciones',
      en: 'Posts module',
    },
    permissions: {
      write: 3,
      read: 4,
    },
  },
  {
    name: 'Massive Emails',
    locale: {
      es: 'Modulo de correos masivos',
      en: 'Massive emails module',
    },
    permissions: {
      write: 5,
      read: 6,
    },
  },
  {
    name: 'Forms',
    locale: {
      es: 'Modulo de formularios',
      en: 'Forms module',
    },
    permissions: {
      write: 7,
      read: 8,
    },
  },
  {
    name: 'Clients',
    locale: {
      es: 'Modulo de clientes',
      en: 'Clients module',
    },
    permissions: {
      write: 9,
      read: 10,
    },
  },
  {
    name: 'Projects',
    locale: {
      es: 'Modulo de proyectos',
      en: 'Projects module',
    },
    permissions: {
      write: 11,
      read: 12,
    },
  },
  {
    name: 'Costs',
    locale: {
      es: 'Modulo de costos',
      en: 'Costs module',
    },
    permissions: {
      write: 13,
      read: 14,
    },
  },
  {
    name: 'Processes',
    locale: {
      es: 'Modulo de procesos',
      en: 'Processes module',
    },
    permissions: {
      write: 15,
      read: 16,
    },
  },
  {
    name: 'Tools',
    locale: {
      es: 'Modulo de herramientas',
      en: 'Tools module',
    },
    permissions: {
      write: 17,
      read: 18,
    },
  },
  {
    name: 'History',
    locale: {
      es: 'Modulo de historial',
      en: 'History module',
    },
    permissions: {
      write: 19,
      read: 20,
    },
  },
  {
    name: 'My company',
    locale: {
      es: 'Mi empresa',
      en: 'My company',
    },
    permissions: {
      write: 21,
      read: 22,
    },
  },
  {
    name: 'Configs',
    locale: {
      es: 'Configuraciones',
      en: 'Configs',
    },
    permissions: {
      write: 23,
      read: 24,
    },
  },
  {
    name: 'Human Resources',
    locale: {
      es: 'Recursos Humanos',
      en: 'Human Resources',
    },
    permissions: {
      write: 25,
      read: 26,
    },
  },
  {
    name: 'Workspaces',
    locale: {
      es: 'Espacios de trabajo',
      en: 'Workspaces',
    },
    permissions: {
      write: 27,
      read: 28,
    },
  },
  {
    name: 'Error reports',
    locale: {
      es: 'Reporte de errores',
      en: 'Error reports',
    },
    permissions: {
      write: 29,
      read: 30,
    },
  },
  {
    name: 'Inventory',
    locale: {
      es: 'Inventario',
      en: 'Inventory',
    },
    permissions: {
      write: 31,
      read: 32,
    },
  },
  {
    name: 'Marketing Campaigns',
    locale: {
      es: 'Campañas de Marketing',
      en: 'Marketing Campaigns',
    },
    permissions: {
      write: 33,
      read: 34,
    },
  },
  {
    name: 'Banco de imágenes',
    locale: {
      es: 'Banco de imágenes',
      en: 'Images bank',
    },
    permissions: {
      write: 35,
      read: 36,
    },
  },
  {
    name: 'Gastos personales',
    locale: {
      es: 'Gastos personales',
      en: 'Personal finances',
    },
    permissions: {
      write: 37,
      read: 38,
    },
  },
  {
    name: 'Editor de imagenes',
    locale: {
      es: 'Editor de imagenes',
      en: 'Images editor',
    },
    permissions: {
      write: 39,
      read: 40,
    },
  },

  {
    name: 'Flujo de caja',
    locale: {
      es: 'Flujo de caja',
      en: 'Cash flow',
    },
    permissions: {
      write: 41,
      read: 42,
    },
  },
  {
    name: 'Cotizaciones',
    locale: {
      es: 'Cotizaciones',
      en: 'Quotations',
    },
    permissions: {
      write: 43,
      read: 44,
    },
  },
  {
    name: 'DataAnalysis',
    locale: {
      es: 'Analisis de data',
      en: 'Data Analysis',
    },
    permissions: {
      write: 45,
      read: 46,
    },
  },
  {
    name: 'GoogleDrive',
    locale: {
      es: 'Google Drive',
      en: 'Google Drive',
    },
    permissions: {
      write: 47,
      read: 48,
    },
  },
]

export default PERMISSIONS
