import {
  GET_MARKETING_CAMPAIGNS,
  UPLOAD_MARKETING_CAMPAIGN,
  UPDATE_MARKETING_CAMPAIGN,
  DELETE_MARKETING_CAMPAIGN,
  UPLOAD_MARKETING_CAMPAIGN_COMMENT,
  SELECT_MARKETING_CAMPAIGN,
} from 'constants/actionTypes'
import { apiFindData, apiPostData, apiPutData, apiDeleteData } from 'helpers/api'
import { MARKETING_CAMPAIGNS_COMMENT_ROUTE, MARKETING_CAMPAIGNS_ROUTE, MARKETING_CAMPAIGNS_TASK_ROUTE } from 'constants/apiRoutes'

export const getMarketingCampaigns = (idCompany) =>
  apiFindData(MARKETING_CAMPAIGNS_ROUTE, { id: idCompany }, GET_MARKETING_CAMPAIGNS)
export const postMarketingCampaign = (marketingCampaign) =>
  apiPostData(MARKETING_CAMPAIGNS_ROUTE, marketingCampaign, UPLOAD_MARKETING_CAMPAIGN)
export const updateMarketingCampaign = (marketingCampaign) =>
  apiPutData(
    MARKETING_CAMPAIGNS_ROUTE,
    { ...marketingCampaign, id: marketingCampaign?.idMarketingCampaign },
    UPDATE_MARKETING_CAMPAIGN
  )
export const deleteMarketingCampaign = (id) => apiDeleteData(MARKETING_CAMPAIGNS_ROUTE, { id }, DELETE_MARKETING_CAMPAIGN)

export const selectMarketingCampaing = (campaign) => ({ type: SELECT_MARKETING_CAMPAIGN, payload: campaign })

// Comments
export const postMarketingCampaignComment = (comment) =>
  apiPostData(MARKETING_CAMPAIGNS_COMMENT_ROUTE, comment, UPLOAD_MARKETING_CAMPAIGN_COMMENT)

// Task
export const postMarketingCampaignTask = (task) => apiPostData(MARKETING_CAMPAIGNS_TASK_ROUTE, task)
