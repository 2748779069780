import React from 'react'
import CHARTS_MODELS from '../helpers/chartsModels'
import { changeGraphicType } from '../helpers/indicatorReducer'
import ChartItem from './ChartItem'
import styles from './GraphicSelector.module.scss'

const GraphicSelector = ({ indicator, dispatchIndicator }) => {
  const handleSelectChart = (type) => () => {
    let value = type
    if (type === indicator.graphicType) value = ''

    dispatchIndicator(changeGraphicType(value))
  }

  return indicator.type === 'graphic' ? (
    <div className={styles.wrapper}>
      {CHARTS_MODELS.map((chart) => (
        <ChartItem
          key={chart.id}
          chart={chart}
          selected={chart.type === indicator.graphicType}
          handleSelectChart={handleSelectChart(chart.type)}
        />
      ))}
    </div>
  ) : null
}

export default GraphicSelector
