import { GET_MASS_EMAILS, SEND_MASS_EMAIL } from 'constants/actionTypes'

const initialState = {
  massEmails: [],
  isLoadingMassEmails: true,
  errorInRequest: false,
}

const handlers = {
  [GET_MASS_EMAILS]: (state, { payload = {} }) => {
    if (!payload?.massEmails) return { ...state, isLoadingMassEmails: false, errorInRequest: true }

    return {
      ...state,
      massEmails: payload?.massEmails?.reverse(),
      isLoadingMassEmails: false,
      errorInRequest: false,
    }
  },
  [SEND_MASS_EMAIL]: (state, { payload = {} }) => {
    if (!payload?.massEmail) return { ...state, errorInRequest: true }

    return {
      ...state,
      massEmails: [payload?.massEmail, ...state?.massEmails],
      errorInRequest: false,
    }
  },
}

const messages = (state = initialState, action) => {
  const { type } = action

  return handlers[type] ? handlers[type](state, action) : state
}

export default messages
