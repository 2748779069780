export const currencies = () => {
  const locale = localStorage.getItem('locale')

  return [
    {
      text: 'Colones (CRC)',
      value: 'crc',
    },
    {
      text: locale === 'es' ? 'Dólar (USD)' : 'Dollar (USD)',
      value: 'usd',
    },
    {
      text: locale === 'es' ? 'Dólar beliceño (BZD)' : 'Belize Dollar (BZD)',
      value: 'bzd',
    },
    {
      text: 'Quetzal (GTQ)',
      value: 'gtq',
    },
    {
      text: 'Lempira (HNL)',
      value: 'nhl',
    },
    {
      text: 'Córdoba (NIO)',
      value: 'nio',
    },
    {
      text: 'Balboa (PAB)',
      value: 'pab',
    },
    {
      text: locale === 'es' ? 'Dólar Salvadoreño (SVC)' : '8.75 Salvadoran Colon',
      value: 'svc',
    },
  ]
}
