import {
  DELETE_INVENTORY_CATEGORY,
  DELETE_INVENTORY_PRODUCT,
  DELETE_INVENTORY_PROVIDER,
  DELETE_INVENTORY_TRANSACTION,
  GET_INVENTORY_CATEGORIES,
  GET_INVENTORY_PRODUCTS,
  GET_INVENTORY_PROVIDERS,
  GET_INVENTORY_TRANSACTIONS,
  UPDATE_INVENTORY_CATEGORY,
  UPDATE_INVENTORY_PRODUCT,
  UPDATE_INVENTORY_PROVIDER,
  UPDATE_INVENTORY_TRANSACTION,
  UPLOAD_INVENTORY_CATEGORY,
  UPLOAD_INVENTORY_PRODUCT,
  UPLOAD_INVENTORY_PROVIDER,
  UPLOAD_INVENTORY_TRANSACTION,
} from 'constants/actionTypes'

const initialState = {
  isLoadingCategories: true,
  isLoadingProviders: true,
  isLoadingProducts: true,
  isLoadingTransactions: true,
  transactions: [],
  categories: [],
  providers: [],
  products: [],
  errorInRequest: false,
}

const handlers = {
  [GET_INVENTORY_CATEGORIES]: (state, { payload = {} }) => {
    if (!payload?.inventoryCategories) return { ...state, errorInRequest: true, isLoadingCategories: false }

    return { ...state, categories: payload?.inventoryCategories, errorInRequest: false, isLoadingCategories: false }
  },

  [UPLOAD_INVENTORY_CATEGORY]: (state, { payload = {} }) => {
    if (!payload?.inventoryCategory) return { ...state, errorInRequest: true, isLoadingCategories: false }

    return { ...state, categories: [...state.categories, payload?.inventoryCategory] }
  },

  [UPDATE_INVENTORY_CATEGORY]: (state, { payload = {} }) => {
    if (!payload?.inventoryCategory) return { ...state, errorInRequest: true, isLoadingCategories: false }

    return {
      ...state,
      categories: state.categories.map((_category) => {
        if (_category.idInventoryCategory === payload?.inventoryCategory?.idInventoryCategory) {
          return payload?.inventoryCategory
        }

        return _category
      }),
    }
  },

  [DELETE_INVENTORY_CATEGORY]: (state, { payload = {} }) => {
    if (!payload?.inventoryCategory) return { ...state, errorInRequest: true, isLoadingCategories: false }

    return {
      ...state,
      categories: state.categories.filter(
        (_category) => _category.idInventoryCategory !== payload?.inventoryCategory?.idInventoryCategory
      ),
    }
  },

  [GET_INVENTORY_PROVIDERS]: (state, { payload = {} }) => {
    if (!payload?.inventoryProviders) return { ...state, errorInRequest: true, isLoadingProviders: false }

    return { ...state, providers: payload?.inventoryProviders, errorInRequest: false, isLoadingProviders: false }
  },

  [UPLOAD_INVENTORY_PROVIDER]: (state, { payload = {} }) => {
    if (!payload?.inventoryProvider) return { ...state, errorInRequest: true, isLoadingProviders: false }

    return { ...state, providers: [payload?.inventoryProvider, ...state.providers] }
  },

  [UPDATE_INVENTORY_PROVIDER]: (state, { payload = {} }) => {
    if (!payload?.inventoryProvider) return { ...state, errorInRequest: true, isLoadingProviders: false }

    return {
      ...state,
      providers: state.providers.map((_provider) => {
        if (_provider.idInventoryProvider === payload?.inventoryProvider?.idInventoryProvider) {
          return payload?.inventoryProvider
        }

        return _provider
      }),
    }
  },

  [DELETE_INVENTORY_PROVIDER]: (state, { payload = {} }) => {
    if (!payload?.inventoryProvider) return { ...state, errorInRequest: true, isLoadingCategories: false }

    return {
      ...state,
      providers: state.providers.filter(
        (_provider) => _provider.idInventoryProvider !== payload?.inventoryProvider?.idInventoryProvider
      ),
    }
  },

  [GET_INVENTORY_PRODUCTS]: (state, { payload = {} }) => {
    if (!payload?.inventoryProducts) return { ...state, errorInRequest: true, isLoadingProducts: false }

    return { ...state, products: payload?.inventoryProducts, errorInRequest: false, isLoadingProducts: false }
  },

  [UPLOAD_INVENTORY_PRODUCT]: (state, { payload = {} }) => {
    if (!payload?.inventoryProduct) return { ...state, errorInRequest: true, isLoadingProducts: false }

    return { ...state, products: [...state.products, payload?.inventoryProduct] }
  },

  [UPDATE_INVENTORY_PRODUCT]: (state, { payload = {} }) => {
    if (!payload?.inventoryProduct) return { ...state, errorInRequest: true, isLoadingProducts: false }

    return {
      ...state,
      products: state.products.map((_product) => {
        if (_product.idInventoryProduct === payload?.inventoryProduct?.idInventoryProduct) {
          return payload?.inventoryProduct
        }

        return _product
      }),
    }
  },

  [DELETE_INVENTORY_PRODUCT]: (state, { payload = {} }) => {
    if (!payload?.inventoryProduct) return { ...state, errorInRequest: true, isLoadingProducts: false }

    return {
      ...state,
      products: state.products.filter(
        (_product) => _product.idInventoryProduct !== payload?.inventoryProduct?.idInventoryProduct
      ),

      transactions: state.transactions.filter(
        (_transaction) => _transaction.idInventoryProduct !== payload?.inventoryProduct?.idInventoryProduct
      ),
    }
  },

  [GET_INVENTORY_TRANSACTIONS]: (state, { payload = {} }) => {
    if (!payload?.inventoryTransactions) return { ...state, errorInRequest: true, isLoadingTransactions: false }

    return { ...state, transactions: payload?.inventoryTransactions, errorInRequest: false, isLoadingTransactions: false }
  },

  [UPLOAD_INVENTORY_TRANSACTION]: (state, { payload = {} }) => {
    if (!payload?.inventoryTransaction) return { ...state, errorInRequest: true, isLoadingTransactions: false }

    return {
      ...state,
      products: state.products.map((_product) => {
        if (_product.idInventoryProduct === payload?.inventoryTransaction?.idInventoryProduct) {
          return payload?.inventoryTransaction?.product
        }

        return _product
      }),
      transactions: [...state.transactions, payload?.inventoryTransaction],
    }
  },

  [UPDATE_INVENTORY_TRANSACTION]: (state, { payload = {} }) => {
    if (!payload?.inventoryTransaction) return { ...state, errorInRequest: true, isLoadingTransactions: false }

    return {
      ...state,
      transactions: state.transactions.map((_transaction) => {
        if (_transaction.idInventoryTransaction === payload?.inventoryTransaction?.idInventoryTransaction) {
          return payload?.inventoryTransaction
        }

        return _transaction
      }),

      products: state.products.map((_product) => {
        if (_product.idInventoryProduct === payload?.inventoryTransaction?.idInventoryProduct) {
          return payload?.inventoryTransaction?.product
        }

        return _product
      }),
    }
  },

  [DELETE_INVENTORY_TRANSACTION]: (state, { payload = {} }) => {
    if (!payload?.inventoryTransaction) return { ...state, errorInRequest: true, isLoadingTransactions: false }

    return {
      ...state,
      transactions: state.transactions.filter(
        (_transaction) => _transaction.idInventoryTransaction !== payload?.inventoryTransaction?.idInventoryTransaction
      ),
      products: state.products.map((_product) => {
        if (_product.idInventoryProduct === payload?.inventoryTransaction?.idInventoryProduct) {
          return payload?.inventoryTransaction?.product
        }

        return _product
      }),
    }
  },
}

const inventory = (state = initialState, action) => {
  const { type } = action

  return handlers[type] ? handlers[type](state, action) : state
}

export default inventory
