import axios from 'axios'
import axiosRetry from 'axios-retry'
import { UID_COOKIE } from 'constants/cookies'
import { readCookie } from './cookies'

/** API LAYER */
const api = (axios) => {
  return {
    get: (url, config) => withAbort(axios.get)(url, config?.config),
    delete: (url, config) => withAbort(axios.delete)(url, config?.config),
    post: (url, config) => withAbort(axios.post)(url, config, config?.config),
    put: (url, config) => withAbort(axios.put)(url, config, config?.config),
  }
}

/**
 * AGREGAMOS RETRY A TODOS LOS INSTANCES DE AXIOS *
 * EN CASO DE CODIGO 500, AXIOS INTENTA DE NUEVO EL MISMO REQUEST *
 * EN CASO DE CODIGO 200, AXIOS NO HACE RETRY *
 */
// axiosRetry(axiosInstace, { retries: 3 })

const didAbort = (error) => axios.isCancel(error)
const getCancelSource = () => axios.CancelToken.source()

const withAbort = (fn) => {
  const executor = async (...args) => {
    const originalConfig = args[args.length - 1]
    // Extract abort property from the config
    const { abort, ...config } = originalConfig ?? {}
    // Create cancel token and abort method only if abort
    // function was passed
    if (typeof abort === 'function') {
      const { cancel, token } = getCancelSource()
      config.cancelToken = token
      abort(cancel)
    }

    try {
      if (args.length > 2) {
        const [url, body] = args
        return (await fn)(url, body, config)
      } else {
        const [url] = args
        return (await fn)(url, config)
      }
    } catch (error) {
      console.log('api error', error)
      // Add "aborted" property to the error if the request was cancelled
      if (didAbort(error)) {
        error.aborted = true
      }
    }
  }

  return executor
}

/**
 * FUNCION PARA INYECTAR LA REF DE CANCELACION *
 */
const inyectAbortRef = (abortRef) => {
  if (abortRef)
    return {
      abort: (abort) => (abortRef.current.abort = abort),
    }
}

/**
 * FUNCION PARA LOS METODOS *
 * GET, POST, PUT, DELETE, FIND *
 */
const withApi = async function (type, url, config, actionType) {
  const axiosParams = {
    params: { auth: readCookie(UID_COOKIE) },
  }

  const axiosInstance = axios.create(axiosParams)
  axiosRetry(axiosInstance, { retries: type === 'delete' ? 0 : 3 })

  const { abortRef } = config ?? {}
  const configuration = {
    ...config,
    config: {
      ...inyectAbortRef(abortRef),
      ...config?.config,
    },
  }

  try {
    const { data } = await api(axiosInstance)[type](url, configuration)

    if (actionType) return { type: actionType, payload: data }

    return data
  } catch (error) {
    throw error
  }
}

export const apiGetData = (...args) => withApi('get', ...args)

export const apiPostData = (...args) => withApi('post', ...args)

export const apiPutData = (...args) => {
  const [url, body, actionType] = args

  return withApi('put', `${url}/${body.id}`, body, actionType)
}

export const apiDeleteData = (...args) => {
  const [url, config, actionType] = args
  const { id } = config ?? {}

  return withApi('delete', `${url}/${id}`, config, actionType)
}

export const apiFindData = (...args) => {
  const [baseUrl, config, actionType] = args
  const { id } = config ?? {}

  return withApi('get', `${baseUrl}/${id}`, config, actionType)
}

/** LEGACY HELPERS CON FORM DATA */
export const apiPostImage = (url, params, actionType) => {
  var formData = new FormData()

  formData.append('auth', readCookie(UID_COOKIE))
  formData.append('image', params?.image)

  return axios
    .post(url, formData)
    .then(({ data }) => (actionType ? { type: actionType, payload: data } : data))
    .catch((error) =>
      actionType ? { type: actionType, payload: { error: true, details: error } } : { error: true, details: error }
    )
}

export const apiPostFormData = (url, params, actionType) => {
  var formData = new FormData()
  formData.append('auth', readCookie(UID_COOKIE))
  for (var key in params) formData.append(key, params[key])

  return axios
    .post(url, formData)
    .then(({ data }) => (actionType ? { type: actionType, payload: data } : data))
    .catch((error) =>
      actionType ? { type: actionType, payload: { error: true, details: error } } : { error: true, details: error }
    )
}

export const apiPostFormDataWithData = (url, form, actionType) => {
  form.append('auth', readCookie(UID_COOKIE))

  return axios
    .post(url, form)
    .then(({ data }) => (actionType ? { type: actionType, payload: data } : data))
    .catch((error) => (actionType ? { type: actionType, payload: { error: true, details: error } } : error))
}

export const apiPostFile = async (url, form, action) => {
  form.append('auth', readCookie(UID_COOKIE))

  const res = await axios.post(url, form).catch((error) => error)

  if (action) {
    return {
      type: action,
      payload: res.data,
    }
  } else return res
}

export const sendReport = async (url, formData) => {
  formData.append('auth', readCookie(UID_COOKIE))

  try {
    const res = await axios.post(url, formData)
    return res
  } catch (error) {
    return {
      error: true,
      message: error,
    }
  }
}
