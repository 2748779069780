import { FormControl, InputLabel, MenuItem, OutlinedInput, Select as MuiSelect } from '@material-ui/core'

const Select = ({
  name,
  label,
  options,
  disabled = false,
  id = '',
  value = null,
  onChange = () => null,
  isRequired = true,
  displayEmpty = false,
  emptyValue = '',
  multiple = false,
  ...delegated
}) => {
  return (
    <FormControl required={isRequired} {...delegated}>
      <InputLabel
        variant="outlined"
        shrink={
          displayEmpty ? true : Boolean(typeof value === 'object' ? value?.length : typeof value === 'boolean' ? true : value)
        }
      >
        {label}
      </InputLabel>

      <MuiSelect
        id={id}
        multiple={multiple}
        displayEmpty={displayEmpty}
        label={label}
        value={value ?? ''}
        name={name}
        onChange={onChange}
        disabled={disabled}
        input={
          <OutlinedInput
            notched={
              displayEmpty ? true : Boolean(typeof value === 'object' ? value?.length : typeof value === 'boolean' ? true : value)
            }
            label={(typeof value === 'boolean' ? true : Boolean(value)) || displayEmpty ? label : undefined}
          />
        }
      >
        {displayEmpty ? <MenuItem value={''}>{emptyValue}</MenuItem> : null}
        {options?.map((i) => (
          <MenuItem value={i.value} key={i.value}>
            {i.text}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  )
}

export default Select
