import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import data from '../data'
import { makeSlug } from '../utils'
import styles from './Faqs.module.scss'

const Element = ({ answer, question }) => {
  const location = useLocation()
  const [open, setOpen] = useState(false)
  const iconClass = open ? `fas fa-chevron-down` : `fas fa-chevron-right`
  const id = makeSlug(question)

  useEffect(() => {
    const { hash } = location
    if (hash) {
      const hashId = decodeURI(hash.replace('#', ''))
      if (hashId === id) {
        setOpen(true)
      }
    }
  }, [location, id])

  return (
    <details id={id}>
      <summary onClick={() => setOpen(!open)}>
        {question}
        <i className={iconClass} />
      </summary>
      <p>{answer}</p>
    </details>
  )
}

const Faqs = () => {
  const { faqs } = data
  return (
    <div className={styles.wrapper}>
      {faqs.map((faq, index) => (
        <Element key={faq.question + index} {...faq} />
      ))}
    </div>
  )
}

export default Faqs
