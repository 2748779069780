import AuthValidation from 'components/AuthValidation/AuthValidation'
import ModalsControler from 'components/ModalsControler/ModalsControler'
import NoProAccess from 'components/NoProAccess'
import { useHideSubscriptionModule } from 'hooks/useHideSubscriptionModule'
import useSubscription from 'hooks/useSubscription'
import DatanalisisLayout from './components/DatanalisisLayout'
import SelectedPage from './components/SelectedPage'

const DatanalisisPage = () => {
  const { isProUser } = useSubscription()
  const hideSubscriptionModule = useHideSubscriptionModule()

  return (
    <AuthValidation>
      {!isProUser && !hideSubscriptionModule ? (
        <NoProAccess />
      ) : (
        <>
          <DatanalisisLayout>
            <SelectedPage />
          </DatanalisisLayout>

          <ModalsControler />
        </>
      )}
    </AuthValidation>
  )
}

export default DatanalisisPage
