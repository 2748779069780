import {
  DELETE_MOVEMENT,
  DELETE_PROJECT,
  GET_COMPANY_PROJECTS,
  GET_MOVEMENTS,
  LOADING_MOVEMENTS,
  UPDATE_MOVEMENT,
  UPDATE_PROJECT,
  UPLOAD_MOVEMENT,
  UPLOAD_PROJECT,
} from 'constants/actionTypes'

import { MOVEMENTS_ROUTE, PROJECTS_ROUTE } from 'constants/apiRoutes'
import { apiDeleteData, apiFindData, apiPostData, apiPutData } from 'helpers/api'

export const getCompanyProjects = (idCompany) => apiFindData(PROJECTS_ROUTE, { id: idCompany }, GET_COMPANY_PROJECTS)

export const postProject = (project) => {
  const now = new Date()
  const hourString = `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`

  return apiPostData(PROJECTS_ROUTE, { ...project, hour: hourString }, UPLOAD_PROJECT)
}

export const updateProject = (project) => apiPutData(PROJECTS_ROUTE, { ...project, id: project?.idProject }, UPDATE_PROJECT)

export const deleteProject = (idProject) => apiDeleteData(PROJECTS_ROUTE, { id: idProject }, DELETE_PROJECT)

export const getProjectMovements = (idProject) => apiFindData(MOVEMENTS_ROUTE, { id: idProject }, GET_MOVEMENTS)

export const postMovement = (movement) => apiPostData(MOVEMENTS_ROUTE, movement, UPLOAD_MOVEMENT)

export const updateMovement = (movements) =>
  apiPutData(MOVEMENTS_ROUTE, { ...movements, id: movements?.idMovement }, UPDATE_MOVEMENT)

export const deleteMovement = (idMovement) => apiDeleteData(MOVEMENTS_ROUTE, { id: idMovement }, DELETE_MOVEMENT)

export const loadingMovements = () => ({ type: LOADING_MOVEMENTS, payload: { isLoadingMovements: true, projectMovements: [] } })
