export const categoriesSelector = (categoryValue, locale) => {
  const categoriesMap = {
    education: locale === 'es' ? 'Educación' : 'Education',
    food: locale === 'es' ? 'Comida' : 'Food',
    tech: locale === 'es' ? 'Tecnología' : 'Tech',
    health: locale === 'es' ? 'Salud' : 'Health',
    others: locale === 'es' ? 'Otros' : 'Others',
  }

  return categoriesMap[categoryValue]
}

export const categoriesOptionsGenerator = (locale) => [
  {
    text: locale === 'es' ? 'Educación' : 'Education',
    value: 'education',
  },
  {
    text: locale === 'es' ? 'Comida' : 'Food',
    value: 'food',
  },
  {
    text: locale === 'es' ? 'Tecnología' : 'Tech',
    value: 'tech',
  },
  {
    text: locale === 'es' ? 'Salud' : 'Health',
    value: 'health',
  },
  {
    text: locale === 'es' ? 'Varios' : 'Others',
    value: 'others',
  },
]
