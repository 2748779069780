//Modals
export const OPEN_FORM_MODAL = 'OPEN_FORM_MODAL'
export const UPDATE_SNACKBAR_MESSAGE = 'UPDATE_SNACKBAR_MESSAGE'
export const CONFIRM_DIALOG = 'CONFIRM_DIALOG'
export const OPEN_POST_MODAL = 'OPEN_POST_MODAL'
export const CLOSE_POST_MODAL = 'CLOSE_POST_MODAL'
export const OPEN_STEPPER_MODAL = 'OPEN_STEPPER_MODAL'
export const UPDATE_STEPPER_MODAL_DATA = 'UPDATE_STEPPER_MODAL_DATA'
export const NEXT_PAGE_STEPPER_MODAL = 'NEXT_PAGE_STEPPER_MODAL'
export const PREVIOUS_PAGE_STEPPER_MODAL = 'PREVIOUS_PAGE_STEPPER_MODAL'
export const CLOSE_STEPPER_MODAL = 'CLOSE_STEPPER_MODAL'

// Users
export const GET_MY_USER = 'GET_MY_USER'
export const UPDATE_MY_USER = 'UPDATE_MY_USER'
export const SET_NEW_USER = 'SET_NEW_USER'
export const SET_NEW_USER_COMPANY = 'SET_NEW_USER_COMPANY'
export const GET_USERS = 'GET_USERS'
export const UPDATE_USER = 'UPDATE_USER'
export const DELETE_USER = 'DELETE_USER'
export const UPDATE_USER_AVATAR = 'UPDATE_USER_AVATAR'
export const UPDATE_MY_COMPANY_LOGO = 'UPDATE_MY_COMPANY_LOGO'
export const DELETE_USER_ASSET = 'DELETE_USER_ASSET'
export const CREATE_USER_EMPLOYEE_WORK_DAY = 'CREATE_USER_EMPLOYEE_WORK_DAY'
export const UPDATE_USER_EMPLOYEE_WORK_DAY = 'UPDATE_USER_EMPLOYEE_WORK_DAY'
export const CREATE_USER_EMPLOYEE_WORK_DAY_BREAK = 'CREATE_USER_EMPLOYEE_WORK_DAY_BREAK'
export const UPDATE_USER_EMPLOYEE_WORK_DAY_BREAK = 'UPDATE_USER_EMPLOYEE_WORK_DAY_BREAK'
export const UPLOAD_USER_FREE_TIME = 'UPLOAD_USER_FREE_TIME'

// CompanyUsers
export const SET_NEW_COMPANY_USER = 'SET_NEW_COMPANY_USER'
export const GET_COMPANY_USERS = 'GET_COMPANY_USERS'
export const UPDATE_COMPANY_USER = 'UPDATE_COMPANY_USER'
export const DELETE_COMPANY_USER = 'DELETE_COMPANY_USER'

// Customers
export const GET_CUSTOMERS = 'GET_CUSTOMERS'
export const SET_NEW_CUSTOMER = 'SET_NEW_CUSTOMER'
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER'
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER'
export const GET_CONTACTS = 'GET_CONTACTS'
export const SET_NEW_CONTACT = 'SET_NEW_CONTACT'
export const UPDATE_CONTACT = 'UPDATE_CONTACT'
export const DELETE_CONTACT = 'DELETE_CONTACT'
export const LOADING_CONTACTS = 'LOADING_CONTACTS'
export const MASSIVE_CUSTOMERS_UPLOAD = 'MASSIVE_CUSTOMERS_UPLOAD'

// Configs
export const GET_CONFIGS = 'GET_CONFIGS'
export const SET_NEW_CONFIG = 'SET_NEW_CONFIG'
export const UPDATE_CONFIG = 'UPDATE_CONFIG'
export const CHANGE_LOCALE = 'CHANGE_LOCALE'

// History
export const GET_HISTORIC = 'GET_HISTORIC'

// SocialMedia
export const GET_FB_PAGES = 'GET_FB_PAGES'
export const GET_POSTS = 'GET_POSTS'
export const SET_NEW_POST = 'SET_NEW_POST'

// Tools
export const GET_TOOLS = 'GET_TOOLS'
export const SET_NEW_TOOL = 'SET_NEW_TOOL'
export const UPDATE_TOOL = 'UPDATE_TOOL'
export const DELETE_TOOL = 'DELETE_TOOL'

// Messages
export const SEND_MASS_EMAIL = 'SEND_MASS_EMAIL'
export const GET_MASS_EMAILS = 'GET_MASS_EMAILS'

// Calendar Actions
export const GET_CALENDAR_ACTIONS = 'GET_CALENDAR_ACTIONS'
export const UPLOAD_CALENDAR_ACTION = 'UPLOAD_CALENDAR_ACTION'
export const UPDATE_CALENDAR_ACTION = 'UPDATE_CALENDAR_ACTION'
export const DELETE_CALENDAR_ACTION = 'DELETE_CALENDAR_ACTION'
export const SELECT_CALENDAR_ACTION = 'SELECT_CALENDAR_ACTION'
export const SELECT_CALENDAR_ACTION_CONTENT = 'SELECT_CALENDAR_ACTION_CONTENT'
export const UPLOAD_CALENDAR_ACTION_COMMENT = 'UPLOAD_CALENDAR_ACTION_COMMENT'

// Client Groups
export const GET_CLIENT_GROUPS = 'GET_CLIENT_GROUPS'
export const UPLOAD_CLIENT_GROUP = 'UPLOAD_CLIENT_GROUP'
export const UPDATE_CLIENT_GROUP = 'UPDATE_CLIENT_GROUP'
export const DELETE_CLIENT_GROUP = 'DELETE_CLIENT_GROUP'

// Projects
export const GET_COMPANY_PROJECTS = 'GET_COMPANY_PROJECTS'
export const UPLOAD_PROJECT = 'UPLOAD_PROJECT'
export const UPDATE_PROJECT = 'UPDATE_PROJECT'
export const DELETE_PROJECT = 'DELETE_PROJECT'

// Movements
export const LOADING_MOVEMENTS = 'LOADING_MOVEMENTS'
export const GET_MOVEMENTS = 'GET_MOVEMENTS'
export const UPLOAD_MOVEMENT = 'UPLOAD_MOVEMENT'
export const UPDATE_MOVEMENT = 'UPDATE_MOVEMENT'
export const DELETE_MOVEMENT = 'DELETE_MOVEMENT'

// Company Information
export const GET_INFO_PAGES = 'GET_INFO_PAGE'
export const UPLOAD_INFO_PAGE = 'UPLOAD_INFO_PAGE'
export const UPDATE_INFO_PAGE = 'UPDATE_INFO_PAGE'
export const DELETE_INFO_PAGE = 'DELETE_INFO_PAGE'
export const GET_INFO_QUESTIONS = 'GET_INFO_QUESTIONS'
export const UPLOAD_INFO_QUESTION = 'UPLOAD_INFO_QUESTION'
export const UPDATE_INFO_QUESTION = 'UPDATE_INFO_QUESTION'
export const DELETE_INFO_QUESTION = 'DELETE_MOVEMENT'
export const GET_INFO_ANSWER = 'GET_INFO_ANSWER'
export const UPLOAD_INFO_ANSWER = 'UPLOAD_INFO_ANSWER'
export const UPDATE_INFO_ANSWER = 'UPDATE_INFO_ANSWER'
export const UPDATE_COMPANY_INFO_QUESTIONS_POSITIONS = 'UPDATE_COMPANY_INFO_QUESTIONS_POSITIONS'
export const UPDATE_INFO_QUESTION_OPTION = 'UPDATE_INFO_QUESTION_OPTION'
export const DELETE_INFO_QUESTION_OPTION = 'DELETE_INFO_QUESTION_OPTION'

// Client Forms
export const GET_FORMS = 'GET_FORMS'
export const UPLOAD_FORM = 'UPLOAD_FORM'
export const UPDATE_FORM = 'UPDATE_FORM'
export const DELETE_FORM = 'DELETE_FORM'
export const GET_FORM_PAGES = 'GET_FORM_PAGES'
export const UPLOAD_FORM_PAGE = 'UPLOAD_FORM_PAGE'
export const UPDATE_FORM_PAGE = 'UPDATE_FORM_PAGE'
export const DELETE_FORM_PAGE = 'DELETE_FORM_PAGE'
export const GET_FORM_QUESTIONS = 'GET_FORM_QUESTION'
export const UPLOAD_FORM_QUESTION = 'UPLOAD_FORM_QUESTION'
export const UPDATE_FORM_QUESTION = 'UPDATE_FORM_QUESTION'
export const DELETE_FORM_QUESTION = 'DELETE_FORM_QUESTION'
export const UPDATE_FORM_QUESTIONS_POSITIONS = 'UPDATE_FORM_QUESTIONS_POSITIONS'
export const UPDATE_FORM_FETCHED = 'UPDATE_FORM_FETCHED'
export const UPDATE_ANSWERS_FETCHED = 'UPDATE_ANSWERS_FETCHED'
export const GET_ANSWERS = 'GET_ANSWERS'
export const DELETE_ANSWER = 'DELETE_ANSWER'
export const ANSWERS_PER_QUESTION = 'ANSWERS_PER_QUESTION'
export const UPDATE_QUESTION_OPTION = 'UPDATE_QUESTION_OPTION'
export const DELETE_QUESTION_OPTION = 'DELETE_QUESTION_OPTION'

// Costs
export const GET_COMPANY_PRODUCTS_COSTS = 'GET_COMPANY_COSTS'
export const UPLOAD_PRODUCTS_COST = 'UPLOAD_COST'
export const UPDATE_PRODUCTS_COST = 'UPDATE_COST'
export const DELETE_PRODUCTS_COST = 'DELETE_COST'
export const GET_COMPANY_INDIRECT_COSTS = 'GET_COMPANY_INDIRECT_COSTS'
export const UPLOAD_INDIRECT_COST = 'UPLOAD_INDIRECT_COST'
export const UPDATE_INDIRECT_COST = 'UPDATE_INDIRECT_COST'
export const DELETE_INDIRECT_COST = 'DELETE_INDIRECT_COST'

// Costs -> Product
export const GET_PRODUCT_WORKFORCES = 'GET_PRODUCT_WORKFORCES'
export const UPLOAD_PRODUCT_WORKFORCE = 'UPLOAD_PRODUCT_WORKFORCE'
export const UPDATE_PRODUCT_WORKFORCE = 'UPDATE_PRODUCT_WORKFORCE'
export const DELETE_PRODUCT_WORKFORCE = 'DELETE_PRODUCT_WORKFORCE'
export const WORKFORCE_FETCHED = 'WORKFORCE_FETCHED'
export const GET_RAW_MATERIALS = 'GET_RAW_MATERIALS'
export const UPLOAD_RAW_MATERIAL = 'UPLOAD_RAW_MATERIAL'
export const UPDATE_RAW_MATERIAL = 'UPDATE_RAW_MATERIAL'
export const DELETE_RAW_MATERIAL = 'DELETE_RAW_MATERIAL'
export const RAW_MATERIALS_FETCHED = 'RAW_MATERIALS_FETCHED'

// Cost -> Product Indirect Costs
export const GET_PRODUCT_INDIRECT_COSTS = 'GET_PRODUCT_INDIRECT_COSTS'
export const UPDATE_PRODUCT_INDIRECT_COST = 'UPDATE_PRODUCT_INDIRECT_COST'

// Processes
export const GET_PROCESSES = 'GET_PROCESSES'
export const UPLOAD_PROCESS = 'UPLOAD_PROCESS'
export const UPDATE_PROCESS = 'UPDATE_PROCESS'
export const DELETE_PROCESS = 'DELETE_PROCESS'

// Custom roles
export const GET_ROLES = 'GET_ROLES'
export const UPLOAD_ROLE = 'UPLOAD_ROLE'
export const UPDATE_ROLE = 'UPDATE_ROLE'
export const DELETE_ROLE = 'DELETE_ROLE'

// Human Resources
export const GET_DEPARTMENTS = 'GET_DEPARTMENTS'
export const UPLOAD_DEPARTMENT = 'UPLOAD_DEPARTMENT'
export const UPDATE_DEPARTMENT = 'UPDATE_DEPARTMENT'
export const DELETE_DEPARTMENT = 'DELETE_DEPARTMENT'
export const GET_POSITIONS = 'GET_POSITIONS'
export const UPLOAD_POSITION = 'UPLOAD_POSITION'
export const UPDATE_POSITION = 'UPDATE_POSITION'
export const DELETE_POSITION = 'DELETE_POSITION'
export const GET_SCHEDULES = 'GET_SCHEDULES'
export const UPLOAD_SCHEDULE = 'UPLOAD_SCHEDULE'
export const UPDATE_SCHEDULE = 'UPDATE_SCHEDULE'
export const DELETE_SCHEDULE = 'DELETE_SCHEDULE'
export const GET_EMPLOYEES = 'GET_EMPLOYEES'
export const UPLOAD_EMPLOYEE = 'UPLOAD_EMPLOYEE'
export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE'
export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE'
export const GET_DEDUCTIONS = 'GET_DEDUCTIONS'
export const UPLOAD_DEDUCTION = 'UPLOAD_DEDUCTION'
export const UPDATE_DEDUCTION = 'UPDATE_DEDUCTION'
export const DELETE_DEDUCTION = 'DELETE_DEDUCTION'
export const UPLOAD_PAYMENT_STUB = 'UPLOAD_PAYMENT_STUB'
export const UPDATE_PAYMENT_STUB = 'UPDATE_PAYMENT_STUB'
export const DELETE_PAYMENT_STUB = 'DELETE_PAYMENT_STUB'
export const UPLOAD_PAYMENT_STUB_FACTORY = 'UPLOAD_PAYMENT_STUB_FACTORY'
export const UPDATE_PAYMENT_STUB_FACTORY = 'UPDATE_PAYMENT_STUB_FACTORY'
export const DELETE_PAYMENT_STUB_FACTORY = 'DELETE_PAYMENT_STUB_FACTORY'
export const UPLOAD_PAYMENT_STUB_RECURRENCY = 'UPLOAD_PAYMENT_STUB_RECURRENCY'
export const UPDATE_PAYMENT_STUB_RECURRENCY = 'UPDATE_PAYMENT_STUB_RECURRENCY'
export const DELETE_PAYMENT_STUB_RECURRENCY = 'DELETE_PAYMENT_STUB_RECURRENCY'

export const UPLOAD_FREE_TIME = 'UPLOAD_FREE_TIME'
export const DELETE_FREE_TIME = 'DELETE_FREE_TIME'
export const UPDATE_EMPLOYEE_WORKED_HOURS = 'UPDATE_EMPLOYEE_WORKED_HOURS'
export const DELETE_EMPLOYEE_WORKED_HOURS = 'DELETE_EMPLOYEE_WORKED_HOURS'
export const DELETE_WORKED_HOURS_BREAK = 'DELETE_WORKED_HOURS_BREAK'
export const UPDATE_EMPLOYEE_FREE_TIME = 'UPDATE_EMPLOYEE_FREE_TIME'
export const GET_EVENTS = 'GET_EVENTS'
export const UPLOAD_EVENT = 'UPLOAD_EVENT'
export const UPDATE_EVENT = 'UPDATE_EVENT'
export const DELETE_EVENT = 'DELETE_EVENT'
export const PAY_BONUS = 'PAY_BONUS'
export const CLEAN_BONUS = 'CLEAN_BONUS'

// Boards
export const GET_BOARDS = 'GET_BOARDS'
export const UPLOAD_BOARD = 'UPLOAD_BOARD'
export const UPDATE_BOARD = 'UPDATE_BOARD'
export const DELETE_BOARD = 'DELETE_BOARD'

// Boards -> templates
export const GET_BOARD_TEMPLATES = 'GET_BOARD_TEMPLATES'
export const UPLOAD_BOARD_TEMPLATE = 'UPLOAD_BOARD_TEMPLATE'
export const UPDATE_BOARD_TEMPLATE = 'UPDATE_BOARD_TEMPLATE'
export const DELETE_BOARD_TEMPLATE = 'DELETE_BOARD_TEMPLATE'

// Boards -> template columns
export const UPLOAD_BOARD_TEMPLATE_COLUMN = 'UPLOAD_BOARD_TEMPLATE_COLUMN'
export const UPDATE_BOARD_TEMPLATE_COLUMN = 'UPDATE_BOARD_TEMPLATE_COLUMN'
export const DELETE_BOARD_TEMPLATE_COLUMN = 'DELETE_BOARD_TEMPLATE_COLUMN'
export const MOVE_COLUMN = 'MOVE_COLUMN'

// Boards -> tasks
export const GET_BOARD_TASKS = 'GET_BOARD_TASKS'
export const UPLOAD_BOARD_TASK = 'UPLOAD_BOARD_TASK'
export const UPDATE_BOARD_TASK = 'UPDATE_BOARD_TASK'
export const UPDATE_BOARD_TASKS_POSITION = 'UPDATE_BOARD_TASKS_POSITION'
export const DELETE_BOARD_TASK = 'DELETE_BOARD_TASK'

// Boards -> labels
export const GET_BOARD_LABELS = 'GET_BOARD_LABELS'
export const UPLOAD_BOARD_LABEL = 'UPLOAD_BOARD_LABEL'
export const UPDATE_BOARD_LABEL = 'UPDATE_BOARD_LABEL'
export const DELETE_BOARD_LABEL = 'DELETE_BOARD_LABEL'

// Boards -> lists
export const UPLOAD_BOARD_LIST = 'UPLOAD_BOARD_LIST'
export const UPDATE_BOARD_LIST = 'UPDATE_BOARD_LIST'
export const DELETE_BOARD_LIST = 'DELETE_BOARD_LIST'
export const UPLOAD_BOARD_TASK_FILE = 'UPLOAD_BOARD_TASK_FILE'
export const DELETE_BOARD_TASK_FILE = 'DELETE_BOARD_TASK_FILE'

// Marketing Campaigns
export const GET_MARKETING_CAMPAIGNS = 'GET_MARKETING_CAMPAIGNS'
export const UPLOAD_MARKETING_CAMPAIGN = 'UPLOAD_MARKETING_CAMPAIGN'
export const UPDATE_MARKETING_CAMPAIGN = 'UPDATE_MARKETING_CAMPAIGN'
export const DELETE_MARKETING_CAMPAIGN = 'DELETE_MARKETING_CAMPAIGN'
export const SELECT_MARKETING_CAMPAIGN = 'SELECT_MARKETING_CAMPAIGN'
export const UPLOAD_MARKETING_CAMPAIGN_COMMENT = 'UPLOAD_MARKETING_CAMPAIGN_COMMENT'

// Boards -> comments
export const UPLOAD_BOARD_TASK_COMMENT = 'UPLOAD_BOARD_TASK_COMMENT'
export const UPDATE_BOARD_TASK_COMMENT = 'UPDATE_BOARD_TASK_COMMENT'

// Boards -> histories
export const UPLOAD_BOARD_TASK_HISTORY = 'UPLOAD_BOARD_TASK_HISTORY'

// Report errors
export const GET_REPORT_ERRORS = 'GET_REPORT_ERRORS'
export const UPLOAD_REPORT_ERROR = 'UPLOAD_REPORT_ERROR'
export const UPDATE_REPORT_ERROR = 'UPDATE_REPORT_ERROR'
export const UPLOAD_REPORT_ERROR_FILE = 'UPLOAD_REPORT_ERROR_FILE'
export const SET_REPORT_ERROR_LOADING_STATE = 'SET_REPORT_ERROR_LOADING_STATE'

// Inventory
// Inventory -> categories
export const GET_INVENTORY_CATEGORIES = 'GET_INVENTORY_CATEGORIES'
export const UPLOAD_INVENTORY_CATEGORY = 'UPLOAD_INVENTORY_CATEGORY'
export const UPDATE_INVENTORY_CATEGORY = 'UPDATE_INVENTORY_CATEGORY'
export const DELETE_INVENTORY_CATEGORY = 'DELETE_INVENTORY_CATEGORY'

// Inventory -> providers
export const GET_INVENTORY_PROVIDERS = 'GET_INVENTORY_PROVIDERS'
export const UPLOAD_INVENTORY_PROVIDER = 'UPLOAD_INVENTORY_PROVIDER'
export const UPDATE_INVENTORY_PROVIDER = 'UPDATE_INVENTORY_PROVIDER'
export const DELETE_INVENTORY_PROVIDER = 'DELETE_INVENTORY_PROVIDER'

// Inventory -> products
export const GET_INVENTORY_PRODUCTS = 'GET_INVENTORY_PRODUCTS'
export const UPLOAD_INVENTORY_PRODUCT = 'UPLOAD_INVENTORY_PRODUCT'
export const UPDATE_INVENTORY_PRODUCT = 'UPDATE_INVENTORY_PRODUCT'
export const DELETE_INVENTORY_PRODUCT = 'DELETE_INVENTORY_PRODUCT'

// Inventory -> transactions
export const GET_INVENTORY_TRANSACTIONS = 'GET_INVENTORY_TRANSACTIONS'
export const UPLOAD_INVENTORY_TRANSACTION = 'UPLOAD_INVENTORY_TRANSACTION'
export const UPDATE_INVENTORY_TRANSACTION = 'UPDATE_INVENTORY_TRANSACTION'
export const DELETE_INVENTORY_TRANSACTION = 'DELETE_INVENTORY_TRANSACTION'

// Images Bank
export const GET_IMAGES_BANK_RECOMMENDATIONS = 'GET_IMAGES_BANK_RECOMMENDATIONS'
export const GET_IMAGES_BANK_BY_QUERY = 'GET_IMAGES_BAK_BY_QUERY'
export const GET_IMAGES_BANK_FAVORITES = 'GET_IMAGES_BANK_FAVORITES'
export const UPLOAD_FAVORITE_IMAGE = 'UPLOAD_FAVORITE_IMAGE'
export const DELETE_FAVORITE_IMAGE = 'DELETE_FAVORITE_IMAGE'

// Personal Finances
export const GET_PERSONAL_FINANCE_TRANSACTIONS = 'GET_PERSONAL_FINANCE_TRANSACTIONS'
export const UPLOAD_PERSONAL_FINANCE_TRANSACTION = 'UPLOAD_PERSONAL_FINANCE_TRANSACTION'
export const UPDATE_PERSONAL_FINANCE_TRANSACTION = 'UPDATE_PERSONAL_FINANCE_TRANSACTION'
export const DELETE_PERSONAL_FINANCE_TRANSACTION = 'DELETE_PERSONAL_FINANCE_TRANSACTION'

export const GET_PERSONAL_FINANCE_SAVINGS = 'GET_PERSONAL_FINANCE_SAVINGS'
export const UPLOAD_PERSONAL_FINANCE_SAVING = 'UPLOAD_PERSONAL_FINANCE_SAVING'
export const DELETE_PERSONAL_FINANCE_SAVING = 'DELETE_PERSONAL_FINANCE_SAVING'
export const UPDATE_PERSONAL_FINANCE_SAVING_AMOUNT = 'UPDATE_PERSONAL_FINANCE_SAVING_AMOUNT'

export const GET_PERSONAL_FINANCE_CREDITS = 'GET_PERSONAL_FINANCE_CREDITS'
export const UPLOAD_PERSONAL_FINANCE_CREDIT = 'UPLOAD_PERSONAL_FINANCE_CREDIT'
export const UPDATE_PERSONAL_FINANCE_CREDIT = 'UPDATE_PERSONAL_FINANCE_CREDIT'
export const DELETE_PERSONAL_FINANCE_CREDIT = 'DELETE_PERSONAL_FINANCE_CREDIT'

export const GET_PERSONAL_FINANCE_CARDS = 'GET_PERSONAL_FINANCE_CARDS'
export const UPLOAD_PERSONAL_FINANCE_CARD = 'UPLOAD_PERSONAL_FINANCE_CARD'
export const UPDATE_PERSONAL_FINANCE_CARD = 'UPDATE_PERSONAL_FINANCE_CARD'
export const DELETE_PERSONAL_FINANCE_CARD = 'DELETE_PERSONAL_FINANCE_CARD'

// Cash Flow
export const GET_CASH_FLOW = 'GET_CASH_FLOW'
export const UPLOAD_CASH_FLOW = 'UPLOAD_CASH_FLOW'
export const UPDATE_CASH_FLOW = 'UPDATE_CASH_FLOW'
export const DELETE_CASH_FLOW = 'DELETE_CASH_FLOW'
export const UPLOAD_CASH_FLOW_INCOME = 'UPLOAD_CASH_FLOW_INCOME'
export const UPDATE_CASH_FLOW_INCOME = 'UPDATE_CASH_FLOW_INCOME'
export const DELETE_CASH_FLOW_INCOME = 'DELETE_CASH_FLOW_INCOME'
export const UPLOAD_CASH_FLOW_EXPENSE = 'UPLOAD_CASH_FLOW_EXPENSE'
export const UPDATE_CASH_FLOW_EXPENSE = 'UPDATE_CASH_FLOW_EXPENSE'
export const DELETE_CASH_FLOW_EXPENSE = 'DELETE_CASH_FLOW_EXPENSE'
export const UPDATE_CASH_FLOW_FILTER = 'UPDATE_CASH_FLOW_FILTER'
export const UPDATE_CASH_FLOW_CURRENCY = 'UPDATE_CASH_FLOW_CURRENCY'

// Quotations
export const GET_QUOTATIONS = 'GET_QUOTATIONS'
export const UPLOAD_QUOTATION = 'UPLOAD_QUOTATION'
export const UPDATE_QUOTATION = 'UPDATE_QUOTATION'
export const DELETE_QUOTATION = 'DELETE_QUOTATION'
export const UPLOAD_QUOTATION_COMMENT = 'UPLOAD_QUOTATION_COMMENT'

// Datanalisis
export const GET_DATANALISIS_PAGES = 'GET_DATANALISIS_PAGES'
export const UPLOAD_DATANALISIS_PAGE = 'UPLOAD_DATANALISIS_PAGE'
export const UPDATE_DATANALISISIS_PAGE = 'UPDATE_DATANALISIS_PAGE'
export const DELETE_DATANALISIS_PAGE = 'DELETE_DATANALISIS_PAGE'
export const SELECT_DATANALISIS_PAGE = 'SELECT_DATANALISIS_PAGE'
export const TOGGLE_INDICATOR_CREATOR = 'TOGGLE_INDICATOR_CREATOR'

export const UPLOAD_DATANALISIS_INDICATOR = 'UPLOAD_DATANALISIS_INDICATOR'
export const UPDATE_DATANALISISIS_INDICATOR = 'UPDATE_DATANALISIS_INDICATOR'
export const DELETE_DATANALISIS_INDICATOR = 'DELETE_DATANALISIS_INDICATOR'
export const UPDATE_DATANALISIS_INDICATOR_LAYOUT = 'UPDATE_DATANALISIS_INDICATOR_LAYOUT'

export const SUBSCRIBE_TO_PRO = 'SUBSCRIBE_TO_PRO'
export const UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION'
export const DELETE_SUBSCRIPTION = 'DELETE_SUBSCRIPTION'

export const UPDATE_PERMISSIONS = 'UPDATE_PERMISSIONS'
export const CLEAR_PERMISSIONS = 'CLEAR_PERMISSIONS'

// Ai Blocks
export const GET_AI_BLOCKS = 'GET_AI_BLOCKS'
export const SET_AI_BLOCKS = 'SET_AI_BLOCKS'
export const UPDATE_AI_BLOCKS = 'UPDATE_AI_BLOCKS'
export const DELETE_AI_BLOCKS = 'DELETE_AI_BLOCKS'
export const GENERATE_TEXT_AI_BLOCKS = 'GENERATE_TEXT_AI_BLOCKS'
