import { updateFreeTime } from 'actions/humanResources'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

const useDetailsModals = (freeTimeDetails) => {
  const dispatch = useDispatch()
  const [freeTimeStatus, setFreeTimeStatus] = useState()
  const [loading, setLoading] = useState(false)

  const handleOnChangeStatus = async (e) => {
    const value = e.target.value
    setLoading(true)
    setFreeTimeStatus(value)
    dispatch(
      await updateFreeTime({
        ...freeTimeDetails,
        status: value,
      })
    )
    setLoading(false)
  }

  useEffect(() => {
    setFreeTimeStatus(freeTimeDetails?.status)
  }, [freeTimeDetails?.status])

  return {
    freeTimeStatus,
    loading,
    setFreeTimeStatus,
    handleOnChangeStatus,
  }
}

export default useDetailsModals
