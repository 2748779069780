import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog'
import FormDialog from 'components/FormDialog/FormDialog'
import SnackbarMessage from 'components/SnackbarMessage/SnackbarMessage'
import PostModal from 'components/PostModal/PostModal'
import StepperModal from 'components/StepperModal/StepperModal'
import useModals from 'hooks/useModals'
import React, { useEffect, useState } from 'react'

const ModalsControler = () => {
  const {
    openStepperModal,
    stepperModalPages,
    openModal,
    modalFormConfig,
    snackbarMessage,
    confirmDialog,
    postModal,
    postModalInformation,
    stepperModalConfig,
  } = useModals()

  const [openSnackbar, setSnackbar] = useState(false)
  const [openFormModal, setOpenFormModal] = useState(false)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [openStepper, setOpenStepper] = useState(false)

  useEffect(() => {
    if (openModal !== openFormModal) {
      setOpenFormModal(openModal)
    }
  }, [openModal, openFormModal, setOpenFormModal])

  useEffect(() => {
    if (openModal !== openStepper) {
      setOpenStepper(openModal)
    }
  }, [openModal, openStepper, setOpenStepper])

  useEffect(() => {
    if (!openSnackbar && snackbarMessage?.message) {
      setSnackbar(snackbarMessage)
    }
  }, [openSnackbar, snackbarMessage])

  useEffect(() => {
    if (openConfirmDialog?.open !== confirmDialog?.open) {
      setOpenConfirmDialog(confirmDialog)
    }
  }, [openConfirmDialog, confirmDialog])

  return (
    <>
      <FormDialog open={openFormModal} setOpen={setOpenFormModal} modalFormConfig={modalFormConfig} />
      <SnackbarMessage
        open={openSnackbar}
        setOpen={setSnackbar}
        message={openSnackbar?.message}
        severity={openSnackbar?.severity}
      />
      <ConfirmDialog open={openConfirmDialog?.open} setOpen={setOpenConfirmDialog} dialogConfig={openConfirmDialog} />
      <PostModal open={postModal} postInformation={postModalInformation} />
      <StepperModal
        open={openStepperModal}
        setOpen={setOpenStepper}
        stepperModalConfig={stepperModalConfig}
        pages={stepperModalPages}
      />
    </>
  )
}

export default ModalsControler
