import { Button } from '@material-ui/core'
import { sendErrorMessage } from 'actions/modals'
import { subscribeToPro } from 'actions/users'
import classNames from 'classnames'
import CreditCardForm from 'components/CreditCardForm'
import Dialog from 'components/Dialog/Dialog'
import { createSubscription } from 'constants/subscriptions'
import { addMonths, addYears, format } from 'date-fns'
import { useHideSubscriptionModule } from 'hooks/useHideSubscriptionModule'
import useLocale from 'hooks/useLocale'
import useUsers from 'hooks/useUsers'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useImmer } from 'use-immer'
import { getCreditCardByNumber } from './creditCardUtils'
import styles from './SubscribeToProModal.module.scss'

const PAYMENT_METHOD_CONTENT = {
  header: {
    es: 'Suscribirse a PRO',
    en: 'Subscribe to PRO',
  },
  firstParagraph: {
    es: 'Tu pago se efectúa en cuanto se acabe <span>tu mes de prueba.</span>',
    en: 'Your payment is processed when <span>your trial period ends.</span>',
  },
  secondParagraph: {
    es: 'Cancela cuando quieras.',
    en: 'You can cancel anytime.',
  },
}

const PaymentMethod = ({ setSection }) => {
  const locale = useLocale()
  const { myUser } = useUsers() ?? {}
  const { subscription_payments } = myUser

  return (
    <>
      <h2 className={styles.header}>{PAYMENT_METHOD_CONTENT.header[locale]}</h2>

      <strong
        className={classNames(styles.paragraph, styles.first)}
        dangerouslySetInnerHTML={{
          __html: Boolean(!subscription_payments?.length)
            ? PAYMENT_METHOD_CONTENT.firstParagraph[locale]
            : locale === 'es'
            ? 'Tu suscripción se renovará automáticamente.'
            : 'Your subscription will renew automatically.',
        }}
      />

      <p className={classNames(styles.paragraph, styles.second)}>{PAYMENT_METHOD_CONTENT.secondParagraph[locale]}</p>
      <Button
        variant="outlined"
        classes={{ root: styles.buttonRoot, label: styles.buttonLabel }}
        onClick={() => setSection('pay-with-card')}
      >
        <i className="fas fa-credit-card" />
        <span className={styles.mainLabel}>{locale === 'es' ? 'Tarjeta de crédito o débito' : 'Credit or debit card'}</span>
        <i className="fas fa-chevron-right" />
      </Button>
    </>
  )
}

const PayWithCard = ({ setSection }) => {
  const locale = useLocale()
  const dispatch = useDispatch()
  const { myUser } = useUsers()
  const [form, setForm] = useImmer({
    card: '',
    name: '',
    date: '',
    cvv: '',
  })
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      const subscription = await createSubscription(myUser, form)

      if (!subscription || subscription?.status === 500 || !subscription?.result.success || subscription?.exception) {
        if (subscription?.errors) {
          throw new Error(subscription.errors[0])
        }
      }

      const subscriptionData = {
        idUser: myUser.idUser,
        subscriptionId: subscription?.subscriptionId,
        startDate: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        endDate: format(addMonths(addYears(new Date(), 1), 1), 'yyyy-MM-dd HH:mm:ss'),
        idPlan: 1,
        creditCardBrand: getCreditCardByNumber(form.card).brand,
        LAST_DIGITS: form.card.slice(-4),
      }

      dispatch(await subscribeToPro(subscriptionData))
      setLoading(false)
    } catch (error) {
      dispatch(
        sendErrorMessage(
          (locale === 'es' ? 'Error al procesar el pago' : 'Error processing payment') + (error?.message && ` - ${error.message}`)
        )
      )
      setLoading(false)

      return
    }

    setSection('payment-success')
  }

  return <CreditCardForm form={form} setForm={setForm} handleSubmit={handleSubmit} pendingSubmit={loading} />
}

export const PaymentSuccess = ({ handleClose, title, body }) => {
  const locale = useLocale()

  const defaultTitle = locale === 'es' ? '¡El método de pago se agregó de forma exitosa!' : 'Payment method added successfully!'
  const defaultBody = locale === 'es' ? 'Disfruta de tu suscripción al plan PRO.' : 'Enjoy your PRO subscription'

  return (
    <div className={styles.successWrapper} style={{ maxWidth: 'none' }}>
      <figure className={styles.checkWrapper}>
        <i className="fas fa-check" />
      </figure>

      <strong className={styles.successFirst}>{title ?? defaultTitle}</strong>
      <p className={styles.successSecond}>{body ?? defaultBody}</p>

      <Button variant="contained" color="primary" onClick={handleClose}>
        {locale === 'es' ? 'Cerrar' : 'Close'}
      </Button>
    </div>
  )
}

const SubscribeToProModal = ({ open, setOpen }) => {
  const [section, setSection] = useState('payment-method')
  const hideSubscriptionModule = useHideSubscriptionModule()

  const Component = useMemo(() => {
    switch (section) {
      case 'payment-method':
        return PaymentMethod
      case 'pay-with-card':
        return PayWithCard
      case 'payment-success':
        return PaymentSuccess
      default:
        return null
    }
  }, [section])

  const handleClose = () => {
    setOpen(false)
    if (section === 'payment-success') document.location.reload()
  }

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setSection('payment-method')
      }, 200)
    }
  }, [open])

  return (
    <Dialog
      open={open}
      handleCloseAction={handleClose}
      title={hideSubscriptionModule ? 'Error' : section === 'pay-with-card' && 'AGREGAR MÉTODO DE PAGO'}
      maxWidth={section === 'payment-success' ? 'xs' : 'sm'}
    >
      {hideSubscriptionModule ? <>404</> : Component && <Component setSection={setSection} handleClose={handleClose} />}
    </Dialog>
  )
}

export default SubscribeToProModal
