import { LinearProgress } from '@material-ui/core'
import { formatBytes } from 'helpers/bytes'
import useLocale from 'hooks/useLocale'
import { useAbout } from 'pages/GoogleDrivePage/useDriveApi'
import React from 'react'
import styles from './DataUsage.module.scss'

const DataUsage = () => {
  const locale = useLocale()
  const { data } = useAbout()
  const { usage, limit } = data?.storageQuota ?? {}
  const percentage = ((usage * 100) / limit).toFixed(2)

  return (
    <div>
      <LinearProgress
        variant="determinate"
        value={percentage}
        style={{
          height: 5,
          borderRadius: 12,
        }}
      />
      <p className={styles.helper}>
        {locale === 'es'
          ? `Uso de almacenamiento: ${formatBytes(+usage)} de ${formatBytes(+limit)}`
          : `Storage usage: ${formatBytes(+usage)} of ${formatBytes(+limit)}`}
      </p>
    </div>
  )
}

export default DataUsage
