import { Button } from '@material-ui/core'
import useLocale from 'hooks/useLocale'
import Page from 'layouts/Page/Page'
import React from 'react'
import { useNavigate } from 'react-router'
import styles from './NoCredentials.module.scss'

const NoCredentials = ({ isTokenError }) => {
  const locale = useLocale()
  const navigate = useNavigate()
  const title = locale === 'es' ? 'Error | Google Drive' : 'Error | Google Drive'

  const titles = {
    default: locale === 'es' ? 'No tienes credenciales creadas' : "You don't have credentials created",
    tokenError: locale === 'es' ? 'Token de acceso expirado' : 'Access token expired',
  }
  const errorMessage = {
    default:
      locale === 'es'
        ? 'Para acceder a este modulo, debes autorizar y linkear una cuenta de google.'
        : 'To use this module, you need to authorized and link a Google account.',
    tokenError:
      locale === 'es'
        ? 'Por los momentos, los token de acceso duran 7 días. Por favor, actualice su token en la página de configuraciones.'
        : 'For now, access tokens last 7 days. Please, update your token in the settings page.',
  }

  const handleGoToSettings = () => {
    navigate('/dashboard/config')
  }

  return (
    <Page title={title} read={47} write={48}>
      <section className={styles.wrapper}>
        <strong className={styles.errorCode}>403</strong>
        <h1 className={styles.title}>{isTokenError ? titles.tokenError : titles.default}</h1>

        <p className={styles.content}>{isTokenError ? errorMessage.tokenError : errorMessage.default}</p>

        <Button
          onClick={handleGoToSettings}
          classes={{ root: styles.button }}
          startIcon={<i className="fas fa-gears" />}
          variant="contained"
          color="primary"
        >
          {locale === 'es' ? 'Ir a configuración' : 'Go to settings'}
        </Button>
      </section>
    </Page>
  )
}

export default NoCredentials
