import { Tooltip } from '@material-ui/core'
import classNames from 'classnames'
import LoadingSection from 'components/LoadingSection'
import { useGoogleAuth } from 'helpers/google'
import useCompany from 'hooks/useCompany'
import useLocale from 'hooks/useLocale'
import Page from 'layouts/Page/Page'
import React, { useCallback, useMemo, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Link, useSearchParams } from 'react-router-dom'
import ContextMenu from './ContextMenu/ContextMenu'
import DriveFiles from './DriveFiles'
import DriveFolders from './DriveFolders'
import EmptyFolder from './EmptyFolder/EmptyFolder'
import EmptyTrashModal from './EmptyTrashModal/EmptyTrashModal'
import styles from './GeneralStyles.module.scss'
import GoogleDriveBreadcrumbs from './GoogleDriveBreadcrumbs/GoogleDriveBreadcrumbs'
import Header from './Header'
import MovePopup from './MovePopup'
import NoCredentials from './NoCredentials/NoCredentials'
import PermissionsModal from './PermissionsModal/PermissionsModal'
import SharingModal from './SharingModal/SharingModal'
import TrashFilesButton from './TrashFilesButton'
import { useContextMenu } from './hooks/useContextMenu'
import { useFilePermissions } from './hooks/useFilePermissions'
import { useFilePermissionsModal } from './hooks/useFilePermissionsModal'
import { useMoveModal } from './hooks/useMoveModal'
import { usePageData } from './hooks/usePageData'
import { useSharingModal } from './hooks/useSharingModal'
import { useGetFile } from './useDriveApi'

const GoogleDrivePage = () => {
  const [searchParams] = useSearchParams()
  let isTrashed = searchParams.get('trashed')
  let isShared = searchParams.get('shared')

  const locale = useLocale()
  const { authToken, isLoadingConfigs, tokenError } = useGoogleAuth()
  const {
    folderId,
    files: filesData,
    folders: foldersData,
    loading,
    isNotRootFolder,
    folderIsEmpty,
    isLoadingFiles,
    isLoadingFolders,
    sharedFiles,
    sharedFolders,
    trashedFiles,
    trashedFolders,
  } = usePageData()
  const { contextMenuAnchor, handleContextMenu, handleCloseContextMenu } = useContextMenu()
  const { data: currurentFolder } = useGetFile(folderId)

  const canCreateFolder = isShared ? currurentFolder?.capabilities?.canAddChildren : true

  const filesToTrash = [...(trashedFolders ?? []), ...(trashedFiles ?? [])]
  const trashedTitle = locale === 'es' ? 'Papelera' : 'Trash'
  const sharedTitle = locale === 'es' ? 'Compartidos conmigo' : 'Shared with me'
  const { isOwner } = useCompany()
  const { getFilePermissionProps } = useFilePermissions()
  const [trashedModal, setTrashedModal] = useState(false)

  const filterFiles = useCallback(
    (acc, item) => {
      const fileId = item.id
      const { isPrivateFile, isWriter, isReader } = getFilePermissionProps(fileId)

      if (isPrivateFile && !isWriter && !isReader && !isOwner) return acc

      acc.push(item)

      return acc
    },
    [getFilePermissionProps, isOwner]
  )

  const files = useMemo(() => {
    if (isTrashed) return trashedFiles
    if (isShared) return sharedFiles?.reduce(filterFiles, []) ?? []

    return filesData?.reduce(filterFiles, []) ?? []
  }, [filesData, isShared, isTrashed, sharedFiles, trashedFiles, filterFiles])

  const folders = useMemo(() => {
    if (isTrashed) return trashedFolders
    if (isShared) return sharedFolders?.reduce(filterFiles, []) ?? []

    return foldersData?.reduce(filterFiles, []) ?? []
  }, [foldersData, isShared, isTrashed, sharedFolders, trashedFolders, filterFiles])

  const {
    handleClose: handleCloseSharingModal,
    handleOpen: handleOpenSharingModal,
    open: openSharingModal,
    selectedResource: selectedToShare,
  } = useSharingModal(files, folders)

  const {
    handleClose: handleCloseMoveModal,
    handleOpen: handleOpenMoveModal,
    open: openMoveModal,
    selectedResource: selectedToMove,
  } = useMoveModal(files, folders)

  const {
    handleClose: handleCloseFilePermissionsModal,
    handleOpen: handleOpenFilePermissionsModal,
    open: openFilePermissionsModal,
    selectedResource: selectedToChangePermissions,
  } = useFilePermissionsModal(files, folders)

  if (!authToken && !isLoadingConfigs) return <NoCredentials />
  if (tokenError) return <NoCredentials isTokenError />

  return (
    <Page title="Google Drive" read={47} write={48}>
      {loading ? (
        <LoadingSection message="Cargando recursos" />
      ) : (
        <section className={styles.contentSection}>
          <DndProvider backend={HTML5Backend}>
            {isNotRootFolder && <GoogleDriveBreadcrumbs folderId={folderId} />}

            <Header
              hideNew={isTrashed || !canCreateFolder}
              isOutsideMyDrive={isTrashed || isShared}
              isFolderEmpty={folderIsEmpty}
            />

            {(isTrashed || isShared) && !folderId && (
              <section className={styles.sectionTitleWrapper}>
                <Tooltip title={locale === 'es' ? 'Volver' : 'Go back'} arrow placement="top">
                  <Link to={-1}>
                    <i className={classNames('fas fa-arrow-left', styles.arrowLeft)} />
                  </Link>
                </Tooltip>
                <h1 className={styles.sectionTitle}>{isTrashed ? trashedTitle : isShared ? sharedTitle : null}</h1>
              </section>
            )}

            {isTrashed && <TrashFilesButton onOpen={() => setTrashedModal(true)} disabled={!filesToTrash.length} />}

            <DriveFolders
              onContextMenu={handleContextMenu}
              folders={folders ?? []}
              isLoading={isLoadingFolders}
              isTrashed={isTrashed}
            />
            <DriveFiles onContextMenu={handleContextMenu} files={files ?? []} isLoading={isLoadingFiles} isTrashed={isTrashed} />
          </DndProvider>

          <SharingModal open={openSharingModal} onClose={handleCloseSharingModal} selected={selectedToShare} />

          <MovePopup
            open={openMoveModal}
            onClose={handleCloseMoveModal}
            selectedResource={selectedToMove}
            anchorEl={contextMenuAnchor}
            onCloseContextMenu={handleCloseContextMenu}
          />

          <PermissionsModal
            open={openFilePermissionsModal}
            onClose={handleCloseFilePermissionsModal}
            selected={selectedToChangePermissions}
          />

          <ContextMenu
            anchorEl={contextMenuAnchor}
            onClose={handleCloseContextMenu}
            onOpenSharingModal={handleOpenSharingModal}
            onOpenMoveModal={handleOpenMoveModal}
            onOpenFilePermissionsModal={handleOpenFilePermissionsModal}
            files={files ?? []}
            folders={folders ?? []}
            isTrashed={isTrashed}
            canCreateInShared={canCreateFolder}
          />

          {isTrashed && (
            <EmptyTrashModal open={trashedModal} onClose={() => setTrashedModal(false)} files={filesToTrash} isFolder />
          )}

          {folderIsEmpty && <EmptyFolder isTrashed={isTrashed} canCreateInShared={canCreateFolder} isShared={isShared} />}
        </section>
      )}
    </Page>
  )
}

export default GoogleDrivePage
