import ButtonIcon from 'components/common/ButtonIcon'
import useLocale from 'hooks/useLocale'
import { nanoid } from 'nanoid'
import React from 'react'
import { handleAddValue, handleChangeValue, handleDeleteValue } from '../../helpers/indicatorReducer'
import useDbModel from '../../hooks/useDbModel'
import styles from './TotalizationValues.module.scss'
import ValueItem from './ValueItem'

const TotalizationValues = ({ indicator, dispatchIndicator }) => {
  const locale = useLocale()
  const dbModel = useDbModel(indicator)
  const { totalizationFields } = dbModel
  const fieldsOptions = totalizationFields?.map((field) => ({ text: field[locale], value: field.key }))

  const onAddValue = () => {
    const defaultValue = {
      id: nanoid(),
      header: '',
      key: '',
      attribute: 'totalization',
    }

    dispatchIndicator(handleAddValue(defaultValue))
  }

  const onChangeValue = (value) => (e) => {
    dispatchIndicator(
      handleChangeValue({
        ...value,
        [e.target.name]: e.target.value,
      })
    )
  }

  const onDeleteValue = (value) => () => {
    dispatchIndicator(handleDeleteValue(value.id))
  }

  return (
    <div>
      <ButtonIcon
        fullWidth
        icon={<i className="fas fa-plus-circle" />}
        variant="text"
        color="primary"
        onClick={onAddValue}
        disabled={indicator?.values?.length === totalizationFields?.length || !totalizationFields}
        classes={{ root: styles.addButton }}
      >
        {indicator.values.length > 0
          ? locale === 'es'
            ? 'Agregar otro valor'
            : 'Add other value'
          : locale === 'es'
          ? 'Agregar valor'
          : 'Add value'}
      </ButtonIcon>

      {indicator.values.map((value, index) => (
        <ValueItem
          key={value.id}
          value={value}
          index={index}
          fieldsOptions={fieldsOptions}
          onChangeValue={onChangeValue(value)}
          onDeleteValue={onDeleteValue(value)}
        />
      ))}
    </div>
  )
}

export default TotalizationValues
