import { Avatar, FormControl, InputLabel, MenuItem, Select, Tooltip } from '@material-ui/core'
import classNames from 'classnames'
import Dialog from 'components/Dialog/Dialog'
import LoadingSnackbar from 'components/LoadingSnackbar/LoadingSnackbar'
import { DOWNLOAD_EMPLOYEE_FILE } from 'constants/apiRoutes'
import freeTimeTypes from 'constants/freeTimeTypes'
import { formatDate } from 'helpers/date'
import useLocale from 'hooks/useLocale'
import { nanoid } from 'nanoid'
import React from 'react'
import styles from './DetailsModal.module.scss'
import content, { STATUSES } from './content'
import useDetailsModal from './useDetailsModal'

const Item = ({ title, value }) => (
  <div className={styles.item}>
    <p className={styles.title}>{title}</p>
    <p className={styles.value}>{value}</p>
  </div>
)

const DownloadFile = ({ filename, id, title }) => {
  return (
    <div className={styles.fileItem}>
      <p>{filename}</p>
      <Tooltip title={title} onClick={() => window.open(`${DOWNLOAD_EMPLOYEE_FILE}?filename=${filename}&id=${id}`)}>
        <i className="fas fa-download" />
      </Tooltip>
    </div>
  )
}

const DetailsModal = ({ data = {}, open, handleClose, canWrite = true }) => {
  const locale = useLocale()
  const vacationType = (value) => freeTimeTypes.get(value)?.['text']?.[locale]

  const { freeTimeStatus, loading: loadingSnackbar, handleOnChangeStatus } = useDetailsModal(data)
  const { type, startTime, endTime, totalDays, fileName, idEmployee, notes } = data ?? {}
  const { name, description, date, applicable_employees } = data?.event ?? {}

  const _renderParticipants = (arr, isEmployee) => (
    <div className={classNames(styles.users, isEmployee && styles.employee)}>
      {arr?.map(({ employee }) => {
        const name = employee?.user ? `${employee?.user?.firstName} ${employee?.user?.lastName}` : employee?.name
        const letterIcon = name?.[0].toUpperCase()

        return (
          <div key={nanoid()} className={styles.participantWrapper}>
            <Avatar classes={{ root: styles.avatar }}>
              {employee?.user?.avatarPhoto ? <img src={employee?.user?.avatarPhoto} alt="" /> : letterIcon}
            </Avatar>
            <p>{name}</p>
          </div>
        )
      })}
    </div>
  )

  return (
    <>
      <Dialog
        open={open}
        handleCloseAction={handleClose}
        title={
          type === 'birthday'
            ? locale === 'es'
              ? 'Cumpleaños'
              : 'Birthday'
            : data?.event
            ? locale === 'es'
              ? 'Eventos y feriados'
              : 'Events and holidays'
            : `${content[locale]['freeTime']}`
        }
      >
        <main className={styles.container}>
          {type === 'birthday' ? (
            <>
              <div className={styles.twoRows}>
                <Item title={locale === 'es' ? 'Empleado' : 'Employee'} value={data.employee?.name} />
                <Item
                  title={locale === 'es' ? 'Fecha de nacimiento' : 'Birthday date'}
                  value={formatDate(data?.date, 'DD MMMM YYYY', true)}
                />
              </div>
            </>
          ) : data?.idEvent ? (
            <>
              <div className={styles.twoRows}>
                <Item title={locale === 'es' ? 'Nombre' : 'Name'} value={name} />
                <Item title={locale === 'es' ? 'Fecha' : 'Date'} value={formatDate(date, 'DD MMMM YYYY', true)} />
              </div>
              <Item title={locale === 'es' ? 'Descripción' : 'Description'} value={description} />
              {Boolean(data?.event?.startTime) && (
                <div className={styles.twoRows}>
                  <Item
                    title={locale === 'es' ? 'Hora de inicio' : 'Start time'}
                    value={data?.event?.startTime.split(':').slice(0, 2).join(':')}
                  />
                  <Item
                    title={locale === 'es' ? 'Hora de fin' : 'End time'}
                    value={data?.event?.endTime.split(':').slice(0, 2).join(':')}
                  />
                </div>
              )}
              {applicable_employees?.length && (
                <Item
                  title={locale === 'es' ? 'Participantes' : 'Participants'}
                  value={_renderParticipants(applicable_employees)}
                />
              )}
            </>
          ) : (
            <>
              <div className={styles.twoRows}>
                <Item title={content[locale]['startTime']} value={formatDate(startTime, 'DD MMMM YYYY', true)} />
                <Item title={content[locale]['endTime']} value={formatDate(endTime, 'DD MMMM YYYY', true)} />
              </div>
              <div className={styles.twoRows}>
                <Item
                  title={content[locale]['totalDays']}
                  value={totalDays === 0 ? (locale === 'es' ? 'Medio día' : 'Half day') : totalDays}
                />
                <Item title={content[locale]['type']} value={vacationType(type)} />
              </div>
              {notes && <Item title={content[locale]['notes']} value={notes} />}
              <div className={styles.twoRows}>
                {canWrite ? (
                  <div className={styles.item}>
                    <InputLabel className={styles.title}>{content[locale]['status']}</InputLabel>
                    <FormControl fullWidth>
                      <Select variant="outlined" value={freeTimeStatus} onChange={handleOnChangeStatus}>
                        {STATUSES?.map((i) => (
                          <MenuItem value={i.value} key={i.value}>
                            {i.text[locale]}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                ) : null}

                {data?.employee && (
                  <Item
                    title={locale === 'es' ? 'Participantes' : 'Participants'}
                    value={_renderParticipants([{ employee: data?.employee } ?? {}], true)}
                  />
                )}

                {fileName ? (
                  <Item
                    title={content[locale]['file']}
                    value={
                      <DownloadFile title={locale === 'es' ? 'Descargar' : 'Download'} filename={fileName} id={idEmployee} />
                    }
                  />
                ) : null}
              </div>
            </>
          )}
        </main>
      </Dialog>
      <LoadingSnackbar open={loadingSnackbar} />
    </>
  )
}

export default DetailsModal
