import {
  CLOSE_STEPPER_MODAL,
  CONFIRM_DIALOG,
  NEXT_PAGE_STEPPER_MODAL,
  OPEN_FORM_MODAL,
  OPEN_POST_MODAL,
  OPEN_STEPPER_MODAL,
  PREVIOUS_PAGE_STEPPER_MODAL,
  UPDATE_SNACKBAR_MESSAGE,
  UPDATE_STEPPER_MODAL_DATA,
} from 'constants/actionTypes'

import { INFO_SNACKBAR, SUCCESS_SNACKBAR } from 'constants/snackbarSeverities'

export const openModal = (modalFormConfig) => ({
  type: OPEN_FORM_MODAL,
  payload: { openModal: true, modalFormConfig },
})

export const closeModal = () => ({
  type: OPEN_FORM_MODAL,
  payload: { openModal: false, openStepperModal: false },
})

export const openStepperModal = (stepperModalPages, stepperModalConfig) => ({
  type: OPEN_STEPPER_MODAL,
  payload: { openStepperModal: true, stepperModalPages, stepperModalConfig },
})

export const nextPageStepperModal = () => ({
  type: NEXT_PAGE_STEPPER_MODAL,
})

export const previousPageStepperModal = () => ({
  type: PREVIOUS_PAGE_STEPPER_MODAL,
  payload: {},
})

export const updateStepperModalData = (stepperModalData) => ({
  type: UPDATE_STEPPER_MODAL_DATA,
  payload: { ...stepperModalData },
})

export const closeStepperModal = () => ({
  type: CLOSE_STEPPER_MODAL,
  payload: {},
})

export const sendErrorMessage = (message) => ({ type: UPDATE_SNACKBAR_MESSAGE, payload: { message } })

export const serverError = (locale) => ({
  type: UPDATE_SNACKBAR_MESSAGE,
  payload: {
    message: locale === 'es' ? 'Error en el servidor' : 'Server error',
  },
})

export const sendSuccessMessage = (message) => ({
  type: UPDATE_SNACKBAR_MESSAGE,
  payload: { message, severity: SUCCESS_SNACKBAR },
})

export const sendAlarmMessage = (message) => ({
  type: UPDATE_SNACKBAR_MESSAGE,
  payload: { message, severity: INFO_SNACKBAR },
})

export const resetSnackbarMessage = () => ({ type: UPDATE_SNACKBAR_MESSAGE, payload: {} })

/**
 * @param {Object} configuration - The modal config
 * @param {string} configuration.message - The message of the modal
 * @param {function} configuration.handlerAction - The function to run if confirmed
 *
 * @returns
 */
export const confirmDialog = (configuration) => ({ type: CONFIRM_DIALOG, payload: { open: true, ...configuration } })

export const closeConfirmDialog = () => ({ type: CONFIRM_DIALOG, payload: { open: false } })

export const openPostModal = (postModalInformation) => ({
  type: OPEN_POST_MODAL,
  payload: { postModal: true, postModalInformation },
})

export const closePostModal = () => ({ type: OPEN_POST_MODAL, payload: { postModal: false, postModalInformation: {} } })
