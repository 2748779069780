import { Button } from '@material-ui/core'
import { toggleIndicatorCreator, updateDatanalisisIndicator, uploadDatanalisisIndicator } from 'actions/datanalisis'
import { sendSuccessMessage } from 'actions/modals'
import Dialog from 'components/Dialog/Dialog'
import LoadingSection from 'components/LoadingSection'
import useDatanalisis from 'hooks/useDatanalisis'
import useFetch from 'hooks/useFetch'
import useLocale from 'hooks/useLocale'
import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import styles from './IndicatorPreviewer.module.scss'
import PreviewChart from './PreviewChart'
import PreviewTotal from './PreviewTotal'

const IndicatorPreviewer = ({ indicator = {}, setOpen, open, resetForm }) => {
  const locale = useLocale()
  const dispatch = useDispatch()
  const { selectedPageId, editIndicator } = useDatanalisis() ?? {}
  const { type } = indicator
  const { fetchData: uploadIndicator, isLoading: isCreatingIndicator } = useFetch(uploadDatanalisisIndicator)
  const { fetchData: updateIndicator, isLoading: isEditingIndicator } = useFetch(updateDatanalisisIndicator)

  const handleUpload = async () => {
    const action = editIndicator ? updateIndicator : uploadIndicator
    const response = await action({
      ...indicator,
      id: indicator?.idDatanalisisIndicator,
      idDatanalisisPage: selectedPageId,
      x: 0,
      y: 0,
      w: 2,
      h: 2,
      minH: 2,
    })

    const uploadMessage = locale === 'es' ? 'Indicador creado!' : 'Indicator created!'
    const editMessage = locale === 'es' ? 'Indicador actualizado!' : 'Indicator updated!'
    const message = editIndicator ? editMessage : uploadMessage

    dispatch(response)
    dispatch(sendSuccessMessage(message))
    dispatch(toggleIndicatorCreator(false))
    resetForm()
    handleClose()
  }

  const handleClose = () => {
    setOpen(null)
  }

  const Component = useMemo(() => {
    switch (type) {
      case 'totalization':
        return PreviewTotal
      case 'graphic':
        return PreviewChart
      default:
        return null
    }
  }, [type])

  return (
    <Dialog
      open={open}
      handleCloseAction={handleClose}
      title={locale === 'es' ? 'PREVISUALIZACIÓN DE INDICADOR' : 'Indicator Preview'}
      maxWidth="sm"
    >
      {Component && <Component indicator={indicator} />}

      <div className={styles.buttons}>
        {isCreatingIndicator || isEditingIndicator ? (
          <LoadingSection />
        ) : (
          <>
            <Button color="primary" variant="outlined" onClick={handleClose}>
              {locale === 'es' ? 'Cancelar' : 'Cancel'}
            </Button>
            <Button color="primary" variant="contained" onClick={handleUpload}>
              {locale === 'es' ? 'Confirmar' : 'Confirm'}
            </Button>
          </>
        )}
      </div>
    </Dialog>
  )
}

export default IndicatorPreviewer
