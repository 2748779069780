import { Menu, MenuItem as MenuItemOption } from '@material-ui/core'
import clsx from 'clsx'
import getHeaderItems from 'constants/headerMenu'
import useLocale from 'hooks/useLocale'
import usePermissions from 'hooks/usePermissions'
import useScreen from 'hooks/useScreen'
import useUsers from 'hooks/useUsers'
import React, { useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './headerMenu.module.scss'

const MenuItemWithOptions = ({ icon, name, options, baseUrl, currentPath, getPermissions }) => {
  const idMenu = `${name}-menu`
  const [anchorEl, setAnchorEl] = useState()

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const menuActive = () => {
    const index = options?.findIndex(() => currentPath.includes(baseUrl))

    return index !== -1
  }

  return (
    <>
      <span className={clsx(styles.menuItem, { [styles.active]: menuActive() })} aria-controls={idMenu} onClick={handleClick}>
        <i className={icon} />
        {name}
        <i className="fa fa-angle-down horizontal-icon" />
      </span>
      <Menu
        id={idMenu}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={styles.menuWithOptions}
      >
        {options
          ?.filter(({ hideIf }) => !hideIf?.({ permissions: getPermissions() }))
          .map(({ icon, name, link, preloadComponent, baseUrl }, index) => (
            <Link
              key={`menu-item-${index}`}
              className={clsx(styles.linkItem, { [styles.active]: currentPath.includes(baseUrl) })}
              to={link}
              onClick={handleClose}
              onMouseEnter={() => (typeof preloadComponent === 'function' ? preloadComponent() : null)}
            >
              <MenuItemOption>
                <i className={icon} />
                {name}
              </MenuItemOption>
            </Link>
          ))}
      </Menu>
    </>
  )
}

const MenuItem = ({ icon, name, link, baseUrl, currentPath, preloadComponent }) => {
  const isLinkActive = useMemo(() => {
    const pathIsDashboard = currentPath === '/dashboard'

    return pathIsDashboard ? link === '/dashboard' : currentPath.includes(baseUrl)
  }, [currentPath, baseUrl, link])

  return (
    <Link
      className={clsx(styles.menuItem, {
        [styles.active]: isLinkActive,
      })}
      to={link}
      onMouseEnter={() => (typeof preloadComponent === 'function' ? preloadComponent() : null)}
    >
      <i className={icon} />
      {name}
    </Link>
  )
}

const HeaderMenu = () => {
  const { currentPath } = useScreen()
  const { myUser } = useUsers()
  const { type } = myUser || {}
  const { getPermissions } = usePermissions()
  const locale = useLocale()

  return (
    <div className={styles.headerMenu}>
      <div className={styles.content}>
        {getHeaderItems(locale)
          .filter(({ hideIf }) => !hideIf?.({ type, permissions: getPermissions() }))
          .map((props, index) =>
            props?.link ? (
              <MenuItem key={`menu-item-${index}`} {...props} currentPath={currentPath} />
            ) : (
              <MenuItemWithOptions
                key={`menu-item-${index}`}
                {...props}
                currentPath={currentPath}
                getPermissions={getPermissions}
              />
            )
          )}
      </div>
    </div>
  )
}

export default HeaderMenu
