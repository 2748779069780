import { isMobileOrTablet } from 'helpers/breackpoints'
import { useEffect, useState } from 'react'

const useInstallApp = (breackpoint) => {
  const [eventPWA, setEventPWA] = useState(null)
  const [activePopup, setActivePopup] = useState(false)

  useEffect(() => {
    if (isMobileOrTablet(breackpoint)) {
      window.addEventListener('beforeinstallprompt', (event) => {
        event.preventDefault()

        const activePopup = !(
          (window.matchMedia && window.matchMedia('(display-mode: standalone)').matches) ||
          window.navigator.standalone === true
        )

        setEventPWA(event)
        setActivePopup(activePopup)
      })
    }
  }, [breackpoint])

  return { eventPWA, activePopup, setActivePopup }
}

export default useInstallApp
