import { Button, Checkbox, FormControlLabel, FormGroup } from '@material-ui/core'
import { updateEmployeesDeductions } from 'actions/humanResources'
import { openModal, sendErrorMessage, sendSuccessMessage } from 'actions/modals'
import Dialog from 'components/Dialog/Dialog'
import LoadingSection from 'components/LoadingSection'
import deductionsFormConfig from 'constants/deductionsFormConfig'
import useArray from 'hooks/useArray'
import useHumanResources from 'hooks/useHumanResources'
import useLocale from 'hooks/useLocale'
import useMoney from 'hooks/useMoney'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import styles from './DeductionsModal.module.scss'

const DeductionsModal = ({ open, setOpen, handleClose, employeeId }) => {
  const locale = useLocale()
  const { findItem } = useArray()
  const { formatMoney } = useMoney()
  const dispatch = useDispatch()
  const { employees } = useHumanResources()
  const employee = employees?.find((el) => el.idEmployee === employeeId)

  const [deductions, setDeductions] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const activeDeductions = useMemo(() => deductions.filter((d) => d['IS_ACTIVE']), [deductions])
  const total = useMemo(
    () =>
      activeDeductions.reduce((acc, item) => {
        if (item.deduction.fixed) {
          return acc + item.deduction.fixed
        } else {
          return acc + (item.deduction.percentage / 100) * employee?.baseSalary
        }
      }, 0),
    [activeDeductions, employee]
  )

  const handleDeductionChange = useCallback(
    (e) => {
      const id = +e.target.value
      const data = findItem(deductions, id, 'idDeduction')
      const newDeduction = { ...data, IS_ACTIVE: !data['IS_ACTIVE'] }
      setDeductions((prev) => prev.map((d) => (d.idDeduction === id ? newDeduction : d)))
    },
    [deductions, findItem]
  )

  const handleSubmit = useCallback(async () => {
    setIsLoading(true)

    try {
      dispatch(await updateEmployeesDeductions({ ...employee, deductions }))
      handleClose()
    } catch (error) {
      dispatch(sendErrorMessage(locale === 'es' ? 'Hubo un error en el servidor' : 'There was a server error'))
    }

    setIsLoading(false)
    dispatch(sendSuccessMessage(locale === 'es' ? 'Actualizado exitosamente' : 'Successfully updated'))
  }, [deductions, employee, locale, handleClose, dispatch])

  useEffect(() => {
    setDeductions(employee?.deductions?.filter((d) => d.deduction.currency === employee.currency) ?? [])
  }, [employee?.deductions, employee?.currency])

  return (
    <Dialog
      open={open}
      setOpen={setOpen}
      title={locale === 'es' ? 'Deducciones'.toUpperCase() : 'Deductions'.toUpperCase()}
      contentClassName={styles.contentContainer}
      handleCloseAction={handleClose}
    >
      <FormGroup classes={{ root: styles.checkboxesContainer }}>
        {!deductions.length ? (
          <div className={styles.emptyContainer}>
            <p className={styles.empty}>{locale === 'es' ? 'No hay deducciones disponibles...' : 'No available deductions'}</p>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => dispatch(openModal(deductionsFormConfig({ idCompany: employee?.idCompany })))}
            >
              {locale === 'es' ? 'Agregar deducción' : 'Add deduction'}
            </Button>
          </div>
        ) : (
          deductions.map(({ idDeduction, IS_ACTIVE, deduction }) => (
            <FormControlLabel
              key={idDeduction}
              classes={{ root: styles.formGroup }}
              control={<Checkbox color="primary" checked={!!IS_ACTIVE} value={idDeduction} onChange={handleDeductionChange} />}
              label={
                <article className={styles.checkboxLabel}>
                  <p className={styles.title}>{deduction.name}</p>
                  <p className={styles.helper}>
                    {locale === 'es' ? 'Tipo' : 'Type'}:{' '}
                    {deduction?.fixed ? (locale === 'es' ? 'Fijo' : 'Fixed') : locale === 'es' ? 'Porcentaje' : 'Percentage'} -{' '}
                    {deduction?.fixed ? formatMoney(deduction?.fixed, deduction.currency) : `${deduction?.percentage}%`}
                  </p>
                </article>
              }
            />
          ))
        )}
      </FormGroup>

      <p className={styles.totals}>
        {locale === 'es' ? 'Deducciones totales' : 'Total deductions'}: {formatMoney(total, employee?.currency)}
      </p>

      <footer>
        {isLoading ? (
          <LoadingSection />
        ) : (
          <Button variant="contained" color="primary" fullWidth onClick={handleSubmit}>
            {locale === 'es' ? 'Guardar' : 'Save'}
          </Button>
        )}
      </footer>
    </Dialog>
  )
}

export default DeductionsModal
