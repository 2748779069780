import countries from 'constants/countries'

export const STEPS_CONFIG = [
  {
    // Step 1: Company Info
    stepKey: 'step1',
    icon: 'fa-solid fa-building',
    modalFormConfig: (content, stepData) => ({
      buttonName: content.buttonName,
      form: [
        {
          name: 'companyName',
          label: content.form.companyName,
          isAutoFocus: true,
          defaultValue: stepData?.companyName ?? '',
        },
        {
          name: 'companyPhone',
          label: content.form.phone,
          type: 'number',
          defaultValue: stepData?.companyPhone ?? '',
        },
        {
          name: 'companyEmail',
          label: content.form.email,
          type: 'email',
          defaultValue: stepData?.companyEmail ?? '',
        },
        {
          name: 'country',
          label: content.form.country,
          midSize: true,
          options: countries.map((province) => ({ value: province.name_es, text: province.name_es })),
          defaultOptionSelected: stepData?.country ?? 'Costa Rica',
        },

        {
          name: 'province',
          label: content.form.province,
          midSize: true,
          defaultValue: stepData?.province ?? '',
        },
        { name: 'canton', label: 'Canton', midSize: true, defaultValue: stepData?.canton ?? '' },
        {
          name: 'district',
          label: content.form.district,
          midSize: true,
          defaultValue: stepData?.district ?? '',
        },
      ],
    }),
  },
  {
    // Step 2: User Info
    stepKey: 'step2',
    icon: 'fa-solid fa-user',
    modalFormConfig: (content, stepData) => ({
      buttonName: content.buttonName,
      form: [
        {
          name: 'userFirstName',
          label: content.form.userFirstName,
          isAutoFocus: true,
          midSize: true,
          defaultValue: stepData?.userFirstName ?? '',
        },
        {
          name: 'userLastName',
          label: content.form.userLastName,
          midSize: true,
          defaultValue: stepData?.userLastName ?? '',
        },
        {
          name: 'userPhone',
          label: content.form.phone,
          type: 'number',
          defaultValue: stepData?.userPhone ?? '',
        },
        {
          name: 'userEmail',
          label: content.form.email,
          type: 'email',
          defaultValue: stepData?.userEmail ?? '',
        },
      ],
    }),
  },
  {
    // Step 3: User password
    stepKey: 'step3',
    icon: 'fa-solid fa-user',
    modalFormConfig: (content) => ({
      buttonName: content.registryButtonName,
      form: [
        {
          name: 'password',
          label: content.form.password,
          isAutoFocus: true,
          type: 'password',
        },
        {
          name: 'repeatPassword',
          label: content.form.repeatPassword,
          type: 'password',
        },
      ],
    }),
  },
]
