import BackButton from 'components/BackButton'
import LoadingSection from 'components/LoadingSection'
import useHumanResources from 'hooks/useHumanResources'
import Page from 'layouts/Page/Page'
import useEmployees from 'pages/HumanResourcesPage/Tabs/Employees/useEmployees'
import React, { useEffect, useMemo } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router'
import EmployeeInformation from './EmployeeInformation'
import EmployeeTabs from './EmployeeTabs'

const EmployeePage = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { employees, isLoadingEmployees } = useHumanResources()
  const employee = useMemo(() => employees?.find((employee) => +employee.idEmployee === +id), [employees, id])
  const pageTitle = employee ? `Empleado - ${employee?.name}` : 'Empleado'

  useEmployees()

  useEffect(() => {
    if (!isLoadingEmployees && !employee) {
      navigate('/404')
    }
  }, [employee, isLoadingEmployees, navigate])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (!employee && !isLoadingEmployees) {
    return <Navigate to="/404" replace />
  }

  return (
    <Page title={pageTitle}>
      {isLoadingEmployees ? (
        <LoadingSection />
      ) : (
        <>
          <BackButton />
          <EmployeeInformation employee={employee} />
          <EmployeeTabs employee={employee} />
        </>
      )}
    </Page>
  )
}

export default EmployeePage
