import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  deleteDrivePermission as deleteDrivePermissionApi,
  deletePrivateFile,
  getDrivePermissions,
  getPrivateFiles,
  postDrivePermissions,
  postPrivateFile,
} from './api'

const fetchPrivateFiles = createAsyncThunk('GET_PRIVATE_FILES', getPrivateFiles)
const fetchUserPermissions = createAsyncThunk('GET_USER_PERMISSIONS', getDrivePermissions)
const saveDrivePermissions = createAsyncThunk('POST_DRIVE_PERMISSIONS', postDrivePermissions)

const initialState = {
  privateFiles: [],
  userPermissions: [],
  isSavingPermissions: false,
  isLoadingPermissions: true,
}

const driveSlice = createSlice({
  name: 'googleDrive',
  initialState,
  reducers: {
    makeDriveFilePrivate: (state, { payload }) => {
      state.privateFiles = [payload, ...state.privateFiles]
      postPrivateFile(payload)
    },
    makeDriveFilePublic: (state, { payload }) => {
      const { fileId, idCompany } = payload

      state.privateFiles = state.privateFiles.filter((file) => file.fileId !== fileId)
      deletePrivateFile({ fileId, idCompany })
    },
    addDrivePermission: (state, { payload }) => {
      const { id } = payload
      const permission = state.userPermissions.find((permission) => permission?.id === id)

      if (permission) {
        state.userPermissions = state.userPermissions.map((permission) => {
          if (permission.id === id) {
            return { ...permission, ...payload }
          }

          return permission
        })
      } else {
        state.userPermissions = [...state.userPermissions, { ...payload }]
      }
    },
    deleteDrivePermission: (state, { payload }) => {
      state.userPermissions = state.userPermissions.filter((permission) => permission.id !== payload)
      deleteDrivePermissionApi(payload)
    },
    updateDrivePermission: (state, { payload }) => {
      state.userPermissions = state.userPermissions.map((permission) => {
        if (permission.idUser === payload.idUser && permission.fileId === payload.fileId) {
          return payload
        }

        return permission
      })
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPrivateFiles.fulfilled, (state, { payload }) => {
        state.privateFiles = payload
        state.isLoadingPermissions = false
      })
      .addCase(fetchUserPermissions.fulfilled, (state, { payload }) => {
        state.userPermissions = payload
      })
      .addCase(saveDrivePermissions.pending, (state) => {
        state.isSavingPermissions = true
      })
      .addCase(saveDrivePermissions.fulfilled, (state, { payload }) => {
        state.userPermissions = [...state.userPermissions, payload]
        state.isSavingPermissions = false
      })
  },
})

export const { makeDriveFilePublic, makeDriveFilePrivate, deleteDrivePermission, addDrivePermission, updateDrivePermission } =
  driveSlice.actions
export { fetchPrivateFiles, fetchUserPermissions, saveDrivePermissions } // extraActions
export default driveSlice.reducer
