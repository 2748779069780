import {
  GET_COMPANY_PRODUCTS_COSTS,
  UPLOAD_PRODUCTS_COST,
  UPDATE_PRODUCTS_COST,
  DELETE_PRODUCTS_COST,
  GET_COMPANY_INDIRECT_COSTS,
  UPLOAD_INDIRECT_COST,
  UPDATE_INDIRECT_COST,
  DELETE_INDIRECT_COST,
} from 'constants/actionTypes'

import { PRODUCTS_COSTS_ROUTE, INDIRECT_COSTS_ROUTE } from 'constants/apiRoutes'
import { apiFindData, apiPostData, apiPutData, apiDeleteData } from 'helpers/api'

export const getCompanyProductsCosts = (idCompany) =>
  apiFindData(PRODUCTS_COSTS_ROUTE, { id: idCompany }, GET_COMPANY_PRODUCTS_COSTS)

export const postProductCost = (productCost) => apiPostData(PRODUCTS_COSTS_ROUTE, productCost, UPLOAD_PRODUCTS_COST)

export const updateProductCost = (productCost) =>
  apiPutData(PRODUCTS_COSTS_ROUTE, { ...productCost, id: productCost?.idProductCost }, UPDATE_PRODUCTS_COST)

export const deleteProductCost = (idProduct) => apiDeleteData(PRODUCTS_COSTS_ROUTE, { id: idProduct }, DELETE_PRODUCTS_COST)

export const getCompanyIndirectCosts = (idCompany) =>
  apiFindData(INDIRECT_COSTS_ROUTE, { id: idCompany }, GET_COMPANY_INDIRECT_COSTS)

export const postIndirectCost = (indirectCost) => apiPostData(INDIRECT_COSTS_ROUTE, indirectCost, UPLOAD_INDIRECT_COST)

export const updateIndirectCost = (indirectCost) =>
  apiPutData(INDIRECT_COSTS_ROUTE, { ...indirectCost, id: indirectCost?.idIndirectCost }, UPDATE_INDIRECT_COST)

export const deleteIndirectCost = (idIndirectCost) =>
  apiDeleteData(INDIRECT_COSTS_ROUTE, { id: idIndirectCost }, DELETE_INDIRECT_COST)
