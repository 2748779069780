import useLocale from 'hooks/useLocale'
import SelectWhite from 'pages/DatanalisisPage/components/SelectWhite'
import TextFieldWhite from 'pages/DatanalisisPage/components/TextFieldWhite'
import React from 'react'
import styles from './Option.module.scss'

const Option = ({ option, handleOnChange, fieldsOptions }) => {
  const locale = useLocale()

  return (
    <div className={styles.wrapper}>
      <header>
        <strong className={styles.title}>{option[locale]}</strong>
      </header>

      <SelectWhite
        field={{
          label: option[locale],
          name: 'key',
        }}
        options={fieldsOptions}
        onChange={handleOnChange}
        value={option.key}
      />

      <TextFieldWhite
        name="header"
        label={locale === 'es' ? 'Titulo' : 'Title'}
        value={option.header}
        onChange={handleOnChange}
      />
    </div>
  )
}

export default Option
