import { Button, TextField } from '@material-ui/core'
import { sendAlarmMessage } from 'actions/modals'
import Cleave from 'cleave.js/react'
import LoadingSection from 'components/LoadingSection'
import { getCreditCardByNumber } from 'components/SubscribeToProModal/creditCardUtils'
import { isPast } from 'date-fns'
import useLocale from 'hooks/useLocale'
import React from 'react'
import { useDispatch } from 'react-redux'
import styles from './CreditCardForm.module.scss'

const PAY_WITH_CARD_FORM = {
  card: {
    es: 'Tarjeta de crédito o débito',
    en: 'Credit or debit card',
  },
  name: {
    es: 'Nombre del titular',
    en: 'Name on card',
  },
  date: {
    es: 'Fecha de vencimiento (MM/AA)',
    en: 'Expiration date (MM/YY)',
  },
  cvv: {
    es: 'Código de seguridad (CVC)',
    en: 'Security code (CVC)',
  },
  button: {
    es: 'Agregar',
    en: 'Add',
  },
}

const CreditCardForm = ({ handleSubmit, form, setForm, pendingSubmit, isUpdate }) => {
  const locale = useLocale()
  const dispatch = useDispatch()

  const handleChange = (event) => {
    const { name, value } = event.target
    setForm({ ...form, [name]: value })
  }

  const validateForm = (form) => {
    const { card, name, date, cvv } = form

    if (!card || !name || !date || !cvv) {
      const message = locale === 'es' ? 'Todos los campos son obligatorios' : 'All fields are required'
      dispatch(sendAlarmMessage(message))

      return false
    }

    if (card.length < 13) {
      const message = locale === 'es' ? 'El número de tarjeta es inválido' : 'Card number is invalid'
      dispatch(sendAlarmMessage(message))

      return false
    }

    const [month, year] = date.split('/')
    const expirationDate = new Date('20' + year, +month - 1, 1)

    if (isPast(expirationDate)) {
      const message = locale === 'es' ? 'La tarjeta ha expirado' : 'Card has expired'
      dispatch(sendAlarmMessage(message))

      return false
    }

    if (cvv.length < 3) {
      const message = locale === 'es' ? 'El código de seguridad es inválido' : 'Security code is invalid'
      dispatch(sendAlarmMessage(message))

      return false
    }

    return true
  }

  return (
    <form
      className={styles.creditCardWrapper}
      onSubmit={(e) => {
        e.preventDefault()

        if (!validateForm(form)) return

        handleSubmit(e)
      }}
    >
      <TextField
        fullWidth
        required
        id="cc-number"
        name="card"
        variant="outlined"
        InputProps={{
          inputComponent: Cleave,
          inputProps: {
            placeholder: '•••• •••• •••• ••••',
            options: { creditCard: true },
          },
          endAdornment: getCreditCardByNumber(form.card)?.icon,
        }}
        label={PAY_WITH_CARD_FORM.card[locale]}
        onChange={handleChange}
        value={form.card}
      />
      <TextField
        fullWidth
        required
        id="cc-name"
        name="name"
        variant="outlined"
        InputProps={{
          placeholder: 'JHON DOE',
        }}
        label={PAY_WITH_CARD_FORM.name[locale]}
        onChange={handleChange}
        value={form.name}
      />

      <TextField
        required
        name="date"
        id="cc-date"
        variant="outlined"
        InputProps={{
          inputComponent: Cleave,
          inputProps: {
            placeholder: 'MM/YY',
            options: { date: true, datePattern: ['m', 'y'] },
          },
        }}
        label={PAY_WITH_CARD_FORM.date[locale]}
        onChange={handleChange}
        value={form.date}
      />
      <TextField
        required
        name="cvv"
        id="cc-cvv"
        variant="outlined"
        InputProps={{
          inputComponent: Cleave,
          inputProps: {
            placeholder: '123',
            options: { blocks: [3], numericOnly: true },
          },
        }}
        label={PAY_WITH_CARD_FORM.cvv[locale]}
        onChange={handleChange}
        value={form.cvv}
      />

      {pendingSubmit ? (
        <LoadingSection />
      ) : (
        <Button type="submit" color="primary" variant="contained">
          {isUpdate ? (locale === 'es' ? 'Actualizar' : 'Update') : PAY_WITH_CARD_FORM.button[locale]}
        </Button>
      )}
    </form>
  )
}

export default CreditCardForm
