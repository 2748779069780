import { useToggle } from '@mantine/hooks'
import { Button, Checkbox, FormControlLabel, FormGroup, TextField } from '@material-ui/core'
import { DatePicker, TimePicker } from '@material-ui/pickers'
import { updateCalendarAction } from 'actions/calendarActions'
import { sendAlarmMessage, sendSuccessMessage } from 'actions/modals'
import LoadingSection from 'components/LoadingSection'
import Select from 'components/common/Select/Select'
import { format, isToday } from 'date-fns'
import { formatDate } from 'helpers/date'
import useCalendarActions from 'hooks/useCalendarActions'
import useLocale from 'hooks/useLocale'
import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import styles from './ScheduleModalConfiguration.module.scss'

const ScheduleModalConfiguration = ({ setForm, form, onNext, handleClose, idMarketingCampaign = null }) => {
  const locale = useLocale()
  const dispatch = useDispatch()
  const { general } = form
  const { selectedCalendarAction } = useCalendarActions()

  const [timeError, toggleTimeError] = useToggle()
  const [pendingUpdate, setPendingUpdate] = useState(false)

  const CONTENT_TYPE_OPTIONS = useMemo(() => {
    const types = [
      {
        text: locale === 'es' ? 'Correo Masivo' : 'Mass email',
        value: 1,
      },
      {
        text: locale === 'es' ? 'Publicación' : 'Post',
        value: 2,
      },
      {
        text: locale === 'es' ? 'Tarea de marketing' : 'Marketing task',
        value: 3,
      },
      {
        text: locale === 'es' ? 'Evento' : 'Event',
        value: 4,
      },
    ]

    return idMarketingCampaign ? types : types.filter((type) => type.value !== 3)
  }, [locale, idMarketingCampaign])

  const handleTimeChange = useCallback(
    (date) => {
      const currentDate = general?.originalDate ?? general?.startDate
      const today = new Date()
      const [hours, minutes] = formatDate(date, 'HH:mm', true).split(':')
      const [todayHours, todayMinutes] = formatDate(today, 'HH:mm', true).split(':')

      if (isToday(new Date(currentDate)) && (hours < todayHours || (hours === todayHours && minutes < todayMinutes))) {
        dispatch(sendAlarmMessage(locale === 'es' ? 'Escoja una hora en el futuro' : 'Pick a time in the future'))

        return toggleTimeError(true)
      }

      toggleTimeError(false)
      setForm((draft) => {
        draft.general.startDate = format(new Date(currentDate), 'yyyy/MM/dd HH:mm:00')

        draft.general.time = date
        draft.event.startTime = formatDate(date, 'HH:mm', true)
        draft.event.endTime = formatDate(date, 'HH:mm', true)
      })
    },
    [setForm, dispatch, locale, toggleTimeError, general]
  )

  const handleDateChange = useCallback(
    (e, key) => {
      setForm((draft) => {
        draft.general[key] = formatDate(e, undefined, true)

        if (draft.event && key === 'startDate') {
          draft.event.date = formatDate(e, 'YYYY-MM-DD 00:00:00', true)
        }
      })
    },
    [setForm]
  )

  const handleChangeRecurring = (e) => {
    setForm((draft) => {
      draft.general.recurring = e.target.value
      draft.general.recurringType = 1
      draft.general.notInfinite = false
    })
  }

  const handleChange = (e, newValue) => {
    setForm((draft) => {
      draft.general[e.target.name] = newValue
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (selectedCalendarAction) {
      setPendingUpdate(true)
      dispatch(await updateCalendarAction({ ...form.general, event: form?.event }))
      dispatch(sendSuccessMessage(locale === 'es' ? 'Actualizado' : 'Updated'))
      setPendingUpdate(false)
      handleClose()

      return
    }

    onNext()
  }

  return (
    <form onSubmit={handleSubmit} className={styles.container}>
      <h2 className={styles.title}>{locale === 'es' ? 'Ajustes generales' : 'General configuration'}</h2>

      <TextField
        fullWidth
        required
        name="name"
        label={locale === 'es' ? 'Título' : 'Title'}
        placeholder={locale === 'es' ? 'Título del post/email' : 'Title for the post/mail'}
        variant="outlined"
        value={general?.name}
        onChange={(e) => handleChange(e, e.target.value)}
      />

      <Select
        label={locale === 'es' ? 'Tipo de contenido' : 'Content type'}
        variant="outlined"
        name="type"
        value={general?.type}
        onChange={(e) => handleChange(e, +e.target.value)}
        options={CONTENT_TYPE_OPTIONS}
        disabled={selectedCalendarAction}
      />

      <Select
        variant="outlined"
        name="recurring"
        label={locale === 'es' ? 'Recurrencia' : 'Recurrent'}
        value={Boolean(general?.recurring)}
        onChange={handleChangeRecurring}
        options={[
          { text: locale === 'es' ? 'Una sola vez' : 'One time', value: false },
          { text: locale === 'es' ? 'Repetir constantemente' : 'Repeat', value: true },
        ]}
        disabled={selectedCalendarAction}
      />

      <Select
        disabled={!general?.recurring}
        variant="outlined"
        name="recurringType"
        label={locale === 'es' ? 'Tipo de recurrencia' : 'Recurring type'}
        value={general?.recurringType}
        onChange={(e) => handleChange(e, e.target.value)}
        options={[
          { text: locale === 'es' ? 'Diario' : 'Daily', value: 1 },
          { text: locale === 'es' ? 'Semanal' : 'Weekly', value: 2 },
          { text: locale === 'es' ? 'Mensual' : 'Monthly', value: 3 },
        ]}
      />

      <TimePicker
        required
        value={general?.time}
        label={locale === 'es' ? 'Hora' : 'Time'}
        onChange={handleTimeChange}
        minutesStep={5}
        variant={'inline'}
        disabled={general?.idCalendarEvent}
      />

      <DatePicker
        fullWidth
        disabled={!selectedCalendarAction}
        format="dd MMMM, yyyy"
        label={locale === 'es' ? 'Fecha de inicio' : 'Start date'}
        variant="inline"
        value={formatDate(general?.startDate, undefined, true)}
        onChange={(e) => handleDateChange(e, 'startDate')}
        required={true}
        minDate={selectedCalendarAction?.originalDate ?? selectedCalendarAction?.startDate ?? new Date()}
      />

      <FormGroup row>
        <FormControlLabel
          size="small"
          name="notInfinite"
          label={locale === 'es' ? 'Agregar fecha de finalización' : 'Add end date'}
          control={<Checkbox color="primary" disabled={!general?.recurring} />}
          checked={general?.notInfinite}
          onChange={(e) => {
            setForm((draft) => {
              draft.general.notInfinite = e.target.checked

              if (e.target.checked) {
                draft.general.endDate = draft.general.startDate
              } else {
                draft.general.endDate = null
              }
            })
          }}
        />
      </FormGroup>

      <DatePicker
        fullWidth
        disabled={!(general?.recurring && general?.notInfinite)}
        format="dd MMMM, yyyy"
        label={locale === 'es' ? 'Fecha de finalización' : 'End date'}
        variant="inline"
        value={general?.endDate ? formatDate(general?.endDate, undefined, true) : formatDate(general?.startDate, undefined, true)}
        onChange={(e) => handleDateChange(e, 'endDate')}
        required={true}
        minDate={general?.startDate}
      />

      {pendingUpdate ? (
        <LoadingSection />
      ) : (
        <Button className={styles.button} type="submit" disabled={timeError}>
          {selectedCalendarAction ? (locale === 'es' ? 'Guardar' : 'Save') : locale === 'es' ? 'Siguiente' : 'Next'}
        </Button>
      )}
    </form>
  )
}

export default ScheduleModalConfiguration
