import { Button } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import styles from './oopsError.module.scss'

const OopsError = ({ title, description, link, buttonName }) => {
  return (
    <div className={styles.oopsError}>
      <h1>Oops! 😔</h1>
      <h3>{title}</h3>
      <span className={styles.description}>{description}</span>
      {link && (
        <Link to={link}>
          <Button color="primary" variant="contained">
            {buttonName}
          </Button>
        </Link>
      )}
    </div>
  )
}

export default OopsError
