import classNames from 'classnames'
import { LOGIN_PAGE_URL } from 'constants/urls'
import Page from 'layouts/Page/Page'
import React, { useEffect, useState } from 'react'
import { Outlet, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import SearchModal from '../SearchModal'
import styles from './HelpLayout.module.scss'

const HelpLayout = () => {
  const { section } = useParams()

  const title = section ? `${section} - Ayuda` : 'Ayuda'
  const [open, setOpen] = useState(false)

  useEffect(() => {
    window.addEventListener('keydown', (e) => {
      if (e.ctrlKey && (e.key === 'k' || e.key === 'K')) {
        e.preventDefault()
        setOpen(true)
      }
    })
  }, [])

  return (
    <Page title={title} containerClassName={styles.page}>
      <header className={styles.header}>
        <div className={classNames(styles.maxWidthWrapper, styles.flexContainer)}>
          <Link to={LOGIN_PAGE_URL}>
            <img src="/logo.svg" alt="CEDECC Digital Pymes" width={144} />
          </Link>

          <SearchModal open={open} onOpen={() => setOpen(true)} onClose={() => setOpen(false)} />
        </div>
      </header>

      <main className={styles.maxWidthWrapper}>
        <Outlet />
      </main>
    </Page>
  )
}

export default HelpLayout
