const content = {
  es: {
    freeTime: 'Tiempo libre',
    startTime: 'Fecha de inicio',
    endTime: 'Fecha de fin',
    totalDays: 'Total de dias',
    type: 'Tipo de tiempo libre',
    status: 'Estado',
    file: 'Archivo asociado',
    notes: 'Notas',
  },
  en: {
    freeTime: 'Free time',
    startTime: 'Start date',
    endTime: 'End date',
    totalDays: 'Total days',
    type: 'Free time type',
    status: 'Status',
    file: 'Associated file',
    notes: 'Notes',
  },
}

export const STATUSES = [
  {
    text: {
      es: 'Pendiente',
      en: 'Pending',
    },
    value: 1,
  },
  {
    text: {
      es: 'Aprobado',
      en: 'Approved',
    },
    value: 2,
  },
  {
    text: {
      es: 'Rechazado',
      en: 'Rejected',
    },
    value: 3,
  },
]

export default content
