import { getRandomColor } from 'constants/colors'
import React from 'react'
import { Cell, Pie, PieChart as RechartsPieChart, ResponsiveContainer, Tooltip } from 'recharts'
import { reduceChartData } from './helpers'

const PieChart = ({ values, chartData }) => {
  const value = values?.find((el) => el.attribute === 'value') ?? {}
  const { key: valueKey } = value
  const data = reduceChartData(chartData, valueKey)

  return (
    <ResponsiveContainer>
      <RechartsPieChart>
        <Pie label isAnimationActive={false} data={data} dataKey="count" nameKey="name" cx="50%" cy="50%">
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={getRandomColor(index)} />
          ))}
        </Pie>
        <Tooltip />
      </RechartsPieChart>
    </ResponsiveContainer>
  )
}

export default PieChart
