import React from 'react'
import styles from './maintenancePage.module.scss'

const WORKING_GIF_EXT_URL = 'https://cdn.dribbble.com/users/966681/screenshots/2896143/media/a9fd79b1391ff77fccecb124c5430eb1.gif'
const LOGO_IMG = './horizontal-logo.svg'

const MaintenancePage = () => {
  return (
    <div className={styles.maintenancePage}>
      <img className={styles.workingImage} src={WORKING_GIF_EXT_URL} alt="" />
      <img className={styles.logoImage} src={LOGO_IMG} alt="" />
      <h1> SITIO EN MANTENIMIENTO</h1>
      <p>
        Actualmente este sitio se encuentra en procesos de mantenimiento, estamos trabajando para que pronto vuelva a estar
        disponible, muchas gracias.
      </p>
    </div>
  )
}

export default MaintenancePage
