import useCalendar from 'hooks/useCalendar'
import useLocale from 'hooks/useLocale'
import { nanoid } from 'nanoid'
import MainCalendar from 'pages/CalendarPage/Calendar'
import DetailsModal from 'pages/HumanResourcesPage/Tabs/Employees/FreeTimeModal/Requests/DetailsModal/DetailsModal'
import React, { useState } from 'react'
import Section from '../Section'
import DayItem from './DayItem'
import styles from './styles.module.scss'

const Legend = ({ title, helper, color }) => (
  <div className={styles.legendContainer}>
    <figure className={styles.color} style={{ '--color': color }} />
    <article className={styles.textContainer}>
      <p className={styles.title}>{title}</p>
      <p className={styles.helper}>{helper}</p>
    </article>
  </div>
)

const Calendar = ({ employee }) => {
  const locale = useLocale()
  const { year, month, handleNextMonth, handlePreviousMonth, handleClickToday } = useCalendar()
  const { free_time: freeTime, events = [], birthday } = employee

  const calendarData = freeTime
    ?.filter((el) => el.status === 2)
    .concat(events?.filter((el) => !!el.applicable))
    .concat({
      date: birthday,
      type: 'birthday',
      employee,
    })

  const [selectedDetails, setSelectedDetails] = useState(null)

  const handleOpenDetails = (obj) => {
    setSelectedDetails(obj)
  }

  return (
    <Section title={locale === 'es' ? 'Calendario' : 'Calendar'}>
      <MainCalendar
        currentYear={year}
        currentMonth={month}
        handleNextMonth={handleNextMonth}
        handlePreviousMonth={handlePreviousMonth}
        handleTodayClick={handleClickToday}
        className={styles.calendar}
      >
        {({ basicStyles, day, isInactiveDay }) => (
          <DayItem
            key={nanoid()}
            data={calendarData}
            date={isInactiveDay ? null : `${year}-${month}-${day}`}
            inactive={isInactiveDay}
            day={day}
            classNames={basicStyles}
            openDetails={handleOpenDetails}
          />
        )}
      </MainCalendar>

      <DetailsModal
        open={Boolean(selectedDetails)}
        data={selectedDetails}
        handleClose={() => {
          setSelectedDetails(null)
        }}
        canWrite={false}
      />

      <footer className={styles.legends}>
        <Legend
          title={locale === 'es' ? 'Tiempo libre' : 'Free time'}
          helper={locale === 'es' ? '(Vacaciones, atención médica, etc)' : '(Vacations, medic atention, etc)'}
          color="#3A681F"
        />
        <Legend title={locale === 'es' ? 'Días feriados' : 'Holidays'} color="#E5DF40" />
        <Legend title={locale === 'es' ? 'Cumpleaños' : 'Birthday'} color="#FA6A6A" />
      </footer>
    </Section>
  )
}

export default Calendar
