import { PERSONAL_FINANCES_URL } from 'constants/urls'

export const getSidebarRoutes = (locale) => {
  return [
    {
      icon: 'fa-solid fa-address-card',
      name: locale === 'es' ? 'Resumen' : 'Resume',
      link: `/${PERSONAL_FINANCES_URL}/resume`,
    },
    {
      icon: 'fa-solid fa-square-caret-up',
      name: locale === 'es' ? 'Ingresos' : 'Incomes',
      link: `/${PERSONAL_FINANCES_URL}/incomes`,
    },
    {
      icon: 'fa-solid fa-square-caret-down',
      name: locale === 'es' ? 'Gastos' : 'Expenses',
      link: `/${PERSONAL_FINANCES_URL}/expenses`,
    },
    {
      icon: 'fa-solid fa-piggy-bank',
      name: locale === 'es' ? 'Ahorros' : 'Savings',
      link: `/${PERSONAL_FINANCES_URL}/savings`,
    },
    {
      icon: 'fa-solid fa-credit-card',
      name: locale === 'es' ? 'Créditos' : 'Credits',
      link: `/${PERSONAL_FINANCES_URL}/credits`,
    },
    {
      icon: 'fa-solid fa-wallet',
      name: locale === 'es' ? 'Tarjetas' : 'Cards',
      link: `/${PERSONAL_FINANCES_URL}/cards`,
    },
  ]
}
