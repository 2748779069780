import { Button, Menu, MenuItem } from '@material-ui/core'
import { DASHBOARD_PAGE_URL } from 'constants/urls'
import useLocale from 'hooks/useLocale'
import { getSidebarRoutes } from 'layouts/PersonalFinancesLayout/constants/sidebarRoutes'
import { useCallback } from 'react'
import { useState } from 'react'
import MenuLink from '../MenuLink'
import styles from './MobileMenu.module.scss'

const MobileMenu = () => {
  const locale = useLocale()
  const routes = getSidebarRoutes(locale)

  const [anchorEl, setAnchorEl] = useState(null)

  const handleOpenMenu = useCallback((event) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  return (
    <div className={styles.mobileMenu}>
      <Button classes={{ root: styles.button }} onClick={handleOpenMenu} color="primary" variant="contained">
        <i className={`fa-solid fa-bars ${styles.icon}`} />
      </Button>

      <Menu id="simple-menu" classes={{ paper: styles.paper }} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleClose}>
          <MenuLink
            route={{
              link: `/${DASHBOARD_PAGE_URL}`,
              icon: 'fa-solid fa-arrow-left',
              name: locale === 'es' ? 'Ir al inicio' : 'Back to home',
            }}
          />
        </MenuItem>

        {routes.map((_route) => (
          <MenuItem onClick={handleClose}>
            <MenuLink route={_route} />
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export default MobileMenu
