import { getRandomColor } from 'constants/colors'
import React from 'react'
import { Bar, BarChart, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { reduceChartData } from './helpers'

const BarsChart = ({ values, chartData }) => {
  const xAxis = values?.find((el) => el.attribute === 'xAxis')
  const xAxisKey = xAxis.key

  const data = reduceChartData(chartData, xAxisKey)

  return (
    <ResponsiveContainer>
      <BarChart data={data}>
        <XAxis dataKey="name" />
        <YAxis dataKey="count" />
        <Tooltip />
        <Bar isAnimationActive={false} dataKey="count">
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={getRandomColor(index)} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}

export default BarsChart
