import {
  DELETE_PERSONAL_FINANCE_CARD,
  DELETE_PERSONAL_FINANCE_CREDIT,
  DELETE_PERSONAL_FINANCE_SAVING,
  DELETE_PERSONAL_FINANCE_TRANSACTION,
  GET_PERSONAL_FINANCE_CARDS,
  GET_PERSONAL_FINANCE_CREDITS,
  GET_PERSONAL_FINANCE_SAVINGS,
  GET_PERSONAL_FINANCE_TRANSACTIONS,
  UPDATE_PERSONAL_FINANCE_CARD,
  UPDATE_PERSONAL_FINANCE_CREDIT,
  UPDATE_PERSONAL_FINANCE_SAVING_AMOUNT,
  UPDATE_PERSONAL_FINANCE_TRANSACTION,
  UPLOAD_PERSONAL_FINANCE_CARD,
  UPLOAD_PERSONAL_FINANCE_CREDIT,
  UPLOAD_PERSONAL_FINANCE_SAVING,
  UPLOAD_PERSONAL_FINANCE_TRANSACTION,
} from 'constants/actionTypes'
import {
  PERSONAL_FINANCES_CARDS_ROUTE,
  PERSONAL_FINANCES_CREDITS_ROUTE,
  PERSONAL_FINANCES_SAVINGS_CHANGE_AMOUNT_ROUTE,
  PERSONAL_FINANCES_SAVINGS_ROUTE,
  PERSONAL_FINANCES_TRANSACTIONS_ROUTE,
} from 'constants/apiRoutes'
import { apiFindData, apiPostData, apiPutData, apiDeleteData } from 'helpers/api'

export const getPersonalFinanceTransactions = (idUser) =>
  apiFindData(PERSONAL_FINANCES_TRANSACTIONS_ROUTE, { id: idUser }, GET_PERSONAL_FINANCE_TRANSACTIONS)

export const postPersonalFinanceTransaction = (personalFinanceTransaction) =>
  apiPostData(PERSONAL_FINANCES_TRANSACTIONS_ROUTE, personalFinanceTransaction, UPLOAD_PERSONAL_FINANCE_TRANSACTION)

export const updatePersonalFinanceTransaction = (personalFinanceTransaction) =>
  apiPutData(
    PERSONAL_FINANCES_TRANSACTIONS_ROUTE,
    { ...personalFinanceTransaction, id: personalFinanceTransaction?.idPersonalFinanceTransaction },
    UPDATE_PERSONAL_FINANCE_TRANSACTION
  )

export const deletePersonalFinanceTransaction = (idPersonalFinanceTransaction) =>
  apiDeleteData(PERSONAL_FINANCES_TRANSACTIONS_ROUTE, { id: idPersonalFinanceTransaction }, DELETE_PERSONAL_FINANCE_TRANSACTION)

export const getPersonalFinanceSavings = (idUser) =>
  apiFindData(PERSONAL_FINANCES_SAVINGS_ROUTE, { id: idUser }, GET_PERSONAL_FINANCE_SAVINGS)

export const postPersonalFinanceSaving = (personalFinanceSaving) =>
  apiPostData(PERSONAL_FINANCES_SAVINGS_ROUTE, personalFinanceSaving, UPLOAD_PERSONAL_FINANCE_SAVING)

export const updatePersonalFinanceSavingAmount = (newPersonalFinanceAmount) =>
  apiPutData(
    PERSONAL_FINANCES_SAVINGS_CHANGE_AMOUNT_ROUTE,
    { ...newPersonalFinanceAmount, id: newPersonalFinanceAmount.idPersonalFinanceSaving },
    UPDATE_PERSONAL_FINANCE_SAVING_AMOUNT
  )

export const deletePersonalFinanceSaving = (idPersonalFinanceSaving) =>
  apiDeleteData(PERSONAL_FINANCES_SAVINGS_ROUTE, { id: idPersonalFinanceSaving }, DELETE_PERSONAL_FINANCE_SAVING)

export const getPersonalFinanceCredits = (idUser) =>
  apiFindData(PERSONAL_FINANCES_CREDITS_ROUTE, { id: idUser }, GET_PERSONAL_FINANCE_CREDITS)

export const postPersonalFinanceCredit = (personalFinanceCredit) =>
  apiPostData(PERSONAL_FINANCES_CREDITS_ROUTE, personalFinanceCredit, UPLOAD_PERSONAL_FINANCE_CREDIT)

export const updatePersonalFinanceCredit = (personalFinanceCredit) =>
  apiPutData(
    PERSONAL_FINANCES_CREDITS_ROUTE,
    { ...personalFinanceCredit, id: personalFinanceCredit?.idPersonalFinanceCredit },
    UPDATE_PERSONAL_FINANCE_CREDIT
  )

export const deletePersonalFinanceCredit = (idPersonalFinanceCredit) =>
  apiDeleteData(PERSONAL_FINANCES_CREDITS_ROUTE, { id: idPersonalFinanceCredit }, DELETE_PERSONAL_FINANCE_CREDIT)

export const getPersonalFinanceCards = (idUser) =>
  apiFindData(PERSONAL_FINANCES_CARDS_ROUTE, { id: idUser }, GET_PERSONAL_FINANCE_CARDS)

export const updatePersonalFinanceCard = (personalFinanceCard) =>
  apiPutData(
    PERSONAL_FINANCES_CARDS_ROUTE,
    { ...personalFinanceCard, id: personalFinanceCard?.idPersonalFinanceCard },
    UPDATE_PERSONAL_FINANCE_CARD
  )

export const postPersonalFinanceCard = (personalFinanceCard) =>
  apiPostData(PERSONAL_FINANCES_CARDS_ROUTE, personalFinanceCard, UPLOAD_PERSONAL_FINANCE_CARD)

export const deletePersonalFinanceCard = (idPersonalFinanceCard) =>
  apiDeleteData(PERSONAL_FINANCES_CARDS_ROUTE, { id: idPersonalFinanceCard }, DELETE_PERSONAL_FINANCE_CARD)
