import { IconButton, ListItemIcon, Menu, MenuItem, TextField } from '@material-ui/core'
import { updateEmployeesDeductions } from 'actions/humanResources'
import classNames from 'classnames'
import ButtonIcon from 'components/common/ButtonIcon'
import useLocale from 'hooks/useLocale'
import useMoney from 'hooks/useMoney'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import Section from '../Section'
import DeductionsModal from './DeductionsModal'
import styles from './styles.module.scss'

const Deduction = ({ deduction, isActive, handleOpenMenu }) => {
  const locale = useLocale()
  const { formatMoney } = useMoney()
  const { fixed, currency, idDeduction } = deduction
  const deductionType = fixed ? 'fixed' : 'percentage'
  const typeText = deductionType === 'fixed' ? ('es' ? 'Fija' : 'Fixed') : 'es' ? 'Porcentaje' : 'Percentage'
  const deductionValue = deduction[deductionType]

  return (
    <article className={classNames(styles.deductionItem, isActive && styles.deductionActive)}>
      <div className={styles.deductionItemTitle}>
        <strong>{deduction.name}</strong>

        <IconButton onClick={(e) => handleOpenMenu(e, isActive, deduction)} data-id-deduction={idDeduction}>
          <i className="fas fa-ellipsis-v" />
        </IconButton>
      </div>
      <p className={styles.deductionItemType}>
        {locale === 'es' ? 'Tipo' : 'Type'}: {typeText}
      </p>

      <p className={styles.deductionItemValue}>
        {deductionType === 'percentage' ? `${deductionValue}%` : formatMoney(deductionValue, currency)}
      </p>
    </article>
  )
}

const EmployeeDeductions = ({ employee }) => {
  const dispatch = useDispatch()
  const locale = useLocale()
  const { deductions, baseSalary, currency } = employee
  const { formatMoney } = useMoney()

  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState('')
  const [anchorRef, setAnchorRef] = useState(null)
  const [usableDeductions, setUsableDeductions] = useState([])
  const menuOpen = Boolean(anchorRef)

  const totalDeductions = useMemo(
    () =>
      deductions
        .filter((d) => Boolean(d.IS_ACTIVE))
        .reduce((acc, item) => {
          if (item.deduction.fixed) {
            return acc + item.deduction.fixed
          } else {
            return acc + (item.deduction.percentage / 100) * baseSalary
          }
        }, 0),
    [deductions, baseSalary]
  )

  const filterUsableDeductions = useCallback(
    (deductions) =>
      deductions
        .filter((d) => d.deduction.currency === currency)
        .filter((d) => d.IS_ACTIVE)
        .filter((d) => d.deduction.name.toLowerCase().includes(search.toLowerCase())),
    [search, currency]
  )

  const handleSearch = (e) => setSearch(e.target.value)
  const handleCreateDeduction = () => setOpen(true)
  const handleCloseDeductions = () => setOpen(false)

  const handleOpenMenu = (e, isActive, deduction) =>
    setAnchorRef({
      current: e.currentTarget,
      deduction,
      isActive,
    })

  const handleDeactivateDeduction = async () => {
    const { idDeduction } = anchorRef.current.dataset
    const newDeductions = deductions.map((d) => {
      if (+d.idDeduction === +idDeduction) {
        return {
          ...d,
          IS_ACTIVE: false,
        }
      }
      return d
    })
    setUsableDeductions(filterUsableDeductions(newDeductions))
    setAnchorRef(null)

    dispatch(await updateEmployeesDeductions({ ...employee, deductions: newDeductions }))
  }

  useEffect(() => {
    setUsableDeductions(filterUsableDeductions(deductions))
  }, [filterUsableDeductions, deductions])

  return (
    <Section title={locale === 'es' ? 'Deducciones' : 'Deductions'}>
      <header className={styles.deductionsHeader}>
        <h3>
          {locale === 'es' ? 'Deducciones totales' : 'Total deductions'}: {formatMoney(totalDeductions, currency)}
        </h3>

        <div>
          <TextField
            variant="outlined"
            placeholder="Buscar deducción"
            value={search}
            onChange={handleSearch}
            InputProps={{
              startAdornment: <i className="fas fa-search" style={{ marginRight: '8px' }} />,
            }}
          />
          <ButtonIcon onClick={handleCreateDeduction} variant="contained" color="primary" icon={<i className="fas fa-plus" />}>
            {locale === 'es' ? 'AGREGAR DEDUCCIÓN' : 'add deduction'}
          </ButtonIcon>
        </div>
      </header>

      <section className={styles.deductionsWrapper}>
        {usableDeductions.length ? (
          usableDeductions.map((d) => (
            <Deduction
              handleOpenMenu={handleOpenMenu}
              key={d.deduction.idDeduction}
              isActive={d.IS_ACTIVE}
              deduction={d.deduction}
            />
          ))
        ) : (
          <p className={styles.helper}>{locale === 'es' ? 'No hay deducciones' : 'There are no deductions'}</p>
        )}
      </section>

      <Menu anchorEl={anchorRef?.current} open={menuOpen} onClose={() => setAnchorRef(null)}>
        <MenuItem classes={{ root: styles.deleteItem }} onClick={handleDeactivateDeduction}>
          <ListItemIcon classes={{ root: styles.menuIcon }}>
            <i className="fas fa-trash-alt" />
          </ListItemIcon>
          {locale === 'es' ? 'Eliminar' : 'Delete'}
        </MenuItem>
      </Menu>

      <DeductionsModal employeeId={employee?.idEmployee} open={open} handleClose={handleCloseDeductions} />
    </Section>
  )
}

export default EmployeeDeductions
