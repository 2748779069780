import React, { useState } from 'react'
import styles from './RecurrentActionsSection.module.scss'
import { Button } from '@material-ui/core'
import RecurrentActionsModal from 'components/RecurrentActionsModal/RecurrentActionsModal'
import useLocale from 'hooks/useLocale'

const RecurrentActionsSection = () => {
  const locale = useLocale()
  const [recurrentActionsModal, setRecurrentActionsModal] = useState(false)

  return (
    <>
      <section className={styles.wrapper}>
        <Button
          onClick={() => setRecurrentActionsModal(true)}
          size="large"
          color="primary"
          variant="contained"
          classes={{ label: styles.label }}
        >
          <i className="fas fa-sync-alt" />
          {locale === 'es' ? 'Recurrencias' : 'Recurrent'}
        </Button>
      </section>
      <RecurrentActionsModal open={recurrentActionsModal} setOpen={setRecurrentActionsModal} />
    </>
  )
}

export default RecurrentActionsSection
