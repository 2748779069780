import useDatanalisis from 'hooks/useDatanalisis'
import useLocale from 'hooks/useLocale'
import { nanoid } from 'nanoid'
import React, { useEffect } from 'react'
import CHARTS_MODELS from '../../helpers/chartsModels'
import { handleAddValue, handleChangeIndicator, handleChangeValue } from '../../helpers/indicatorReducer'
import useDbModel from '../../hooks/useDbModel'
import Option from './Option'

const GraphicsValues = ({ indicator, dispatchIndicator }) => {
  const locale = useLocale()
  const { editIndicator } = useDatanalisis()
  const selectedChart = CHARTS_MODELS?.find((chart) => chart?.type === indicator?.graphicType) ?? {}
  const dbModel = useDbModel(indicator)
  const { options } = selectedChart
  const { chartFields } = dbModel
  const fieldsOptions = chartFields?.map((field) => ({ text: field[locale], value: field.key }))

  const onChangeValue = (value) => (e) => {
    dispatchIndicator(
      handleChangeValue({
        ...value,
        [e.target.name]: e.target.value,
      })
    )
  }

  useEffect(() => {
    const onAddValue = (option) => {
      const defaultValue = {
        id: nanoid(),
        key: '',
        header: '',
        attribute: option.key,
        es: option.es,
        en: option.en,
      }

      dispatchIndicator(handleAddValue(defaultValue))
    }

    if (editIndicator) {
      const values = editIndicator.values.map((value) => {
        const option = options.find((option) => option.key === value.attribute)

        return { ...value, es: option.es, en: option.en }
      })

      return dispatchIndicator(handleChangeIndicator({ values }))
    }

    if (options?.length) {
      // Clean the old values
      dispatchIndicator(handleChangeIndicator({ values: [] }))
      // Set the new values
      options?.forEach(onAddValue)
    }
  }, [options, dispatchIndicator, editIndicator])

  return (
    <div>
      {indicator?.values?.map((option) => (
        <Option key={option.id} option={option} handleOnChange={onChangeValue(option)} fieldsOptions={fieldsOptions} />
      ))}
    </div>
  )
}

export default GraphicsValues
