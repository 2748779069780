import { useCallback } from 'react'

const useArray = () => {
  const findItem = useCallback((arr, id, field) => {
    const item = arr?.filter((item) => item[field] === id)[0]
    return item ?? null
  }, [])

  const filterByField = useCallback((arr, value, field) => arr.filter((item) => item[field] === value), [])

  const isOnArray = useCallback((arr, item) => !!arr.find((i) => i === item), [])

  return { findItem, filterByField, isOnArray }
}

export default useArray
