import { CHANGE_LOCALE, GET_CONFIGS, GET_HISTORIC, SET_NEW_CONFIG, UPDATE_CONFIG } from 'constants/actionTypes'

const initialState = {
  configs: [],
  historic: [],
  locale: localStorage.getItem('locale') ?? 'es',
  isLoadingConfigs: true,
  isLoadingHistoric: true,
  errorInRequest: false,
}

const handlers = {
  [GET_CONFIGS]: (state, { payload = {} }) => {
    if (!payload?.configs) return { ...state, isLoadingConfigs: false, errorInRequest: true }

    return {
      ...state,
      configs: payload?.configs,
      isLoadingConfigs: false,
      errorInRequest: false,
    }
  },
  [SET_NEW_CONFIG]: (state, { payload = {} }) => {
    if (!payload?.config) return { ...state, errorInRequest: true }

    return {
      ...state,
      configs: [...state?.configs, payload?.config],
      errorInRequest: false,
    }
  },
  [UPDATE_CONFIG]: (state, { payload = {} }) => {
    if (!payload?.config) return { ...state, errorInRequest: true }

    return {
      ...state,
      configs: state?.configs?.map((config) => (config?.idConfig === payload?.config?.idConfig ? payload?.config : config)),
      errorInRequest: false,
    }
  },
  [GET_HISTORIC]: (state, { payload = {} }) => {
    if (!payload?.historic) return { ...state, isLoadingHistoric: false, errorInRequest: true }

    return {
      ...state,
      historic: payload?.historic?.reverse(),
      isLoadingHistoric: false,
      errorInRequest: false,
    }
  },
  [CHANGE_LOCALE]: (state, { payload = {} }) => {
    localStorage.setItem('locale', payload)

    return { ...state, locale: payload }
  },
}

const configs = (state = initialState, action) => {
  const { type } = action

  return handlers[type] ? handlers[type](state, action) : state
}

export default configs
