import { deleteWorkDay } from 'actions/humanResources'
import DataGridTable from 'components/DataGridTable/DataGridTable'
import { formatDate } from 'helpers/date'
import useLocale from 'hooks/useLocale'
import React, { useMemo, useState } from 'react'
import EditWorkedHoursModal from './EditWorkedHoursModal'
import styles from './index.module.scss'

const RenderBreaks = ({ data }) => {
  const locale = useLocale()

  return data.length ? (
    <div className={styles.breakContainer}>
      {data?.length > 2
        ? locale === 'es'
          ? 'Múltiplos descansos'
          : 'Multiple breaks'
        : data.map((el) => (
            <div>
              {formatDate(el.startTime, 'HH:mm:ss', true)} - {el?.endTime ? formatDate(el?.endTime, 'HH:mm:ss', true) : '///'}
            </div>
          ))}
    </div>
  ) : (
    <div className={styles.emptyBreaks}>N/A</div>
  )
}

const WeeklyHours = ({ data: rawData, hideActions }) => {
  const locale = useLocale()

  const data = useMemo(() => rawData?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)), [rawData])

  const [selectedId, setSelectedId] = useState(null)

  const selectedObject = data?.find((el) => el.idEmployeeWorkDay === selectedId)

  const COLUMNS = useMemo(
    () => [
      {
        headerName: locale === 'es' ? 'Fecha' : 'Date',
        field: 'date',
        width: 150,
        sortable: false,
        renderCell: ({ row }) => <div style={{ color: '#507938' }}>{formatDate(row.created_at, 'DD MMMM YYYY', true)}</div>,
      },
      {
        headerName: locale === 'es' ? 'Hora de entrada' : 'Start time',
        field: 'startTime',
        width: 150,
        sortable: false,
        renderCell: ({ value }) => formatDate(value, 'HH:mm:ss', true),
      },
      {
        headerName: locale === 'es' ? 'Descansos' : 'Breaks',
        field: 'breaks',
        width: 200,
        sortable: false,
        renderCell: ({ value }) => <RenderBreaks data={value} />,
      },
      {
        headerName: locale === 'es' ? 'Hora de salida' : 'End time',
        field: 'endTime',
        width: 150,
        sortable: false,
        renderCell: ({ value }) => (value ? formatDate(value, 'HH:mm:ss', true) : '-'),
      },
      {
        headerName: locale === 'es' ? 'Total' : 'Total',
        field: 'workedHours',
        width: 100,
        sortable: false,
      },
    ],
    [locale]
  )

  const ACTIONS = useMemo(
    () => ({
      hideBasicActions: hideActions,
      width: 150,
      field: 'idEmployeeWorkDay',
      handlerEdit: ({ selectedObject }) => setSelectedId(selectedObject.idEmployeeWorkDay),
      queryDelete: deleteWorkDay,
    }),
    [hideActions]
  )

  return (
    <>
      <DataGridTable columns={COLUMNS} actionsConfig={ACTIONS} data={data} pageSize={7} />
      <EditWorkedHoursModal open={Boolean(selectedId)} selected={selectedObject} onClose={() => setSelectedId(null)} />
    </>
  )
}

export default WeeklyHours
