const es = {
  copyright: {
    develop: 'Desarrollado por ',
    terms: 'Nuestros terminos: ',
    privacy: 'Políticas de Privacidad',
  },
  form: {
    emailLabel: 'Correo electronico',
    passwordLabel: 'Contraseña',
    rememberMeToolTip: 'Si no seleccionas "Recordar", tu sesión expira dentro de 2 días.',
    rememberMeLabel: 'Recordar',
    loadingText: 'Cargando...',
    login: 'Iniciar sesión',
    register: 'Registrarme',
    forgot: 'Olvide mi contraseña',
  },
  language: 'Idioma: ',
}

const en = {
  copyright: {
    develop: 'Develop by ',
    terms: 'Our terms: ',
    privacy: 'Privacy policies',
  },
  form: {
    emailLabel: 'Email',
    passwordLabel: 'Password',
    rememberMeToolTip: `If you don't select "Remember", your session will expire in 2 days.`,
    rememberMeLabel: 'Remember',
    loadingText: 'Loading...',
    login: 'Login',
    register: 'Register',
    forgot: 'Forgot my password',
  },
  language: 'Language: ',
}

const getContent = (locale) => (locale === 'es' ? es : en)

export default getContent
