import {
  DELETE_DATANALISIS_INDICATOR,
  DELETE_DATANALISIS_PAGE,
  GET_DATANALISIS_PAGES,
  SELECT_DATANALISIS_PAGE,
  TOGGLE_INDICATOR_CREATOR,
  UPDATE_DATANALISISIS_INDICATOR,
  UPDATE_DATANALISISIS_PAGE,
  UPDATE_DATANALISIS_INDICATOR_LAYOUT,
  UPLOAD_DATANALISIS_INDICATOR,
  UPLOAD_DATANALISIS_PAGE,
} from 'constants/actionTypes'
import { deleteItem, pushItem, updateItem } from 'helpers/reducers'

const initialState = {
  pages: [],
  selectedPageId: null,
  loadingPages: true,
  isCreatingIndicator: false,
  editIndicator: null,
}

const handlers = {
  [GET_DATANALISIS_PAGES]: (state, { payload }) => {
    if (!payload?.pages) return { ...state, loadingPages: false }

    const defaultPageId = payload?.pages[0]?.idDatanalisisPage

    return {
      ...state,
      pages: payload.pages,
      selectedPageId: defaultPageId,
      loadingPages: false,
    }
  },
  [UPLOAD_DATANALISIS_PAGE]: (state, { payload }) => {
    if (!payload?.page) return state

    return {
      ...state,
      pages: pushItem(state.pages, payload.page),
    }
  },
  [UPDATE_DATANALISISIS_PAGE]: (state, { payload }) => {
    if (!payload?.page) return state

    return {
      ...state,
      pages: updateItem(state.pages, payload.page, 'idDatanalisisPage'),
      selectedPageId: payload?.page?.idDatanalisisPage,
    }
  },
  [DELETE_DATANALISIS_PAGE]: (state, { payload }) => {
    if (!payload?.page) return state

    const pages = deleteItem(state.pages, payload.page, 'idDatanalisisPage')

    return {
      ...state,
      pages,
      selectedPageId: pages?.[0]?.idDatanalisisPage,
    }
  },
  [SELECT_DATANALISIS_PAGE]: (state, { payload }) => ({
    ...state,
    selectedPageId: payload?.idDatanalisisPage,
  }),
  [TOGGLE_INDICATOR_CREATOR]: (state, { payload }) => ({
    ...state,
    isCreatingIndicator: payload.state,
    editIndicator: payload.item,
  }),
  [UPLOAD_DATANALISIS_INDICATOR]: (state, { payload }) => {
    const indicator = payload?.indicator
    if (!indicator) return state

    const { idDatanalisisPage: pageId } = indicator

    return {
      ...state,
      pages: state.pages.map((page) =>
        page?.idDatanalisisPage === pageId ? { ...page, indicators: pushItem(page.indicators, indicator) } : page
      ),
    }
  },
  [UPDATE_DATANALISISIS_INDICATOR]: (state, { payload }) => {
    const indicator = payload?.indicator
    if (!indicator) return state

    const { idDatanalisisPage: pageId } = indicator

    return {
      ...state,
      pages: state.pages.map((page) =>
        page?.idDatanalisisPage === pageId
          ? { ...page, indicators: updateItem(page.indicators, indicator, 'idDatanalisisIndicator') }
          : page
      ),
    }
  },
  [DELETE_DATANALISIS_INDICATOR]: (state, { payload }) => {
    const indicator = payload?.indicator
    if (!indicator) return state

    const { idDatanalisisPage: pageId } = indicator

    return {
      ...state,
      pages: state.pages.map((page) =>
        page?.idDatanalisisPage === pageId
          ? { ...page, indicators: deleteItem(page.indicators, indicator, 'idDatanalisisIndicator') }
          : page
      ),
    }
  },
  [UPDATE_DATANALISIS_INDICATOR_LAYOUT]: (state, { payload }) => {
    const { indicators } = payload ?? {}
    const pageId = indicators?.[0]?.idDatanalisisPage

    if (!pageId) return state

    return { ...state, pages: state.pages.map((page) => (page.idDatanalisisPage === pageId ? { ...page, indicators } : page)) }
  },
}

const datanalisis = (state = initialState, action) => {
  const { type } = action

  return handlers[type] ? handlers[type](state, action) : state
}

export default datanalisis
