import { Tooltip } from '@material-ui/core'
import { confirmDialog } from 'actions/modals'
import classNames from 'classnames'
import useLocale from 'hooks/useLocale'
import { useDeletePermission, useUpdatePermission } from 'pages/GoogleDrivePage/useDriveApi'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import styles from './PeopleWithAccess.module.scss'
import RoleSelector from './RoleSelector/RoleSelector'

const Person = ({ displayName, emailAddress, role, photoLink, fileId, id }) => {
  const { folderId } = useParams()
  const dispatch = useDispatch()
  const locale = useLocale()
  const isOwner = role === 'owner'
  const [personRole, setPersonRole] = useState(role)
  const { mutateAsync: deletePermission } = useDeletePermission()
  const { mutate: updatePermission } = useUpdatePermission()

  const handleChangeRole = (e) => {
    setPersonRole(e.target.value)
    const body = {
      role: e.target.value,
    }

    updatePermission({ fileId, permissionId: id, body, folderId })
  }

  const handleDeletePermission = () => {
    dispatch(
      confirmDialog({
        message: locale === 'es' ? '¿Estás seguro de eliminar este permiso?' : 'Are you sure you want to delete this permission?',
        handlerAction: async () => {
          await deletePermission({ permissionId: id, fileId, folderId })
        },
      })
    )
  }

  return (
    <div className={styles.personWrapper}>
      <figure className={styles.imageWrapper}>
        <img src={photoLink} referrerPolicy="no-referrer" alt="" />
      </figure>

      <div className={styles.nameWrapper}>
        <strong>
          {displayName} {isOwner && `(${locale === 'es' ? 'Tú' : 'You'})`}
        </strong>
        <p>{emailAddress}</p>
      </div>

      <div className={styles.roleWrapper}>
        {isOwner ? locale === 'es' ? 'Propietario' : 'Owner' : <RoleSelector onChange={handleChangeRole} value={personRole} />}
        {!isOwner && (
          <Tooltip title={locale === 'es' ? 'Eliminar permiso' : 'Delete permission'} onClick={handleDeletePermission}>
            <i className={classNames('fas fa-trash-alt', styles.deleteIcon)} />
          </Tooltip>
        )}
      </div>
    </div>
  )
}

const PeopleWithAccess = ({ data, fileId }) => {
  const locale = useLocale()
  const title = locale === 'es' ? 'Personas con acceso' : 'People with access'
  const filteredData = data?.filter((item) => item.id !== 'anyoneWithLink')?.reverse() ?? []

  return (
    <section className={styles.wrapper}>
      <h3 className={styles.title}>{title}</h3>

      {filteredData?.map((item) => (
        <Person key={item.id} fileId={fileId} {...item} />
      ))}
    </section>
  )
}

export default PeopleWithAccess
