import { differenceInMonths } from 'date-fns'

const PREFIX = {
  en: {
    day: 'day',
    days: 'days',
    year: 'year',
    years: 'years',
    month: 'month',
    months: 'months',
    bind: 'and',
  },
  es: {
    day: 'día',
    days: 'días',
    year: 'año',
    years: 'años',
    month: 'mes',
    months: 'meses',
    bind: 'con',
  },
}

export const checkEndDate = (date) => {
  const currentDate = new Date()
  if (date === null) {
    return currentDate
  }

  const endDate = new Date(date)

  return currentDate > endDate ? endDate : currentDate
}

export const getRelativeDate = (date, end = null) => {
  const locale = localStorage.getItem('locale')
  const startDate = new Date(date)
  const endDate = checkEndDate(end)
  const prefix = PREFIX[locale]

  const diff = differenceInMonths(endDate, startDate)
  const days = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24))
  const years = Math.floor(diff / 12)
  const months = diff - years * 12

  if (days < 30) {
    const dayPrefix = days > 1 ? prefix.days : prefix.day

    if (days < 0) return 'N/A'

    return `${days} ${dayPrefix}`
  } else if (years && months > 0) {
    const yearPrefix = years > 1 ? prefix.years : prefix.year
    const monthPrefix = months > 1 ? prefix.months : prefix.month
    const bind = prefix.bind

    return `${years} ${yearPrefix} ${bind} ${months} ${monthPrefix}`
  } else if (years) {
    const yearPrefix = years > 1 ? prefix.years : prefix.year

    return `${years} ${yearPrefix}`
  } else if (months) {
    const monthPrefix = months > 1 ? prefix.months : prefix.month

    return `${months} ${monthPrefix}`
  }
}
