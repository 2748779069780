import Dialog from 'components/Dialog/Dialog'
import useLocale from 'hooks/useLocale'
import React from 'react'
import { useSearchParams } from 'react-router-dom'
import styles from './SharingModal.module.scss'
import AddPeople from './components/AddPeople'
import GeneralAccess from './components/GeneralAccess'
import PeopleWithAccess from './components/PeopleWithAccess'

const SharingModal = ({ open, onClose, selected = {} }) => {
  const locale = useLocale()
  const [searchParams] = useSearchParams()
  const { name, permissions, id, mimeType } = selected
  const isFolder = mimeType === 'application/vnd.google-apps.folder'
  const title = locale === 'es' ? `Compartir "${name}"` : `Share "${name}"`
  const isShared = searchParams.get('shared')
  return (
    <Dialog maxWidth="sm" open={open} handleCloseAction={onClose} title={title}>
      <AddPeople fileId={id} />

      {isShared && (
        <p className={styles.notOwned}>
          {locale === 'es'
            ? 'Este archivo no es tuyo, pero puedes enviar una invitación para compartirlo con alguien.'
            : 'This file is not yours, but you can send an invitation to share it with someone.'}
        </p>
      )}

      {!isShared && <PeopleWithAccess data={permissions} fileId={id} />}
      {!isShared && <GeneralAccess data={permissions} fileId={id} isFolder={isFolder} />}
    </Dialog>
  )
}

export default SharingModal
