export const downloadByUrl = (url) => {
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.style = 'display: none'
  a.href = url
  a.click()
  document.body.removeChild(a)

  return
}
