import { GET_ROLES, UPLOAD_ROLE, UPDATE_ROLE, DELETE_ROLE } from 'constants/actionTypes'

import { CUSTOM_ROLES_ROUTE } from 'constants/apiRoutes'
import { apiFindData, apiPostData, apiPutData, apiDeleteData } from 'helpers/api'

export const getRoles = (idCompany) => apiFindData(CUSTOM_ROLES_ROUTE, { id: idCompany }, GET_ROLES)

export const postRole = (role) => apiPostData(CUSTOM_ROLES_ROUTE, role, UPLOAD_ROLE)

export const updateRole = (role) => apiPutData(CUSTOM_ROLES_ROUTE, { ...role, id: role?.idCustomRole }, UPDATE_ROLE)

export const deleteRole = (idCustomRole) => apiDeleteData(CUSTOM_ROLES_ROUTE, { id: idCustomRole }, DELETE_ROLE)
