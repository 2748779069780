import { Divider, ListItemIcon, MenuItem } from '@material-ui/core'
import useCompany from 'hooks/useCompany'
import useLocale from 'hooks/useLocale'
import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { useFilePermissions } from '../hooks/useFilePermissions'
import { useContextMenu } from './useContextMenu'

function ContextOptions({ isFolder, styles, id, config, handleOpenNamingMenu, canCreateInShared }) {
  const locale = useLocale()
  const [searchParams] = useSearchParams()
  const {
    canShare,
    previewFile,
    downloadFolder,
    moveFile,
    changeFilePermissions,
    downloadFile,
    deleteFile,
    openFolder,
    shareFile,
  } = useContextMenu(id, config)

  const { isOwner } = useCompany()
  const { isPrivateFile, isWriter } = useFilePermissions(id)
  const isShared = searchParams.get('shared')

  if (isPrivateFile) {
    return (
      <>
        {isFolder && (
          <MenuItem onClick={openFolder}>
            <ListItemIcon classes={{ root: styles.menuIcon }}>
              <i className="fas fa-folder-open" />
            </ListItemIcon>

            {locale === 'es' ? 'Abrir' : 'Open'}
          </MenuItem>
        )}

        {isFolder && (
          <MenuItem onClick={downloadFolder}>
            <ListItemIcon classes={{ root: styles.menuIcon }}>
              <i className="fas fa-download" />
            </ListItemIcon>

            {locale === 'es' ? 'Descargar' : 'Download'}
          </MenuItem>
        )}

        {!isFolder && (
          <MenuItem onClick={previewFile}>
            <ListItemIcon classes={{ root: styles.menuIcon }}>
              <i className="fas fa-eye" />
            </ListItemIcon>

            {locale === 'es' ? 'Previsualizar' : 'Preview'}
          </MenuItem>
        )}

        {(isWriter || isOwner) && (
          <MenuItem onClick={moveFile}>
            <ListItemIcon classes={{ root: styles.menuIcon }}>
              <i className="fas fa-arrows" />
            </ListItemIcon>

            {locale === 'es' ? 'Mover' : 'Move'}
          </MenuItem>
        )}

        {isOwner && (
          <MenuItem onClick={changeFilePermissions}>
            <ListItemIcon classes={{ root: styles.menuIcon }}>
              <i className="fas fa-lock" />
            </ListItemIcon>

            {locale === 'es' ? 'Permisos' : 'Permissions'}
          </MenuItem>
        )}

        {(isWriter || isOwner) && !isFolder && <Divider classes={{ root: styles.divider }} />}

        {!isFolder && (
          <MenuItem onClick={downloadFile}>
            <ListItemIcon classes={{ root: styles.menuIcon }}>
              <i className="fas fa-download" />
            </ListItemIcon>

            {locale === 'es' ? 'Descargar' : 'Download'}
          </MenuItem>
        )}
        {(isWriter || isOwner) && (
          <MenuItem onClick={shareFile}>
            <ListItemIcon classes={{ root: styles.menuIcon }}>
              <i className="fas fa-share-from-square" />
            </ListItemIcon>

            {locale === 'es' ? 'Compartir' : 'Share'}
          </MenuItem>
        )}

        {(isWriter || isOwner) && (
          <MenuItem onClick={handleOpenNamingMenu}>
            <ListItemIcon classes={{ root: styles.menuIcon }}>
              <i className="fas fa-edit" />
            </ListItemIcon>

            {locale === 'es' ? 'Renombrar' : 'Rename'}
          </MenuItem>
        )}

        {(isWriter || isOwner) && <Divider classes={{ root: styles.divider }} />}

        {(isWriter || isOwner) && (
          <MenuItem onClick={deleteFile}>
            <ListItemIcon classes={{ root: styles.menuIcon }}>
              <i className="fas fa-trash-alt" />
            </ListItemIcon>

            {locale === 'es' ? 'Eliminar' : 'Delete'}
          </MenuItem>
        )}
      </>
    )
  }

  return (
    <>
      {isFolder && (
        <MenuItem onClick={openFolder}>
          <ListItemIcon classes={{ root: styles.menuIcon }}>
            <i className="fas fa-folder-open" />
          </ListItemIcon>

          {locale === 'es' ? 'Abrir' : 'Open'}
        </MenuItem>
      )}

      {isFolder && (
        <MenuItem onClick={downloadFolder}>
          <ListItemIcon classes={{ root: styles.menuIcon }}>
            <i className="fas fa-download" />
          </ListItemIcon>

          {locale === 'es' ? 'Descargar' : 'Download'}
        </MenuItem>
      )}

      {!isFolder && (
        <MenuItem onClick={previewFile}>
          <ListItemIcon classes={{ root: styles.menuIcon }}>
            <i className="fas fa-eye" />
          </ListItemIcon>

          {locale === 'es' ? 'Previsualizar' : 'Preview'}
        </MenuItem>
      )}

      {canCreateInShared && (
        <MenuItem onClick={moveFile}>
          <ListItemIcon classes={{ root: styles.menuIcon }}>
            <i className="fas fa-arrows" />
          </ListItemIcon>

          {locale === 'es' ? 'Mover' : 'Move'}
        </MenuItem>
      )}

      {isOwner && (
        <MenuItem onClick={changeFilePermissions}>
          <ListItemIcon classes={{ root: styles.menuIcon }}>
            <i className="fas fa-lock" />
          </ListItemIcon>

          {locale === 'es' ? 'Permisos' : 'Permissions'}
        </MenuItem>
      )}

      {!isFolder && <Divider classes={{ root: styles.divider }} />}

      {!isFolder && (
        <MenuItem onClick={downloadFile}>
          <ListItemIcon classes={{ root: styles.menuIcon }}>
            <i className="fas fa-download" />
          </ListItemIcon>

          {locale === 'es' ? 'Descargar' : 'Download'}
        </MenuItem>
      )}
      {canShare && (
        <MenuItem onClick={shareFile}>
          <ListItemIcon classes={{ root: styles.menuIcon }}>
            <i className="fas fa-share-from-square" />
          </ListItemIcon>

          {locale === 'es' ? 'Compartir' : 'Share'}
        </MenuItem>
      )}

      {canCreateInShared && (
        <MenuItem onClick={handleOpenNamingMenu}>
          <ListItemIcon classes={{ root: styles.menuIcon }}>
            <i className="fas fa-edit" />
          </ListItemIcon>

          {locale === 'es' ? 'Renombrar' : 'Rename'}
        </MenuItem>
      )}

      {!isShared && <Divider classes={{ root: styles.divider }} />}

      {!isShared && (
        <MenuItem onClick={deleteFile}>
          <ListItemIcon classes={{ root: styles.menuIcon }}>
            <i className="fas fa-trash-alt" />
          </ListItemIcon>

          {locale === 'es' ? 'Eliminar' : 'Delete'}
        </MenuItem>
      )}
    </>
  )
}

export default ContextOptions
