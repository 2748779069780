import { useAbout } from 'pages/GoogleDrivePage/useDriveApi'

export const useDataUsage = () => {
  const { data } = useAbout()
  const { usage, limit } = data?.storageQuota ?? {}

  const remainingSpace = limit - usage
  const canUploadFile = remainingSpace > 500000

  return { usage, limit, remainingSpace, canUploadFile }
}
