import { GET_CLIENT_GROUPS, UPLOAD_CLIENT_GROUP, UPDATE_CLIENT_GROUP, DELETE_CLIENT_GROUP } from 'constants/actionTypes'
import { CLIENT_GROUPS_ROUTE } from 'constants/apiRoutes'
import { apiFindData, apiPostData, apiPutData, apiDeleteData } from 'helpers/api'

export const getClientGroups = (idCompany) => apiFindData(CLIENT_GROUPS_ROUTE, { id: idCompany }, GET_CLIENT_GROUPS)

export const postClientGroup = (clientGroup) => apiPostData(CLIENT_GROUPS_ROUTE, clientGroup, UPLOAD_CLIENT_GROUP)

export const updateClientGroup = (clientGroup) => apiPutData(CLIENT_GROUPS_ROUTE, clientGroup, UPDATE_CLIENT_GROUP)

export const deleteClientGroup = (idClientGroup) => apiDeleteData(CLIENT_GROUPS_ROUTE, { id: idClientGroup }, DELETE_CLIENT_GROUP)
