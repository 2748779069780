import { getConfigs } from 'actions/configs'
import { getCompanyUsers } from 'actions/users'
import useConfigs from 'hooks/useConfigs'
import useDriveSlice from 'hooks/useDriveSlice'
import useUsers from 'hooks/useUsers'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { fetchPrivateFiles, fetchUserPermissions } from 'slices/driveSlice'
import { useFiles, useFolders, useSharedFiles, useSharedFolders, useTrashedFiles, useTrashedFolders } from '../useDriveApi'

export const usePageData = () => {
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const { folderId } = useParams()
  const { data: files, isLoading: isLoadingFiles, status } = useFiles(folderId)
  const { data: sharedFiles, isLoading: isLoadingSharedFiles } = useSharedFiles(folderId)
  const { data: trashedFiles, isLoading: isLoadingTrashedFiles } = useTrashedFiles(folderId)
  const { data: folders, isLoading: isLoadingFolders } = useFolders(folderId)
  const { data: sharedFolders, isLoading: isLoadingSharedFolders } = useSharedFolders(folderId)
  const { data: trashedFolders, isLoading: isLoadingTrashedFolders } = useTrashedFolders(folderId)
  const loading =
    status === 'idle' ||
    status === 'loading' ||
    isLoadingFiles ||
    isLoadingFolders ||
    isLoadingSharedFiles ||
    isLoadingSharedFolders ||
    isLoadingTrashedFiles ||
    isLoadingTrashedFolders

  const isNotRootFolder = Boolean(folderId)
  let isTrashed = searchParams.get('trashed')
  let isShared = searchParams.get('shared')

  const isEmptyFiles = !files?.length && !folders?.length
  const isEmptySharedFiles = !sharedFiles?.length && !sharedFolders?.length
  const isEmptyTrashedFiles = !trashedFiles?.length && !trashedFolders?.length
  const folderIsEmpty = isTrashed ? isEmptyTrashedFiles : isShared ? isEmptySharedFiles : isEmptyFiles

  const { isLoadingConfigs } = useConfigs()
  const { myUser, isLoadingCompanyUsers } = useUsers()
  const { isLoadingPermissions } = useDriveSlice()
  const { company, idUser } = myUser ?? {}
  const { idCompany } = company ?? {}

  useEffect(() => {
    const fetch = async () => {
      if (isLoadingCompanyUsers && company?.idCompany) {
        dispatch(await getCompanyUsers(company?.idCompany))
      }
    }

    fetch()
  }, [dispatch, isLoadingCompanyUsers, company?.idCompany])

  useEffect(() => {
    if (isLoadingPermissions && company?.idCompany && myUser?.idUser) {
      dispatch(fetchPrivateFiles(company?.idCompany))
      dispatch(fetchUserPermissions({ idUser: myUser?.idUser, idCompany: company?.idCompany }))
    }
  }, [isLoadingPermissions, company?.idCompany, myUser?.idUser, dispatch])

  useEffect(() => {
    const fetch = async () => {
      dispatch(await getConfigs(idCompany, idUser))
    }

    if (idUser && idCompany && isLoadingConfigs) fetch()
  }, [dispatch, idUser, idCompany, isLoadingConfigs])

  return {
    folderId,
    files,
    folders,
    sharedFiles,
    sharedFolders,
    isLoadingSharedFolders,
    trashedFiles,
    trashedFolders,
    isLoadingTrashedFolders,
    loading,
    isNotRootFolder,
    folderIsEmpty,
    isLoadingFiles: isLoadingFiles || isLoadingSharedFiles || isLoadingTrashedFiles,
    isLoadingFolders: isLoadingFolders || isLoadingSharedFolders || isLoadingTrashedFolders,
  }
}
