import React from 'react'
import EmployeeDeductions from './EmployeeDeductions'
import EmployeeDetails from './EmployeeDetails'
import EmployeeFiles from './EmployeeFiles'

const Profile = ({ employee }) => {
  return (
    <>
      <EmployeeDetails employee={employee} />
      <EmployeeDeductions employee={employee} />
      <EmployeeFiles employee={employee} />
    </>
  )
}

export default Profile
