import classNames from 'classnames'
import SubscribeToProButton from 'components/SubscribeToProButton'
import useLocale from 'hooks/useLocale'
import React from 'react'
import styles from './Functionalities.module.scss'

function LangObj(es, en) {
  this.es = es
  this.en = en
}

function Module(title, free, pro) {
  this.title = title
  this.free = free
  this.pro = pro
}

const PRO_FEATURE_CONTENT = new LangObj('Acceso ilimitado', 'Unlimited access')
const NO_ACCESS_CONTENT = new LangObj('Sin acceso', 'No access')

const CONTENT = {
  header: new LangObj('Funcionalidades', 'Functionalities'),
  modules: [
    new Module(new LangObj('Banco de imágenes', 'Image bank'), PRO_FEATURE_CONTENT, PRO_FEATURE_CONTENT),
    new Module(
      new LangObj('Campañas de Marketing', 'MarketingCampaigns'),
      new LangObj('3 campañas de marketing', '3 marketing campaings per month'),
      PRO_FEATURE_CONTENT
    ),
    new Module(new LangObj('Clientes', 'Clients'), new LangObj('20 clientes', '20 clients'), PRO_FEATURE_CONTENT),
    new Module(new LangObj('Correos Masivos', 'Mass emails'), PRO_FEATURE_CONTENT, PRO_FEATURE_CONTENT),
    new Module(new LangObj('Costos', 'Costs'), PRO_FEATURE_CONTENT, PRO_FEATURE_CONTENT),
    new Module(
      new LangObj('Cotizaciones', 'Quotations'),
      new LangObj('1 cotización al mes', '1 quotation per month'),
      PRO_FEATURE_CONTENT
    ),
    new Module(new LangObj('Editor de imagen', 'Image editor'), PRO_FEATURE_CONTENT, PRO_FEATURE_CONTENT),
    new Module(
      new LangObj('Espacios de trabajo', 'Workspaces'),
      new LangObj('2 espacios de trabajo', '2 workspaces'),
      PRO_FEATURE_CONTENT
    ),
    new Module(new LangObj('Formularios', 'Fomrs'), new LangObj('1 formulario al mes', '1 form per month'), PRO_FEATURE_CONTENT),
    new Module(new LangObj('Herramientas', 'Tools'), PRO_FEATURE_CONTENT, PRO_FEATURE_CONTENT),
    new Module(new LangObj('Historial', 'History'), PRO_FEATURE_CONTENT, PRO_FEATURE_CONTENT),
    new Module(new LangObj('Ingresos y gastos', 'Incomes and expenses'), PRO_FEATURE_CONTENT, PRO_FEATURE_CONTENT),
    new Module(
      new LangObj('Inventario', 'Inventory'),
      new LangObj('3 productos en inventario', '3 products on inventory'),
      PRO_FEATURE_CONTENT
    ),
    new Module(new LangObj('Procesos', 'Processes'), new LangObj('2 procesos', '2 processes'), PRO_FEATURE_CONTENT),
    new Module(
      new LangObj('Publicaciones', 'Posts'),
      new LangObj('3 publicaciones al mes', '3 post per month'),
      PRO_FEATURE_CONTENT
    ),
    new Module(new LangObj('Análisis de Datos', 'Data analysis'), NO_ACCESS_CONTENT, PRO_FEATURE_CONTENT),
    new Module(new LangObj('Calendario', 'Calendar'), NO_ACCESS_CONTENT, PRO_FEATURE_CONTENT),
    new Module(new LangObj('Flujo de Caja', 'Cash flow'), NO_ACCESS_CONTENT, PRO_FEATURE_CONTENT),
    new Module(new LangObj('Gastos Personales', 'Personal expenses'), NO_ACCESS_CONTENT, PRO_FEATURE_CONTENT),
    new Module(new LangObj('Recursos Humanos', 'Human Resources'), NO_ACCESS_CONTENT, PRO_FEATURE_CONTENT),
  ],
}

const TableRow = ({ title, free, pro, even }) => {
  return (
    <tr className={classNames(styles.tableRowWrapper, even && styles.tableRowEven)}>
      <td>{title}</td>
      <td>{free}</td>
      <td>{pro}</td>
    </tr>
  )
}

const Functionalities = () => {
  const locale = useLocale()

  return (
    <section className={styles.wrapper} id="plans">
      <h2 className={styles.header}>{CONTENT.header[locale]}</h2>
      <div className={styles.tableContainer}>
        <table className={styles.tableWrapper}>
          <tr className={styles.tableRowWrapper}>
            <th>{locale === 'es' ? 'MÓDULO' : 'Module'}</th>
            <th>FREE PLAN</th>
            <th>
              <div className={styles.proPlanHeader}>
                PRO PLAN <br /> $55
              </div>
            </th>
          </tr>
          {CONTENT.modules.map((module, index) => (
            <TableRow
              key={module.title[locale]}
              title={module.title[locale]}
              free={module.free[locale]}
              pro={module.pro[locale]}
              even={index % 2 === 0}
            />
          ))}
          <tr>
            <td></td>
            <td></td>
            <td style={{ paddingTop: '16px' }}>
              <SubscribeToProButton fullWidth />
            </td>
          </tr>
        </table>
      </div>
    </section>
  )
}

export default Functionalities
