import { GET_CLIENT_GROUPS, UPLOAD_CLIENT_GROUP, UPDATE_CLIENT_GROUP, DELETE_CLIENT_GROUP } from 'constants/actionTypes'

const initialState = {
  isLoadingClientGroups: true,
  clientGroups: [],
}

const handlers = {
  [GET_CLIENT_GROUPS]: (state, { payload = {} }) => {
    if (!payload?.clientGroups) return { ...state, errorInRequest: true }

    return {
      ...state,
      isLoadingClientGroups: false,
      clientGroups: payload?.clientGroups,
      errorInRequest: false,
    }
  },
  [UPLOAD_CLIENT_GROUP]: (state, { payload = {} }) => {
    if (!payload?.clientGroup) return { ...state, errorInRequest: true }

    return {
      ...state,
      clientGroups: state.clientGroups.concat(payload.clientGroup),
      errorInRequest: false,
    }
  },
  [UPDATE_CLIENT_GROUP]: (state, { payload = {} }) => {
    if (!payload?.clientGroup) return { ...state, errorInRequest: true }

    return {
      ...state,
      clientGroups: state.clientGroups.map((group) =>
        group.idClientGroup === payload.clientGroup.idClientGroup ? payload.clientGroup : group
      ),
      errorInRequest: false,
    }
  },
  [DELETE_CLIENT_GROUP]: (state, { payload = {} }) => {
    if (!payload?.clientGroup) return { ...state, errorInRequest: true }

    return {
      ...state,
      clientGroups: state.clientGroups.filter(({ idClientGroup }) => idClientGroup !== payload.clientGroup.idClientGroup),
      errorInRequest: false,
    }
  },
}

const clientGroups = (state = initialState, action) => {
  const { type } = action

  return handlers[type] ? handlers[type](state, action) : state
}

export default clientGroups
