import { Divider, ListItemIcon, Menu, MenuItem } from '@material-ui/core'
import useLocale from 'hooks/useLocale'
import React, { useState } from 'react'
import { ReactComponent as FileUp } from '../../utils/FileUp.svg'
import { ReactComponent as FolderUp } from '../../utils/FolderUp.svg'
import NamingModal from './NamingModal'
import styles from './NewButton.module.scss'
import { useCreateMenu } from './useCreateFile'

const CreationMenu = ({ anchorEl, setAnchorEl }) => {
  const [open, setOpen] = useState(false)

  const onCloseModal = () => setOpen(false)
  const onOpenModal = (fileType) => setOpen(fileType)

  const handleClose = () => {
    setAnchorEl(null)
    setOpen(false)
  }

  const handleUploadFile = () => {
    inputRef.current.click()
  }

  const creationHandler = (name) => {
    const callbacks = {
      folder: handleCreateFolder,
      docx: handleCreateGoogleFile('docx'),
      xlsx: handleCreateGoogleFile('xlsx'),
      pptx: handleCreateGoogleFile('pptx'),
    }

    callbacks[open](name)
    handleClose()
  }

  const { inputRef, folderInputRef, handleCreateFromFolder, handleCreate, handleCreateFolder, handleCreateGoogleFile } =
    useCreateMenu(handleClose)
  const locale = useLocale()

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        classes={{ paper: styles.wrapper }}
      >
        <MenuItem onClick={handleUploadFile}>
          <ListItemIcon classes={{ root: styles.menuIcon }}>
            <FileUp className={styles.svgIcon} />
          </ListItemIcon>

          {locale === 'es' ? 'Subir archivo' : 'Upload file'}
        </MenuItem>

        <MenuItem onClick={() => folderInputRef.current.click()}>
          <ListItemIcon classes={{ root: styles.menuIcon }}>
            <FolderUp className={styles.svgIcon} />
          </ListItemIcon>

          {locale === 'es' ? 'Subir carpeta' : 'Upload folder'}
        </MenuItem>

        <Divider classes={{ root: styles.divider }} />

        <MenuItem onClick={() => onOpenModal('folder')}>
          <ListItemIcon classes={{ root: styles.menuIcon }}>
            <i className="fas fa-folder" />
          </ListItemIcon>

          {locale === 'es' ? 'Carpeta' : 'Folder'}
        </MenuItem>

        <Divider classes={{ root: styles.divider }} />

        <MenuItem onClick={() => onOpenModal('docx')}>
          <ListItemIcon classes={{ root: styles.menuIcon }}>
            <i className="fas fa-file-word" />
          </ListItemIcon>
          Google Docs
        </MenuItem>

        <MenuItem onClick={() => onOpenModal('xlsx')}>
          <ListItemIcon classes={{ root: styles.menuIcon }}>
            <i className="fas fa-file-excel" />
          </ListItemIcon>
          Google Sheets
        </MenuItem>

        <MenuItem onClick={() => onOpenModal('pptx')}>
          <ListItemIcon classes={{ root: styles.menuIcon }}>
            <i className="fas fa-file-powerpoint" />
          </ListItemIcon>
          Google Slides
        </MenuItem>
      </Menu>

      <input multiple ref={inputRef} type="file" className={styles.hideFileInput} onChange={handleCreate} />
      <input
        webkitdirectory=""
        directory=""
        type="file"
        ref={folderInputRef}
        onChange={handleCreateFromFolder}
        className={styles.hideFileInput}
      />

      <NamingModal open={Boolean(open)} onClose={onCloseModal} onCreateFile={creationHandler} />
    </>
  )
}

export default CreationMenu
