import {
  CREATE_USER_EMPLOYEE_WORK_DAY,
  CREATE_USER_EMPLOYEE_WORK_DAY_BREAK,
  DELETE_COMPANY_USER,
  DELETE_SUBSCRIPTION,
  DELETE_USER,
  DELETE_USER_ASSET,
  GET_COMPANY_USERS,
  GET_MY_USER,
  GET_USERS,
  SET_NEW_COMPANY_USER,
  SET_NEW_USER_COMPANY,
  SUBSCRIBE_TO_PRO,
  UPDATE_COMPANY_USER,
  UPDATE_MY_COMPANY_LOGO,
  UPDATE_MY_USER,
  UPDATE_SUBSCRIPTION,
  UPDATE_USER,
  UPDATE_USER_AVATAR,
  UPDATE_USER_EMPLOYEE_WORK_DAY,
  UPDATE_USER_EMPLOYEE_WORK_DAY_BREAK,
  UPLOAD_USER_FREE_TIME,
} from 'constants/actionTypes'

const initialState = {
  myUser: {},
  users: [],
  companyUsers: [],
  isLoadingMyUser: true,
  isLoadingUsers: true,
  isLoadingCompanyUsers: true,
  errorInRequest: false,
}

const handlers = {
  [GET_MY_USER]: (state, { payload = {} }) => {
    if (!payload?.user || !payload?.company) return { ...state, isLoadingMyUser: false, errorInRequest: true }

    const isCompanyOwner = payload.user.idUser === payload.company.ownerIdUser

    return {
      ...state,
      myUser: { ...payload?.user, isCompanyOwner, company: payload?.company, permissions: payload?.permissions ?? [] },
      isLoadingMyUser: false,
      errorInRequest: false,
    }
  },
  [UPDATE_MY_USER]: (state, { payload = {} }) => {
    return {
      ...state,
      myUser: {
        ...(payload?.user ?? state.myUser),
        permissions: payload?.permissions ?? state.myUser.permissions,
        company: payload?.company ?? state?.myUser.company,
      },
      errorInRequest: false,
    }
  },
  [SET_NEW_USER_COMPANY]: (state, { payload = {} }) => {
    if (!payload?.user) return { ...state, errorInRequest: true }

    return {
      ...state,
      users: [payload?.user, ...state?.users],
      errorInRequest: false,
    }
  },
  [GET_USERS]: (state, { payload = {} }) => {
    if (!payload?.users) return { ...state, isLoadingUsers: false, errorInRequest: true }

    return {
      ...state,
      users: payload?.users?.reverse(),
      isLoadingUsers: false,
      errorInRequest: false,
    }
  },
  [UPDATE_USER]: (state, { payload = {} }) => {
    if (!payload?.user) return { ...state, errorInRequest: true }

    return {
      ...state,
      users: state?.users?.map((user) => (user?.idUser === payload?.user?.idUser ? payload?.user : user)),
      errorInRequest: false,
    }
  },
  [DELETE_USER]: (state, { payload = {} }) => {
    if (!payload?.user) return { ...state, errorInRequest: true }
    const { user } = payload

    return {
      ...state,
      users: state.users?.filter((_u) => _u.idUser !== user?.idUser) ?? [],
    }
  },
  [GET_COMPANY_USERS]: (state, { payload = {} }) => {
    if (!payload?.companyUsers) return { ...state, isLoadingCompanyUsers: false, errorInRequest: true }

    return {
      ...state,
      companyUsers: payload?.companyUsers?.reverse(),
      isLoadingCompanyUsers: false,
      errorInRequest: false,
    }
  },
  [SET_NEW_COMPANY_USER]: (state, { payload = {} }) => {
    if (!payload?.user) return { ...state, errorInRequest: true }

    return {
      ...state,
      companyUsers: [payload?.user, ...state?.companyUsers],
      errorInRequest: false,
    }
  },
  [UPDATE_COMPANY_USER]: (state, { payload = {} }) => {
    if (!payload?.user) return { ...state, errorInRequest: true }

    return {
      ...state,
      companyUsers: state?.companyUsers?.map((user) => (user?.idUser === payload?.user?.idUser ? payload?.user : user)),
      errorInRequest: false,
    }
  },
  [DELETE_COMPANY_USER]: (state, { payload = {} }) => {
    if (!payload?.user) return { ...state, errorInRequest: true }
    const { user } = payload

    return {
      ...state,
      companyUsers: state.companyUsers?.filter((_u) => _u.idUser !== user?.idUser) ?? [],
    }
  },
  [UPDATE_USER_AVATAR]: (state, { payload = {} }) => {
    if (!payload?.user) return { ...state, errorInRequest: true }

    return {
      ...state,
      myUser: { ...state.myUser, avatarPhoto: payload?.user?.avatarPhoto },
      errorInRequest: false,
    }
  },
  [UPDATE_MY_COMPANY_LOGO]: (state, { payload = {} }) => {
    if (!payload?.company) return { ...state, errorInRequest: true }

    return {
      ...state,
      myUser: {
        ...state.myUser,
        company: {
          ...state.myUser?.company,
          companyLogo: payload?.company?.companyLogo,
        },
      },
      errorInRequest: false,
    }
  },
  [CREATE_USER_EMPLOYEE_WORK_DAY]: (state, { payload = {} }) => {
    if (!payload?.workDay) return { ...state, errorInRequest: true }

    return {
      ...state,
      myUser: {
        ...state.myUser,
        employee: {
          ...state.myUser.employee,
          work_day: [payload?.workDay, ...state.myUser.employee.work_day],
        },
      },
    }
  },
  [UPDATE_USER_EMPLOYEE_WORK_DAY]: (state, { payload = {} }) => {
    if (!payload?.workDay) return { ...state, errorInRequest: true }

    const employeeWorkDayId = payload?.workDay?.idEmployeeWorkDay

    return {
      ...state,
      myUser: {
        ...state.myUser,
        employee: {
          ...state.myUser.employee,
          work_day: state.myUser.employee.work_day.map((el) =>
            el?.idEmployeeWorkDay === employeeWorkDayId ? payload?.workDay : el
          ),
        },
      },
    }
  },
  [CREATE_USER_EMPLOYEE_WORK_DAY_BREAK]: (state, { payload = {} }) => {
    if (!payload?.break) return { ...state, errorInRequest: true }

    const employeeWorkDayId = payload?.break?.idEmployeeWorkDay

    return {
      ...state,
      myUser: {
        ...state.myUser,
        employee: {
          ...state?.myUser?.employee,
          work_day: state.myUser.employee.work_day.map((el) => {
            if (el?.idEmployeeWorkDay === employeeWorkDayId) {
              return {
                ...el,
                breaks: [payload?.break, ...el.breaks],
              }
            } else return el
          }),
        },
      },
    }
  },
  [UPDATE_USER_EMPLOYEE_WORK_DAY_BREAK]: (state, { payload = {} }) => {
    if (!payload?.break) return { ...state, errorInRequest: true }

    const employeeWorkDayId = payload?.break?.idEmployeeWorkDay
    const breakId = payload?.break?.idWorkDayBreak

    return {
      ...state,
      myUser: {
        ...state.myUser,
        employee: {
          ...state?.myUser?.employee,
          work_day: state.myUser.employee.work_day.map((el) => {
            if (el.idEmployeeWorkDay === employeeWorkDayId) {
              return {
                ...el,
                breaks: el.breaks.map((breakEl) => (breakEl?.idWorkDayBreak === breakId ? payload?.break : breakEl)),
              }
            } else return el
          }),
        },
      },
    }
  },
  [UPLOAD_USER_FREE_TIME]: (state, { payload = {} }) => {
    if (!payload?.freeTime) return { ...state, errorInRequest: true }

    return {
      ...state,
      myUser: {
        ...state.myUser,
        employee: {
          ...state?.myUser?.employee,
          free_time: [payload?.freeTime, ...state.myUser.employee.free_time],
        },
      },
    }
  },
  [DELETE_USER_ASSET]: (state, { payload = {} }) => {
    if (!payload) return { ...state, errorInRequest: true }

    const deletionType = payload?.asset_type

    if (deletionType === 'company') {
      return {
        ...state,
        myUser: {
          ...state.myUser,
          company: {
            ...state.myUser.company,
            companyLogo: '',
          },
        },
      }
    } else {
      return {
        ...state,
        myUser: {
          ...state.myUser,
          avatarPhoto: '',
        },
      }
    }
  },
  [SUBSCRIBE_TO_PRO]: (state, { payload = {} }) => {
    const userId = payload?.subscription.idUser
    const newUser = {
      ...state.myUser,
      subscription: payload?.subscription,
      subscription_payments: payload?.subscription_payments,
      payment_method: payload?.payment_method,
    }

    return {
      ...state,
      myUser: newUser,
      companyUsers: state?.companyUsers?.map((user) => (user?.idUser === userId ? newUser : user)),
    }
  },
  [UPDATE_SUBSCRIPTION]: (state, { payload = {} }) => {
    const userId = payload?.payment_method?.idUser
    const newUser = {
      ...state.myUser,
      payment_method: payload?.payment_method,
    }

    return {
      ...state,
      myUser: newUser,
      companyUsers: state?.companyUsers?.map((user) => (user?.idUser === userId ? newUser : user)),
    }
  },
  [DELETE_SUBSCRIPTION]: (state, { payload = {} }) => {
    const userId = payload?.subscription.idUser
    const newUser = {
      ...state.myUser,
    }

    return {
      ...state,
      myUser: newUser,
      companyUsers: state?.companyUsers?.map((user) => (user?.idUser === userId ? newUser : user)),
    }
  },
}

const users = (state = initialState, action) => {
  const { type } = action

  return handlers[type] ? handlers[type](state, action) : state
}

export default users
