import { Button } from '@material-ui/core'
import classNames from 'classnames'
import SubscribeToProButton from 'components/SubscribeToProButton'
import useLocale from 'hooks/useLocale'
import React from 'react'
import styles from './Plans.module.scss'

function LangObj(es, en) {
  this.es = es
  this.en = en
}

const CONTENT = {
  header: new LangObj('Planes', 'Plans'),
  cards: [
    {
      id: 'free',
      header: 'FREE',
      subheader: new LangObj('Acceso limitado', 'Limited access'),
      iconSrc: '/icons/icon.svg',
      price: new LangObj('Gratis', 'Free'),
      functions: new LangObj('Ver funcionalidades', 'View functions'),
      button: new LangObj('Plan actual', 'Current plan'),
    },
    {
      id: 'pro',
      header: 'PRO',
      subheader: new LangObj('Acceso ilimitado', 'Unlimited access'),
      iconSrc: '/icons/pro-icon.svg',
      price: new LangObj('$55', '$55'),
      functions: new LangObj('Ver funcionalidades', 'View functions'),
      proPlan: true,
      button: new LangObj('Suscribirme', 'Subscribe'),
    },
  ],
}

const PlanCard = ({ header, subheader, price, functions, iconSrc, button, proPlan }) => {
  const locale = useLocale()

  return (
    <article className={classNames(styles.cardWrapper, proPlan && styles.proPlanCard)}>
      <h3 className={styles.cardHeader}>{header}</h3>
      <p className={styles.cardSubheader}>{subheader[locale]}</p>

      <img src={iconSrc} alt={`icono-para-${header[locale]}`} className={styles.cardIcon} />

      <p className={styles.cardPrice}>{price[locale]}</p>
      <a className={styles.cardFunctions} href="/pro#plans">
        {functions[locale]}
      </a>

      {proPlan ? (
        <SubscribeToProButton />
      ) : (
        <Button color="primary" variant="contained" disabled={true}>
          {button[locale]}
        </Button>
      )}
    </article>
  )
}

const Plans = () => {
  const locale = useLocale()

  return (
    <section className={styles.wrapper}>
      <h2 className={styles.header}>{CONTENT.header[locale]}</h2>
      <div className={styles.cards}>
        {CONTENT.cards.map((_card) => (
          <PlanCard
            key={_card.id}
            header={_card.header}
            subheader={_card.subheader}
            functions={_card.functions}
            price={_card.price}
            proPlan={_card.proPlan}
            iconSrc={_card.iconSrc}
            button={_card.button}
          />
        ))}
      </div>
    </section>
  )
}

export default Plans
