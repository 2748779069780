import { selectPage, uploadDatanalisisPage } from 'actions/datanalisis'
import useCompany from 'hooks/useCompany'
import useDatanalisis from 'hooks/useDatanalisis'
import useLocale from 'hooks/useLocale'
import { useState } from 'react'
import { useDispatch } from 'react-redux'

const useCreatePage = () => {
  const dispatch = useDispatch()
  const locale = useLocale()
  const { pages } = useDatanalisis() ?? {}
  const { idCompany } = useCompany() ?? {}

  const [snackbar, setSnackbar] = useState(false)

  const handleCreatePage = async () => {
    setSnackbar(true)
    const index = pages.length + 1
    const page = {
      title: `${locale === 'es' ? 'Página' : 'Page'} ${index}`,
      idCompany,
    }
    const res = await uploadDatanalisisPage(page)

    dispatch(res)
    if (res?.payload?.page) dispatch(selectPage(res?.payload?.page))
    setSnackbar(false)
  }

  return [handleCreatePage, snackbar]
}

export default useCreatePage
