import { ListItemIcon, Menu, MenuItem } from '@material-ui/core'
import { deleteDatanalaisisIndicator, toggleIndicatorCreator, updateDatanalisisIndicatorsLayout } from 'actions/datanalisis'
import { confirmDialog } from 'actions/modals'
import classNames from 'classnames'
import useCancelApi from 'hooks/useCancelApi'
import useLocale from 'hooks/useLocale'
import React, { useState } from 'react'
import { Responsive, WidthProvider } from 'react-grid-layout'
import 'react-grid-layout/css/styles.css'
import { useDispatch } from 'react-redux'
import 'react-resizable/css/styles.css'
import PreviewChart from '../../IndicatorCreatorModal/IndicatorPreviewer/PreviewChart'
import PreviewTotal from '../../IndicatorCreatorModal/IndicatorPreviewer/PreviewTotal'
import styles from './IndicatorsGrid.module.scss'

const IndicatorItemMenu = ({ indicator }) => {
  const locale = useLocale()
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleEdit = () => {
    dispatch(toggleIndicatorCreator(true, indicator))
    handleClose()
  }
  const handleDelete = () => {
    const handlerAction = async () => {
      const res = await deleteDatanalaisisIndicator(indicator?.idDatanalisisIndicator)

      dispatch(res)
    }

    dispatch(
      confirmDialog({
        message:
          locale === 'es' ? '¿Esta seguro de querer eliminar este indicador?' : 'Are you sure you want to delete this indicator?',
        handlerAction,
      })
    )
    handleClose()
  }

  return (
    <>
      <i className={classNames('fa-solid fa-ellipsis-vertical', styles.indicatorMenuIcon)} onClick={handleClick} />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleEdit}>
          <ListItemIcon className={styles.menuIcon}>
            <i className="fas fa-edit" />
          </ListItemIcon>
          {locale === 'es' ? 'Editar' : 'Edit'}
        </MenuItem>
        <MenuItem onClick={handleDelete} classes={{ root: styles.deleteMenuItem }}>
          <ListItemIcon className={styles.menuIcon}>
            <i className={classNames(styles.deleteMenuItem, 'fas fa-trash-alt')} />
          </ListItemIcon>
          {locale === 'es' ? 'Eliminar' : 'Delete'}
        </MenuItem>
      </Menu>
    </>
  )
}

const IndicatorItem = React.forwardRef(({ style, className, children, key, indicator, isResizing, ...rest }, ref) => {
  const { type } = indicator
  const Component = (() => {
    const types = {
      totalization: PreviewTotal,
      graphic: PreviewChart,
    }

    return types[type]
  })()

  return (
    <div key={key} ref={ref} style={{ ...style }} className={className} {...rest}>
      <div className={styles.indicatorItemWrapper}>
        {isResizing ? null : (
          <>
            <IndicatorItemMenu indicator={indicator} />
            <Component indicator={indicator} />
          </>
        )}

        {children}
      </div>
    </div>
  )
})

const ResponsiveGridLayout = WidthProvider(Responsive)

const IndicatorsGrid = ({ indicators }) => {
  const dispatch = useDispatch()
  const [resizingId, setResizingId] = useState(null)
  const layout = indicators.map(({ idDatanalisisIndicator, x, y, w, h, minH }) => ({
    i: String(idDatanalisisIndicator),
    x,
    y,
    w,
    h,
    minH,
  }))

  const updateLayout = async (abortRef, layout) => {
    const res = await updateDatanalisisIndicatorsLayout({ indicators: layout, abortRef })
    dispatch(res)
  }

  const [executor] = useCancelApi(updateLayout)

  const handleLayoutUpdate = (newLayout) => {
    executor(newLayout)
  }

  return (
    <div className={styles.wrapper}>
      <ResponsiveGridLayout
        className="layout"
        layouts={{ lg: layout, md: layout, sm: layout }}
        breakpoints={{ lg: 1200, md: 996, sm: 768 }}
        cols={{ lg: 4, md: 4, sm: 4 }}
        margin={[24, 24]}
        onLayoutChange={handleLayoutUpdate}
        onResizeStart={(e, item) => setResizingId(item.i)}
        onResizeStop={() => setResizingId(null)}
      >
        {indicators.map((indicator) => (
          <IndicatorItem
            key={String(indicator.idDatanalisisIndicator)}
            indicator={indicator}
            isResizing={resizingId === String(indicator.idDatanalisisIndicator)}
          />
        ))}
      </ResponsiveGridLayout>
    </div>
  )
}

export default IndicatorsGrid
